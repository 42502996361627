import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, map } from 'rxjs/operators';
import { throwError } from "rxjs";
import dayjs from "dayjs";

import { AppSettings } from './AppSettings';
import { LogService } from "./LogService";
import { States } from '../constants/States';

export enum TipReimportCurs { zile1 = 'zile1', zile90 = 'zile90' }

@Injectable({ providedIn: 'root' })
export class CursValutarService {
    constructor(private http: HttpClient, private settings: AppSettings, private log: LogService) { }

    getCursValutar(dataCurs: string | Date, idValutaFilter?: number, reimport?: boolean | TipReimportCurs) {
        var warning = this.checkDate(dataCurs);
        if (warning) {
            this.log.warning(warning);
            return throwError(() => new Error(warning));
        }
        return this.getCurs(dataCurs, reimport).pipe(map((curs) => {
            const notImported = curs.filter(c => c.valoare == 1 && c['valoareRaportare'] == 1);
            if (notImported.length > 0) {
                const aici = `<span class="underline"><i class="fa fa-hand-point-right"></i> Click aici</span>`;
                const valute = `pentru a seta cursul pentru valuta (${notImported.map(item => item['valuta']).join(', ')})!`;
                const message = `<a href="/${States.nomenclatoaregenerale.nomgenCursvalutarCursvalutar}" target="_blank">${aici} ${valute}</a>!`;
                this.log.warning(message, 'Nu exista curs valutar online!', null, {
                    enableHtml: true, timeOut: 6000, progressBar: true
                });
            }
            return !idValutaFilter ? curs : curs.filter(v => v.idValuta == idValutaFilter)
        }))
    }

    private getCurs(dataCurs: string | Date, reimport?: boolean | TipReimportCurs) {
        return this.http.post<metadata.registre.CursValutar[]>('/mfinante/getExchangeRate', {
            data: dataCurs, reimport: reimport,// uid: this.settings.idUnitate
        }).pipe(catchError(error => {
            const i = '<i class="fa fa-hand-point-right"></i>aici';
            const a = `<a href="/${States.nomenclatoaregenerale.nomgenCursvalutarCursvalutar}" target="_blank">${i}</a>`;
            const msg = `Nu se poate accesa cursul valutar online. Completati manual ${a}!`;
            this.log.warning(msg, null, null, { enableHtml: true });
            // error.error && this.log.error(error.error);
            throw error;
        }))
    }
    private checkDate(dataCurs: string | Date): string {
        var data = dayjs(dataCurs);
        if (data.isAfter(dayjs(), 'day')) { return "Data in viitor!" }
        //if (data.isBefore(dayjs().subtract(12, "day"), 'day')) {
        //    return "Nu exista curs valutar online pentru data mai mare de 10 zile!"
        //}
        return ''
    }
}