<div class="modal-header">
    <h4>{{params && params.title || 'Progres'}}</h4>
</div>
<div class="modal-body text-center">
    <i *ngIf="progress.procent != 100" class="fa fa-2x fa-spinner fa-spin fa-pulse"></i>
    <p class="mt-2" style="max-height:50vh; white-space: pre-line;" [innerHtml]="progress.message"></p>
    <p *ngIf="progress.error" style="white-space: pre-line;">{{progress.error}}</p>
    
    <div class="mt-2" *ngIf="progress.detaliu" style="overflow: auto; max-height: 50vh;">
        <p class="mb-1 text-left" *ngIf="progress.detaliu" style="white-space: pre-line;">{{progress.detaliu}}</p>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="close()">
        <i class="fa fa-1x"></i>&nbsp;{{progress.procent==100 ? 'Inchide' : 'Renunta'}}
    </button>
</div>