import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FileUploader, FileItem } from 'ng2-file-upload';
import { Observable, Subject, throwError } from 'rxjs';

import { FileViewer } from '../controls/modal/dialog.file';
import { DialogService, DialogSize } from './DialogService';
import { UserService } from './UserService';
import { LogService } from './LogService';
import { FileEndpoints } from "../constants/EnumCustom";

@Injectable({ providedIn: 'root' })
export class FileService {
    constructor(private http: HttpClient, private user: UserService,
        private dialog: DialogService, private log: LogService) { }

    private fileName = 'download';
    private forceSaveableType = "application/octet-stream";
    private saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
    //private userAgent = (navigator.userAgent || navigator.vendor || window['opera']).toLowerCase();

    viewer(entitate: string, idEntitate: number, canUpload?: boolean) {
        return this.dialog.custom(FileViewer, {
            entitate: entitate, idEntitate: idEntitate, canUpload: canUpload
        }, { size: DialogSize.xlarge, backdrop: false }).result
    }

    select(accept?: string | string[], multiple?: boolean): Promise<File> {
        return new Promise((resolve, reject) => {
            var input: HTMLInputElement = document.createElement('input');
            input.style.display = 'none';
            input.type = 'file';
            // input.setAttribute("style", "display:none");
            if (multiple) { input.setAttribute("multiple", "") }

            var ext = Array.isArray(accept) ? accept : [accept];
            if (accept) { input.accept = ext.join(',') }

            //input.addEventListener("change", function () { resolve(this.files) }, false);
            input.onchange = function (evt) {
                var search = new RegExp('(' + ext.join('|').replace(/,/g, '|') + ')$', 'i');
                if (!search.test(this['files'][0].name)) {
                    reject(`Se accepta doar fisiere de tip ${ext.join()}`)
                }
                resolve(this['files'][0])
            };
            document.body.appendChild(input);
            input.click();
            setTimeout(() => document.body.removeChild(input), 333);
        })
    }
    getFile(url: string, fileName: string, autoOpen?: boolean, noAutoBoom?: boolean) {
        this.http.post(url, { file: fileName }, { responseType: 'arraybuffer' }).subscribe(result => {
            if (autoOpen) {
                this.open(result, result['headers']("Content-Type"));
            } else {
                this.download(result, fileName, this.forceSaveableType, noAutoBoom);
            }
        })
    }
    searchFile(entitate: string, idEntitate: string, fileName: string) {
        this.http.post(FileEndpoints.search, { entitate, idEntitate, fileName }, { responseType: 'arraybuffer' }).subscribe({
            next: (res) => {
                if (res && res instanceof ArrayBuffer && res.byteLength !== undefined) {
                    this.download(<any>res, fileName, this.forceSaveableType)
                } else if (res['headers']("Content-Type") == 'application/json') {
                    this.log.warning(res['body'])
                }
            }, error: (err) => {
                this.log.warning('File not found!', null, err)
            }
        })
    }

    upload(url: string, file: File, data?: { [key: string]: any }, acceptedExtentions?: string[]): Observable<any> {
        if (acceptedExtentions && acceptedExtentions.length > 0) {
            const reg = new RegExp('(' + acceptedExtentions.join('|').replace(/\./g, '\.') + ')$', 'i');
            if (!reg.test(file.name)) {
                return throwError(() => new Error(`Se accepta doar fisiere de tip ${acceptedExtentions.join('/')}!`))
            }
        }
        var uploader = new FileUploader({ url: url, authToken: `Bearer ${this.user.token}` });
        if (data) { uploader.options.additionalParameter = data }
        uploader.addToQueue([file]);
        uploader.uploadAll();

        // return uploader.response;
        const subject = new Subject();
        uploader.onErrorItem = (item, response, status, headers) => subject.error({ item, message: response, status, headers });
        uploader.onCompleteItem = (item, response, status, headers) => subject.next({ item, response, status, headers });
        uploader.onCompleteAll = () => { console.log('file upload complete'); subject.complete(); }
        return subject;
    }

    download(blob: Blob | ArrayBuffer | string, name?: string, mimeType?: string, noAutoBom?: boolean) {
        blob = blob instanceof Blob ? blob : new Blob([blob], { type: mimeType || this.forceSaveableType });
        if (!noAutoBom) { blob = this.autoBom(blob) }
        if (!name) { name = this.fileName }

        if ("download" in this.saveLink) {
            var a: HTMLAnchorElement = document.createElement('a');
            a.href = this.getUrl().createObjectURL(blob);
            a.download = name;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () { document.body.removeChild(a) }, 333);
        } else {
            this.open(blob, mimeType, name);
            this.revoke(<any>blob); // clean up objectUrl
            this.closeNewWindow(); // close window
        }
    }

    open(data: Blob | string | any, mimeType?: string, title?: string) {
        var win;
        if (data instanceof Blob) {
            win = window.open(this.getUrl().createobjecturl(data), '', title);
        } else if (typeof data == "string" && /^\/.*/.test(data)) {
            win = window.open(data, "_blank");
        } else {
            win = window.open("data:" + mimeType + "," + data)
        }
        //win.document.title = title || this.fileName;
        win && win.focus();
    }

    private getUrl(): any { return (window.URL || window['webkitURL'] || window) }
    private autoBom(blob: Blob): Blob {
        // prepend BOM for UTF-8 XML and text/* types (including HTML)
        if (/^\s*(?:text\/\S*|application\/xml|\S*\/\S*\+xml)\s*;.*charset\s*=\s*utf-8/i.test(blob.type)) {
            return new Blob(["\ufeff", blob], { type: blob.type });
        }
        return blob;
    }
    private revoke(file: string | FileItem) {
        //                                                    file is an object URL            file is a File
        var revoker = () => { typeof file === "string" ? this.getUrl().revokeObjectURL(file) : file.remove() };
        window['chrome'] ? revoker() : setTimeout(revoker, 500);
    }
    private closeNewWindow() {
        setTimeout(() => { window.focus(); window.close() }, 0);
    }
}