import {
  Directive, ElementRef, Input, Output, EventEmitter,
  OnInit, OnChanges, OnDestroy, forwardRef, Renderer2,
  ChangeDetectorRef, SimpleChanges, NgZone,
  ViewContainerRef, HostListener,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR, ControlValueAccessor, AbstractControl,
  NG_VALIDATORS, ValidationErrors, Validator
} from '@angular/forms';

import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { Datepicker } from './datepicker.directive';

import { Calendar } from './calendarType';
import { CustomDate, DateStruct } from './date';

import { DateAdapter } from './adapters/date-adapter';
import { DatepickerInputConfig } from './datepicker-config';

import { DatepickerI18n } from './i18n';
import { Keys } from '../element.key';
import { autoClose } from '../element.autoClose';
import { PopupService } from '../element.popup.service';
import { PlacementArray, positionElements } from '../element.position';
import { AirDatepickerLocale, AirDatepickerOptions } from 'air-datepicker';

@Directive({
  selector: '[ngbDatepicker]',
  exportAs: 'ngbDatepicker',
  host: {
    '(input)': 'manualDateInput($event.target.value)',
    '(change)': 'manualDateChange($event.target.value)',
    '(blur)': 'onBlur()',
    '(focus)': 'onFocus()',
    '[disabled]': 'disabled',
    '[autocomplete]': 'autocomplete'
  },
  providers: [
    PopupService,
    { provide: NG_VALIDATORS, multi: true, useExisting: forwardRef(() => DatepickerInput) },
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => DatepickerInput) },
  ],
})
export class DatepickerInput implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator {
  constructor(
    private elRef: ElementRef<HTMLInputElement>, private vcRef: ViewContainerRef,
    private changeDetector: ChangeDetectorRef, private renderer: Renderer2,
    private dateAdapter: DateAdapter<any>, private i18n: DatepickerI18n,
    private config: DatepickerInputConfig, private calendar: Calendar,
    private ngZone: NgZone, private popup: PopupService<any>
  ) {
    this.autoClose = config.autoClose;
    this.container = config.container;
    this.placement = config.placement;
    this.positionTarget = config.positionTarget;
    this.zoneSubscription = ngZone.onStable.pipe(take(1)).subscribe(() => this.updatePopupPosition());
  }

  private isDisabled: boolean = false;
  private knownSymbols: string = "dMyHhmftsz";
  private dateFormatRegExp = /dddd|ddd|dd|d|MMMM|MMM|MM|M|yyyy|yy|HH|H|hh|h|mm|m|fff|ff|f|tt|ss|s|zzz|zz|z|"[^"]*"|'[^']*'/g;
  private zoneSubscription: any;
  private autoCloseSub = new Subject();
  private currentDate: CustomDate = new CustomDate();

  @Input() autocomplete: string = 'off';
  @Input('ngbDatepicker') format: string //| ((date: Date) => string);;
  @Input() options: Partial<AirDatepickerOptions>;

  /**
   * The date to open calendar with. Default calendar uses: ISO 8601: 'month' is 1=Jan ... 12=Dec.
   * If nothing or invalid date is provided, calendar will open with current month.
   */
  @Input() startDate: Date | { year: number, month: number, day?: number };

  /** Adds extra classes to the calendar */
  @Input() classes: string;
  /** Language of the calendar.Available locales are in air datepicker/locale */
  @Input() locale: AirDatepickerLocale;
  /** The index of the day from which the week begins.Possible values are from 0(Sunday) to 6(Saturday).By default, it is taken from the localization, if the value is passed here, it will have a higher priority. */
  @Input() firstDay: number;
  /** Indexes of the days that will be considered a weekend.The.- weekend - class will be added to them.By default, this is Saturday and Sunday. */
  @Input() weekends: number[];
  /** If true, then the calendar will appear as a modal window with slightly enlarged dimensions. */
  @Input() isMobile: boolean;
  /** Shows the calendar immediately after initialization. */
  @Input() visible: boolean;
  /** An additional text field where the date with the format from the altFieldDateFormat field will be written */
  @Input() altField: string;
  /** Date format for alternative field */
  @Input() altFieldDateFormat: string | ((date: Date) => string);
  /** If true, then clicking on the active cell will remove the selection from it  */
  @Input() toggleSelected: boolean;
  /** Enables keyboard navigation.It only works if the element on which the calendar is initialized is a text field. */
  @Input() keyboardNav: boolean;
  /** Array of active dates.Accepts both separate data types and mixed data types.If an invalid date format is passed, this value will be ignored */
  @Input() selectedDates: Date[] | string[] | number[];
  /** Parent element for the calendar.By default all calendars are placed in element with class name .air - datepicker - global - container. */
  @Input() container: string | HTMLElement;
  /** 
   * Position of the calendar relative to the text field. 
   * If it is a string then the first value is position along the main axis, the second is position along the secondary one.For example,
   * If you pass the function, you can adjust the position yourself - it will be called when the show() method is triggered.But in this case, all transitions are disabled and you will have to add them manually if they are required.The function accepts an object from the following fields
  */
  @Input() position: string | (() => void);
  /** The initial view of the calendar.Possible values: */
  @Input() view: 'days' | 'months' | 'years';
  /** The minimum possible representation of the calendar.It is used, for example, when you need to provide only a choice of the month.The possible values are the same as for view */
  @Input() minView: 'days' | 'months' | 'years';
  /** If true, dates from other months will be displayed in days view. */
  @Input() showOtherMonths: boolean;
  /** If true, it will be possible to select dates from other months. */
  @Input() selectOtherMonths: boolean;
  /** If true, then selecting dates from another month will be causing transition to this month. */
  @Input() moveToOtherMonthsOnSelect: boolean;
  /** The minimum possible date to select. */
  @Input() minDate: Date | string | number | DateStruct;
  /** The maximum possible date to select. */
  @Input() maxDate: Date | string | number | DateStruct;
  /** Whether it is necessary to prohibit switching to the next or previous month / year / decade if they go beyond the minimum or maximum dates. */
  @Input() disableNavWhenOutOfRange: boolean;
  /** If true, then you can select an unlimited number of dates.If you pass a number, the number of selected dates will be limited by it's value. */
  @Input() multipleDates: boolean | number;
  /** Separator between dates in text field.It is used in the multiple date selection mode and in the range mode. */
  @Input() multipleDatesSeparator: string;
  /** Provides the ability to select a date range.The value from multipleDatesSeparator will be used as the separator. */
  @Input() range: boolean;
  /** If true, then after selecting dates in range mode, they can be changed by dragging them. */
  @Input() dynamicRange: boolean;
  /** This option allows you to add action buttons to body of the calendar.You could add two pre installed buttons or create your own. */
  @Input() buttons: string[] | object | object[] | false;
  /** A field from the localization object that will be used to display the names of the month in the months view. */
  @Input() monthsField: string;
  /** DOM event on which calendar will be shown. */
  @Input() showEvent: string;
  /** If true, the calendar will be hidden after selecting the date. */
  @Input() autoClose: boolean | 'inside' | 'outside';
  @Input() prevHtml: string;
  @Input() nextHtml: string;
  /** 
   * Title templates in the calendar navigation.You can use HTML tags and tokens from dateFormat.You can also pass the function as a value - it will receive an instance of the calendar as an argument, and should return a string. 
   * If a callback function is passed, it will be called every time the view changes, the date is selected, or when switching to another month.
   */
  @Input() navTitles: { days?: string, months?: string, years?: string } | (() => void);
  /** Turns on timepicker */
  @Input() timepicker: boolean;
  /** If you need to choose only time, without date. */
  @Input() onlyTimepicker: boolean;
  /** Separator between date and time. */
  @Input() dateTimeSeparator: string;

  /** Time format.Just like dateFormat relies on Unicode Technical Standard #35. If you pass a 12 - hour display format, the time sliders will be automatically adjusted to the corresponding mode. */
  @Input() timeFormat: string;
  @Input() minHours: number; /** Minimum possible hours value. */
  @Input() maxHours: number; /** Minimum possible hours value. */
  @Input() minMinutes: number; /** Minimum possible minutes value. */
  @Input() maxMinutes: number; /** Maximum possible minutes value. */
  @Input() hoursStep: number; /** Hours step. */
  @Input() minutesStep: number; /** Minutes step. */

  /** Indicates whether the datepicker popup should be closed automatically after date selection / outside click or not. */
  // @Input() autoClose: boolean | 'inside' | 'outside';
  /** A css selector or html element specifying the element the datepicker popup should be positioned against. */
  @Input() positionTarget: string | HTMLElement;

  /**
   * The preferred placement of the datepicker popup.
   * Possible values are: top, top-left, top-right, bottom, bottom-left,
   *   bottom-right, left, left-top, left-bottom, right, right-top, right-bottom
   *
   * Accepts an array of strings or a string with space separated possible values.
   * The default order of preference is "bottom-left bottom-right top-left top-right"
   */
  @Input() placement: PlacementArray;

  /**
   * If `true`, when closing datepicker will focus element that was focused before datepicker was opened.
   * Alternatively you could provide a selector or an `HTMLElement` to focus. 
   * If the element doesn't exist or invalid, we'll fallback to focus document body.
   */
  @Input() restoreFocus: true | string | HTMLElement = true;

  /**
  * mark some dates as disabled. Called for each new date when navigating to a different month.
  * `current` is the month that is currently displayed by the datepicker.
  */
  @Input() markDisabled: (date: CustomDate, current?: { year: number, month: number }) => boolean;


  @Output() closed = new EventEmitter<void>();
  @Output() navigate = new EventEmitter<any>();
  @Output() dateSelect = new EventEmitter<CustomDate>();

  @Input() get disabled() { return this.isDisabled }
  set disabled(value: any) {
    this.isDisabled = value === '' || (value && value !== 'false');
    if (this.popup.isOpen()) {
      this.popup.windowRef!.instance.setDisabledState(this.isDisabled)
    }
  }

  private onTouched = () => { };
  private validatorChange = () => { };
  private changeModel = (_: any) => { };

  ngOnInit() {
    // nu se poate initializa in constructor pt ca aici vine fara valoare
    if (!this.format) { this.format = this.config.dateFormat }
  }

  registerOnChange(fn: (value: any) => any): void { this.changeModel = fn }
  registerOnTouched(fn: () => any): void { this.onTouched = fn }
  registerOnValidatorChange(fn: () => void): void { this.validatorChange = fn }
  setDisabledState(isDisabled: boolean): void { this.disabled = isDisabled }

  validate(ctrl: AbstractControl): ValidationErrors | null {
    const { value } = ctrl;

    if (value != null) {
      let currentDate = this.dateAdapter.fromModel(value);
      if (!currentDate) { return { date: { invalid: value } } }

      if (this.minDate && this.currentDate.isBefore(CustomDate.from(this.minDate))) {
        return { min: { invalid: value, minDate: this.minDate } }
      }
      if (this.maxDate && this.currentDate.isAfter(CustomDate.from(this.maxDate))) {
        return { max: { invalid: value, maxDate: this.maxDate } }
      }
    }
    return null;
  }

  writeValue(value) { // from ngModel
    this.currentDate.setDate(this.dateAdapter.fromModel(value));
    this.writeModelValue(value);
  }

  manualDateChange(value: Date | DateStruct) { }

  manualDateInput(value: string) { // on input
    const start = caret(this.elRef.nativeElement)[0];
    const symbol = this.format[start - 1];
    var segment = '', newValue = '';

    for (var i = start, j = start - 1; i < this.format.length || j >= 0; i++, j--) {
      if (i < this.format.length && symbol == this.format[i]) {
        segment = segment + this.format[i]
      }
      if (j >= 0 && symbol == this.format[j]) {
        segment = this.format[j] + segment;
        newValue = `${value[j]}${newValue}`;
      }
    }

    const isValid = this.currentDate.modifyPart(this.format[start - 1], newValue);
    const valueChange = +newValue != this.currentDate.getValue(symbol);

    if (isValid && this.calendar.isValid(this.currentDate)) {
      this.changeModel(this.dateAdapter.toModel(this.currentDate))
    }
    if (newValue == '0') {
      // do nothing, wait for input
    } else if (segment.length == newValue.length) {
      this.writeModelValue(this.currentDate);
      if (valueChange) {
        this.selectSegment(symbol)
      } else {
        this.keydown(<any>{ keyCode: 39, preventDefault: function () { } })
      }
    } else if (symbol != this.format[start]) {
      this.keydown(<any>{ keyCode: 39, preventDefault: function () { } })
    }
  }

  private writeModelValue(model: CustomDate | null) { // to view
    const value = model === null ? null : this.toView(this.format);
    this.renderer.setProperty(this.elRef.nativeElement, 'value', value);
    if (this.popup.isOpen()) {
      this.popup.windowRef!.instance.writeValue(this.dateAdapter.toModel(model));
      this.onTouched();
    }
  }
  private toView(format: string): string {
    return format.replace(this.dateFormatRegExp, (match) => {
      var result;
      switch (match) {
        case ("d"): result = this.currentDate.day ? this.currentDate.day : match /* Placeholders.day */; break;
        case ("dd"): result = this.currentDate.day ? pad(this.currentDate.day) : match /*Placeholders.day */; break;
        case ("ddd"): result = this.currentDate.day ? this.i18n.getWeekdayAbbrName(this.currentDate.day) : match /* Placeholders.weekday */; break;
        case ("dddd"): result = this.currentDate.day ? this.i18n.getWeekdayFullName(this.currentDate.day) : match /* Placeholders.weekday */; break;

        case ("M"): result = this.currentDate.month ? this.currentDate.month : match /* Placeholders.month */; break;
        case ("MM"): result = this.currentDate.month ? pad(this.currentDate.month) : match /* Placeholders.month */; break;
        case ("MMM"): result = this.currentDate.month ? this.i18n.getMonthShortName(this.currentDate.month) : match/* Placeholders.month */; break;
        case ("MMMM"): result = this.currentDate.month ? this.i18n.getMonthFullName(this.currentDate.month) : match/* Placeholders.month */; break;

        case ("yy"): result = this.currentDate.year ? pad(this.currentDate.year % 100) : match/* Placeholders.year */; break;
        case ("yyyy"): result = this.currentDate.year ? pad(this.currentDate.year, 4) : match/* Placeholders.year */; break;

        case ("h"): result = this.currentDate.hours ? this.currentDate.hours % 12 || 12 : match/* placeholders.hour */; break;
        case ("hh"): result = this.currentDate.hours ? pad(this.currentDate.hours % 12 || 12) : match/* placeholders.hour */; break;
        case ("H"): result = this.currentDate.hours ? this.currentDate.hours : match/* placeholders.hour */; break;
        case ("HH"): result = this.currentDate.hours ? pad(this.currentDate.hours) : match/* placeholders.hour */; break;
        case ("m"): result = this.currentDate.minutes ? this.currentDate.minutes : match/* placeholders.minute */; break;
        case ("mm"): result = this.currentDate.minutes ? pad(this.currentDate.minutes) : match/* placeholders.minute */; break;
        case ("s"): result = this.currentDate.second ? this.currentDate.second : match/* placeholders.second */; break;
        case ("ss"): result = this.currentDate.second ? pad(this.currentDate.second) : match/* placeholders.second */; break;

        // case ("f"): result = milliseconds ? Math.floor(value.getMilliseconds() / 100) : milliseconds; break;
        // case ("ff"):
        //   result = value.getMilliseconds();
        //   if (result > 99) { result = Math.floor(result / 10) }
        //   result = milliseconds ? pad(result) : match;
        //   break;
        // case ("fff"): result = milliseconds ? pad(value.getMilliseconds(), 3) : match; break;
        // case ("tt"): result = hours ? (value.getHours() < 12 ? calendar.AM[0] : calendar.PM[0]) : placeholders.dayperiod; break;
        // case ("zzz"):
        //   const mins: number;
        //   mins = value.getTimezoneOffset();
        //   const sign = mins < 0;
        //   result = Math.abs(mins / 60).toString().split(".")[0];
        //   mins = Math.abs(mins) - (result * 60);
        //   result = (sign ? "+" : "-") + pad(result);
        //   result += ":" + pad(mins);
        //   break;
        // case ("z"):
        // case ("zz"):
        //   result = value.getTimezoneOffset() / 60;
        //   const sign = result < 0;
        //   result = Math.abs(result).toString().split(".")[0];
        //   result = (sign ? "+" : "-") + (match === "zz" ? pad(result) : result);
        //   break;
      }
      return (result !== undefined ? result : match.slice(1, match.length - 1));
    }).toLowerCase()
  }

  onFocus() {
    this.selectNearestSegment([0]);
    this.onTouched();
  }
  onBlur() {
    this.close();
  }
  today() {
    const d = new Date();
    this.navigateTo({ year: d.getFullYear(), month: d.getMonth(), day: d.getDate() });
  }

  @HostListener('paste', ['$event'])
  paste(e: ClipboardEvent) {
    e.preventDefault()
    // TODO: handle pasted date
  }

  @HostListener('keydown', ['$event'])
  keydown(event: KeyboardEvent) {
    const { key } = event;

    if (key == Keys.ArrowLeft || key == Keys.ArrowRight) { // left / right
      event.preventDefault();
      const selection = caret(this.elRef.nativeElement);
      if (selection[0] != selection[1]) { this.selectNearestSegment() }

      const dir = (key == Keys.ArrowLeft) ? -1 : 1;
      var index = (dir == -1) ? caret(this.elRef.nativeElement)[0] - 1 : caret(this.elRef.nativeElement)[1] + 1;

      while (index >= 0 && index < this.format.length) {
        if (this.knownSymbols.indexOf(this.format[index]) >= 0) {
          this.selectSegment(this.format[index]);
          break;
        }
        index += dir;
      }
    } else if (key == Keys.ArrowUp || key == Keys.ArrowDown) { // up / down
      event.preventDefault();
      const selection = caret(this.elRef.nativeElement);
      const symbol = this.format[selection[0]];
      if (this.knownSymbols.indexOf(symbol) > -1) {
        const interval = 1;
        const value = this.currentDate.getValue(symbol) + (key == Keys.ArrowUp ? interval * 1 : interval * -1);
        // if (symbol == 'm') { interval = this.options.interval || 1 }
        this.currentDate.modifyPart(symbol, value);
        this.writeModelValue(this.currentDate);
        if (this.calendar.isValid(this.currentDate)) {
          this.changeModel(this.dateAdapter.toModel(this.currentDate))
        }
        this.selectSegment(symbol);
      }
    } else if (key == Keys.Backspace || key == Keys.Delete) { // backspace/delete/del
      event.preventDefault();
      const selection = caret(this.elRef.nativeElement);
      this.currentDate.modifyPart(this.format[selection[0]], null);
      this.writeModelValue(this.currentDate);
      this.changeModel(null);
      this.selectNearestSegment(selection);
    } else if (key === Keys.Enter || key === Keys.Space) { // next segment
      if (caret(this.elRef.nativeElement)[1] < this.format.length) {
        event.preventDefault();
        event.stopPropagation();
        if (this.config.getDefaultValue) {
          const date = this.config.getDefaultValue();
          const symbol = this.format[caret(this.elRef.nativeElement)[1] - 1];
          switch (symbol) {
            case 'd': this.currentDate.modifyPart(symbol, date.getDate()); break;
            case 'M': this.currentDate.modifyPart(symbol, date.getMonth()); break;
            case 'y': this.currentDate.modifyPart(symbol, date.getFullYear()); break;
          }
          this.writeModelValue(this.currentDate);
          this.changeModel(this.currentDate);
        }
        this.keydown(<any>{ key: Keys.ArrowRight, preventDefault: function () { } })
      }
    }
  }

  @HostListener('mouseup', ['$event'])
  mouseUp(event: MouseEvent) {
    event.preventDefault();
    const selection = caret(this.elRef.nativeElement);
    if (selection && selection[0] === selection[1]) { this.selectNearestSegment(selection) }
  }

  // @HostListener('wheel', ['$event'])
  @HostListener('mousewheel', ['$event'])
  mousewheel(e: WheelEvent) {
    let el = this.elRef.nativeElement;
    if (el !== document.activeElement || el.hasAttribute("readonly")) { return }

    var newEvent: any = { key: Keys.ArrowLeft, preventDefault: function () { } };
    if (e.shiftKey) {
      newEvent.key = (e.deltaY || e['wheelDelta'] || e.deltaMode || -e.detail) > 0 ? Keys.ArrowLeft : Keys.ArrowRight
    } else {
      newEvent.key = (e.deltaY || e['wheelDelta'] || e.deltaMode || -e.detail) > 0 ? Keys.ArrowDown : Keys.ArrowUp
    }

    this.keydown(newEvent);
    e.returnValue = false;
    if (e.preventDefault) { e.preventDefault() }
    if (e.stopPropagation) { e.stopPropagation() }
  }

  private selectNearestSegment(selection?: [number, number?]) {
    if (!selection) { selection = caret(this.elRef.nativeElement) }
    const start = selection[0];
    for (var i = start, j = start - 1; i < this.format.length || j >= 0; i++, j--) {

      if (i < this.format.length && this.knownSymbols.indexOf(this.format[i]) !== -1) {
        this.selectSegment(this.format[i]);
        return;
      }
      if (j >= 0 && this.knownSymbols.indexOf(this.format[j]) !== -1) {
        this.selectSegment(this.format[j]);
        return;
      }
    }
  }

  private selectSegment(symbol: string) {
    var begin = -1, end = 0;
    for (var i = 0; i < this.format.length; i++) {
      if (this.format[i] === symbol) {
        end = i + 1;
        if (begin === -1) { begin = i }
      }
    }
    caret(this.elRef.nativeElement, begin < 0 ? 0 : begin, end);
  }

  toggle() { this.popup.isOpen() ? this.close() : this.open() }

  open() {
    if (!this.popup.isOpen()) {
      this.popup.open(this.vcRef, Datepicker, {
        date: !this.currentDate.date || this.currentDate.date < new Date(1980) ? new Date() : this.currentDate.date,
        options: Object.assign({ position: 'bottom left', inline: false }, this.config, this.options, this.getOptions())
      });
      if (this.markDisabled) {
        this.popup.windowRef.instance.onRenderCell.subscribe((params) => this.markDisabled(params))
      }
      this.popup.windowRef.instance.change.subscribe(({ date }) => {
        if (this.autoClose === true || this.autoClose === 'inside') {
          this.close()
        }
        this.currentDate.setDate(date || null);
        this.writeModelValue(this.currentDate);
        if (this.calendar.isValid(this.currentDate)) {
          this.changeModel(this.dateAdapter.toModel(this.currentDate))
        }
        this.dateSelect.emit(date || null);
      });
      !this.isMobile && this.updatePopupPosition();
      autoClose(
        this.ngZone, document, 'outside', () => this.close(), this.autoCloseSub,
        [this.elRef.nativeElement, this.popup.windowRef.location.nativeElement]
      );
    }
  }

  close() {
    this.autoCloseSub.next(undefined);
    if (this.popup.isOpen()) {
      this.popup.close(this.vcRef);
      this.closed.emit();
      this.changeDetector.markForCheck();

      let elementToFocus: HTMLElement | null = this.elRef.nativeElement;
      if (typeof this.restoreFocus == 'string') {
        elementToFocus = document.querySelector(this.restoreFocus)
      } else if (this.restoreFocus !== undefined) {
        elementToFocus = this.restoreFocus as HTMLElement
      }
      // in IE document.activeElement can contain an object without 'focus()' sometimes
      if (elementToFocus && elementToFocus['focus']) {
        elementToFocus.focus()
      } else {
        document.body.focus()
      }
    }
  }

  private getOptions() {
    const options = {
      dateFormat: this.format,
      classes: this.classes,
      locale: this.locale,
      firstDay: this.firstDay,
      weekends: this.weekends,
      isMobile: this.isMobile,
      visible: this.visible,
      altField: this.altField,
      altFieldDateFormat: this.altFieldDateFormat,
      toggleSelected: this.toggleSelected,
      keyboardNav: this.keyboardNav,
      selectedDates: this.selectedDates,
      container: this.container,
      position: this.position,
      view: this.view,
      minView: this.minView,
      showOtherMonths: this.showOtherMonths,
      selectOtherMonths: this.selectOtherMonths,
      moveToOtherMonthsOnSelect: this.moveToOtherMonthsOnSelect,
      minDate: this.minDate,
      maxDate: this.maxDate,
      disableNavWhenOutOfRange: this.disableNavWhenOutOfRange,
      multipleDates: this.multipleDates,
      multipleDatesSeparator: this.multipleDatesSeparator,
      range: this.range,
      dynamicRange: this.dynamicRange,
      buttons: this.buttons,
      monthsField: this.monthsField,
      showEvent: this.showEvent,
      autoClose: (this.autoClose == 'inside' || this.autoClose != 'outside' || this.autoClose),
      prevHtml: this.prevHtml,
      nextHtml: this.nextHtml,
      navTitles: this.navTitles,
      timepicker: this.timepicker,
      onlyTimepicker: this.onlyTimepicker,
      dateTimeSeparator: this.dateTimeSeparator,
      timeFormat: this.timeFormat,
      minHours: this.minHours,
      maxHours: this.maxHours,
      minMinutes: this.minMinutes,
      maxMinutes: this.maxMinutes,
      hoursStep: this.hoursStep,
      minutesStep: this.minutesStep,
    };
    return Object.keys(options).reduce((prop, key) => {
      if ((options[key] !== undefined)) { prop[key] = options[key] }
      return prop;
    }, {})
  }

  /**
   * Navigates to the provided date. Default calendar: ISO 8601: 'month' is 1=Jan...12=Dec.
   * If nothing or invalid date provided calendar will open current month.
   * Use `[startDate]` input as an alternative.
   */
  navigateTo(date?: { year: number, month: number, day?: number }) {
    // if (this.popup.isOpen()) { this.popup.datepickerRef!.instance.navigateTo(date) }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['minDate'] || changes['maxDate']) {
      this.validatorChange();
      if (this.popup.isOpen()) {
        this.popup.windowRef!.instance.ngOnChanges(changes);
      }
    }
  }

  ngOnDestroy() {
    this.close();
    this.zoneSubscription.unsubscribe();
  }

  // private fromDateStruct(date: DateStruct | null): CustomDate | null {
  //   const myDate = date ? new CustomDate(date.year, date.month, date.day) : null;
  //   return this.calendar.isValid(myDate) ? myDate : null;
  // }

  private updatePopupPosition() {
    if (!this.popup.isOpen()) { return }

    let hostElement: HTMLElement;
    if (typeof this.positionTarget == 'string') {
      hostElement = document.querySelector(this.positionTarget)
    } else if (this.positionTarget instanceof HTMLElement) {
      hostElement = this.positionTarget
    } else {
      hostElement = this.elRef.nativeElement
    }
    if (this.positionTarget && !hostElement) {
      throw new Error('Datepicker could not find element declared in [positionTarget] to position against.')
    }
    positionElements(hostElement,
      this.popup.windowRef.location.nativeElement,
      ['bottom-left'] || this.placement,
      this.container === 'body',
      undefined,
      { 'z-index': 1000, 'background-color': 'white' })
  }
}


const wp = navigator.userAgent.match(/(Windows Phone(?: OS)?)\s(\d+)\.(\d+(\.\d+)?)/);
const android = navigator.userAgent.match(/(Android|Android.*(?:Opera|Firefox).*?\/)\s*(\d+)\.?(\d+(\.\d+)?)?/);

const zeros = ["", "0", "00", "000", "0000"];
function pad(number, digits?: number): string {
  return zeros[digits || 2].slice(`${number}`.length) + number;
}
function caret(element, start?, end?) {
  var rangeElement, isPosition = start !== undefined;

  if (end === undefined) { end = start }
  if (element[0]) { element = element[0] }
  if (isPosition && element.disabled) { return }

  try {
    if (element.selectionStart !== undefined) {
      if (isPosition) {
        element.focus();

        if (wp || android) { // without the timeout the caret is at the end of the input
          setTimeout(() => element.setSelectionRange(start, end), 0)
        } else {
          element.setSelectionRange(start, end)
        }
      } else {
        start = [element.selectionStart, element.selectionEnd];
      }
    } else if (document['selection']) {
      // if ($(element).is(":visible")) { element.focus() }
      rangeElement = element.createTextRange();

      if (isPosition) {
        rangeElement.collapse(true);
        rangeElement.moveStart("character", start);
        rangeElement.moveEnd("character", end - start);
        rangeElement.select();
      } else {
        var selectionStart, selectionEnd, rangeDuplicated = rangeElement.duplicate();

        rangeElement.moveToBookmark(document['selection'].createRange().getBookmark());
        rangeDuplicated.setEndPoint('EndToStart', rangeElement);
        selectionStart = rangeDuplicated.text.length;
        selectionEnd = selectionStart + rangeElement.text.length;

        start = [selectionStart, selectionEnd];
      }
    }
  } catch (e) { start = [] } /* element is not focused or it is not in the DOM */

  return start;
}