/*
 * Copyright 2012-2023 IdeaBlade, Inc.  All Rights Reserved.
 * Use, reproduction, distribution, and modification of this code is subject to the terms and
 * conditions of the IdeaBlade Breeze license, available at http://www.breezejs.com/license
 *
 * Author: Jay Traband
 */
/**
Base class for all Breeze enumerations, such as EntityState, DataType, FetchStrategy, MergeStrategy etc.
A Breeze Enum is a namespaced set of constant values.  Each Enum consists of a group of related constants, called 'symbols'.
Unlike enums in some other environments, each 'symbol' can have both methods and properties.
>     class DayOfWeek extends BreezeEnum {
>       dayIndex: number;
>       isWeekend?: boolean;
>       nextDay() {
>         let nextIndex = (this.dayIndex + 1) % 7;
>         return DayOfWeek.getSymbols()[nextIndex];
>       }
>
>       static Monday = new DayOfWeek( { dayIndex: 0});
>       static Tuesday = new DayOfWeek( { dayIndex: 1 });
>       static Wednesday = new DayOfWeek( { dayIndex: 2 });
>       static Thursday = new DayOfWeek( { dayIndex: 3 });
>       static Friday = new DayOfWeek( { dayIndex: 4 });
>       static Saturday = new DayOfWeek( { dayIndex: 5, isWeekend: true });
>       static Sunday = new DayOfWeek( { dayIndex: 6, isWeekend: true });
>     }
>
>     describe("DayOfWeek", () => {
>       test("should support full enum capabilities", function() {
>         // // custom methods
>         let dowSymbols = DayOfWeek.getSymbols();
>         expect(dowSymbols.length).toBe(7);
>         expect(DayOfWeek.Monday.nextDay()).toBe(DayOfWeek.Tuesday);
>         expect(DayOfWeek.Sunday.nextDay()).toBe(DayOfWeek.Monday);
>       // // custom properties
>         expect(DayOfWeek.Tuesday.isWeekend).toBe(undefined);
>         expect(DayOfWeek.Saturday.isWeekend).toBe(true);
>       // // Standard enum capabilities
>         expect(DayOfWeek.Thursday instanceof DayOfWeek).toBe(true);
>         expect(BreezeEnum.isSymbol(DayOfWeek.Wednesday)).toBe(true);
>         expect(DayOfWeek.contains(DayOfWeek.Thursday)).toBe(true);
>         expect(DayOfWeek.Friday.toString()).toBe("Friday");
>       });
>   });
Note that we have Error['x'] = ... in some places in the code to prevent Terser from optimizing out some important calls.
@dynamic
*/
class BreezeEnum {
  /**  */
  constructor(propertiesObj) {
    if (propertiesObj) {
      Object.keys(propertiesObj).forEach(key => this[key] = propertiesObj[key]);
    }
  }
  /**
  Returns all of the symbols contained within this Enum.
  >     let symbols = DayOfWeek.getSymbols();
  @return All of the symbols contained within this Enum.
  **/
  static getSymbols() {
    return this.resolveSymbols().map(ks => ks.symbol);
  }
  /**
  Returns the names of all of the symbols contained within this Enum.
  >     let symbols = DayOfWeek.getNames();
  @return  All of the names of the symbols contained within this Enum.
  **/
  static getNames() {
    return this.resolveSymbols().map(ks => ks.name);
  }
  /**
  Returns an Enum symbol given its name.
  >     let dayOfWeek = DayOfWeek.from("Thursday");
  >     // nowdayOfWeek === DayOfWeek.Thursday
  @param name - Name for which an enum symbol should be returned.
  @return The symbol that matches the name or 'undefined' if not found.
  **/
  static fromName(name) {
    return this[name];
  }
  /**
  Seals this enum so that no more symbols may be added to it. This should only be called after all symbols
  have already been added to the Enum. This method also sets the 'name' property on each of the symbols.
  >     DayOfWeek.resolveSymbols();
  **/
  static resolveSymbols() {
    if (this._resolvedNamesAndSymbols) return this._resolvedNamesAndSymbols;
    let result = [];
    for (let key in this) {
      if (this.hasOwnProperty(key)) {
        let symb = this[key];
        if (symb instanceof BreezeEnum) {
          result.push({
            name: key,
            symbol: symb
          });
          this[key] = symb;
          symb.name = key;
        }
      }
    }
    this._resolvedNamesAndSymbols = result;
    return result;
  }
  /**
  Returns whether an Enum contains a specified symbol.
  >     let symbol = DayOfWeek.Friday;
  >     if (DayOfWeek.contains(symbol)) {
  >         // do something
  >     }
  @param sym - Object or symbol to test.
  @return Whether this Enum contains the specified symbol.
  **/
  static contains(sym) {
    if (!(sym instanceof BreezeEnum)) {
      return false;
    }
    return this[sym.name] != null;
  }
  // /**
  // Checks if an object is an Enum 'symbol'. Use the 'contains' method instead of this one 
  // if you want to test for a specific Enum. 
  // >     if (Enum.isSymbol(DayOfWeek.Wednesday)) {
  // >       // do something ...
  // >     };
  // **/
  // static isSymbol(obj: any) {
  //   return obj instanceof BreezeEnum;
  // };
  /** Returns the string name of this Enum */
  toString() {
    return this.name;
  }
  /** Return enum name and symbol name */
  toJSON() {
    return {
      _$typeName: this['_$typeName'] || this.constructor.name,
      name: this.name
    };
  }
}

/** See if this comment will make it into .d.ts */
let hasOwnProperty = uncurry(Object.prototype.hasOwnProperty);
let arraySlice = uncurry(Array.prototype.slice);
const ɵ0 = function () {
  try {
    return !!(Object.getPrototypeOf && Object.defineProperty({}, 'x', {}));
  } catch (e) {
    return false;
  }
};
let isES5Supported = ɵ0();
// iterate over object
function objectForEach(obj, kvFn) {
  for (let key in obj) {
    if (hasOwnProperty(obj, key)) {
      kvFn(key, obj[key]);
    }
  }
}
function objectMap(obj, kvFn) {
  let results = [];
  for (let key in obj) {
    if (hasOwnProperty(obj, key)) {
      let result = kvFn ? kvFn(key, obj[key]) : obj[key];
      if (result !== undefined) {
        results.push(result);
      }
    }
  }
  return results;
}
function objectFirst(obj, kvPredicate) {
  for (let key in obj) {
    if (hasOwnProperty(obj, key)) {
      let value = obj[key];
      if (kvPredicate(key, value)) {
        return {
          key: key,
          value: value
        };
      }
    }
  }
  return null;
}
function arrayFlatMap(arr, mapFn) {
  return Array.prototype.concat.apply([], arr.map(mapFn));
}
function isSettable(obj, propertyName) {
  let pd = getPropDescriptor(obj, propertyName);
  if (pd == null) return true;
  return !!(pd.writable || pd.set);
}
function getPropDescriptor(obj, propertyName) {
  if (!isES5Supported) return undefined;
  if (obj.hasOwnProperty(propertyName)) {
    return Object.getOwnPropertyDescriptor(obj, propertyName);
  } else {
    let nextObj = Object.getPrototypeOf(obj);
    if (nextObj == null) return undefined;
    return getPropDescriptor(nextObj, propertyName);
  }
}
// Functional extensions
/** can be used like: persons.filter(propEq("firstName", "John")) */
function propEq(propertyName, value) {
  return function (obj) {
    return obj[propertyName] === value;
  };
}
/** can be used like: persons.filter(propEq("firstName", "FirstName", "John")) */
function propsEq(property1Name, property2Name, value) {
  return function (obj) {
    return obj[property1Name] === value || obj[property2Name] === value;
  };
}
/** can be used like persons.map(pluck("firstName")) */
function pluck(propertyName) {
  return function (obj) {
    return obj[propertyName];
  };
}
// end functional extensions
/** Return an array of property values from source */
function getOwnPropertyValues(source) {
  let result = [];
  for (let name in source) {
    if (hasOwnProperty(source, name)) {
      result.push(source[name]);
    }
  }
  return result;
}
/** Copy properties from source to target. Returns target. */
function extend(target, source, propNames) {
  if (!source) return target;
  if (propNames) {
    propNames.forEach(function (propName) {
      target[propName] = source[propName];
    });
  } else {
    for (let propName in source) {
      if (hasOwnProperty(source, propName)) {
        target[propName] = source[propName];
      }
    }
  }
  return target;
}
/** Copy properties from defaults iff undefined on target.  Returns target. */
function updateWithDefaults(target, defaults) {
  for (let name in defaults) {
    if (target[name] === undefined) {
      target[name] = defaults[name];
    }
  }
  return target;
}
/** Set ctor.defaultInstance to an instance of ctor with properties from target.
    We want to insure that the object returned by ctor.defaultInstance is always immutable
    Use 'target' as the primary template for the ctor.defaultInstance;
    Use current 'ctor.defaultInstance' as the template for any missing properties
    creates a new instance for ctor.defaultInstance
    returns target unchanged */
function setAsDefault(target, ctor) {
  ctor.defaultInstance = updateWithDefaults(new ctor(target), ctor.defaultInstance);
  return target;
}
/**
    'source' is an object that will be transformed into another
    'template' is a map where the
       keys: are the keys to return
         if a key contains ','s then the key is treated as a delimited string with first of the
         keys being the key to return and the others all valid aliases for this key
       'values' are either
           1) the 'default' value of the key
           2) a function that takes in the source value and should return the value to set
         The value from the source is then set on the target,
         after first passing thru the fn, if provided, UNLESS:
           1) it is the default value
           2) it is undefined ( nulls WILL be set)
    'target' is optional
       - if it exists then properties of the target will be set ( overwritten if the exist)
       - if it does not exist then a new object will be created as filled.
    'target is returned.
*/
function toJson(source, template, target = {}) {
  for (let key in template) {
    let aliases = key.split(",");
    let defaultValue = template[key];
    // using some as a forEach with a 'break'
    aliases.some(function (propName) {
      if (!(propName in source)) return false;
      let value = source[propName];
      // there is a functional property defined with this alias ( not what we want to replace).
      if (typeof value === 'function') return false;
      // '==' is deliberate here - idea is that null or undefined values will never get serialized
      // if default value is set to null.
      // tslint:disable-next-line
      if (value == defaultValue) return true;
      if (Array.isArray(value) && value.length === 0) return true;
      if (typeof defaultValue === "function") {
        value = defaultValue(value);
      } else if (typeof value === "object") {
        if (value && value instanceof BreezeEnum) {
          value = value.name;
        }
      }
      if (value === undefined) return true;
      target[aliases[0]] = value;
      return true;
    });
  }
  return target;
}
/** Replacer function for toJSONSafe, when serializing entities.  Excludes entityAspect and other internal properties. */
function toJSONSafeReplacer(prop, val) {
  if (prop === "entityAspect" || prop === "complexAspect" || prop === "entityType" || prop === "complexType" || prop === "getProperty" || prop === "setProperty" || prop === "constructor" || prop.charAt(0) === '_' || prop.charAt(0) === '$') return;
  return val;
}
/** Safely perform toJSON logic on objects with cycles. */
function toJSONSafe(obj, replacer) {
  if (obj !== Object(obj)) return obj; // primitive value
  if (obj._$visited) return undefined;
  if (obj.toJSON) {
    let newObj = obj.toJSON();
    if (newObj !== Object(newObj)) return newObj; // primitive value
    if (newObj !== obj) return toJSONSafe(newObj, replacer);
    // toJSON returned the object unchanged.
    obj = newObj;
  }
  obj._$visited = true;
  let result;
  if (obj instanceof Array) {
    result = obj.map(function (o) {
      return toJSONSafe(o, replacer);
    });
  } else if (typeof obj === "function") {
    result = undefined;
  } else {
    result = {};
    for (let prop in obj) {
      if (prop === "_$visited") continue;
      let val = obj[prop];
      if (replacer) {
        val = replacer(prop, val);
        if (val === undefined) continue;
      }
      val = toJSONSafe(val, replacer);
      if (val === undefined) continue;
      result[prop] = val;
    }
  }
  delete obj._$visited;
  return result;
}
/** Resolves the values of a list of properties by checking each property in multiple sources until a value is found. */
function resolveProperties(sources, propertyNames) {
  let r = {};
  let length = sources.length;
  propertyNames.forEach(function (pn) {
    for (let i = 0; i < length; i++) {
      let src = sources[i];
      if (src) {
        let val = src[pn];
        if (val !== undefined) {
          r[pn] = val;
          break;
        }
      }
    }
  });
  return r;
}
// array functions
function toArray(item) {
  if (item == null) {
    return [];
  } else if (Array.isArray(item)) {
    return item;
  } else {
    return [item];
  }
}
/** a version of Array.map that doesn't require an array, i.e. works on arrays and scalars. */
// function map<T, U>(items: T | T[], fn: (v: T, ix?: number) => U, includeNull?: boolean): U | U[] {
function map(items, fn, includeNull) {
  // whether to return nulls in array of results; default = true;
  includeNull = includeNull == null ? true : includeNull;
  if (items == null) return items;
  // let result: U[];
  if (Array.isArray(items)) {
    let result = [];
    items.forEach(function (v, ix) {
      let r = fn(v, ix);
      if (r != null || includeNull) {
        result[ix] = r;
      }
    });
    return result;
  } else {
    let result = fn(items);
    return result;
  }
}
function arrayFirst(array, predicate) {
  for (let i = 0, j = array.length; i < j; i++) {
    if (predicate(array[i])) {
      return array[i];
    }
  }
  return null;
}
function arrayIndexOf(array, predicate) {
  for (let i = 0, j = array.length; i < j; i++) {
    if (predicate(array[i])) return i;
  }
  return -1;
}
/** Add item if not already in array */
function arrayAddItemUnique(array, item) {
  let ix = array.indexOf(item);
  if (ix === -1) array.push(item);
}
/** Remove items from the array
 * @param array
 * @param predicateOrItem - item to remove, or function to determine matching item
 * @param shouldRemoveMultiple - true to keep removing after first match, false otherwise
 */
function arrayRemoveItem(array, predicateOrItem, shouldRemoveMultiple) {
  let predicate = isFunction(predicateOrItem) ? predicateOrItem : undefined;
  let lastIx = array.length - 1;
  let removed = false;
  for (let i = lastIx; i >= 0; i--) {
    if (predicate ? predicate(array[i]) : array[i] === predicateOrItem) {
      array.splice(i, 1);
      removed = true;
      if (!shouldRemoveMultiple) {
        return true;
      }
    }
  }
  return removed;
}
/** Combine array elements using the callback.  Returns array with length == min(a1.length, a2.length) */
function arrayZip(a1, a2, callback) {
  let result = [];
  let n = Math.min(a1.length, a2.length);
  for (let i = 0; i < n; ++i) {
    result.push(callback(a1[i], a2[i]));
  }
  return result;
}
//function arrayDistinct(array) {
//    array = array || [];
//    let result = [];
//    for (let i = 0, j = array.length; i < j; i++) {
//        if (result.indexOf(array[i]) < 0)
//            result.push(array[i]);
//    }
//    return result;
//}
// Not yet needed
//// much faster but only works on array items with a toString method that
//// returns distinct string for distinct objects.  So this is safe for arrays with primitive
//// types but not for arrays with object types, unless toString() has been implemented.
//function arrayDistinctUnsafe(array) {
//    let o = {}, i, l = array.length, r = [];
//    for (i = 0; i < l; i += 1) {
//        let v = array[i];
//        o[v] = v;
//    }
//    for (i in o) r.push(o[i]);
//    return r;
//}
function arrayEquals(a1, a2, equalsFn) {
  //Check if the arrays are undefined/null
  if (!a1 || !a2) return false;
  if (a1.length !== a2.length) return false;
  //go thru all the vars
  for (let i = 0; i < a1.length; i++) {
    //if the let is an array, we need to make a recursive check
    //otherwise we'll just compare the values
    if (Array.isArray(a1[i])) {
      if (!arrayEquals(a1[i], a2[i])) return false;
    } else {
      if (equalsFn) {
        if (!equalsFn(a1[i], a2[i])) return false;
      } else {
        if (a1[i] !== a2[i]) return false;
      }
    }
  }
  return true;
}
// end of array functions
/** Returns an array for a source and a prop, and creates the prop if needed. */
function getArray(source, propName) {
  let arr = source[propName];
  if (!arr) {
    arr = [];
    source[propName] = arr;
  }
  return arr;
}
/** Calls requireLibCore on semicolon-separated libNames */
function requireLib(libNames, errMessage) {
  let arrNames = libNames.split(";");
  for (let i = 0, j = arrNames.length; i < j; i++) {
    let lib = requireLibCore(arrNames[i]);
    if (lib) return lib;
  }
  if (errMessage) {
    throw new Error("Unable to initialize " + libNames + ".  " + errMessage);
  }
}
/** Returns the 'libName' module if loaded or else returns undefined */
function requireLibCore(libName) {
  let win = window || (global ? global.window : undefined);
  if (!win) return; // Must run in a browser. Todo: add commonjs support
  // get library from browser globals if we can
  let lib = win[libName];
  if (lib) return lib;
  // if require exists, maybe require can get it.
  // This method is synchronous so it can't load modules with AMD.
  // It can only obtain modules from require that have already been loaded.
  // Developer should bootstrap such that the breeze module
  // loads after all other libraries that breeze should find with this method
  // See documentation
  let r = win.require;
  if (r) {
    // if require exists
    if (r.defined) {
      // require.defined is not standard and may not exist
      // require.defined returns true if module has been loaded
      return r.defined(libName) ? r(libName) : undefined;
    } else {
      // require.defined does not exist so we have to call require('libName') directly.
      // The require('libName') overload is synchronous and does not load modules.
      // It throws an exception if the module isn't already loaded.
      try {
        return r(libName);
      } catch (e) {
        // require('libName') threw because module not loaded
        return;
      }
    }
  }
}
/** Execute fn while obj has tempValue for property */
function using(obj, property, tempValue, fn) {
  if (!obj) {
    return fn();
  }
  let originalValue = obj[property];
  if (tempValue === originalValue) {
    return fn();
  }
  obj[property] = tempValue;
  try {
    return fn();
  } finally {
    if (originalValue === undefined) {
      delete obj[property];
    } else {
      obj[property] = originalValue;
    }
  }
}
/** Call state = startFn(), call fn(), call endFn(state) */
function wrapExecution(startFn, endFn, fn) {
  let state;
  try {
    state = startFn();
    return fn();
  } catch (e) {
    if (typeof state === 'object') {
      state.error = e;
    }
    throw e;
  } finally {
    endFn(state);
  }
}
/** Remember & return the value of fn() when it was called with its current args */
function memoize(fn) {
  return function () {
    let args = arraySlice(arguments),
      hash = "",
      i = args.length,
      currentArg = null;
    while (i--) {
      currentArg = args[i];
      hash += currentArg === Object(currentArg) ? JSON.stringify(currentArg) : currentArg;
      fn.memoize || (fn.memoize = {});
    }
    return hash in fn.memoize ? fn.memoize[hash] : fn.memoize[hash] = fn.apply(this, args);
  };
}
function getUuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // tslint:disable-next-line
    let r = Math.random() * 16 | 0,
      v = c == 'x' ? r : r & 0x3 | 0x8;
    return v.toString(16);
  });
}
function durationToSeconds(duration) {
  // basic algorithm from https://github.com/nezasa/iso8601-js-period
  if (typeof duration !== "string") throw new Error("Invalid ISO8601 duration '" + duration + "'");
  // regex splits as follows - grp0, grp1, y, m, d, grp2, h, m, s
  //                           0     1     2  3  4  5     6  7  8
  let struct = /^P((\d+Y)?(\d+M)?(\d+D)?)?(T(\d+H)?(\d+M)?(\d+S)?)?$/.exec(duration);
  if (!struct) throw new Error("Invalid ISO8601 duration '" + duration + "'");
  let ymdhmsIndexes = [2, 3, 4, 6, 7, 8]; // -> grp1,y,m,d,grp2,h,m,s
  let factors = [31104000, 2592000, 86400, 3600, 60, 1]; // second (1)
  let seconds = 0;
  for (let i = 0; i < 6; i++) {
    let digit = struct[ymdhmsIndexes[i]];
    // remove letters, replace by 0 if not defined
    digit = digit ? +digit.replace(/[A-Za-z]+/g, '') : 0;
    seconds += digit * factors[i];
  }
  return seconds;
}
// is functions
function noop() {
  // does nothing
}
function identity(x) {
  return x;
}
function classof(o) {
  if (o === null) {
    return "null";
  }
  if (o === undefined) {
    return "undefined";
  }
  return Object.prototype.toString.call(o).slice(8, -1).toLowerCase();
}
function isDate(o) {
  return classof(o) === "date" && !isNaN(o.getTime());
}
function isDateString(s) {
  // let rx = /^(\d{4}|[+\-]\d{6})(?:-(\d{2})(?:-(\d{2}))?)?(?:T(\d{2}):(\d{2})(?::(\d{2})(?:\.(\d{3}))?)?(?:(Z)|([+\-])(\d{2})(?::(\d{2}))?)?)?$/;
  let rx = /^((\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z)))$/;
  return typeof s === "string" && rx.test(s);
}
function isFunction(o) {
  return classof(o) === "function";
}
// function isString(o: any) {
//     return (typeof o === "string");
// }
// function isObject(o: any) {
//     return (typeof o === "object");
// }
function isGuid(value) {
  return typeof value === "string" && /[a-fA-F\d]{8}-(?:[a-fA-F\d]{4}-){3}[a-fA-F\d]{12}/.test(value);
}
function isDuration(value) {
  return typeof value === "string" && /^(-|)?P[T]?[\d\.,\-]+[YMDTHS]/.test(value);
}
function isEmpty(obj) {
  if (obj === null || obj === undefined) {
    return true;
  }
  for (let key in obj) {
    if (hasOwnProperty(obj, key)) {
      return false;
    }
  }
  return true;
}
function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
// end of is Functions
// string functions
function stringStartsWith(str, prefix) {
  // returns true for empty string or null prefix
  if (!str) return false;
  if (prefix === "" || prefix == null) return true;
  return str.indexOf(prefix, 0) === 0;
}
function stringEndsWith(str, suffix) {
  // returns true for empty string or null suffix
  if (!str) return false;
  if (suffix === "" || suffix == null) return true;
  return str.indexOf(suffix, str.length - suffix.length) !== -1;
}
// Based on fragment from Dean Edwards' Base 2 library
/** format("a %1 and a %2", "cat", "dog") -> "a cat and a dog" */
function formatString(str, ...params) {
  let args = arguments;
  let pattern = RegExp("%([1-" + (arguments.length - 1) + "])", "g");
  return str.replace(pattern, function (match, index) {
    return args[index];
  });
}
// See http://stackoverflow.com/questions/7225407/convert-camelcasetext-to-camel-case-text
/** Change text to title case with spaces, e.g. 'myPropertyName12' to 'My Property Name 12' */
let camelEdges = /([A-Z](?=[A-Z][a-z])|[^A-Z](?=[A-Z])|[a-zA-Z](?=[^a-zA-Z]))/g;
function titleCaseSpace(text) {
  text = text.replace(camelEdges, '$1 ');
  text = text.charAt(0).toUpperCase() + text.slice(1);
  return text;
}
// end of string functions
// See Mark Miller’s explanation of what this does.
// http://wiki.ecmascript.org/doku.php?id=conventions:safe_meta_programming
function uncurry(f) {
  let call = Function.call;
  return function () {
    return call.apply(f, arguments);
  };
}
// shims
if (!Object.create) {
  Object.create = function (parent) {
    let F = function () {};
    F.prototype = parent;
    return new F();
  };
}
// strings for error messages
const strings = {
  "TO_TYPE": "Add 'EntityQuery.toType()' to your query, or call 'MetadataStore.setEntityTypeForResourceName()' to register an EntityType for this resourceName."
};
// // not all methods above are exported
const core = {
  isES5Supported: isES5Supported,
  hasOwnProperty: hasOwnProperty,
  getOwnPropertyValues: getOwnPropertyValues,
  getPropertyDescriptor: getPropDescriptor,
  objectForEach: objectForEach,
  objectFirst: objectFirst,
  objectMap: objectMap,
  extend: extend,
  propEq: propEq,
  propsEq: propsEq,
  pluck: pluck,
  map: map,
  resolveProperties: resolveProperties,
  setAsDefault: setAsDefault,
  updateWithDefaults: updateWithDefaults,
  getArray: getArray,
  toArray: toArray,
  arrayEquals: arrayEquals,
  arraySlice: arraySlice,
  arrayFirst: arrayFirst,
  arrayIndexOf: arrayIndexOf,
  arrayRemoveItem: arrayRemoveItem,
  arrayZip: arrayZip,
  arrayAddItemUnique: arrayAddItemUnique,
  arrayFlatMap: arrayFlatMap,
  requireLib: requireLib,
  using: using,
  wrapExecution: wrapExecution,
  memoize: memoize,
  getUuid: getUuid,
  durationToSeconds: durationToSeconds,
  isSettable: isSettable,
  isDate: isDate,
  isDateString: isDateString,
  isGuid: isGuid,
  isDuration: isDuration,
  isFunction: isFunction,
  isEmpty: isEmpty,
  isNumeric: isNumeric,
  identity: identity,
  noop: noop,
  stringStartsWith: stringStartsWith,
  stringEndsWith: stringEndsWith,
  formatString: formatString,
  titleCase: titleCaseSpace,
  toJson: toJson,
  toJSONSafe: toJSONSafe,
  toJSONSafeReplacer: toJSONSafeReplacer,
  strings: strings
};
// Unused
/*
// returns true for booleans, numbers, strings and dates
// false for null, and non-date objects, functions, and arrays
function isPrimitive(obj: any) {
    if (obj == null) return false;
    // true for numbers, strings, booleans and null, false for objects
    if (obj != Object(obj)) return true;
    return isDate(obj);
}

*/

/** @hidden @internal */
class Param {
  constructor(v, name) {
    /** @hidden @internal */
    this._applyOne = function (instance) {
      if (this.v !== undefined) {
        instance[this.name] = this.v;
      } else {
        if (this.defaultValue !== undefined) {
          instance[this.name] = this.defaultValue;
        }
      }
    };
    this.MESSAGE_PREFIX = "The '%1' parameter ";
    this.v = v;
    this.name = name;
    this._contexts = [null];
  }
  isObject() {
    return this.isTypeOf('object');
  }
  isBoolean() {
    return this.isTypeOf('boolean');
  }
  isString() {
    return this.isTypeOf('string');
  }
  isNumber() {
    return this.isTypeOf('number');
  }
  isFunction() {
    return this.isTypeOf('function');
  }
  isNonEmptyString() {
    return addContext(this, {
      fn: isNonEmptyString,
      msg: "must be a nonEmpty string"
    });
  }
  isTypeOf(typeName) {
    return addContext(this, {
      fn: isTypeOf,
      typeName: typeName,
      msg: "must be a '" + typeName + "'"
    });
  }
  isInstanceOf(type, typeName) {
    typeName = typeName || type.prototype._$typeName;
    return addContext(this, {
      fn: isInstanceOf,
      type: type,
      typeName: typeName,
      msg: "must be an instance of '" + typeName + "'"
    });
  }
  hasProperty(propertyName) {
    return addContext(this, {
      fn: hasProperty,
      propertyName: propertyName,
      msg: "must have a '" + propertyName + "' property"
    });
  }
  isEnumOf(enumType) {
    return addContext(this, {
      fn: isEnumOf,
      enumType: enumType,
      msg: "must be an instance of the '" + (enumType.name || 'unknown') + "' enumeration"
    });
  }
  isRequired(allowNull = false) {
    return addContext(this, {
      fn: isRequired,
      allowNull: allowNull,
      msg: "is required"
    });
  }
  isOptional() {
    let context = {
      fn: isOptional,
      prevContext: null,
      msg: isOptionalMessage
    };
    return addContext(this, context);
  }
  isNonEmptyArray() {
    return this.isArray(true);
  }
  isArray(mustNotBeEmpty) {
    let context = {
      fn: isArray,
      mustNotBeEmpty: mustNotBeEmpty,
      prevContext: null,
      msg: isArrayMessage
    };
    return addContext(this, context);
  }
  or() {
    this._contexts.push(null);
    this._context = null;
    return this;
  }
  check(defaultValue) {
    let ok = exec(this);
    if (ok === undefined) return;
    if (!ok) {
      throw new Error(this.getMessage());
    }
    if (this.v !== undefined) {
      return this.v;
    } else {
      return defaultValue;
    }
  }
  /** @hidden @internal */
  // called from outside this file.
  _addContext(context) {
    return addContext(this, context);
  }
  getMessage() {
    let that = this;
    let message = this._contexts.map(function (context) {
      return getMessage(context, that.v);
    }).join(", or it ");
    return core.formatString(this.MESSAGE_PREFIX, this.name) + " " + message;
  }
  withDefault(defaultValue) {
    this.defaultValue = defaultValue;
    return this;
  }
  whereParam(propName) {
    return this.parent.whereParam(propName);
  }
  applyAll(instance, checkOnly = false) {
    let parentTypeName = instance._$typeName;
    let allowUnknownProperty = parentTypeName && this.parent.config._$typeName === parentTypeName;
    let clone = core.extend({}, this.parent.config);
    this.parent.params.forEach(function (p) {
      if (!allowUnknownProperty) delete clone[p.name];
      try {
        p.check();
      } catch (e) {
        throwConfigError(instance, e.message);
      }
      !checkOnly && p._applyOne(instance);
    });
    // should be no properties left in the clone
    if (!allowUnknownProperty) {
      for (let key in clone) {
        // allow props with an undefined value
        if (clone[key] !== undefined) {
          throwConfigError(instance, core.formatString("Unknown property: '%1'.", key));
        }
      }
    }
  }
}
/** @hidden @internal */
let assertParam = function (v, name) {
  return new Param(v, name);
};
function isTypeOf(context, v) {
  if (v == null) return false;
  if (typeof v === context.typeName) return true;
  return false;
}
function isNonEmptyString(context, v) {
  if (v == null) return false;
  return typeof v === 'string' && v.length > 0;
}
function isInstanceOf(context, v) {
  if (v == null || context.type == null) return false;
  return v instanceof context.type;
}
function isEnumOf(context, v) {
  if (v == null || context.enumType == null) return false;
  return context.enumType.contains(v);
}
function hasProperty(context, v) {
  if (v == null || context.propertyName == null) return false;
  return v[context.propertyName] !== undefined;
}
function isRequired(context, v) {
  if (context.allowNull) {
    return v !== undefined;
  } else {
    return v != null;
  }
}
function isOptional(context, v) {
  if (v == null) return true;
  let prevContext = context.prevContext;
  if (prevContext && prevContext.fn) {
    return prevContext.fn(prevContext, v);
  } else {
    return true;
  }
}
function isOptionalMessage(context, v) {
  let prevContext = context.prevContext;
  let element = prevContext ? " or it " + getMessage(prevContext, v) : "";
  return "is optional" + element;
}
function isArray(context, v) {
  if (!Array.isArray(v)) {
    return false;
  }
  if (context.mustNotBeEmpty) {
    if (v.length === 0) return false;
  }
  // allow standalone is array call.
  let prevContext = context.prevContext;
  if (!prevContext) return true;
  let pc = prevContext;
  return v.every(function (v1) {
    return pc.fn && pc.fn(pc, v1);
  });
}
function isArrayMessage(context, v) {
  let arrayDescr = context.mustNotBeEmpty ? "a nonEmpty array" : "an array";
  let prevContext = context.prevContext;
  let element = prevContext ? " where each element " + getMessage(prevContext, v) : "";
  return " must be " + arrayDescr + element;
}
function getMessage(context, v) {
  let msg = context.msg;
  if (typeof msg === "function") {
    msg = msg(context, v);
  }
  return msg;
}
function addContext(that, context) {
  if (that._context) {
    let curContext = that._context;
    while (curContext.prevContext != null) {
      curContext = curContext.prevContext;
    }
    if (curContext.prevContext === null) {
      curContext.prevContext = context;
      // just update the prevContext but don't change the curContext.
      return that;
    } else if (context.prevContext == null) {
      context.prevContext = that._context;
    } else {
      throw new Error("Illegal construction - use 'or' to combine checks");
    }
  }
  return setContext(that, context);
}
function setContext(that, context) {
  that._contexts[that._contexts.length - 1] = context;
  that._context = context;
  return that;
}
function exec(self) {
  // clear off last one if null
  let contexts = self._contexts;
  if (contexts[contexts.length - 1] == null) {
    contexts.pop();
  }
  if (contexts.length === 0) {
    return undefined;
  }
  return contexts.some(function (context) {
    return context.fn ? context.fn(context, self.v) : false;
  });
}
function throwConfigError(instance, message) {
  throw new Error(core.formatString("Error configuring an instance of '%1'. %2", instance && instance._$typeName || "object", message));
}
class ConfigParam {
  constructor(config) {
    if (typeof config !== "object") {
      throw new Error("Configuration parameter should be an object, instead it is a: " + typeof config);
    }
    this.config = config;
    this.params = [];
  }
  whereParam(propName) {
    let param = new Param(this.config[propName], propName);
    param.parent = this;
    this.params.push(param);
    return param;
  }
}
/** @hidden @internal */
let assertConfig = function (config) {
  return new ConfigParam(config);
};
// Param is exposed so that additional 'is' methods can be added to the prototype.
core.Param = Param;
core.assertParam = assertParam;
core.assertConfig = assertConfig;
function publishCore(that, data, errorCallback) {
  let subscribers = that._subscribers;
  if (!subscribers) return true;
  // subscribers from outer scope.
  subscribers.forEach(function (s) {
    try {
      s.callback(data);
    } catch (e) {
      e.context = "unable to publish on topic: " + that.name;
      if (errorCallback) {
        errorCallback(e);
      } else if (that._defaultErrorCallback) {
        that._defaultErrorCallback(e);
      } else {
        fallbackErrorHandler(e);
      }
    }
  });
}
function fallbackErrorHandler(e) {
  // TODO: maybe log this
  // for now do nothing;
}
/**
Class to support basic event publication and subscription semantics.
@dynamic
**/
class BreezeEvent {
  /**
  Constructor for an Event
  >     salaryEvent = new BreezeEvent("salaryEvent", person);
  @param name - The name of the event.
  @param publisher - The object that will be doing the publication. i.e. the object to which this event is attached.
  @param defaultErrorCallback - Function to call when an error occurs during subscription execution.
  If omitted then subscriber notification failures will be ignored.
  **/
  constructor(name, publisher, defaultErrorCallback) {
    /**
    Unsubscribe from this event.
    >      // Assume order is a preexisting 'order' entity
    >      let token = order.entityAspect.propertyChanged.subscribe(function (pcEvent) {
    >              // do something
    >      });
    >      // sometime later
    >      order.entityAspect.propertyChanged.unsubscribe(token);
    @param unsubKey - The value returned from the 'subscribe' method may be used to unsubscribe here.
    @return Whether unsubscription occured. This will return false if already unsubscribed or if the key simply
    cannot be found.
    **/
    this.unsubscribe = function (unsubKey) {
      if (!this._subscribers) return false;
      let subs = this._subscribers;
      let ix = core.arrayIndexOf(subs, function (s) {
        return s.unsubKey === unsubKey;
      });
      if (ix !== -1) {
        subs.splice(ix, 1);
        if (subs.length === 0) {
          this._subscribers = null;
        }
        return true;
      } else {
        return false;
      }
    };
    assertParam(name, "eventName").isNonEmptyString().check();
    assertParam(publisher, "publisher").isObject().check();
    this.name = name;
    // register the name
    BreezeEvent.__eventNameMap[name] = true;
    this.publisher = publisher;
    if (defaultErrorCallback) {
      this._defaultErrorCallback = defaultErrorCallback;
    }
  }
  /**
  Publish data for this event.
  >      // Assume 'salaryEvent' is previously constructed Event
  >      salaryEvent.publish( { eventType: "payRaise", amount: 100 });
      This event can also be published asychronously
  >      salaryEvent.publish( { eventType: "payRaise", amount: 100 }, true);
      And we can add a handler in case the subscriber 'mishandles' the event.
  >      salaryEvent.publish( { eventType: "payRaise", amount: 100 }, true, function(error) {
  >          // do something with the 'error' object
  >      });
  @param data - Data to publish
  @param publishAsync - (default=false) Whether to publish asynchonously or not.
  @param errorCallback - Function to be called for any errors that occur during publication. If omitted,
  errors will be eaten.
  @return false if event is disabled; true otherwise.
  **/
  publish(data, publishAsync = false, errorCallback) {
    if (!BreezeEvent._isEnabled(this.name, this.publisher)) return false;
    if (publishAsync === true) {
      setTimeout(publishCore, 0, this, data, errorCallback);
    } else {
      publishCore(this, data, errorCallback);
    }
    return true;
  }
  /**
  Publish data for this event asynchronously.
  >      // Assume 'salaryEvent' is previously constructed Event
  >      salaryEvent.publishAsync( { eventType: "payRaise", amount: 100 });
      And we can add a handler in case the subscriber 'mishandles' the event.
  >      salaryEvent.publishAsync( { eventType: "payRaise", amount: 100 }, function(error) {
  >          // do something with the 'error' object
  >      });
  @param data - Data to publish
  @param errorCallback - Function to be called for any errors that occur during publication. If omitted,
  errors will be eaten.
  **/
  publishAsync(data, errorCallback) {
    this.publish(data, true, errorCallback);
  }
  /**
  Subscribe to this event.
  >      // Assume 'salaryEvent' is previously constructed Event
  >      salaryEvent.subscribe(function (eventArgs) {
  >          if (eventArgs.eventType === "payRaise") {
  >              // do something
  >          }
  >      });
      There are several built in Breeze events, such as [[EntityAspect.propertyChanged]], [[EntityAspect.validationErrorsChanged]] as well.
  >      // Assume order is a preexisting 'order' entity
  >      order.entityAspect.propertyChanged.subscribe(function (pcEvent) {
  >          if ( pcEvent.propertyName === "OrderDate") {
  >              // do something
  >          }
  >      });
  @param callback- Function to be called whenever 'data' is published for this event.
  @param callback.data - {Object} Whatever 'data' was published.  This should be documented on the specific event.
  @return This is a key for 'unsubscription'.  It can be passed to the 'unsubscribe' method.
  **/
  subscribe(callback) {
    if (!this._subscribers) {
      this._subscribers = [];
    }
    let unsubKey = BreezeEvent.__nextUnsubKey;
    this._subscribers.push({
      unsubKey: unsubKey,
      callback: callback
    });
    ++BreezeEvent.__nextUnsubKey;
    return unsubKey;
  }
  /** remove all subscribers */
  clear() {
    this._subscribers = null;
  }
  /** event bubbling - document later. */
  // null or undefined 'getParentFn' means Event does not need to bubble i.e. that it is always enabled - .
  static bubbleEvent(target, getParentFn) {
    target._getEventParent = getParentFn || null;
  }
  /**
  Enables or disables the named event for an object and all of its children.
  >      BreezeEvent.enable(“propertyChanged”, myEntityManager, false)
      will disable all EntityAspect.propertyChanged events within a EntityManager.
  >      BreezeEvent.enable(“propertyChanged”, myEntityManager, true)
      will enable all EntityAspect.propertyChanged events within a EntityManager.
  >      BreezeEvent.enable(“propertyChanged”, myEntity.entityAspect, false)
      will disable EntityAspect.propertyChanged events for a specific entity.
  >      BreezeEvent.enable(“propertyChanged”, myEntity.entityAspect, null)
      will removes any enabling / disabling at the entity aspect level so now any 'Event.enable' calls at the EntityManager level,
  made either previously or in the future, will control notification.
  >      BreezeEvent.enable(“validationErrorsChanged”, myEntityManager, function(em) {
  >          return em.customTag === “blue”;
  >      })
    
  will either enable or disable myEntityManager based on the current value of a ‘customTag’ property on myEntityManager.
  Note that this is dynamic, changing the customTag value will cause events to be enabled or disabled immediately.
  @param eventName - The name of the event.
  @param target - The object at which enabling or disabling will occur.  All event notifications that occur to this object or
  children of this object will be enabled or disabled.
  @param isEnabled - A boolean, a null or a function that returns either a boolean or a null.
  **/
  static enable(eventName, obj, isEnabled) {
    assertParam(eventName, "eventName").isNonEmptyString().check();
    assertParam(obj, "obj").isObject().check();
    assertParam(isEnabled, "isEnabled").isBoolean().isOptional().or().isFunction().check();
    let ob = obj;
    if (!ob._$eventMap) {
      ob._$eventMap = {};
    }
    ob._$eventMap[eventName] = isEnabled;
  }
  /**
  Returns whether for a specific event and a specific object and its children, notification is enabled or disabled or not set.
  >      BreezeEvent.isEnabled(“propertyChanged”, myEntityManager)
  >
  @param eventName - The name of the event.
  @param target - The object for which we want to know if notifications are enabled.
  @return A null is returned if this value has not been set.
  **/
  static isEnabled(eventName, obj) {
    assertParam(eventName, "eventName").isNonEmptyString().check();
    assertParam(obj, "obj").isObject().check();
    // null is ok - it just means that the object is at the top level.
    if (obj._getEventParent === undefined) {
      throw new Error("This object does not support event enabling/disabling");
    }
    // return ctor._isEnabled(getFullEventName(eventName), obj);
    return BreezeEvent._isEnabled(eventName, 3);
  }
}
/** @hidden @internal */
BreezeEvent.__eventNameMap = {};
/** @hidden @internal */
BreezeEvent.__nextUnsubKey = 1;
/** @hidden @internal */
BreezeEvent._isEnabled = function (eventName, obj) {
  let isEnabled = null;
  let ob = obj;
  let eventMap = ob._$eventMap;
  if (eventMap) {
    isEnabled = eventMap[eventName];
  }
  if (isEnabled != null) {
    if (typeof isEnabled === 'function') {
      return !!isEnabled(obj);
    } else {
      return !!isEnabled;
    }
  } else {
    let parent = ob._getEventParent && ob._getEventParent();
    if (parent) {
      return !!this._isEnabled(eventName, parent);
    } else {
      // default if not explicitly disabled.
      return true;
    }
  }
};
// legacy support - deliberately not typed
core.Event = BreezeEvent;
class InterfaceDef {
  constructor(name) {
    this.name = name;
    this.defaultInstance = undefined;
    this._implMap = {};
  }
  /** Define an implementation of the given adaptername */
  registerCtor(adapterName, ctor) {
    this._implMap[adapterName.toLowerCase()] = {
      ctor: ctor,
      defaultInstance: undefined
    };
  }
  /** Return the definition for the given adapterName */
  getImpl(adapterName) {
    return this._implMap[adapterName.toLowerCase()];
  }
  /** Return the first implementation for this InterfaceDef */
  getFirstImpl() {
    let kv = core.objectFirst(this._implMap, function () {
      return true;
    });
    return kv ? kv.value : null;
  }
  getDefaultInstance() {
    return this.defaultInstance;
  }
}
class BreezeConfig {
  constructor() {
    this.functionRegistry = {};
    this.typeRegistry = {};
    this.objectRegistry = {};
    this.stringifyPad = '';
    this.interfaceInitialized = new BreezeEvent("interfaceInitialized", this);
    if (this.noEval === undefined) {
      try {
        Error['x'] = Function('');
        this.noEval = false; // eval succeeded
      } catch (_a) {
        this.noEval = true; // eval failed, probably due to CSP
      }
    }
  }
  /**
  Method use to register implementations of standard breeze interfaces.  Calls to this method are usually
  made as the last step within an adapter implementation.
  @method registerAdapter
  @param interfaceName {String} - one of the following interface names: "ajax", "dataService", "modelLibrary", "uriBuilder"
  @param adapterCtor {Function} - an ctor function that returns an instance of the specified interface.
  **/
  registerAdapter(interfaceName, adapterCtor) {
    assertParam(interfaceName, "interfaceName").isNonEmptyString().check();
    assertParam(adapterCtor, "adapterCtor").isFunction().check();
    // this impl will be thrown away after the name is retrieved.
    let impl = new adapterCtor();
    let implName = impl.name;
    if (!implName) {
      throw new Error("Unable to locate a 'name' property on the constructor passed into the 'registerAdapter' call.");
    }
    let idef = this.getInterfaceDef(interfaceName);
    idef.registerCtor(implName, adapterCtor);
  }
  /**
  Returns the ctor function used to implement a specific interface with a specific adapter name.
  @method getAdapter
  @param interfaceName {String} One of the following interface names: "ajax", "dataService", "modelLibrary", "uriBuilder"
  @param [adapterName] {String} The name of any previously registered adapter. If this parameter is omitted then
  this method returns the "default" adapter for this interface. If there is no default adapter, then a null is returned.
  @return {Function|null} Returns either a ctor function or null.
  **/
  getAdapter(interfaceName, adapterName) {
    let idef = this.getInterfaceDef(interfaceName);
    if (adapterName) {
      let impl = idef.getImpl(adapterName);
      return impl ? impl.ctor : null;
    } else {
      return idef.defaultInstance ? idef.defaultInstance._$impl.ctor : null;
    }
  }
  /**
  Initializes a single adapter implementation. Initialization means either newing a instance of the
  specified interface and then calling "initialize" on it or simply calling "initialize" on the instance
  if it already exists.
  @method initializeAdapterInstance
  @param interfaceName {String} The name of the interface to which the adapter to initialize belongs.
  @param adapterName {String} - The name of a previously registered adapter to initialize.
  @param [isDefault=true] {Boolean} - Whether to make this the default "adapter" for this interface.
  @return {an instance of the specified adapter}
  **/
  initializeAdapterInstance(interfaceName, adapterName, isDefault = true) {
    isDefault = isDefault === undefined ? true : isDefault;
    assertParam(interfaceName, "interfaceName").isNonEmptyString().check();
    assertParam(adapterName, "adapterName").isNonEmptyString().check();
    assertParam(isDefault, "isDefault").isBoolean().check();
    let idef = this.getInterfaceDef(interfaceName);
    let impl = idef.getImpl(adapterName);
    if (!impl) {
      throw new Error("Unregistered adapter.  Interface: " + interfaceName + " AdapterName: " + adapterName);
    }
    return this._initializeAdapterInstanceCore(idef, impl, isDefault);
  }
  /**
  Returns the adapter instance corresponding to the specified interface and adapter names.
  @method getAdapterInstance
  @param interfaceName {String} The name of the interface.
  @param [adapterName] {String} - The name of a previously registered adapter.  If this parameter is
  omitted then the default implementation of the specified interface is returned. If there is
  no defaultInstance of this interface, then the first registered instance of this interface is returned.
  @return {an instance of the specified adapter}
  @internal
  **/
  getAdapterInstance(interfaceName, adapterName) {
    let idef = this.getInterfaceDef(interfaceName);
    let impl;
    let isDefault = adapterName == null || adapterName === "";
    if (isDefault) {
      if (idef.defaultInstance) return idef.defaultInstance;
      impl = idef.getFirstImpl();
    } else {
      impl = idef.getImpl(adapterName);
    }
    if (!impl) return undefined;
    if (impl.defaultInstance) {
      return impl.defaultInstance;
    } else {
      return this._initializeAdapterInstanceCore(idef, impl, isDefault);
    }
  }
  /** this is needed for reflection purposes when deserializing an object that needs a fn or ctor.
      Used to register validators. */
  registerFunction(fn, fnName) {
    assertParam(fn, "fn").isFunction().check();
    assertParam(fnName, "fnName").isString().check();
    if (fn.prototype) {
      fn.prototype._$fnName = fnName;
    }
    this.functionRegistry[fnName] = fn;
  }
  registerType(ctor, typeName) {
    assertParam(ctor, "ctor").isFunction().check();
    assertParam(typeName, "typeName").isString().check();
    if (ctor.prototype) {
      ctor.prototype._$typeName = typeName;
    }
    this.typeRegistry[typeName] = ctor;
  }
  getRegisteredFunction(fnName) {
    return this.functionRegistry[fnName];
  }
  getInterfaceDef(interfaceName) {
    let lcName = interfaceName.toLowerCase();
    // source may be null
    let kv = core.objectFirst(this._interfaceRegistry || {}, function (k, v) {
      return k.toLowerCase() === lcName;
    });
    if (!kv) {
      throw new Error("Unknown interface name: " + interfaceName);
    }
    return kv.value;
  }
  /** @deprecated @internal no-op kept for backward compatibility */
  setQ(q) {
    console && console.warn("setQ does nothing; ES6 Promise support is required - use a shim if necessary.");
  }
  /** @hidden @internal */
  _storeObject(obj, type, name) {
    // uncomment this if we make this public.
    //assertParam(obj, "obj").isObject().check();
    //assertParam(name, "objName").isString().check();
    let key = (typeof type === "string" ? type : type.prototype._$typeName) + "." + name;
    this.objectRegistry[key] = obj;
  }
  /** @hidden @internal */
  _fetchObject(type, name) {
    if (!name) return undefined;
    let key = (typeof type === "string" ? type : type.prototype._$typeName) + "." + name;
    let result = this.objectRegistry[key];
    if (!result) {
      throw new Error("Unable to locate a registered object by the name: " + key);
    }
    return result;
  }
  /** @hidden @internal */
  _initializeAdapterInstanceCore(interfaceDef, impl, isDefault) {
    let instance;
    let inst = impl.defaultInstance;
    if (!inst) {
      instance = new impl.ctor();
      impl.defaultInstance = instance;
      instance._$impl = impl;
    } else {
      instance = inst;
    }
    instance.initialize();
    if (isDefault) {
      // next line needs to occur before any recomposition
      interfaceDef.defaultInstance = instance;
    }
    // recomposition of other impls will occur here.
    this.interfaceInitialized.publish({
      interfaceName: interfaceDef.name,
      instance: instance,
      isDefault: true
    });
    if (instance.checkForRecomposition != null) {
      // now register for own dependencies.
      this.interfaceInitialized.subscribe(interfaceInitializedArgs => {
        // TODO: why '!'s needed here for typescript to compile correctly???
        instance.checkForRecomposition(interfaceInitializedArgs);
      });
    }
    return instance;
  }
}
const config = new BreezeConfig();
// legacy
core.config = config;

/**
A DataService instance is used to encapsulate the details of a single 'service'; this includes a serviceName, a dataService adapterInstance,
and whether the service has server side metadata.

You can construct an EntityManager with either a serviceName or a DataService instance, if you use a serviceName then a DataService
is constructed for you.  (It can also be set via the EntityManager.setProperties method).

The same applies to the MetadataStore.fetchMetadata method, i.e. it takes either a serviceName or a DataService instance.

Each metadataStore contains a list of DataServices, each accessible via its ‘serviceName’.
( see MetadataStore.getDataService and MetadataStore.addDataService).  The ‘addDataService’ method is called internally
anytime a MetadataStore.fetchMetadata call occurs with a new dataService ( or service name).

**/
class DataService {
  /**   DataService constructor
  >     var dataService = new DataService({
  >         serviceName: altServiceName,
  >         hasServerMetadata: false
  >     });
      >     var metadataStore = new MetadataStore({
  >         namingConvention: NamingConvention.camelCase
  >     });
      >     return new EntityManager({
  >         dataService: dataService,
  >         metadataStore: metadataStore
  >     });
  @param config - A configuration object.
  **/
  constructor(config) {
    updateWithConfig(this, config);
  }
  /**
  Returns a copy of this DataService with the specified properties applied.
  @param config - The configuration object to apply to create a new DataService.
  **/
  using(config) {
    if (!config) return this;
    let result = new DataService(this);
    return updateWithConfig(result, config);
  }
  static resolve(dataServices) {
    // final defaults
    // Deliberate use of 'as any' below.
    dataServices.push({
      hasServerMetadata: true,
      useJsonp: false
    });
    let ds = new DataService(core.resolveProperties(dataServices, ["serviceName", "adapterName", "uriBuilderName", "hasServerMetadata", "jsonResultsAdapter", "useJsonp"]));
    if (!ds.serviceName) {
      throw new Error("Unable to resolve a 'serviceName' for this dataService");
    }
    ds.adapterInstance = ds.adapterInstance || config.getAdapterInstance("dataService", ds.adapterName);
    ds.jsonResultsAdapter = ds.jsonResultsAdapter || ds.adapterInstance.jsonResultsAdapter;
    ds.uriBuilder = ds.uriBuilder || config.getAdapterInstance("uriBuilder", ds.uriBuilderName);
    return ds;
  }
  /** @hidden @internal */
  static _normalizeServiceName(serviceName) {
    serviceName = serviceName.trim();
    if (serviceName.substr(-1) !== "/") {
      return serviceName + '/';
    } else {
      return serviceName;
    }
  }
  /**  */
  toJSON() {
    // don't use default value here - because we want to be able to distinguish undefined props for inheritence purposes.
    return core.toJson(this, {
      serviceName: null,
      adapterName: null,
      uriBuilderName: null,
      hasServerMetadata: null,
      jsonResultsAdapter: function (v) {
        return v && v.name;
      },
      useJsonp: null
    });
  }
  static fromJSON(json) {
    json.jsonResultsAdapter = config._fetchObject(JsonResultsAdapter, json.jsonResultsAdapter);
    return new DataService(json);
  }
  /**
   Returns a url for this dataService with the specified suffix. This method handles dataService names either
   with or without trailing '/'s.  If the suffix starts with "http" then it will be returned as-is.
   @method qualifyUrl
   @param suffix {String} The resulting url.
   @return {a Url string}
   **/
  qualifyUrl(suffix) {
    if (suffix && suffix.startsWith("http")) {
      return suffix;
    }
    let url = this.serviceName;
    // remove any trailing "/"
    if (core.stringEndsWith(url, "/")) {
      url = url.substr(0, url.length - 1);
    }
    // ensure that it ends with "/" + suffix
    suffix = "/" + suffix;
    if (!core.stringEndsWith(url, suffix)) {
      url = url + suffix;
    }
    return url;
  }
}
DataService.prototype._$typeName = "DataService";
function updateWithConfig(obj, dsConfig) {
  if (dsConfig) {
    assertConfig(dsConfig).whereParam("serviceName").isOptional().whereParam("adapterName").isString().isOptional().whereParam("uriBuilderName").isString().isOptional().whereParam("hasServerMetadata").isBoolean().isOptional().whereParam("jsonResultsAdapter").isInstanceOf(JsonResultsAdapter).isOptional().whereParam("useJsonp").isBoolean().isOptional().applyAll(obj);
    obj.serviceName = obj.serviceName && DataService._normalizeServiceName(obj.serviceName);
    obj.adapterInstance = obj.adapterName ? config.getAdapterInstance("dataService", obj.adapterName) : undefined;
    obj.uriBuilder = obj.uriBuilderName ? config.getAdapterInstance("uriBuilder", obj.uriBuilderName) : undefined;
  }
  return obj;
}
/**
A JsonResultsAdapter instance is used to provide custom extraction and parsing logic on the json results returned by any web service.
This facility makes it possible for breeze to talk to virtually any web service and return objects that will be first class 'breeze' citizens.
**/
class JsonResultsAdapter {
  /**
  JsonResultsAdapter constructor
      @example
      //
      var jsonResultsAdapter = new JsonResultsAdapter({
          name: "test1e",
          extractResults: function(json) {
              return json.results;
          },
          visitNode: function(node, mappingContext, nodeContext) {
              var entityType = normalizeTypeName(node.$type);
              var propertyName = nodeContext.propertyName;
              var ignore = propertyName && propertyName.substr(0, 1) === "$";
                  return {
                  entityType: entityType,
                  nodeId: node.$id,
                  nodeRefId: node.$ref,
                  ignore: ignore,
                  passThru: false // default
              };
          }
      });
          var dataService = new DataService( {
              serviceName: "breeze/foo",
              jsonResultsAdapter: jsonResultsAdapter
      });
          var entityManager = new EntityManager( {
          dataService: dataService
      });
      @param config - A configuration object.
      **/
  constructor(jsConfig) {
    if (arguments.length !== 1) {
      throw new Error("The JsonResultsAdapter ctor should be called with a single argument that is a configuration object.");
    }
    assertConfig(jsConfig).whereParam("name").isNonEmptyString().whereParam("extractResults").isFunction().isOptional().withDefault(extractResultsDefault).whereParam("extractSaveResults").isFunction().isOptional().withDefault(extractSaveResultsDefault).whereParam("extractKeyMappings").isFunction().isOptional().withDefault(extractKeyMappingsDefault).whereParam("extractDeletedKeys").isFunction().isOptional().withDefault(extractDeletedKeysDefault).whereParam("visitNode").isFunction().applyAll(this);
    config._storeObject(this, "JsonResultsAdapter", this.name);
  }
}
JsonResultsAdapter.prototype._$typeName = "JsonResultsAdapter";
function extractResultsDefault(data) {
  return data.results;
}
function extractSaveResultsDefault(data) {
  return data.entities || data.Entities || [];
}
function extractKeyMappingsDefault(data) {
  return data.keyMappings || data.KeyMappings || [];
}
function extractDeletedKeysDefault(data) {
  return data.deletedKeys || data.DeletedKeys || [];
}
const INT16_MIN = -32768;
const INT16_MAX = 32767;
const INT32_MIN = -2147483648;
const INT32_MAX = 2147483647;
const BYTE_MIN = 0;
const BYTE_MAX = 255;
const ɵ0$1 = function (context) {
  if (context.property) {
    return context.property.resolveProperty("displayName") || context.propertyName || context.property.name;
  } else {
    return "Value";
  }
};
// add common props and methods for every validator 'context' here.
let rootContext = {
  displayName: ɵ0$1
};
/**
Instances of the Validator class provide the logic to validate another object and provide a description of any errors
encountered during the validation process.  They are typically associated with a 'validators' property on the following types: [[EntityType]],
[[DataProperty]] or [[NavigationProperty]].

A number of property level validators are registered automatically, i.e added to each DataProperty.validators property
based on [[DataProperty]] metadata.  For example,

- DataProperty.dataType -> one of the 'dataType' validator methods such as Validator.int64, Validator.date, Validator.bool etc.
- DataProperty.maxLength -> Validator.maxLength
- DataProperty.isNullable -> Validator.required (if not nullable)

@class Validator
**/
/**
Validator constructor - This method is used to create create custom validations.  Several
basic "Validator" construction methods are also provided as static methods to this class. These methods
provide a simpler syntax for creating basic validations.

Many of these stock validators are inspired by and implemented to conform to the validators defined at
http://msdn.microsoft.com/en-us/library/system.componentmodel.dataannotations.aspx

Sometimes a custom validator will be required.
@example
Most validators will be 'property' level validators, like this.
@example
    // v is this function is the value to be validated, in this case a "country" string.
    var valFn = function (v) {
        if (v == null) return true;
        return (core.stringStartsWith(v, "US"));
    };
    var countryValidator = new Validator("countryIsUS", valFn, {
        displayName: "Country",
        messageTemplate: "'%displayName%' must start with 'US'"
    });

    // Now plug it into Breeze.
    // Assume em1 is a preexisting EntityManager.
    var custType = metadataStore.getEntityType("Customer");
    var countryProp = custType.getProperty("Country");
    // Note that validator is added to a 'DataProperty' validators collection.
    prop.validators.push(countryValidator);
Entity level validators are also possible
@example
    function isValidZipCode(value) {
        var re = /^\d{5}([\-]\d{4})?$/;
        return (re.test(value));
    }

    // v in this case will be a Customer entity
    var valFn = function (v) {
        // This validator only validates US Zip Codes.
        if ( v.getProperty("Country") === "USA") {
            var postalCode = v.getProperty("PostalCode");
            return isValidZipCode(postalCode);
        }
        return true;
    };
    var zipCodeValidator = new Validator("zipCodeValidator", valFn,
        { messageTemplate: "For the US, this is not a valid PostalCode" });

    // Now plug it into Breeze.
    // Assume em1 is a preexisting EntityManager.
    var custType = em1.metadataStore.getEntityType("Customer");
    // Note that validator is added to an 'EntityType' validators collection.
    custType.validators.push(zipCodeValidator);
What is commonly needed is a way of creating a parameterized function that will itself
return a new Validator.  This requires the use of a 'context' object.
@example
    // create a function that will take in a config object
    // and will return a validator
    var numericRangeValidator = function(context) {
        var valFn = function(v, ctx) {
            if (v == null) return true;
            if (typeof(v) !== "number") return false;
            if (ctx.min != null && v < ctx.min) return false;
            if (ctx.max != null && v > ctx.max) return false;
            return true;
        };
        // The last parameter below is the 'context' object that will be passed into the 'ctx' parameter above
        // when this validator executes. Several other properties, such as displayName will get added to this object as well.
        return new Validator("numericRange", valFn, {
            messageTemplate: "'%displayName%' must be a number between the values of %min% and %max%",
            min: context.min,
            max: context.max
        });
    };
    // Assume that freightProperty is a DataEntityProperty that describes numeric values.
    // register the validator
    freightProperty.validators.push(numericRangeValidator({ min: 100, max: 500 }));

Breeze substitutes context values and functions for the tokens in the messageTemplate when preparing the runtime error message;
'displayName' is a pre-defined context function that is always available.

Please note that Breeze substitutes the empty string for falsey parameters. That usually works in your favor.
Sometimes it doesn't as when the 'min' value is zero in which case the message text would have a hole
where the 'min' value goes, saying: "... an integer between the values of and ...". That is not what you want.

To avoid this effect, you may can bake certain of the context values into the 'messageTemplate' itself
as shown in this revision to the pertinent part of the previous example:
@example
    // ... as before
    // ... but bake the min/max values into the message template.
    var template = breeze.core.formatString(
        "'%displayName%' must be a number between the values of %1 and %2",
        context.min, context.max);
    return new Validator("numericRange", valFn, {
        messageTemplate: template,
        min: context.min,
        max: context.max
    });

@method <ctor> Validator
@param name {String} The name of this validator.
@param validatorFn {Function} A function to perform validation.

validatorFn(value, context)
@param validatorFn.value {Object} Value to be validated
@param validatorFn.context {Object} The same context object passed into the constructor with the following additional properties if not
otherwise specified.
@param validatorFn.context.value {Object} The value being validated.
@param validatorFn.context.name {String} The name of the validator being executed.
@param validatorFn.context.displayName {String} This will be either the value of the property's 'displayName' property or
the value of its 'name' property or the string 'Value'
@param validatorFn.context.messageTemplate {String} This will either be the value of Validator.messageTemplates[ {this validators name}] or null. Validator.messageTemplates
is an object that is keyed by validator name and that can be added to in order to 'register' your own message for a given validator.
The following property can also be specified for any validator to force a specific errorMessage string
@param [validatorFn.context.message] {String} If this property is set it will be used instead of the 'messageTemplate' property when an
error message is generated.

@param [context] {Object} A free form object whose properties will made available during the validation and error message creation process.
This object will be passed into the Validator's validation function whenever 'validate' is called. See above for a description
of additional properties that will be automatically added to this object if not otherwise specified.
@dynamic
**/
class Validator {
  constructor(name, valFn, context) {
    // _baseContext is what will get serialized
    this._baseContext = context || {};
    this._baseContext.name = name;
    context = core.extend(Object.create(rootContext), this._baseContext);
    context.messageTemplate = context.messageTemplate || Validator.messageTemplates[name];
    this.name = name;
    this.valFn = valFn;
    this.context = context;
  }
  /**
  The name of this validator.
      __readOnly__
  @property name {String}
  **/
  /**
  The context for this validator.
      This object will typically contain at a minimum the following properties. "name", "displayName", and "message" or "messageTemplate".
  __readOnly__
  @property context {Object}
  **/
  /**
  Run this validator against the specified value.  This method will usually be called internally either
  automatically by an property change, entity attach, query or save operation, or manually as a result of
  a validateEntity call on the EntityAspect. The resulting ValidationResults are available via the
  EntityAspect.getValidationErrors method.
      However, you can also call a validator directly either for testing purposes or some other reason if needed.
  @example
      // using one of the predefined validators
      var validator = Validator.maxLength({ maxLength: 5, displayName: "City" });
      // should be ok because "asdf".length < 5
      var result = validator.validate("asdf");
      ok(result === null);
      result = validator.validate("adasdfasdf");
      // extract all of the properties of the 'result'
      var errMsg = result.errorMessage;
      var context = result.context;
      var sameValidator = result.validator;
  @method validate
  @param value {Object} Value to validate
  @param additionalContext {Object} Any additional contextual information that the Validator
  can make use of.
  @return {ValidationError|null} A ValidationError if validation fails, null otherwise
  **/
  validate(value, additionalContext) {
    let currentContext; // { value?: Object };
    if (additionalContext) {
      currentContext = core.extend(Object.create(this.context), additionalContext);
    } else {
      currentContext = this.context;
    }
    this.currentContext = currentContext;
    try {
      if (this.valFn(value, currentContext)) {
        return null;
      } else {
        currentContext.value = value;
        return new ValidationError(this, currentContext, this.getMessage());
      }
    } catch (e) {
      return new ValidationError(this, currentContext, "Exception occured while executing this validator: " + this.name);
    }
  }
  // context.value is not avail unless validate was called first.
  /**
  Returns the message generated by the most recent execution of this Validator.
  @example
      var v0 = Validator.maxLength({ maxLength: 5, displayName: "City" });
      v0.validate("adasdfasdf");
      var errMessage = v0.getMessage());
  @method getMessage
  @return {String}
  **/
  getMessage() {
    try {
      let context = this.currentContext;
      let message = context.message;
      if (message) {
        if (typeof message === "function") {
          return message(context);
        } else {
          return message;
        }
      } else if (context.messageTemplate) {
        return formatTemplate(context.messageTemplate, context);
      } else {
        return "invalid value: " + (this.name || "{unnamed validator}");
      }
    } catch (e) {
      return "Unable to format error message" + e.toString();
    }
  }
  toJSON() {
    return this._baseContext;
  }
  /**
  Creates a validator instance from a JSON object or an array of instances from an array of JSON objects.
  @method fromJSON
  @static
  @param json {Object} JSON object that represents the serialized version of a validator.
  **/
  static fromJSON(json) {
    if (Array.isArray(json)) {
      return json.map(function (js) {
        return Validator.fromJSON(js);
      });
    }
    if (json instanceof Validator) {
      return json;
    }
    let validatorName = "Validator." + json.name;
    let fn = config.getRegisteredFunction(validatorName);
    if (!fn) {
      throw new Error("Unable to locate a validator named:" + json.name);
    }
    return fn(json);
  }
  /**
  Register a validator instance so that any deserialized metadata can reference it.
  @method register
  @static
  @param validator {Validator} Validator to register.
  **/
  static register(validator) {
    config.registerFunction(function () {
      return validator;
    }, "Validator." + validator.name);
  }
  /**
  Register a validator factory so that any deserialized metadata can reference it.
  @method registerFactory
  @static
  @param validatorFactory {Function} A function that optionally takes a context property and returns a Validator instance.
  @param name {String} The name of the validator.
  **/
  static registerFactory(validatorFactory, name) {
    config.registerFunction(validatorFactory, "Validator." + name);
  }
}
/**
Map of standard error message templates keyed by validator name.
You can add to or modify this object to customize the template used for any validation error message.
@example
    // v is this function is the value to be validated, in this case a "country" string.
    var valFn = function (v) {
        if (v == null) return true;
        return (core.stringStartsWith(v, "US"));
    };
    var countryValidator = new Validator("countryIsUS", valFn, { displayName: "Country" });
    Validator.messageTemplates.countryIsUS = "'%displayName%' must start with 'US'";
    // This will have a similar effect to this
    var countryValidator = new Validator("countryIsUS", valFn, {
        displayName: "Country",
        messageTemplate: "'%displayName%' must start with 'US'"
    });
@property messageTemplates {Object}
@static
**/
Validator.messageTemplates = {
  bool: "'%displayName%' must be a 'true' or 'false' value",
  creditCard: "The %displayName% is not a valid credit card number",
  date: "'%displayName%' must be a date",
  duration: "'%displayName%' must be a ISO8601 duration string, such as 'P3H24M60S'",
  emailAddress: "The %displayName% '%value%' is not a valid email address",
  guid: "'%displayName%' must be a GUID",
  integer: "'%displayName%' must be an integer",
  integerRange: "'%displayName%' must be an integer between the values of %minValue% and %maxValue%",
  maxLength: "'%displayName%' must be a string with %maxLength% characters or less",
  number: "'%displayName%' must be a number",
  phone: "The %displayName% '%value%' is not a valid phone number",
  regularExpression: "The %displayName% '%value%' does not match '%expression%'",
  required: "'%displayName%' is required",
  string: "'%displayName%' must be a string",
  stringLength: "'%displayName%' must be a string with between %minLength% and %maxLength% characters",
  url: "The %displayName% '%value%' is not a valid url"
};
/**
Returns a standard 'required value' Validator
@example
    // Assume em1 is a preexisting EntityManager.
    var custType = em1.metadataStore.getEntityType("Customer");
    var regionProperty - custType.getProperty("Region");
    // Makes "Region" on Customer a required property.
    regionProperty.validators.push(Validator.required());
    // or to allow empty strings
    regionProperty.validators.push(Validator.required({ allowEmptyStrings: true }););
@method required
@static
@param context {Object}
@param [context.allowEmptyStrings] {Boolean} If this parameter is omitted or false then empty strings do NOT pass validation.
@return {Validator} A new Validator
**/
Validator.required = function (context) {
  let valFn = function (v, ctx) {
    if (typeof v === "string") {
      if (ctx && ctx.allowEmptyStrings) return true;
      return v.length > 0;
    } else {
      return v != null;
    }
  };
  return new Validator("required", valFn, context);
};
/**
Returns a standard maximum string length Validator; the maximum length must be specified
@example
    // Assume em1 is a preexisting EntityManager.
    var custType = em1.metadataStore.getEntityType("Customer");
    var regionProperty - custType.getProperty("Region");
    // Validates that the value of the Region property on Customer will be less than or equal to 5 characters.
    regionProperty.validators.push(Validator.maxLength( {maxLength: 5}));
@method maxLength
@static
@param context {Object}
@param context.maxLength {Integer}
@return {Validator} A new Validator
**/
Validator.maxLength = function (context) {
  let valFn = function (v, ctx) {
    if (v == null) return true;
    if (typeof v !== "string") return false;
    return v.length <= ctx.maxLength;
  };
  return new Validator("maxLength", valFn, context);
};
/**
Returns a standard string length Validator; both minimum and maximum lengths must be specified.
@example
    // Assume em1 is a preexisting EntityManager.
    var custType = em1.metadataStore.getEntityType("Customer");
    var regionProperty - custType.getProperty("Region");
    // Validates that the value of the Region property on Customer will be
    // between 2 and 5 characters
    regionProperty.validators.push(Validator.stringLength( {minLength: 2, maxLength: 5});
@method stringLength
@static
@param context {Object}
@param context.maxLength {Integer}
@param context.minLength {Integer}
@return {Validator} A new Validator
**/
Validator.stringLength = function (context) {
  let valFn = function (v, ctx) {
    if (v == null) return true;
    if (typeof v !== "string") return false;
    if (ctx.minLength != null && v.length < ctx.minLength) return false;
    if (ctx.maxLength != null && v.length > ctx.maxLength) return false;
    return true;
  };
  return new Validator("stringLength", valFn, context);
};
/**
Returns a standard string dataType Validator.
@example
    // Assume em1 is a preexisting EntityManager.
    var custType = em1.metadataStore.getEntityType("Customer");
    var regionProperty - custType.getProperty("Region");
    // Validates that the value of the Region property on Customer is a string.
    regionProperty.validators.push(Validator.string());
@method string
@static
@return {Validator} A new Validator
**/
Validator.string = function () {
  let valFn = function (v) {
    if (v == null) return true;
    return typeof v === "string";
  };
  return new Validator("string", valFn);
};
/**
Returns a Guid data type Validator.
@example
    // Assume em1 is a preexisting EntityManager.
    var custType = em1.metadataStore.getEntityType("Customer");
    var customerIdProperty - custType.getProperty("CustomerID");
    // Validates that the value of the CustomerID property on Customer is a Guid.
    customerIdProperty.validators.push(Validator.guid());
@method guid
@static
@return {Validator} A new Validator
**/
Validator.guid = function () {
  let valFn = function (v) {
    if (v == null) return true;
    return core.isGuid(v);
  };
  return new Validator("guid", valFn);
};
/**
Returns a ISO 8601 duration string  Validator.
@example
    // Assume em1 is a preexisting EntityManager.
    var eventType = em1.metadataStore.getEntityType("Event");
    var elapsedTimeProperty - eventType.getProperty("ElapsedTime");
    // Validates that the value of the ElapsedTime property on Customer is a duration.
    elapsedTimeProperty.validators.push(Validator.duration());
@method duration
@static
@return {Validator} A new Validator
**/
Validator.duration = function () {
  let valFn = function (v) {
    if (v == null) return true;
    return core.isDuration(v);
  };
  return new Validator("duration", valFn);
};
/**
Returns a standard numeric data type Validator.
@example
    // Assume em1 is a preexisting EntityManager.
    var orderType = em1.metadataStore.getEntityType("Order");
    var freightProperty - orderType.getProperty("Freight");
    // Validates that the value of the Freight property on Order is a number.
    freightProperty.validators.push(Validator.number());
@method number
@static
@return {Validator} A new Validator
**/
// TODO: may need to have seperate logic for single.
Validator.number = function (context) {
  let valFn = function (v, ctx) {
    if (v == null) return true;
    if (typeof v === "string" && ctx && ctx.allowString) {
      v = parseFloat(v);
    }
    return typeof v === "number" && !isNaN(v);
  };
  return new Validator("number", valFn, context);
};
Validator.double = Validator.number;
Validator.single = Validator.number;
/**
Returns a standard large integer data type - 64 bit - Validator.
@example
    // Assume em1 is a preexisting EntityManager.
    var orderType = em1.metadataStore.getEntityType("Order");
    var freightProperty - orderType.getProperty("Freight");
    // Validates that the value of the Freight property on Order is within the range of a 64 bit integer.
    freightProperty.validators.push(Validator.int64());
@method int64
@static
@return {Validator} A new Validator
**/
Validator.integer = function (context) {
  let valFn = function (v, ctx) {
    if (v == null) return true;
    if (typeof v === "string" && ctx && ctx.allowString) {
      v = parseInt(v, 10);
    }
    return typeof v === "number" && !isNaN(v) && Math.floor(v) === v;
  };
  return new Validator("integer", valFn, context);
};
Validator.int64 = Validator.integer;
/**
Returns a standard 32 bit integer data type Validator.
@example
    // Assume em1 is a preexisting EntityManager.
    var orderType = em1.metadataStore.getEntityType("Order");
    var freightProperty - orderType.getProperty("Freight");
    freightProperty.validators.push(Validator.int32());
@method int32
@static
@return {Validator} A new Validator
**/
Validator.int32 = function (context) {
  return intRangeValidatorCtor("int32", INT32_MIN, INT32_MAX, context)();
};
/**
Returns a standard 16 bit integer data type Validator.
@example
    // Assume em1 is a preexisting EntityManager.
    var orderType = em1.metadataStore.getEntityType("Order");
    var freightProperty - orderType.getProperty("Freight");
    // Validates that the value of the Freight property on Order is within the range of a 16 bit integer.
    freightProperty.validators.push(Validator.int16());
@method int16
@static
@return {Validator} A new Validator
**/
Validator.int16 = function (context) {
  return intRangeValidatorCtor("int16", INT16_MIN, INT16_MAX, context)();
};
/**
Returns a standard byte data type Validator. (This is a integer between 0 and 255 inclusive for js purposes).
@example
    // Assume em1 is a preexisting EntityManager.
    var orderType = em1.metadataStore.getEntityType("Order");
    var freightProperty - orderType.getProperty("Freight");
    // Validates that the value of the Freight property on Order is within the range of a 16 bit integer.
    // Probably not a very good validation to place on the Freight property.
    regionProperty.validators.push(Validator.byte());
@method byte
@static
@return {Validator} A new Validator
**/
Validator.byte = function (context) {
  return intRangeValidatorCtor("byte", BYTE_MIN, BYTE_MAX, context)();
};
/**
Returns a standard boolean data type Validator.
@example
    // Assume em1 is a preexisting EntityManager.
    var productType = em1.metadataStore.getEntityType("Product");
    var discontinuedProperty - productType.getProperty("Discontinued");
    // Validates that the value of the Discontinued property on Product is a boolean
    discontinuedProperty.validators.push(Validator.bool());
@method bool
@static
@return {Validator} A new Validator
**/
Validator.bool = function () {
  let valFn = function (v) {
    if (v == null) return true;
    return v === true || v === false;
  };
  return new Validator("bool", valFn);
};
Validator.none = function () {
  let valFn = function (v) {
    return true;
  };
  return new Validator("none", valFn);
};
/**
Returns a standard date data type Validator.
@example
    // Assume em1 is a preexisting EntityManager.
    var orderType = em1.metadataStore.getEntityType("Order");
    var orderDateProperty - orderType.getProperty("OrderDate");
    // Validates that the value of the OrderDate property on Order is a date
    // Probably not a very good validation to place on the Freight property.
    orderDateProperty.validators.push(Validator.date());
@method date
@static
@return {Validator} A new Validator
**/
Validator.date = function () {
  let valFn = function (v) {
    if (v == null) return true;
    if (typeof v === "string") {
      try {
        return !isNaN(Date.parse(v));
        // old code
        // return __isDate(new Date(v));
      } catch (e) {
        return false;
      }
    } else {
      return core.isDate(v);
    }
  };
  return new Validator("date", valFn);
};
/**
Returns a credit card number validator
Performs a luhn algorithm checksum test for plausability
catches simple mistakes; only service knows for sure
@example
    // Assume em is a preexisting EntityManager.
    var personType = em.metadataStore.getEntityType("Person");
    var creditCardProperty = personType.getProperty("creditCard");
    // Validates that the value of the Person.creditCard property is credit card.
    creditCardProperty.validators.push(Validator.creditCard());
@method creditCard
@static
@param [context] {Object} optional parameters to pass through to validation constructor
@return {Validator} A new Validator
**/
Validator.creditCard = function (context) {
  function valFn(v) {
    if (v == null || v === '') return true;
    if (typeof v !== 'string') return false;
    v = v.replace(/(\-|\s)/g, ""); // remove dashes and spaces
    if (!v || /\D/.test(v)) return false; // all digits, not empty
    return luhn(v);
  }
  return new Validator('creditCard', valFn, context);
};
/**
Returns a regular expression validator; the expression must be specified
@example
    // Add validator to a property. Assume em is a preexisting EntityManager.
    var customerType = em.metadataStore.getEntityType("Customer");
    var regionProperty = customerType.getProperty("Region");
    // Validates that the value of Customer.Region is 2 char uppercase alpha.
    regionProperty.validators.push(Validator.regularExpression( {expression: '^[A-Z]{2}$'} );
@method regularExpression
@static
@param context {Object}
@param context.expression {String} String form of the regular expression to apply
@return {Validator} A new Validator
**/
Validator.regularExpression = function (context) {
  function valFn(v, ctx) {
    // do not invalidate if empty; use a separate required test
    if (v == null || v === '') return true;
    if (typeof v !== 'string') return false;
    try {
      let re = new RegExp(ctx.expression);
      return re.test(v);
    } catch (e) {
      throw new Error('Missing or invalid expression parameter to regExp validator');
    }
  }
  return new Validator('regularExpression', valFn, context);
};
/**
Returns the email address validator
@example
    // Assume em is a preexisting EntityManager.
    var personType = em.metadataStore.getEntityType("Person");
    var emailProperty = personType.getProperty("email");
    // Validates that the value of the Person.email property is an email address.
    emailProperty.validators.push(Validator.emailAddress());
@method emailAddress
@static
@param [context] {Object} optional parameters to pass through to validation constructor
@return {Validator} A new Validator
**/
Validator.emailAddress = function (context) {
  // See https://github.com/srkirkland/DataAnnotationsExtensions/blob/master/DataAnnotationsExtensions/EmailAttribute.cs
  let reEmailAddress = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i;
  return makeRegExpValidator('emailAddress', reEmailAddress, null, context);
};
/**
Returns the phone validator
Provides basic assertions on the format and will help to eliminate most nonsense input
Matches:
International dialing prefix: {{}, +, 0, 0000} (with or without a trailing break character, if not '+': [-/. ])
> ((\+)|(0(\d+)?[-/.\s]))
Country code: {{}, 1, ..., 999} (with or without a trailing break character: [-/. ])
> [1-9]\d{,2}[-/.\s]?
Area code: {(0), ..., (000000), 0, ..., 000000} (with or without a trailing break character: [-/. ])
> ((\(\d{1,6}\)|\d{1,6})[-/.\s]?)?
Local: {0, ...}+ (with or without a trailing break character: [-/. ])
> (\d+[-/.\s]?)+\d+
@example
    // Assume em is a preexisting EntityManager.
    var customerType = em.metadataStore.getEntityType("Customer");
    var phoneProperty = customerType.getProperty("phone");
    // Validates that the value of the Customer.phone property is phone.
    phoneProperty.validators.push(Validator.phone());
@method phone
@static
@param [context] {Object} optional parameters to pass through to validation constructor
@return {Validator} A new Validator
**/
Validator.phone = function (context) {
  // See https://github.com/srkirkland/DataAnnotationsExtensions/blob/master/DataAnnotationsExtensions/Expressions.cs
  let rePhone = /^((\+|(0(\d+)?[-/.\s]?))[1-9]\d{0,2}[-/.\s]?)?((\(\d{1,6}\)|\d{1,6})[-/.\s]?)?(\d+[-/.\s]?)+\d+$/;
  return makeRegExpValidator('phone', rePhone, null, context);
};
/**
Returns the URL (protocol required) validator
@example
    // Assume em is a preexisting EntityManager.
    var personType = em.metadataStore.getEntityType("Person");
    var websiteProperty = personType.getProperty("website");
    // Validates that the value of the Person.website property is a URL.
    websiteProperty.validators.push(Validator.url());
@method url
@static
@param [context] {Object} optional parameters to pass through to validation constructor
@return {Validator} A new Validator
**/
Validator.url = function (context) {
  //See https://github.com/srkirkland/DataAnnotationsExtensions/blob/master/DataAnnotationsExtensions/UrlAttribute.cs
  let reUrlProtocolRequired = /^(https?|ftp):\/\/(((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-fA-F]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|([a-zA-Z][\-a-zA-Z0-9]*)|((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-fA-F]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-fA-F]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-fA-F]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-fA-F]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/;
  return makeRegExpValidator('url', reUrlProtocolRequired, null, context);
};
/**
Creates a regular expression validator with a fixed expression.
Many of the stock validators are built with this factory method.
Their expressions are often derived from
https://github.com/srkirkland/DataAnnotationsExtensions/blob/master/DataAnnotationsExtensions
You can try many of them at http://dataannotationsextensions.org/
@example
    // Make a zipcode validator
    function zipValidator = Validator.makeRegExpValidator(
    "zipVal,
    /^\d{5}([\-]\d{4})?$/,
    "The %displayName% '%value%' is not a valid U.S. zipcode");
    // Register it.
    Validator.register(zipValidator);
    // Add it to a data property. Assume em is a preexisting EntityManager.
    var custType = em.metadataStore.getEntityType("Customer");
    var zipProperty = custType.getProperty("PostalCode");
    zipProperty.validators.push(zipValidator);
@method makeRegExpValidator
@static
@param validatorName {String} name of this validator
@param expression {String | RegExp} regular expression to apply
@param [defaultMessage] {String} default message for failed validations
@param [context] {Object} optional parameters to pass through to validation constructor
@return {Validator} A new Validator
**/
Validator.makeRegExpValidator = makeRegExpValidator;
Validator.prototype._$typeName = "Validator";
// register all validators
Error['x'] = core.objectForEach(Validator, function (key, value) {
  if (typeof value !== "function") {
    return;
  }
  if (key === "fromJSON" || key === "register" || key === "registerFactory" || key === "makeRegExpValidator") {
    return;
  }
  config.registerFunction(value, "Validator." + key);
});
function formatTemplate(template, vars, ownPropertiesOnly = false) {
  if (!vars) return template;
  return template.replace(/%([^%]+)%/g, function (_, key) {
    let valOrFn;
    if (ownPropertiesOnly) {
      valOrFn = vars.hasOwnProperty(key) ? vars[key] : '';
    } else {
      valOrFn = vars[key];
    }
    if (valOrFn != null) {
      if (core.isFunction(valOrFn)) {
        return valOrFn(vars);
      } else {
        return valOrFn;
      }
    } else {
      return "";
    }
  });
}
function intRangeValidatorCtor(validatorName, minValue, maxValue, context) {
  context = context || {};
  if (minValue !== undefined) {
    context.min = minValue;
  }
  if (maxValue !== undefined) {
    context.max = maxValue;
  }
  let templateExists = context.messageTemplate || Validator.messageTemplates[validatorName];
  if (!templateExists) {
    Validator.messageTemplates[validatorName] = core.formatString("'%displayName%' must be an integer between the values of %1 and %2", minValue, maxValue);
  }
  return function () {
    let valFn = function (v, ctx) {
      if (v == null) return true;
      if (typeof v === "string" && ctx && ctx.allowString) {
        v = parseInt(v, 0);
      }
      if (typeof v === "number" && !isNaN(v) && Math.floor(v) === v) {
        if (minValue != null && v < minValue) {
          return false;
        }
        if (maxValue != null && v > maxValue) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    };
    return new Validator(validatorName, valFn, context);
  };
}
function makeRegExpValidator(validatorName, expression, defaultMessage, context) {
  if (defaultMessage) {
    Validator.messageTemplates[validatorName] = defaultMessage;
  }
  let re = typeof expression === 'string' ? new RegExp(expression) : expression;
  let valFn = function (v) {
    // do not invalidate if empty; use a separate required test
    if (v == null || v === '') return true;
    if (typeof v !== 'string') return false;
    return re.test(v);
  };
  return new Validator(validatorName, valFn, context);
}
const ɵ1 = function () {
  let luhnArr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
  return function (str) {
    let counter = 0;
    let incNum;
    let odd = false;
    let temp = String(str).replace(/[^\d]/g, "");
    if (temp.length === 0) return false;
    for (let i = temp.length - 1; i >= 0; --i) {
      incNum = parseInt(temp.charAt(i), 10);
      counter += (odd = !odd) ? incNum : luhnArr[incNum];
    }
    return counter % 10 === 0;
  };
};
// http://rosettacode.org/wiki/Luhn_test_of_credit_card_numbers#JavaScript
// function luhn(a: string, b: number, c: number, d: number, e: number) {
//   for (d = +a[b = a.length - 1], e = 0; b--; ) {
//     c = +a[b], d += ++e % 2 ? 2 * c % 10 + (c > 4) : c;
//   }
//   return !(d % 10);
// };
let luhn = ɵ1();
/**
A ValidationError is used to describe a failed validation.

@class ValidationError
**/
/**
Constructs a new ValidationError
@method <ctor> ValidationError

@param validator {Validator || null} The Validator used to create this error, if any.
@param context { ContextObject || null} The Context object used in conjunction with the Validator to create this error.
@param errorMessage { String} The actual error message
@param [key] {String} An optional key used to define a key for this error. One will be created automatically if not provided here.
**/
class ValidationError {
  constructor(validator, context, errorMessage, key) {
    // Error is with isInstanceOf(Validator)
    assertParam(validator, "validator").isOptional().isInstanceOf(Validator).check();
    assertParam(errorMessage, "errorMessage").isNonEmptyString().check();
    assertParam(key, "key").isOptional().isNonEmptyString().check();
    this.validator = validator || undefined;
    context = context || {};
    this.context = context;
    this.errorMessage = errorMessage;
    this.property = context.property;
    this.propertyName = context.propertyName || context.property && context.property.name;
    if (key) {
      this.key = key;
    } else {
      this.key = ValidationError.getKey(validator || errorMessage, this.propertyName);
    }
    this.isServerError = false;
  }
  /**
  The Validator associated with this ValidationError.
      __readOnly__
  @property validator {Validator}
  **/
  /**
  A 'context' object associated with this ValidationError.
      __readOnly__
  @property context {Object}
  **/
  /**
  The DataProperty or NavigationProperty associated with this ValidationError.
      __readOnly__
  @property property {DataProperty|NavigationProperty}
  **/
  /**
  The property name associated with this ValidationError. This will be a "property path" for any properties of a complex object.
      __readOnly__
  @property propertyName {String}
  **/
  /**
  The error message associated with the ValidationError.
      __readOnly__
  @property errorMessage {string}
  **/
  /**
  The key by which this validation error may be removed from a collection of ValidationErrors.
      __readOnly__
  @property key {string}
  **/
  /**
  Whether this is a server error.
      __readOnly__
  @property isServerError {bool}
  **/
  /**
  Composes a ValidationError 'key' given a validator or an errorName and an optional propertyName
  @method getKey
  @static
  @param validator {ValidatorOrErrorKey} A Validator or an "error name" if no validator is available.
  @param [propertyName] A property name
  @return {String} A ValidationError 'key'
  **/
  static getKey(validatorOrErrorName, propertyName) {
    let name = typeof validatorOrErrorName === 'string' ? validatorOrErrorName : validatorOrErrorName.name;
    return name + (propertyName ? ":" + propertyName : "");
  }
}
let _localTimeRegex = /.\d{3}$/;
/**
DataType is an 'Enum' containing all of the supported data types.
@dynamic
**/
class DataType extends BreezeEnum {
  static getComparableFn(dataType) {
    if (dataType && dataType.normalize) {
      return dataType.normalize;
    } else if (dataType === DataType.Time) {
      // durations must be converted to compare them
      return function (value) {
        return value && core.durationToSeconds(value);
      };
    } else {
      // TODO: __identity
      return function (value) {
        return value;
      };
    }
  }
  /** Returns the DataType for a specified EDM type name.
  **/
  static fromEdmDataType(typeName) {
    let dt;
    let parts = typeName.split(".");
    if (parts.length > 1) {
      let simpleName = parts[1];
      if (simpleName === "image") {
        // hack
        dt = DataType.Byte;
      } else if (parts.length === 2) {
        dt = DataType.fromName(simpleName) || DataType.Undefined;
      } else {
        // enum
        // dt = DataType.Int32;
        dt = DataType.String;
      }
    }
    return dt;
  }
  /** Returns the DataType for a specified input. */
  static fromValue(val) {
    if (core.isDate(val)) return DataType.DateTime;
    switch (typeof val) {
      case "string":
        if (core.isGuid(val)) return DataType.Guid;
        // the >3 below is a hack to insure that if we are inferring datatypes that
        // very short strings that are valid but unlikely ISO encoded Time's are treated as strings instead.
        else if (core.isDuration(val) && val.length > 3) return DataType.Time;else if (core.isDateString(val)) return DataType.DateTime;
        return DataType.String;
      case "boolean":
        return DataType.Boolean;
      case "number":
        return DataType.Double;
    }
    return DataType.Undefined;
  }
  static parseTimeFromServer(source) {
    if (typeof source === 'string') {
      return source;
    }
    // ODATA v3 format
    if (source && source.__edmType === 'Edm.Time') {
      let seconds = Math.floor(source.ms / 1000);
      return 'PT' + seconds + 'S';
    }
    return source;
  }
  static parseDateAsUTC(source) {
    if (typeof source === 'string') {
      // convert to UTC string if no time zone specifier.
      let isLocalTime = _localTimeRegex.test(source);
      // var isLocalTime = !hasTimeZone(source);
      source = isLocalTime ? source + 'Z' : source;
    }
    source = new Date(Date.parse(source));
    return source;
  }
  /** Returns a raw value converted to the specified DataType */
  static parseRawValue(val, dataType) {
    // undefined values will be the default for most unmapped properties EXCEPT when they are set
    // in a jsonResultsAdapter ( an unusual use case).
    if (val === undefined) return undefined;
    if (!val) return val;
    if (dataType && dataType.parseRawValue) {
      val = dataType.parseRawValue(val);
    }
    return val;
  }
  /** @hidden @internal */
  // used during initialization; visible on instance for testing purposes.
  static _resetConstants() {
    DataType.constants = {
      stringPrefix: "K_",
      nextNumber: -1,
      nextNumberIncrement: -1
    };
  }
}
DataType.parseDateFromServer = value => DataType.parseDateAsUTC(value);
DataType.String = new DataType({
  defaultValue: "",
  parse: coerceToString,
  fmtOData: fmtString,
  getNext: getNextString
});
DataType.Int64 = new DataType({
  defaultValue: 0,
  isNumeric: true,
  isInteger: true,
  quoteJsonOData: true,
  parse: coerceToInt,
  fmtOData: makeFloatFmt("L"),
  getNext: getNextNumber
});
DataType.Int32 = new DataType({
  defaultValue: 0,
  isNumeric: true,
  isInteger: true,
  parse: coerceToInt,
  fmtOData: fmtInt,
  getNext: getNextNumber
});
DataType.Int16 = new DataType({
  defaultValue: 0,
  isNumeric: true,
  isInteger: true,
  parse: coerceToInt,
  fmtOData: fmtInt,
  getNext: getNextNumber
});
DataType.Byte = new DataType({
  defaultValue: 0,
  isNumeric: true,
  isInteger: true,
  parse: coerceToInt,
  fmtOData: fmtInt
});
DataType.Decimal = new DataType({
  defaultValue: 0,
  isNumeric: true,
  quoteJsonOData: true,
  isFloat: true,
  parse: coerceToFloat,
  fmtOData: makeFloatFmt("m"),
  getNext: getNextNumber
});
DataType.Double = new DataType({
  defaultValue: 0,
  isNumeric: true,
  isFloat: true,
  parse: coerceToFloat,
  fmtOData: makeFloatFmt("d"),
  getNext: getNextNumber
});
DataType.Single = new DataType({
  defaultValue: 0,
  isNumeric: true,
  isFloat: true,
  parse: coerceToFloat,
  fmtOData: makeFloatFmt("f"),
  getNext: getNextNumber
});
DataType.DateTime = new DataType({
  defaultValue: new Date(1900, 0, 1),
  isDate: true,
  parse: coerceToDate,
  parseRawValue: parseRawDate,
  normalize: function (value) {
    return value && value.getTime && value.getTime();
  },
  fmtOData: fmtDateTime,
  getNext: getNextDateTime,
  getConcurrencyValue: getConcurrencyDateTime
});
DataType.DateTimeOffset = new DataType({
  defaultValue: new Date(1900, 0, 1),
  isDate: true,
  parse: coerceToDate,
  parseRawValue: parseRawDate,
  normalize: function (value) {
    return value && value.getTime && value.getTime();
  },
  fmtOData: fmtDateTimeOffset,
  getNext: getNextDateTime,
  getConcurrencyValue: getConcurrencyDateTime
});
DataType.Time = new DataType({
  defaultValue: "PT0S",
  fmtOData: fmtTime,
  parseRawValue: DataType.parseTimeFromServer
});
DataType.Boolean = new DataType({
  defaultValue: false,
  parse: coerceToBool,
  fmtOData: fmtBoolean
});
DataType.Guid = new DataType({
  defaultValue: "00000000-0000-0000-0000-000000000000",
  parse: coerceToGuid,
  fmtOData: fmtGuid,
  getNext: getNextGuid,
  parseRawValue: function (val) {
    return val.toLowerCase();
  },
  getConcurrencyValue: core.getUuid
});
DataType.Binary = new DataType({
  defaultValue: null,
  fmtOData: fmtBinary,
  parseRawValue: parseRawBinary
});
DataType.Undefined = new DataType({
  defaultValue: undefined,
  fmtOData: fmtUndefined
});
DataType.prototype._$typeName = "DataType";
Error['x'] = DataType._resetConstants();
Error['x'] = DataType.resolveSymbols();
Error['x'] = DataType.getSymbols().forEach(sym => sym.validatorCtor = getValidatorCtor(sym));
// private functions;
function getValidatorCtor(dataType) {
  switch (dataType) {
    case DataType.String:
      return Validator.string;
    case DataType.Int64:
      return Validator.int64;
    case DataType.Int32:
      return Validator.int32;
    case DataType.Int16:
      return Validator.int16;
    case DataType.Decimal:
      return Validator.number;
    case DataType.Double:
      return Validator.number;
    case DataType.Single:
      return Validator.number;
    case DataType.DateTime:
      return Validator.date;
    case DataType.DateTimeOffset:
      return Validator.date;
    case DataType.Boolean:
      return Validator.bool;
    case DataType.Guid:
      return Validator.guid;
    case DataType.Byte:
      return Validator.byte;
    case DataType.Binary:
      // TODO: don't quite know how to validate this yet.
      return Validator.none;
    case DataType.Time:
      return Validator.duration;
    case DataType.Undefined:
      return Validator.none;
  }
}
function getNextString() {
  return DataType.constants.stringPrefix + getNextNumber().toString();
}
function getNextNumber() {
  let result = DataType.constants.nextNumber;
  DataType.constants.nextNumber += DataType.constants.nextNumberIncrement;
  return result;
}
function getNextGuid() {
  return core.getUuid();
}
function getNextDateTime() {
  return new Date();
}
function getConcurrencyDateTime(val) {
  // use the current datetime but insure that it is different from previous call.
  let dt = new Date();
  let dt2 = new Date();
  while (dt.getTime() === dt2.getTime()) {
    dt2 = new Date();
  }
  return dt2;
}
function coerceToString(source, sourceTypeName) {
  return source == null ? source : source.toString();
}
function coerceToGuid(source, sourceTypeName) {
  if (sourceTypeName === "string") {
    return source.trim().toLowerCase();
  }
  return source;
}
function coerceToInt(source, sourceTypeName) {
  if (sourceTypeName === "string") {
    let src = source.trim();
    if (src === "") return null;
    let val = parseInt(src, 10);
    return isNaN(val) ? source : val;
  } else if (sourceTypeName === "number") {
    return Math.round(source);
  }
  // do we want to coerce floats -> ints
  return source;
}
function coerceToFloat(source, sourceTypeName) {
  if (sourceTypeName === "string") {
    let src = source.trim();
    if (src === "") return null;
    let val = parseFloat(src);
    return isNaN(val) ? source : val;
  }
  return source;
}
function coerceToDate(source, sourceTypeName) {
  let val;
  if (sourceTypeName === "string") {
    let src = source.trim();
    if (src === "") return null;
    val = new Date(Date.parse(src));
    return core.isDate(val) ? val : source;
  } else if (sourceTypeName === "number") {
    val = new Date(source);
    return core.isDate(val) ? val : source;
  }
  return source;
}
function coerceToBool(source, sourceTypeName) {
  if (sourceTypeName === "string") {
    let src = source.trim().toLowerCase();
    if (src === "false" || src === "") {
      return false;
    } else if (src === "true") {
      return true;
    } else {
      return source;
    }
  }
  return source;
}
function fmtString(val) {
  return val == null ? null : "'" + val.replace(/'/g, "''") + "'";
}
function fmtInt(val) {
  return val == null ? null : typeof val === "string" ? parseInt(val, 10) : val;
}
function makeFloatFmt(fmtSuffix) {
  return function (val) {
    if (val == null) return null;
    if (typeof val === "string") {
      val = parseFloat(val);
    }
    return val + fmtSuffix;
  };
}
function fmtDateTime(val) {
  if (val == null) return null;
  try {
    return "datetime'" + val.toISOString() + "'";
  } catch (e) {
    throwError("'%1' is not a valid dateTime", val);
  }
}
function fmtDateTimeOffset(val) {
  if (val == null) return null;
  try {
    return "datetimeoffset'" + val.toISOString() + "'";
  } catch (e) {
    throwError("'%1' is not a valid dateTime", val);
  }
}
function fmtTime(val) {
  if (val == null) return null;
  if (!core.isDuration(val)) {
    throwError("'%1' is not a valid ISO 8601 duration", val);
  }
  return "time'" + val + "'";
}
function fmtGuid(val) {
  if (val == null) return null;
  if (!core.isGuid(val)) {
    throwError("'%1' is not a valid guid", val);
  }
  return "guid'" + val + "'";
}
function fmtBoolean(val) {
  if (val == null) return null;
  if (typeof val === "string") {
    return val.trim().toLowerCase() === "true";
  } else {
    return !!val;
  }
}
function fmtBinary(val) {
  if (val == null) return val;
  return "binary'" + val + "'";
}
// TODO: use __identity instead;
function fmtUndefined(val) {
  return val;
}
function throwError(msg, val) {
  msg = core.formatString(msg, val);
  throw new Error(msg);
}
function parseRawDate(val) {
  if (!core.isDate(val)) {
    val = DataType.parseDateFromServer(val);
  }
  return val;
}
function parseRawBinary(val) {
  if (val && val.$value !== undefined) {
    val = val.$value; // this will be a byte[] encoded as a string
  }
  return val;
}
//function hasTimeZone(source) {
//  var ix = source.indexOf("T");
//  var timePart = source.substring(ix+1);
//  return  timePart.indexOf("-") >= 0 || timePart.indexOf("+") >= 0 || timePart.indexOf("Z");
//}

/**
EntityState is an 'Enum' containing all of the valid states for an 'Entity'.
**/
class EntityState extends BreezeEnum {
  /**
  Returns whether an entityState instance is EntityState.Unchanged.
  >     var es = anEntity.entityAspect.entityState;
  >     return es.isUnchanged();
      is the same as
  >     return es === EntityState.Unchanged;
  **/
  isUnchanged() {
    return this === EntityState.Unchanged;
  }
  /**
  Return whether an entityState instance is EntityState.Added.
  
  >     var es = anEntity.entityAspect.entityState;
  >     return es.isAdded();
      is the same as
  >     return es === EntityState.Added;
  **/
  isAdded() {
    return this === EntityState.Added;
  }
  /**
  Returns whether an entityState instance is EntityState.Modified.
  >     var es = anEntity.entityAspect.entityState;
  >     return es.isModified();
      is the same as
  >     return es === EntityState.Modified;
  **/
  isModified() {
    return this === EntityState.Modified;
  }
  /**
  Returns whether an entityState instance is EntityState.Deleted.
  >     var es = anEntity.entityAspect.entityState;
  >     return es.isDeleted();
      is the same as
  
  >     return es === EntityState.Deleted;
  **/
  isDeleted() {
    return this === EntityState.Deleted;
  }
  /**
  Returns whether an entityState instance is EntityState.Detached.
  >     var es = anEntity.entityAspect.entityState;
  >     return es.isDetached();
      is the same as
  >     return es === EntityState.Detached;
  **/
  isDetached() {
    return this === EntityState.Detached;
  }
  /**
  Returns whether an entityState instance is EntityState.Unchanged or EntityState.Modified.
  >     var es = anEntity.entityAspect.entityState;
  >     return es.isUnchangedOrModified();
      is the same as
  >     return es === EntityState.Unchanged || es === EntityState.Modified
  **/
  isUnchangedOrModified() {
    return this === EntityState.Unchanged || this === EntityState.Modified;
  }
  /** Returns whether an entityState instance is EntityState.Added or EntityState.Modified or EntityState.Deleted.
  >     var es = anEntity.entityAspect.entityState;
  >     return es.isAddedModifiedOrDeleted();
      is the same as
  >     return es === EntityState.Added || es === EntityState.Modified || es === EntityState.Deleted
  **/
  isAddedModifiedOrDeleted() {
    return this === EntityState.Added || this === EntityState.Modified || this === EntityState.Deleted;
  }
}
/** The 'Unchanged' state. **/
EntityState.Unchanged = new EntityState();
/**  The 'Added' state.  **/
EntityState.Added = new EntityState();
/**  The 'Modified' state.   **/
EntityState.Modified = new EntityState();
/**  The 'Deleted' state.  **/
EntityState.Deleted = new EntityState();
/**  The 'Detached' state.  **/
EntityState.Detached = new EntityState();
EntityState.prototype._$typeName = "EntityState";
Error['x'] = EntityState.resolveSymbols();

/** EntityAction is an 'Enum' containing all of the valid actions that can occur to an 'Entity'.
 
*/
class EntityAction extends BreezeEnum {
  /** Is this an 'attach' operation? ( Attach, AttachOnQuery or AttachOnImport) */
  isAttach() {
    return !!this._isAttach;
  }
  /** Is this a 'detach' operation? ( Detach, Clear) */
  isDetach() {
    return !!this._isDetach;
  }
  /** Is this a 'modification' operation? ( PropertyChange, MergeOnQuery, MergeOnSave, MergeOnImport, RejectChanges) */
  isModification() {
    return !!this._isModification;
  }
}
/** Entity was attached via an AttachEntity call. */
EntityAction.Attach = new EntityAction({
  _isAttach: true
});
/**  Entity was attached as a result of a query. */
EntityAction.AttachOnQuery = new EntityAction({
  _isAttach: true
});
/**  Entity was attached as a result of an import. */
EntityAction.AttachOnImport = new EntityAction({
  _isAttach: true
});
/** Entity was detached */
EntityAction.Detach = new EntityAction({
  _isDetach: true
});
/** Properties on the entity were merged as a result of a query. */
EntityAction.MergeOnQuery = new EntityAction({
  _isModification: true
});
/** Properties on the entity were merged as a result of an import. */
EntityAction.MergeOnImport = new EntityAction({
  _isModification: true
});
/** Properties on the entity were merged as a result of a save */
EntityAction.MergeOnSave = new EntityAction({
  _isModification: true
});
/** A property on the entity was changed. */
EntityAction.PropertyChange = new EntityAction({
  _isModification: true
});
/** The EntityState of the entity was changed. */
EntityAction.EntityStateChange = new EntityAction();
/** AcceptChanges was called on the entity, or its entityState was set to Unmodified. */
EntityAction.AcceptChanges = new EntityAction();
/** RejectChanges was called on the entity. */
EntityAction.RejectChanges = new EntityAction({
  _isModification: true
});
/** The EntityManager was cleared.  All entities detached. */
EntityAction.Clear = new EntityAction({
  _isDetach: true
});
EntityAction.prototype._$typeName = "EntityAction";
Error['x'] = EntityAction.resolveSymbols();

/**
An EntityKey is an object that represents the unique identity of an entity.  EntityKey's are immutable.


**/
class EntityKey {
  /**
  Constructs a new EntityKey.  Each entity within an EntityManager will have a unique EntityKey.
  >     // assume em1 is an EntityManager containing a number of existing entities.
  >     var empType = em1.metadataStore.getEntityType("Employee");
  >     var entityKey = new EntityKey(empType, 1);
      EntityKey's may also be found by calling EntityAspect.getKey()
  >     // assume employee1 is an existing Employee entity
  >     var empKey = employee1.entityAspect.getKey();
      Multipart keys are created by passing an array as the 'keyValues' parameter
  >     var empTerrType = em1.metadataStore.getEntityType("EmployeeTerritory");
  >     var empTerrKey = new EntityKey(empTerrType, [ 1, 77]);
  >     // The order of the properties in the 'keyValues' array must be the same as that
  >     // returned by empTerrType.keyProperties
  @param entityType - The [[EntityType]] of the entity.
  @param keyValues - A single value or an array of values.
  */
  constructor(entityType, keyValues) {
    assertParam(entityType, "entityType").isInstanceOf(EntityType).check();
    let subtypes = entityType.getSelfAndSubtypes();
    if (subtypes.length > 1) {
      this._subtypes = subtypes.filter(function (st) {
        return st.isAbstract === false;
      });
    }
    if (!Array.isArray(keyValues)) {
      keyValues = [keyValues];
    }
    this.entityType = entityType;
    entityType.keyProperties.forEach(function (kp, i) {
      // insure that guid keys are comparable.
      if (kp.dataType === DataType.Guid) {
        keyValues[i] = keyValues[i] && keyValues[i].toLowerCase ? keyValues[i].toLowerCase() : keyValues[i];
      }
    });
    this.values = keyValues;
    this._keyInGroup = EntityKey.createKeyString(keyValues);
  }
  toJSON() {
    return {
      entityType: this.entityType.name,
      values: this.values
    };
  }
  static fromJSON(json, metadataStore) {
    let et = metadataStore._getStructuralType(json.entityType, true);
    return new EntityKey(et, json.values);
  }
  /**
  Used to compare EntityKeys are determine if they refer to the same Entity.
  There is also an static version of 'equals' with the same functionality.
  
  >      // assume em1 is an EntityManager containing a number of existing entities.
  >      var empType = em1.metadataStore.getEntityType("Employee");
  >      var empKey1 = new EntityKey(empType, 1);
  >      // assume employee1 is an existing Employee entity
  >      var empKey2 = employee1.entityAspect.getKey();
  >      if (empKey1.equals(empKey2)) {
  >          // do something  ...
  >      }
  **/
  equals(entityKey) {
    if (!(entityKey instanceof EntityKey)) return false;
    return this.entityType === entityKey.entityType && core.arrayEquals(this.values, entityKey.values);
  }
  /*
  Returns a human readable representation of this EntityKey.
  */
  toString(altEntityType) {
    return (altEntityType || this.entityType).name + '-' + this._keyInGroup;
  }
  /**
  Used to compare EntityKeys are determine if they refer to the same Entity.
  There is also an instance version of 'equals' with the same functionality.
  >      // assume em1 is an EntityManager containing a number of existing entities.
  >      var empType = em1.metadataStore.getEntityType("Employee");
  >      var empKey1 = new EntityKey(empType, 1);
  >      // assume employee1 is an existing Employee entity
  >      var empKey2 = employee1.entityAspect.getKey();
  >      if (EntityKey.equals(empKey1, empKey2)) {
  >          // do something  ...
  >      }
  **/
  static equals(k1, k2) {
    if (!(k1 instanceof EntityKey)) return false;
    return k1.equals(k2);
  }
  /** @hidden @internal */
  // TODO: we may want to compare to default values later.
  _isEmpty() {
    return this.values.join("").length === 0;
  }
  /** hidden */
  // TODO: think about giving _ prefix or documenting.
  static createKeyString(keyValues) {
    return keyValues.join(EntityKey.ENTITY_KEY_DELIMITER);
  }
}
/** @hidden @internal */
EntityKey.ENTITY_KEY_DELIMITER = ":::";
EntityKey.prototype._$typeName = "EntityKey";

/**
MergeStrategy is an 'Enum' that determines how entities are merged into an EntityManager.

@class MergeStrategy
@static
**/
class MergeStrategy extends BreezeEnum {}
/**
MergeStrategy.PreserveChanges updates the cached entity with the incoming values unless the cached entity is in a changed
state (added, modified, deleted) in which case the incoming values are ignored. The updated cached entity’s EntityState will
remain [[EntityState.Unchanged]] unless you’re importing entities in which case the new EntityState will
be that of the imported entities.
**/
MergeStrategy.PreserveChanges = new MergeStrategy();
/**
MergeStrategy.OverwriteChanges always updates the cached entity with incoming values even if the entity is in
a changed state (added, modified, deleted). After the merge, the pending changes are lost.
The new EntityState will be  [[EntityState/Unchanged]] unless you’re importing entities
in which case the new EntityState will be that of the imported entities.
**/
MergeStrategy.OverwriteChanges = new MergeStrategy();
/**
SkipMerge is used to ignore incoming values. Adds the incoming entity to the cache only if there is no cached entity with the same key.
This is the fastest merge strategy but your existing cached data will remain “stale”.
**/
MergeStrategy.SkipMerge = new MergeStrategy();
/**
Disallowed is used to throw an exception if there is an incoming entity with the same key as an entity already in the cache.
Use this strategy when you want to be sure that the incoming entity is not already in cache.
This is the default strategy for EntityManager.attachEntity.
**/
MergeStrategy.Disallowed = new MergeStrategy();
MergeStrategy.prototype._$typeName = "MergeStrategy";
Error['x'] = MergeStrategy.resolveSymbols();
/**
FetchStrategy is an 'Enum' that determines how and where entities are retrieved from as a result of a query.
**/
class FetchStrategy extends BreezeEnum {}
/**
FromServer is used to tell the query to execute the query against a remote data source on the server.
**/
FetchStrategy.FromServer = new FetchStrategy();
/**
FromLocalCache is used to tell the query to execute the query against a local EntityManager instead of going to a remote server.
**/
FetchStrategy.FromLocalCache = new FetchStrategy();
FetchStrategy.prototype._$typeName = "FetchStrategy";
Error['x'] = FetchStrategy.resolveSymbols();
/**
A QueryOptions instance is used to specify the 'options' under which a query will occur.
**/
class QueryOptions {
  /**
  QueryOptions constructor
  >     var newQo = new QueryOptions( { mergeStrategy: MergeStrategy.OverwriteChanges });
  >     // assume em1 is a preexisting EntityManager
  >     em1.setProperties( { queryOptions: newQo });
  Any QueryOptions property that is not defined will be defaulted from any QueryOptions defined at a higher level in the breeze hierarchy, i.e.
  -  from query.queryOptions
  -  to   entityManager.queryOptions
  -  to   QueryOptions.defaultInstance;
      @param config - A configuration object.
  **/
  constructor(config) {
    QueryOptions._updateWithConfig(this, config);
  }
  static resolve(queryOptionsArray) {
    return new QueryOptions(core.resolveProperties(queryOptionsArray, ["fetchStrategy", "mergeStrategy", "includeDeleted"]));
  }
  /**
  Returns a copy of this QueryOptions with the specified [[MergeStrategy]],
  [[FetchStrategy]], or 'includeDeleted' option applied.
  >     // Given an EntityManager instance, em
  >     var queryOptions = em.queryOptions.using(MergeStrategy.PreserveChanges);
      or
  >     var queryOptions = em.queryOptions.using(FetchStrategy.FromLocalCache);
      or
  >     var queryOptions = em.queryOptions.using({ mergeStrategy: MergeStrategy.OverwriteChanges });
      or
  >     var queryOptions = em.queryOptions.using({
  >        includeDeleted: true,
  >        fetchStrategy:  FetchStrategy.FromLocalCache
  >     });
  @param config - A configuration object or a standalone [[MergeStrategy]] or [[FetchStrategy]]
  @return A new QueryOptions instance.
  **/
  using(qoConfig) {
    if (!qoConfig) return this;
    let result = new QueryOptions(this);
    if (qoConfig instanceof MergeStrategy) {
      qoConfig = {
        mergeStrategy: qoConfig
      };
    } else if (qoConfig instanceof FetchStrategy) {
      qoConfig = {
        fetchStrategy: qoConfig
      };
    }
    return QueryOptions._updateWithConfig(result, qoConfig);
  }
  /**
  Sets the 'defaultInstance' by creating a copy of the current 'defaultInstance' and then applying all of the properties of the current instance.
  The current instance is returned unchanged.
  >     var newQo = new QueryOptions( { mergeStrategy: MergeStrategy.OverwriteChanges });
  >     newQo.setAsDefault();
  **/
  setAsDefault() {
    return core.setAsDefault(this, QueryOptions);
  }
  toJSON() {
    return core.toJson(this, {
      fetchStrategy: null,
      mergeStrategy: null,
      includeDeleted: false
    });
  }
  static fromJSON(json) {
    return new QueryOptions({
      fetchStrategy: FetchStrategy.fromName(json.fetchStrategy),
      mergeStrategy: MergeStrategy.fromName(json.mergeStrategy),
      includeDeleted: json.includeDeleted === true
    });
  }
  /** @hidden @internal */
  static _updateWithConfig(obj, config) {
    if (config) {
      assertConfig(config).whereParam("fetchStrategy").isEnumOf(FetchStrategy).isOptional().whereParam("mergeStrategy").isEnumOf(MergeStrategy).isOptional().whereParam("includeDeleted").isBoolean().isOptional().applyAll(obj);
    }
    return obj;
  }
}
/**
The default instance for use whenever QueryOptions are not specified.
**/
QueryOptions.defaultInstance = new QueryOptions({
  fetchStrategy: FetchStrategy.FromServer,
  mergeStrategy: MergeStrategy.PreserveChanges,
  includeDeleted: false
});
QueryOptions.prototype._$typeName = "QueryOptions";

/**
Used to define a 'where' predicate for an [[EntityQuery]].  Predicates are immutable, which means that any
method that would modify a Predicate actually returns a new Predicate.
**/
class Predicate {
  /**
  Predicate constructor
  >     let p1 = new Predicate("CompanyName", "StartsWith", "B");
  >     let query = new EntityQuery("Customers").where(p1);
      or
  >     let p2 = new Predicate("Region", FilterQueryOp.Equals, null);
  >     let query = new EntityQuery("Customers").where(p2);
  @param property - A property name, a nested property name or an expression involving a property name.
  @param operator -
  @param value - This will be treated as either a property expression or a literal depending on context.  In general,
  if the value can be interpreted as a property expression it will be, otherwise it will be treated as a literal.
  In most cases this works well, but you can also force the interpretation by making the value argument itself an object with a 'value'
  property and an 'isLiteral' property set to either true or false.  Breeze also tries to infer the dataType of any
  literal based on context, if this fails you can force this inference by making the value argument an object with a
  'value' property and a 'dataType' property set to one of the breeze.DataType enumeration instances.
  **/
  constructor(...args) {
    if (args.length === 0) return;
    if (!(this instanceof Predicate)) {
      return new Predicate(...args);
    }
    return Predicate.create(...args);
  }
  /**
  Same as using the ctor.
  >      // so
  >      let p = Predicate.create(a, b, c);
  >      // is the same as
  >      let p = new Predicate(a, b, c);
  @param property -  A property name, a nested property name or an expression involving a property name.
  @param operator - the filter query operator.
  @param value - This will be treated as either a property expression or a literal depending on context.  In general,
  if the value can be interpreted as a property expression it will be, otherwise it will be treated as a literal.
  In most cases this works well, but you can also force the interpretation by making the value argument itself an object with a 'value'
  property and an 'isLiteral' property set to either true or false.  Breeze also tries to infer the dataType of any
  literal based on context, if this fails you can force this inference by making the value argument an object with a
  'value' property and a 'dataType' property set to one of the breeze.DataType enumeration instances.
  **/
  static create(...args) {
    // can be called from std javascript without new ( legacy )
    // empty ctor is used by all subclasses.
    if (args.length === 0) return new Predicate();
    if (args.length === 1) {
      // possibilities:
      //      Predicate([ aPredicate ]) or  Predicate(["freight", ">", 100]) - an array
      //      Predicate(aPredicate) - a predicate
      //      Predicate( "freight gt 100" }  // passthru ( i.e. maybe an odata string) - a string
      //      Predicate( { freight: { ">": 100 } }) - an object
      let arg = arguments[0];
      if (Array.isArray(arg)) {
        if (arg.length === 1) {
          // recurse
          return new Predicate(arg[0]);
        } else {
          return createPredicateFromArray(arg);
        }
      } else if (arg instanceof Predicate) {
        return arg;
      } else if (typeof arg === 'string') {
        return new PassthruPredicate(arg);
      } else {
        return createPredicateFromObject(arg);
      }
    } else {
      // 2 possibilities
      //      Predicate("freight", ">", 100");
      //      Predicate("orders", "any", "freight",  ">", 950);
      return createPredicateFromArray(args);
    }
  }
  /** @hidden @internal */
  _validate(entityType, usesNameOnServer) {
    // noop here;
  }
  /**
  Creates a 'composite' Predicate by 'and'ing a set of specified Predicates together.
  >      let dt = new Date(88, 9, 12);
  >      let p1 = Predicate.create("OrderDate", "ne", dt);
  >      let p2 = Predicate.create("ShipCity", "startsWith", "C");
  >      let p3 = Predicate.create("Freight", ">", 100);
  >      let newPred = Predicate.and(p1, p2, p3);
      or
  >      let preds = [p1, p2, p3];
  >      let newPred = Predicate.and(preds);
  @param predicates - multiple Predicates or an array of Predicate.
  Any null or undefined values passed in will be automatically filtered out before constructing the composite predicate.
  **/
  static and(...args) {
    let pred = new AndOrPredicate("and", args);
    // TODO removed below
    // return undefined if empty
    // return pred.op && pred;
    return pred;
  }
  /**
  Creates a 'composite' Predicate by 'or'ing a set of specified Predicates together.
  >      let dt = new Date(88, 9, 12);
  >      let p1 = Predicate.create("OrderDate", "ne", dt);
  >      let p2 = Predicate.create("ShipCity", "startsWith", "C");
  >      let p3 = Predicate.create("Freight", ">", 100);
  >      let newPred = Predicate.or(p1, p2, p3);
      or
  >      let preds = [p1, p2, p3];
  >      let newPred = Predicate.or(preds);
  @param predicates - multiple Predicates or an array of Predicate.
  Any null or undefined values passed in will be automatically filtered out before constructing the composite predicate.
  **/
  static or(...args) {
    let pred = new AndOrPredicate("or", args);
    // return pred.op && pred;
    return pred;
  }
  /**
  Creates a 'composite' Predicate by 'negating' a specified predicate.
  >      let p1 = Predicate.create("Freight", "gt", 100);
  >      let not_p1 = Predicate.not(p1);
      This can also be accomplished using the 'instance' version of the 'not' method
  >      let not_p1 = p1.not();
      Both of which would be the same as
  >      let not_p1 = Predicate.create("Freight", "le", 100);
  **/
  static not(pred) {
    return pred.not();
  }
  // TODO: determine if/where this is used.
  // static extendBinaryPredicateFn(opMap: IOpMap, visitorFn: any) {
  //   let baseVisitorFn = toFunctionVisitor.binaryPredicate;
  //   for (let op in (opMap || {})) {
  //     let config = opMap[op];
  //     config.visitorFn = visitorFn;
  //     updateAliasMap(BinaryPredicate.prototype.aliasMap, op, opMap[op]);
  //   }
  //   if (!toFunctionVisitor.isExtended) {
  //     toFunctionVisitor.binaryPredicate = function (context, expr1Val, expr2Val) {
  //       let visitorFn = this.aliasMap[this.op.key].visitorFn;
  //       if (visitorFn) {
  //         return visitorFn(context, expr1Val, expr2Val);
  //       } else {
  //         return baseVisitorFn(context, expr1Val, expr2Val);
  //       }
  //     };
  //     toFunctionVisitor.isExtended = true;
  //   }
  // };
  static extendFuncMap(funcMap) {
    for (let func in funcMap || {}) {
      let config = funcMap[func];
      FnExpr._funcMap[func] = config;
    }
  }
  /**
  'And's this Predicate with one or more other Predicates and returns a new 'composite' Predicate
  >      let dt = new Date(88, 9, 12);
  >      let p1 = Predicate.create("OrderDate", "ne", dt);
  >      let p2 = Predicate.create("ShipCity", "startsWith", "C");
  >      let p3 = Predicate.create("Freight", ">", 100);
  >      let newPred = p1.and(p2, p3);
      or
  >      let preds = [p2, p3];
  >      let newPred = p1.and(preds);
      The 'and' method is also used to write "fluent" expressions
  >      let p4 = Predicate.create("ShipCity", "startswith", "F")
  >        .and("Size", "gt", 2000);
  @param predicates - multiple Predicates or an array of Predicates.
  Any null or undefined values passed in will be automatically filtered out before constructing the composite predicate.
  **/
  and(...args) {
    return new AndOrPredicate("and", argsForAndOrPredicates(this, args));
  }
  /**
  'Or's this Predicate with one or more other Predicates and returns a new 'composite' Predicate
  >      let dt = new Date(88, 9, 12);
  >      let p1 = Predicate.create("OrderDate", "ne", dt);
  >      let p2 = Predicate.create("ShipCity", "startsWith", "C");
  >      let p3 = Predicate.create("Freight", ">", 100);
  >      let newPred = p1.or(p2, p3);
      or
  >      let preds = [p2, p3];
  >      let newPred = p1.or(preds);
      The 'or' method is also used to write "fluent" expressions
  >      let p4 = Predicate.create("ShipCity", "startswith", "F")
  >        .or("Size", "gt", 2000);
  @param predicates - multiple Predicates or an array of Predicates.
  Any null or undefined values passed in will be automatically filtered out before constructing the composite predicate.
  **/
  or(...args) {
    return new AndOrPredicate("or", argsForAndOrPredicates(this, args));
  }
  /**
  Returns the 'negated' version of this Predicate
  >      let p1 = Predicate.create("Freight", "gt", 100);
  >      let not_p1 = p1.not();
      This can also be accomplished using the 'static' version of the 'not' method
  >      let p1 = Predicate.create("Freight", "gt", 100);
  >      let not_p1 = Predicate.not(p1);
      which would be the same as
  >      let not_p1 = Predicate.create("Freight", "le", 100);
  **/
  not() {
    return new UnaryPredicate("not", this);
  }
  //
  toJSON() {
    // toJSON ( part of js standard - takes a single parameter
    // that is either "" or the name of the property being serialized.
    return this.toJSONExt({
      entityType: this._entityType
    });
  }
  /** For use by breeze plugin authors only. The class is for use in building a [[IUriBuilderAdapter]] implementation.
  @adapter (see [[IUriBuilderAdapter]])
  @hidden @internal
  */
  toJSONExt(context) {
    return this.visit(context, toJSONVisitor);
  }
  /** For use by breeze plugin authors only. The class is for use in building a [[IUriBuilderAdapter]] implementation.
  @adapter (see [[IUriBuilderAdapter]])
  @hidden @internal
  */
  toFunction(context) {
    return this.visit(context, toFunctionVisitor);
  }
  toString() {
    return JSON.stringify(this);
  }
  /** For use by breeze plugin authors only. The class is for use in building a [[IUriBuilderAdapter]] implementation.
  @adapter (see [[IUriBuilderAdapter]])
  @hidden @internal
  */
  visit(context, visitor) {
    if (core.isEmpty(context)) {
      context = {
        entityType: undefined
      };
    } else if (context instanceof EntityType) {
      context = {
        entityType: context
      };
    } else if (!core.hasOwnProperty(context, "entityType")) {
      throw new Error("All visitor methods must be called with a context object containing at least an 'entityType' property");
    }
    if (visitor) {
      context.visitor = visitor;
    }
    let tVisitor = visitor || context.visitor;
    let fn = tVisitor[this.visitorMethodName];
    if (fn == null) {
      throw new Error("Unable to locate method: " + this.visitorMethodName + " on visitor");
    }
    let entityType = context.entityType;
    // don't bother validating if already done so ( or if no _validate method
    if (this._validate && (entityType == null || this._entityType !== entityType)) {
      // don't need to capture return value because validation fn doesn't have one.
      // TODO: this was old code
      // this._validate(entityType, context.usesNameOnServer);
      this._validate(entityType, context.toNameOnServer);
      this._entityType = entityType;
    }
    return fn.call(this, context);
  }
  /** @hidden @internal */
  _initialize(visitorMethodName, opMap = {}) {
    this.visitorMethodName = visitorMethodName;
    let aliasMap = this.aliasMap = {};
    for (let op in opMap) {
      updateAliasMap(aliasMap, op, opMap[op]);
    }
  }
  /** @hidden @internal */
  _resolveOp(op, okIfNotFound) {
    let opStr = typeof op === "string" ? op : op.operator;
    let result = this.aliasMap[opStr.toLowerCase()];
    if (!result && !okIfNotFound) {
      throw new Error("Unable to resolve operator: " + opStr);
    }
    return result;
  }
}
function createPredicateFromArray(arr) {
  // TODO: assert that length of the array should be > 3
  // Needs to handle:
  //      [ "freight", ">", 100"];
  //      [ "orders", "any", "freight",  ">", 950 ]
  //      [ "orders", "and", anotherPred ]
  //      [ "orders", "and", [ "freight, ">", 950 ]]
  let json = {};
  let value = {};
  json[arr[0]] = value;
  let op = arr[1];
  op = op.operator || op; // incoming op will be either a string or a FilterQueryOp
  if (arr.length === 3) {
    value[op] = arr[2];
  } else {
    value[op] = createPredicateFromArray(arr.splice(2));
  }
  return createPredicateFromObject(json);
}
function createPredicateFromObject(obj) {
  if (obj instanceof Predicate) return obj;
  if (typeof obj !== 'object') {
    throw new Error("Unable to convert to a Predicate: " + obj);
  }
  let keys = Object.keys(obj);
  let preds = keys.map(function (key) {
    return createPredicateFromKeyValue(key, obj[key]);
  });
  return preds.length === 1 ? preds[0] : new AndOrPredicate("and", preds);
}
function createPredicateFromKeyValue(key, value) {
  // { and: [a,b] } key='and', value = [a,b]
  if (AndOrPredicate.prototype._resolveOp(key, true)) {
    return new AndOrPredicate(key, value);
  }
  // { not: a }  key= 'not', value = a
  if (UnaryPredicate.prototype._resolveOp(key, true)) {
    return new UnaryPredicate(key, value);
  }
  if (typeof value !== 'object' || value == null || core.isDate(value)) {
    // { foo: bar } key='foo', value = bar ( where bar is a literal i.e. a string, a number, a boolean or a date.
    return new BinaryPredicate("eq", key, value);
  } else if (core.hasOwnProperty(value, 'value')) {
    // { foo: { value: bar, dataType: xxx} } key='foo', value = bar ( where bar is an object representing a literal
    return new BinaryPredicate("eq", key, value);
  }
  if (Array.isArray(value)) {
    throw new Error("Unable to resolve predicate after the phrase: " + key);
  }
  let expr = key;
  let keys = Object.keys(value);
  let preds = keys.map(function (op) {
    // { a: { any: b } op = 'any', expr=a, value[op] = b
    if (AnyAllPredicate.prototype._resolveOp(op, true)) {
      return new AnyAllPredicate(op, expr, value[op]);
    }
    if (BinaryPredicate.prototype._resolveOp(op, true)) {
      // { a: { ">": b }} op = ">", expr=a, value[op] = b
      return new BinaryPredicate(op, expr, value[op]);
    } else if (core.hasOwnProperty(value[op], 'value')) {
      // { a: { ">": { value: b, dataType: 'Int32' }} expr = a value[op] = { value: b, dataType: 'Int32' }
      return new BinaryPredicate("eq", expr, value[op]);
    }
    let msg = core.formatString("Unable to resolve predicate after the phrase: '%1' for operator: '%2'  and value: '%3'", expr, op, value[op]);
    throw new Error(msg);
  });
  return preds.length === 1 ? preds[0] : new AndOrPredicate("and", preds);
}
function argsForAndOrPredicates(obj, args) {
  let preds = args[0];
  if (preds instanceof Predicate) {
    preds = core.arraySlice(args);
  } else if (!Array.isArray(preds)) {
    preds = [new Predicate(core.arraySlice(args))];
  }
  return [obj].concat(preds);
}
function updateAliasMap(aliasMap, opStr, op) {
  let key = opStr.toLowerCase();
  op.key = key;
  aliasMap[key] = op;
  op.aliases && op.aliases.forEach(alias => {
    aliasMap[alias.toLowerCase()] = op;
  });
}
/** For use by breeze plugin authors only. The class is for use in building a [[IUriBuilderAdapter]] implementation.
@adapter (see [[IUriBuilderAdapter]])
@hidden @internal
*/
class PassthruPredicate extends Predicate {
  constructor(value) {
    super();
    this.value = value;
  }
}
Error['x'] = PassthruPredicate.prototype._initialize('passthruPredicate');
/** For use by breeze plugin authors only. The class is for use in building a [[IUriBuilderAdapter]] implementation.
@adapter (see [[IUriBuilderAdapter]])
@hidden
*/
class UnaryPredicate extends Predicate {
  constructor(op, ...args) {
    super();
    this.op = this._resolveOp(op);
    this.pred = new Predicate(args);
  }
  _validate(entityType, usesNameOnServer) {
    this.pred._validate(entityType, usesNameOnServer);
  }
}
Error['x'] = UnaryPredicate.prototype._initialize('unaryPredicate', {
  'not': {
    aliases: ['!', '~']
  }
});
/** For use by breeze plugin authors only. The class is for use in building a [[IUriBuilderAdapter]] implementation.
@adapter (see [[IUriBuilderAdapter]])
@hidden
*/
class BinaryPredicate extends Predicate {
  constructor(op, expr1, expr2) {
    super();
    // 5 public props op, expr1Source, expr2Source, expr1, expr2
    this.op = this._resolveOp(op);
    this.expr1Source = expr1;
    this.expr2Source = expr2;
    // this.expr1 and this.expr2 won't be
    // determined until validate is run
  }
  _validate(entityType, usesNameOnServer) {
    let expr1Context = {
      entityType: entityType,
      usesNameOnServer: usesNameOnServer
    };
    this.expr1 = createExpr(this.expr1Source, expr1Context);
    if (this.expr1 == null) {
      throw new Error("Unable to validate 1st expression: " + this.expr1Source);
    }
    if (this.expr1 instanceof LitExpr) {
      // lhs must be either a property or a function.
      throw new Error("The left hand side of a binary predicate cannot be a literal expression, it must be a valid property or functional predicate expression: " + this.expr1Source);
    }
    if (this.op.key === 'in' && !Array.isArray(this.expr2Source)) {
      throw new Error("The 'in' operator requires that its right hand argument be an array");
    }
    let expr2Context = core.extend(expr1Context, {
      isRHS: true,
      dataType: this.expr1.dataType
    });
    this.expr2 = createExpr(this.expr2Source, expr2Context);
    if (this.expr2 == null) {
      throw new Error("Unable to validate 2nd expression: " + this.expr2Source);
    }
    if (this.expr1.dataType == null) {
      this.expr1.dataType = this.expr2.dataType;
    }
  }
}
Error['x'] = BinaryPredicate.prototype._initialize('binaryPredicate', {
  'eq': {
    aliases: ["==", "equals"]
  },
  'ne': {
    aliases: ["!=", "~=", "notequals"]
  },
  'lt': {
    aliases: ["<", "lessthan"]
  },
  'le': {
    aliases: ["<=", "lessthanorequal"]
  },
  'gt': {
    aliases: [">", "greaterthan"]
  },
  'ge': {
    aliases: [">=", "greaterthanorequal"]
  },
  'startswith': {
    isFunction: true
  },
  'endswith': {
    isFunction: true
  },
  'contains': {
    aliases: ["substringof"],
    isFunction: true
  },
  'in': {}
});
/** For use by breeze plugin authors only. The class is for use in building a [[IUriBuilderAdapter]] implementation.
@adapter (see [[IUriBuilderAdapter]])
@hidden
*/
class AndOrPredicate extends Predicate {
  constructor(op, preds) {
    super();
    this.op = this._resolveOp(op);
    if (preds.length === 1 && Array.isArray(preds[0])) {
      preds = preds[0];
    }
    this.preds = preds.filter(function (pred) {
      return pred != null;
    }).map(function (pred) {
      return new Predicate(pred);
    });
    // TODO: this was removed - test if really needed.
    // if (this.preds.length === 0) {
    //   // marker for an empty predicate
    //   this.op = null;
    // }
    if (this.preds.length === 1) {
      return this.preds[0]; // HACK: this.preds[0] is actually NOT a AndOrPredicate but some other kind of pred.
    }
  }
  _validate(entityType, usesNameOnServer) {
    this.preds.forEach(pred => {
      pred._validate(entityType, usesNameOnServer);
    });
  }
}
Error['x'] = AndOrPredicate.prototype._initialize("andOrPredicate", {
  'and': {
    aliases: ['&&']
  },
  'or': {
    aliases: ['||']
  }
});
/** For use by breeze plugin authors only. The class is for use in building a [[IUriBuilderAdapter]] implementation.
@adapter (see [[IUriBuilderAdapter]])
@hidden
*/
class AnyAllPredicate extends Predicate {
  // 4 public props: op, exprSource, expr, pred
  constructor(op, expr, pred) {
    super();
    this.op = this._resolveOp(op);
    this.exprSource = expr;
    // this.expr will not be resolved until validate is called
    this.pred = new Predicate(pred);
  }
  _validate(entityType, usesNameOnServer) {
    this.expr = createExpr(this.exprSource, {
      entityType: entityType,
      usesNameOnServer: usesNameOnServer
    });
    // can't really know the predicateEntityType unless the original entity type was known.
    if (entityType == null || entityType.isAnonymous) {
      this.expr.dataType = undefined;
    }
    this.pred._validate(this.expr.dataType, usesNameOnServer);
  }
}
Error['x'] = AnyAllPredicate.prototype._initialize("anyAllPredicate", {
  'any': {
    aliases: ['some']
  },
  'all': {
    aliases: ["every"]
  }
});
/** @hidden */
class PredicateExpression {
  constructor(visitorMethodName) {
    this.visitorMethodName = visitorMethodName;
    // give expressions the Predicate prototype method
    this.visit = Predicate.prototype.visit;
  }
  // default impls - may/will be overridden be subclass expressions
  _validate(entityType, usesNameOnServer) {
    // noop;
  }
}
/** For use by breeze plugin authors only. The class is for use in building a [[IUriBuilderAdapter]] implementation.
@adapter (see [[IUriBuilderAdapter]])
@hidden
*/
class LitExpr extends PredicateExpression {
  // 2 public props: value, dataType
  constructor(value, dataType, hasExplicitDataType) {
    super("litExpr");
    // dataType may come is an a string
    let dt1 = resolveDataType(dataType);
    // if the DataType comes in as Undefined this means
    // that we should NOT attempt to parse it but just leave it alone
    // for now - this is usually because it is part of a Func expr.
    // TODO: cast as DataType seems to be needed by early version of TypeDoc - may be able to remove later
    let dt2 = dt1 || DataType.fromValue(value);
    if (dt2.parse) {
      if (Array.isArray(value)) {
        this.value = value.map(v => {
          return dt2.parse(v, typeof v);
        });
      } else {
        this.value = dt2.parse(value, typeof value);
      }
    } else {
      this.value = value;
    }
    this.dataType = dt2;
    this.hasExplicitDataType = !!hasExplicitDataType;
  }
  toString() {
    return " LitExpr - value: " + this.value.toString() + " dataType: " + this.dataType.toString();
  }
}
function resolveDataType(dataType) {
  if (dataType == null) return dataType;
  // if (DataType.contains(dataType)) {
  if (dataType instanceof DataType) {
    return dataType;
  }
  if (typeof dataType === 'string') {
    let dt = DataType.fromName(dataType);
    if (dt) return dt;
    throw new Error("Unable to resolve a dataType named: " + dataType);
  }
  throw new Error("The dataType parameter passed into this literal expression is not a 'DataType'" + dataType);
}
/** For use by breeze plugin authors only. The class is for use in building a [[IUriBuilderAdapter]] implementation.
@adapter (see [[IUriBuilderAdapter]])
@hidden
*/
class PropExpr extends PredicateExpression {
  // two public props: propertyPath, dateType
  constructor(propertyPath) {
    super('propExpr');
    this.propertyPath = propertyPath;
    //this.dataType = DataType.Undefined;
    // this.dataType resolved after validate ( if not on an anon type }
  }
  toString() {
    return " PropExpr - " + this.propertyPath;
  }
  _validate(entityType, usesNameOnServer) {
    if (entityType == null || entityType.isAnonymous) return;
    let props = entityType.getPropertiesOnPath(this.propertyPath, null, false);
    if (!props) {
      let msg = core.formatString("Unable to resolve propertyPath.  EntityType: '%1'   PropertyPath: '%2'", entityType.name, this.propertyPath);
      throw new Error(msg);
    }
    // get the last property
    let prop = props[props.length - 1];
    if (prop instanceof DataProperty) {
      this.dataType = prop.dataType;
    } else {
      this.dataType = prop.entityType;
    }
  }
}
/** For use by breeze plugin authors only. The class is for use in building a [[IUriBuilderAdapter]] implementation.
@adapter (see [[IUriBuilderAdapter]])
@hidden @dynamic
*/
class FnExpr extends PredicateExpression {
  constructor(fnName, exprs) {
    super('fnExpr');
    // 4 public props: fnName, exprs, localFn, dataType
    this.fnName = fnName;
    this.exprs = exprs;
    let qf = FnExpr._funcMap[fnName];
    if (qf == null) {
      throw new Error("Unknown function: " + fnName);
    }
    this.localFn = qf.fn;
    this.dataType = qf.dataType;
  }
  toString() {
    let exprStr = this.exprs.map(function (expr) {
      expr.toString();
    }).toString();
    return "FnExpr - " + this.fnName + "(" + exprStr + ")";
  }
  _validate(entityType, usesNameOnServer) {
    this.exprs.forEach(function (expr) {
      expr._validate(entityType, usesNameOnServer);
    });
  }
}
FnExpr._funcMap = {
  toupper: {
    fn: function (source) {
      return source.toUpperCase();
    },
    dataType: DataType.String
  },
  tolower: {
    fn: function (source) {
      return source.toLowerCase();
    },
    dataType: DataType.String
  },
  substring: {
    fn: function (source, pos, length) {
      return source.substring(pos, length);
    },
    dataType: DataType.String
  },
  substringof: {
    fn: function (find, source) {
      return source.indexOf(find) >= 0;
    },
    dataType: DataType.Boolean
  },
  length: {
    fn: function (source) {
      return source.length;
    },
    dataType: DataType.Int32
  },
  trim: {
    fn: function (source) {
      return source.trim();
    },
    dataType: DataType.String
  },
  concat: {
    fn: function (s1, s2) {
      return s1.concat(s2);
    },
    dataType: DataType.String
  },
  replace: {
    fn: function (source, find, replace) {
      return source.replace(find, replace);
    },
    dataType: DataType.String
  },
  startswith: {
    fn: function (source, find) {
      return core.stringStartsWith(source, find);
    },
    dataType: DataType.Boolean
  },
  endswith: {
    fn: function (source, find) {
      return core.stringEndsWith(source, find);
    },
    dataType: DataType.Boolean
  },
  indexof: {
    fn: function (source, find) {
      return source.indexOf(find);
    },
    dataType: DataType.Int32
  },
  round: {
    fn: function (source) {
      return Math.round(source);
    },
    dataType: DataType.Int32
  },
  ceiling: {
    fn: function (source) {
      return Math.ceil(source);
    },
    dataType: DataType.Int32
  },
  floor: {
    fn: function (source) {
      return Math.floor(source);
    },
    dataType: DataType.Int32
  },
  second: {
    fn: function (source) {
      return source.getSeconds();
    },
    dataType: DataType.Int32
  },
  minute: {
    fn: function (source) {
      return source.getMinutes();
    },
    dataType: DataType.Int32
  },
  day: {
    fn: function (source) {
      return source.getDate();
    },
    dataType: DataType.Int32
  },
  month: {
    fn: function (source) {
      return source.getMonth() + 1;
    },
    dataType: DataType.Int32
  },
  year: {
    fn: function (source) {
      return source.getFullYear();
    },
    dataType: DataType.Int32
  }
};
// TODO: add dataTypes for the args next - will help to infer other dataTypes.
let RX_IDENTIFIER = /^[a-z_][\w.$]*$/i;
// comma delimited expressions ignoring commas inside of both single and double quotes.
let RX_COMMA_DELIM1 = /('[^']*'|[^,]+)/g;
let RX_COMMA_DELIM2 = /("[^"]*"|[^,]+)/g;
let DELIM = String.fromCharCode(191);
function createExpr(source, exprContext) {
  let entityType = exprContext.entityType;
  // the right hand side of an 'in' clause
  if (Array.isArray(source)) {
    if (!exprContext.isRHS) {
      throw new Error("Array expressions are only permitted on the right hand side of a BinaryPredicate");
    }
    return new LitExpr(source, exprContext.dataType);
  }
  if (!(typeof source === 'string')) {
    if (source != null && typeof source === 'object' && !source.toISOString) {
      // source is an object but not a Date-like thing such as a JS or MomentJS Date
      if (source.value === undefined) {
        throw new Error("Unable to resolve an expression for: " + source + " on entityType: " + (entityType ? entityType.name : 'null'));
      }
      if (source.isProperty) {
        return new PropExpr(source.value);
      } else {
        // we want to insure that any LitExpr created this way is tagged with 'hasExplicitDataType: true'
        // because we want to insure that if we roundtrip thru toJSON that we don't
        // accidentally reinterpret this node as a PropExpr.
        // return new LitExpr(source.value, source.dataType || context.dataType, !!source.dataType);
        return new LitExpr(source.value, source.dataType || exprContext.dataType, true);
      }
    } else {
      return new LitExpr(source, exprContext.dataType);
    }
  }
  if (exprContext.isRHS) {
    if (entityType == null || entityType.isAnonymous) {
      // if entityType is unknown then assume that the rhs is a literal
      return new LitExpr(source, exprContext.dataType);
    } else {
      return parseLitOrPropExpr(source, exprContext);
    }
  } else {
    let regex = /\([^()]*\)/;
    let m;
    let tokens = [];
    let i = 0;
    while (m = regex.exec(source)) {
      let token = m[0];
      tokens.push(token);
      let repl = DELIM + i++;
      source = source.replace(token, repl);
    }
    let expr = parseExpr(source, tokens, exprContext);
    expr._validate(entityType, exprContext.usesNameOnServer);
    return expr;
  }
}
function parseExpr(source, tokens, exprContext) {
  let parts = source.split(DELIM);
  if (parts.length === 1) {
    return parseLitOrPropExpr(parts[0], exprContext);
  } else {
    return parseFnExpr(source, parts, tokens, exprContext);
  }
}
function parseLitOrPropExpr(value, exprContext) {
  value = value.trim();
  // value is either a string, a quoted string, a number, a bool value, or a date
  // if a string ( not a quoted string) then this represents a property name ( 1st ) or a lit string ( 2nd)
  let firstChar = value.substr(0, 1);
  let isQuoted = (firstChar === "'" || firstChar === '"') && value.length > 1 && value.substr(value.length - 1) === firstChar;
  if (isQuoted) {
    let unquotedValue = value.substr(1, value.length - 2);
    return new LitExpr(unquotedValue, exprContext.dataType || DataType.String);
  } else {
    let entityType = exprContext.entityType;
    // TODO: get rid of isAnonymous below when we get the chance.
    if (entityType == null || entityType.isAnonymous) {
      // this fork will only be reached on the LHS of an BinaryPredicate -
      // a RHS expr cannot get here with an anon type
      return new PropExpr(value);
    } else {
      let mayBeIdentifier = RX_IDENTIFIER.test(value);
      if (mayBeIdentifier) {
        // if (entityType.getProperty(value, false) != null) {
        if (entityType.getPropertiesOnPath(value, null, false) != null) {
          return new PropExpr(value);
        }
      }
    }
    // we don't really know the datatype here because even though it comes in as a string
    // its usually a string BUT it might be a number  i.e. the "1" or the "2" from an expr
    // like "toUpper(substring(companyName, 1, 2))"
    return new LitExpr(value, exprContext.dataType);
  }
}
function parseFnExpr(source, parts, tokens, exprContext) {
  try {
    let fnName = parts[0].trim().toLowerCase();
    let argSource = tokens[parts[1]].trim();
    if (argSource.substr(0, 1) === "(") {
      argSource = argSource.substr(1, argSource.length - 2);
    }
    let commaMatchStr = source.indexOf("'") >= 0 ? RX_COMMA_DELIM1 : RX_COMMA_DELIM2;
    let args = argSource.match(commaMatchStr);
    let newContext = core.extend({}, exprContext);
    // a dataType of Undefined on a context basically means not to try parsing
    // the value if the expr is a literal
    newContext.dataType = DataType.Undefined;
    newContext.isFnArg = true;
    let exprs = args.map(function (a) {
      return parseExpr(a, tokens, newContext);
    });
    return new FnExpr(fnName, exprs);
  } catch (e) {
    // TODO: removed old code here
    // return null;
    // and replaced with 
    throw e;
  }
}
const ɵ0$2 = function () {
    throw new Error("Cannot execute an PassthruPredicate expression against the local cache: " + this.value);
  },
  ɵ1$1 = function (context) {
    let predFn = this.pred.visit(context);
    switch (this.op.key) {
      case "not":
        return function (entity) {
          return !predFn(entity);
        };
      default:
        throw new Error("Invalid unary operator:" + this.op.key);
    }
  },
  ɵ2 = function (context) {
    let expr1Fn = this.expr1.visit(context);
    let expr2Fn = this.expr2.visit(context);
    let dataType = this.expr1.dataType || this.expr2.dataType;
    let lqco = context.entityType.metadataStore.localQueryComparisonOptions;
    let predFn = getBinaryPredicateFn(this, dataType, lqco);
    if (predFn == null) {
      throw new Error("Invalid binaryPredicate operator:" + this.op.key);
    }
    return function (entity) {
      return predFn(expr1Fn(entity), expr2Fn(entity));
    };
  },
  ɵ3 = function (context) {
    let predFns = this.preds.map(pred => {
      return pred.visit(context);
    });
    switch (this.op.key) {
      case "and":
        return function (entity) {
          let result = predFns.reduce(function (prev, cur) {
            return prev && cur(entity);
          }, true);
          return result;
        };
      case "or":
        return function (entity) {
          let result = predFns.reduce(function (prev, cur) {
            return prev || cur(entity);
          }, false);
          return result;
        };
      default:
        throw new Error("Invalid boolean operator:" + this.op.key);
    }
  },
  ɵ4 = function (context) {
    let exprFn = this.expr.visit(context);
    let newContext = core.extend({}, context);
    newContext.entityType = this.expr.dataType;
    let predFn = this.pred.visit(newContext);
    let anyAllPredFn = getAnyAllPredicateFn(this.op);
    return function (entity) {
      return anyAllPredFn(exprFn(entity), predFn);
    };
  },
  ɵ5 = function () {
    let value = this.value;
    return function (entity) {
      return value;
    };
  },
  ɵ6 = function () {
    let propertyPath = this.propertyPath;
    let properties = propertyPath.split('.');
    if (properties.length === 1) {
      return function (entity) {
        return entity.getProperty(propertyPath);
      };
    } else {
      return function (entity) {
        return EntityAspect.getPropertyPathValue(entity, properties);
      };
    }
  },
  ɵ7 = function (context) {
    let exprFns = this.exprs.map(function (expr) {
      return expr.visit(context);
    });
    let that = this;
    return function (entity) {
      let values = exprFns.map(function (exprFn) {
        let value = exprFn(entity);
        return value;
      });
      let result = that.localFn.apply(null, values);
      return result;
    };
  };
// toFunctionVisitor
let toFunctionVisitor = {
  isExtended: false,
  passthruPredicate: ɵ0$2,
  unaryPredicate: ɵ1$1,
  binaryPredicate: ɵ2,
  andOrPredicate: ɵ3,
  anyAllPredicate: ɵ4,
  litExpr: ɵ5,
  propExpr: ɵ6,
  fnExpr: ɵ7
};
function getAnyAllPredicateFn(op) {
  switch (op.key) {
    case "any":
      return function (v1, v2) {
        return v1.some(function (v) {
          return v2(v);
        });
      };
    case "all":
      return function (v1, v2) {
        return v1.every(function (v) {
          return v2(v);
        });
      };
    default:
      throw new Error("Unknown operator: " + op.key);
  }
}
function getBinaryPredicateFn(binaryPredicate, dataType, lqco) {
  let op = binaryPredicate.op;
  let mc = DataType.getComparableFn(dataType);
  let predFn;
  switch (op.key) {
    case 'eq':
      predFn = function (v1, v2) {
        if (v1 && typeof v1 === 'string') {
          return stringEquals(v1, v2, lqco);
        } else {
          return mc(v1) === mc(v2);
        }
      };
      break;
    case 'ne':
      predFn = function (v1, v2) {
        if (v1 && typeof v1 === 'string') {
          return !stringEquals(v1, v2, lqco);
        } else {
          return mc(v1) !== mc(v2);
        }
      };
      break;
    case 'gt':
      predFn = function (v1, v2) {
        return mc(v1) > mc(v2);
      };
      break;
    case 'ge':
      predFn = function (v1, v2) {
        return mc(v1) >= mc(v2);
      };
      break;
    case 'lt':
      predFn = function (v1, v2) {
        return mc(v1) < mc(v2);
      };
      break;
    case 'le':
      predFn = function (v1, v2) {
        return mc(v1) <= mc(v2);
      };
      break;
    case 'startswith':
      predFn = function (v1, v2) {
        return stringStartsWith$1(v1, v2, lqco);
      };
      break;
    case 'endswith':
      predFn = function (v1, v2) {
        return stringEndsWith$1(v1, v2, lqco);
      };
      break;
    case 'contains':
      predFn = function (v1, v2) {
        return stringContains(v1, v2, lqco);
      };
      break;
    case 'in':
      predFn = function (v1, v2) {
        v1 = mc(v1);
        v2 = v2.map(function (v) {
          return mc(v);
        });
        return v2.indexOf(v1) >= 0;
      };
      break;
    default:
      return null;
  }
  return predFn;
}
function stringEquals(a, b, lqco) {
  if (b == null) return false;
  if (typeof b !== 'string') {
    b = b.toString();
  }
  if (lqco.usesSql92CompliantStringComparison) {
    a = (a || "").trim();
    b = (b || "").trim();
  }
  if (!lqco.isCaseSensitive) {
    a = (a || "").toLowerCase();
    b = (b || "").toLowerCase();
  }
  return a === b;
}
function stringStartsWith$1(a, b, lqco) {
  if (!lqco.isCaseSensitive) {
    a = (a || "").toLowerCase();
    b = (b || "").toLowerCase();
  }
  return core.stringStartsWith(a, b);
}
function stringEndsWith$1(a, b, lqco) {
  if (!lqco.isCaseSensitive) {
    a = (a || "").toLowerCase();
    b = (b || "").toLowerCase();
  }
  return core.stringEndsWith(a, b);
}
function stringContains(a, b, lqco) {
  if (!lqco.isCaseSensitive) {
    a = (a || "").toLowerCase();
    b = (b || "").toLowerCase();
  }
  return a.indexOf(b) >= 0;
}
const ɵ8 = function () {
    return this.value;
  },
  ɵ9 = function (context) {
    let predVal = this.pred.visit(context);
    let json = {};
    json[this.op.key] = predVal;
    return json;
  },
  ɵ10 = function (context) {
    let expr1Val = this.expr1.visit(context);
    let expr2Val = this.expr2.visit(context);
    let json = {};
    if (this.expr2 instanceof PropExpr) {
      expr2Val = {
        value: expr2Val,
        isProperty: true
      };
    }
    if (this.op.key === "eq") {
      json[expr1Val] = expr2Val;
    } else {
      let value = {};
      json[expr1Val] = value;
      value[this.op.key] = expr2Val;
    }
    return json;
  },
  ɵ11 = function (context) {
    let predVals = this.preds.map(function (pred) {
      return pred.visit(context);
    });
    if (!predVals || !predVals.length) {
      return {};
    }
    let json;
    // normalizeAnd clauses if possible.
    // passthru predicate will appear as string and their 'ands' can't be 'normalized'
    if (this.op.key === 'and' && predVals.length === 2 && !predVals.some(v => v.or || typeof v === 'string')) {
      // normalize 'and' clauses - will return null if can't be combined.
      json = predVals.reduce(combine);
    }
    if (json == null) {
      json = {};
      json[this.op.key] = predVals;
    }
    return json;
  },
  ɵ12 = function (context) {
    let exprVal = this.expr.visit(context);
    let newContext = core.extend({}, context);
    newContext.entityType = this.expr.dataType;
    let predVal = this.pred.visit(newContext);
    let json = {};
    let value = {};
    value[this.op.key] = predVal;
    json[exprVal] = value;
    return json;
  },
  ɵ13 = function (context) {
    if (this.hasExplicitDataType || context.useExplicitDataType) {
      return {
        value: this.value,
        dataType: this.dataType.name
      };
    } else {
      return this.value;
    }
  },
  ɵ14 = function (context) {
    if (context.toNameOnServer) {
      if (!context.entityType) {
        console.warn(`No EntityType for propertyPath "${this.propertyPath}".  ${core.strings.TO_TYPE}`);
        return this.propertyPath;
      }
      return context.entityType.clientPropertyPathToServer(this.propertyPath);
    } else {
      return this.propertyPath;
    }
  },
  ɵ15 = function (context) {
    let exprVals = this.exprs.map(function (expr) {
      return expr.visit(context);
    });
    return this.fnName + "(" + exprVals.join(",") + ")";
  };
// toJSONVisitor
let toJSONVisitor = {
  passthruPredicate: ɵ8,
  unaryPredicate: ɵ9,
  binaryPredicate: ɵ10,
  andOrPredicate: ɵ11,
  anyAllPredicate: ɵ12,
  litExpr: ɵ13,
  propExpr: ɵ14,
  fnExpr: ɵ15
};
function combine(j1, j2) {
  let ok = Object.keys(j2).every(function (key) {
    if (j1.hasOwnProperty(key)) {
      if (typeof j2[key] !== 'object') {
        // exit and indicate that we can't combine
        return false;
      }
      if (combine(j1[key], j2[key]) == null) {
        return false;
      }
    } else if (typeof j1 !== 'object') {
      // cannot assign to j1[key]
      return false;
    } else {
      j1[key] = j2[key];
    }
    return true;
  });
  return ok ? j1 : null;
}

/**
An EntityQuery instance is used to query entities either from a remote datasource or from a local [[EntityManager]].

EntityQueries are immutable - this means that all EntityQuery methods that return an EntityQuery actually create a new EntityQuery.  This means that
EntityQueries can be 'modified' without affecting any current instances.
@dynamic
**/
class EntityQuery {
  /** Constructor
  >    let query = new EntityQuery("Customers")
      Usually this constructor will be followed by calls to filtering, ordering or selection methods
  >      let query = new EntityQuery("Customers")
  >        .where("CompanyName", "startsWith", "C")
  >        .orderBy("Region");
  @param resourceName - either a resource name or a serialized EntityQuery ( created by [[EntityQuery.toJSON]])
  **/
  constructor(resourceName) {
    if (resourceName != null && typeof resourceName !== 'string') {
      return fromJSON(this, resourceName);
    }
    // TODO: cast as string below needed for early versions of TypeDoc - but not for regular compile - check later
    this.resourceName = resourceName;
    this.fromEntityType = undefined;
    this.wherePredicate = undefined;
    this.orderByClause = undefined;
    this.selectClause = undefined;
    this.skipCount = undefined;
    this.takeCount = undefined;
    this.expandClause = undefined;
    this.parameters = {};
    this.inlineCountEnabled = false;
    this.noTrackingEnabled = false;
    // default is to get queryOptions and dataService from the entityManager.
    // this.queryOptions = new QueryOptions();
    // this.dataService = new DataService();
    this.entityManager = undefined;
  }
  /**
  Specifies the resource to query for this EntityQuery.
  >      let query = new EntityQuery()
  >        .from("Customers");
      is the same as
  >      let query = new EntityQuery("Customers");
  @param resourceName - The resource to query.
  **/
  from(resourceName) {
    // TODO: think about allowing entityType as well
    assertParam(resourceName, "resourceName").isString().check();
    return clone(this, "resourceName", resourceName);
  }
  /**
  This is a static version of the "from" method and it creates a 'base' entityQuery for the specified resource name.
  >      let query = EntityQuery.from("Customers");
      is the same as
  >      let query = new EntityQuery("Customers");
  @param resourceName - The resource to query.
  **/
  static from(resourceName) {
    assertParam(resourceName, "resourceName").isString().check();
    return new EntityQuery(resourceName);
  }
  /**
  Specifies the top level EntityType that this query will return.  Only needed when a query returns a json result that does not include type information,
  or when using a resource name that is not associated to an EntityType.
  >      let query = new EntityQuery()
  >        .from("MyCustomMethod")
  >        .toType("Customer")
  @param entityType - The top level EntityType that this query will return.
  @summary If the json result consists of more than a simple entity or array of entities, consider using a [[JsonResultsAdapter]] instead.
  **/
  toType(entityType) {
    assertParam(entityType, "entityType").isString().or().isInstanceOf(EntityType).check();
    return clone(this, "resultEntityType", entityType);
  }
  /**
  Returns a new query with an added filter criteria; Can be called multiple times which means to 'and' with any existing
  Predicate or can be called with null to clear all predicates.
  >      let query = new EntityQuery("Customers")
  >          .where("CompanyName", "startsWith", "C");
      This can also be expressed using an explicit [[FilterQueryOp]] as
  >      let query = new EntityQuery("Customers")
  >          .where("CompanyName", FilterQueryOp.StartsWith, "C");
      or a preconstructed [[Predicate]] may be used
  >      let pred = new Predicate("CompanyName", FilterQueryOp.StartsWith, "C");
  >      let query = new EntityQuery("Customers").where(pred);
      Predicates are often useful when you want to combine multiple conditions in a single filter, such as
  >      let pred = Predicate.create("CompanyName", "startswith", "C").and("Region", FilterQueryOp.Equals, null);
  >      let query = new EntityQuery("Customers")
  >          .where(pred);
      More complicated queries can make use of nested property paths
  >      let query = new EntityQuery("Products")
  >          .where("Category.CategoryName", "startswith", "S");
      or OData functions - A list of valid OData functions can be found within the [[Predicate]] documentation.
  
  >      let query = new EntityQuery("Customers")
  >          .where("toLower(CompanyName)", "startsWith", "c");
      or to be even more baroque
  >      let query = new EntityQuery("Customers")
  >          .where("toUpper(substring(CompanyName, 1, 2))", FilterQueryOp.Equals, "OM");
  @param predicate -  Can be either
    - a single [[Predicate]]
        - the parameters to create a 'simple' Predicate
    - -  a property name, a property path with '.' as path seperators or a property expression {String}
    - -  an operator - [[FilterQueryOp]] or it's string representation. Case is ignored
    when if a string is provided and any string that matches one of the FilterQueryOp aliases will be accepted.
    - -  a value {Object} - This will be treated as either a property expression or a literal depending on context.
    In general, if the value can be interpreted as a property expression it will be, otherwise it will be treated as a literal.
    In most cases this works well, but you can also force the interpretation by making the value argument itself an object
    with a 'value' property and an 'isLiteral' property set to either true or false.
    Breeze also tries to infer the dataType of any literal based on context, if this fails you can force this inference by making the value argument
    an object with a 'value' property and a 'dataType'property set to one of the DataType enumeration instances.
        - a null or undefined ( this causes any existing where clause to be removed)
  **/
  where(...args) {
    let wherePredicate;
    if (args.length > 0 && args[0] != null) {
      wherePredicate = Predicate.create(...args);
      if (this.fromEntityType) wherePredicate._validate(this.fromEntityType);
      if (this.wherePredicate) {
        wherePredicate = this.wherePredicate.and(wherePredicate);
      }
    }
    return clone(this, "wherePredicate", wherePredicate);
  }
  /**
  Returns a new query that orders the results of the query by property name.  By default sorting occurs is ascending order, but sorting in descending order is supported as well.
  OrderBy clauses may be chained.
  >     let query = new EntityQuery("Customers")
  >        .orderBy("CompanyName");
      or to sort across multiple properties
  >     let query = new EntityQuery("Customers")
  >        .orderBy("Region, CompanyName");
      Nested property paths are also supported
  >     let query = new EntityQuery("Products")
  >        .orderBy("Category.CategoryName");
      Sorting in descending order is supported via the addition of ' desc' to the end of any property path.
  >     let query = new EntityQuery("Customers")
  >        .orderBy("CompanyName desc");
      or
  >     let query = new EntityQuery("Customers")
  >        .orderBy("Region desc, CompanyName desc");
  @param propertyPaths - A comma-separated (',') string of property paths or an array of property paths.
  Each property path can optionally end with " desc" to force a descending sort order. If 'propertyPaths' is either null or omitted then all ordering is removed.
  @param isDescending - If specified, overrides all of the embedded 'desc' tags in the previously specified property paths.
  **/
  orderBy(propertyPaths, isDescending) {
    // propertyPaths: can pass in create("A.X,B") or create("A.X desc, B") or create("A.X desc,B", true])
    // isDesc parameter trumps isDesc in propertyName.
    let orderByClause = propertyPaths == null ? null : new OrderByClause(normalizePropertyPaths(propertyPaths), isDescending);
    if (this.orderByClause && orderByClause) {
      orderByClause = new OrderByClause([this.orderByClause, orderByClause]);
    }
    return clone(this, "orderByClause", orderByClause);
  }
  /**
  Returns a new query that orders the results of the query by property name in descending order.
  >     let query = new EntityQuery("Customers")
  >        .orderByDesc("CompanyName");
      or to sort across multiple properties
  >     let query = new EntityQuery("Customers")
  >        .orderByDesc("Region, CompanyName");
      Nested property paths are also supported
  >     let query = new EntityQuery("Products")
  >        .orderByDesc("Category.CategoryName");
  @param propertyPaths - A comma-separated (',') string of property paths or an array of property paths.
  If 'propertyPaths' is either null or omitted then all ordering is removed.
  **/
  orderByDesc(propertyPaths) {
    return this.orderBy(propertyPaths, true);
  }
  /**
  Returns a new query that selects a list of properties from the results of the original query and returns the values of just these properties. This
  will be referred to as a projection.
  If the result of this selection "projection" contains entities, these entities will automatically be added to EntityManager's cache and will
  be made 'observable'.
  Any simple properties, i.e. strings, numbers or dates within a projection will not be cached are will NOT be made 'observable'.
  
  Simple data properties can be projected
  >     let query = new EntityQuery("Customers")
  >         .where("CompanyName", "startsWith", "C")
  >         .select("CompanyName");
      This will return an array of objects each with a single "CompanyName" property of type string.
  A similar query could return a navigation property instead
  >     let query = new EntityQuery("Customers")
  >        .where("CompanyName", "startsWith", "C")
  >        .select("Orders");
      where the result would be an array of objects each with a single "Orders" property that would itself be an array of "Order" entities.
  Composite projections are also possible:
  >     let query = new EntityQuery("Customers")
  >        .where("CompanyName", "startsWith", "C")
  >        .select("CompanyName, Orders");
      As well as projections involving nested property paths
  >     let query = EntityQuery("Orders")
  >        .where("Customer.CompanyName", "startsWith", "C")
  >        .select("Customer.CompanyName, Customer, OrderDate");
  @param propertyPaths - A comma-separated (',') string of property paths or an array of property paths.
  If 'propertyPaths' is either null or omitted then any existing projection on the query is removed.
  **/
  select(propertyPaths) {
    let selectClause = propertyPaths == null ? null : new SelectClause(normalizePropertyPaths(propertyPaths));
    return clone(this, "selectClause", selectClause);
  }
  /**
  Returns a new query that skips the specified number of entities when returning results.
  Any existing 'skip' can be cleared by calling 'skip' with no arguments.
  >     let query = new EntityQuery("Customers")
  >       .where("CompanyName", "startsWith", "C")
  >       .skip(5);
  @param count - The number of entities to skip over. If omitted or null any existing skip count on the query is removed.
  **/
  skip(count) {
    assertParam(count, "count").isOptional().isNumber().check();
    return clone(this, "skipCount", count == null ? null : count);
  }
  /**
  Returns a new query that returns only the specified number of entities when returning results. - Same as 'take'.
  Any existing 'top' can be cleared by calling 'top' with no arguments.
  >     let query = new EntityQuery("Customers")
  >        .top(5);
  @param count - The number of entities to return.
  If 'count' is either null or omitted then any existing 'top' count on the query is removed.
  **/
  top(count) {
    return this.take(count);
  }
  /**
  Returns a new query that returns only the specified number of entities when returning results - Same as 'top'.
  Any existing take can be cleared by calling take with no arguments.
  >     let query = new EntityQuery("Customers")
  >        .take(5);
  @param count - The number of entities to return.
  If 'count' is either null or omitted then any existing 'take' count on the query is removed.
  **/
  take(count) {
    assertParam(count, "count").isOptional().isNumber().check();
    return clone(this, "takeCount", count == null ? null : count);
  }
  /**
  Returns a new query that will return related entities nested within its results. The expand method allows you to identify related entities, via navigation property
  names such that a graph of entities may be retrieved with a single request. Any filtering occurs before the results are 'expanded'.
  >     let query = new EntityQuery("Customers")
  >        .where("CompanyName", "startsWith", "C")
  >        .expand("Orders");
      will return the filtered customers each with its "Orders" properties fully resolved.
  Multiple paths may be specified by separating the paths by a ','
  >     let query = new EntityQuery("Orders")
  >        .expand("Customer, Employee")
      and nested property paths my be specified as well
  >     let query = new EntityQuery("Orders")
  >        .expand("Customer, OrderDetails, OrderDetails.Product")
  @param propertyPaths - A comma-separated list of navigation property names or an array of navigation property names. Each Navigation Property name can be followed
  by a '.' and another navigation property name to enable identifying a multi-level relationship.
  If 'propertyPaths' is either null or omitted then any existing 'expand' clause on the query is removed.
  **/
  expand(propertyPaths) {
    let expandClause = propertyPaths == null ? null : new ExpandClause(normalizePropertyPaths(propertyPaths));
    return clone(this, "expandClause", expandClause);
  }
  /**
  Returns a new query that includes a collection of parameters to pass to the server.
  >     let query = EntityQuery.from("EmployeesFilteredByCountryAndBirthdate")
  >        .withParameters({ BirthDate: "1/1/1960", Country: "USA" });
   
  will call the 'EmployeesFilteredByCountryAndBirthdate' method on the server and pass in 2 parameters. This
  query will be uri encoded as
  >      {serviceApi}/EmployeesFilteredByCountryAndBirthdate?birthDate=1%2F1%2F1960&country=USA
      Parameters may also be mixed in with other query criteria.
  >     let query = EntityQuery.from("EmployeesFilteredByCountryAndBirthdate")
  >        .withParameters({ BirthDate: "1/1/1960", Country: "USA" })
  >        .where("LastName", "startsWith", "S")
  >        .orderBy("BirthDate");
  @param parameters - A parameters object where the keys are the parameter names and the values are the parameter values.
  **/
  withParameters(parameters) {
    assertParam(parameters, "parameters").isObject().check();
    return clone(this, "parameters", parameters);
  }
  /**
  Returns a query with the 'inlineCount' capability either enabled or disabled.  With 'inlineCount' enabled, an additional 'inlineCount' property
  will be returned with the query results that will contain the number of entities that would have been returned by this
  query with only the 'where'/'filter' clauses applied, i.e. without any 'skip'/'take' operators applied. For local queries this clause is ignored.
  >     let query = new EntityQuery("Customers")
  >        .take(20)
  >        .orderBy("CompanyName")
  >        .inlineCount(true);
      will return the first 20 customers as well as a count of all of the customers in the remote store.
  @param enabled - (default = true) Whether or not inlineCount capability should be enabled. If this parameter is omitted, true is assumed.
  **/
  inlineCount(enabled) {
    assertParam(enabled, "enabled").isBoolean().isOptional().check();
    enabled = enabled === undefined ? true : !!enabled;
    return clone(this, "inlineCountEnabled", enabled);
  }
  useNameOnServer(usesNameOnServer) {
    assertParam(usesNameOnServer, "usesNameOnServer").isBoolean().isOptional().check();
    usesNameOnServer = usesNameOnServer === undefined ? true : !!usesNameOnServer;
    return clone(this, "usesNameOnServer", usesNameOnServer);
  }
  /**
  Returns a query with the 'noTracking' capability either enabled or disabled.  With 'noTracking' enabled, the results of this query
  will not be coerced into entities but will instead look like raw javascript projections. i.e. simple javascript objects.
  >     let query = new EntityQuery("Customers")
  >         .take(20)
  >         .orderBy("CompanyName")
  >         .noTracking(true);
  @param enabled - (default = true) Whether or not the noTracking capability should be enabled. If this parameter is omitted, true is assumed.
  **/
  noTracking(enabled) {
    assertParam(enabled, "enabled").isBoolean().isOptional().check();
    enabled = enabled === undefined ? true : !!enabled;
    return clone(this, "noTrackingEnabled", enabled);
  }
  /**
  Returns a copy of this EntityQuery with the specified [[EntityManager]], [[DataService]],
  [[JsonResultsAdapter]], [[MergeStrategy]] or [[FetchStrategy]] applied.
  >      // 'using' can be used to return a new query with a specified EntityManager.
  >      let em = new EntityManager(serviceName);
  >      let query = new EntityQuery("Orders")
  >        .using(em);
      or with a specified [[MergeStrategy]]
  >      let em = new EntityManager(serviceName);
  >      let query = new EntityQuery("Orders")
  >        .using(MergeStrategy.PreserveChanges);
      or with a specified [[FetchStrategy]]
  >      let em = new EntityManager(serviceName);
  >      let query = new EntityQuery("Orders")
  >        .using(FetchStrategy.FromLocalCache);
  @param obj - The object to update in creating a new EntityQuery from an existing one.
  **/
  using(obj) {
    if (!obj) return this;
    let eq = clone(this);
    processUsing(eq, {
      "entityManager": null,
      "dataService": null,
      "queryOptions": null,
      "fetchStrategy": (eq, val) => {
        eq.queryOptions = (eq.queryOptions || new QueryOptions()).using(val);
      },
      "mergeStrategy": (eq, val) => {
        eq.queryOptions = (eq.queryOptions || new QueryOptions()).using(val);
      },
      "jsonResultsAdapter": (eq, val) => {
        eq.dataService = (eq.dataService || new DataService()).using({
          jsonResultsAdapter: val
        });
      }
    }, obj);
    return eq;
  }
  /**
  Executes this query.  This method requires that an EntityManager has been previously specified via the "using" method.
  
  This method can be called using a 'promises' syntax ( recommended)
  >      let em = new EntityManager(serviceName);
  >      let query = new EntityQuery("Orders").using(em);
  >      query.execute().then( function(data) {
  >          ... query results processed here
  >      }).catch( function(err) {
  >          ... query failure processed here
  >      });
      or with callbacks
  >      let em = new EntityManager(serviceName);
  >      let query = new EntityQuery("Orders").using(em);
  >      query.execute(
  >        function(data) {
  >                    let orders = data.results;
  >                    ... query results processed here
  >                },
  >        function(err) {
  >                    ... query failure processed here
  >                });
      Either way this method is the same as calling the EntityManager 'execute' method.
  >      let em = new EntityManager(serviceName);
  >      let query = new EntityQuery("Orders");
  >      em.executeQuery(query).then( function(data) {
  >         let orders = data.results;
  >          ... query results processed here
  >      }).catch( function(err) {
  >         ... query failure processed here
  >      });
      @param callback -  Function called on success.
  @param errorCallback - Function called on failure.
  @return Promise
  **/
  execute(callback, errorCallback) {
    if (!this.entityManager) {
      throw new Error("An EntityQuery must have its EntityManager property set before calling 'execute'");
    }
    return this.entityManager.executeQuery(this, callback, errorCallback);
  }
  /**
  Executes this query against the local cache.  This method requires that an EntityManager have been previously specified via the "using" method.
  >      // assume em is an entityManager already filled with order entities;
  >      let query = new EntityQuery("Orders").using(em);
  >      let orders = query.executeLocally();
      Note that calling this method is the same as calling [[EntityManager.executeQueryLocally]].
  **/
  executeLocally() {
    if (!this.entityManager) {
      throw new Error("An EntityQuery must have its EntityManager property set before calling 'executeLocally'");
    }
    return this.entityManager.executeQueryLocally(this);
  }
  toJSON() {
    return this.toJSONExt();
  }
  /** Typically only for use when building UriBuilderAdapters.
  @hidden @internal
  */
  toJSONExt(context) {
    context = context || {};
    context.entityType = context.entityType || this.fromEntityType;
    context.propertyPathFn = context.toNameOnServer ? context.entityType.clientPropertyPathToServer.bind(context.entityType) : core.identity;
    let toJSONExtFn = function (v) {
      return v ? v.toJSONExt(context) : undefined;
    };
    return core.toJson(this, {
      "from,resourceName": null,
      "toType,resultEntityType": function (v) {
        // resultEntityType can be either a string or an entityType
        return v ? typeof v === 'string' ? v : v.name : undefined;
      },
      "where,wherePredicate": toJSONExtFn,
      "orderBy,orderByClause": toJSONExtFn,
      "select,selectClause": toJSONExtFn,
      "expand,expandClause": toJSONExtFn,
      "skip,skipCount": null,
      "take,takeCount": null,
      parameters: function (v) {
        return core.isEmpty(v) ? undefined : v;
      },
      "inlineCount,inlineCountEnabled": false,
      "noTracking,noTrackingEnabled": false,
      queryOptions: null
    });
  }
  /**
  Static method that creates an EntityQuery that will allow 'requerying' an entity or a collection of entities by primary key. This can be useful
  to force a requery of selected entities, or to restrict an existing collection of entities according to some filter.
      Works for a single entity or an array of entities of the SAME type.
  Does not work for an array of entities of different types.
  >      // assuming 'customers' is an array of 'Customer' entities retrieved earlier.
  >      let customersQuery = EntityQuery.fromEntities(customers);
      The resulting query can, of course, be extended
  >      // assuming 'customers' is an array of 'Customer' entities retrieved earlier.
  >      let customersQuery = EntityQuery.fromEntities(customers)
  >        .where("Region", FilterQueryOp.NotEquals, null);
      Single entities can requeried as well.
  >      // assuming 'customer' is a 'Customer' entity retrieved earlier.
  >      let customerQuery = EntityQuery.fromEntities(customer);
      will create a query that will return an array containing a single customer entity.
  @param entities - The entities for which we want to create an EntityQuery.
  **/
  static fromEntities(entities) {
    assertParam(entities, "entities").isEntity().or().isNonEmptyArray().isEntity().check();
    let ents = Array.isArray(entities) ? entities : [entities];
    let firstEntity = ents[0];
    let type = firstEntity.entityType;
    if (ents.some(function (e) {
      return e.entityType !== type;
    })) {
      throw new Error("All 'fromEntities' must be the same type; at least one is not of type " + type.name);
    }
    let q = new EntityQuery(type.defaultResourceName);
    let preds = ents.map(function (entity) {
      return buildPredicate(entity);
    });
    let pred = Predicate.or(preds);
    q = q.where(pred);
    let em = firstEntity.entityAspect.entityManager;
    if (em) {
      q = q.using(em);
    }
    return q;
  }
  /**
  Creates an EntityQuery for the specified [[EntityKey]].
  >      let empType = metadataStore.getEntityType("Employee");
  >      let entityKey = new EntityKey(empType, 1);
  >      let query = EntityQuery.fromEntityKey(entityKey);
      or
  >      // 'employee' is a previously queried employee
  >      let entityKey = employee.entityAspect.getKey();
  >      let query = EntityQuery.fromEntityKey(entityKey);
  @param entityKey - The [[EntityKey]] for which a query will be created.
  **/
  static fromEntityKey(entityKey) {
    assertParam(entityKey, "entityKey").isInstanceOf(EntityKey).check();
    let q = new EntityQuery(entityKey.entityType.defaultResourceName);
    let pred = buildKeyPredicate(entityKey);
    q = q.where(pred).toType(entityKey.entityType);
    return q;
  }
  // protected methods
  /** @hidden @internal */
  _getFromEntityType(metadataStore, throwErrorIfNotFound) {
    // Uncomment next two lines if we make this method public.
    // assertParam(metadataStore, "metadataStore").isInstanceOf(MetadataStore).check();
    // assertParam(throwErrorIfNotFound, "throwErrorIfNotFound").isBoolean().isOptional().check();
    let entityType = this.fromEntityType;
    if (entityType) return entityType;
    let resourceName = this.resourceName;
    if (!resourceName) {
      throw new Error("There is no resourceName for this query");
    }
    if (metadataStore.isEmpty()) {
      if (throwErrorIfNotFound) {
        throw new Error("There is no metadata available for this query. " + "Are you querying the local cache before you've fetched metadata?");
      } else {
        return undefined;
      }
    }
    let entityTypeName = metadataStore.getEntityTypeNameForResourceName(resourceName);
    if (entityTypeName) {
      entityType = metadataStore._getStructuralType(entityTypeName);
    } else {
      entityType = this._getToEntityType(metadataStore, true);
    }
    if (!entityType) {
      if (throwErrorIfNotFound) {
        throw new Error(core.formatString("Cannot find an entityType for resourceName: '%1'. ", resourceName) + core.strings.TO_TYPE);
      } else {
        return undefined;
      }
    }
    this.fromEntityType = entityType;
    return entityType;
  }
  /** @hidden @internal */
  _getToEntityType(metadataStore, skipFromCheck) {
    // skipFromCheck is to avoid recursion if called from _getFromEntityType;
    if (this.resultEntityType instanceof EntityType) {
      return this.resultEntityType;
    } else if (this.resultEntityType) {
      // resultEntityType is a string
      this.resultEntityType = metadataStore._getStructuralType(this.resultEntityType, false);
      return this.resultEntityType;
    } else {
      // resolve it, if possible, via the resourceName
      // do not cache this value in this case
      // cannot determine the resultEntityType if a selectClause is present.
      // return skipFromCheck ? null : (!this.selectClause) && this._getFromEntityType(metadataStore, false);
      if (skipFromCheck || this.selectClause) {
        return undefined;
      } else {
        this._getFromEntityType(metadataStore, false);
      }
    }
  }
  /** @hidden @internal */
  // for testing
  _toUri(em) {
    let ds = DataService.resolve([em.dataService]);
    return ds.uriBuilder.buildUri(this, em.metadataStore);
  }
}
/**
Creates an EntityQuery for the specified entity and [[NavigationProperty]].
>      // 'employee' is a previously queried employee
>      let ordersNavProp = employee.entityType.getProperty("Orders");
>      let query = EntityQuery.fromEntityNavigation(employee, ordersNavProp);

will return a query for the "Orders" of the specified 'employee'.
@param entity - The Entity whose navigation property will be queried.
@param navigationProperty - The [[NavigationProperty]] or name of the NavigationProperty to be queried.
**/
EntityQuery.fromEntityNavigation = function (entity, navigationProperty) {
  assertParam(entity, "entity").isEntity().check();
  let navProperty = entity.entityType._checkNavProperty(navigationProperty);
  let q = new EntityQuery(navProperty.entityType.defaultResourceName);
  let pred = buildNavigationPredicate(entity, navProperty);
  if (pred == null) {
    throw new Error("Unable to create a NavigationQuery for navigationProperty: " + navProperty.name);
  }
  q = q.where(pred);
  let em = entity.entityAspect.entityManager;
  return em ? q.using(em) : q;
};
EntityQuery.prototype._$typeName = "EntityQuery";
// private functions
function fromJSON(eq, json) {
  core.toJson(json, {
    "resourceName,from": null,
    // just the name comes back and will be resolved later
    "resultEntityType,toType": null,
    "wherePredicate,where": function (v) {
      return v ? new Predicate(v) : undefined;
    },
    "orderByClause,orderBy": function (v) {
      return v ? new OrderByClause(v) : undefined;
    },
    "selectClause,select": function (v) {
      return v ? new SelectClause(v) : undefined;
    },
    "expandClause,expand": function (v) {
      return v ? new ExpandClause(v) : undefined;
    },
    "skipCount,skip": null,
    "takeCount,take": null,
    parameters: function (v) {
      return core.isEmpty(v) ? undefined : v;
    },
    "inlineCountEnabled,inlineCount": false,
    "noTrackingEnabled,noTracking": false,
    queryOptions: function (v) {
      return v ? QueryOptions.fromJSON(v) : undefined;
    }
  }, eq);
  return eq;
}
function clone(eq, propName, value) {
  // immutable queries mean that we don't need to clone if no change in value.
  if (propName) {
    if (eq[propName] === value) return eq;
  }
  // copying QueryOptions is safe because they are are immutable;
  let copy = core.extend(new EntityQuery(), eq, ["resourceName", "fromEntityType", "wherePredicate", "orderByClause", "selectClause", "skipCount", "takeCount", "expandClause", "inlineCountEnabled", "noTrackingEnabled", "usesNameOnServer", "queryOptions", "entityManager", "dataService", "resultEntityType"]);
  copy.parameters = core.extend({}, eq.parameters);
  if (propName) {
    copy[propName] = value;
  }
  return copy;
}
function processUsing(eq, map, value, propertyName) {
  let typeName = value._$typeName || value instanceof BreezeEnum && value.constructor.name;
  let key = typeName && typeName.substr(0, 1).toLowerCase() + typeName.substr(1);
  if (propertyName && key !== propertyName) {
    throw new Error("Invalid value for property: " + propertyName);
  }
  if (key) {
    let fn = map[key];
    if (fn === undefined) {
      throw new Error("Invalid config property: " + key);
    } else if (fn === null) {
      eq[key] = value;
    } else {
      fn(eq, value);
    }
  } else {
    core.objectForEach(value, (propName, val) => {
      processUsing(eq, map, val, propName);
    });
  }
}
function normalizePropertyPaths(propertyPaths) {
  assertParam(propertyPaths, "propertyPaths").isOptional().isString().or().isArray().isString().check();
  if (typeof propertyPaths === 'string') {
    propertyPaths = propertyPaths.split(",");
  }
  propertyPaths = propertyPaths.map(function (pp) {
    return pp.trim();
  });
  return propertyPaths;
}
function buildPredicate(entity) {
  let entityType = entity.entityType;
  let predParts = entityType.keyProperties.map(function (kp) {
    return Predicate.create(kp.name, FilterQueryOp.Equals, entity.getProperty(kp.name));
  });
  let pred = Predicate.and(predParts);
  return pred;
}
function buildKeyPredicate(entityKey) {
  let keyProps = entityKey.entityType.keyProperties;
  let preds = core.arrayZip(keyProps, entityKey.values, function (kp, v) {
    return Predicate.create(kp.name, FilterQueryOp.Equals, v);
  });
  let pred = Predicate.and(preds);
  return pred;
}
function buildNavigationPredicate(entity, navigationProperty) {
  if (navigationProperty.isScalar) {
    if (navigationProperty.foreignKeyNames.length === 0) return null;
    let relatedKeyValues = navigationProperty.foreignKeyNames.map(fkName => {
      return entity.getProperty(fkName);
    });
    let entityKey = new EntityKey(navigationProperty.entityType, relatedKeyValues);
    return buildKeyPredicate(entityKey);
  } else {
    let inverseNp = navigationProperty.inverse;
    let foreignKeyNames = inverseNp ? inverseNp.foreignKeyNames : navigationProperty.invForeignKeyNames;
    if (foreignKeyNames.length === 0) return null;
    let keyValues = entity.entityAspect.getKey().values;
    let predParts = core.arrayZip(foreignKeyNames, keyValues, (fkName, kv) => {
      return Predicate.create(fkName, FilterQueryOp.Equals, kv);
    });
    return Predicate.and(predParts);
  }
}
/**
FilterQueryOp is an 'Enum' containing all of the valid  [[Predicate]]
filter operators for an [[EntityQuery]].
**/
class FilterQueryOp extends BreezeEnum {}
/** Aliases: "eq", "==" **/
FilterQueryOp.Equals = new FilterQueryOp({
  operator: "eq"
});
/**  Aliases: "ne", "!="  **/
FilterQueryOp.NotEquals = new FilterQueryOp({
  operator: "ne"
});
/** Aliases: "gt", ">"   **/
FilterQueryOp.GreaterThan = new FilterQueryOp({
  operator: "gt"
});
/** Aliases: "lt", "<"  **/
FilterQueryOp.LessThan = new FilterQueryOp({
  operator: "lt"
});
/**  Aliases: "ge", ">="  **/
FilterQueryOp.GreaterThanOrEqual = new FilterQueryOp({
  operator: "ge"
});
/**  Aliases: "le", "<="  **/
FilterQueryOp.LessThanOrEqual = new FilterQueryOp({
  operator: "le"
});
/**  String operation: Is a string a substring of another string.  Aliases: "substringof"   **/
FilterQueryOp.Contains = new FilterQueryOp({
  operator: "contains"
});
/** No aliases */
FilterQueryOp.StartsWith = new FilterQueryOp({
  operator: "startswith"
});
/** No aliases */
FilterQueryOp.EndsWith = new FilterQueryOp({
  operator: "endswith"
});
/**  Aliases: "some"  **/
FilterQueryOp.Any = new FilterQueryOp({
  operator: "any"
});
/**  Aliases: "every"  **/
FilterQueryOp.All = new FilterQueryOp({
  operator: "all"
});
/** No aliases */
FilterQueryOp.In = new FilterQueryOp({
  operator: "in"
});
/** No aliases */
FilterQueryOp.IsTypeOf = new FilterQueryOp({
  operator: "isof"
});
FilterQueryOp.prototype._$typeName = "FilterQueryOp";
Error['x'] = FilterQueryOp.resolveSymbols();
/**
 BooleanQueryOp is an 'Enum' containing all of the valid  boolean
operators for an [[EntityQuery]].
**/
class BooleanQueryOp extends BreezeEnum {}
BooleanQueryOp.And = new BooleanQueryOp({
  operator: "and"
});
BooleanQueryOp.Or = new BooleanQueryOp({
  operator: "or"
});
BooleanQueryOp.Not = new BooleanQueryOp({
  operator: "not"
});
BooleanQueryOp.prototype._$typeName = "BooleanQueryOp";
Error['x'] = BooleanQueryOp.resolveSymbols();
/** For use by breeze plugin authors only.  The class is used in most [[IUriBuilderAdapter]] implementations
@adapter (see [[IUriBuilderAdapter]])
@hidden

An OrderByClause is a description of the properties and direction that the result
of a query should be sorted in.  OrderByClauses are immutable, which means that any
method that would modify an OrderByClause actually returns a new OrderByClause.

For example for an Employee object with properties of 'Company' and 'LastName' the following would be valid expressions:
>     let obc = new OrderByClause("Company.CompanyName, LastName")

or
>     let obc = new OrderByClause("Company.CompanyName desc, LastName")

or
>     let obc = new OrderByClause("Company.CompanyName, LastName", true);
*/
class OrderByClause {
  constructor(propertyPaths, isDesc) {
    if (propertyPaths.length === 0) {
      throw new Error("OrderByClause cannot be empty");
    }
    // you can also pass in an array of orderByClauses
    if (propertyPaths[0] instanceof OrderByClause) {
      let clauses = propertyPaths;
      this.items = core.arrayFlatMap(clauses, c => c.items);
      // this.items = Array.prototype.concat.apply(clauses[0].items, clauses.slice(1).map(core.pluck("items")));
      // this.items = Array.prototype.concat.apply([], clauses.map(core.pluck("items")));
    } else {
      this.items = propertyPaths.map(function (pp) {
        return new OrderByItem(pp, isDesc);
      });
    }
  }
  validate(entityType) {
    if (entityType == null || entityType.isAnonymous) return;
    this.items.forEach(item => {
      item.validate(entityType);
    });
  }
  getComparer(entityType) {
    let orderByFuncs = this.items.map(function (obc) {
      return obc.getComparer(entityType);
    });
    return function (entity1, entity2) {
      for (let i = 0; i < orderByFuncs.length; i++) {
        let result = orderByFuncs[i](entity1, entity2);
        if (result !== 0) {
          return result;
        }
      }
      return 0;
    };
  }
  toJSONExt(context) {
    return this.items.map(function (item) {
      return context.propertyPathFn(item.propertyPath) + (item.isDesc ? " desc" : "");
    });
  }
}
/** @hidden @internal */
class OrderByItem {
  constructor(propertyPath, isDesc) {
    if (!(typeof propertyPath === 'string')) {
      throw new Error("propertyPath is not a string");
    }
    propertyPath = propertyPath.trim();
    let parts = propertyPath.split(' ');
    // parts[0] is the propertyPath; [1] would be whether descending or not.
    // if (parts.length > 1 && isDesc !== true && isDesc !== false) {
    if (parts.length > 1 && isDesc == null) {
      isDesc = core.stringStartsWith(parts[1].toLowerCase(), "desc");
      if (!isDesc) {
        // isDesc is false but check to make sure its intended.
        let isAsc = core.stringStartsWith(parts[1].toLowerCase(), "asc");
        if (!isAsc) {
          throw new Error("the second word in the propertyPath must begin with 'desc' or 'asc'");
        }
      }
    }
    this.propertyPath = parts[0];
    this.isDesc = isDesc || false;
  }
  validate(entityType) {
    if (entityType == null || entityType.isAnonymous) return;
    // will throw an exception on bad propertyPath
    this.lastProperty = entityType.getProperty(this.propertyPath, true);
    return this.lastProperty;
  }
  getComparer(entityType) {
    let propDataType;
    let isCaseSensitive;
    if (!this.lastProperty) this.validate(entityType);
    if (this.lastProperty) {
      propDataType = this.lastProperty.dataType;
      isCaseSensitive = this.lastProperty.parentType.metadataStore.localQueryComparisonOptions.isCaseSensitive;
    }
    let propertyPath = this.propertyPath;
    let isDesc = this.isDesc;
    return function (entity1, entity2) {
      let value1 = EntityAspect.getPropertyPathValue(entity1, propertyPath);
      let value2 = EntityAspect.getPropertyPathValue(entity2, propertyPath);
      let dataType = propDataType || value1 && DataType.fromValue(value1) || DataType.fromValue(value2);
      if (dataType === DataType.String) {
        if (isCaseSensitive) {
          value1 = value1 || "";
          value2 = value2 || "";
        } else {
          value1 = (value1 || "").toLowerCase();
          value2 = (value2 || "").toLowerCase();
        }
      } else {
        let normalize = DataType.getComparableFn(dataType);
        value1 = normalize(value1);
        value2 = normalize(value2);
      }
      if (value1 === value2) {
        return 0;
      } else if (value1 > value2 || value2 === undefined) {
        return isDesc ? -1 : 1;
      } else {
        return isDesc ? 1 : -1;
      }
    };
  }
}
/** For use by breeze plugin authors only.  The class is used in most [[IUriBuilderAdapter]] implementations
@adapter (see [[IUriBuilderAdapter]])
@hidden
**/
class SelectClause {
  constructor(propertyPaths) {
    this.propertyPaths = propertyPaths;
    this._pathNames = propertyPaths.map(function (pp) {
      return pp.replace(".", "_");
    });
  }
  validate(entityType) {
    if (entityType == null || entityType.isAnonymous) return; // can't validate yet
    // will throw an exception on bad propertyPath
    this.propertyPaths.forEach(function (path) {
      entityType.getProperty(path, true);
    });
  }
  toFunction( /* config */
  ) {
    let that = this;
    return function (entity) {
      let result = {};
      that.propertyPaths.forEach(function (path, i) {
        result[that._pathNames[i]] = EntityAspect.getPropertyPathValue(entity, path);
      });
      return result;
    };
  }
  toJSONExt(context) {
    return this.propertyPaths.map(function (pp) {
      return context.propertyPathFn(pp);
    });
  }
}
/** For use by breeze plugin authors only.  The class is used in most [[IUriBuilderAdapter]] implementations
@adapter (see [[IUriBuilderAdapter]])
@hidden
**/
class ExpandClause {
  constructor(propertyPaths) {
    this.propertyPaths = propertyPaths;
  }
  toJSONExt(context) {
    return this.propertyPaths.map(function (pp) {
      return context.propertyPathFn(pp);
    });
  }
}

/**
An EntityAspect instance is associated with every attached entity and is accessed via the entity's 'entityAspect' property.

The EntityAspect itself provides properties to determine and modify the EntityState of the entity and has methods
that provide a variety of services including validation and change tracking.

An EntityAspect will almost never need to be constructed directly. You will usually get an EntityAspect by accessing
an entities 'entityAspect' property.  This property will be automatically attached when an entity is created via either
a query, import or [[EntityManager.createEntity]] call.
>      // assume order is an order entity attached to an EntityManager.
>      var aspect = order.entityAspect;
>      var currentState = aspect.entityState;

**/
class EntityAspect {
  /** @hidden @internal */
  constructor(entity) {
    // if called without new
    // if (!(this instanceof EntityAspect)) {
    //   return new EntityAspect(entity);
    // }
    /**
    Sets the entity to an EntityState of 'Unchanged'.  This is also the equivalent of calling [[EntityAspect.acceptChanges]].
    The same operation can be performed by calling [[EntityAspect.setEntityState]].
    >      // assume order is an order entity attached to an EntityManager.
    >      order.entityAspect.setUnchanged();
    >      // The 'order' entity will now be in an 'Unchanged' state with any changes committed.
    **/
    this.setUnchanged = function () {
      return this.setEntityState(EntityState.Unchanged);
    };
    /**
    Sets the entity to an EntityState of 'Modified'.  This can also be achieved by changing the value of any property on an 'Unchanged' entity.
    The same operation can be performed by calling [[EntityAspect.setEntityState]].
    >      // assume order is an order entity attached to an EntityManager.
    >      order.entityAspect.setModified();
    >      // The 'order' entity will now be in a 'Modified' state.
    **/
    this.setModified = function () {
      return this.setEntityState(EntityState.Modified);
    };
    /**
    Sets the entity to an EntityState of 'Deleted'.  This both marks the entity as being scheduled for deletion during the next 'Save' call
    but also removes the entity from all of its related entities.
    The same operation can be performed by calling [[EntityAspect.setEntityState]].
    >      // assume order is an order entity attached to an EntityManager.
    >      order.entityAspect.setDeleted();
    >      // The 'order' entity will now be in a 'Deleted' state and it will no longer have any 'related' entities.
    **/
    this.setDeleted = function () {
      return this.setEntityState(EntityState.Deleted);
    };
    /**
    Sets the entity to an EntityState of 'Detached'.  This removes the entity from all of its related entities, but does NOT change the EntityState of any existing entities.
    The same operation can be performed by calling [[EntityAspect.setEntityState]].
    >      // assume order is an order entity attached to an EntityManager.
    >      order.entityAspect.setDetached();
    >      // The 'order' entity will now be in a 'Detached' state and it will no longer have any 'related' entities.
    **/
    this.setDetached = function () {
      return this.setEntityState(EntityState.Detached);
    };
    this.entity = entity;
    // TODO: keep public or not?
    this.entityGroup = undefined;
    this.entityManager = undefined;
    this.entityState = EntityState.Detached;
    this.isBeingSaved = false;
    this.originalValues = {};
    this.hasValidationErrors = false;
    this._validationErrors = {};
    // Uncomment when we implement entityAspect.isNavigationPropertyLoaded method
    // this._loadedNavPropMap = {};
    this.validationErrorsChanged = new BreezeEvent("validationErrorsChanged", this);
    this.propertyChanged = new BreezeEvent("propertyChanged", this);
    // in case this is the NULL entityAspect. - used with ComplexAspects that have no parent.
    if (entity != null) {
      // remove properties that should be on prototype but placed on class by Babel
      if (!entity.entityType) {
        delete entity.entityType;
      }
      if (!entity.entityAspect) {
        delete entity.entityAspect;
      }
      entity.entityAspect = this;
      // entityType should already be on the entity from 'watch'
      let entityType = entity.entityType || entity._$entityType;
      if (!entityType) {
        let typeName = entity.prototype._$typeName;
        if (!typeName) {
          throw new Error("This entity is not registered as a valid EntityType");
        } else {
          throw new Error("Metadata for this entityType has not yet been resolved: " + typeName);
        }
      }
      let entityCtor = entityType.getEntityCtor();
      config.interfaceRegistry.modelLibrary.getDefaultInstance().startTracking(entity, entityCtor.prototype);
    }
  }
  /** @hidden */
  // type-guard
  static isEntity(obj) {
    return obj.entityAspect != null;
  }
  // No longer used
  // static createFrom(entity: Entity): EntityAspect {
  //   if (entity == null) {
  //     return EntityAspect._nullInstance;
  //   } else if (entity.entityAspect) {
  //     return entity.entityAspect;
  //   }
  //   return new EntityAspect(entity);
  // }
  // TODO: refactor this and the instance getPropertyValue method.
  /**
  Returns the value of a specified 'property path' for a specified entity.
      The propertyPath can be either a string delimited with '.' or a string array.
  **/
  // used by EntityQuery and Predicate
  static getPropertyPathValue(obj, propertyPath) {
    let properties = Array.isArray(propertyPath) ? propertyPath : propertyPath.split(".");
    if (properties.length === 1) {
      return obj.getProperty(propertyPath);
    } else {
      let nextValue = obj;
      // hack use of some to perform mapFirst operation.
      properties.some(prop => {
        nextValue = nextValue.getProperty(prop);
        return nextValue == null;
      });
      return nextValue;
    }
  }
  /**
  Returns the [[EntityKey]] for this Entity.
  >      // assume order is an order entity attached to an EntityManager.
  >      var entityKey = order.entityAspect.getKey();
  @param forceRefresh - (boolean=false) Forces the recalculation of the key.  This should normally be unnecessary.
  @return The [[EntityKey]] associated with this Entity.
  **/
  getKey(forceRefresh = false) {
    forceRefresh = assertParam(forceRefresh, "forceRefresh").isBoolean().isOptional().check(false);
    if (forceRefresh || !this._entityKey) {
      let entityType = this.entity.entityType;
      let keyProps = entityType.keyProperties;
      let values = keyProps.map(function (p) {
        return this.entity.getProperty(p.name);
      }, this);
      this._entityKey = new EntityKey(entityType, values);
    }
    return this._entityKey;
  }
  /**
  Returns the entity to an [[EntityState]] of 'Unchanged' by committing all changes made since the entity was last queried
  had 'acceptChanges' called on it.
  >      // assume order is an order entity attached to an EntityManager.
  >      order.entityAspect.acceptChanges();
  >      // The 'order' entity will now be in an 'Unchanged' state with any changes committed.
  **/
  acceptChanges() {
    if (!this.entity) return;
    this._checkOperation("acceptChanges");
    let em = this.entityManager;
    if (this.entityState.isDeleted()) {
      em.detachEntity(this.entity);
    } else {
      this.setUnchanged();
    }
    em.entityChanged.publish({
      entityAction: EntityAction.AcceptChanges,
      entity: this.entity
    });
  }
  /**
  Returns the entity to an [[EntityState]] of 'Unchanged' by rejecting all changes made to it since the entity was last queried
  had 'rejectChanges' called on it.
  >      // assume order is an order entity attached to an EntityManager.
  >      order.entityAspect.rejectChanges();
  >      // The 'order' entity will now be in an 'Unchanged' state with any changes rejected.
  **/
  rejectChanges() {
    this._checkOperation("rejectChanges");
    let entity = this.entity;
    let entityManager = this.entityManager;
    // we do not want PropertyChange or EntityChange events to occur here
    core.using(entityManager, "isRejectingChanges", true, function () {
      rejectChangesCore(entity);
    });
    if (this.entityState.isAdded()) {
      // next line is needed because the following line will cause this.entityManager -> null;
      entityManager.detachEntity(entity);
      // need to tell em that an entity that needed to be saved no longer does.
      entityManager._notifyStateChange(entity, false);
    } else {
      if (this.entityState.isDeleted()) {
        entityManager._linkRelatedEntities(entity);
      }
      this.setUnchanged();
      // propertyChanged propertyName is not specified because more than one property may have changed.
      this.propertyChanged.publish({
        entity: entity,
        propertyName: null
      });
      entityManager.entityChanged.publish({
        entityAction: EntityAction.RejectChanges,
        entity: entity
      });
    }
  }
  /**  @hidden @internal */
  // TODO: rename - and use '_'; used on both EntityAspect and ComplexAspect for polymorphic reasons.
  getPropertyPath(propName) {
    return propName;
  }
  /**
  Sets the entity to an EntityState of 'Added'.  This is NOT the equivalent of calling [[EntityManager.addEntity]]
  because no key generation will occur for autogenerated keys as a result of this operation. As a result this operation can be problematic
  unless you are certain that the entity being marked 'Added' does not already exist in the database and does not have an autogenerated key.
  The same operation can be performed by calling [[EntityAspect.setEntityState]].
  >      // assume order is an order entity attached to an EntityManager.
  >      order.entityAspect.setAdded();
  >      // The 'order' entity will now be in an 'Added' state.
  **/
  setAdded() {
    return this.setEntityState(EntityState.Added);
  }
  /**
  Sets the entity to the specified EntityState. See also 'setUnchanged', 'setModified', 'setDetached', etc.
  >      // assume order is an order entity attached to an EntityManager.
  >      order.entityAspect.setEntityState(EntityState.Unchanged);
  >      // The 'order' entity will now be in a 'Unchanged' state.
  **/
  setEntityState(entityState) {
    if (this.entityState === entityState) return false;
    this._checkOperation("setEntityState");
    if (this.entityState.isDetached()) {
      throw new Error("You cannot set the 'entityState' of an entity when it is detached - except by first attaching it to an EntityManager");
    }
    let entity = this.entity;
    let em = this.entityManager;
    let needsSave = true;
    if (entityState === EntityState.Unchanged) {
      clearOriginalValues(entity);
      delete this.hasTempKey;
      needsSave = false;
    } else if (entityState === EntityState.Added) {
      clearOriginalValues(entity);
      // TODO: more to do here... like regenerating key ???
    } else if (entityState === EntityState.Deleted) {
      if (this.entityState.isAdded()) {
        // turn it into a detach and exit early
        this.setEntityState(EntityState.Detached);
        return true;
      } else {
        // TODO: think about cascade deletes
        // entityState needs to be set it early in this one case to insure that fk's are not cleared.
        this.entityState = EntityState.Deleted;
        removeFromRelations(entity, EntityState.Deleted);
      }
    } else if (entityState === EntityState.Modified) {
      // nothing extra needed
    } else if (entityState === EntityState.Detached) {
      let group = this.entityGroup;
      // no group === already detached.
      if (!group) return false;
      group.detachEntity(entity);
      // needs to occur early here - so this IS deliberately redundent with the same code later in this method.
      this.entityState = entityState;
      removeFromRelations(entity, EntityState.Detached);
      this._detach();
      em.entityChanged.publish({
        entityAction: EntityAction.Detach,
        entity: entity
      });
      needsSave = false;
    }
    this.entityState = entityState;
    em._notifyStateChange(entity, needsSave);
    return true;
  }
  /**
  Performs a query for the value of a specified [[NavigationProperty]]. __Async__
  >      emp.entityAspect.loadNavigationProperty("Orders").then(function (data) {
  >          var orders = data.results;
  >      }).catch(function (exception) {
  >          // handle exception here;
  >      });
  @param navigationProperty - The NavigationProperty or the name of the NavigationProperty to 'load'.
  @param callback - Function to call on success.
  @param errorCallback - Function to call on failure.
  @return Promise with shape
    - results {Array of Entity}
    - query {EntityQuery} The original query
    - httpResponse {httpResponse} The HttpResponse returned from the server.
  **/
  loadNavigationProperty(navigationProperty, callback, errorCallback) {
    let entity = this.entity;
    let navProperty = entity.entityType._checkNavProperty(navigationProperty);
    let query = EntityQuery.fromEntityNavigation(entity, navProperty);
    // return entity.entityAspect.entityManager.executeQuery(query, callback, errorCallback);
    let promise = entity.entityAspect.entityManager.executeQuery(query);
    return promise.then(data => {
      this._markAsLoaded(navProperty.name);
      if (callback) callback(data);
      return Promise.resolve(data);
    }, error => {
      if (errorCallback) errorCallback(error);
      return Promise.reject(error);
    });
  }
  /**
  Marks this navigationProperty on this entity as already having been loaded.
  >      emp.entityAspect.markNavigationPropertyAsLoaded("Orders");
  @param navigationProperty - The NavigationProperty or name of NavigationProperty to 'load'.
  **/
  markNavigationPropertyAsLoaded(navigationProperty) {
    if (!this.entity) return;
    let navProperty = this.entity.entityType._checkNavProperty(navigationProperty);
    this._markAsLoaded(navProperty.name);
  }
  /**
  Determines whether a navigationProperty on this entity has already been loaded.
      A navigation property is considered loaded when any of the following three conditions applies:
        1. It was fetched from the backend server.
        <br/>   This can be the result of an expand query or a call to the [[EntityAspect.loadNavigationProperty]] method.
        <br/>   Note that even if the fetch returns nothing the property is still marked as loaded in this case.
    1. The property is scalar and has been set to a nonnull value.
    1. The [[EntityAspect.markNavigationPropertyAsLoaded]] was called.
  
  >     var wasLoaded = emp.entityAspect.isNavigationPropertyLoaded("Orders");
  @param navigationProperty - The NavigationProperty or name of NavigationProperty to 'load'.
  **/
  isNavigationPropertyLoaded(navigationProperty) {
    if (!this.entity) return;
    let navProperty = this.entity.entityType._checkNavProperty(navigationProperty);
    if (navProperty.isScalar && this.entity.getProperty(navProperty.name) != null) {
      return true;
    }
    return this._loadedNps && this._loadedNps.indexOf(navProperty.name) >= 0;
  }
  /** @hidden @internal */
  _markAsLoaded(navPropName) {
    this._loadedNps = this._loadedNps || [];
    core.arrayAddItemUnique(this._loadedNps, navPropName);
  }
  /**
  Performs validation on the entity, any errors encountered during the validation are available via the
  [[EntityAspect.getValidationErrors]] method. Validating an entity means executing
  all of the validators on both the entity itself as well as those on each of its properties.
  >      // assume order is an order entity attached to an EntityManager.
  >      var isOk = order.entityAspect.validateEntity();
  >      // isOk will be 'true' if there are no errors on the entity.
  >      if (!isOk) {
  >          var errors = order.entityAspect.getValidationErrors();
  >      }
  @return Whether the entity passed validation.
  **/
  validateEntity() {
    let ok = true;
    this._processValidationOpAndPublish(function (that) {
      ok = validateTarget(that.entity);
    });
    return ok;
  }
  /**
  Performs validation on a specific property of this entity, any errors encountered during the validation are available via the
  [[EntityAspect.getValidationErrors]] method. Validating a property means executing
  all of the validators on the specified property.  This call is also made automatically anytime a property
  of an entity is changed.
  >      // assume order is an order entity attached to an EntityManager.
  >      var isOk = order.entityAspect.validateProperty("Order");
      or
  >      var orderDateProperty = order.entityType.getProperty("OrderDate");
  >      var isOk = order.entityAspect.validateProperty(OrderDateProperty);
  @param property - The [[DataProperty]] or [[NavigationProperty]] to validate or a string
  with the name of the property or a property path with the path to a property of a complex object.
  @param context -  A context object used to pass additional information to each [[Validator]].
  @return Whether the entity passed validation.
  **/
  validateProperty(property, context) {
    let value = this.getPropertyValue(property); // performs validations
    if (value && value.complexAspect) {
      return validateTarget(value);
    }
    context = context || {};
    context.entity = this.entity;
    if (typeof property === "string") {
      context.property = this.entity.entityType.getProperty(property, true);
      context.propertyName = property;
    } else {
      context.property = property;
      context.propertyName = property.name;
    }
    return this._validateProperty(value, context);
  }
  /**
  Returns the validation errors associated with either the entire entity or any specified property.
  
  This method can return all of the errors for an Entity
  >      // assume order is an order entity attached to an EntityManager.
  >      var valErrors = order.entityAspect.getValidationErrors();
      as well as those for just a specific property.
  >      // assume order is an order entity attached to an EntityManager.
  >      var orderDateErrors = order.entityAspect.getValidationErrors("OrderDate");
      which can also be expressed as
  >      // assume order is an order entity attached to an EntityManager.
  >      var orderDateProperty = order.entityType.getProperty("OrderDate");
  >      var orderDateErrors = order.entityAspect.getValidationErrors(orderDateProperty);
  @param property - The property for which validation errors should be retrieved.
  If omitted, all of the validation errors for this entity will be returned.
  @return A array of validation errors.
  **/
  getValidationErrors(property) {
    assertParam(property, "property").isOptional().isEntityProperty().or().isString().check();
    let result = core.getOwnPropertyValues(this._validationErrors);
    if (property) {
      let propertyName = typeof property === 'string' ? property : property.name;
      result = result.filter(function (ve) {
        return ve.property && (ve.property.name === propertyName || propertyName.indexOf(".") !== -1 && ve.propertyName === propertyName);
      });
    }
    return result;
  }
  /**
  Adds a validation error.
  **/
  addValidationError(validationError) {
    assertParam(validationError, "validationError").isInstanceOf(ValidationError).check();
    this._processValidationOpAndPublish(function (that) {
      that._addValidationError(validationError);
    });
  }
  /**
  Removes a validation error.
  @param validationErrorOrKey - Either a ValidationError or a ValidationError 'key' value
  **/
  removeValidationError(validationErrorOrKey) {
    assertParam(validationErrorOrKey, "validationErrorOrKey").isString().or().isInstanceOf(ValidationError).or().isInstanceOf(Validator).check();
    let key = typeof validationErrorOrKey === "string" ? validationErrorOrKey : validationErrorOrKey.key;
    this._processValidationOpAndPublish(function (that) {
      that._removeValidationError(key);
    });
  }
  /**
  Removes all of the validation errors for a specified entity
  **/
  clearValidationErrors() {
    this._processValidationOpAndPublish(function (that) {
      core.objectForEach(that._validationErrors, function (key, valError) {
        if (valError) {
          delete that._validationErrors[key];
          that._pendingValidationResult.removed.push(valError);
        }
      });
      that.hasValidationErrors = !core.isEmpty(that._validationErrors);
    });
  }
  /**
  Returns an [[EntityKey]] for the entity pointed to by the specified scalar NavigationProperty.
  This only returns an EntityKey if the current entity is a 'child' entity along the specified NavigationProperty.
  i.e. has a single parent.
      @param navigationProperty - The [[NavigationProperty]] ( pointing to a parent).
  @returns Either a parent EntityKey if this is a 'child' entity or null;
  */
  getParentKey(navigationProperty) {
    if (!this.entity) return null;
    // TODO: review this - not sure about the comment.
    // NavigationProperty doesn't yet exist
    // assertParam(navigationProperty, "navigationProperty").isInstanceOf(NavigationProperty).check();
    let fkNames = navigationProperty.foreignKeyNames;
    if (fkNames.length === 0) return null;
    let that = this;
    let fkValues = fkNames.map(function (fkn) {
      return that.entity.getProperty(fkn);
    });
    return new EntityKey(navigationProperty.entityType, fkValues);
  }
  // TODO: refactor this and the static getPropertyPathValue.
  /**
  Returns the value of a specified DataProperty or NavigationProperty or 'property path'.
  **/
  getPropertyValue(property) {
    assertParam(property, "property").isString().or().isEntityProperty().check();
    let value;
    if (typeof property === 'string') {
      let propNames = property.trim().split(".");
      let propName = propNames.shift();
      value = this.entity;
      value = value.getProperty(propName);
      while (propNames.length > 0) {
        propName = propNames.shift();
        value = value.getProperty(propName);
      }
    } else {
      if (!(property.parentType instanceof EntityType)) {
        throw new Error("The validateProperty method does not accept a 'property' parameter whose parentType is a ComplexType; " + "Pass a 'property path' string as the 'property' parameter instead ");
      }
      value = this.entity.getProperty(property.name);
    }
    return value;
  }
  // internal methods
  /** @hidden @internal */
  _checkOperation(operationName) {
    if (this.isBeingSaved) {
      throw new Error("Cannot perform a '" + operationName + "' on an entity that is in the process of being saved");
    }
    // allows chaining
    return this;
  }
  /** @hidden @internal */
  _detach() {
    this.entityGroup = undefined;
    this.entityManager = undefined;
    this.entityState = EntityState.Detached;
    this.originalValues = {};
    this._validationErrors = {};
    this.hasValidationErrors = false;
    this.validationErrorsChanged.clear();
    this.propertyChanged.clear();
  }
  // called from defaultInterceptor.
  /** @hidden @internal */
  _validateProperty(value, context) {
    let ok = true;
    this._processValidationOpAndPublish(function (that) {
      context.property.getAllValidators().forEach(function (validator) {
        ok = validate(that, validator, value, context) && ok;
      });
    });
    return ok;
  }
  /** @hidden @internal */
  _processValidationOpAndPublish(validationFn) {
    if (this._pendingValidationResult) {
      // only top level processValidations call publishes
      validationFn(this);
    } else {
      try {
        this._pendingValidationResult = {
          entity: this.entity,
          added: [],
          removed: []
        };
        validationFn(this);
        if (this._pendingValidationResult.added.length > 0 || this._pendingValidationResult.removed.length > 0) {
          this.validationErrorsChanged.publish(this._pendingValidationResult);
          // this might be a detached entity hence the guard below.
          this.entityManager && this.entityManager.validationErrorsChanged.publish(this._pendingValidationResult);
        }
      } finally {
        this._pendingValidationResult = undefined;
      }
    }
  }
  /** @hidden @internal */
  // TODO: add/use a ValidationError type
  _addValidationError(validationError) {
    this._validationErrors[validationError.key] = validationError;
    this.hasValidationErrors = true;
    this._pendingValidationResult.added.push(validationError);
  }
  /** @hidden @internal */
  _removeValidationError(key) {
    let valError = this._validationErrors[key];
    if (valError) {
      delete this._validationErrors[key];
      this.hasValidationErrors = !core.isEmpty(this._validationErrors);
      this._pendingValidationResult.removed.push(valError);
    }
  }
}
/** @hidden @internal */
EntityAspect._nullInstance = new EntityAspect(); // TODO: determine if this works
BreezeEvent.bubbleEvent(EntityAspect.prototype, function () {
  return this.entityManager;
});
function rejectChangesCore(target) {
  let aspect = target.entityAspect || target.complexAspect;
  let stype = target.entityType || target.complexType;
  let originalValues = aspect.originalValues;
  for (let propName in originalValues) {
    target.setProperty(propName, originalValues[propName]);
  }
  stype.complexProperties.forEach(function (cp) {
    let cos = target.getProperty(cp.name);
    if (cp.isScalar) {
      rejectChangesCore(cos);
    } else {
      cos._rejectChanges();
      cos.forEach(rejectChangesCore);
    }
  });
}
function removeFromRelations(entity, entityState) {
  // remove this entity from any collections.
  // mark the entity deleted or detached
  let isDeleted = entityState.isDeleted();
  if (isDeleted) {
    removeFromRelationsCore(entity);
  } else {
    core.using(entity.entityAspect.entityManager, "isLoading", true, function () {
      removeFromRelationsCore(entity);
    });
  }
}
function removeFromRelationsCore(entity) {
  entity.entityType.navigationProperties.forEach(function (np) {
    let inverseNp = np.inverse;
    let npValue = entity.getProperty(np.name);
    if (np.isScalar) {
      if (npValue) {
        if (inverseNp) {
          if (inverseNp.isScalar) {
            npValue.setProperty(inverseNp.name, null);
          } else {
            let collection = npValue.getProperty(inverseNp.name);
            if (collection.length) {
              core.arrayRemoveItem(collection, entity);
            }
          }
        }
        entity.setProperty(np.name, null);
      }
    } else {
      if (inverseNp != null) {
        // npValue is a live list so we need to copy it first.
        npValue.slice(0).forEach(v => {
          if (inverseNp.isScalar) {
            v.setProperty(inverseNp.name, null);
          } else {
            // TODO: many to many - not yet handled.
          }
        });
      }
      // now clear it.
      npValue.length = 0;
    }
  });
}
// note entityAspect only - ( no complex aspect allowed on the call).
function validate(entityAspect, validator, value, context) {
  let ve = validator.validate(value, context);
  if (ve) {
    entityAspect._addValidationError(ve);
    return false;
  } else {
    let key = ValidationError.getKey(validator, context ? context.propertyName : null);
    entityAspect._removeValidationError(key);
    return true;
  }
}
// coIndex is only used where target is a complex object that is part of an array of complex objects
// in which case ctIndex is the index of the target within the array.
function validateTarget(target, coIndex) {
  let ok = true;
  let stype = target.entityType || target.complexType;
  let aspect = target.entityAspect || target.complexAspect;
  let entityAspect = target.entityAspect || target.complexAspect.getEntityAspect();
  let context = {
    entity: entityAspect.entity
  };
  if (coIndex !== undefined) {
    context.index = coIndex;
  }
  stype.getProperties().forEach(function (p) {
    let value = target.getProperty(p.name);
    let validators = p.getAllValidators();
    if (validators.length > 0) {
      context.property = p;
      context.propertyName = aspect.getPropertyPath(p.name);
      ok = entityAspect._validateProperty(value, context) && ok;
    }
    if (p.isComplexProperty) {
      if (p.isScalar) {
        ok = validateTarget(value) && ok;
      } else {
        ok = value.reduce(function (pv, cv, ix) {
          return validateTarget(cv, ix) && pv;
        }, ok);
      }
    }
  });
  // then target level
  stype.getAllValidators().forEach(function (validator) {
    ok = validate(entityAspect, validator, target) && ok;
  });
  return ok;
}
/**
An ComplexAspect instance is associated with every complex object instance and is accessed via the complex object's 'complexAspect' property.

The ComplexAspect itself provides properties to determine the parent object, parent property and original values for the complex object.

A ComplexAspect will almost never need to be constructed directly. You will usually get an ComplexAspect by accessing
an entities 'complexAspect' property.  This property will be automatically attached when an complex object is created as part of an
entity via either a query, import or EntityManager.createEntity call.
>      // assume address is a complex property on the 'Customer' type
>      var aspect = aCustomer.address.complexAspect;
>      // aCustomer === aspect.parent;
**/
class ComplexAspect {
  /** You will rarely, if ever, create a ComplexAspect directly. */
  constructor(complexObject, parent, parentProperty) {
    if (!complexObject) {
      throw new Error("The  ComplexAspect ctor requires an entity as its only argument.");
    }
    if (complexObject.complexAspect) {
      return complexObject.complexAspect;
    }
    // if called without new
    if (!(this instanceof ComplexAspect)) {
      return new ComplexAspect(complexObject, parent, parentProperty);
    }
    // entityType should already be on the entity from 'watch'
    this.complexObject = complexObject;
    complexObject.complexAspect = this;
    // TODO: keep public or not?
    this.originalValues = {};
    // if a standalone complexObject
    if (parent != null) {
      this.parent = parent;
      this.parentProperty = parentProperty;
    }
    let complexType = complexObject.complexType;
    if (!complexType) {
      let typeName = complexObject.prototype._$typeName;
      if (!typeName) {
        throw new Error("This entity is not registered as a valid ComplexType");
      } else {
        throw new Error("Metadata for this complexType has not yet been resolved: " + typeName);
      }
    }
    let complexCtor = complexType.getCtor();
    config.interfaceRegistry.modelLibrary.getDefaultInstance().startTracking(complexObject, complexCtor.prototype);
  }
  /**
  Returns the EntityAspect for the top level entity that contains this complex object.
  **/
  getEntityAspect() {
    let parent = this.parent;
    if (!parent) return new EntityAspect();
    let entityAspect = parent.entityAspect;
    while (parent && !entityAspect) {
      parent = parent.complexAspect && parent.complexAspect.parent;
      entityAspect = parent && parent.entityAspect;
    }
    return entityAspect || new EntityAspect();
  }
  /**  @hidden @internal */
  // TODO: rename - and use '_'; used on both EntityAspect and ComplexAspect for polymorphic reasons.
  getPropertyPath(propName) {
    let parent = this.parent;
    if (!parent) return null;
    let aspect = parent.complexAspect || parent.entityAspect;
    return aspect.getPropertyPath(this.parentProperty.name + "." + propName);
  }
}
function clearOriginalValues(target) {
  let aspect = target.entityAspect || target.complexAspect;
  aspect.originalValues = {};
  let stype = target.entityType || target.complexType;
  stype.complexProperties.forEach(function (cp) {
    let cos = target.getProperty(cp.name);
    if (cp.isScalar) {
      clearOriginalValues(cos);
    } else {
      cos._acceptChanges();
      cos.forEach(clearOriginalValues);
    }
  });
}

/**
A NamingConvention instance is used to specify the naming conventions under which a MetadataStore
will translate property names between the server and the javascript client.

The default NamingConvention does not perform any translation, it simply passes property names thru unchanged.
@dynamic
**/
class NamingConvention {
  /**
  NamingConvention constructor
  >      // A naming convention that converts the first character of every property name to uppercase on the server
  >      // and lowercase on the client.
  >      var namingConv = new NamingConvention({
  >          serverPropertyNameToClient: function(serverPropertyName) {
  >              return serverPropertyName.substr(0, 1).toLowerCase() + serverPropertyName.substr(1);
  >          },
  >          clientPropertyNameToServer: function(clientPropertyName) {
  >              return clientPropertyName.substr(0, 1).toUpperCase() + clientPropertyName.substr(1);
  >          }
  >      });
  >      var ms = new MetadataStore({ namingConvention: namingConv });
  >      var em = new EntityManager( { metadataStore: ms });
  **/
  constructor(ncConfig) {
    assertConfig(ncConfig || {}).whereParam("name").isOptional().isString().whereParam("serverPropertyNameToClient").isFunction().whereParam("clientPropertyNameToServer").isFunction().applyAll(this);
    if (!this.name) {
      this.name = core.getUuid();
    }
    config._storeObject(this, "NamingConvention", this.name);
  }
  /**
  Sets the 'defaultInstance' by creating a copy of the current 'defaultInstance' and then applying all of the properties of the current instance.
  The current instance is returned unchanged.
  >      var namingConv = new NamingConvention({
  >          serverPropertyNameToClient: function(serverPropertyName) {
  >              return serverPropertyName.substr(0, 1).toLowerCase() + serverPropertyName.substr(1);
  >          },
  >          clientPropertyNameToServer: function(clientPropertyName) {
  >              return clientPropertyName.substr(0, 1).toUpperCase() + clientPropertyName.substr(1);
  >          }
  >      });
  >      namingConv.setAsDefault();
  **/
  setAsDefault() {
    return core.setAsDefault(this, NamingConvention);
  }
}
/**


/**
A noop naming convention - This is the default unless another is specified.
**/
NamingConvention.none = new NamingConvention({
  name: "noChange",
  serverPropertyNameToClient: serverPropertyName => {
    return serverPropertyName;
  },
  clientPropertyNameToServer: clientPropertyName => {
    return clientPropertyName;
  }
});
/**
The "camelCase" naming convention - This implementation only lowercases the first character of the server property name
but leaves the rest of the property name intact.  If a more complicated version is needed then one should be created via the ctor.
**/
NamingConvention.camelCase = new NamingConvention({
  name: "camelCase",
  serverPropertyNameToClient: serverPropertyName => {
    return serverPropertyName.substr(0, 1).toLowerCase() + serverPropertyName.substr(1);
  },
  clientPropertyNameToServer: clientPropertyName => {
    return clientPropertyName.substr(0, 1).toUpperCase() + clientPropertyName.substr(1);
  }
});
/**
The default value whenever NamingConventions are not specified.
**/
NamingConvention.defaultInstance = new NamingConvention(NamingConvention.none);
NamingConvention.prototype._$typeName = "NamingConvention";
const RX_COLLECTION = /Collection\((.*)\)/;
function parse(metadataStore, schemas, altMetadata) {
  metadataStore._entityTypeResourceMap = {};
  schemas = core.toArray(schemas);
  schemas.forEach(function (schema) {
    if (schema.cSpaceOSpaceMapping) {
      // Web api only - not avail in OData.
      // TODO throw informative error if already parsed and converted to map on a previous pass
      let mappings = JSON.parse(schema.cSpaceOSpaceMapping);
      let newMap = {};
      mappings.forEach(function (mapping) {
        newMap[mapping[0]] = mapping[1];
      });
      schema.cSpaceOSpaceMapping = newMap;
    }
    if (schema.entityContainer) {
      core.toArray(schema.entityContainer).forEach(function (container) {
        core.toArray(container.entitySet).forEach(function (entitySet) {
          let entityTypeName = parseTypeNameWithSchema(entitySet.entityType, schema).typeName;
          metadataStore.setEntityTypeForResourceName(entitySet.name, entityTypeName);
          metadataStore._entityTypeResourceMap[entityTypeName] = entitySet.name;
        });
      });
    }
    // process complextypes before entity types.
    if (schema.complexType) {
      core.toArray(schema.complexType).forEach(function (ct) {
        parseCsdlComplexType(ct, schema, metadataStore);
      });
    }
    if (schema.entityType) {
      core.toArray(schema.entityType).forEach(function (et) {
        parseCsdlEntityType(et, schema, schemas, metadataStore);
      });
    }
  });
  let badNavProps = metadataStore.getIncompleteNavigationProperties();
  if (badNavProps.length > 0) {
    let msg = badNavProps.map(function (npa) {
      if (Array.isArray(npa)) {
        return npa.map(function (np) {
          return np.parentType.name + ":" + np.name;
        }).join(', ');
      }
      return npa.parentType.name + ":" + npa.name;
    }).join(', ');
    throw new Error("Incomplete navigation properties: " + msg);
  }
  if (altMetadata) {
    metadataStore.importMetadata(altMetadata, true);
  }
  return metadataStore;
}
function parseCsdlEntityType(csdlEntityType, schema, schemas, metadataStore) {
  let shortName = csdlEntityType.name;
  let ns = getNamespaceFor(shortName, schema);
  let entityType = new EntityType({
    shortName: shortName,
    namespace: ns,
    isAbstract: csdlEntityType.abstract && csdlEntityType.abstract === 'true'
  });
  if (csdlEntityType.baseType) {
    let baseTypeName = parseTypeNameWithSchema(csdlEntityType.baseType, schema).typeName;
    entityType.baseTypeName = baseTypeName;
    let baseEntityType = metadataStore._getStructuralType(baseTypeName, true);
    if (baseEntityType) {
      completeParseCsdlEntityType(entityType, csdlEntityType, schema, schemas, metadataStore);
    } else {
      let deferrals = metadataStore._deferredTypes[baseTypeName];
      if (!deferrals) {
        deferrals = [];
        metadataStore._deferredTypes[baseTypeName] = deferrals;
      }
      deferrals.push({
        entityType: entityType,
        csdlEntityType: csdlEntityType
      });
    }
  } else {
    completeParseCsdlEntityType(entityType, csdlEntityType, schema, schemas, metadataStore);
  }
  // entityType may or may not have been added to the metadataStore at this point.
  return entityType;
}
function completeParseCsdlEntityType(entityType, csdlEntityType, schema, schemas, metadataStore) {
  let keyNamesOnServer = csdlEntityType.key ? core.toArray(csdlEntityType.key.propertyRef).map(core.pluck("name")) : [];
  core.toArray(csdlEntityType.property).forEach(function (prop) {
    parseCsdlDataProperty(entityType, prop, schema, keyNamesOnServer);
  });
  core.toArray(csdlEntityType.navigationProperty).forEach(function (prop) {
    parseCsdlNavProperty(entityType, prop, schema, schemas);
  });
  metadataStore.addEntityType(entityType);
  entityType.defaultResourceName = metadataStore._entityTypeResourceMap[entityType.name];
  let deferredTypes = metadataStore._deferredTypes;
  let deferrals = deferredTypes[entityType.name];
  if (deferrals) {
    deferrals.forEach(function (d) {
      completeParseCsdlEntityType(d.entityType, d.csdlEntityType, schema, schemas, metadataStore);
    });
    delete deferredTypes[entityType.name];
  }
}
function parseCsdlComplexType(csdlComplexType, schema, metadataStore) {
  let shortName = csdlComplexType.name;
  let ns = getNamespaceFor(shortName, schema);
  let complexType = new ComplexType({
    shortName: shortName,
    namespace: ns
  });
  core.toArray(csdlComplexType.property).forEach(function (prop) {
    parseCsdlDataProperty(complexType, prop, schema);
  });
  metadataStore.addEntityType(complexType);
  return complexType;
}
function parseCsdlDataProperty(parentType, csdlProperty, schema, keyNamesOnServer) {
  let dp;
  let typeParts = csdlProperty.type.split(".");
  // Both tests on typeParts are necessary because of differing metadata conventions for OData and Edmx feeds.
  if (typeParts[0].endsWith("Edm") && typeParts.length === 2) {
    dp = parseCsdlSimpleProperty(parentType, csdlProperty, keyNamesOnServer);
  } else {
    if (isEnumType(csdlProperty, schema)) {
      dp = parseCsdlSimpleProperty(parentType, csdlProperty, keyNamesOnServer);
      if (dp) {
        dp.enumType = csdlProperty.type;
      }
    } else {
      dp = parseCsdlComplexProperty(parentType, csdlProperty, schema);
    }
  }
  if (dp) {
    parentType._addPropertyCore(dp);
    addValidators(dp);
  }
  return dp;
}
function parseCsdlSimpleProperty(parentType, csdlProperty, keyNamesOnServer) {
  let isCollectionType = isCollection(csdlProperty.type);
  let propertyType = getCollectionType(csdlProperty.type) || csdlProperty.type;
  let dataType = DataType.fromEdmDataType(propertyType);
  if (dataType == null) {
    parentType.warnings.push("Unable to recognize DataType for property: " + csdlProperty.name + " DateType: " + csdlProperty.type);
    return undefined;
  }
  let isNullable = csdlProperty.nullable === 'true' || csdlProperty.nullable == null;
  // let fixedLength = csdlProperty.fixedLength ? csdlProperty.fixedLength === true : undefined;
  let isPartOfKey = keyNamesOnServer != null && keyNamesOnServer.indexOf(csdlProperty.name) >= 0;
  if (isPartOfKey && parentType instanceof EntityType && parentType.autoGeneratedKeyType === AutoGeneratedKeyType.None) {
    if (isIdentityProperty(csdlProperty)) {
      parentType.autoGeneratedKeyType = AutoGeneratedKeyType.Identity;
    }
  }
  // TODO: nit - don't set maxLength if null;
  let maxLength = csdlProperty.maxLength;
  maxLength = maxLength == null || maxLength === "Max" ? null : parseInt(maxLength, 10);
  // can't set the name until we go thru namingConventions and these need the dp.
  let dp = new DataProperty({
    nameOnServer: csdlProperty.name,
    dataType: dataType,
    isNullable: isNullable,
    isPartOfKey: isPartOfKey,
    isScalar: !isCollectionType,
    maxLength: maxLength,
    defaultValue: csdlProperty.defaultValue,
    // fixedLength: fixedLength,
    concurrencyMode: csdlProperty.concurrencyMode
  });
  if (dataType === DataType.Undefined) {
    dp.rawTypeName = csdlProperty.type;
  }
  return dp;
}
function parseCsdlComplexProperty(parentType, csdlProperty, schema) {
  // Complex properties are never nullable ( per EF specs)
  // let isNullable = csdlProperty.nullable === 'true' || csdlProperty.nullable == null;
  // let complexTypeName = csdlProperty.type.split("Edm.")[1];
  let isCollectionType = isCollection(csdlProperty.type);
  let propertyType = getCollectionType(csdlProperty.type) || csdlProperty.type;
  let complexTypeName = parseTypeNameWithSchema(propertyType, schema).typeName;
  // can't set the name until we go thru namingConventions and these need the dp.
  let dp = new DataProperty({
    nameOnServer: csdlProperty.name,
    complexTypeName: complexTypeName,
    isNullable: false,
    isScalar: !isCollectionType
  });
  return dp;
}
function parseCsdlNavProperty(entityType, csdlProperty, schema, schemas) {
  let association = getAssociation(csdlProperty, schema, schemas);
  if (!association) {
    throw new Error("Unable to resolve Foreign Key Association: " + csdlProperty.relationship);
  }
  let toEnd = core.arrayFirst(association.end, assocEnd => {
    return assocEnd.role === csdlProperty.toRole;
  });
  let isScalar = toEnd.multiplicity !== "*";
  let dataType = parseTypeNameWithSchema(toEnd.type, schema).typeName;
  let constraint = association.referentialConstraint;
  if (!constraint) {
    // TODO: Revisit this later - right now we just ignore many-many and assocs with missing constraints.
    // Think about adding this back later.
    if (association.end[0].multiplicity === "*" && association.end[1].multiplicity === "*") {
      // ignore many to many relations for now
      return;
    } else {
      // For now assume it will be set later directly on the client.
      // other alternative is to throw an error:
      // throw new Error("Foreign Key Associations must be turned on for this model");
    }
  }
  let cfg = {
    nameOnServer: csdlProperty.name,
    entityTypeName: dataType,
    isScalar: isScalar,
    associationName: association.name
  };
  if (constraint) {
    let principal = constraint.principal;
    let dependent = constraint.dependent;
    let propRefs = core.toArray(dependent.propertyRef);
    let fkNames = propRefs.map(core.pluck("name"));
    if (csdlProperty.fromRole === principal.role) {
      cfg.invForeignKeyNamesOnServer = fkNames;
    } else {
      // will be used later by np._update
      cfg.foreignKeyNamesOnServer = fkNames;
    }
  }
  let np = new NavigationProperty(cfg);
  entityType._addPropertyCore(np);
  return np;
}
function isCollection(propertyType) {
  return RX_COLLECTION.test(propertyType);
}
function isEnumType(csdlProperty, schema) {
  if (schema.enumType) return isEdmxEnumType(csdlProperty, schema);else if (schema.extensions) return isODataEnumType(csdlProperty, schema);else return false;
}
function isEdmxEnumType(csdlProperty, schema) {
  let enumTypes = core.toArray(schema.enumType);
  let propertyType = getCollectionType(csdlProperty.type) || csdlProperty.type;
  let typeParts = propertyType.split(".");
  let baseTypeName = typeParts[typeParts.length - 1];
  return enumTypes.some(function (enumType) {
    return enumType.name === baseTypeName;
  });
}
function isODataEnumType(csdlProperty, schema) {
  let enumTypes = schema.extensions.filter(ext => {
    return ext.name === "EnumType";
  });
  let propertyType = getCollectionType(csdlProperty.type) || csdlProperty.type;
  let typeParts = propertyType.split(".");
  let baseTypeName = typeParts[typeParts.length - 1];
  return enumTypes.some(enumType => {
    return enumType.attributes.some(attr => {
      return attr.name === "Name" && attr.value === baseTypeName;
    });
  });
}
function addValidators(dataProperty) {
  let typeValidator;
  if (!dataProperty.isNullable) {
    dataProperty.validators.push(Validator.required());
  }
  if (dataProperty.isComplexProperty) return;
  if (dataProperty.dataType === DataType.String) {
    if (dataProperty.maxLength) {
      let validatorArgs = {
        maxLength: dataProperty.maxLength
      };
      typeValidator = Validator.maxLength(validatorArgs);
    } else {
      typeValidator = Validator.string();
    }
  } else {
    let validatorCtor = dataProperty.dataType.validatorCtor;
    if (!validatorCtor) return;
    typeValidator = validatorCtor();
  }
  dataProperty.validators.push(typeValidator);
}
function isIdentityProperty(csdlProperty) {
  // see if web api feed
  let propName = core.arrayFirst(Object.keys(csdlProperty), pn => {
    return pn.indexOf("StoreGeneratedPattern") >= 0;
  });
  if (propName) {
    return csdlProperty[propName] === "Identity";
  } else {
    // see if Odata feed
    let extensions = csdlProperty.extensions;
    if (!extensions) {
      return false;
    }
    let identityExtn = core.arrayFirst(extensions, extension => {
      return extension.name === "StoreGeneratedPattern" && extension.value === "Identity";
    });
    return !!identityExtn;
  }
}
// Fast version
// np: schema.entityType[].navigationProperty.relationship -> schema.association
//   match( shortName(np.relationship) == schema.association[].name
//      --> association__
// Correct version
// np: schema.entityType[].navigationProperty.relationship -> schema.association
//   match( np.relationship == schema.entityContainer[0].associationSet[].association )
//      -> associationSet.name
//   match ( associationSet.name == schema.association[].name )
//      -> association
function getAssociation(csdlNavProperty, containingSchema, schemas) {
  let assocFullName = parseTypeNameWithSchema(csdlNavProperty.relationship, containingSchema);
  let assocNamespace = assocFullName.namespace;
  let assocSchema = core.arrayFirst(schemas, schema => {
    return schema.namespace === assocNamespace;
  });
  if (!assocSchema) return null;
  let assocName = assocFullName.shortTypeName;
  let assocs = assocSchema.association;
  if (!assocs) return null;
  if (!Array.isArray(assocs)) {
    assocs = [assocs];
  }
  let association = core.arrayFirst(assocs, assoc => {
    return assoc.name === assocName;
  });
  return association;
}
// schema is only needed for navProperty type name
function parseTypeNameWithSchema(entityTypeName, schema) {
  let result = MetadataStore.parseTypeName(entityTypeName);
  if (schema && schema.cSpaceOSpaceMapping) {
    let ns = getNamespaceFor(result.shortTypeName, schema);
    if (ns) {
      result = MetadataStore.makeTypeHash(result.shortTypeName, ns);
    }
  }
  return result;
}
function getCollectionType(propertyType) {
  const match = propertyType.match(RX_COLLECTION);
  return match ? match[1] : null;
}
function getNamespaceFor(shortName, schema) {
  let ns;
  let mapping = schema.cSpaceOSpaceMapping;
  if (mapping) {
    let fullName = mapping[schema.namespace + "." + shortName];
    ns = fullName && fullName.substr(0, fullName.length - (shortName.length + 1));
    if (ns) return ns;
  }
  // if schema does not also have an entityType node then
  // this is an WebApi2 OData schema which is usually equal to 'Default'; which is useless.
  if (schema.entityType || schema.namespace !== 'Default') {
    return schema.namespace;
  }
  return null;
}
/** @hidden @internal */
const CsdlMetadataParser = {
  parse: parse
};

/**
A LocalQueryComparisonOptions instance is used to specify the "comparison rules" used when performing "local queries" in order
to match the semantics of these same queries when executed against a remote service.  These options should be set based on the
manner in which your remote service interprets certain comparison operations.

The default LocalQueryComparisonOptions stipulates 'caseInsensitive" queries with ANSI SQL rules regarding comparisons of unequal
length strings.
**/
class LocalQueryComparisonOptions {
  /**
  LocalQueryComparisonOptions constructor
  >      // create a 'caseSensitive - non SQL' instance.
  >      var lqco = new LocalQueryComparisonOptions({
  >              name: "caseSensitive-nonSQL"
  >              isCaseSensitive: true;
  >              usesSql92CompliantStringComparison: false;
  >          });
  >      // either apply it globally
  >      lqco.setAsDefault();
  >      // or to a specific MetadataStore
  >      var ms = new MetadataStore({ localQueryComparisonOptions: lqco });
  >      var em = new EntityManager( { metadataStore: ms });
  @param config - A configuration object.
  **/
  constructor(lqcoConfig) {
    assertConfig(lqcoConfig || {}).whereParam("name").isOptional().isString().whereParam("isCaseSensitive").isOptional().isBoolean().whereParam("usesSql92CompliantStringComparison").isBoolean().applyAll(this);
    if (!this.name) {
      this.name = core.getUuid();
    }
    config._storeObject(this, "LocalQueryComparisonOptions", this.name);
  }
  /**
  Sets the 'defaultInstance' by creating a copy of the current 'defaultInstance' and then applying all of the properties of the current instance.
  The current instance is returned unchanged.
  >     var lqco = new LocalQueryComparisonOptions({
  >        isCaseSensitive: false;
  >        usesSql92CompliantStringComparison: true;
  >     });
  >     lqco.setAsDefault();
  **/
  setAsDefault() {
    return core.setAsDefault(this, LocalQueryComparisonOptions);
  }
}
/**
Case insensitive SQL compliant options - this is also the default unless otherwise changed.
**/
LocalQueryComparisonOptions.caseInsensitiveSQL = new LocalQueryComparisonOptions({
  name: "caseInsensitiveSQL",
  isCaseSensitive: false,
  usesSql92CompliantStringComparison: true
});
/**
The default value whenever LocalQueryComparisonOptions are not specified. By default this is 'caseInsensitiveSQL'.
**/
LocalQueryComparisonOptions.defaultInstance = new LocalQueryComparisonOptions(LocalQueryComparisonOptions.caseInsensitiveSQL);
LocalQueryComparisonOptions.prototype._$typeName = "LocalQueryComparisonOptions";

/** @hidden @internal */
function defaultPropertyInterceptor(property, newValue, rawAccessorFn) {
  // 'this' is the entity itself in this context.
  if (newValue === undefined) newValue = null; // remove? to allow assignment to undefined in Babel constructors?
  let oldValue = rawAccessorFn();
  let dataType = property.dataType;
  if (dataType && dataType.parse) {
    // attempts to coerce a value to the correct type - if this fails return the value unchanged
    if (Array.isArray(newValue) && !property.isScalar) {
      newValue = newValue.map(function (nv) {
        return dataType.parse(nv, typeof nv);
      });
    } else {
      newValue = dataType.parse(newValue, typeof newValue);
    }
  }
  // exit if no change - extra cruft is because dateTimes don't compare cleanly.
  if (newValue === oldValue || newValue === null && oldValue === undefined || dataType && dataType.normalize && newValue && oldValue && dataType.normalize(newValue) === dataType.normalize(oldValue)) {
    return;
  }
  // CANNOT DO NEXT LINE because it has the possibility of creating a new property
  // 'entityAspect' on 'this'.  - Not permitted by IE inside of a defined property on a prototype.
  // let entityAspect = new EntityAspect(this);
  let propertyName;
  let entityAspect = this.entityAspect;
  if (entityAspect) {
    propertyName = property.name;
  } else {
    let localAspect = this.complexAspect;
    if (localAspect) {
      entityAspect = localAspect.getEntityAspect();
      propertyName = localAspect.getPropertyPath(property.name);
    } else {
      // does not yet have an EntityAspect so just set the prop
      rawAccessorFn(newValue);
      return;
    }
  }
  // Note that we need to handle multiple properties in process, not just one in order to avoid recursion.
  // ( except in the case of null propagation with fks where null -> 0 in some cases.)
  // (this may not be needed because of the newValue === oldValue test above)
  let inProcess = entityAspect._inProcess = entityAspect._inProcess || [];
  // check for recursion
  if (inProcess.indexOf(property) >= 0) return;
  inProcess.push(property);
  try {
    let context = {
      parent: this,
      property: property,
      newValue: newValue,
      oldValue: oldValue,
      propertyName: propertyName,
      entityAspect: entityAspect
    };
    if (property.isComplexProperty) {
      setDpValueComplex(context, rawAccessorFn);
    } else if (property.isDataProperty) {
      setDpValueSimple(context, rawAccessorFn);
    } else {
      setNpValue(context, rawAccessorFn);
    }
    postChangeEvents(context);
  } finally {
    inProcess.pop();
  }
}
function setDpValueSimple(context, rawAccessorFn) {
  let parent = context.parent;
  let property = context.property;
  let entityAspect = context.entityAspect;
  let oldValue = context.oldValue;
  let newValue = context.newValue;
  let entityManager = entityAspect.entityManager;
  if (!property.isScalar) {
    throw new Error("Nonscalar data properties are readonly - items may be added or removed but the collection may not be changed.");
  }
  // store an original value for this property if not already set
  if (entityAspect.entityState.isUnchangedOrModified()) {
    let propName = property.name;
    // localAspect is not the same as entityAspect for complex props
    let localAspect = EntityAspect.isEntity(parent) ? parent.entityAspect : parent.complexAspect;
    if (localAspect.originalValues[propName] === undefined) {
      // otherwise this entry will be skipped during serialization
      localAspect.originalValues[propName] = oldValue !== undefined ? oldValue : property.defaultValue;
    }
  }
  // if we are changing the key update our internal entityGroup indexes.
  if (property.isPartOfKey && entityManager && !entityManager.isLoading) {
    // 'entityType' on the next line be null for complex properties but it will only be ref'd within this
    // fn when the property is part of the key
    let entityType = parent.entityType;
    let keyProps = entityType.keyProperties;
    let values = keyProps.map(function (p) {
      if (p === property) {
        return newValue;
      } else {
        return parent.getProperty(p.name);
      }
    });
    let newKey = new EntityKey(entityType, values);
    if (entityManager.getEntityByKey(newKey)) {
      throw new Error("An entity with this key is already in the cache: " + newKey.toString());
    }
    let oldKey = parent.entityAspect.getKey();
    let eg = entityManager._findEntityGroup(entityType);
    eg._replaceKey(oldKey, newKey);
  }
  // process related updates ( the inverse relationship) first so that collection dups check works properly.
  // update inverse relationship
  let relatedNavProp = property.relatedNavigationProperty;
  if (relatedNavProp && entityManager) {
    // Example: bidirectional fkDataProperty: 1->n: order -> orderDetails
    // orderDetail.orderId <- newOrderId || null
    //    ==> orderDetail.order = lookupOrder(newOrderId)
    //    ==> (see set navProp above)
    //       and
    // Example: bidirectional fkDataProperty: 1->1: order -> internationalOrder
    // internationalOrder.orderId <- newOrderId || null
    //    ==> internationalOrder.order = lookupOrder(newOrderId)
    //    ==> (see set navProp above)
    if (newValue != null) {
      let relatedEntity;
      let key;
      if (relatedNavProp.invForeignKeyNames.length) {
        // property is related by field which is not the PK
        const query = new EntityQuery(relatedNavProp.entityType.defaultResourceName).where(relatedNavProp.invForeignKeyNames[0], 'eq', newValue);
        const qresult = entityManager.executeQueryLocally(query);
        if (qresult.length === 1) {
          relatedEntity = qresult[0];
        }
      } else {
        // property is related by the PK
        key = new EntityKey(relatedNavProp.entityType, [newValue]);
        relatedEntity = entityManager.getEntityByKey(key);
      }
      if (relatedEntity) {
        parent.setProperty(relatedNavProp.name, relatedEntity);
      } else {
        // it may not have been fetched yet in which case we want to add it as an unattachedChild.
        if (key) {
          // TODO currently _linkRelatedentities only works with PK relations, so we only add those.
          entityManager._unattachedChildrenMap.addChild(key, relatedNavProp, parent);
        }
        parent.setProperty(relatedNavProp.name, null);
      }
    } else {
      parent.setProperty(relatedNavProp.name, null);
    }
  } else if (property.inverseNavigationProperty && entityManager && !entityManager._inKeyFixup) {
    // Example: unidirectional fkDataProperty: 1->n: region -> territories
    // territory.regionId <- newRegionId
    //    ==> lookupRegion(newRegionId).territories.push(territory)
    //                and
    // Example: unidirectional fkDataProperty: 1->1: order -> internationalOrder
    // internationalOrder.orderId <- newOrderId
    //    ==> lookupOrder(newOrderId).internationalOrder = internationalOrder
    //                and
    // Example: unidirectional fkDataProperty: 1->n: region -> territories
    // territory.regionId <- null
    //    ==> lookupRegion(territory.oldRegionId).territories.remove(oldTerritory);
    //                and
    // Example: unidirectional fkDataProperty: 1->1: order -> internationalOrder
    // internationalOrder.orderId <- null
    //    ==> lookupOrder(internationalOrder.oldOrderId).internationalOrder = null;
    let invNavProp = property.inverseNavigationProperty;
    if (oldValue != null) {
      let key = new EntityKey(invNavProp.parentType, [oldValue]);
      let relatedEntity = entityManager.getEntityByKey(key);
      if (relatedEntity) {
        if (invNavProp.isScalar) {
          relatedEntity.setProperty(invNavProp.name, null);
        } else {
          // remove 'this' from old related nav prop
          let relatedArray = relatedEntity.getProperty(invNavProp.name);
          // arr.splice(arr.indexOf(value_to_remove), 1);
          relatedArray.splice(relatedArray.indexOf(parent), 1);
        }
      }
    }
    if (newValue != null) {
      let key = new EntityKey(invNavProp.parentType, [newValue]);
      let relatedEntity = entityManager.getEntityByKey(key);
      if (relatedEntity) {
        if (invNavProp.isScalar) {
          relatedEntity.setProperty(invNavProp.name, parent);
        } else {
          relatedEntity.getProperty(invNavProp.name).push(parent);
        }
      } else {
        // it may not have been fetched yet in which case we want to add it as an unattachedChild.
        entityManager._unattachedChildrenMap.addChild(key, invNavProp, parent);
      }
    }
  }
  rawAccessorFn(newValue);
  updateStateAndValidate(context);
  // if (property.isPartOfKey && (!this.complexAspect)) {
  if (property.isPartOfKey) {
    // propogate pk change to all related entities;
    let entityType = parent.entityType;
    let propertyIx = entityType.keyProperties.indexOf(property);
    // this part handles order.orderId => orderDetail.orderId
    // but won't handle product.productId => orderDetail.productId because product
    // doesn't have an orderDetails property.
    entityType.navigationProperties.forEach(function (np) {
      let inverseNp = np.inverse;
      let fkNames = inverseNp ? inverseNp.foreignKeyNames : np.invForeignKeyNames;
      if (fkNames.length === 0) return;
      let npValue = parent.getProperty(np.name);
      if (!npValue) return;
      let fkName = fkNames[propertyIx];
      if (np.isScalar) {
        npValue.setProperty(fkName, newValue);
      } else {
        npValue.slice(0).forEach(function (iv) {
          iv.setProperty(fkName, newValue);
        });
      }
    });
    // this handles unidirectional problems not covered above.
    if (entityManager) {
      let inverseForeignKeyProperties = entityType.inverseForeignKeyProperties;
      let baseEntityType = entityType.baseEntityType;
      while (baseEntityType) {
        inverseForeignKeyProperties = inverseForeignKeyProperties.concat(baseEntityType.inverseForeignKeyProperties);
        baseEntityType = baseEntityType.baseEntityType;
      }
      inverseForeignKeyProperties.forEach(invFkProp => {
        if (invFkProp.relatedNavigationProperty.inverse == null) {
          // this next step may be slow - it iterates over all of the entities in a group;
          // hopefully it doesn't happen often.
          entityManager._updateFkVal(invFkProp, oldValue, newValue);
        }
      });
    }
    // insure that cached key is updated.
    entityAspect.getKey(true);
  }
}
function setDpValueComplex(context, rawAccessorFn) {
  let property = context.property;
  let oldValue = context.oldValue;
  let newValue = context.newValue;
  // To get here it must be a ComplexProperty
  // 'dataType' will be a complexType
  let dataType = property.dataType;
  if (property.isScalar) {
    if (!newValue) {
      throw new Error(core.formatString("You cannot set the '%1' property to null because its datatype is the ComplexType: '%2'", property.name, property.dataType.name));
    }
    if (!oldValue) {
      let ctor = dataType.getCtor();
      oldValue = new ctor();
      rawAccessorFn(oldValue);
    }
    dataType.dataProperties.forEach(function (dp) {
      let pn = dp.name;
      let nv = newValue.getProperty(pn);
      oldValue.setProperty(pn, nv);
    });
  } else {
    throw new Error(core.formatString("You cannot set the non-scalar complex property: '%1' on the type: '%2'." + "Instead get the property and use array functions like 'push' or 'splice' to change its contents.", property.name, property.parentType.name));
  }
}
function setNpValue(context, rawAccessorFn) {
  let parent = context.parent;
  let property = context.property;
  let entityAspect = context.entityAspect;
  let oldValue = context.oldValue;
  let newValue = context.newValue;
  if (!property.isScalar) {
    throw new Error("Nonscalar navigation properties are readonly - entities can be added or removed but the collection may not be changed.");
  }
  let entityManager = entityAspect.entityManager;
  let inverseProp = property.inverse;
  // manage attachment -
  if (newValue != null) {
    if (!newValue.entityAspect) {
      return;
    }
    let newAspect = newValue.entityAspect;
    if (entityManager) {
      if (newAspect.entityState.isDetached()) {
        if (!entityManager.isLoading) {
          entityManager.attachEntity(newValue, EntityState.Added);
        }
      } else {
        if (newAspect.entityManager !== entityManager) {
          throw new Error("An Entity cannot be attached to an entity in another EntityManager. One of the two entities must be detached first.");
        }
      }
    } else {
      if (newAspect && newAspect.entityManager) {
        entityManager = newAspect.entityManager;
        if (!entityManager.isLoading) {
          entityManager.attachEntity(entityAspect.entity, EntityState.Added);
        }
      }
    }
  }
  // process related updates ( the inverse relationship) first so that collection dups check works properly.
  // update inverse relationship
  if (inverseProp) {
    ///
    if (inverseProp.isScalar) {
      // Example: bidirectional navProperty: 1->1: order -> internationalOrder
      // order.internationalOrder <- internationalOrder || null
      //    ==> (oldInternationalOrder.order = null)
      //    ==> internationalOrder.order = order
      if (oldValue != null) {
        // TODO: null -> NullEntity later
        oldValue.setProperty(inverseProp.name, null);
      }
      if (newValue != null) {
        newValue.setProperty(inverseProp.name, parent);
      }
    } else {
      // Example: bidirectional navProperty: 1->n: order -> orderDetails
      // orderDetail.order <- newOrder || null
      //    ==> (oldOrder).orderDetails.remove(orderDetail)
      //    ==> order.orderDetails.push(newOrder)
      if (oldValue != null) {
        let oldSiblings = oldValue.getProperty(inverseProp.name);
        let ix = oldSiblings.indexOf(parent);
        if (ix !== -1) {
          oldSiblings.splice(ix, 1);
        }
      }
      if (newValue != null) {
        let siblings = newValue.getProperty(inverseProp.name);
        // recursion check if already in the collection is performed by the relationArray
        siblings.push(parent);
      }
    }
  } else if (property.invForeignKeyNames && entityManager && !entityManager._inKeyFixup) {
    let invForeignKeyNames = property.invForeignKeyNames;
    if (newValue != null) {
      // Example: unidirectional navProperty: 1->1: order -> internationalOrder
      // order.InternationalOrder <- internationalOrder
      //    ==> internationalOrder.orderId = orderId
      //      and
      // Example: unidirectional navProperty: 1->n: order -> orderDetails
      // orderDetail.order <-xxx newOrder
      //    ==> CAN'T HAPPEN because if unidirectional because orderDetail will not have an order prop
      let pkValues = parent.entityAspect.getKey().values;
      invForeignKeyNames.forEach((fkName, i) => {
        newValue.setProperty(fkName, pkValues[i]);
      });
    } else {
      // Example: unidirectional navProperty: 1->1: order -> internationalOrder
      // order.internationalOrder <- null
      //    ==> (old internationalOrder).orderId = null
      //        and
      // Example: unidirectional navProperty: 1->n: order -> orderDetails
      // orderDetail.order <-xxx newOrder
      //    ==> CAN'T HAPPEN because if unidirectional because orderDetail will not have an order prop
      if (oldValue != null) {
        invForeignKeyNames.forEach(fkName => {
          let fkProp = oldValue.entityType.getProperty(fkName);
          if (!fkProp.isPartOfKey) {
            // don't update with null if fk is part of the key
            oldValue.setProperty(fkName, null);
          }
        });
      }
    }
  }
  rawAccessorFn(newValue);
  updateStateAndValidate(context);
  // update fk data property - this can only occur if this navProperty has
  // a corresponding fk on this entity.
  if (property.relatedDataProperties) {
    let entityState = entityAspect.entityState;
    // if either side of nav prop is detached don't clear fks. Note: oldValue in next line cannot be null so no check is needed.
    if (newValue == null && (entityState.isDetached() || oldValue.entityAspect.entityState.isDetached())) return;
    if (entityState.isDeleted()) return;
    // we may have inverse props indicating the related prop on the other entity, if it is not the other entity's PK
    let inverseKeyProps = property.invForeignKeyNames;
    if (!(inverseKeyProps && inverseKeyProps.length)) {
      // otherwise, this prop is related to the other entity's PK
      inverseKeyProps = property.entityType.keyProperties.map(k => k.name);
    }
    inverseKeyProps.forEach(function (keyProp, i) {
      let relatedDataProp = property.relatedDataProperties[i];
      // Do not trash related property if it is part of that entity's key
      if (newValue || !relatedDataProp.isPartOfKey) {
        let relatedValue = newValue ? newValue.getProperty(keyProp) : relatedDataProp.defaultValue;
        parent.setProperty(relatedDataProp.name, relatedValue);
      }
    });
  }
}
function postChangeEvents(context) {
  let entityAspect = context.entityAspect;
  let entityManager = entityAspect.entityManager;
  let entity = entityAspect.entity;
  let propChangedArgs = {
    entity: entity,
    parent: context.parent,
    property: context.property,
    propertyName: context.propertyName,
    oldValue: context.oldValue,
    newValue: context.newValue
  };
  if (entityManager) {
    // propertyChanged will be fired during loading but we only want to fire it once per entity, not once per property.
    // so propertyChanged is fired in the entityManager mergeEntity method if not fired here.
    if (!entityManager.isLoading && !entityManager.isRejectingChanges) {
      entityAspect.propertyChanged.publish(propChangedArgs);
      // don't fire entityChanged event if propertyChanged is suppressed.
      entityManager.entityChanged.publish({
        entityAction: EntityAction.PropertyChange,
        entity: entity,
        args: propChangedArgs
      });
    }
  } else {
    entityAspect.propertyChanged.publish(propChangedArgs);
  }
}
function updateStateAndValidate(context) {
  let entityAspect = context.entityAspect;
  let entityManager = entityAspect.entityManager;
  if (entityManager == null || entityManager.isLoading) return;
  let property = context.property;
  if (entityAspect.entityState.isUnchanged() && !property.isUnmapped) {
    entityAspect.setModified();
  }
  if (entityManager.validationOptions.validateOnPropertyChange) {
    // entityAspect.entity is NOT the same as parent in the code below. It's use is deliberate.
    entityAspect._validateProperty(context.newValue, {
      entity: entityAspect.entity,
      property: property,
      propertyName: context.propertyName,
      oldValue: context.oldValue
    });
  }
}

/**
An instance of the MetadataStore contains all of the metadata about a collection of [[EntityType]]'s.
MetadataStores may be shared across [[EntityManager]]'s.  If an EntityManager is created without an
explicit MetadataStore, the MetadataStore from the MetadataStore.defaultInstance property will be used.
@dynamic
**/
class MetadataStore {
  /**
  Constructs a new MetadataStore.
  
  >     let ms = new MetadataStore();
      The store can then be associated with an EntityManager
  >     let entityManager = new EntityManager( {
  >         serviceName: "breeze/NorthwindIBModel",
  >         metadataStore: ms
  >     });
      or for an existing EntityManager
  >    // Assume em1 is an existing EntityManager
  >    em1.setProperties( { metadataStore: ms });
  
  @param config - Configuration settings .
    - namingConvention - (default=NamingConvention.defaultInstance) NamingConvention to be used in mapping property names
  between client and server. Uses the NamingConvention.defaultInstance if not specified.
    - localQueryComparisonOptions - (default=LocalQueryComparisonOptions.defaultInstance) The LocalQueryComparisonOptions to be
  used when performing "local queries" in order to match the semantics of queries against a remote service.
    - serializerFn - A function that is used to mediate the serialization of instances of this type.
  **/
  constructor(config) {
    config = config || {};
    assertConfig(config).whereParam("namingConvention").isOptional().isInstanceOf(NamingConvention).withDefault(NamingConvention.defaultInstance).whereParam("localQueryComparisonOptions").isOptional().isInstanceOf(LocalQueryComparisonOptions).withDefault(LocalQueryComparisonOptions.defaultInstance).whereParam("serializerFn").isOptional().isFunction().applyAll(this);
    this.dataServices = []; // array of dataServices;
    this._resourceEntityTypeMap = {}; // key is resource name - value is qualified entityType name
    this._structuralTypeMap = {}; // key is qualified structuraltype name - value is structuralType. ( structural = entityType or complexType).
    this._shortNameMap = {}; // key is shortName, value is qualified name - does not need to be serialized.
    this._ctorRegistry = {}; // key is either short or qual type name - value is ctor;
    this._incompleteTypeMap = {}; // key is entityTypeName; value is array of nav props
    this._incompleteComplexTypeMap = {}; // key is complexTypeName; value is array of complexType props
    this._id = MetadataStore.__id++;
    this.metadataFetched = new BreezeEvent("metadataFetched", this);
  }
  // for debugging use the line below instead.
  //ctor.normalizeTypeName = function (rawTypeName) { return parseTypeName(rawTypeName).typeName; };
  /**
  General purpose property set method
  
  >     // assume em1 is an EntityManager containing a number of existing entities.
  >     em1.metadataStore.setProperties( {
  >         version: "6.1.3",
  >         serializerFn: function(prop, value) {
  >         return (prop.isUnmapped) ? undefined : value;
  >         }
  >     )};
  @param config -  An object containing the selected properties and values to set.
  **/
  setProperties(config) {
    assertConfig(config).whereParam("name").isString().isOptional().whereParam("serializerFn").isFunction().isOptional().applyAll(this);
  }
  /**
  Adds a DataService to this MetadataStore. If a DataService with the same serviceName is already
  in the MetadataStore an exception will be thrown.
  @param dataService - The [[DataService]] to add
  @param shouldOverwrite - (default=false) Permit overwrite of existing DataService rather than throw exception
  **/
  addDataService(dataService, shouldOverwrite) {
    assertParam(dataService, "dataService").isInstanceOf(DataService).check();
    assertParam(shouldOverwrite, "shouldOverwrite").isBoolean().isOptional().check();
    let ix = this._getDataServiceIndex(dataService.serviceName);
    if (ix >= 0) {
      if (!!shouldOverwrite) {
        this.dataServices[ix] = dataService;
      } else {
        throw new Error("A dataService with this name '" + dataService.serviceName + "' already exists in this MetadataStore");
      }
    } else {
      this.dataServices.push(dataService);
    }
  }
  /** @hidden @internal */
  _getDataServiceIndex(serviceName) {
    return core.arrayIndexOf(this.dataServices, function (ds) {
      return ds.serviceName === serviceName;
    });
  }
  /**
  Adds an EntityType to this MetadataStore.  No additional properties may be added to the EntityType after its has
  been added to the MetadataStore.
  @param structuralType - The EntityType or ComplexType to add
  **/
  addEntityType(stype) {
    let structuralType;
    if (stype instanceof EntityType || stype instanceof ComplexType) {
      structuralType = stype;
    } else {
      structuralType = stype.isComplexType ? new ComplexType(stype) : new EntityType(stype);
    }
    // if (!structuralType.isComplexType) { // same as below but isn't a 'type guard'
    if (structuralType instanceof EntityType) {
      if (structuralType.baseTypeName && !structuralType.baseEntityType) {
        let baseEntityType = this._getStructuralType(structuralType.baseTypeName, true);
        // safe cast because we know that baseEntityType must be an EntityType if the structuralType is an EntityType
        structuralType._updateFromBase(baseEntityType);
      }
      if (structuralType.keyProperties.length === 0 && !structuralType.isAbstract) {
        throw new Error("Unable to add " + structuralType.name + " to this MetadataStore.  An EntityType must have at least one property designated as a key property - See the 'DataProperty.isPartOfKey' property.");
      }
    }
    structuralType.metadataStore = this;
    // don't register anon types
    if (!structuralType.isAnonymous) {
      if (this._structuralTypeMap[structuralType.name]) {
        throw new Error("Type " + structuralType.name + " already exists in this MetadataStore.");
      }
      this._structuralTypeMap[structuralType.name] = structuralType;
      this._shortNameMap[structuralType.shortName] = structuralType.name;
    }
    structuralType.getProperties().forEach(p => {
      structuralType._updateNames(p);
      if (!p.isUnmapped) {
        structuralType._mappedPropertiesCount++;
      }
    });
    structuralType._updateCps();
    // 'isEntityType' is a type guard
    if (structuralType instanceof EntityType) {
      structuralType._updateNps();
      // give the type it's base's resource name if it doesn't have its own.
      let defResourceName = structuralType.defaultResourceName || structuralType.baseEntityType && structuralType.baseEntityType.defaultResourceName;
      if (defResourceName && !this.getEntityTypeNameForResourceName(defResourceName)) {
        this.setEntityTypeForResourceName(defResourceName, structuralType.name);
      }
      structuralType.defaultResourceName = defResourceName;
      // check if this structural type's name, short version or qualified version has a registered ctor.
      structuralType.getEntityCtor();
    }
  }
  /**
  Exports this MetadataStore to a serialized string appropriate for local storage.   This operation is also called
  internally when exporting an EntityManager.
  >      // assume ms is a previously created MetadataStore
  >      let metadataAsString = ms.exportMetadata();
  >      window.localStorage.setItem("metadata", metadataAsString);
  >      // and later, usually in a different session imported
  >      let metadataFromStorage = window.localStorage.getItem("metadata");
  >      let newMetadataStore = new MetadataStore();
  >      newMetadataStore.importMetadata(metadataFromStorage);
  @return A serialized version of this MetadataStore that may be stored locally and later restored.
  **/
  exportMetadata() {
    let result = JSON.stringify({
      "metadataVersion": MetadataStore.metadataVersion,
      "name": this.name,
      "namingConvention": this.namingConvention.name,
      "localQueryComparisonOptions": this.localQueryComparisonOptions.name,
      "dataServices": this.dataServices,
      "structuralTypes": core.objectMap(this._structuralTypeMap),
      "resourceEntityTypeMap": this._resourceEntityTypeMap
    }, null, config.stringifyPad);
    return result;
  }
  /**
  Imports a previously exported serialized MetadataStore into this MetadataStore.
    
  >      // assume ms is a previously created MetadataStore
  >      let metadataAsString = ms.exportMetadata();
  >      window.localStorage.setItem("metadata", metadataAsString);
  >      // and later, usually in a different session
  >      let metadataFromStorage = window.localStorage.getItem("metadata");
  >      let newMetadataStore = new MetadataStore();
  >      newMetadataStore.importMetadata(metadataFromStorage);
  @param exportedMetadata - A previously exported MetadataStore.
  @param allowMerge -  Allows custom metadata to be merged into existing metadata types.
  @return This MetadataStore.
  @chainable
  **/
  importMetadata(exportedMetadata, allowMerge = false) {
    assertParam(allowMerge, "allowMerge").isOptional().isBoolean().check();
    this._deferredTypes = {};
    // insure that we don't mutate incoming exportedMetadata ( if its an object)
    let metadataAsString = typeof exportedMetadata === "string" ? exportedMetadata : JSON.stringify(exportedMetadata);
    const metadataJson = JSON.parse(metadataAsString);
    if (metadataJson.schema) {
      return CsdlMetadataParser.parse(this, metadataJson.schema, metadataJson.altMetadata);
    }
    let json = metadataJson;
    if (json.metadataVersion && json.metadataVersion !== MetadataStore.metadataVersion) {
      let msg = core.formatString("Cannot import metadata with a different 'metadataVersion' (%1) than the current 'MetadataStore.metadataVersion' (%2) ", json.metadataVersion, MetadataStore.metadataVersion);
      throw new Error(msg);
    }
    let ncName = json.namingConvention;
    let lqcoName = json.localQueryComparisonOptions;
    if (this.isEmpty()) {
      this.namingConvention = config._fetchObject(NamingConvention, ncName) || this.namingConvention;
      this.localQueryComparisonOptions = config._fetchObject(LocalQueryComparisonOptions, lqcoName) || this.localQueryComparisonOptions;
    } else {
      if (ncName && this.namingConvention.name !== ncName) {
        throw new Error("Cannot import metadata with a different 'namingConvention' from the current MetadataStore");
      }
      if (lqcoName && this.localQueryComparisonOptions.name !== lqcoName) {
        throw new Error("Cannot import metadata with different 'localQueryComparisonOptions' from the current MetadataStore");
      }
    }
    //noinspection JSHint
    json.dataServices && json.dataServices.forEach(ds => {
      let realDs = DataService.fromJSON(ds);
      this.addDataService(realDs, true);
    });
    json.structuralTypes && json.structuralTypes.forEach(stype => {
      structuralTypeFromJson(this, stype, allowMerge);
    });
    core.extend(this._resourceEntityTypeMap, json.resourceEntityTypeMap);
    core.extend(this._incompleteTypeMap, json.incompleteTypeMap);
    return this;
  }
  /**
  Creates a new MetadataStore from a previously exported serialized MetadataStore
  >      // assume ms is a previously created MetadataStore
  >      let metadataAsString = ms.exportMetadata();
  >      window.localStorage.setItem("metadata", metadataAsString);
  >      // and later, usually in a different session
  >      let metadataFromStorage = window.localStorage.getItem("metadata");
  >      let newMetadataStore = MetadataStore.importMetadata(metadataFromStorage);
  @param exportedString - A previously exported MetadataStore.
  @return A new MetadataStore.
  **/
  static importMetadata(exportedString) {
    let ms = new MetadataStore();
    ms.importMetadata(exportedString);
    return ms;
  }
  /**
  Returns whether Metadata has been retrieved for a specified service name.
  >      // Assume em1 is an existing EntityManager.
  >      if (!em1.metadataStore.hasMetadataFor("breeze/NorthwindIBModel"))) {
  >          // do something interesting
  >      }
  @param serviceName - The service name.
  @return Whether metadata has already been retrieved for the specified service name.
  **/
  hasMetadataFor(serviceName) {
    return !!this.getDataService(serviceName);
  }
  /**
  Returns the DataService for a specified service name
  >      // Assume em1 is an existing EntityManager.
  >      let ds = em1.metadataStore.getDataService("breeze/NorthwindIBModel");
  >      let adapterName = ds.adapterName; // may be null
  @param serviceName - The service name.
  @return The DataService with the specified name.
  **/
  getDataService(serviceName) {
    assertParam(serviceName, "serviceName").isString().check();
    serviceName = DataService._normalizeServiceName(serviceName);
    return core.arrayFirst(this.dataServices, function (ds) {
      return ds.serviceName === serviceName;
    });
  }
  /**
  Fetches the metadata for a specified 'service'. This method is automatically called
  internally by an EntityManager before its first query against a new service. __Async__
      Usually you will not actually process the results of a fetchMetadata call directly, but will instead
  ask for the metadata from the EntityManager after the fetchMetadata call returns.
  >      let ms = new MetadataStore();
  >      // or more commonly
  >      // let ms = anEntityManager.metadataStore;
  >      ms.fetchMetadata("breeze/NorthwindIBModel").then(function(rawMetadata) {
  >            // do something with the metadata
  >      }).catch(function(exception) {
  >          // handle exception here
  >      });
  @param dataService -  Either a DataService or just the name of the DataService to fetch metadata for.
  @param callback - Function called on success.
  @param errorCallback - Function called on failure.
  @return Promise
  **/
  fetchMetadata(dataService, callback, errorCallback) {
    try {
      assertParam(dataService, "dataService").isString().or().isInstanceOf(DataService).check();
      assertParam(callback, "callback").isFunction().isOptional().check();
      assertParam(errorCallback, "errorCallback").isFunction().isOptional().check();
      if (typeof dataService === "string") {
        // use the dataService with a matching name or create a new one.
        dataService = this.getDataService(dataService) || new DataService({
          serviceName: dataService
        });
      }
      dataService = DataService.resolve([dataService]);
      if (this.hasMetadataFor(dataService.serviceName)) {
        throw new Error("Metadata for a specific serviceName may only be fetched once per MetadataStore. ServiceName: " + dataService.serviceName);
      }
      return dataService.adapterInstance.fetchMetadata(this, dataService).then(rawMetadata => {
        this.metadataFetched.publish({
          metadataStore: this,
          dataService: dataService,
          rawMetadata: rawMetadata
        });
        if (callback) callback(rawMetadata);
        return Promise.resolve(rawMetadata);
      }, function (error) {
        if (errorCallback) errorCallback(error);
        return Promise.reject(error);
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }
  // TODO: strongly type interceptor below.
  /**
  Used to register a constructor for an EntityType that is not known via standard Metadata discovery;
  i.e. an unmapped type.
  @param entityCtor - The constructor function for the 'unmapped' type.
  @param interceptor - An interceptor function
  **/
  trackUnmappedType(entityCtor, interceptor) {
    assertParam(entityCtor, "entityCtor").isFunction().check();
    assertParam(interceptor, "interceptor").isFunction().isOptional().check();
    // TODO: think about adding this to the MetadataStore.
    let entityType = new EntityType(this);
    entityType._setCtor(entityCtor, interceptor);
  }
  /**
  Provides a mechanism to register a 'custom' constructor to be used when creating new instances
  of the specified entity type.  If this call is not made, a default constructor is created for
  the entity as needed.
  This call may be made before or after the corresponding EntityType has been discovered via
  Metadata discovery.
  >      let Customer = function () {
  >              this.miscData = "asdf";
  >          };
  >      Customer.prototype.doFoo() {
  >              ...
  >          }
  >      // assume em1 is a preexisting EntityManager;
  >      em1.metadataStore.registerEntityTypeCtor("Customer", Customer);
  >      // any queries or EntityType.create calls from this point on will call the Customer constructor
  >      // registered above.
  @param structuralTypeName - The name of the EntityType or ComplexType.
  @param aCtor - The constructor for this EntityType or ComplexType; may be null if all you want to do is set the next parameter.
  @param initFn - A function or the name of a function on the entity that is to be executed immediately after the entity has been created
  and populated with any initial values. Called with 'initFn(entity)'
  @param noTrackingFn - A function that is executed immediately after a noTracking entity has been created and whose return
  value will be used in place of the noTracking entity.
  **/
  registerEntityTypeCtor(structuralTypeName, aCtor, initFn, noTrackingFn) {
    assertParam(structuralTypeName, "structuralTypeName").isString().check();
    assertParam(aCtor, "aCtor").isFunction().isOptional().check();
    assertParam(initFn, "initFn").isOptional().isFunction().or().isString().check();
    assertParam(noTrackingFn, "noTrackingFn").isOptional().isFunction().check();
    let qualifiedTypeName = getQualifiedTypeName(this, structuralTypeName, false);
    let typeName = qualifiedTypeName || structuralTypeName;
    if (aCtor) {
      if (aCtor._$typeName && aCtor._$typeName !== typeName) {
        // TODO: wrap this - console and especially console.warn does not exist in all browsers.
        console.warn("Registering a constructor for " + typeName + " that is already used for " + aCtor._$typeName + ".");
      }
      aCtor._$typeName = typeName;
    }
    this._ctorRegistry[typeName] = {
      ctor: aCtor,
      initFn: initFn,
      noTrackingFn: noTrackingFn
    };
    if (qualifiedTypeName) {
      let stype = this._structuralTypeMap[qualifiedTypeName];
      stype && stype.getCtor(true); // this will complete the registration if avail now.
    }
  }
  /**
  Returns whether this MetadataStore contains any metadata yet.
  >      // assume em1 is a preexisting EntityManager;
  >      if (em1.metadataStore.isEmpty()) {
  >          // do something interesting
  >      }
  **/
  isEmpty() {
    return core.isEmpty(this._structuralTypeMap);
  }
  /**
  Returns an [[EntityType]] or null given its name.
  >      // assume em1 is a preexisting EntityManager
  >      let odType = em1.metadataStore.getAsEntityType("OrderDetail");
      or to throw an error if the type is not found
  >      let badType = em1.metadataStore.getAsEntityType("Foo", false);
  >      // badType will not get set and an exception will be thrown.
  @param structuralTypeName - Either the fully qualified name or a short name may be used. If a short name is specified and multiple types share
  that same short name an exception will be thrown.
  @param okIfNotFound - (default=false) Whether to throw an error if the specified EntityType is not found.
  @return The EntityType. ComplexType or 'null' if not not found.
  **/
  getAsEntityType(typeName, okIfNotFound = false) {
    const st = this.getStructuralType(typeName, okIfNotFound);
    if (st instanceof EntityType) {
      return st;
    } else if (okIfNotFound) {
      return null;
    } else {
      let msg = core.formatString("Unable to locate an 'EntityType' by the name: '%1'. Be sure to execute a query or call fetchMetadata first.", typeName);
      throw new Error(msg);
    }
  }
  /**
  Returns an [[EntityType]] or null given its name.
  >      // assume em1 is a preexisting EntityManager
  >      let locType = em1.metadataStore.getAsComplexType("Location");
      or to throw an error if the type is not found
  >      let badType = em1.metadataStore.getAsComplexType("Foo", false);
  >      // badType will not get set and an exception will be thrown.
  @param structuralTypeName - Either the fully qualified name or a short name may be used. If a short name is specified and multiple types share
  that same short name an exception will be thrown.
  @param okIfNotFound - (default=false) Whether to throw an error if the specified EntityType is not found.
  @return The EntityType. ComplexType or 'null' if not not found.
  **/
  getAsComplexType(typeName, okIfNotFound = false) {
    const st = this.getStructuralType(typeName, okIfNotFound);
    if (st instanceof ComplexType) {
      return st;
    } else if (okIfNotFound) {
      return null;
    } else {
      let msg = core.formatString("Unable to locate an 'ComplexType' by the name: '%1'. Be sure to execute a query or call fetchMetadata first.", typeName);
      throw new Error(msg);
    }
  }
  /**
  Returns an [[EntityType]] or a [[ComplexType]] given its name.
  @deprecated Replaced by getStructuralType but ... it is probably more usefull to call either getAsEntityType or getAsComplexType instead
  @param typeName - Either the fully qualified name or a short name may be used. If a short name is specified and multiple types share
  that same short name an exception will be thrown.
  @param okIfNotFound - (default=false) Whether to throw an error if the specified EntityType is not found.
  @return The EntityType. ComplexType or 'null' if not not found.
  **/
  getEntityType(typeName, okIfNotFound = false) {
    return this.getStructuralType(typeName, okIfNotFound);
  }
  /**
  Returns an [[EntityType]] or a [[ComplexType]] given its name.
  >      // assume em1 is a preexisting EntityManager
  >      let odType = em1.metadataStore.getStructuralType("OrderDetail");
      or to throw an error if the type is not found
  >      let badType = em1.metadataStore.getStructuralType("Foo", false);
  >      // badType will not get set and an exception will be thrown.
  @deprecated Preferably use either getAsEntityType or getAsComplexType.  Get
  @param typeName - Either the fully qualified name or a short name may be used. If a short name is specified and multiple types share
  that same short name an exception will be thrown.
  @param okIfNotFound - (default=false) Whether to throw an error if the specified EntityType is not found.
  @return The EntityType. ComplexType or 'null' if not not found.
  **/
  getStructuralType(typeName, okIfNotFound = false) {
    assertParam(typeName, "typeName").isString().check();
    assertParam(okIfNotFound, "okIfNotFound").isBoolean().isOptional().check(false);
    return this._getStructuralType(typeName, okIfNotFound);
  }
  /** @hidden @internal */
  _getStructuralType(typeName, okIfNotFound = false) {
    let qualTypeName = getQualifiedTypeName(this, typeName, false);
    let type = this._structuralTypeMap[qualTypeName];
    if (!type) {
      if (okIfNotFound) return null;
      let msg = core.formatString("Unable to locate a 'Type' by the name: '%1'. Be sure to execute a query or call fetchMetadata first.", typeName);
      throw new Error(msg);
    }
    return type;
  }
  /**
  Returns an array containing all of the [[EntityType]]s or [[ComplexType]]s in this MetadataStore.
  >      // assume em1 is a preexisting EntityManager
  >      let allTypes = em1.metadataStore.getEntityTypes();
  **/
  getEntityTypes() {
    return getTypesFromMap(this._structuralTypeMap);
  }
  getIncompleteNavigationProperties() {
    return core.objectMap(this._incompleteTypeMap, function (key, value) {
      return value;
    });
  }
  /**
  Returns a fully qualified entityTypeName for a specified resource name.  The reverse of this operation
  can be obtained via the  [[EntityType.defaultResourceName]] property
  **/
  getEntityTypeNameForResourceName(resourceName) {
    assertParam(resourceName, "resourceName").isString().check();
    return this._resourceEntityTypeMap[resourceName];
  }
  /**
  Associates a resourceName with an entityType.
      This method is only needed in those cases where multiple resources return the same
  entityType.  In this case Metadata discovery will only determine a single resource name for
  each entityType.
  @param resourceName - The resource name
  @param entityTypeOrName - If passing a string either the fully qualified name or a short name may be used. If a short name is specified and multiple types share
  that same short name an exception will be thrown. If the entityType has not yet been discovered then a fully qualified name must be used.
  **/
  setEntityTypeForResourceName(resourceName, entityTypeOrName) {
    assertParam(resourceName, "resourceName").isString().check();
    assertParam(entityTypeOrName, "entityTypeOrName").isInstanceOf(EntityType).or().isString().check();
    let entityTypeName;
    if (entityTypeOrName instanceof EntityType) {
      entityTypeName = entityTypeOrName.name;
    } else {
      entityTypeName = getQualifiedTypeName(this, entityTypeOrName, true);
    }
    this._resourceEntityTypeMap[resourceName] = entityTypeName;
    let entityType = this._getStructuralType(entityTypeName, true);
    if (entityType && entityType instanceof EntityType && !entityType.defaultResourceName) {
      entityType.defaultResourceName = resourceName;
    }
  }
  /** __Dev Only__ - for use when creating a new MetadataParserAdapter  */
  static parseTypeName(entityTypeName) {
    // TODO: removed 
    // if (!entityTypeName) {
    //   return null;
    // }
    let typeParts = entityTypeName.split(":#");
    if (typeParts.length > 1) {
      return MetadataStore.makeTypeHash(typeParts[0], typeParts[1]);
    }
    if (core.stringStartsWith(entityTypeName, MetadataStore.ANONTYPE_PREFIX)) {
      let typeHash = MetadataStore.makeTypeHash(entityTypeName);
      typeHash.isAnonymous = true;
      return typeHash;
    }
    let entityTypeNameNoAssembly = entityTypeName.split(",")[0];
    typeParts = entityTypeNameNoAssembly.split(".");
    if (typeParts.length > 1) {
      let shortName = typeParts[typeParts.length - 1];
      let namespaceParts = typeParts.slice(0, typeParts.length - 1);
      let ns = namespaceParts.join(".");
      return MetadataStore.makeTypeHash(shortName, ns);
    } else {
      return MetadataStore.makeTypeHash(entityTypeName);
    }
  }
  /** __Dev Only__ - for use when creating a new MetadataParserAdapter  */
  static makeTypeHash(shortName, ns) {
    return {
      shortTypeName: shortName,
      namespace: ns,
      typeName: qualifyTypeName(shortName, ns)
    };
  }
  // protected methods
  /** @hidden @internal */
  _checkEntityType(entity) {
    if (entity.entityType) return;
    let typeName = entity.prototype._$typeName;
    if (!typeName) {
      throw new Error("This entity has not been registered. See the MetadataStore.registerEntityTypeCtor method");
    }
    // we know that it is an EntityType ( as opposed to a ComplexType)
    let entityType = this._getStructuralType(typeName);
    if (entityType) {
      entity.entityType = entityType;
    }
  }
}
/** @hidden @internal */
MetadataStore.__id = 0;
/** @hidden @internal */
MetadataStore.ANONTYPE_PREFIX = "_IB_";
/** The version of any MetadataStores created by this class */
MetadataStore.metadataVersion = '1.0.5';
// needs to be made avail to dataService.xxx files
MetadataStore.normalizeTypeName = core.memoize(function (rawTypeName) {
  return rawTypeName && MetadataStore.parseTypeName(rawTypeName).typeName;
});
MetadataStore.prototype._$typeName = "MetadataStore";
BreezeEvent.bubbleEvent(MetadataStore.prototype);
function getTypesFromMap(typeMap) {
  let types = [];
  for (let key in typeMap) {
    let value = typeMap[key];
    // skip 'shortName' entries
    if (key === value.name) {
      types.push(typeMap[key]);
    }
  }
  return types;
}
function structuralTypeFromJson(metadataStore, json, allowMerge) {
  let typeName = qualifyTypeName(json.shortName, json.namespace);
  let stype = metadataStore._getStructuralType(typeName, true);
  if (stype) {
    if (allowMerge) {
      return mergeStructuralType(stype, json);
    } else {
      // allow it but don't replace anything.
      return stype;
    }
  }
  let config = {
    shortName: json.shortName,
    namespace: json.namespace,
    isAbstract: json.isAbstract,
    autoGeneratedKeyType: AutoGeneratedKeyType.fromName(json.autoGeneratedKeyType),
    defaultResourceName: json.defaultResourceName,
    custom: json.custom
  };
  stype = json.isComplexType ? new ComplexType(config) : new EntityType(config);
  // baseType may not have been imported yet so we need to defer handling this type until later.
  if (json.baseTypeName && stype instanceof EntityType) {
    stype.baseTypeName = json.baseTypeName;
    let baseEntityType = metadataStore._getStructuralType(json.baseTypeName, true);
    if (baseEntityType) {
      completeStructuralTypeFromJson(metadataStore, json, stype);
    } else {
      core.getArray(metadataStore._deferredTypes, json.baseTypeName).push({
        json: json,
        stype: stype
      });
    }
  } else {
    completeStructuralTypeFromJson(metadataStore, json, stype);
  }
  // stype may or may not have been added to the metadataStore at this point.
  return stype;
}
function mergeStructuralType(stype, json) {
  if (json.custom) {
    stype.custom = json.custom;
  }
  mergeProps(stype, json.dataProperties);
  mergeProps(stype, json.navigationProperties);
  return stype;
}
function mergeProps(stype, jsonProps) {
  if (!jsonProps) return;
  jsonProps.forEach(jsonProp => {
    let propName = jsonProp.name;
    if (!propName) {
      if (jsonProp.nameOnServer) {
        propName = stype.metadataStore.namingConvention.serverPropertyNameToClient(jsonProp.nameOnServer, {});
      } else {
        // backslash-quote works around compiler bug
        const msg = "Unable to complete \'importMetadata\' - cannot locate a \'name\' or \'nameOnServer\' for one of the imported property nodes";
        throw new Error(msg);
      }
    }
    if (jsonProp.custom) {
      let prop = stype.getProperty(propName, true);
      prop.custom = jsonProp.custom;
    }
  });
}
function completeStructuralTypeFromJson(metadataStore, json, stype) {
  // validators from baseType work because validation walks thru base types
  // so no need to copy down.
  if (json.validators) {
    stype.validators = json.validators.map(Validator.fromJSON);
  }
  json.dataProperties.forEach(function (dp) {
    stype._addPropertyCore(DataProperty.fromJSON(dp));
  });
  let isEntityType = !json.isComplexType;
  if (isEntityType) {
    //noinspection JSHint
    json.navigationProperties && json.navigationProperties.forEach(function (np) {
      stype._addPropertyCore(NavigationProperty.fromJSON(np));
    });
  }
  metadataStore.addEntityType(stype);
  let deferredTypes = metadataStore._deferredTypes;
  let deferrals = deferredTypes[stype.name];
  if (deferrals) {
    deferrals.forEach(function (d) {
      completeStructuralTypeFromJson(metadataStore, d.json, d.stype);
    });
    delete deferredTypes[stype.name];
  }
}
function getQualifiedTypeName(metadataStore, structTypeName, throwIfNotFound) {
  if (isQualifiedTypeName(structTypeName)) return structTypeName;
  let result = metadataStore._shortNameMap[structTypeName];
  if (!result && throwIfNotFound) {
    throw new Error("Unable to locate 'entityTypeName' of: " + structTypeName);
  }
  return result;
}
/** Container for all of the metadata about a specific type of Entity.
**/
class EntityType {
  /** EntityType constructor
  >      let entityType = new EntityType( {
  >          shortName: "person",
  >          namespace: "myAppNamespace"
  >      });
  @param config - Configuration settings or a MetadataStore.  If this parameter is just a MetadataStore
  then what will be created is an 'anonymous' type that will never be communicated to or from the server. It is purely for
  client side use and will be given an automatically generated name. Normally, however, you will use a configuration object.
  **/
  constructor(config) {
    /** Always false for an EntityType. **/
    this.isComplexType = false;
    /**
    @deprecated Use [[getCtor]] instead.
    */
    this.getEntityCtor = this.getCtor;
    if (arguments.length > 1) {
      throw new Error("The EntityType ctor has a single argument that is either a 'MetadataStore' or a configuration object.");
    }
    // let etConfig =  <EntityTypeConfig> <any> undefined;
    let etConfig = undefined;
    if (config._$typeName === "MetadataStore") {
      this.metadataStore = config;
      this.shortName = "Anon_" + ++EntityType.__nextAnonIx;
      this.namespace = "";
      this.isAnonymous = true;
      // etConfig = undefined;
    } else {
      etConfig = config;
      assertConfig(config).whereParam("shortName").isNonEmptyString().whereParam("namespace").isString().isOptional().withDefault("").whereParam("baseTypeName").isString().isOptional().whereParam("isAbstract").isBoolean().isOptional().withDefault(false).whereParam("autoGeneratedKeyType").isEnumOf(AutoGeneratedKeyType).isOptional().withDefault(AutoGeneratedKeyType.None).whereParam("defaultResourceName").isNonEmptyString().isOptional().withDefault(null).whereParam("dataProperties").isOptional().whereParam("navigationProperties").isOptional().whereParam("serializerFn").isOptional().isFunction().whereParam("custom").isOptional().applyAll(this);
    }
    this.name = qualifyTypeName(this.shortName, this.namespace);
    // the defaultResourceName may also be set up either via metadata lookup or first query or via the 'setProperties' method
    this.dataProperties = [];
    this.navigationProperties = [];
    this.complexProperties = [];
    this.keyProperties = [];
    this.foreignKeyProperties = [];
    this.inverseForeignKeyProperties = [];
    this.concurrencyProperties = [];
    this.unmappedProperties = []; // will be updated later.
    this.validators = [];
    this.warnings = [];
    this._mappedPropertiesCount = 0;
    this.subtypes = [];
    // now process any data/nav props
    if (etConfig && etConfig.dataProperties) {
      addProperties(this, etConfig.dataProperties, DataProperty);
    }
    if (etConfig && etConfig.navigationProperties) {
      addProperties(this, etConfig.navigationProperties, NavigationProperty);
    }
  }
  /**
  General purpose property set method
  >      // assume em1 is an EntityManager containing a number of existing entities.
  >      let custType = em1.metadataStore.getEntityType("Customer");
  >      custType.setProperties( {
  >          autoGeneratedKeyType: AutoGeneratedKeyType.Identity;
  >          defaultResourceName: "CustomersAndIncludedOrders"
  >      )};
  @param config - a configuration object
  **/
  setProperties(config) {
    assertConfig(config).whereParam("autoGeneratedKeyType").isEnumOf(AutoGeneratedKeyType).isOptional().whereParam("defaultResourceName").isString().isOptional().whereParam("serializerFn").isFunction().isOptional().whereParam("custom").isOptional().applyAll(this);
    if (config.defaultResourceName) {
      this.defaultResourceName = config.defaultResourceName;
    }
  }
  /**
  Returns whether this type is a subtype of a specified type.
  **/
  isSubtypeOf(entityType) {
    assertParam(entityType, "entityType").isInstanceOf(EntityType).check();
    let baseType = this;
    do {
      if (baseType === entityType) return true;
      baseType = baseType.baseEntityType;
    } while (baseType);
    return false;
  }
  /**
  Returns an array containing this type and any/all subtypes of this type down thru the hierarchy.
  **/
  getSelfAndSubtypes() {
    let result = [this];
    this.subtypes.forEach(function (st) {
      let subtypes = st.getSelfAndSubtypes();
      result.push.apply(result, subtypes);
    });
    return result;
  }
  getAllValidators() {
    let result = this.validators.slice(0);
    let bt = this.baseEntityType;
    while (bt) {
      result.push.apply(result, bt.validators);
      bt = bt.baseEntityType;
    }
    return result;
  }
  /**
  Adds a  [[DataProperty]] or a [[NavigationProperty]] to this EntityType.
  >      // assume myEntityType is a newly constructed EntityType.
  >      myEntityType.addProperty(dataProperty1);
  >      myEntityType.addProperty(dataProperty2);
  >      myEntityType.addProperty(navigationProperty1);
  **/
  addProperty(property) {
    assertParam(property, "property").isInstanceOf(DataProperty).or().isInstanceOf(NavigationProperty).check();
    // true is 2nd arg to force resolve of any navigation properties.
    let newprop = this._addPropertyCore(property, true);
    if (this.subtypes && this.subtypes.length) {
      let stype = this;
      stype.getSelfAndSubtypes().forEach(function (st) {
        if (st !== stype) {
          if (property.isNavigationProperty) {
            st._addPropertyCore(new NavigationProperty(property), true);
          } else {
            st._addPropertyCore(new DataProperty(property), true);
          }
        }
      });
    }
    return newprop;
  }
  /** @hidden @internal */
  _updateFromBase(baseEntityType) {
    this.baseEntityType = baseEntityType;
    if (this.autoGeneratedKeyType === AutoGeneratedKeyType.None) {
      this.autoGeneratedKeyType = baseEntityType.autoGeneratedKeyType;
    }
    baseEntityType.dataProperties.forEach(dp => {
      let newDp = new DataProperty(dp);
      // don't need to copy validators becaue we will walk the hierarchy to find them
      newDp.validators = [];
      newDp.baseProperty = dp;
      this._addPropertyCore(newDp);
    }, this);
    baseEntityType.navigationProperties.forEach(np => {
      let newNp = new NavigationProperty(np);
      // don't need to copy validators becaue we will walk the hierarchy to find them
      newNp.validators = [];
      newNp.baseProperty = np;
      this._addPropertyCore(newNp);
    }, this);
    baseEntityType.subtypes.push(this);
  }
  /** @hidden @internal */
  _addPropertyCore(property, shouldResolve = false) {
    if (this.isFrozen) {
      throw new Error("The '" + this.name + "' EntityType/ComplexType has been frozen. You can only add properties to an EntityType/ComplexType before any instances of that type have been created and attached to an entityManager.");
    }
    let parentType = property.parentType;
    if (parentType) {
      if (parentType !== this) {
        throw new Error("This property: " + property.name + " has already been added to " + property.parentType.name);
      } else {
        // adding the same property more than once to the same entityType is just ignored.
        return;
      }
    }
    property.parentType = this;
    let ms = this.metadataStore;
    // if (property.isDataProperty) { // modified because doesn't act as a type guard 
    if (property instanceof DataProperty) {
      this._addDataProperty(property);
    } else {
      this._addNavigationProperty(property);
      // metadataStore can be undefined if this entityType has not yet been added to a MetadataStore.
      if (shouldResolve && ms) {
        tryResolveNp(property, ms);
      }
    }
    // unmapped properties can be added AFTER entityType has already resolved all property names.
    if (ms && !(property.name && property.nameOnServer)) {
      updateClientServerNames(ms.namingConvention, property, "name");
    }
    // props can be added after entity prototype has already been wrapped.
    if (ms && this._extra) {
      if (this._extra.alreadyWrappedProps) {
        let proto = this._ctor.prototype;
        config.interfaceRegistry.modelLibrary.getDefaultInstance().initializeEntityPrototype(proto);
      }
    }
  }
  /**
  Create a new entity of this type.
  >      // assume em1 is an EntityManager containing a number of existing entities.
  >      let custType = em1.metadataStore.getAsEntityType("Customer");
  >      let cust1 = custType.createEntity();
  >      em1.addEntity(cust1);
  @param initialValues- Configuration object of the properties to set immediately after creation.
  @return The new entity.
  **/
  createEntity(initialValues) {
    // ignore the _$eref once the entity is attached to an entityManager.
    if (initialValues && initialValues._$eref && !initialValues._$eref.entityAspect.entityManager) return initialValues._$eref;
    let instance = this._createInstanceCore();
    if (initialValues) {
      // only assign an _eref if the object is fully "keyed"
      if (this.keyProperties.every(function (kp) {
        return initialValues[kp.name] != null;
      })) {
        initialValues._$eref = instance;
      }
      this._updateTargetFromRaw(instance, initialValues, getRawValueFromConfig);
      this.navigationProperties.forEach(function (np) {
        let relatedEntity;
        let val = initialValues[np.name];
        if (val != undefined) {
          let navEntityType = np.entityType;
          if (np.isScalar) {
            relatedEntity = val.entityAspect ? val : navEntityType.createEntity(val);
            instance.setProperty(np.name, relatedEntity);
          } else {
            let relatedEntities = instance.getProperty(np.name);
            val.forEach(v => {
              relatedEntity = v.entityAspect ? v : navEntityType.createEntity(v);
              relatedEntities.push(relatedEntity);
            });
          }
        }
      });
    }
    this._initializeInstance(instance);
    return instance;
  }
  /** @hidden @internal */
  _createInstanceCore() {
    let aCtor = this.getCtor();
    let instance = new aCtor();
    new EntityAspect(instance);
    return instance;
  }
  /** @hidden @internal */
  _initializeInstance(instance) {
    if (this.baseEntityType) {
      this.baseEntityType._initializeInstance(instance);
    }
    let initFn = this.initFn;
    if (initFn) {
      let fn = typeof initFn === "string" ? instance[initFn] : initFn;
      fn(instance);
    }
    this.complexProperties && this.complexProperties.forEach(function (cp) {
      let complexType = cp.dataType;
      let ctInstance = instance.getProperty(cp.name);
      if (Array.isArray(ctInstance)) {
        ctInstance.forEach(ctInst => {
          complexType._initializeInstance(ctInst);
        });
      } else {
        complexType._initializeInstance(ctInstance);
      }
    });
    // not needed for complexObjects
    if (instance.entityAspect) {
      instance.entityAspect._initialized = true;
    }
  }
  /**
  Returns the constructor for this EntityType.
  @param forceRefresh - Whether to ignore any cached version of this constructor. (default == false)
  @return The constructor for this EntityType.
  **/
  getCtor(forceRefresh = false) {
    if (this._ctor && !forceRefresh) return this._ctor;
    let ctorRegistry = this.metadataStore._ctorRegistry;
    let r = ctorRegistry[this.name] || ctorRegistry[this.shortName] || {};
    let aCtor = r.ctor || this._ctor;
    let ctorType = aCtor && aCtor.prototype && (aCtor.prototype.entityType || aCtor.prototype.complexType);
    if (ctorType && ctorType.metadataStore !== this.metadataStore) {
      // We can't risk a mismatch between the ctor and the type info in a specific metadatastore
      // because modelLibraries rely on type info to intercept ctor properties
      throw new Error("Cannot register the same constructor for " + this.name + " in different metadata stores.  Please define a separate constructor for each metadata store.");
    }
    if (r.ctor && forceRefresh) {
      this._extra = undefined;
    }
    if (!aCtor) {
      let createCtor = config.interfaceRegistry.modelLibrary.getDefaultInstance().createCtor;
      aCtor = createCtor ? createCtor(this) : createEmptyCtor(this);
    }
    this.initFn = r.initFn;
    this.noTrackingFn = r.noTrackingFn;
    aCtor.prototype._$typeName = this.name;
    this._setCtor(aCtor);
    return aCtor;
  }
  /** @hidden @internal */
  // May make public later.
  _setCtor(aCtor, interceptor) {
    let instanceProto = aCtor.prototype;
    // place for extra breeze related data
    this._extra = this._extra || {};
    let instance = new aCtor();
    calcUnmappedProperties(this, instance);
    if (this._$typeName === "EntityType") {
      // insure that all of the properties are on the 'template' instance before watching the class.
      instanceProto.entityType = this;
    } else {
      instanceProto.complexType = this;
    }
    // defaultPropertyInterceptor is a 'global' (but internal to breeze) function;
    instanceProto._$interceptor = interceptor || defaultPropertyInterceptor;
    config.interfaceRegistry.modelLibrary.getDefaultInstance().initializeEntityPrototype(instanceProto);
    this._ctor = aCtor;
  }
  /**
  Adds either an entity or property level validator to this EntityType.
  >      // assume em1 is an EntityManager containing a number of existing entities.
  >      let custType = em1.metadataStore.getEntityType("Customer");
  >      let countryProp = custType.getProperty("Country");
  >      let valFn = function (v) {
  >              if (v == null) return true;
  >              return (core.stringStartsWith(v, "US"));
  >          };
  >      let countryValidator = new Validator("countryIsUS", valFn,
  >      { displayName: "Country", messageTemplate: "'%displayName%' must start with 'US'" });
  >      custType.addValidator(countryValidator, countryProp);
      This is the same as adding an entity level validator via the 'validators' property of DataProperty or NavigationProperty
  >      countryProp.validators.push(countryValidator);
      Entity level validators can also be added by omitting the 'property' parameter.
  >      custType.addValidator(someEntityLevelValidator);
      or
  >      custType.validators.push(someEntityLevelValidator);
  @param validator - Validator to add.
  @param property - Property to add this validator to.  If omitted, the validator is assumed to be an
  entity level validator and is added to the EntityType's 'validators'.
  **/
  addValidator(validator, property) {
    assertParam(validator, "validator").isInstanceOf(Validator).check();
    assertParam(property, "property").isOptional().isString().or().isEntityProperty().check();
    if (property != null) {
      let prop = typeof property === 'string' ? this.getProperty(property, true) : property;
      prop.validators.push(validator);
    } else {
      this.validators.push(validator);
    }
  }
  /**
  Returns all of the properties ( dataProperties and navigationProperties) for this EntityType.
  >      // assume em1 is an EntityManager containing a number of existing entities.
  >      let custType = em1.metadataStore.getEntityType("Customer");
  >      let arrayOfProps = custType.getProperties();
  @return An array of Data and Navigation properties.
  **/
  getProperties() {
    return this.dataProperties.concat(this.navigationProperties);
  }
  /**
  Returns all of the property names ( for both dataProperties and navigationProperties) for this EntityType.
  >      // assume em1 is an EntityManager containing a number of existing entities.
  >      let custType = em1.metadataStore.getEntityType("Customer");
  >      let arrayOfPropNames = custType.getPropertyNames();
  **/
  getPropertyNames() {
    return this.getProperties().map(core.pluck('name'));
  }
  /**
  Returns a data property with the specified name or null.
  >      // assume em1 is an EntityManager containing a number of existing entities.
  >      let custType = em1.metadataStore.getEntityType("Customer");
  >      let customerNameDataProp = custType.getDataProperty("CustomerName");
  @return A DataProperty or null if not found.
  **/
  getDataProperty(propertyName) {
    return core.arrayFirst(this.dataProperties, core.propEq('name', propertyName));
  }
  /**
  Returns a navigation property with the specified name or null.
  >      // assume em1 is an EntityManager containing a number of existing entities.
  >      let custType = em1.metadataStore.getEntityType("Customer");
  >      let customerOrdersNavProp = custType.getDataProperty("Orders");
  @return A NavigationProperty or null if not found.
  **/
  getNavigationProperty(propertyName) {
    return core.arrayFirst(this.navigationProperties, core.propEq('name', propertyName));
  }
  /**
  Returns either a DataProperty or a NavigationProperty with the specified name or null.
  
  This method also accepts a '.' delimited property path and will return the 'property' at the
  end of the path.
  >      let custType = em1.metadataStore.getEntityType("Customer");
  >      let companyNameProp = custType.getProperty("CompanyName");
      This method can also walk a property path to return a property
  >      let orderDetailType = em1.metadataStore.getEntityType("OrderDetail");
  >      let companyNameProp2 = orderDetailType.getProperty("Order.Customer.CompanyName");
  >      // companyNameProp === companyNameProp2
  @param [throwIfNotFound=false] {Boolean} Whether to throw an exception if not found.
  @return A DataProperty or NavigationProperty or null if not found.
  **/
  getProperty(propertyPath, throwIfNotFound = false) {
    let props = this.getPropertiesOnPath(propertyPath, false, throwIfNotFound);
    return props && props.length > 0 ? props[props.length - 1] : null;
  }
  /** @hidden @internal */
  // TODO: have this return empty array instead of null and fix consumers.
  // TODO: think about renaming with '_' prefix.
  getPropertiesOnPath(propertyPath, useServerName, throwIfNotFound = false) {
    let propertyNames = Array.isArray(propertyPath) ? propertyPath : propertyPath.trim().split('.');
    let ok = true;
    let key = useServerName === true ? "nameOnServer" : useServerName === false ? "name" : null;
    let parentType = this;
    const getProps = propName => {
      const fn = key === null ? core.propsEq("name", "nameOnServer", propName) : core.propEq(key, propName);
      let prop = core.arrayFirst(parentType.getProperties(), fn);
      if (prop) {
        parentType = prop instanceof NavigationProperty ? prop.entityType : prop.dataType;
        // parentType = prop.isNavigationProperty ? prop.entityType : prop.dataType;
      } else if (throwIfNotFound) {
        throw new Error("unable to locate property: " + propName + " on entityType: " + parentType.name);
      } else {
        ok = false;
      }
      return prop;
    };
    let props = propertyNames.map(getProps);
    return ok ? props : null;
  }
  /** For use in pluggable adapters. */
  // TODO: document use
  clientPropertyPathToServer(propertyPath, delimiter = '.') {
    let propNames;
    if (this.isAnonymous) {
      let fn = this.metadataStore.namingConvention.clientPropertyNameToServer;
      propNames = propertyPath.split(".").map(function (propName) {
        return fn(propName);
      });
    } else {
      let props = this.getPropertiesOnPath(propertyPath, false, true);
      propNames = props.map(prop => prop.nameOnServer);
    }
    return propNames.join(delimiter);
  }
  /** For use in pluggable adapters. */
  // TODO: document use
  getEntityKeyFromRawEntity(rawEntity, rawValueFn) {
    let keyValues = this.keyProperties.map(dp => {
      let val = rawValueFn(rawEntity, dp);
      return DataType.parseRawValue(val, dp.dataType);
    });
    return new EntityKey(this, keyValues);
  }
  /** @hidden @internal */
  _updateTargetFromRaw(target, raw, rawValueFn) {
    // called recursively for complex properties
    this.dataProperties.forEach(dp => {
      if (!dp.isSettable) return;
      let rawVal = rawValueFn(raw, dp);
      if (rawVal === undefined) return;
      let dataType = dp.dataType; // this will be a complexType when dp is a complexProperty
      let oldVal;
      if (dp.isComplexProperty) {
        let complexType = dp.dataType;
        if (rawVal === null) return; // rawVal may be null in nosql dbs where it was never defined for the given row.
        oldVal = target.getProperty(dp.name);
        if (dp.isScalar) {
          complexType._updateTargetFromRaw(oldVal, rawVal, rawValueFn);
        } else {
          if (Array.isArray(rawVal)) {
            let newVal = rawVal.map(function (rawCo) {
              let newCo = complexType._createInstanceCore(target, dp);
              complexType._updateTargetFromRaw(newCo, rawCo, rawValueFn);
              complexType._initializeInstance(newCo);
              return newCo;
            });
            if (!core.arrayEquals(oldVal, newVal, coEquals)) {
              // clear the old array and push new objects into it.
              oldVal.length = 0;
              newVal.forEach(function (nv) {
                oldVal.push(nv);
              });
            }
          } else {
            oldVal.length = 0;
          }
        }
      } else {
        if (dp.isScalar) {
          let newVal = DataType.parseRawValue(rawVal, dataType);
          target.setProperty(dp.name, newVal);
        } else {
          oldVal = target.getProperty(dp.name);
          if (Array.isArray(rawVal)) {
            // need to compare values
            let newVal = rawVal.map(rv => {
              return DataType.parseRawValue(rv, dataType);
            });
            if (!core.arrayEquals(oldVal, newVal)) {
              // clear the old array and push new objects into it.
              oldVal.length = 0;
              newVal.forEach(function (nv) {
                oldVal.push(nv);
              });
            }
          } else {
            oldVal.length = 0;
          }
        }
      }
    });
    // if merging from an import then raw will have an entityAspect or a complexAspect
    let rawAspect = raw.entityAspect || raw.complexAspect;
    if (rawAspect) {
      let targetAspect = EntityAspect.isEntity(target) ? target.entityAspect : target.complexAspect;
      if (rawAspect.originalValuesMap) {
        targetAspect.originalValues = rawAspect.originalValuesMap;
      }
      if (rawAspect.extraMetadata) {
        targetAspect.extraMetadata = rawAspect.extraMetadata;
      }
    }
  }
  /**
  Returns a string representation of this EntityType.
  **/
  toString() {
    return this.name;
  }
  toJSON() {
    return core.toJson(this, {
      shortName: null,
      namespace: null,
      baseTypeName: null,
      isAbstract: false,
      autoGeneratedKeyType: null,
      defaultResourceName: null,
      dataProperties: localPropsOnly,
      navigationProperties: localPropsOnly,
      validators: null,
      custom: null
    });
  }
  /** @hidden @internal */
  _updateNames(property) {
    let nc = this.metadataStore.namingConvention;
    updateClientServerNames(nc, property, "name");
    if (property.isNavigationProperty) {
      updateClientServerNames(nc, property, "foreignKeyNames");
      updateClientServerNames(nc, property, "invForeignKeyNames");
      // these will get set later via _updateNps
      // this.inverse
      // this.entityType
      // this.relatedDataProperties
      //    dataProperty.relatedNavigationProperty
      //    dataProperty.inverseNavigationProperty
    }
  }
  /** @hidden @internal */
  _checkNavProperty(navigationProperty) {
    // if (navigationProperty.isNavigationProperty) {
    if (navigationProperty instanceof NavigationProperty) {
      if (navigationProperty.parentType !== this) {
        throw new Error(core.formatString("The navigationProperty '%1' is not a property of entity type '%2'", navigationProperty.name, this.name));
      }
      return navigationProperty;
    }
    if (typeof navigationProperty === 'string') {
      let np = this.getProperty(navigationProperty);
      // if (np && np.isNavigationProperty) return np;
      if (np && np instanceof NavigationProperty) return np;
    }
    throw new Error("The 'navigationProperty' parameter must either be a NavigationProperty or the name of a NavigationProperty");
  }
  /** @hidden @internal */
  _addDataProperty(dp) {
    this.dataProperties.push(dp);
    if (dp.isPartOfKey) {
      this.keyProperties.push(dp);
    }
    if (dp.isComplexProperty) {
      this.complexProperties.push(dp);
    }
    if (dp.concurrencyMode && dp.concurrencyMode !== "None") {
      this.concurrencyProperties.push(dp);
    }
    if (dp.isUnmapped) {
      this.unmappedProperties.push(dp);
    }
  }
  /** @hidden @internal */
  _addNavigationProperty(np) {
    this.navigationProperties.push(np);
    if (!isQualifiedTypeName(np.entityTypeName)) {
      np.entityTypeName = qualifyTypeName(np.entityTypeName, this.namespace);
    }
  }
  /** @hidden @internal */
  _updateCps() {
    let metadataStore = this.metadataStore;
    let incompleteTypeMap = metadataStore._incompleteComplexTypeMap;
    this.complexProperties.forEach(function (cp) {
      if (cp.complexType) return;
      if (!resolveCp(cp, metadataStore)) {
        core.getArray(incompleteTypeMap, cp.complexTypeName).push(cp);
      }
    });
    if (this.isComplexType) {
      (incompleteTypeMap[this.name] || []).forEach(function (cp) {
        resolveCp(cp, metadataStore);
      });
      delete incompleteTypeMap[this.name];
    }
  }
  /** @hidden @internal */
  _updateNps() {
    let metadataStore = this.metadataStore;
    // resolve all navProps for this entityType
    this.navigationProperties.forEach(function (np) {
      tryResolveNp(np, metadataStore);
    });
    let incompleteTypeMap = metadataStore._incompleteTypeMap;
    // next resolve all navProp that point to this entityType.
    (incompleteTypeMap[this.name] || []).forEach(function (np) {
      tryResolveNp(np, metadataStore);
    });
    // every navProp that pointed to this type should now be resolved
    delete incompleteTypeMap[this.name];
  }
}
/** @hidden @internal */
EntityType.__nextAnonIx = 0;
/** @hidden @internal */
EntityType.qualifyTypeName = qualifyTypeName;
EntityType.prototype._$typeName = "EntityType";
function getRawValueFromConfig(rawEntity, dp) {
  // 'true' fork can happen if an initializer contains an actaul instance of an already created complex object.
  return rawEntity.entityAspect || rawEntity.complexAspect ? rawEntity.getProperty(dp.name) : rawEntity[dp.name];
}
function updateClientServerNames(nc, parent, clientPropName) {
  let serverPropName = clientPropName + "OnServer";
  let clientName = parent[clientPropName];
  if (clientName && clientName.length) {
    // if (parent.isUnmapped) return;
    let serverNames = core.toArray(clientName).map(function (cName) {
      let sName = nc.clientPropertyNameToServer(cName, parent);
      let testName = nc.serverPropertyNameToClient(sName, parent);
      if (cName !== testName) {
        throw new Error("NamingConvention for this client property name does not roundtrip properly:" + cName + "-->" + testName);
      }
      return sName;
    });
    parent[serverPropName] = Array.isArray(clientName) ? serverNames : serverNames[0];
  } else {
    let serverName = parent[serverPropName];
    if (!serverName || serverName.length === 0) return;
    let clientNames = core.toArray(serverName).map(function (sName) {
      let cName = nc.serverPropertyNameToClient(sName, parent);
      let testName = nc.clientPropertyNameToServer(cName, parent);
      if (sName !== testName) {
        throw new Error("NamingConvention for this server property name does not roundtrip properly:" + sName + "-->" + testName);
      }
      return cName;
    });
    parent[clientPropName] = Array.isArray(serverName) ? clientNames : clientNames[0];
  }
}
function createEmptyCtor(type) {
  if (config.noEval) {
    let Entity = function () {};
    return Entity;
  } else {
    let name = type.name.replace(/\W/g, '_');
    return Function('return function ' + name + '(){}')();
  }
}
function coEquals(co1, co2) {
  let complexType = co1.complexAspect.parentProperty.dataType;
  let dataProps = complexType.dataProperties;
  let areEqual = dataProps.every(function (dp) {
    if (!dp.isSettable) return true;
    let v1 = co1.getProperty(dp.name);
    let v2 = co2.getProperty(dp.name);
    if (dp.isComplexProperty && dp.isScalar) {
      return coEquals(v1, v2);
    } else if (dp.isComplexProperty && !dp.isScalar) {
      return core.arrayEquals(v1, v2, coEquals);
    } else {
      let dataType = dp.dataType; // this will be a complexType when dp is a complexProperty
      return v1 === v2 || dataType && dataType.normalize && v1 && v2 && dataType.normalize(v1) === dataType.normalize(v2);
    }
  });
  return areEqual;
}
function localPropsOnly(props) {
  return props.filter(function (prop) {
    return prop.baseProperty == null;
  });
}
function resolveCp(cp, metadataStore) {
  let complexType = metadataStore._getStructuralType(cp.complexTypeName, true);
  if (!complexType) return false;
  if (!(complexType instanceof ComplexType)) {
    throw new Error("Unable to resolve ComplexType with the name: " + cp.complexTypeName + " for the property: " + cp.name);
  }
  cp.dataType = complexType;
  cp.defaultValue = null;
  return true;
}
function tryResolveNp(np, metadataStore) {
  if (np.entityType) return true;
  let entityType = metadataStore._getStructuralType(np.entityTypeName, true);
  if (entityType) {
    np.entityType = entityType;
    np._resolveNp();
    // don't bother removing - _updateNps will do it later.
    // __arrayRemoveItem(incompleteNps, np, false);
  } else {
    let incompleteNps = core.getArray(metadataStore._incompleteTypeMap, np.entityTypeName);
    core.arrayAddItemUnique(incompleteNps, np);
  }
  return !!entityType;
}
function calcUnmappedProperties(stype, instance) {
  let metadataPropNames = stype.getPropertyNames();
  let modelLib = config.interfaceRegistry.modelLibrary.getDefaultInstance();
  let trackablePropNames = modelLib.getTrackablePropertyNames(instance);
  trackablePropNames.forEach(function (pn) {
    if (metadataPropNames.indexOf(pn) === -1) {
      let val = instance[pn];
      try {
        if (typeof val === "function") val = val();
      } catch (e) {}
      let dt = DataType.fromValue(val);
      let newProp = new DataProperty({
        name: pn,
        dataType: dt,
        isNullable: true,
        isUnmapped: true
      });
      newProp.isSettable = core.isSettable(instance, pn);
      if (stype instanceof EntityType && stype.subtypes != null && stype.subtypes.length) {
        stype.getSelfAndSubtypes().forEach(st => {
          st._addPropertyCore(new DataProperty(newProp));
        });
      } else {
        stype._addPropertyCore(newProp);
      }
    }
  });
}
/**  Container for all of the metadata about a specific type of Complex object.
>     let complexType = new ComplexType( {
>         shortName: "address",
>         namespace: "myAppNamespace"
>     });
@param config - Configuration settings
**/
class ComplexType {
  constructor(config) {
    /** For polymorphic purpose only - always true here */
    this.isComplexType = true;
    // copy entityType methods onto complexType
    /** See [[EntityType.getCtor]] */
    this.getCtor = EntityType.prototype.getCtor;
    // note the name change.
    this.createInstance = EntityType.prototype.createEntity;
    /** See [EntityType.addValidator] */
    this.addValidator = EntityType.prototype.addValidator;
    this.getProperty = EntityType.prototype.getProperty;
    this.getPropertiesOnPath = EntityType.prototype.getPropertiesOnPath;
    this.getPropertyNames = EntityType.prototype.getPropertyNames;
    /** @hidden @internal */
    this._addPropertyCore = EntityType.prototype._addPropertyCore;
    /** @hidden @internal */
    this._addDataProperty = EntityType.prototype._addDataProperty;
    /** @hidden @internal */
    this._updateNames = EntityType.prototype._updateNames;
    /** @hidden @internal */
    this._updateCps = EntityType.prototype._updateCps;
    /** @hidden @internal */
    this._initializeInstance = EntityType.prototype._initializeInstance;
    /** @hidden @internal */
    this._updateTargetFromRaw = EntityType.prototype._updateTargetFromRaw;
    /** @hidden @internal */
    this._setCtor = EntityType.prototype._setCtor;
    if (arguments.length > 1) {
      throw new Error("The ComplexType ctor has a single argument that is a configuration object.");
    }
    assertConfig(config).whereParam("shortName").isNonEmptyString().whereParam("namespace").isString().isOptional().withDefault("").whereParam("dataProperties").isOptional().whereParam("isComplexType").isOptional().isBoolean() // needed because this ctor can get called from the addEntityType method which needs the isComplexType prop
    .whereParam("custom").isOptional().applyAll(this);
    this.name = qualifyTypeName(this.shortName, this.namespace);
    this.isComplexType = true;
    this.dataProperties = [];
    this.complexProperties = [];
    this.validators = [];
    this.concurrencyProperties = [];
    this.unmappedProperties = [];
    this._mappedPropertiesCount = 0;
    // keyProperties and navigationProperties are not used on complexTypes - but here to allow sharing of code between EntityType and ComplexType.
    this.navigationProperties = [];
    this.keyProperties = []; // may be used later to enforce uniqueness on arrays of complextypes.
    if (config.dataProperties) {
      addProperties(this, config.dataProperties, DataProperty);
    }
  }
  /**
  General purpose property set method
  >      // assume em1 is an EntityManager
  >      let addresstType = em1.metadataStore.getEntityType("Address");
  >      addressType.setProperties( {
  >          custom: { foo: 7, bar: "test" }
  >      });
  @param config - Custom config object
  @param config.custom - {Object}
  **/
  setProperties(config) {
    assertConfig(config).whereParam("custom").isOptional().applyAll(this);
  }
  getAllValidators() {
    // ComplexType inheritance is not YET supported.
    return this.validators;
  }
  /** @hidden @internal */
  _createInstanceCore(parent, parentProperty) {
    let aCtor = this.getCtor();
    let instance = new aCtor();
    new ComplexAspect(instance, parent, parentProperty);
    // initialization occurs during either attach or in createInstance call.
    return instance;
  }
  addProperty(dataProperty) {
    assertParam(dataProperty, "dataProperty").isInstanceOf(DataProperty).check();
    return this._addPropertyCore(dataProperty);
  }
  getProperties() {
    return this.dataProperties;
  }
  toJSON() {
    return core.toJson(this, {
      shortName: null,
      namespace: null,
      isComplexType: null,
      dataProperties: null,
      validators: null,
      custom: null
    });
  }
}
ComplexType.prototype._$typeName = "ComplexType";
/** Creates an instance of this complexType */
ComplexType.prototype.createInstance = EntityType.prototype.createEntity;
/**
A DataProperty describes the metadata for a single property of an  [[EntityType]] that contains simple data.

Instances of the DataProperty class are constructed automatically during Metadata retrieval. However it is also possible to construct them
directly via the constructor.
**/
class DataProperty {
  /** DataProperty constructor
  >      let lastNameProp = new DataProperty( {
  >          name: "lastName",
  >          dataType: DataType.String,
  >          isNullable: true,
  >          maxLength: 20
  >      });
  >      // assuming personEntityType is a newly constructed EntityType
  >      personEntityType.addProperty(lastNameProperty);
  @param config - A configuration Object or a DataProperty
  */
  constructor(config) {
    /** Is this a DataProperty? - always true here. Allows polymorphic treatment of DataProperties and NavigationProperties. __Read Only__ */
    this.isDataProperty = true;
    /** Is this a NavigationProperty? - always false here.  Allows polymorphic treatment of DataProperties and NavigationProperties. __Read Only__ */
    this.isNavigationProperty = false;
    assertConfig(config).whereParam("name").isString().isOptional().whereParam("nameOnServer").isString().isOptional().whereParam("dataType").isEnumOf(DataType).isOptional().or().isString().or().isInstanceOf(ComplexType).whereParam("complexTypeName").isOptional().whereParam("isNullable").isBoolean().isOptional().withDefault(true).whereParam("isScalar").isOptional().withDefault(true) // will be false for some NoSQL databases.
    .whereParam("defaultValue").isOptional().whereParam("isPartOfKey").isBoolean().isOptional().whereParam("isUnmapped").isBoolean().isOptional().whereParam("isSettable").isBoolean().isOptional().withDefault(true).whereParam("concurrencyMode").isString().isOptional().whereParam("maxLength").isNumber().isOptional().whereParam("validators").isInstanceOf(Validator).isArray().isOptional().withDefault([]).whereParam("displayName").isOptional().whereParam("enumType").isOptional().whereParam("rawTypeName").isOptional() // occurs with undefined datatypes
    .whereParam("custom").isOptional().applyAll(this);
    let hasName = !!(this.name || this.nameOnServer);
    if (!hasName) {
      throw new Error("A DataProperty must be instantiated with either a 'name' or a 'nameOnServer' property");
    }
    // name/nameOnServer is resolved later when a metadataStore is available.
    if (this.complexTypeName) {
      this.isComplexProperty = true;
      // this.dataType = null; // TODO: would like to remove this line because dataType will be set later.
    } else if (typeof this.dataType === "string") {
      let dt = DataType.fromName(this.dataType);
      if (!dt) {
        throw new Error("Unable to find a DataType enumeration by the name of: " + this.dataType);
      }
      this.dataType = dt;
    } else if (!this.dataType) {
      this.dataType = DataType.String;
    }
    // == as opposed to === is deliberate here.
    if (this.defaultValue == null) {
      if (this.isNullable) {
        this.defaultValue = null;
      } else {
        if (this.isComplexProperty) {
          // what to do? - shouldn't happen from EF - but otherwise ???
        } else if (this.dataType === DataType.Binary) {
          this.defaultValue = "AAAAAAAAJ3U="; // hack for all binary fields but value is specifically valid for timestamp fields - arbitrary valid 8 byte base64 value.
        } else {
          this.defaultValue = this.dataType.defaultValue;
          const msg = "A nonnullable DataProperty cannot have a null defaultValue. Name: " + (this.name || this.nameOnServer);
          if (this.defaultValue === null) {
            throw new Error(msg); // if defaultValue is explicity set to null, that's an error
          } else if (this.defaultValue == null) {
            console.warn(msg); // if defaultValue is not set, that's a warning
          }
        }
      }
    } else if (this.dataType.isNumeric) {
      // in case the defaultValue comes in as a string ( which it does in EF6).
      if (typeof this.defaultValue === "string") {
        this.defaultValue = parseFloat(this.defaultValue);
      }
    }
    if (this.isComplexProperty) {
      this.isScalar = this.isScalar == null || this.isScalar === true;
    }
  }
  static getRawValueFromServer(rawEntity, dp) {
    if (dp.isUnmapped) {
      return rawEntity[dp.nameOnServer || dp.name];
    } else {
      let val = rawEntity[dp.nameOnServer];
      return val !== undefined ? val : dp.defaultValue;
    }
  }
  static getRawValueFromClient(rawEntity, dp) {
    let val = rawEntity[dp.name];
    return val !== undefined ? val : dp.defaultValue;
  }
  resolveProperty(propName) {
    let result = this[propName];
    let baseProp = this.baseProperty;
    while (result == undefined && baseProp != null) {
      result = baseProp[propName];
      baseProp = baseProp.baseProperty;
    }
    return result;
  }
  formatName() {
    return this.parentType.name + "--" + this.name;
  }
  /**
  General purpose property set method
  >      // assume em1 is an EntityManager
  >      let prop = myEntityType.getProperty("myProperty");
  >      prop.setProperties( {
  >          custom: { foo: 7, bar: "test" }
  >      });
  @param config - A configuration object.
  **/
  setProperties(config) {
    assertConfig(config).whereParam("displayName").isOptional().whereParam("custom").isOptional().applyAll(this);
  }
  getAllValidators() {
    let validators = this.validators.slice(0);
    let baseProp = this.baseProperty;
    while (baseProp) {
      validators.push.apply(validators, baseProp.validators);
      baseProp = baseProp.baseProperty;
    }
    return validators;
  }
  toJSON() {
    // do not serialize dataTypes that are complexTypes
    return core.toJson(this, {
      name: null,
      dataType: function (v) {
        return v && v instanceof DataType ? v.name : undefined;
      },
      complexTypeName: null,
      isNullable: true,
      defaultValue: null,
      isPartOfKey: false,
      isUnmapped: false,
      isSettable: true,
      concurrencyMode: null,
      maxLength: null,
      validators: null,
      displayName: null,
      enumType: null,
      rawTypeName: null,
      isScalar: true,
      custom: null
    });
  }
  static fromJSON(json) {
    json.dataType = DataType.fromName(json.dataType);
    // Parse default value into correct data type. (dateTime instances require extra work to deserialize properly.)
    if (json.defaultValue && json.dataType && json.dataType.parse) {
      json.defaultValue = json.dataType.parse(json.defaultValue, typeof json.defaultValue);
    }
    if (json.validators) {
      json.validators = json.validators.map(Validator.fromJSON);
    }
    return new DataProperty(json);
  }
}
DataProperty.prototype._$typeName = "DataProperty";
/**   A NavigationProperty describes the metadata for a single property of an [[EntityType]] that return instances of other EntityTypes.

Instances of the NavigationProperty class are constructed automatically during Metadata retrieval.   However it is also possible to construct them
directly via the constructor.
**/
class NavigationProperty {
  /** NavigationProperty constructor
  >      let homeAddressProp = new NavigationProperty( {
  >          name: "homeAddress",
  >          entityTypeName: "Address:#myNamespace",
  >          isScalar: true,
  >          associationName: "address_person",
  >          foreignKeyNames: ["homeAddressId"]
  >      });
  >      let homeAddressIdProp = new DataProperty( {
  >          name: "homeAddressId"
  >          dataType: DataType.Integer
  >      });
  >      // assuming personEntityType is a newly constructed EntityType
  >      personEntityType.addProperty(homeAddressProp);
  >      personEntityType.addProperty(homeAddressIdProp);
  @param config - A configuration object.
  **/
  constructor(config) {
    /** Is this a DataProperty? - always false here
    Allows polymorphic treatment of DataProperties and NavigationProperties. __Read Only__ */
    this.isDataProperty = false;
    /** Is this a NavigationProperty? - always true here
    Allows polymorphic treatment of DataProperties and NavigationProperties. __Read Only__ */
    this.isNavigationProperty = true;
    this.formatName = DataProperty.prototype.formatName;
    this.getAllValidators = DataProperty.prototype.getAllValidators;
    this.resolveProperty = DataProperty.prototype.resolveProperty;
    assertConfig(config).whereParam("name").isString().isOptional().whereParam("nameOnServer").isString().isOptional().whereParam("entityTypeName").isString().whereParam("isScalar").isBoolean().isOptional().withDefault(true).whereParam("associationName").isString().isOptional().whereParam("foreignKeyNames").isArray().isString().isOptional().withDefault([]).whereParam("foreignKeyNamesOnServer").isArray().isString().isOptional().withDefault([]).whereParam("invForeignKeyNames").isArray().isString().isOptional().withDefault([]).whereParam("invForeignKeyNamesOnServer").isArray().isString().isOptional().withDefault([]).whereParam("validators").isInstanceOf(Validator).isArray().isOptional().withDefault([]).whereParam("displayName").isOptional().whereParam("custom").isOptional().applyAll(this);
    let hasName = !!(this.name || this.nameOnServer);
    if (!hasName) {
      throw new Error("A Navigation property must be instantiated with either a 'name' or a 'nameOnServer' property");
    }
  }
  /**
  General purpose property set method
  >      // assume myEntityType is an EntityType
  >      let prop = myEntityType.getProperty("myProperty");
  >      prop.setProperties( {
  >          custom: { foo: 7, bar: "test" }
  >      });
  @param config - A config object
  **/
  // TODO: create an interface for this.
  setProperties(config) {
    if (!this.parentType) {
      throw new Error("Cannot call NavigationProperty.setProperties until the parent EntityType of the NavigationProperty has been set.");
    }
    let inverse = config.inverse;
    if (inverse) delete config.inverse;
    assertConfig(config).whereParam("displayName").isOptional().whereParam("foreignKeyNames").isArray().isString().isOptional().withDefault([]).whereParam("invForeignKeyNames").isArray().isString().isOptional().withDefault([]).whereParam("custom").isOptional().applyAll(this);
    this.parentType._updateNames(this);
    this._resolveNp();
    if (inverse) {
      this.setInverse(inverse);
    }
  }
  /** The inverse of this NavigationProperty.  The NavigationProperty that represents a navigation in the opposite direction
  to this NavigationProperty. May be undefined for a undirectional NavigationProperty. __Read Only__ */
  get inverse() {
    return this.getInverse();
  }
  /** @hidden @internal */
  getInverse() {
    let np = this;
    while (!np._inverse && np.baseProperty) {
      np = np.baseProperty;
    }
    return np._inverse;
  }
  setInverse(inverseNp) {
    // let invNp: NavigationProperty;
    let invNp = inverseNp instanceof NavigationProperty ? inverseNp : this.entityType.getNavigationProperty(inverseNp);
    if (!invNp) {
      throw throwSetInverseError(this, "Unable to find inverse property: " + inverseNp);
    }
    if (this._inverse || invNp._inverse) {
      throwSetInverseError(this, "It has already been set on one side or the other.");
    }
    if (invNp.entityType !== this.parentType) {
      throwSetInverseError(this, invNp.formatName + " is not a valid inverse property for this.");
    }
    if (this.associationName) {
      invNp.associationName = this.associationName;
    } else {
      if (!invNp.associationName) {
        invNp.associationName = this.formatName() + "_" + invNp.formatName();
      }
      this.associationName = invNp.associationName;
    }
    this._resolveNp();
    invNp._resolveNp();
  }
  // // In progress - will be used for manual metadata config
  // createInverse(config: any) {
  //   if (!this.entityType) {
  //     throwCreateInverseError(this, "has not yet been defined.");
  //   }
  //   if (this.entityType.isFrozen) {
  //     throwCreateInverseError(this, "is frozen.");
  //   }
  //   let metadataStore = this.entityType.metadataStore;
  //   if (metadataStore == null) {
  //     throwCreateInverseError(this, "has not yet been added to the metadataStore.");
  //   }
  //   config.entityTypeName = this.parentEntityType.name;
  //   config.associationName = this.associationName;
  //   let invNp = new NavigationProperty(config);
  //   this.parentEntityType.addNavigationProperty(invNp);
  //   return invNp;
  // };
  toJSON() {
    return core.toJson(this, {
      name: null,
      entityTypeName: null,
      isScalar: null,
      associationName: null,
      validators: null,
      displayName: null,
      foreignKeyNames: null,
      invForeignKeyNames: null,
      custom: null
    });
  }
  static fromJSON(json) {
    if (json.validators) {
      json.validators = json.validators.map(Validator.fromJSON);
    }
    return new NavigationProperty(json);
  }
  /** @hidden @internal */
  _resolveNp() {
    let np = this;
    let entityType = np.entityType;
    let invNp = core.arrayFirst(entityType.navigationProperties, altNp => {
      // Can't do this because of possibility of comparing a base class np with a subclass altNp.
      // return altNp.associationName === np.associationName
      //    && altNp !== np;
      // So use this instead.
      return altNp.associationName === np.associationName && (altNp.name !== np.name || altNp.entityTypeName !== np.entityTypeName);
    });
    np._inverse = invNp || undefined;
    //if (invNp && invNp.inverse == null) {
    //    invNp._resolveNp();
    //}
    if (!invNp) {
      // unidirectional 1-n relationship
      np.invForeignKeyNames.forEach(function (invFkName) {
        let fkProp = entityType.getDataProperty(invFkName);
        if (fkProp == null) {
          throw new Error("EntityType '" + np.entityTypeName + "' has no foreign key matching '" + invFkName + "'");
        }
        let invEntityType = np.parentType;
        invNp = core.arrayFirst(invEntityType.navigationProperties, np2 => {
          return np2.invForeignKeyNames && np2.invForeignKeyNames.indexOf(fkProp.name) >= 0 && np2.entityType === fkProp.parentType;
        });
        fkProp.inverseNavigationProperty = invNp || undefined;
        core.arrayAddItemUnique(entityType.foreignKeyProperties, fkProp);
      });
    }
    resolveRelated(np);
  }
}
NavigationProperty.prototype._$typeName = "NavigationProperty";
function throwSetInverseError(np, message) {
  throw new Error("Cannot set the inverse property for: " + np.formatName() + ". " + message);
}
// Not current used.
// function throwCreateInverseError(np: NavigationProperty, message: string) {
//   throw new Error("Cannot create inverse for: " + np.formatName() + ". The entityType for this navigation property " + message);
// }
// sets navigation property: relatedDataProperties and dataProperty: relatedNavigationProperty
function resolveRelated(np) {
  let fkNames = np.foreignKeyNames;
  if (fkNames.length === 0) return;
  let parentEntityType = np.parentType;
  let fkProps = fkNames.map(function (fkName) {
    return parentEntityType.getDataProperty(fkName);
  });
  let fkPropCollection = parentEntityType.foreignKeyProperties;
  fkProps.forEach(dp => {
    core.arrayAddItemUnique(fkPropCollection, dp);
    dp.relatedNavigationProperty = np;
    // now update the inverse
    core.arrayAddItemUnique(np.entityType.inverseForeignKeyProperties, dp);
    if (np.relatedDataProperties) {
      core.arrayAddItemUnique(np.relatedDataProperties, dp);
    } else {
      np.relatedDataProperties = [dp];
    }
  });
}
/**
AutoGeneratedKeyType is an 'Enum' containing all of the valid states for an automatically generated key.
**/
class AutoGeneratedKeyType extends BreezeEnum {}
/**
This entity does not have an autogenerated key.
The client must set the key before adding the entity to the EntityManager
**/
AutoGeneratedKeyType.None = new AutoGeneratedKeyType();
/**
This entity's key is an Identity column and is set by the backend database.
Keys for new entities will be temporary until the entities are saved at which point the keys will
be converted to their 'real' versions.
**/
AutoGeneratedKeyType.Identity = new AutoGeneratedKeyType();
/**
This entity's key is generated by a KeyGenerator and is set by the backend database.
Keys for new entities will be temporary until the entities are saved at which point the keys will
be converted to their 'real' versions.
**/
AutoGeneratedKeyType.KeyGenerator = new AutoGeneratedKeyType();
AutoGeneratedKeyType.prototype._$typeName = "AutoGeneratedKeyType";
Error['x'] = AutoGeneratedKeyType.resolveSymbols();
let proto = Param.prototype;
// 'this' below is TS annotation 
proto.isEntity = function () {
  return this._addContext({
    fn: isEntity,
    msg: " must be an entity"
  });
};
function isEntity(context, v) {
  if (v == null) return false;
  return v.entityType !== undefined;
}
proto.isEntityProperty = function () {
  return this._addContext({
    fn: isEntityProperty,
    msg: " must be either a DataProperty or a NavigationProperty"
  });
};
function isEntityProperty(context, v) {
  if (v == null) return false;
  return v.isDataProperty || v.isNavigationProperty;
}
// functions shared between classes related to Metadata
function isQualifiedTypeName(entityTypeName) {
  return entityTypeName.indexOf(":#") >= 0;
}
function qualifyTypeName(shortName, ns) {
  if (ns && ns.length > 0) {
    return shortName + ":#" + ns;
  } else {
    return shortName;
  }
}
// Used by both ComplexType and EntityType
function addProperties(entityType, propObj, ctor) {
  if (propObj == null) return;
  if (Array.isArray(propObj)) {
    propObj.forEach(entityType._addPropertyCore.bind(entityType));
  } else if (typeof propObj === 'object') {
    for (let key in propObj) {
      if (core.hasOwnProperty(propObj, key)) {
        let value = propObj[key];
        value.name = key;
        let prop = new ctor(value);
        entityType._addPropertyCore(prop);
      }
    }
  } else {
    throw new Error("The 'dataProperties' or 'navigationProperties' values must be either an array of data/nav properties or an object where each property defines a data/nav property");
  }
}

/** For use by breeze plugin authors only.  The class is used as the base class for most [[IDataServiceAdapter]] implementations
@adapter (see [[IDataServiceAdapter]])
@hidden
*/
class AbstractDataServiceAdapter {
  constructor() {
    /**
    Returns a constructor function for a "ChangeRequestInterceptor"
    that can tweak the saveBundle both as it is built and when it is completed
    by a concrete DataServiceAdapater.
            Initialized with a default, no-op implementation that developers can replace with a
    substantive implementation that changes the individual entity change requests
    or aspects of the entire 'saveBundle' without having to write their own DataService adapters.
    >     let adapter = breeze.config.getAdapterInstance('dataService');
    >     adapter.changeRequestInterceptor = function (saveContext, saveBundle) {
    >         this.getRequest = function (request, entity, index) {
    >            // alter the request that the adapter prepared for this entity
    >            // based on the entity, saveContext, and saveBundle
    >            // e.g., add a custom header or prune the originalValuesMap
    >            return request;
    >        };
    >        this.done = function (requests) {
    >            // alter the array of requests representing the entire change-set
    >            // based on the saveContext and saveBundle
    >        };
    >     }
            @param saveContext - The BreezeJS "context" for the save operation.
    @param saveBundle - Contains the array of entities-to-be-saved (AKA, the entity change-set).
    @return Constructor for a "ChangeRequestInterceptor".
    **/
    this.changeRequestInterceptor = DefaultChangeRequestInterceptor;
    this.jsonResultsAdapter = new JsonResultsAdapter({
      name: "noop",
      visitNode: function /* node, mappingContext, nodeContext */
      () {
        return {};
      }
    });
  }
  // TODO use interface
  checkForRecomposition(interfaceInitializedArgs) {
    if (interfaceInitializedArgs.interfaceName === "ajax" && interfaceInitializedArgs.isDefault) {
      this.initialize();
    }
  }
  initialize() {
    this.ajaxImpl = config.getAdapterInstance("ajax");
    // don't cache 'ajax' because then we would need to ".bind" it, and don't want to because of brower support issues.
    if (this.ajaxImpl && this.ajaxImpl.ajax) {
      return;
    }
    throw new Error("Unable to find ajax adapter for dataservice adapter '" + (this.name || '') + "'.");
  }
  fetchMetadata(metadataStore, dataService) {
    let serviceName = dataService.serviceName;
    let url = dataService.qualifyUrl("Metadata");
    let promise = new Promise((resolve, reject) => {
      this.ajaxImpl.ajax({
        type: "GET",
        url: url,
        dataType: 'json',
        success: httpResponse => {
          // might have been fetched by another query
          if (metadataStore.hasMetadataFor(serviceName)) {
            return resolve("already fetched");
          }
          let data = httpResponse.data;
          let metadata;
          try {
            metadata = typeof data === "string" ? JSON.parse(data) : data;
            metadataStore.importMetadata(metadata);
          } catch (e) {
            let errMsg = "Unable to either parse or import metadata: " + e.message;
            handleHttpError(reject, httpResponse, "Metadata query failed for: " + url + ". " + errMsg);
          }
          // import may have brought in the service.
          if (!metadataStore.hasMetadataFor(serviceName)) {
            metadataStore.addDataService(dataService);
          }
          resolve(metadata);
        },
        error: httpResponse => {
          handleHttpError(reject, httpResponse, "Metadata query failed for: " + url);
        }
      });
    });
    return promise;
  }
  executeQuery(mappingContext) {
    mappingContext.adapter = this;
    let promise = new Promise((resolve, reject) => {
      let url = mappingContext.getUrl();
      let params = {
        type: "GET",
        url: url,
        params: mappingContext.query.parameters,
        dataType: 'json',
        success: function (httpResponse) {
          let data = httpResponse.data;
          try {
            let rData;
            let results = data && (data.results || data.Results);
            if (results) {
              rData = {
                results: results,
                inlineCount: data.inlineCount || data.InlineCount,
                httpResponse: httpResponse,
                query: mappingContext.query
              };
            } else {
              rData = {
                results: data,
                httpResponse: httpResponse,
                query: mappingContext.query
              };
            }
            resolve(rData);
          } catch (e) {
            if (e instanceof Error) {
              reject(e);
            } else {
              handleHttpError(reject, httpResponse);
            }
          }
        },
        error: function (httpResponse) {
          handleHttpError(reject, httpResponse);
        },
        crossDomain: false
      };
      if (mappingContext.dataService.useJsonp) {
        params.dataType = 'jsonp';
        params.crossDomain = true;
      }
      this.ajaxImpl.ajax(params);
    });
    return promise;
  }
  saveChanges(saveContext, saveBundle) {
    let adapter = saveContext.adapter = this;
    let saveBundleSer = adapter._prepareSaveBundle(saveContext, saveBundle);
    let bundle = JSON.stringify(saveBundleSer);
    let url = saveContext.dataService.qualifyUrl(saveContext.resourceName);
    let promise = new Promise((resolve, reject) => {
      this.ajaxImpl.ajax({
        type: "POST",
        url: url,
        dataType: 'json',
        contentType: "application/json",
        data: bundle,
        success: function (httpResponse) {
          httpResponse.saveContext = saveContext;
          let data = httpResponse.data;
          if (data.Errors || data.errors) {
            handleHttpError(reject, httpResponse);
          } else {
            let saveResult = adapter._prepareSaveResult(saveContext, data);
            saveResult.httpResponse = httpResponse;
            resolve(saveResult);
          }
        },
        error: function (httpResponse) {
          httpResponse.saveContext = saveContext;
          handleHttpError(reject, httpResponse);
        }
      });
    });
    return promise;
  }
  /** Abstract method that needs to be overwritten in any concrete DataServiceAdapter subclass.
  The return value from this method should be a serializable object that will be sent to the server after calling JSON.stringify on it.
  */
  _prepareSaveBundle(saveContext, saveBundle) {
    // The implementor should call _createChangeRequestInterceptor
    throw new Error("Need a concrete implementation of _prepareSaveBundle");
  }
  /** @hidden @internal */
  _createChangeRequestInterceptor(saveContext, saveBundle) {
    let adapter = saveContext.adapter;
    let cri = adapter.changeRequestInterceptor;
    let isFn = core.isFunction;
    if (isFn(cri)) {
      let pre = adapter.name + " DataServiceAdapter's ChangeRequestInterceptor";
      let post = " is missing or not a function.";
      let interceptor = new cri(saveContext, saveBundle);
      if (!isFn(interceptor.getRequest)) {
        throw new Error(pre + '.getRequest' + post);
      }
      if (!isFn(interceptor.done)) {
        throw new Error(pre + '.done' + post);
      }
      return interceptor;
    } else {
      return new DefaultChangeRequestInterceptor(saveContext, saveBundle);
    }
  }
  /** Abstract method that needs to be overwritten in any concrete DataServiceAdapter sublclass.
  This method needs to take the result returned the server and convert it into an ISaveResult.
  */
  _prepareSaveResult(saveContext, data) {
    throw new Error("Need a concrete implementation of _prepareSaveResult");
  }
  /** Utility method that may be used in any concrete DataServiceAdapter sublclass to handle any
  http connection issues.
  */
  // Put this at the bottom of your http error analysis
  static _catchNoConnectionError(err) {
    if (err.status === 0 && err.message == null) {
      err.message = "HTTP response status 0 and no message.  " + "Likely did not or could not reach server. Is the server running?";
    }
  }
}
function handleHttpError(reject, httpResponse, messagePrefix) {
  let err = createError(httpResponse);
  AbstractDataServiceAdapter._catchNoConnectionError(err);
  if (messagePrefix) {
    err.message = messagePrefix + "; " + err.message;
  }
  reject(err);
}
function createError(httpResponse) {
  let err = new Error();
  err.httpResponse = httpResponse;
  err.status = httpResponse.status;
  let errObj = httpResponse.data;
  if (!errObj) {
    err.message = httpResponse.error && httpResponse.error.toString();
    return err;
  }
  // some ajax providers will convert errant result into an object (angularjs), others will not (jQuery)
  // if not do it here.
  if (typeof errObj === "string") {
    try {
      errObj = JSON.parse(errObj);
    } catch (e) {
      // sometimes httpResponse.data is just the error message itself
      err.message = errObj;
      return err;
    }
  }
  let saveContext = httpResponse.saveContext;
  // if any of the follow properties exist the source is .NET
  let tmp = errObj.Message || errObj.ExceptionMessage || errObj.EntityErrors || errObj.Errors;
  let isDotNet = !!tmp;
  let message, entityErrors;
  if (!isDotNet) {
    message = errObj.message;
    entityErrors = errObj.errors || errObj.entityErrors;
  } else {
    let tmp = errObj;
    do {
      // .NET exceptions can provide both ExceptionMessage and Message but ExceptionMethod if it
      // exists has a more detailed message.
      message = tmp.ExceptionMessage || tmp.Message;
      tmp = tmp.InnerException;
    } while (tmp);
    // .EntityErrors will only occur as a result of an EntityErrorsException being deliberately thrown on the server
    entityErrors = errObj.Errors || errObj.EntityErrors;
    entityErrors = entityErrors && entityErrors.map(function (e) {
      return {
        errorName: e.ErrorName,
        entityTypeName: MetadataStore.normalizeTypeName(e.EntityTypeName),
        keyValues: e.KeyValues,
        propertyName: e.PropertyName,
        errorMessage: e.ErrorMessage,
        custom: e.Custom
      };
    });
  }
  if (saveContext && entityErrors) {
    let propNameFn = saveContext.entityManager.metadataStore.namingConvention.serverPropertyNameToClient;
    entityErrors.forEach(function (e) {
      e.propertyName = e.propertyName && propNameFn(e.propertyName);
    });
    err.entityErrors = entityErrors;
  }
  err.message = message || "Server side errors encountered - see the entityErrors collection on this object for more detail";
  return err;
}
/** This is a default, no-op implementation that developers can replace. */
class DefaultChangeRequestInterceptor {
  constructor(saveContext, saveBundle) {}
  getRequest(request, entity, index) {
    return request;
  }
  done(requests) {}
}

/**
A ValidationOptions instance is used to specify the conditions under which validation will be executed.

*/
class ValidationOptions {
  /**
  ValidationOptions constructor
  >     var newVo = new ValidationOptions( { validateOnSave: false, validateOnAttach: false });
  >     // assume em1 is a preexisting EntityManager
  >     em1.setProperties( { validationOptions: newVo });
  @param config - A configuration object.
  **/
  constructor(config) {
    updateWithConfig$1(this, config);
  }
  /**
  Returns a copy of this ValidationOptions with changes to the specified config properties.
  >     var validationOptions = new ValidationOptions();
  >     var newOptions = validationOptions.using( { validateOnQuery: true, validateOnSave: false} );
  @param config - A configuration object
  @return A new ValidationOptions instance.
  **/
  using(config) {
    if (!config) return this;
    let result = new ValidationOptions(this);
    updateWithConfig$1(result, config);
    return result;
  }
  /**
  Sets the 'defaultInstance' by creating a copy of the current 'defaultInstance' and then applying all of the properties of the current instance.
  The current instance is returned unchanged.
  >     var validationOptions = new ValidationOptions()
  >     var newOptions = validationOptions.using( { validateOnQuery: true, validateOnSave: false} );
  >     var newOptions.setAsDefault();
  **/
  setAsDefault() {
    return core.setAsDefault(this, ValidationOptions);
  }
}
/**
The default instance for use whenever ValidationOptions are not specified.
**/
ValidationOptions.defaultInstance = new ValidationOptions({
  validateOnAttach: true,
  validateOnSave: true,
  validateOnQuery: false,
  validateOnPropertyChange: true
});
ValidationOptions.prototype._$typeName = "ValidationOptions";
function updateWithConfig$1(options, config) {
  if (config) {
    assertConfig(config).whereParam("validateOnAttach").isBoolean().isOptional().whereParam("validateOnSave").isBoolean().isOptional().whereParam("validateOnQuery").isBoolean().isOptional().whereParam("validateOnPropertyChange").isBoolean().isOptional().applyAll(options);
  }
  return options;
}

/**
A SaveOptions instance is used to specify the 'options' under which a save will occur.
**/
class SaveOptions {
  constructor(config) {
    SaveOptions._updateWithConfig(this, config);
  }
  /**
  Sets the 'defaultInstance' by creating a copy of the current 'defaultInstance' and then applying all of the properties of the current instance.
  The current instance is returned unchanged.
  **/
  setAsDefault() {
    return core.setAsDefault(this, SaveOptions);
  }
  /**
  Returns a copy of this SaveOptions with the specified config options applied.
  >     var saveOptions = em1.saveOptions.using( {resourceName: "anotherResource" });
  **/
  using(config) {
    return SaveOptions._updateWithConfig(this, config);
  }
  /** @hidden @internal */
  static _updateWithConfig(obj, config) {
    if (config) {
      assertConfig(config).whereParam("resourceName").isOptional().isString().whereParam("dataService").isOptional().isInstanceOf(DataService).whereParam("allowConcurrentSaves").isBoolean().isOptional().whereParam("tag").isOptional().applyAll(obj);
    }
    return obj;
  }
}
/** The default value whenever SaveOptions are not specified. */
SaveOptions.defaultInstance = new SaveOptions({
  allowConcurrentSaves: false
});
SaveOptions.prototype._$typeName = "SaveOptions";

/*
  @class KeyGenerator
  */
class KeyGenerator {
  constructor() {
    // key is dataProperty.name + || + entityType.name, value is propEntry
    // propEntry = { entityType, propertyName, keyMap }
    // keyMap has key of the actual value ( as a string) and a value of null or the real id.
    this._tempIdMap = {};
  }
  /*
  Returns a unique 'temporary' id for the specified [[EntityType]].
  Uniqueness is defined for this purpose as being unique within each instance of a KeyGenerator. This is sufficient
  because each EntityManager will have its own instance of a KeyGenerator and any entities imported into
  the EntityManager with temporary keys will have them regenerated and remapped on import.
      The return value of this method must be of the correct type as determined by the keyProperties of the
  specified EntityType
  @example
      // Assume em1 is a preexisting EntityManager
      let custType = em1.metadataStore.getEntityType("Customer");
      let cust1 = custType.createEntity();
      // next line both sets cust1's 'CustomerId' property but also returns the value
      let cid1 = em1.generateTempKeyValue(cust1);
      em1.saveChanges().then( function( data) {
        let sameCust1 = data.results[0];
        // cust1 === sameCust1;
        // but cust1.getProperty("CustomerId") != cid1
        // because the server will have generated a new id
        // and the client will have been updated with this
        // new id.
      });
  @method generateTempKeyValue
  @param entityType {EntityType}
  */
  generateTempKeyValue(entityType, valueIfAvail) {
    let keyProps = entityType.keyProperties;
    if (keyProps.length > 1) {
      throw new Error("Ids can not be autogenerated for entities with multipart keys");
    }
    let keyProp = keyProps[0];
    let propEntry = this._getPropEntry(keyProp, true);
    let nextId;
    if (valueIfAvail != null) {
      if (!propEntry.keyMap[valueIfAvail.toString()]) {
        nextId = valueIfAvail;
      }
    }
    if (nextId === undefined) {
      let dataType = keyProp.dataType;
      let getNextFn = dataType.getNext;
      if (getNextFn) {
        nextId = getNextFn(this);
        // need to watch out for collision with previously imported ids that might also get generated.
        while (propEntry.keyMap[nextId.toString()] != null) {
          nextId = getNextFn(this);
        }
      } else {
        throw new Error("Cannot use a property with a dataType of: " + dataType.toString() + " for id generation");
      }
    }
    propEntry.keyMap[nextId.toString()] = true;
    return nextId;
  }
  getTempKeys() {
    let results = [];
    //noinspection JSHint
    for (let key in this._tempIdMap) {
      let propEntry = this._tempIdMap[key];
      let entityType = propEntry.entityType;
      // let propName = propEntry.propertyName;
      //noinspection JSHint
      for (let keyValue in propEntry.keyMap) {
        results.push(new EntityKey(entityType, [keyValue]));
      }
    }
    return results;
  }
  // proto methods below are not part of the KeyGenerator interface.
  isTempKey(entityKey) {
    let keyProps = entityKey.entityType.keyProperties;
    if (keyProps.length > 1) return false;
    let keyProp = keyProps[0];
    let propEntry = this._getPropEntry(keyProp);
    if (!propEntry) {
      return false;
    }
    return propEntry.keyMap[entityKey.values[0].toString()] !== undefined;
  }
  /** @hidden @internal */
  _getPropEntry(keyProp, createIfMissing = false) {
    let key = keyProp.name + ".." + keyProp.parentType.name;
    let propEntry = this._tempIdMap[key];
    if (!propEntry) {
      if (createIfMissing) {
        propEntry = {
          entityType: keyProp.parentType,
          propertyName: keyProp.name,
          keyMap: {}
        };
        this._tempIdMap[key] = propEntry;
      }
    }
    return propEntry;
  }
}
config.registerType(KeyGenerator, "KeyGenerator");

/** @hidden @internal */
class EntityGroup {
  constructor(entityManager, entityType) {
    this.entityManager = entityManager;
    this.entityType = entityType;
    // freeze the entityType after the first instance of this type is either created or queried.
    this.entityType.isFrozen = true;
    this._indexMap = {};
    this._entities = [];
    this._emptyIndexes = [];
  }
  attachEntity(entity, entityState, mergeStrategy) {
    // entity should already have an aspect.
    let aspect = entity.entityAspect;
    if (!aspect._initialized) {
      this.entityType._initializeInstance(entity);
    }
    delete aspect._initialized;
    let keyInGroup = aspect.getKey()._keyInGroup;
    let ix = this._indexMap[keyInGroup];
    if (ix >= 0) {
      // safecast because key was found not ix will not return a null
      let targetEntity = this._entities[ix];
      let targetEntityState = targetEntity.entityAspect.entityState;
      let wasUnchanged = targetEntityState.isUnchanged();
      if (targetEntity === entity) {
        aspect.entityState = entityState;
      } else if (mergeStrategy === MergeStrategy.Disallowed) {
        throw new Error("A MergeStrategy of 'Disallowed' does not allow you to attach an entity when an entity with the same key is already attached: " + aspect.getKey());
      } else if (mergeStrategy === MergeStrategy.OverwriteChanges || mergeStrategy === MergeStrategy.PreserveChanges && wasUnchanged) {
        // unwrapInstance returns an entity with server side property names - so we need to use DataProperty.getRawValueFromServer these when we apply
        // the property values back to the target.
        let rawServerEntity = this.entityManager.helper.unwrapInstance(entity);
        this.entityType._updateTargetFromRaw(targetEntity, rawServerEntity, DataProperty.getRawValueFromServer);
        targetEntity.entityAspect.setEntityState(entityState);
      }
      return targetEntity;
    } else {
      if (this._emptyIndexes.length === 0) {
        ix = this._entities.push(entity) - 1;
      } else {
        ix = this._emptyIndexes.pop();
        this._entities[ix] = entity;
      }
      this._indexMap[keyInGroup] = ix;
      aspect.entityState = entityState;
      aspect.entityGroup = this;
      aspect.entityManager = this.entityManager;
      return entity;
    }
  }
  detachEntity(entity) {
    // by this point we have already determined that this entity
    // belongs to this group.
    let aspect = entity.entityAspect;
    let keyInGroup = aspect.getKey()._keyInGroup;
    let ix = this._indexMap[keyInGroup];
    if (ix === undefined) {
      // shouldn't happen.
      throw new Error("internal error - entity cannot be found in group");
    }
    delete this._indexMap[keyInGroup];
    this._emptyIndexes.push(ix);
    this._entities[ix] = null;
    return entity;
  }
  // returns entity based on an entity key defined either as an array of key values or an EntityKey
  findEntityByKey(entityKey) {
    let keyInGroup;
    if (entityKey instanceof EntityKey) {
      keyInGroup = entityKey._keyInGroup;
    } else {
      keyInGroup = EntityKey.createKeyString(entityKey);
    }
    let ix = this._indexMap[keyInGroup];
    // can't use just (ix) below because 0 is valid
    let r = ix !== undefined ? this._entities[ix] : undefined;
    // coerce null to undefined
    return r == null ? undefined : r;
  }
  hasChanges() {
    let entities = this._entities;
    let unchanged = EntityState.Unchanged;
    for (let i = 0, len = entities.length; i < len; i++) {
      let e = entities[i];
      if (e && e.entityAspect.entityState !== unchanged) {
        return true;
      }
    }
    return false;
  }
  getChanges() {
    let entities = this._entities;
    let unchanged = EntityState.Unchanged;
    let changes = [];
    for (let i = 0, len = entities.length; i < len; i++) {
      let e = entities[i];
      if (e && e.entityAspect.entityState !== unchanged) {
        changes.push(e);
      }
    }
    return changes;
  }
  getEntities(entityStates) {
    let filter = getFilter(entityStates);
    return this._entities.filter(filter);
  }
  _checkOperation(operationName) {
    this._entities.forEach(function (entity) {
      entity && entity.entityAspect._checkOperation(operationName);
    });
    // for chaining;
    return this;
  }
  // do not expose this method. It is doing a special purpose INCOMPLETE fast detach operation
  // just for the entityManager clear method - the entityGroup will be in an inconsistent state
  // after this op, which is ok because it will be thrown away.
  // TODO: rename this to be clear that it is UNSAFE...
  _clear() {
    this._entities.forEach(function (entity) {
      if (entity != null) {
        entity.entityAspect._detach();
      }
    });
    this._entities = null;
    this._indexMap = null;
    this._emptyIndexes = null;
  }
  _updateFkVal(fkProp, oldValue, newValue) {
    let fkPropName = fkProp.name;
    this._entities.forEach(function (entity) {
      if (entity != null) {
        if (entity.getProperty(fkPropName) === oldValue) {
          entity.setProperty(fkPropName, newValue);
        }
      }
    });
  }
  _fixupKey(tempValue, realValue) {
    // single part keys appear directly in map
    let ix = this._indexMap[tempValue];
    if (ix === undefined) {
      throw new Error("Internal Error in key fixup - unable to locate entity");
    }
    let entity = this._entities[ix];
    let keyPropName = entity.entityType.keyProperties[0].name;
    // fks on related entities will automatically get updated by this as well
    entity.setProperty(keyPropName, realValue);
    delete entity.entityAspect.hasTempKey;
    delete this._indexMap[tempValue];
    this._indexMap[realValue] = ix;
  }
  _replaceKey(oldKey, newKey) {
    let ix = this._indexMap[oldKey._keyInGroup];
    delete this._indexMap[oldKey._keyInGroup];
    this._indexMap[newKey._keyInGroup] = ix;
  }
}
function getFilter(entityStates) {
  if (entityStates.length === 0) {
    return function (e) {
      return !!e;
    };
  } else if (entityStates.length === 1) {
    let entityState = entityStates[0];
    return function (e) {
      return !!e && e.entityAspect.entityState === entityState;
    };
  } else {
    return function (e) {
      return !!e && -1 !== entityStates.indexOf(e.entityAspect.entityState);
    };
  }
}
// do not expose EntityGroup - internal only

/**
For use by breeze plugin authors only. The class is for use in building a [[IDataServiceAdapter]] implementation.
@adapter (see [[IDataServiceAdapter]])
@hidden
*/
class MappingContext {
  constructor(config) {
    this.rawValueFn = DataProperty.getRawValueFromServer; // think about passing this in later.
    core.extend(this, config, ["query", "entityManager", "dataService", "mergeOptions"]);
    // calc'd props
    this.refMap = {};
    this.deferredFns = [];
    this.jsonResultsAdapter = this.dataService.jsonResultsAdapter;
    this.metadataStore = this.entityManager.metadataStore;
    this.rawValueFn = DataProperty.getRawValueFromServer; // think about passing this in later.
  }
  getUrl() {
    let query = this.query;
    if (!query) {
      throw new Error("query cannot be empty");
    }
    let uriString;
    if (typeof query === 'string') {
      uriString = query;
    } else if (query instanceof EntityQuery) {
      uriString = this.dataService.uriBuilder.buildUri(query, this.metadataStore);
    } else {
      throw new Error("unable to recognize query parameter as either a string or an EntityQuery");
    }
    return this.dataService.qualifyUrl(uriString);
  }
  visitAndMerge(nodes, nodeContext) {
    let query = this.query;
    let jra = this.jsonResultsAdapter;
    nodeContext = nodeContext || {};
    let that = this;
    return core.map(nodes, function (node) {
      if (query == null && node.entityAspect) {
        // don't bother merging a result from a save that was not returned from the server.
        if (node.entityAspect.entityState.isDeleted()) {
          that.entityManager.detachEntity(node);
        } else {
          node.entityAspect.acceptChanges();
        }
        return node;
      }
      let meta = jra.visitNode(node, that, nodeContext) || {};
      node = meta.node || node;
      if (query && nodeContext.nodeType === "root" && !meta.entityType) {
        meta.entityType = query instanceof EntityQuery && query._getToEntityType && query._getToEntityType(that.metadataStore);
      }
      return processMeta(that, node, meta);
    }, this.mergeOptions.includeDeleted);
  }
  processDeferred() {
    if (this.deferredFns.length > 0) {
      this.deferredFns.forEach(fn => {
        fn();
      });
    }
  }
}
MappingContext.prototype._$typeName = "MappingContext";
function processMeta(mc, node, meta, assignFn) {
  // == is deliberate here instead of ===
  if (meta.ignore || node == null) {
    return null;
  } else if (meta.nodeRefId) {
    let refValue = resolveEntityRef(mc, meta.nodeRefId);
    if (typeof refValue === "function" && assignFn != null) {
      mc.deferredFns.push(function () {
        assignFn(refValue);
      });
      return undefined; // deferred and will be set later;
    }
    return refValue;
  } else if (meta.entityType) {
    let entityType = meta.entityType;
    if (mc.mergeOptions.noTracking) {
      node = processNoMerge(mc, entityType, node);
      if (entityType.noTrackingFn) {
        node = entityType.noTrackingFn(node, entityType);
      }
      if (meta.nodeId) {
        mc.refMap[meta.nodeId] = node;
      }
      return node;
    } else {
      if (entityType.isComplexType) {
        // because we still need to do serverName to client name processing
        return processNoMerge(mc, entityType, node);
      } else {
        return mergeEntity(mc, node, meta);
      }
    }
  } else {
    if (!meta.passThru && typeof node === 'object' && !core.isDate(node)) {
      node = processAnonType(mc, node);
    }
    // updating the refMap for entities is handled by updateEntityRef for entities.
    if (meta.nodeId) {
      mc.refMap[meta.nodeId] = node;
    }
    return node;
  }
}
function processNoMerge(mc, stype, node) {
  let result = {};
  stype.dataProperties.forEach(function (dp) {
    if (dp.isComplexProperty) {
      result[dp.name] = core.map(node[dp.nameOnServer], v => {
        return processNoMerge(mc, dp.dataType, v);
      });
    } else {
      result[dp.name] = DataType.parseRawValue(node[dp.nameOnServer], dp.dataType);
    }
  });
  stype instanceof EntityType && stype.navigationProperties.forEach(np => {
    let nodeContext = {
      nodeType: "navProp",
      navigationProperty: np
    };
    visitNode(node[np.nameOnServer], mc, nodeContext, result, np.name);
  });
  return result;
}
function processAnonType(mc, node) {
  // node is guaranteed to be an object by this point, i.e. not a scalar
  let keyFn = mc.metadataStore.namingConvention.serverPropertyNameToClient;
  let result = {};
  core.objectForEach(node, function (key, value) {
    let newKey = keyFn(key);
    let nodeContext = {
      nodeType: "anonProp",
      propertyName: newKey
    };
    visitNode(value, mc, nodeContext, result, newKey);
  });
  return result;
}
function visitNode(node, mc, nodeContext, result, key) {
  let jra = mc.jsonResultsAdapter;
  let meta = jra.visitNode(node, mc, nodeContext) || {};
  // allows visitNode to change the value;
  node = meta.node || node;
  if (meta.ignore) return;
  if (meta.passThru) return node;
  if (Array.isArray(node)) {
    nodeContext.nodeType = nodeContext.nodeType + "Item";
    result[key] = node.map(function (v, ix) {
      meta = jra.visitNode(v, mc, nodeContext) || {};
      v = meta.node || v;
      return processMeta(mc, v, meta, function (refValue) {
        result[key][ix] = refValue();
      });
    });
  } else {
    result[key] = processMeta(mc, node, meta, function (refValue) {
      result[key] = refValue();
    });
  }
}
function resolveEntityRef(mc, nodeRefId) {
  let entity = mc.refMap[nodeRefId];
  if (entity === undefined) {
    return function () {
      return mc.refMap[nodeRefId];
    };
  } else {
    return entity;
  }
}
function updateEntityRef(mc, targetEntity, node) {
  let nodeId = node._$meta.nodeId;
  if (!nodeId && node._$meta.extraMetadata) {
    // odata case.  refMap isn't really used, but is returned as data.retrievedEntities, so we populated it anyway.
    nodeId = node._$meta.extraMetadata.uriKey;
  }
  if (nodeId != null) {
    mc.refMap[nodeId] = targetEntity;
  }
}
// can return null for a deleted entity if includeDeleted == false
function mergeEntity(mc, node, meta) {
  node._$meta = meta;
  let em = mc.entityManager;
  let entityType = meta.entityType;
  if (typeof entityType === 'string') {
    entityType = mc.metadataStore._getStructuralType(entityType, false);
  }
  node.entityType = entityType;
  let mergeStrategy = mc.mergeOptions.mergeStrategy;
  let isSaving = mc.query == null;
  let entityKey = entityType.getEntityKeyFromRawEntity(node, mc.rawValueFn);
  let targetEntity = em.getEntityByKey(entityKey);
  if (targetEntity) {
    if (isSaving && targetEntity.entityAspect.entityState.isDeleted()) {
      em.detachEntity(targetEntity);
      return targetEntity;
    }
    let targetEntityState = targetEntity.entityAspect.entityState;
    if (mergeStrategy === MergeStrategy.Disallowed) {
      throw new Error("A MergeStrategy of 'Disallowed' prevents " + entityKey.toString() + " from being merged");
    } else if (mergeStrategy === MergeStrategy.SkipMerge) {
      updateEntityNoMerge(mc, targetEntity, node);
    } else {
      if (mergeStrategy === MergeStrategy.OverwriteChanges || targetEntityState.isUnchanged()) {
        updateEntity(mc, targetEntity, node);
        targetEntity.entityAspect.wasLoaded = true;
        if (meta.extraMetadata) {
          targetEntity.entityAspect.extraMetadata = meta.extraMetadata;
        }
        targetEntity.entityAspect.entityState = EntityState.Unchanged;
        clearOriginalValues$1(targetEntity);
        // propertyName not specified because multiple props EntityChangedEventArgs
        targetEntity.entityAspect.propertyChanged.publish({
          entity: targetEntity,
          propertyName: null
        });
        let action = isSaving ? EntityAction.MergeOnSave : EntityAction.MergeOnQuery;
        em.entityChanged.publish({
          entityAction: action,
          entity: targetEntity
        });
        // this is needed to handle an overwrite of a modified entity with an unchanged entity
        // which might in turn cause _hasChanges to change.
        if (!targetEntityState.isUnchanged()) {
          em._notifyStateChange(targetEntity, false);
        }
      } else {
        if (targetEntityState === EntityState.Deleted && !mc.mergeOptions.includeDeleted) {
          return null;
        }
        updateEntityNoMerge(mc, targetEntity, node);
      }
    }
  } else {
    targetEntity = entityType._createInstanceCore();
    updateEntity(mc, targetEntity, node);
    if (meta.extraMetadata) {
      targetEntity.entityAspect.extraMetadata = meta.extraMetadata;
    }
    // em._attachEntityCore(targetEntity, EntityState.Unchanged, MergeStrategy.Disallowed);
    em._attachEntityCore(targetEntity, EntityState.Unchanged, mergeStrategy);
    targetEntity.entityAspect.wasLoaded = true;
    em.entityChanged.publish({
      entityAction: EntityAction.AttachOnQuery,
      entity: targetEntity
    });
  }
  return targetEntity;
}
// copied from entityAspect
function clearOriginalValues$1(target) {
  let aspect = target.entityAspect || target.complexAspect;
  aspect.originalValues = {};
  let stype = target.entityType || target.complexType;
  stype.complexProperties.forEach(function (cp) {
    let cos = target.getProperty(cp.name);
    if (cp.isScalar) {
      clearOriginalValues$1(cos);
    } else {
      cos._acceptChanges();
      cos.forEach(clearOriginalValues$1);
    }
  });
}
function updateEntityNoMerge(mc, targetEntity, node) {
  updateEntityRef(mc, targetEntity, node);
  // we still need to merge related entities even if top level entity wasn't modified.
  node.entityType.navigationProperties.forEach(function (np) {
    if (np.isScalar) {
      mergeRelatedEntityCore(mc, node, np);
    } else {
      mergeRelatedEntitiesCore(mc, node, np);
    }
  });
}
function updateEntity(mc, targetEntity, node) {
  updateEntityRef(mc, targetEntity, node);
  let entityType = targetEntity.entityType;
  entityType._updateTargetFromRaw(targetEntity, node, mc.rawValueFn);
  entityType.navigationProperties.forEach(function (np) {
    if (np.isScalar) {
      mergeRelatedEntity(mc, np, targetEntity, node);
    } else {
      mergeRelatedEntities(mc, np, targetEntity, node);
    }
  });
}
function mergeRelatedEntity(mc, navigationProperty, targetEntity, rawEntity) {
  let relatedEntity = mergeRelatedEntityCore(mc, rawEntity, navigationProperty);
  if (relatedEntity == null) return;
  if (typeof relatedEntity === 'function') {
    mc.deferredFns.push(function () {
      relatedEntity = relatedEntity();
      updateRelatedEntity(relatedEntity, targetEntity, navigationProperty);
    });
  } else {
    updateRelatedEntity(relatedEntity, targetEntity, navigationProperty);
  }
}
function mergeRelatedEntities(mc, navigationProperty, targetEntity, rawEntity) {
  let relatedEntities = mergeRelatedEntitiesCore(mc, rawEntity, navigationProperty);
  if (relatedEntities == null) return;
  let inverseProperty = navigationProperty.inverse;
  if (!inverseProperty) return;
  let originalRelatedEntities = targetEntity.getProperty(navigationProperty.name);
  originalRelatedEntities.wasLoaded = true;
  relatedEntities.forEach(function (relatedEntity) {
    if (typeof relatedEntity === 'function') {
      mc.deferredFns.push(function () {
        relatedEntity = relatedEntity();
        updateRelatedEntityInCollection(mc, relatedEntity, originalRelatedEntities, targetEntity, inverseProperty);
      });
    } else {
      updateRelatedEntityInCollection(mc, relatedEntity, originalRelatedEntities, targetEntity, inverseProperty);
    }
  });
}
function mergeRelatedEntityCore(mc, rawEntity, navigationProperty) {
  let relatedRawEntity = rawEntity[navigationProperty.nameOnServer];
  if (!relatedRawEntity) return null;
  let relatedEntity = mc.visitAndMerge(relatedRawEntity, {
    nodeType: "navProp",
    navigationProperty: navigationProperty
  });
  return relatedEntity;
}
function mergeRelatedEntitiesCore(mc, rawEntity, navigationProperty) {
  let relatedRawEntities = rawEntity[navigationProperty.nameOnServer];
  if (!relatedRawEntities) return null;
  // needed if what is returned is not an array and we expect one - this happens with __deferred in OData.
  if (!Array.isArray(relatedRawEntities)) {
    // return null;
    relatedRawEntities = relatedRawEntities.results; // OData v3 will look like this with an expand
    if (!relatedRawEntities) {
      return null;
    }
  }
  let relatedEntities = mc.visitAndMerge(relatedRawEntities, {
    nodeType: "navPropItem",
    navigationProperty: navigationProperty
  });
  return relatedEntities;
}
function updateRelatedEntity(relatedEntity, targetEntity, navigationProperty) {
  if (!relatedEntity) return;
  let propName = navigationProperty.name;
  let currentRelatedEntity = targetEntity.getProperty(propName);
  // check if the related entity is already hooked up
  if (currentRelatedEntity !== relatedEntity) {
    // if not hook up both directions.
    targetEntity.setProperty(propName, relatedEntity);
    let inverseProperty = navigationProperty.inverse;
    if (!inverseProperty) return;
    if (inverseProperty.isScalar) {
      relatedEntity.setProperty(inverseProperty.name, targetEntity);
    } else {
      let collection = relatedEntity.getProperty(inverseProperty.name);
      collection.push(targetEntity);
    }
  }
}
function updateRelatedEntityInCollection(mc, relatedEntity, relatedEntities, targetEntity, inverseProperty) {
  if (!relatedEntity) return;
  // don't update relatedCollection if preserveChanges & relatedEntity has an fkChange.
  if (relatedEntity.entityAspect.entityState === EntityState.Modified && mc.mergeOptions.mergeStrategy === MergeStrategy.PreserveChanges) {
    let origValues = relatedEntity.entityAspect.originalValues;
    let fkWasModified = inverseProperty.relatedDataProperties.some(function (dp) {
      return origValues[dp.name] != undefined;
    });
    if (fkWasModified) return;
  }
  // check if the related entity is already hooked up
  let thisEntity = relatedEntity.getProperty(inverseProperty.name);
  if (thisEntity !== targetEntity) {
    // if not - hook it up.
    relatedEntities.push(relatedEntity);
    relatedEntity.setProperty(inverseProperty.name, targetEntity);
  }
}

/** @hidden @internal */
// Represents entities not yet attached to navigationProperties. 
class UnattachedChildrenMap {
  constructor() {
    // key is EntityKey.toString(), value is array of { navigationProperty, children }
    this.map = {};
  }
  addChild(parentEntityKey, navigationProperty, child) {
    let tuple = this.getTuple(parentEntityKey, navigationProperty);
    if (!tuple) {
      tuple = {
        navigationProperty: navigationProperty,
        children: []
      };
      core.getArray(this.map, parentEntityKey.toString()).push(tuple);
    }
    tuple.children.push(child);
  }
  removeChildren(parentEntityKeyString, navigationProperty) {
    let tuples = this.map[parentEntityKeyString];
    if (!tuples) return;
    core.arrayRemoveItem(tuples, t => {
      return t.navigationProperty === navigationProperty;
    });
    if (!tuples.length) {
      delete this.map[parentEntityKeyString];
    }
  }
  getTuple(parentEntityKey, navigationProperty) {
    let tuples = this.getTuples(parentEntityKey);
    if (!tuples) return null;
    let tuple = core.arrayFirst(tuples, function (t) {
      return t.navigationProperty === navigationProperty;
    });
    return tuple;
  }
  getTuples(parentEntityKey) {
    let allTuples = [];
    let tuples = this.map[parentEntityKey.toString()];
    if (tuples) {
      allTuples = allTuples.concat(tuples);
    }
    let entityType = parentEntityKey.entityType;
    while (entityType.baseEntityType) {
      entityType = entityType.baseEntityType;
      let baseKey = parentEntityKey.toString(entityType);
      tuples = this.map[baseKey];
      if (tuples) {
        allTuples = allTuples.concat(tuples);
      }
    }
    return allTuples.length ? allTuples : undefined;
  }
  getTuplesByString(parentEntityKeyString) {
    return this.map[parentEntityKeyString];
  }
}

/**
Instances of the EntityManager contain and manage collections of entities, either retrieved from a backend datastore or created on the client.
**/
class EntityManager {
  /**
  EntityManager constructor.
      At its most basic an EntityManager can be constructed with just a service name
  >     let entityManager = new EntityManager( "breeze/NorthwindIBModel");
      This is the same as calling it with the following configuration object
  >     let entityManager = new EntityManager( {serviceName: "breeze/NorthwindIBModel" });
      Usually however, configuration objects will contain more than just the 'serviceName';
  >     let metadataStore = new MetadataStore();
  >     let entityManager = new EntityManager( {
  >       serviceName: "breeze/NorthwindIBModel",
  >       metadataStore: metadataStore
  >     });
      or
  >     return new QueryOptions({
  >         mergeStrategy: obj,
  >         fetchStrategy: this.fetchStrategy
  >     });
  >     let queryOptions = new QueryOptions({
  >         mergeStrategy: MergeStrategy.OverwriteChanges,
  >         fetchStrategy: FetchStrategy.FromServer
  >     });
  >     let validationOptions = new ValidationOptions({
  >         validateOnAttach: true,
  >         validateOnSave: true,
  >         validateOnQuery: false
  >     });
  >     let entityManager = new EntityManager({
  >         serviceName: "breeze/NorthwindIBModel",
  >         queryOptions: queryOptions,
  >         validationOptions: validationOptions
  >     });
  @param emConfig - Configuration settings or a service name.
  **/
  constructor(emConfig) {
    this.helper = {
      unwrapInstance: unwrapInstance,
      unwrapOriginalValues: unwrapOriginalValues,
      unwrapChangedValues: unwrapChangedValues
    };
    if (arguments.length > 1) {
      throw new Error("The EntityManager ctor has a single optional argument that is either a 'serviceName' or a configuration object.");
    }
    let config;
    if (arguments.length === 0) {
      config = {
        serviceName: ""
      };
    } else if (typeof emConfig === 'string') {
      config = {
        serviceName: emConfig
      };
    } else {
      config = emConfig || {};
    }
    EntityManager._updateWithConfig(this, config, true);
    this.entityChanged = new BreezeEvent("entityChanged", this);
    this.validationErrorsChanged = new BreezeEvent("validationErrorsChanged", this);
    this.hasChangesChanged = new BreezeEvent("hasChangesChanged", this);
    this.clear();
  }
  /**
  General purpose property set method.  Any of the properties in the [[EntityManagerConfig]]
  may be set.
  >      // assume em1 is a previously created EntityManager
  >      // where we want to change some of its settings.
  >      em1.setProperties( {
  >          serviceName: "breeze/foo"
  >      });
  @param config - An object containing the selected properties and values to set.
  **/
  setProperties(config) {
    EntityManager._updateWithConfig(this, config, false);
  }
  /** @hidden @internal */
  static _updateWithConfig(em, config, isCtor) {
    let defaultQueryOptions = isCtor ? QueryOptions.defaultInstance : em.queryOptions;
    let defaultSaveOptions = isCtor ? SaveOptions.defaultInstance : em.saveOptions;
    let defaultValidationOptions = isCtor ? ValidationOptions.defaultInstance : em.validationOptions;
    let configParam = assertConfig(config).whereParam("serviceName").isOptional().isString().whereParam("dataService").isOptional().isInstanceOf(DataService).whereParam("queryOptions").isInstanceOf(QueryOptions).isOptional().withDefault(defaultQueryOptions).whereParam("saveOptions").isInstanceOf(SaveOptions).isOptional().withDefault(defaultSaveOptions).whereParam("validationOptions").isInstanceOf(ValidationOptions).isOptional().withDefault(defaultValidationOptions).whereParam("keyGeneratorCtor").isFunction().isOptional();
    if (isCtor) {
      configParam = configParam.whereParam("metadataStore").isInstanceOf(MetadataStore).isOptional().withDefault(new MetadataStore());
    }
    configParam.applyAll(em);
    // insure that entityManager's options versions are completely populated
    core.updateWithDefaults(em.queryOptions, defaultQueryOptions);
    core.updateWithDefaults(em.saveOptions, defaultSaveOptions);
    core.updateWithDefaults(em.validationOptions, defaultValidationOptions);
    if (config.serviceName) {
      em.dataService = new DataService({
        serviceName: em.serviceName
      });
    }
    em.serviceName = em.dataService && em.dataService.serviceName;
    em.keyGeneratorCtor = em.keyGeneratorCtor || KeyGenerator;
    if (isCtor || config.keyGeneratorCtor) {
      em.keyGenerator = new em.keyGeneratorCtor();
    }
  }
  /**
  Creates a new entity of a specified type and optionally initializes it. By default the new entity is created with an EntityState of Added
  but you can also optionally specify an EntityState.  An EntityState of 'Detached' will insure that the entity is created but not yet added
  to the EntityManager.
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      // create and add an entity;
  >      let emp1 = em1.createEntity("Employee");
  >      // create and add an initialized entity;
  >      let emp2 = em1.createEntity("Employee", { lastName: "Smith", firstName: "John" });
  >      // create and attach (not add) an initialized entity
  >      let emp3 = em1.createEntity("Employee", { id: 435, lastName: "Smith", firstName: "John" }, EntityState.Unchanged);
  >      // create but don't attach an entity;
  >      let emp4 = em1.createEntity("Employee", { id: 435, lastName: "Smith", firstName: "John" }, EntityState.Detached);
  @param typeName - The name of the EntityType for which an instance should be created.
  @param entityType - The EntityType of the type for which an instance should be created.
  @param initialValues - (default=null) Configuration object of the properties to set immediately after creation.
  @param entityState - (default = [[EntityState.Added]]) The EntityState of the entity after being created and added to this EntityManager.
  @param mergeStrategy - (default = [[MergeStrategy.Disallowed]]) - How to handle conflicts if an entity with the same key already exists within this EntityManager.
  @return {Entity} A new Entity of the specified type.
  */
  createEntity(entityType, initialValues, entityState, mergeStrategy) {
    assertParam(entityType, "entityType").isString().or().isInstanceOf(EntityType).check();
    assertParam(entityState, "entityState").isEnumOf(EntityState).isOptional().check();
    assertParam(mergeStrategy, "mergeStrategy").isEnumOf(MergeStrategy).isOptional().check();
    let et = typeof entityType === "string" ? this.metadataStore._getStructuralType(entityType) : entityType;
    entityState = entityState || EntityState.Added;
    let entity = {};
    core.using(this, "isLoading", true, function () {
      entity = et.createEntity(initialValues);
    });
    if (entityState !== EntityState.Detached) {
      entity = this.attachEntity(entity, entityState, mergeStrategy);
    }
    return entity;
  }
  /**
  Creates a new EntityManager and imports a previously exported result into it.
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let bundle = em1.exportEntities();
  >      // can be stored via the web storage api
  >      window.localStorage.setItem("myEntityManager", bundle);
  >      // assume the code below occurs in a different session.
  >      let bundleFromStorage = window.localStorage.getItem("myEntityManager");
  >      // and imported
  >      let em2 = EntityManager.importEntities(bundleFromStorage);
  >      // em2 will now have a complete copy of what was in em1
  @param exportedString - The result of a previous 'exportEntities' call as a string
  @param exportedData - The result of a previous 'exportEntities' call as an Object.
  @param config - A configuration object.
  @param config.mergeStrategy - A  [[MergeStrategy]] to use when
  merging into an existing EntityManager.
  @param config.metadataVersionFn - A function that takes two arguments (the current metadataVersion and the imported store's 'name')
  and may be used to perform version checking.
  @return A new EntityManager.  Note that the return value of this method call is different from that
  provided by the same named method on an EntityManager instance. Use that method if you need additional information
  regarding the imported entities.
  **/
  static importEntities(exported, config) {
    let em = new EntityManager();
    em.importEntities(exported, config);
    return em;
  }
  // instance methods
  /**
  Calls [[EntityAspect.acceptChanges]] on every changed entity in this EntityManager.
  **/
  acceptChanges() {
    this.getChanges().map(function (entity) {
      return entity.entityAspect._checkOperation("acceptChanges");
    }).forEach(function (aspect) {
      aspect.acceptChanges();
    });
  }
  /**
  Exports selected entities, all entities of selected types, or an entire EntityManager cache.
      This method takes a snapshot of an EntityManager that can be stored offline or held in memory.
  Use the [[EntityManager.importEntities]] method to restore or merge the snapshot
  into another EntityManager at some later time.
  >      // let em1 be an EntityManager containing a number of existing entities.
  >     // export every entity in em1.
  >     let bundle = em1.exportEntities();
  >     // save to the browser's local storage
  >     window.localStorage.setItem("myEntityManager", bundle);
  >     // later retrieve the export
  >     let bundleFromStorage = window.localStorage.getItem("myEntityManager");
  >     // import the retrieved export bundle into another manager
  >     let em2 = em1.createEmptyCopy();
  >     em2.importEntities(bundleFromStorage);
  >     // em2 now has a complete, faithful copy of the entities that were in em1
      You can also control exactly which entities are exported.
  >     // get em1's unsaved changes (an array) and export them.
  >     let changes = em1.getChanges();
  >     let bundle = em1.exportEntities(changes);
  >     // merge these entities into em2 which may contains some of the same entities.
  >     // do NOT overwrite the entities in em2 if they themselves have unsaved changes.
  >     em2.importEntities(bundle, { mergeStrategy: MergeStrategy.PreserveChanges} );
      Metadata are included in an export by default. You may want to exclude the metadata
  especially if you're exporting just a few entities for local storage.
  >     let bundle = em1.exportEntities(arrayOfSelectedEntities, {includeMetadata: false});
  >     window.localStorage.setItem("goodStuff", bundle);
      You may still express this option as a boolean value although this older syntax is deprecated.
  >     // Exclude the metadata (deprecated syntax)
  >     let bundle = em1.exportEntities(arrayOfSelectedEntities, false);
      You can export all entities of one or more specified EntityTypes.
  >     // Export all Customer and Employee entities (and also exclude metadata)
  >     let bundle = em1.exportEntities(['Customer', 'Employee'], {includeMetadata: false});
      All of the above examples return an export bundle as a string which is the default format.
  You can export the bundle as JSON if you prefer by setting the `asString` option to false.
  >     // Export all Customer and Employee entities as JSON and exclude the metadata
  >     let bundle = em1.exportEntities(['Customer', 'Employee'],
  >                                     {asString: false, includeMetadata: false});
  >     // store JSON bundle somewhere ... perhaps indexDb ... and later import as we do here.
  >     em2.importEntities(bundle);
  @param entities - The entities to export or the EntityType(s) of the entities to export;
    all entities are exported if this parameter is omitted or null.
  @param exportConfig - Export configuration options or a boolean
    - asString - (boolean) - If true (default), return export bundle as a string.
    - includeMetadata - (boolean) - If true (default), include metadata in the export bundle.
  @return The export bundle either serialized as a string (default) or as a JSON object.
  The bundle contains the metadata (unless excluded) and the entity data grouped by type.
  The entity data include property values, change-state, and temporary key mappings (if any).
      The export bundle internals are deliberately undocumented.  This Breeze-internal representation of entity data is
  suitable for export, storage, and import. The schema and contents of the bundle may change in future versions of Breeze.
  Manipulate it at your own risk with appropriate caution.
  **/
  exportEntities(entities, exportConfig) {
    assertParam(entities, "entities").isArray().isEntity().or().isNonEmptyArray().isInstanceOf(EntityType).or().isNonEmptyArray().isString().or().isOptional().check();
    // assertParam(exportConfig, "exportConfig").isObject()
    //   .or().isBoolean()
    //   .or().isOptional().check();
    if (exportConfig == null) {
      exportConfig = {
        includeMetadata: true,
        asString: true
      };
    } else if (typeof exportConfig === 'boolean') {
      // deprecated
      exportConfig = {
        includeMetadata: exportConfig,
        asString: true
      };
    }
    assertConfig(exportConfig).whereParam("asString").isBoolean().isOptional().withDefault(true).whereParam("includeMetadata").isBoolean().isOptional().withDefault(true).applyAll(exportConfig);
    let exportBundle = exportEntityGroups(this, entities);
    let json = core.extend({}, exportBundle, ["tempKeys", "entityGroupMap"]);
    if (exportConfig.includeMetadata) {
      json = core.extend(json, this, ["dataService", "saveOptions", "queryOptions", "validationOptions"]);
      json.metadataStore = this.metadataStore.exportMetadata();
    } else {
      json.metadataVersion = MetadataStore.metadataVersion;
      json.metadataStoreName = this.metadataStore.name;
    }
    let result = exportConfig.asString ? JSON.stringify(json, null, config.stringifyPad) : json;
    return result;
  }
  /**
  Imports a previously exported result into this EntityManager.
      This method can be used to make a complete copy of any previously created entityManager, even if created
  in a previous session and stored in localStorage. The static version of this method performs a
  very similar process.
  >     // assume em1 is an EntityManager containing a number of existing entities.
  >     let bundle = em1.exportEntities();
  >     // bundle can be stored in window.localStorage or just held in memory.
  >     let em2 = new EntityManager({
  >         serviceName: em1.serviceName,
  >         metadataStore: em1.metadataStore
  >     });
  >     em2.importEntities(bundle);
  >     // em2 will now have a complete copy of what was in em1
      It can also be used to merge the contents of a previously created EntityManager with an
  existing EntityManager with control over how the two are merged.
  >     let bundle = em1.exportEntities();
  >     // assume em2 is another entityManager containing some of the same entities possibly with modifications.
  >     em2.importEntities(bundle, { mergeStrategy: MergeStrategy.PreserveChanges} );
  >     // em2 will now contain all of the entities from both em1 and em2.  Any em2 entities with previously
  >     // made modifications will not have been touched, but all other entities from em1 will have been imported.
  @param exportedString - The result of a previous 'export' call.
  @param importConfig - A configuration object.
  @param importConfig.mergeStrategy -  A [[MergeStrategy]] to use when
  merging into an existing EntityManager.
  @param importConfig.metadataVersionFn - A function that takes two arguments (the current metadataVersion and the imported store's 'name')
  and may be used to perform version checking.
  @return result
    - result.entities {Array of Entities} The entities that were imported.
    - result.tempKeyMap {Object} Mapping from original EntityKey in the import bundle to its corresponding EntityKey in this EntityManager.
  **/
  importEntities(exported, importConfig) {
    importConfig = importConfig || {};
    assertConfig(importConfig).whereParam("mergeStrategy").isEnumOf(MergeStrategy).isOptional().withDefault(this.queryOptions.mergeStrategy).whereParam("metadataVersionFn").isFunction().isOptional().whereParam("mergeAdds").isBoolean().isOptional().applyAll(importConfig);
    let json = typeof exported === "string" ? JSON.parse(exported) : exported;
    if (json.metadataStore) {
      this.metadataStore.importMetadata(json.metadataStore);
      // the || clause is for backwards compat with an earlier serialization format.
      this.dataService = json.dataService && DataService.fromJSON(json.dataService) || new DataService({
        serviceName: json.serviceName
      });
      this.saveOptions = new SaveOptions(json.saveOptions);
      this.queryOptions = QueryOptions.fromJSON(json.queryOptions);
      this.validationOptions = new ValidationOptions(json.validationOptions);
    } else {
      importConfig.metadataVersionFn && importConfig.metadataVersionFn({
        metadataVersion: json.metadataVersion,
        metadataStoreName: json.metadataStoreName
      });
    }
    let tempKeyMap = {};
    json.tempKeys.forEach(k => {
      let oldKey = EntityKey.fromJSON(k, this.metadataStore);
      // try to use oldKey if not already used in this keyGenerator.
      tempKeyMap[oldKey.toString()] = new EntityKey(oldKey.entityType, this.keyGenerator.generateTempKeyValue(oldKey.entityType, oldKey.values[0]));
    });
    let entitiesToLink = [];
    let impConfig = importConfig;
    impConfig.tempKeyMap = tempKeyMap;
    core.wrapExecution(() => {
      this._pendingPubs = [];
    }, state => {
      this._pendingPubs.forEach(fn => fn());
      this._pendingPubs = undefined;
      this._hasChangesAction && this._hasChangesAction();
    }, () => {
      core.objectForEach(json.entityGroupMap, (entityTypeName, jsonGroup) => {
        let entityType = this.metadataStore._getStructuralType(entityTypeName, false);
        let targetEntityGroup = findOrCreateEntityGroup(this, entityType);
        let entities = importEntityGroup(targetEntityGroup, jsonGroup, impConfig);
        if (entities && entities.length) {
          entitiesToLink = entitiesToLink.concat(entities);
        }
      });
      entitiesToLink.forEach(entity => {
        if (!entity.entityAspect.entityState.isDeleted()) {
          this._linkRelatedEntities(entity);
        }
      });
    });
    return {
      entities: entitiesToLink,
      tempKeyMapping: tempKeyMap
    };
  }
  /**
  Clears this EntityManager's cache but keeps all other settings. Note that this
  method is not as fast as creating a new EntityManager via 'new EntityManager'.
  This is because clear actually detaches all of the entities from the EntityManager.
  >     // assume em1 is an EntityManager containing a number of existing entities.
  >     em1.clear();
  >     // em1 is will now contain no entities, but all other setting will be maintained.
  **/
  clear() {
    core.objectMap(this._entityGroupMap, function (key, entityGroup) {
      return entityGroup._checkOperation('clear');
    }).forEach(entityGroup => {
      entityGroup._clear();
    });
    this._entityGroupMap = {};
    this._unattachedChildrenMap = new UnattachedChildrenMap();
    this.keyGenerator = new this.keyGeneratorCtor();
    this.entityChanged.publish({
      entityAction: EntityAction.Clear
    });
    this._setHasChanges(false);
  }
  /**
  Creates an empty copy of this EntityManager but with the same DataService, MetadataStore, QueryOptions, SaveOptions, ValidationOptions, etc.
  >     // assume em1 is an EntityManager containing a number of existing entities.
  >     let em2 = em1.createEmptyCopy();
  >     // em2 is a new EntityManager with all of em1's settings
  >     // but no entities.
  @return A new EntityManager.
  **/
  createEmptyCopy() {
    let copy = new EntityManager(core.extend({}, this, ["dataService", "metadataStore", "queryOptions", "saveOptions", "validationOptions", "keyGeneratorCtor"]));
    return copy;
  }
  /**
  Attaches an entity to this EntityManager with an  [[EntityState]] of 'Added'.
  >     // assume em1 is an EntityManager containing a number of existing entities.
  >     let custType = em1.metadataStore.getEntityType("Customer");
  >     let cust1 = custType.createEntity();
  >     em1.addEntity(cust1);
      Note that this is the same as using 'attachEntity' with an [[EntityState]] of 'Added'.
      >     // assume em1 is an EntityManager containing a number of existing entities.
  >     let custType = em1.metadataStore.getEntityType("Customer");
  >     let cust1 = custType.createEntity();
  >     em1.attachEntity(cust1, EntityState.Added);
  @param entity - The entity to add.
  @return The added entity.
  **/
  addEntity(entity) {
    return this.attachEntity(entity, EntityState.Added);
  }
  /**
  Attaches an entity to this EntityManager with a specified [[EntityState]].
  >     // assume em1 is an EntityManager containing a number of existing entities.
  >     let custType = em1.metadataStore.getEntityType("Customer");
  >     let cust1 = custType.createEntity();
  >     em1.attachEntity(cust1, EntityState.Added);
  @param entity - The entity to add.
  @param entityState - (default=EntityState.Unchanged) The EntityState of the newly attached entity. If omitted this defaults to EntityState.Unchanged.
  @param mergeStrategy - (default = MergeStrategy.Disallowed) How the specified entity should be merged into the EntityManager if this EntityManager already contains an entity with the same key.
  @return The attached entity.
  **/
  attachEntity(entity, entityState, mergeStrategy) {
    assertParam(entity, "entity").isRequired().check();
    this.metadataStore._checkEntityType(entity);
    let esSymbol = assertParam(entityState, "entityState").isEnumOf(EntityState).isOptional().check(EntityState.Unchanged);
    let msSymbol = assertParam(mergeStrategy, "mergeStrategy").isEnumOf(MergeStrategy).isOptional().check(MergeStrategy.Disallowed);
    if (entity.entityType.metadataStore !== this.metadataStore) {
      throw new Error("Cannot attach this entity because the EntityType (" + entity.entityType.name + ") and MetadataStore associated with this entity does not match this EntityManager's MetadataStore.");
    }
    let aspect = entity.entityAspect;
    if (aspect) {
      // to avoid reattaching an entity in progress
      if (aspect._inProcessEntity) return aspect._inProcessEntity;
    } else {
      // this occur's when attaching an entity created via new instead of via createEntity.
      aspect = new EntityAspect(entity);
    }
    let manager = aspect.entityManager;
    if (manager) {
      if (manager === this) {
        return entity;
      } else {
        throw new Error("This entity already belongs to another EntityManager");
      }
    }
    let attachedEntity = {};
    core.using(this, "isLoading", true, () => {
      if (esSymbol.isAdded()) {
        checkEntityKey(this, entity);
      }
      // attachedEntity === entity EXCEPT in the case of a merge.
      attachedEntity = this._attachEntityCore(entity, esSymbol, msSymbol);
      aspect._inProcessEntity = attachedEntity;
      try {
        // entity ( not attachedEntity) is deliberate here.
        attachRelatedEntities(this, entity, esSymbol, msSymbol);
      } finally {
        // insure that _inProcessEntity is cleared.
        aspect._inProcessEntity = undefined;
      }
    });
    if (this.validationOptions.validateOnAttach) {
      attachedEntity.entityAspect.validateEntity();
    }
    if (!esSymbol.isUnchanged()) {
      this._notifyStateChange(attachedEntity, true);
    }
    this.entityChanged.publish({
      entityAction: EntityAction.Attach,
      entity: attachedEntity
    });
    return attachedEntity;
  }
  /**
  Detaches an entity from this EntityManager.
  >     // assume em1 is an EntityManager containing a number of existing entities.
  >     // assume cust1 is a customer Entity previously attached to em1
  >     em1.detachEntity(cust1);
  >     // em1 will now no longer contain cust1 and cust1 will have an
  >     // entityAspect.entityState of EntityState.Detached
  @param entity - The entity to detach.
  @return Whether the entity could be detached. This will return false if the entity is already detached or was never attached.
  **/
  detachEntity(entity) {
    assertParam(entity, "entity").isEntity().check();
    let aspect = entity.entityAspect;
    if (!aspect) {
      // no aspect means in couldn't appear in any group
      return false;
    }
    if (aspect.entityManager !== this) {
      throw new Error("This entity does not belong to this EntityManager.");
    }
    return aspect.setDetached();
  }
  /**
  Fetches the metadata associated with the EntityManager's current 'serviceName'.  This call
  occurs internally before the first query to any service if the metadata hasn't already been
  loaded. __Async__
      Usually you will not actually process the results of a fetchMetadata call directly, but will instead
  ask for the metadata from the EntityManager after the fetchMetadata call returns.
  >     let em1 = new EntityManager( "breeze/NorthwindIBModel");
  >     em1.fetchMetadata()
  >       .then(function() {
  >           let metadataStore = em1.metadataStore;
  >           // do something with the metadata
  >       }).catch(function(exception) {
  >           // handle exception here
  >       });
  
  @param callback - Function called on success.
  @param errorCallback - Function called on failure.
  @return {Promise}
    - schema {Object} The raw Schema object from metadata provider - Because this schema will differ depending on the metadata provider
        it is usually better to access metadata via the 'metadataStore' property of the EntityManager instead of using this 'raw' data.
  **/
  fetchMetadata(dataService, callback, errorCallback) {
    if (typeof dataService === "function") {
      // legacy support for when dataService was not an arg. i.e. first arg was callback
      errorCallback = callback;
      callback = dataService;
      dataService = undefined;
    } else {
      assertParam(dataService, "dataService").isInstanceOf(DataService).isOptional().check();
      assertParam(callback, "callback").isFunction().isOptional().check();
      assertParam(errorCallback, "errorCallback").isFunction().isOptional().check();
    }
    let promise = this.metadataStore.fetchMetadata(dataService || this.dataService);
    return promiseWithCallbacks(promise, callback, errorCallback);
  }
  /**
  Executes the specified query. __Async__
  
  >     let em = new EntityManager(serviceName);
  >     let query = new EntityQuery("Orders");
  >     em.executeQuery(query).then( function(data) {
  >         let orders = data.results;
  >         ... query results processed here
  >     }).catch( function(err) {
  >         ... query failure processed here
  >     });
      or with callbacks
  >     let em = new EntityManager(serviceName);
  >     let query = new EntityQuery("Orders");
  >     em.executeQuery(query,
  >         function(data) {
  >             let orders = data.results;
  >             ... query results processed here
  >         },
  >         function(err) {
  >             ... query failure processed here
  >         });
      Either way this method is the same as calling the The [[EntityQuery]] 'execute' method.
  >     let em = new EntityManager(serviceName);
  >     let query = new EntityQuery("Orders").using(em);
  >     query.execute().then( function(data) {
  >         let orders = data.results;
  >         ... query results processed here
  >     }).catch( function(err) {
  >         ... query failure processed here
  >     });
  @param query - The [[EntityQuery]] or OData query string to execute.
  @param callback - Function called on success.
  @param errorCallback - {Function} Function called on failure.
  @return Promise of
    - results - An array of entities
    - retrievedEntities - A array of all of the entities returned by the query.  Differs from results (above) when .expand() is used.
    - query - The original [[EntityQuery]] or query string
    - entityManager -  The EntityManager.
    - httpResponse - The [[IHttpResponse]] returned from the server.
    - inlineCount -  Only available if 'inlineCount(true)' was applied to the query.  Returns the count of
    items that would have been returned by the query before applying any skip or take operators, but after any filter/where predicates
    would have been applied.
  **/
  executeQuery(query, callback, errorCallback) {
    assertParam(query, "query").isInstanceOf(EntityQuery).or().isString().check();
    assertParam(callback, "callback").isFunction().isOptional().check();
    assertParam(errorCallback, "errorCallback").isFunction().isOptional().check();
    let promise;
    // 'resolve' methods create a new typed object with all of its properties fully resolved against a list of sources.
    // Thought about creating a 'normalized' query with these 'resolved' objects
    // but decided not to because the 'query' may not be an EntityQuery (it can be a string) and hence might not have a queryOptions or dataServices property on it.
    let queryOptions = QueryOptions.resolve([query.queryOptions, this.queryOptions, QueryOptions.defaultInstance]);
    let dataService = DataService.resolve([query.dataService, this.dataService]);
    if (!dataService.hasServerMetadata || this.metadataStore.hasMetadataFor(dataService.serviceName)) {
      promise = executeQueryCore(this, query, queryOptions, dataService);
    } else {
      promise = this.fetchMetadata(dataService).then(() => {
        return executeQueryCore(this, query, queryOptions, dataService);
      });
    }
    return promiseWithCallbacks(promise, callback, errorCallback);
  }
  /**
  Executes the specified query against this EntityManager's local cache.
      Because this method is executed immediately there is no need for a promise or a callback
  >     let em = new EntityManager(serviceName);
  >     let query = new EntityQuery("Orders");
  >     let orders = em.executeQueryLocally(query);
      Note that this can also be accomplished using the 'executeQuery' method with
  a FetchStrategy of FromLocalCache and making use of the Promise or callback
  >     let em = new EntityManager(serviceName);
  >     let query = new EntityQuery("Orders").using(FetchStrategy.FromLocalCache);
  >     em.executeQuery(query).then( function(data) {
  >         let orders = data.results;
  >         ... query results processed here
  >     }).catch( function(err) {
  >         ... query failure processed here
  >     });
  @param query - The [[EntityQuery]] to execute.
  @return  {Array of Entity}  Array of entities from cache that satisfy the query
  **/
  executeQueryLocally(query) {
    return executeQueryLocallyCore(this, query).results;
  }
  /**
  Saves either a list of specified entities or all changed entities within this EntityManager. If there are no changes to any of the entities
  specified then there will be no server side call made but a valid 'empty' saveResult will still be returned. __Async__
      Often we will be saving all of the entities within an EntityManager that are either added, modified or deleted
  and we will let the 'saveChanges' call determine which entities these are.
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      // This could include added, modified and deleted entities.
  >      em.saveChanges().then(function(saveResult) {
  >          let savedEntities = saveResult.entities;
  >          let keyMappings = saveResult.keyMappings;
  >      }).catch(function (e) {
  >          // e is any exception that was thrown.
  >      });
      But we can also control exactly which entities to save and can specify specific SaveOptions
      >      // assume entitiesToSave is an array of entities to save.
  >      let saveOptions = new SaveOptions({ allowConcurrentSaves: true });
  >      em.saveChanges(entitiesToSave, saveOptions).then(function(saveResult) {
  >          let savedEntities = saveResult.entities;
  >          let keyMappings = saveResult.keyMappings;
  >      }).catch(function (e) {
  >          // e is any exception that was thrown.
  >      });
      Callback methods can also be used
  >      em.saveChanges(entitiesToSave, null,
  >          function(saveResult) {
  >              let savedEntities = saveResult.entities;
  >              let keyMappings = saveResult.keyMappings;
  >          }, function (e) {
  >              // e is any exception that was thrown.
  >          }
  >      );
      @param entities - The list of entities to save.
  Every entity in that list will be sent to the server, whether changed or unchanged,
  as long as it is attached to this EntityManager.
  If this parameter is omitted, null or empty (the usual case),
  every entity with pending changes in this EntityManager will be saved.
  @param saveOptions - [[SaveOptions]] for the save - will default to
  [[EntityManager.saveOptions]] if null.
  @param callback -  Function called on success.
  @param errorCallback - Function called on failure.
  @return {Promise} Promise
  **/
  saveChanges(entities, saveOptions, callback, errorCallback) {
    assertParam(entities, "entities").isOptional().isArray().isEntity().check();
    assertParam(saveOptions, "saveOptions").isInstanceOf(SaveOptions).isOptional().check();
    assertParam(callback, "callback").isFunction().isOptional().check();
    assertParam(errorCallback, "errorCallback").isFunction().isOptional().check();
    saveOptions = saveOptions || this.saveOptions || SaveOptions.defaultInstance;
    let entitiesToSave = getEntitiesToSave(this, entities ? entities : undefined);
    if (entitiesToSave.length === 0) {
      let result = {
        entities: [],
        keyMappings: []
      };
      if (callback) callback(result);
      return Promise.resolve(result);
    }
    if (!saveOptions.allowConcurrentSaves) {
      let anyPendingSaves = entitiesToSave.some(function (entity) {
        return entity.entityAspect.isBeingSaved;
      });
      if (anyPendingSaves) {
        let err = new Error("Concurrent saves not allowed - SaveOptions.allowConcurrentSaves is false");
        if (errorCallback) errorCallback(err);
        return Promise.reject(err);
      }
    }
    clearServerErrors(entitiesToSave);
    let valError = this.saveChangesValidateOnClient(entitiesToSave);
    if (valError) {
      if (errorCallback) errorCallback(valError);
      return Promise.reject(valError);
    }
    let dataService = DataService.resolve([saveOptions.dataService, this.dataService]);
    let saveContext = {
      entityManager: this,
      dataService: dataService,
      processSavedEntities: processSavedEntities,
      resourceName: saveOptions.resourceName || this.saveOptions.resourceName || "SaveChanges"
    };
    // TODO: need to check that if we are doing a partial save that all entities whose temp keys
    // are referenced are also in the partial save group
    let saveBundle = {
      entities: entitiesToSave,
      saveOptions: saveOptions
    };
    try {
      // Guard against exception thrown in dataservice adapter before it goes async
      updateConcurrencyProperties(entitiesToSave);
      return dataService.adapterInstance.saveChanges(saveContext, saveBundle).then(saveSuccess).then(r => r, saveFail);
    } catch (err) {
      // undo the marking by updateConcurrencyProperties
      markIsBeingSaved(entitiesToSave, false);
      if (errorCallback) errorCallback(err);
      return Promise.reject(err);
    }
    function saveSuccess(saveResult) {
      let em = saveContext.entityManager;
      markIsBeingSaved(entitiesToSave, false);
      let savedEntities = saveContext.processSavedEntities(saveResult);
      saveResult.entities = savedEntities;
      // update _hasChanges after save.
      em._setHasChanges();
      // can't do this anymore because other changes might have been made while saved entities in flight.
      //      let hasChanges = (isFullSave && haveSameContents(entitiesToSave, savedEntities)) ? false : null;
      //      em._setHasChanges(hasChanges);
      if (callback) callback(saveResult);
      return Promise.resolve(saveResult);
    }
    function processSavedEntities(saveResult) {
      let savedEntities = saveResult.entities;
      let deletedKeys = saveResult.deletedKeys || [];
      if (savedEntities.length === 0 && deletedKeys.length === 0) {
        return [];
      }
      let keyMappings = saveResult.keyMappings;
      let em = saveContext.entityManager;
      // must occur outside of isLoading block
      fixupKeys(em, keyMappings);
      core.using(em, "isLoading", true, () => {
        let mappingContext = new MappingContext({
          query: undefined,
          entityManager: em,
          mergeOptions: {
            mergeStrategy: MergeStrategy.OverwriteChanges
          },
          dataService: dataService
        });
        // The visitAndMerge operation has been optimized so that we do not actually perform a merge if the
        // the save operation did not actually return the entity - i.e. during OData and Mongo updates and deletes.
        savedEntities = mappingContext.visitAndMerge(savedEntities, {
          nodeType: "root"
        });
      });
      // detach any entities found in the em that appear in the deletedKeys list. 
      deletedKeys.forEach(key => {
        let entityType = em.metadataStore._getStructuralType(key.entityTypeName);
        let ekey = new EntityKey(entityType, key.keyValues);
        let entity = em.getEntityByKey(ekey);
        if (entity) {
          entity.entityAspect.setDetached();
        }
      });
      return savedEntities;
    }
    function saveFail(serverError) {
      markIsBeingSaved(entitiesToSave, false);
      let clientError = processServerErrors(saveContext, serverError);
      if (errorCallback) errorCallback(clientError);
      return Promise.reject(clientError);
    }
  }
  /**
  Run the "saveChanges" pre-save client validation logic.
  
  This is NOT a general purpose validation method.
  It is intended for utilities that must know if saveChanges
  would reject the save due to client validation errors.
  
  It only validates entities if the EntityManager's
  [[ValidationOptions]].validateOnSave is true.
  
  @param entitiesToSave {Array of Entity} The list of entities to save (to validate).
  @return {Error} Validation error or null if no error
  **/
  saveChangesValidateOnClient(entitiesToSave) {
    if (this.validationOptions.validateOnSave) {
      let failedEntities = entitiesToSave.filter(function (entity) {
        let aspect = entity.entityAspect;
        let isValid = aspect.entityState.isDeleted() || aspect.validateEntity();
        return !isValid;
      });
      if (failedEntities.length > 0) {
        let valError = new Error("Client side validation errors encountered - see the entityErrors collection on this object for more detail");
        valError.entityErrors = createEntityErrors(failedEntities);
        return valError; // TODO: type this.
      }
    }
    return null;
  }
  /** @hidden @internal */
  _findEntityGroup(entityType) {
    return this._entityGroupMap[entityType.name];
  }
  /**
  Attempts to locate an entity within this EntityManager by its [EntityKey].
  @param entityKey - The [[EntityKey]] of the Entity to be located.
  @param type - The [[EntityType]] for this key.
  @param typeName - The EntityType name for this key.
  @param keyValues - The values for this key - will usually just be a single value; an array is only needed for multipart keys.
  @return An Entity or null;
  **/
  getEntityByKey(...args) {
    let entityKey = createEntityKey(this, args).entityKey;
    let entityTypes = entityKey._subtypes || [entityKey.entityType];
    let e;
    // hack use of some to simulate mapFirst logic.
    entityTypes.some(et => {
      let group = this._findEntityGroup(et);
      // group version of findEntityByKey doesn't care about entityType
      e = group && group.findEntityByKey(entityKey);
      return e != null;
    });
    return e || null;
  }
  /**
  Attempts to fetch an entity from the server by its [[EntityKey]] with
  an option to check the local cache first. Note the this EntityManager's queryOptions.mergeStrategy
  will be used to merge any server side entity returned by this method.
  >     // assume em1 is an EntityManager containing a number of preexisting entities.
  >     let employeeType = em1.metadataStore.getEntityType("Employee");
  >     let employeeKey = new EntityKey(employeeType, 1);
  >     em1.fetchEntityByKey(employeeKey).then(function(result) {
  >       let employee = result.entity;
  >       let entityKey = result.entityKey;
  >       let fromCache = result.fromCache;
  >     });
  @param typeName  - The EntityType name for this key.
  @param entityType  - The EntityType for this key.
  @param keyValues - The values for this key - will usually just be a single value; an array is only needed for multipart keys.
  @param entityKey - The [[EntityKey]] of the Entity to be located.
  @param checkLocalCacheFirst - (default = false) - Whether to check this EntityManager first before going to the server. By default, the query will NOT do this.
  @return {Promise}
    - Properties on the promise success result
      - entity {Object} The entity returned or null
      - entityKey {EntityKey} The entityKey of the entity to fetch.
      - fromCache {Boolean} Whether this entity was fetched from the server or was found in the local cache.
  **/
  fetchEntityByKey(...args) {
    let dataService = DataService.resolve([this.dataService]);
    if (!dataService.hasServerMetadata || this.metadataStore.hasMetadataFor(dataService.serviceName)) {
      return fetchEntityByKeyCore(this, args);
    } else {
      return this.fetchMetadata(dataService).then(() => {
        return fetchEntityByKeyCore(this, args);
      });
    }
  }
  /**
  [Deprecated] - Attempts to locate an entity within this EntityManager by its  [[EntityKey]].
  >     // assume em1 is an EntityManager containing a number of preexisting entities.
  >     let employeeType = em1.metadataStore.getEntityType("Employee");
  >     let employeeKey = new EntityKey(employeeType, 1);
  >     let employee = em1.findEntityByKey(employeeKey);
  >     // employee will either be an entity or null.
  @deprecated    Use getEntityByKey instead
  @param entityKey - The  [[EntityKey]] of the Entity to be located.
  @return An Entity or null;
  **/
  findEntityByKey(entityKey) {
    return this.getEntityByKey(entityKey);
  }
  /**
  Generates a temporary key for the specified entity.  This is used to insure that newly
  created entities have unique keys and to register that these keys are temporary and
  need to be automatically replaced with 'real' key values once these entities are saved.
  
  The [[EntityManager.keyGeneratorCtor]] property is used internally by this method to actually generate
  the keys - See the  KeyGenerator interface interface description to see
  how a custom key generator can be plugged in.
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let custType = em1.metadataStore.getEntityType("Customer");
  >      let customer = custType.createEntity();
  >      let customerId = em.generateTempKeyValue(customer);
  >      // The 'customer' entity 'CustomerID' property is now set to a newly generated unique id value
  >      // This property will change again after a successful save of the 'customer' entity.
  >
  >      em1.saveChanges().then( function( data) {
  >          let sameCust1 = data.results[0];
  >          // cust1 === sameCust1;
  >          // but cust1.getProperty("CustomerId") != customerId
  >          // because the server will have generated a new id
  >          // and the client will have been updated with this
  >          // new id.
  >      })
  @param entity - The Entity to generate a key for.
  @return The new key value
  **/
  generateTempKeyValue(entity) {
    // TODO - check if this entity is attached to this EntityManager.
    assertParam(entity, "entity").isEntity().check();
    let entityType = entity.entityType;
    let nextKeyValue = this.keyGenerator.generateTempKeyValue(entityType);
    let keyProp = entityType.keyProperties[0];
    entity.setProperty(keyProp.name, nextKeyValue);
    entity.entityAspect.hasTempKey = true;
    return nextKeyValue;
  }
  /**
  Returns whether there are any changed entities of the specified [[EntityType]]s. A 'changed' Entity has
  has an [[EntityState]] of either Added, Modified or Deleted.
      This method can be used to determine if an EntityManager has any changes
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      if ( em1.hasChanges() {
  >          // do something interesting
  >      }
      or if it has any changes on to a specific [[EntityType]].
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let custType = em1.metadataStore.getEntityType("Customer");
  >      if ( em1.hasChanges(custType) {
  >          // do something interesting
  >      }
      or to a collection of [[EntityType]]s
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let custType = em1.metadataStore.getEntityType("Customer");
  >      let orderType = em1.metadataStore.getEntityType("Order");
  >      if ( em1.hasChanges( [custType, orderType]) {
  >          // do something interesting
  >      }
  @param entityTypes - The [[EntityType]] or EntityTypes for which 'changed' entities will be found.
  @param entityTypeNames - The [[EntityType]] name or names for which 'changed' entities will be found.
  @return Whether there are any changed entities that match the types specified..
  **/
  hasChanges(entityTypes) {
    if (!this._hasChanges) return false;
    if (entityTypes === undefined) return this._hasChanges;
    return this._hasChangesCore(entityTypes);
  }
  /** @hidden @internal */
  // backdoor to "really" check for changes.
  _hasChangesCore(entityTypes) {
    let ets = checkEntityTypes(this, entityTypes);
    let entityGroups = getEntityGroups(this, ets);
    return entityGroups.some(function (eg) {
      return eg && eg.hasChanges();
    });
  }
  /**
  Returns a array of all changed entities of the specified [[EntityType]]s. A 'changed' Entity has
  has an [[EntityState]] of either Added, Modified or Deleted.
  
  This method can be used to get all of the changed entities within an EntityManager
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let changedEntities = em1.getChanges();
      or you can specify that you only want the changes on a specific [[EntityType]]
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let custType = em1.metadataStore.getEntityType("Customer");
  >      let changedCustomers = em1.getChanges(custType);
      or to a collection of [[EntityType]]s
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let custType = em1.metadataStore.getEntityType("Customer");
  >      let orderType = em1.metadataStore.getEntityType("Order");
  >      let changedCustomersAndOrders = em1.getChanges([custType, orderType]);
  @param entityTypes - The [[EntityType]] or EntityTypes for which 'changed' entities will be found.
  @param entityTypeNames - The [[EntityType]] name or names for which 'changed' entities will be found.
  @return An array of Entities
  **/
  getChanges(entityTypes) {
    let ets = checkEntityTypes(this, entityTypes);
    return getChangesCore(this, ets);
  }
  /**
  Rejects (reverses the effects) all of the additions, modifications and deletes from this EntityManager.
  Calls [[EntityAspect.rejectChanges]] on every changed entity in this EntityManager.
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let entities = em1.rejectChanges();
  @return The entities whose changes were rejected. These entities will all have EntityStates of
  either 'Unchanged' or 'Detached'
  **/
  rejectChanges() {
    if (!this._hasChanges) return [];
    let changes = getChangesCore(this);
    // next line stops individual reject changes from each calling _hasChangesCore
    let aspects = changes.map(function (e) {
      return e.entityAspect._checkOperation("rejectChanges");
    });
    this._hasChanges = false;
    aspects.forEach(function (aspect) {
      aspect.rejectChanges();
    });
    this.hasChangesChanged.publish({
      entityManager: this,
      hasChanges: false
    });
    return changes;
  }
  /**
  Returns a array of all entities of the specified [[EntityType]]s with the specified [[EntityState]]s.
      This method can be used to get all of the entities within an EntityManager
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let entities = em1.getEntities();
      or you can specify that you only want the changes on a specific [[EntityType]]
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let custType = em1.metadataStore.getEntityType("Customer");
  >      let customers = em1.getEntities(custType);
      or to a collection of [[EntityType]]s
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let custType = em1.metadataStore.getEntityType("Customer");
  >      let orderType = em1.metadataStore.getEntityType("Order");
  >      let customersAndOrders = em1.getChanges([custType, orderType]);
      You can also ask for entities with a particular [[EntityState]] or EntityStates.
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let custType = em1.metadataStore.getEntityType("Customer");
  >      let orderType = em1.metadataStore.getEntityType("Order");
  >      let addedCustomersAndOrders = em1.getEntities([custType, orderType], EntityState.Added);
  
  @param entityTypeName - The [[EntityType]] name or names for which entities will be found.
  If this parameter is omitted, all EntityTypes are searched.
  @param entityTypes - The [[EntityType]] or EntityTypes for which entities will be found.
  If this parameter is omitted, all EntityTypes are searched.
  @param entityStates - The [[EntityState]]s for which entities will be found.
  If this parameter is omitted, entities of all EntityStates are returned.
  @return An array of Entities
  **/
  getEntities(entityTypes, entityStates) {
    let entTypes = checkEntityTypes(this, entityTypes);
    assertParam(entityStates, "entityStates").isOptional().isEnumOf(EntityState).or().isNonEmptyArray().isEnumOf(EntityState).check();
    let states = validateEntityStates(this, entityStates);
    return getEntitiesCore(this, entTypes, states);
  }
  // protected methods
  /** @hidden @internal */
  _notifyStateChange(entity, needsSave) {
    let ecArgs = {
      entityAction: EntityAction.EntityStateChange,
      entity: entity
    };
    if (needsSave) {
      if (!this._hasChanges) this._setHasChanges(true);
    } else {
      // called when rejecting a change or merging an unchanged record.
      // NOTE: this can be slow with lots of entities in the cache.
      // so defer it during a query/import or save and call it once when complete ( if needed).
      if (this._hasChanges) {
        if (this.isLoading) {
          this._hasChangesAction = this._hasChangesAction || function () {
            this._setHasChanges(null);
            this.entityChanged.publish(ecArgs);
          }.bind(this);
          return;
        } else {
          this._setHasChanges();
        }
      }
    }
    this.entityChanged.publish(ecArgs);
  }
  /** @hidden @internal */
  _setHasChanges(hasChanges) {
    if (hasChanges == null) hasChanges = this._hasChangesCore();
    let hadChanges = this._hasChanges;
    this._hasChanges = hasChanges;
    if (hasChanges !== hadChanges) {
      this.hasChangesChanged.publish({
        entityManager: this,
        hasChanges: hasChanges
      });
    }
    this._hasChangesAction = undefined;
  }
  /** @hidden @internal */
  _linkRelatedEntities(entity) {
    let em = this;
    let entityAspect = entity.entityAspect;
    // we do not want entityState to change as a result of linkage.
    core.using(em, "isLoading", true, function () {
      let unattachedMap = em._unattachedChildrenMap;
      let entityKey = entityAspect.getKey();
      let entityType = entityKey.entityType;
      while (entityType) {
        let keystring = entityKey.toString(entityType);
        // attach any unattachedChildren
        let tuples = unattachedMap.getTuplesByString(keystring);
        if (tuples) {
          tuples.slice(0).forEach(function (tpl) {
            let unattachedChildren = tpl.children.filter(function (e) {
              return e.entityAspect.entityState !== EntityState.Detached;
            });
            let childToParentNp;
            let parentToChildNp;
            // np is usually childToParentNp
            // except with unidirectional 1-n where it is parentToChildNp;
            let np = tpl.navigationProperty;
            let inverseNp = np.inverse;
            if (inverseNp) {
              // bidirectional
              childToParentNp = np;
              parentToChildNp = inverseNp;
              if (parentToChildNp.isScalar) {
                let onlyChild = unattachedChildren[0];
                entity.setProperty(parentToChildNp.name, onlyChild);
                onlyChild.setProperty(childToParentNp.name, entity);
              } else {
                let currentChildren = entity.getProperty(parentToChildNp.name);
                unattachedChildren.forEach(function (child) {
                  currentChildren.push(child);
                  child.setProperty(childToParentNp.name, entity);
                });
              }
              unattachedMap.removeChildren(keystring, childToParentNp);
            } else {
              // unidirectional
              // if (np.isScalar || np.parentType !== entity.entityType) {
              if (np.isScalar) {
                // n -> 1  eg: child: OrderDetail parent: Product
                // 1 -> 1 eg child: Employee parent: Employee ( only Manager, no DirectReports property)
                childToParentNp = np;
                unattachedChildren.forEach(function (child) {
                  child.setProperty(childToParentNp.name, entity);
                });
                unattachedMap.removeChildren(keystring, childToParentNp);
              } else {
                // 1 -> n  eg: parent: Region child: Terr
                // TODO: need to remove unattached children from the map after this; only a perf issue.
                parentToChildNp = np;
                let currentChildren = entity.getProperty(parentToChildNp.name);
                unattachedChildren.forEach(function (child) {
                  // we know if can't already be there.
                  currentChildren._push(child);
                });
              }
            }
          });
        }
        entityType = entityType.baseEntityType; // look for relationships up the hierarchy
      }
      // now add to unattachedMap if needed.
      entity.entityType.navigationProperties.forEach(function (np) {
        if (np.isScalar) {
          let value = entity.getProperty(np.name);
          // property is already linked up
          if (value) return;
        }
        // first determine if np contains a parent or child
        // having a parentKey means that this is a child
        // if a parent then no need for more work because children will attach to it.
        let parentKey = entityAspect.getParentKey(np);
        if (parentKey) {
          // check for empty keys - meaning that parent id's are not yet set.
          if (parentKey._isEmpty()) return;
          // if a child - look for parent in the em cache
          if (np.invForeignKeyNames.length) {
            // np relates to non-PK property of parent entity
            const query = new EntityQuery(parentKey.entityType.defaultResourceName).where(np.invForeignKeyNames[0], 'eq', parentKey.values[0]);
            const qresult = em.executeQueryLocally(query);
            if (qresult.length === 1) {
              let parent = qresult[0];
              entity.setProperty(np.name, parent);
            }
          } else {
            // np relates to PK of parent entity
            let parent = em.getEntityByKey(parentKey);
            if (parent) {
              // if found hook it up
              entity.setProperty(np.name, parent);
            } else {
              // else add parent to unresolvedParentMap;
              unattachedMap.addChild(parentKey, np, entity);
            }
          }
        } else if (np.inverse && np.inverse.invForeignKeyNames.length) {
          // np relates to non-PK property of parent entity; query entities by FK
          const akValue = entity.getProperty(np.inverse.invForeignKeyNames[0]);
          const query = new EntityQuery(np.entityType.defaultResourceName).where(np.invForeignKeyNames[0], 'eq', akValue);
          const qresult = em.executeQueryLocally(query);
          qresult.forEach(child => {
            child.setProperty(np.inverse.name, entity);
          });
        }
      });
      // handle unidirectional 1-x where we set x.fk
      entity.entityType.foreignKeyProperties.forEach(function (fkProp) {
        let invNp = fkProp.inverseNavigationProperty;
        if (!invNp) return;
        // unidirectional fk props only
        let fkValue = entity.getProperty(fkProp.name);
        let parentKey = new EntityKey(invNp.parentType, [fkValue]);
        let parent = em.getEntityByKey(parentKey);
        if (parent) {
          if (invNp.isScalar) {
            parent.setProperty(invNp.name, entity);
          } else {
            if (em.isLoading) {
              parent.getProperty(invNp.name)._push(entity);
            } else {
              parent.getProperty(invNp.name).push(entity);
            }
          }
        } else {
          // else add parent to unresolvedParentMap;
          unattachedMap.addChild(parentKey, invNp, entity);
        }
      });
    });
  }
  /** @hidden @internal */
  _attachEntityCore(entity, entityState, mergeStrategy) {
    let group = findOrCreateEntityGroup(this, entity.entityType);
    let attachedEntity = group.attachEntity(entity, entityState, mergeStrategy);
    this._linkRelatedEntities(attachedEntity);
    return attachedEntity;
  }
  /** @hidden @internal */
  _updateFkVal(fkProp, oldValue, newValue) {
    let group = this._entityGroupMap[fkProp.parentType.name];
    if (!group) return;
    group._updateFkVal(fkProp, oldValue, newValue);
  }
}
EntityManager.prototype._$typeName = "EntityManager";
BreezeEvent.bubbleEvent(EntityManager.prototype);
function clearServerErrors(entities) {
  entities.forEach(function (entity) {
    let serverKeys = [];
    let aspect = entity.entityAspect;
    core.objectForEach(aspect._validationErrors, function (key, ve) {
      if (ve.isServerError) serverKeys.push(key);
    });
    if (serverKeys.length === 0) return;
    aspect._processValidationOpAndPublish(function () {
      serverKeys.forEach(function (key) {
        aspect._removeValidationError(key);
      });
    });
  });
}
function createEntityErrors(entities) {
  let entityErrors = [];
  entities.forEach(entity => {
    core.objectForEach(entity.entityAspect._validationErrors, function (key, ve) {
      let cfg = core.extend({
        entity: entity,
        errorName: ve.validator.name
      }, ve, ["errorMessage", "propertyName", "isServerError", "custom"]);
      entityErrors.push(cfg);
    });
  });
  return entityErrors;
}
function processServerErrors(saveContext, saveError) {
  // converting ISaveErrorFromServer -> ISaveError
  let serverErrors = saveError.entityErrors;
  if (!serverErrors) return saveError;
  let entityManager = saveContext.entityManager;
  let metadataStore = entityManager.metadataStore;
  let entityErrors = serverErrors.map(serr => {
    let entity = null;
    let entityType;
    if (serr.keyValues) {
      entityType = metadataStore._getStructuralType(serr.entityTypeName);
      let ekey = new EntityKey(entityType, serr.keyValues);
      entity = entityManager.getEntityByKey(ekey);
    }
    if (entityType && entity) {
      let context = serr.propertyName ? {
        propertyName: serr.propertyName,
        property: entityType.getProperty(serr.propertyName)
      } : {};
      let key = ValidationError.getKey(serr.errorName || serr.errorMessage, serr.propertyName);
      let ve = new ValidationError(null, context, serr.errorMessage, key);
      ve.isServerError = true;
      entity.entityAspect.addValidationError(ve);
    }
    let entityError = core.extend({
      entity: entity,
      isServerError: true
    }, serr, ["errorName", "errorMessage", "propertyName", "custom"]);
    return entityError;
  });
  // converting ISaveErrorFromServer -> ISaveError 
  saveError.entityErrors = entityErrors;
  return saveError;
}
function fetchEntityByKeyCore(em, args) {
  let tpl = createEntityKey(em, args);
  let entityKey = tpl.entityKey;
  let checkLocalCacheFirst = tpl.remainingArgs.length === 0 ? false : !!tpl.remainingArgs[0];
  let entity = null;
  let foundIt = false;
  if (checkLocalCacheFirst) {
    entity = em.getEntityByKey(entityKey);
    foundIt = entity != null;
    if (entity != null &&
    // null the entity if it is deleted and we should exclude deleted entities
    !em.queryOptions.includeDeleted && entity.entityAspect.entityState.isDeleted()) {
      entity = null;
      // but resume looking if we'd overwrite deleted entity with a remote entity
      // note: em.queryOptions is always fully resolved by now
      foundIt = em.queryOptions.mergeStrategy !== MergeStrategy.OverwriteChanges;
    }
  }
  if (foundIt) {
    return Promise.resolve({
      entity: entity || undefined,
      entityKey: entityKey,
      fromCache: true
    });
  } else {
    return EntityQuery.fromEntityKey(entityKey).using(em).execute().then(function (data) {
      entity = data.results.length === 0 ? null : data.results[0];
      return Promise.resolve({
        entity: entity || undefined,
        entityKey: entityKey,
        fromCache: false
      });
    });
  }
}
// private fns
// takes in entityTypes as either strings or entityTypes or arrays of either
// and returns either an entityType or an array of entityTypes or throws an error
function checkEntityTypes(em, entityTypes) {
  assertParam(entityTypes, "entityTypes").isString().isOptional().or().isNonEmptyArray().isString().or().isInstanceOf(EntityType).or().isNonEmptyArray().isInstanceOf(EntityType).check();
  let resultTypes;
  if (typeof entityTypes === "string") {
    resultTypes = em.metadataStore._getStructuralType(entityTypes, false);
  } else if (Array.isArray(entityTypes) && typeof entityTypes[0] === "string") {
    resultTypes = entityTypes.map(function (etName) {
      return em.metadataStore._getStructuralType(etName, false);
    });
  } else {
    resultTypes = entityTypes;
  }
  return resultTypes;
}
function getChangesCore(em, entityTypes) {
  let entityGroups = getEntityGroups(em, entityTypes);
  // TODO: think about writing a core.mapMany method if we see more of these.
  let selected = [];
  entityGroups.forEach(function (eg) {
    // eg may be undefined or null
    if (!eg) return;
    let entities = eg.getChanges();
    if (selected && selected.length) {
      selected = selected.concat(entities);
    } else {
      selected = entities;
    }
  });
  return selected;
}
function getEntitiesCore(em, entityTypes, entityStates) {
  let entityGroups = getEntityGroups(em, entityTypes);
  // TODO: think about writing a core.mapMany method if we see more of these.
  let selected = [];
  entityGroups.forEach(function (eg) {
    // eg may be undefined or null
    if (!eg) return;
    let entities = eg.getEntities(entityStates);
    if (selected && selected.length) {
      selected = selected.concat(entities);
    } else {
      selected = entities;
    }
  });
  return selected;
}
function createEntityKey(em, args) {
  try {
    if (args[0] instanceof EntityKey) {
      return {
        entityKey: args[0],
        remainingArgs: core.arraySlice(args, 1)
      };
    } else if (args.length >= 2) {
      let entityType = typeof args[0] === 'string' ? em.metadataStore._getStructuralType(args[0], false) : args[0];
      return {
        entityKey: new EntityKey(entityType, args[1]),
        remainingArgs: core.arraySlice(args, 2)
      };
    }
  } catch (e) {/* throw below */
    // throw new Error("Must supply an EntityKey OR an EntityType name or EntityType followed by a key value or an array of key values.");
  }
  throw new Error("Must supply an EntityKey OR an EntityType name or EntityType followed by a key value or an array of key values.");
}
function markIsBeingSaved(entities, flag) {
  entities.forEach(function (entity) {
    entity.entityAspect.isBeingSaved = flag;
  });
}
function exportEntityGroups(em, entitiesOrEntityTypes) {
  let entityGroupMap;
  let first = entitiesOrEntityTypes && entitiesOrEntityTypes[0];
  // check if array
  if (first) {
    // group entities by entityType and
    // create 'groups' that look like entityGroups.
    entityGroupMap = {};
    if (first.entityType) {
      let entities = entitiesOrEntityTypes;
      // assume "entities" is an array of entities;
      entities.forEach(function (e) {
        if (e.entityAspect.entityState === EntityState.Detached) {
          throw new Error("Unable to export an entity with an EntityState of 'Detached'");
        }
        let group = entityGroupMap[e.entityType.name];
        if (!group) {
          group = {};
          group.entityType = e.entityType;
          group._entities = [];
          entityGroupMap[e.entityType.name] = group;
        }
        group._entities.push(e);
      });
    } else {
      // assume "entities" is an array of EntityTypes (or names)
      let entityTypes = checkEntityTypes(em, entitiesOrEntityTypes);
      if (entityTypes != null) {
        entityTypes.forEach(et => {
          let group = em._entityGroupMap[et.name];
          if (group && group._entities.length) {
            entityGroupMap[et.name] = group;
          }
        });
      }
    }
  } else if (entitiesOrEntityTypes && entitiesOrEntityTypes.length === 0) {
    // empty array = export nothing
    entityGroupMap = {};
  } else {
    entityGroupMap = em._entityGroupMap;
  }
  let tempKeys = [];
  let newGroupMap = {};
  core.objectForEach(entityGroupMap, (entityTypeName, entityGroup) => {
    newGroupMap[entityTypeName] = exportEntityGroup(entityGroup, tempKeys);
  });
  return {
    entityGroupMap: newGroupMap,
    tempKeys: tempKeys
  };
}
function exportEntityGroup(entityGroup, tempKeys) {
  let resultGroup = {};
  let entityType = entityGroup.entityType;
  let dps = entityType.dataProperties;
  let serializerFn = getSerializerFn(entityType);
  let rawEntities = [];
  entityGroup._entities.forEach(entity => {
    if (entity) {
      let rawEntity = structuralObjectToJson(entity, dps, serializerFn, tempKeys);
      rawEntities.push(rawEntity);
    }
  });
  resultGroup.entities = rawEntities;
  return resultGroup;
}
function structuralObjectToJson(so, dps, serializerFn, tempKeys) {
  let result = {};
  dps.forEach(function (dp) {
    let dpName = dp.name;
    let value = so.getProperty(dpName);
    if (value == null && dp.defaultValue == null) return;
    if (value && dp.isComplexProperty) {
      let coDps = dp.dataType.dataProperties;
      value = core.map(value, function (v) {
        return structuralObjectToJson(v, coDps, serializerFn);
      });
    } else {
      value = serializerFn ? serializerFn(dp, value) : value;
      if (dp.isUnmapped) {
        value = core.toJSONSafe(value, core.toJSONSafeReplacer);
      }
    }
    if (value === undefined) return;
    result[dpName] = value;
  });
  // if (so.entityAspect) {
  if (EntityAspect.isEntity(so)) {
    let aspect = so.entityAspect;
    let entityState = aspect.entityState;
    let newAspect = {
      tempNavPropNames: exportTempKeyInfo(aspect, tempKeys || []),
      entityState: entityState.name
    };
    if (aspect.extraMetadata) {
      newAspect.extraMetadata = aspect.extraMetadata;
    }
    if (entityState.isModified() || entityState.isDeleted()) {
      newAspect.originalValuesMap = aspect.originalValues;
    }
    result.entityAspect = newAspect;
  } else {
    let aspect = so.complexAspect;
    let newAspect = {};
    if (aspect.originalValues && !core.isEmpty(aspect.originalValues)) {
      newAspect.originalValuesMap = aspect.originalValues;
    }
    result.complexAspect = newAspect;
  }
  return result;
}
function exportTempKeyInfo(entityAspect, tempKeys) {
  let entity = entityAspect.entity;
  if (entityAspect.hasTempKey) {
    tempKeys.push(entityAspect.getKey().toJSON());
  }
  // create map for this entity with foreignKeys that are 'temporary'
  // map -> key: tempKey, value: fkPropName
  let tempNavPropNames = [];
  entity.entityType.navigationProperties.forEach(function (np) {
    if (np.relatedDataProperties) {
      let relatedValue = entity.getProperty(np.name);
      if (relatedValue && relatedValue.entityAspect.hasTempKey) {
        tempNavPropNames.push(np.name);
      }
    }
  });
  return tempNavPropNames;
}
function importEntityGroup(entityGroup, jsonGroup, importConfig) {
  let tempKeyMap = importConfig.tempKeyMap;
  let mergeAdds = !!importConfig.mergeAdds;
  let entityType = entityGroup.entityType;
  let mergeStrategy = importConfig.mergeStrategy;
  let targetEntity;
  let em = entityGroup.entityManager;
  let entityChanged = em.entityChanged;
  let entitiesToLink = [];
  let rawValueFn = DataProperty.getRawValueFromClient;
  jsonGroup.entities.forEach(function (rawEntity) {
    let newAspect = rawEntity.entityAspect;
    let entityKey = entityType.getEntityKeyFromRawEntity(rawEntity, rawValueFn);
    let entityState = EntityState.fromName(newAspect.entityState);
    if (!entityState || entityState === EntityState.Detached) {
      throw new Error("Only entities with a non detached entity state may be imported.");
    }
    // Merge if raw entity is in cache UNLESS this is a new entity w/ a temp key
    // Cannot safely merge such entities even if could match temp key to an entity in cache.
    // Can enable merge of entities w/temp key using "mergeAdds" - use at your own risk!
    let newTempKey = !mergeAdds && entityState.isAdded() && getMappedKey(tempKeyMap, entityKey);
    targetEntity = newTempKey ? undefined : entityGroup.findEntityByKey(entityKey);
    if (targetEntity) {
      if (mergeStrategy === MergeStrategy.SkipMerge) {
        // deliberate fall thru
      } else if (mergeStrategy === MergeStrategy.Disallowed) {
        throw new Error("A MergeStrategy of 'Disallowed' prevents " + entityKey.toString() + " from being merged");
      } else {
        let targetEntityState = targetEntity.entityAspect.entityState;
        let wasUnchanged = targetEntityState.isUnchanged();
        if (mergeStrategy === MergeStrategy.OverwriteChanges || wasUnchanged) {
          entityType._updateTargetFromRaw(targetEntity, rawEntity, rawValueFn);
          targetEntity.entityAspect.setEntityState(entityState);
          entityChanged.publish({
            entityAction: EntityAction.MergeOnImport,
            entity: targetEntity
          });
        }
      }
    } else {
      targetEntity = entityType._createInstanceCore();
      entityType._updateTargetFromRaw(targetEntity, rawEntity, rawValueFn);
      if (newTempKey) {
        targetEntity.entityAspect.hasTempKey = true;
        // fixup pk
        targetEntity.setProperty(entityType.keyProperties[0].name, newTempKey.values[0]);
        // fixup foreign keys
        // This is safe because the entity is detached here and therefore originalValues will not be updated.
        if (newAspect.tempNavPropNames) {
          newAspect.tempNavPropNames.forEach(function (npName) {
            let np = entityType.getNavigationProperty(npName);
            let fkPropName = np.relatedDataProperties[0].name;
            let oldFkValue = targetEntity.getProperty(fkPropName);
            let fk = new EntityKey(np.entityType, [oldFkValue]);
            let newFk = getMappedKey(tempKeyMap, fk);
            targetEntity.setProperty(fkPropName, newFk.values[0]);
          });
        }
      }
      // Now performed in attachEntity
      targetEntity = entityGroup.attachEntity(targetEntity, entityState);
      entityChanged.publish({
        entityAction: EntityAction.AttachOnImport,
        entity: targetEntity
      });
      if (!entityState.isUnchanged()) {
        em._notifyStateChange(targetEntity, true);
      }
    }
    entitiesToLink.push(targetEntity);
  });
  return entitiesToLink;
}
function getMappedKey(tempKeyMap, entityKey) {
  let newKey = tempKeyMap[entityKey.toString()];
  if (newKey) return newKey;
  let subtypes = entityKey._subtypes;
  if (!subtypes) return null;
  for (let i = 0, j = subtypes.length; i < j; i++) {
    newKey = tempKeyMap[entityKey.toString(subtypes[i])];
    if (newKey) return newKey;
  }
  return null;
}
function promiseWithCallbacks(promise, callback, errorCallback) {
  promise = promise.then(function (data) {
    if (callback) callback(data);
    return Promise.resolve(data);
  }, function (error) {
    if (errorCallback) errorCallback(error);
    return Promise.reject(error);
  });
  return promise;
}
function getEntitiesToSave(em, entities) {
  let entitiesToSave;
  if (entities) {
    entitiesToSave = entities.filter(function (e) {
      if (e.entityAspect.entityManager !== em) {
        throw new Error("Only entities in this entityManager may be saved");
      }
      return !e.entityAspect.entityState.isDetached();
    });
  } else {
    entitiesToSave = em.getChanges();
  }
  return entitiesToSave;
}
function fixupKeys(em, keyMappings) {
  em._inKeyFixup = true;
  keyMappings.forEach(function (km) {
    let group = em._entityGroupMap[km.entityTypeName];
    if (!group) {
      throw new Error("Unable to locate the following fully qualified EntityType name: " + km.entityTypeName);
    }
    group._fixupKey(km.tempValue, km.realValue);
  });
  em._inKeyFixup = false;
}
function getEntityGroups(em, entityTypes) {
  let groupMap = em._entityGroupMap;
  if (entityTypes) {
    return core.toArray(entityTypes).map(function (et) {
      if (et instanceof EntityType) {
        return groupMap[et.name];
      } else {
        throw new Error("The EntityManager.getChanges() 'entityTypes' parameter must be either an entityType or an array of entityTypes or null");
      }
    });
  } else {
    return core.getOwnPropertyValues(groupMap);
  }
}
function checkEntityKey(em, entity) {
  let ek = entity.entityAspect.getKey();
  // return properties that are = to defaultValues
  let keyPropsWithDefaultValues = core.arrayZip(entity.entityType.keyProperties, ek.values, function (kp, kv) {
    return kp.defaultValue === kv ? kp : null;
  }).filter(function (kp) {
    return kp !== null;
  });
  if (keyPropsWithDefaultValues.length) {
    if (entity.entityType.autoGeneratedKeyType !== AutoGeneratedKeyType.None) {
      em.generateTempKeyValue(entity);
    } else {
      // we will allow attaches of entities where only part of the key is set.
      if (keyPropsWithDefaultValues.length === ek.values.length) {
        throw new Error("Cannot attach an object of type  (" + entity.entityType.name + ") to an EntityManager without first setting its key or setting its entityType 'AutoGeneratedKeyType' property to something other than 'None'");
      }
    }
  }
}
function validateEntityStates(em, entityStates) {
  if (!entityStates) return [];
  let entStates = core.toArray(entityStates);
  entStates.forEach(es => {
    if (!(es instanceof EntityState)) {
      throw new Error("The EntityManager.getChanges() 'entityStates' parameter must either be null, an entityState or an array of entityStates");
    }
  });
  return entStates;
}
function attachRelatedEntities(em, entity, entityState, mergeStrategy) {
  let navProps = entity.entityType.navigationProperties;
  navProps.forEach(function (np) {
    let related = entity.getProperty(np.name);
    if (np.isScalar) {
      if (!related) return;
      em.attachEntity(related, entityState, mergeStrategy);
    } else {
      related.forEach(function (e) {
        em.attachEntity(e, entityState, mergeStrategy);
      });
    }
  });
}
// returns a promise
function executeQueryCore(em, query, queryOptions, dataService) {
  try {
    let results;
    let metadataStore = em.metadataStore;
    if (metadataStore.isEmpty() && dataService.hasServerMetadata) {
      throw new Error("cannot execute _executeQueryCore until metadataStore is populated.");
    }
    if (queryOptions.fetchStrategy === FetchStrategy.FromLocalCache) {
      try {
        if (typeof query === 'string') {
          throw new Error("cannot execute 'string' EntityQuery locally.");
        }
        let qr = executeQueryLocallyCore(em, query);
        return Promise.resolve({
          results: qr.results,
          entityManager: em,
          inlineCount: qr.inlineCount,
          query: query
        });
      } catch (e) {
        return Promise.reject(e);
      }
    }
    let mappingContext = new MappingContext({
      query: query,
      entityManager: em,
      dataService: dataService,
      mergeOptions: {
        mergeStrategy: queryOptions.mergeStrategy,
        noTracking: !!query.noTrackingEnabled,
        includeDeleted: queryOptions.includeDeleted
      }
    });
    let validateOnQuery = em.validationOptions.validateOnQuery;
    return dataService.adapterInstance.executeQuery(mappingContext).then(function (data) {
      let result = core.wrapExecution(function () {
        let state = {
          isLoading: em.isLoading
        };
        em.isLoading = true;
        em._pendingPubs = [];
        return state;
      }, function (state) {
        // cleanup
        em.isLoading = state.isLoading;
        em._pendingPubs.forEach(function (fn) {
          fn();
        });
        em._pendingPubs = undefined;
        em._hasChangesAction && em._hasChangesAction();
        // TODO: removed - not sure why needed in first place...
        // // HACK for GC
        // query = undefined;
        mappingContext = undefined;
        // HACK: some errors thrown in next function do not propogate properly - this catches them.
        if (state.error) {
          return Promise.reject(state.error);
        }
      }, function () {
        let nodes = dataService.jsonResultsAdapter.extractResults(data);
        nodes = core.toArray(nodes);
        results = mappingContext.visitAndMerge(nodes, {
          nodeType: "root"
        });
        if (validateOnQuery) {
          results.forEach(function (r) {
            // anon types and simple types will not have an entityAspect.
            r.entityAspect && r.entityAspect.validateEntity();
          });
        }
        mappingContext.processDeferred();
        // if query has expand clauses walk each of the 'results' and mark the expanded props as loaded.
        if (query instanceof EntityQuery) {
          markLoadedNavProps(results, query);
        }
        let retrievedEntities = core.objectMap(mappingContext.refMap);
        return {
          results: results,
          query: query,
          entityManager: em,
          httpResponse: data.httpResponse,
          inlineCount: data.inlineCount,
          retrievedEntities: retrievedEntities
        };
      });
      return Promise.resolve(result);
    }, function (e) {
      if (e) {
        e.query = query;
        e.entityManager = em;
      }
      return Promise.reject(e);
    });
  } catch (e) {
    if (e) {
      e.query = query;
    }
    return Promise.reject(e);
  }
}
function markLoadedNavProps(entities, query) {
  if (query.noTrackingEnabled) return;
  let expandClause = query.expandClause;
  if (expandClause == null) return;
  expandClause.propertyPaths.forEach(function (propertyPath) {
    let propNames = propertyPath.split('.');
    markLoadedNavPath(entities, propNames);
  });
}
function markLoadedNavPath(entities, propNames) {
  let propName = propNames[0];
  entities.forEach(entity => {
    let ea = entity.entityAspect;
    if (!ea) return; // entity may not be a 'real' entity in the case of a projection.
    ea._markAsLoaded(propName);
    if (propNames.length === 1) return;
    let next = entity.getProperty(propName);
    if (!next) return; // no children to process.
    // strange logic because nonscalar nav values are NOT really arrays
    // otherwise we could use Array.isArray
    if (!next.arrayChanged) next = [next];
    markLoadedNavPath(next, propNames.slice(1));
  });
}
function updateConcurrencyProperties(entities) {
  let candidates = entities.filter(e => {
    e.entityAspect.isBeingSaved = true;
    return e.entityAspect.entityState.isModified() && e.entityType.concurrencyProperties.length > 0;
  });
  if (candidates.length === 0) return;
  candidates.forEach(function (c) {
    c.entityType.concurrencyProperties.forEach(function (cp) {
      updateConcurrencyProperty(c, cp);
    });
  });
}
function updateConcurrencyProperty(entity, property) {
  // check if property has already been updated
  if (entity.entityAspect.originalValues[property.name]) return;
  let value = entity.getProperty(property.name);
  let dataType = property.dataType;
  if (!value) value = dataType.defaultValue;
  if (dataType.isNumeric) {
    entity.setProperty(property.name, value + 1);
  } else if (dataType.getConcurrencyValue) {
    // DataType has its own implementation
    let nextValue = dataType.getConcurrencyValue(value);
    entity.setProperty(property.name, nextValue);
  } else if (dataType === DataType.Binary) {
    // best guess - that this is a timestamp column and is computed on the server during save
    // - so no need to set it here.
    return;
  } else {
    // this just leaves DataTypes of Boolean, String and Byte - none of which should be the
    // type for a concurrency column.
    // NOTE: thought about just returning here but would rather be safe for now.
    throw new Error("Unable to update the value of concurrency property before saving: " + property.name);
  }
}
function findOrCreateEntityGroup(em, entityType) {
  let group = em._entityGroupMap[entityType.name];
  if (!group) {
    group = new EntityGroup(em, entityType);
    em._entityGroupMap[entityType.name] = group;
  }
  return group;
}
function findOrCreateEntityGroups(em, entityType) {
  let entityTypes = entityType.getSelfAndSubtypes();
  return entityTypes.map(et => {
    return findOrCreateEntityGroup(em, et);
  });
}
function unwrapInstance(structObj, transformFn) {
  let rawObject = {};
  let stype = EntityAspect.isEntity(structObj) ? structObj.entityType : structObj.complexType;
  let serializerFn = getSerializerFn(stype);
  let unmapped = {};
  stype.dataProperties.forEach(function (dp) {
    if (dp.isComplexProperty) {
      rawObject[dp.nameOnServer] = core.map(structObj.getProperty(dp.name), function (co) {
        return unwrapInstance(co, transformFn);
      });
    } else {
      let val = structObj.getProperty(dp.name);
      val = transformFn ? transformFn(dp, val) : val;
      if (val === undefined) return;
      val = serializerFn ? serializerFn(dp, val) : val;
      if (val !== undefined) {
        if (dp.isUnmapped) {
          unmapped[dp.nameOnServer] = core.toJSONSafe(val, core.toJSONSafeReplacer);
        } else {
          rawObject[dp.nameOnServer] = val;
        }
      }
    }
  });
  if (!core.isEmpty(unmapped)) {
    // TODO: review this.
    rawObject.__unmapped = unmapped;
  }
  return rawObject;
}
function unwrapOriginalValues(target, metadataStore, transformFn) {
  let stype = EntityAspect.isEntity(target) ? target.entityType : target.complexType;
  let aspect = EntityAspect.isEntity(target) ? target.entityAspect : target.complexAspect;
  let fn = metadataStore.namingConvention.clientPropertyNameToServer;
  let result = {};
  core.objectForEach(aspect.originalValues, function (propName, val) {
    let prop = stype.getProperty(propName);
    val = transformFn ? transformFn(prop, val) : val;
    if (val !== undefined) {
      result[fn(propName, prop)] = val;
    }
  });
  stype.complexProperties.forEach(function (cp) {
    let nextTarget = target.getProperty(cp.name);
    if (cp.isScalar) {
      let unwrappedCo = unwrapOriginalValues(nextTarget, metadataStore, transformFn);
      if (!core.isEmpty(unwrappedCo)) {
        result[fn(cp.name, cp)] = unwrappedCo;
      }
    } else {
      let unwrappedCos = nextTarget.map(item => {
        return unwrapOriginalValues(item, metadataStore, transformFn);
      });
      result[fn(cp.name, cp)] = unwrappedCos;
    }
  });
  return result;
}
function unwrapChangedValues(entity, metadataStore, transformFn) {
  let stype = entity.entityType;
  let serializerFn = getSerializerFn(stype);
  let fn = metadataStore.namingConvention.clientPropertyNameToServer;
  let result = {};
  core.objectForEach(entity.entityAspect.originalValues, function (propName, value) {
    let prop = stype.getProperty(propName);
    let val = entity.getProperty(propName);
    val = transformFn ? transformFn(prop, val) : val;
    if (val === undefined) return;
    val = serializerFn ? serializerFn(prop, val) : val;
    if (val !== undefined) {
      result[fn(propName, prop)] = val;
    }
  });
  // any change to any complex object or array of complex objects returns the ENTIRE
  // current complex object or complex object array.  This is by design. Complex Objects
  // are atomic.
  stype.complexProperties.forEach(cp => {
    if (cpHasOriginalValues(entity, cp)) {
      let coOrCos = entity.getProperty(cp.name);
      result[fn(cp.name, cp)] = core.map(coOrCos, function (co) {
        return unwrapInstance(co, transformFn);
      });
    }
  });
  return result;
}
function cpHasOriginalValues(structuralObject, cp) {
  let coOrCos = structuralObject.getProperty(cp.name);
  if (cp.isScalar) {
    return coHasOriginalValues(coOrCos);
  } else {
    // this occurs when a nonscalar co array has had cos added or removed.
    if (coOrCos._origValues) return true;
    return coOrCos.some(function (co) {
      return coHasOriginalValues(co);
    });
  }
}
function executeQueryLocallyCore(em, query) {
  assertParam(query, "query").isInstanceOf(EntityQuery).check();
  let metadataStore = em.metadataStore;
  let entityType = query._getFromEntityType(metadataStore, true);
  // there may be multiple groups is this is a base entity type.
  let groups = findOrCreateEntityGroups(em, entityType);
  // filter then order then skip then take
  let filterFunc = query.wherePredicate && query.wherePredicate.toFunction({
    entityType: entityType
  });
  let queryOptions = QueryOptions.resolve([query.queryOptions, em.queryOptions, QueryOptions.defaultInstance]);
  let includeDeleted = queryOptions.includeDeleted === true;
  let newFilterFunc = function (entity) {
    return entity && (includeDeleted || !entity.entityAspect.entityState.isDeleted()) && (filterFunc ? filterFunc(entity) : true);
  };
  let result = [];
  // TODO: mapMany
  groups.forEach(group => {
    let entities = group._entities.filter(newFilterFunc);
    if (entities.length) {
      result = result.length ? result.concat(entities) : entities;
    }
  });
  let orderByComparer = query.orderByClause && query.orderByClause.getComparer(entityType);
  if (orderByComparer) {
    result.sort(orderByComparer);
  }
  let inlineCount = query.inlineCountEnabled ? result.length : undefined;
  let skipCount = query.skipCount;
  if (skipCount) {
    result = result.slice(skipCount);
  }
  let takeCount = query.takeCount;
  if (takeCount) {
    result = result.slice(0, takeCount);
  }
  let selectClause = query.selectClause;
  if (selectClause) {
    let selectFn = selectClause.toFunction();
    result = result.map(selectFn);
  }
  return {
    results: result,
    inlineCount: inlineCount
  };
}
function coHasOriginalValues(co) {
  // next line checks all non complex properties of the co.
  if (!core.isEmpty(co.complexAspect.originalValues)) return true;
  // now need to recursively check each of the cps
  return co.complexType.complexProperties.some(function (cp) {
    return cpHasOriginalValues(co, cp);
  });
}
function getSerializerFn(stype) {
  return stype.serializerFn || stype.metadataStore && stype.metadataStore.serializerFn;
}

/** Registers adapters used by Breeze */
class InterfaceRegistry {
  constructor() {
    this.ajax = new InterfaceDef("ajax");
    this.modelLibrary = new InterfaceDef("modelLibrary");
    this.dataService = new InterfaceDef("dataService");
    this.uriBuilder = new InterfaceDef("uriBuilder");
  }
}
config.interfaceRegistry = new InterfaceRegistry();
config._interfaceRegistry = config.interfaceRegistry;
config.interfaceRegistry.modelLibrary.getDefaultInstance = function () {
  if (!this.defaultInstance) {
    throw new Error("Unable to locate the default implementation of the '" + this.name + "' interface.  Possible options are 'ko', 'backingStore' or 'backbone'. See the breeze.config.initializeAdapterInstances method.");
  }
  return this.defaultInstance;
};
/**
Initializes a collection of adapter implementations and makes each one the default for its corresponding interface.
@method initializeAdapterInstances
@param config {Object}
@param [config.ajax] {String} - the name of a previously registered "ajax" adapter
@param [config.dataService] {String} - the name of a previously registered "dataService" adapter
@param [config.modelLibrary] {String} - the name of a previously registered "modelLibrary" adapter
@param [config.uriBuilder] {String} - the name of a previously registered "uriBuilder" adapter
@return [array of instances]
**/
config.initializeAdapterInstances = function (irConfig) {
  assertConfig(irConfig).whereParam("dataService").isOptional().whereParam("modelLibrary").isOptional().whereParam("ajax").isOptional().whereParam("uriBuilder").isOptional().applyAll(this, false);
  return core.objectMap(config, this.initializeAdapterInstance);
};
const ɵ0$3 = function (...args) {
    if (this._inProgress) {
      return -1;
    }
    let goodAdds = this._getGoodAdds(args);
    if (!goodAdds.length) {
      return this.length;
    }
    this._beforeChange();
    let result;
    let objPrototype = Object.getPrototypeOf(this);
    if (objPrototype.push) {
      result = objPrototype.push.apply(this, goodAdds);
    } else {
      result = Array.prototype.push.apply(this, goodAdds);
    }
    processAdds(this, goodAdds);
    return result;
  },
  ɵ1$2 = function (...args) {
    if (this._inProgress) {
      return -1;
    }
    let goodAdds = args;
    this._beforeChange();
    let result;
    let objPrototype = Object.getPrototypeOf(this);
    if (objPrototype.push) {
      result = objPrototype.push.apply(this, goodAdds);
    } else {
      result = Array.prototype.push.apply(this, goodAdds);
    }
    processAdds(this, goodAdds);
    return result;
  },
  ɵ2$1 = function (...args) {
    let goodAdds = this._getGoodAdds(args);
    if (!goodAdds.length) {
      return this.length;
    }
    this._beforeChange();
    let result;
    let objPrototype = Object.getPrototypeOf(this);
    if (objPrototype.unshift) {
      result = objPrototype.unshift.apply(this, goodAdds);
    } else {
      result = Array.prototype.unshift.apply(this, goodAdds);
    }
    processAdds(this, goodAdds);
    return result;
  },
  ɵ3$1 = function () {
    this._beforeChange();
    let result;
    let objPrototype = Object.getPrototypeOf(this);
    if (objPrototype.pop) {
      result = objPrototype.pop.apply(this);
    } else {
      result = Array.prototype.pop.apply(this);
    }
    processRemoves(this, [result]);
    return result;
  },
  ɵ4$1 = function () {
    this._beforeChange();
    let result;
    let objPrototype = Object.getPrototypeOf(this);
    if (objPrototype.shift) {
      result = objPrototype.shift.apply(this);
    } else {
      result = Array.prototype.shift.apply(this);
    }
    processRemoves(this, [result]);
    return result;
  },
  ɵ5$1 = function (...args) {
    let goodAdds = this._getGoodAdds(core.arraySlice(args, 2));
    let newArgs = core.arraySlice(args, 0, 2).concat(goodAdds);
    this._beforeChange();
    let result;
    let objPrototype = Object.getPrototypeOf(this);
    if (objPrototype.splice) {
      result = objPrototype.splice.apply(this, newArgs);
    } else {
      result = Array.prototype.splice.apply(this, newArgs);
    }
    processRemoves(this, result);
    if (goodAdds.length) {
      processAdds(this, goodAdds);
    }
    return result;
  },
  ɵ6$1 = function () {
    return this.parent.entityAspect || this.parent.complexAspect.getEntityAspect();
  },
  ɵ7$1 = function () {
    return this.getEntityAspect();
  },
  ɵ8$1 = function () {
    let em = this.getEntityAspect().entityManager;
    return em && em._pendingPubs;
  },
  ɵ9$1 = function () {
    // default is to do nothing
  };
let mixin = {
  push: ɵ0$3,
  _push: ɵ1$2,
  unshift: ɵ2$1,
  pop: ɵ3$1,
  shift: ɵ4$1,
  splice: ɵ5$1,
  getEntityAspect: ɵ6$1,
  _getEventParent: ɵ7$1,
  _getPendingPubs: ɵ8$1,
  _beforeChange: ɵ9$1
};
function updateEntityState(obsArray) {
  let entityAspect = obsArray.getEntityAspect();
  if (entityAspect.entityState.isUnchanged()) {
    entityAspect.setModified();
  }
  if (entityAspect.entityState.isModified() && !obsArray._origValues) {
    obsArray._origValues = obsArray.slice(0);
  }
}
function publish(publisher, eventName, eventArgs) {
  let pendingPubs = publisher._getPendingPubs();
  if (pendingPubs) {
    if (!publisher._pendingArgs) {
      publisher._pendingArgs = eventArgs;
      pendingPubs.push(function () {
        publisher[eventName].publish(publisher._pendingArgs);
        publisher._pendingArgs = null;
      });
    } else {
      combineArgs(publisher._pendingArgs, eventArgs);
    }
  } else {
    publisher[eventName].publish(eventArgs);
  }
}
function initializeParent(obsArray, parent, parentProperty) {
  obsArray.parent = parent;
  obsArray.parentProperty = parentProperty;
}
function processAdds(obsArray, adds) {
  obsArray._processAdds(adds);
  // this is referencing the name of the method on the complexArray not the name of the event
  //var args = { added: adds };
  //args[obsArray._typeName] = obsArray;
  publish(obsArray, "arrayChanged", {
    array: obsArray,
    added: adds
  });
}
function processRemoves(obsArray, removes) {
  obsArray._processRemoves(removes);
  // this is referencing the name of the method on the array not the name of the event
  publish(obsArray, "arrayChanged", {
    array: obsArray,
    removed: removes
  });
}
// TODO: see if this function already exists in core and can be imported.
function combineArgs(target, source) {
  for (let key in source) {
    if (key !== "array" && target.hasOwnProperty(key)) {
      let sourceValue = source[key];
      let targetValue = target[key];
      if (targetValue) {
        if (!Array.isArray(targetValue)) {
          throw new Error("Cannot combine non array args");
        }
        Array.prototype.push.apply(targetValue, sourceValue);
      } else {
        target[key] = sourceValue;
      }
    }
  }
}
/** @hidden @internal */
const observableArray = {
  mixin: mixin,
  updateEntityState: updateEntityState,
  publish: publish,
  initializeParent: initializeParent
};
const ɵ0$4 = function (callback, errorCallback) {
    let parent = this.parentEntity;
    let query = EntityQuery.fromEntityNavigation(this.parentEntity, this.navigationProperty);
    let em = parent.entityAspect.entityManager;
    return em.executeQuery(query, callback, errorCallback);
  },
  ɵ1$3 = function () {
    return this.parentEntity.entityAspect;
  },
  ɵ2$2 = function () {
    let em = this.parentEntity.entityAspect.entityManager;
    return em && em._pendingPubs;
  },
  ɵ3$2 = function (adds) {
    return getGoodAdds(this, adds);
  },
  ɵ4$2 = function (adds) {
    processAdds$1(this, adds);
  },
  ɵ5$2 = function (removes) {
    processRemoves$1(this, removes);
  };
let relationArrayMixin = {
  /**
  Relation arrays are not actually classes, they are objects that mimic arrays. A relation array is collection of
  entities associated with a navigation property on a single entity. i.e. customer.orders or order.orderDetails.
  This collection looks like an array in that the basic methods on arrays such as 'push', 'pop', 'shift', 'unshift', 'splice'
  are all provided as well as several special purpose methods.
  @class {relationArray}
  **/
  /**
  An [[Event]] that fires whenever the contents of this array changed.  This event
  is fired any time a new entity is attached or added to the EntityManager and happens to belong to this collection.
  Adds that occur as a result of query or import operations are batched so that all of the adds or removes to any individual
  collections are collected into a single notification event for each relation array.
  @example
      // assume order is an order entity attached to an EntityManager.
      orders.arrayChanged.subscribe(
      function (arrayChangedArgs) {
          let addedEntities = arrayChangedArgs.added;
          let removedEntities = arrayChanged.removed;
      });
  @event arrayChanged
  @param added {Array of Entity} An array of all of the entities added to this collection.
  @param removed {Array of Entity} An array of all of the removed from this collection.
  @readOnly
  **/
  /**
  Performs an asynchronous load of all other the entities associated with this relationArray.
  @example
      // assume orders is an empty, as yet unpopulated, relation array of orders
      // associated with a specific customer.
      orders.load().then(...)
  @method load
  @param [callback] {Function}
  @param [errorCallback] {Function}
  @return {Promise}
  **/
  load: ɵ0$4,
  _getEventParent: ɵ1$3,
  _getPendingPubs: ɵ2$2,
  // virtual impls
  _getGoodAdds: ɵ3$2,
  _processAdds: ɵ4$2,
  _processRemoves: ɵ5$2
};
function getGoodAdds(relationArray, adds) {
  let goodAdds = checkForDups(relationArray, adds);
  if (!goodAdds.length) {
    return goodAdds;
  }
  let parentEntity = relationArray.parentEntity;
  let entityManager = parentEntity.entityAspect.entityManager;
  // we do not want to attach an entity during loading
  // because these will all be 'attached' at a later step.
  if (entityManager && !entityManager.isLoading) {
    goodAdds.forEach(function (add) {
      if (add.entityAspect.entityState.isDetached()) {
        relationArray._inProgress = true;
        try {
          entityManager.attachEntity(add, EntityState.Added);
        } finally {
          relationArray._inProgress = false;
        }
      }
    });
  }
  return goodAdds;
}
function processAdds$1(relationArray, adds) {
  let parentEntity = relationArray.parentEntity;
  let np = relationArray.navigationProperty;
  let addsInProcess = relationArray._addsInProcess;
  let invNp = np.inverse;
  let startIx = addsInProcess.length;
  try {
    adds.forEach(function (childEntity) {
      addsInProcess.push(childEntity);
      if (invNp) {
        childEntity.setProperty(invNp.name, parentEntity);
      } else {
        // This occurs with a unidirectional 1-n navigation - in this case
        // we need to update the fks instead of the navProp
        let pks = parentEntity.entityType.keyProperties;
        np.invForeignKeyNames.forEach(function (fk, i) {
          childEntity.setProperty(fk, parentEntity.getProperty(pks[i].name));
        });
      }
    });
  } finally {
    addsInProcess.splice(startIx, adds.length);
  }
}
function processRemoves$1(relationArray, removes) {
  let inp = relationArray.navigationProperty.inverse;
  if (inp) {
    removes.forEach(function (childEntity) {
      childEntity.setProperty(inp.name, null);
    });
  }
}
function checkForDups(relationArray, adds) {
  // don't allow dups in this array. - also prevents recursion
  let parentEntity = relationArray.parentEntity;
  let navProp = relationArray.navigationProperty;
  let inverseProp = navProp.inverse;
  let goodAdds;
  if (inverseProp) {
    goodAdds = adds.filter(function (a) {
      if (relationArray._addsInProcess.indexOf(a) >= 0) {
        return false;
      }
      let inverseValue = a.getProperty(inverseProp.name);
      return inverseValue !== parentEntity;
    });
  } else {
    // This occurs with a unidirectional 1->N relation ( where there is no n -> 1)
    // in this case we compare fks.
    let fkPropNames = navProp.invForeignKeyNames;
    let keyProps = parentEntity.entityType.keyProperties;
    goodAdds = adds.filter(function (a) {
      if (relationArray._addsInProcess.indexOf(a) >= 0) {
        return false;
      }
      return fkPropNames.some(function (fk, i) {
        let keyProp = keyProps[i].name;
        let keyVal = parentEntity.getProperty(keyProp);
        let fkVal = a.getProperty(fk);
        return keyVal !== fkVal;
      });
    });
  }
  return goodAdds;
}
/** For use by breeze plugin authors only. The class is for use in building a [[IModelLibraryAdapter]] implementation.
@adapter (see [[IModelLibraryAdapter]])
@hidden
*/
function makeRelationArray(arr, parentEntity, navigationProperty) {
  let arrX = arr;
  arrX.parentEntity = parentEntity;
  arrX.navigationProperty = navigationProperty;
  arrX.arrayChanged = new BreezeEvent("arrayChanged", arrX);
  // array of pushes currently in process on this relation array - used to prevent recursion.
  arrX._addsInProcess = [];
  // need to use mixins here instead of inheritance because we are starting from an existing array object.
  core.extend(arrX, observableArray.mixin);
  return core.extend(arrX, relationArrayMixin);
}
const ɵ0$5 = function (adds) {
    return getGoodAdds$1(this, adds);
  },
  ɵ1$4 = function () {
    observableArray.updateEntityState(this);
  },
  ɵ2$3 = function (adds) {
    processAdds$2(this, adds);
  },
  ɵ3$3 = function (removes) {
    processRemoves$2(this, removes);
  },
  ɵ4$3 = function () {
    if (!this._origValues) return;
    let that = this;
    this.forEach(function (co) {
      clearAspect(co, that);
    });
    this.length = 0;
    this._origValues.forEach(function (co) {
      that.push(co);
    });
  },
  ɵ5$3 = function () {
    this._origValues = null;
  };
let complexArrayMixin = {
  // complexArray will have the following props
  //    parent
  //    propertyPath
  //    parentProperty
  //    addedItems  - only if modified
  //    removedItems  - only if modified
  //  each complexAspect of any entity within a complexArray
  //  will have its own _complexState = "A/M";
  /**
   Complex arrays are not actually classes, they are objects that mimic arrays. A complex array is collection of
   complexTypes associated with a data property on a single entity or other complex object. i.e. customer.orders or order.orderDetails.
   This collection looks like an array in that the basic methods on arrays such as 'push', 'pop', 'shift', 'unshift', 'splice'
   are all provided as well as several special purpose methods.
   @class {complexArray}
   **/
  /**
  An [[Event]] that fires whenever the contents of this array changed.  This event
  is fired any time a new entity is attached or added to the EntityManager and happens to belong to this collection.
  Adds that occur as a result of query or import operations are batched so that all of the adds or removes to any individual
  collections are collected into a single notification event for each relation array.
  @example
      // assume order is an order entity attached to an EntityManager.
      orders.arrayChanged.subscribe(
      function (arrayChangedArgs) {
          var addedEntities = arrayChangedArgs.added;
          var removedEntities = arrayChanged.removed;
      });
  @event arrayChanged
  @param added {Array of Entity} An array of all of the entities added to this collection.
  @param removed {Array of Entity} An array of all of the removed from this collection.
  @readOnly
  **/
  // virtual impls
  _getGoodAdds: ɵ0$5,
  _beforeChange: ɵ1$4,
  _processAdds: ɵ2$3,
  _processRemoves: ɵ3$3,
  _rejectChanges: ɵ4$3,
  _acceptChanges: ɵ5$3
};
// local functions
function getGoodAdds$1(complexArray, adds) {
  // remove any that are already added here
  return adds.filter(function (a) {
    // return a.parent !== complexArray.parent;  // TODO: check if this is actually a bug in original breezejs ???
    return a.complexAspect == null || a.complexAspect.parent !== complexArray.parent;
  });
}
function processAdds$2(complexArray, adds) {
  adds.forEach(function (a) {
    // if (a.parent != null) { // TODO: check if this is actually a bug in original breezejs ???
    if (a.complexAspect && a.complexAspect.parent != null) {
      throw new Error("The complexObject is already attached. Either clone it or remove it from its current owner");
    }
    setAspect(a, complexArray);
  });
}
function processRemoves$2(complexArray, removes) {
  removes.forEach(function (a) {
    clearAspect(a, complexArray);
  });
}
function clearAspect(co, arr) {
  let coAspect = co.complexAspect;
  // if not already attached - exit
  if (coAspect.parent !== arr.parent) return null;
  coAspect.parent = undefined;
  coAspect.parentProperty = undefined;
  return coAspect;
}
function setAspect(co, arr) {
  let coAspect = co.complexAspect;
  // if already attached - exit
  if (coAspect.parent === arr.parent) return null;
  coAspect.parent = arr.parent;
  coAspect.parentProperty = arr.parentProperty;
  return coAspect;
}
/** For use by breeze plugin authors only. The class is for use in building a [[IModelLibraryAdapter]] implementation.
@adapter (see [[IModelLibraryAdapter]])
@hidden
*/
function makeComplexArray(arr, parent, parentProperty) {
  let arrX = arr;
  observableArray.initializeParent(arrX, parent, parentProperty);
  arrX.arrayChanged = new BreezeEvent("arrayChanged", arrX);
  core.extend(arrX, observableArray.mixin);
  return core.extend(arrX, complexArrayMixin);
}
const ɵ0$6 = function (adds) {
    return adds;
  },
  ɵ1$5 = function () {
    let entityAspect = this.getEntityAspect();
    if (entityAspect.entityState.isUnchanged()) {
      entityAspect.setModified();
    }
    if (entityAspect.entityState.isModified() && !this._origValues) {
      this._origValues = this.slice(0);
    }
  },
  ɵ2$4 = function (adds) {
    // nothing needed
  },
  ɵ3$4 = function (removes) {
    // nothing needed;
  },
  ɵ4$4 = function () {
    if (!this._origValues) return;
    this.length = 0;
    Array.prototype.push.apply(this, this._origValues);
  },
  ɵ5$4 = function () {
    this._origValues = null;
  };
// TODO: mixin impl is not very typesafe
// Not needed
// interface IPrimitiveArray extends IObservableArray {
//   [index: number]: any;
//   parent?: IStructuralObject;
//   parentProperty?: DataProperty;
// }
let primitiveArrayMixin = {
  // complexArray will have the following props
  //    parent
  //    propertyPath
  //    parentProperty
  //    addedItems  - only if modified
  //    removedItems  - only if modified
  //  each complexAspect of any entity within a complexArray
  //  will have its own _complexState = "A/M";
  /**
  Primitive arrays are not actually classes, they are objects that mimic arrays. A primitive array is collection of
  primitive types associated with a data property on a single entity or complex object. i.e. customer.invoiceNumbers.
  This collection looks like an array in that the basic methods on arrays such as 'push', 'pop', 'shift', 'unshift', 'splice'
  are all provided as well as several special purpose methods.
  @class {primitiveArray}
  **/
  /**
  An [[Event]] that fires whenever the contents of this array changed.  This event
  is fired any time a new entity is attached or added to the EntityManager and happens to belong to this collection.
  Adds that occur as a result of query or import operations are batched so that all of the adds or removes to any individual
  collections are collected into a single notification event for each relation array.
  @example
      // assume order is an order entity attached to an EntityManager.
      orders.arrayChanged.subscribe(
      function (arrayChangedArgs) {
          let addedEntities = arrayChangedArgs.added;
          let removedEntities = arrayChanged.removed;
      });
  @event arrayChanged
  @param added {Array of Primitives} An array of all of the items added to this collection.
  @param removed {Array of Primitives} An array of all of the items removed from this collection.
  @readOnly
  **/
  // virtual impls
  _getGoodAdds: ɵ0$6,
  _beforeChange: ɵ1$5,
  _processAdds: ɵ2$4,
  _processRemoves: ɵ3$4,
  _rejectChanges: ɵ4$4,
  _acceptChanges: ɵ5$4
};
// local functions
/** For use by breeze plugin authors only. The class is for use in building a [[IModelLibraryAdapter]] implementation.
@adapter (see [[IModelLibraryAdapter]])
@hidden
*/
function makePrimitiveArray(arr, parent, parentProperty) {
  let arrX = arr;
  observableArray.initializeParent(arrX, parent, parentProperty);
  arrX.arrayChanged = new BreezeEvent("arrayChanged", arrX);
  core.extend(arrX, observableArray.mixin);
  return core.extend(arrX, primitiveArrayMixin);
}

// create a breeze variable here
const breeze = {
  AbstractDataServiceAdapter: AbstractDataServiceAdapter,
  assertConfig: null,
  assertParam: null,
  AutoGeneratedKeyType: AutoGeneratedKeyType,
  BooleanQueryOp: BooleanQueryOp,
  ComplexAspect: ComplexAspect,
  ComplexType: ComplexType,
  config: config,
  core: core,
  DataProperty: DataProperty,
  DataService: DataService,
  DataType: DataType,
  EntityAction: EntityAction,
  EntityAspect: EntityAspect,
  EntityKey: EntityKey,
  EntityManager: EntityManager,
  EntityQuery: EntityQuery,
  EntityState: EntityState,
  EntityType: EntityType,
  Event: BreezeEvent,
  FetchStrategy: FetchStrategy,
  FilterQueryOp: FilterQueryOp,
  InterfaceRegistry: InterfaceRegistry,
  JsonResultsAdapter: JsonResultsAdapter,
  KeyGenerator: KeyGenerator,
  LocalQueryComparisonOptions: LocalQueryComparisonOptions,
  makeComplexArray: makeComplexArray,
  makePrimitiveArray: makePrimitiveArray,
  makeRelationArray: makeRelationArray,
  MergeStrategy: MergeStrategy,
  MetadataStore: MetadataStore,
  NamingConvention: NamingConvention,
  NavigationProperty: NavigationProperty,
  OrderByClause: OrderByClause,
  Param,
  Predicate: Predicate,
  QueryOptions: QueryOptions,
  SaveOptions: SaveOptions,
  ValidationError: ValidationError,
  ValidationOptions: ValidationOptions,
  Validator: Validator,
  version: "2.1.5"
};
/** @hidden @internal */
let win;
try {
  win = window ? window : global ? global.window : undefined;
} catch (e) {}
if (win) {
  win.breeze = breeze;
}

/**
 * Generated bundle index. Do not edit.
 */

export { AbstractDataServiceAdapter, AndOrPredicate, AnyAllPredicate, AutoGeneratedKeyType, BinaryPredicate, BreezeConfig, BreezeEnum, BreezeEvent, ComplexAspect, ComplexType, DataProperty, DataService, DataType, EntityAction, EntityAspect, EntityKey, EntityManager, EntityQuery, EntityState, EntityType, ExpandClause, FetchStrategy, FilterQueryOp, FnExpr, InterfaceRegistry, JsonResultsAdapter, KeyGenerator, LitExpr, LocalQueryComparisonOptions, MappingContext, MergeStrategy, MetadataStore, NamingConvention, NavigationProperty, OrderByClause, Predicate, PropExpr, QueryOptions, SaveOptions, SelectClause, UnaryPredicate, ValidationError, ValidationOptions, Validator, assertConfig, assertParam, breeze, config, core, makeComplexArray, makePrimitiveArray, makeRelationArray, PredicateExpression as ɵa, Param as ɵb, qualifyTypeName as ɵc, BooleanQueryOp as ɵd };
