import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'dialog-report', templateUrl: './dialog.report.html',
})
export class ReportFileTypeSelection implements OnInit {
    constructor(public activeModal: NgbActiveModal) { }

    @Input() template: TemplateRef<any>;
    @Input() params: { [key: string]: any };

    ngOnInit() {
        if (!this.params) { this.params = {} }
    }

    cancel() { this.activeModal.dismiss() }
    getReport(fileType: string) {
        this.activeModal.close({ params: this.params, exportFileType: fileType })
    }
}