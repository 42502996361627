import { isInteger, toInteger, isNumber, padNumber } from '../util';

export interface DateStruct {
  year: number; /** The year, for example 2016 */
  month: number; /** The month, for example 1=Jan ... 12=Dec */
  day: number; /** The day of month, starting at 1 */
}

export class CustomDate implements DateStruct {
  constructor(date?: string | number | Date | null | any, month?: number, day?: number) {
    if (date) {
      this.setDate(date && month && day ? date : { year: date, month: month, day: day })
    }
  }

  date: Date;
  lastDate: string;
  private lastSymbol: string;

  private noYear: boolean;
  private noMonth: boolean;
  private noDay: boolean;
  private noHours: boolean;
  private noMinutes: boolean;
  private noSecond: boolean;

  get year(): number { return this.noYear === null ? null : this.date.getFullYear() }
  get month(): number { return this.noMonth === null ? null : this.date.getMonth() + 1 }
  get day(): number { return this.noDay === null ? null : this.date.getDate() }
  get hours(): number { return this.noHours === null ? null : this.date.getHours() }
  get minutes(): number { return this.noMinutes === null ? null : this.date.getMinutes() }
  get second(): number { return this.noSecond === null ? null : this.date.getSeconds() }

  set year(val) { }
  set month(val) { }
  set day(val) { }
  set minutes(val) { }
  set hours(val) { }
  set second(val) { }

  static from(date?: string | number | DateStruct | Date | null): CustomDate | null {
    if (date === null) { return null }
    if (date instanceof CustomDate) { return date }
    if (date instanceof Date) { return new CustomDate(date) }
    if (typeof date == 'number' || typeof date == 'string') { return new CustomDate(date) }
  }

  setDate(date: string | number | Date | DateStruct | null) {
    if (date === null) { this.date = new Date(null) }
    else if (date instanceof Date) { this.date = date }
    else if (typeof date == 'number' || typeof date == 'string') {
      this.date = new Date(date)
    } else {
      this.date = new Date(date.year || null, date.month ? date.month - 1 : null, date.day || null)
    }
  }
  getValue(symbol: string) {
    switch (symbol) {
      case "y": return this.year;
      case "M": return this.month;
      case "d": case "E": return this.day;
      case "h": case "H": case "t": return this.hours;
      case "m": return this.minutes;
      case "s": return this.second;
    }
  }

  modifyPart(symbol: string, char: string | number | null): boolean {
    if (char === null) {
      switch (symbol) {
        case "y": this.noYear = null; break;
        case "M": this.noMonth = null; break;
        case "d": case "E": this.noDay = null; break;
        case "h": case "H": this.noHours = null; break;
        case "m": this.noMinutes = null; break;
        case "s": this.noSecond = null; break;
        case "t": this.noHours = null; break;
      }
      return true
    } else {
      let newValue = this.date;

      switch (symbol) {
        case "d":
          this.noDay = false;
          let newDate = parseInt(char.toString(), 10);
          // let newDate = (this.lastSymbol == symbol ? newValue.getDate() * 10 : 0) + parseInt(char.toString(), 10);
          if (isNaN(newDate)) { return }
          while (newDate > 31) {
            newDate = parseInt(newDate.toString().slice(1), 10)
          }
          if (newDate < 1) { this.lastSymbol = symbol } else {
            newValue.setDate(newDate);
            if (newValue.getMonth() !== this.date.getMonth()) { return }
          }
          break;
        case "M":
          this.noMonth = false;
          let newMonth = parseInt(char.toString(), 10);
          // let newMonth = (this.lastSymbol == symbol ? (newValue.getMonth() + 1) * 10 : 0) + parseInt(char.toString(), 10);
          if (!isNaN(newMonth)) {
            while (newMonth > 12) {
              newMonth = parseInt(newMonth.toString().slice(1), 10)
            }
            if (newMonth < 1) { this.lastSymbol = symbol } else {
              newValue.setMonth(newMonth - 1);
              if (newValue.getMonth() !== newMonth - 1) {
                newValue.setDate(1);
                newValue.setMonth(newMonth - 1);
              }
            }
          } else {
            // let monthNames = calendar.months.names;
            // typedMonthPart += char.toLowerCase();

            // while (typedMonthPart.length > 0) {
            //   for (var i = 0; i < monthNames.length; i++) {
            //     if (monthNames[i].toLowerCase().indexOf(typedMonthPart) === 0) {
            //       newValue.setMonth(i);
            //       month = true;
            //       value = newValue;
            //       return true;
            //     }
            //   }
            //   typedMonthPart = typedMonthPart.substring(1, typedMonthPart.length);
            // }
            return false;
          }
          break;
        case "y":
          this.noYear = false;
          let newYear = (this.lastSymbol == symbol ? (newValue.getFullYear()) * 10 : 0) + parseInt(char.toString(), 10);
          if (isNaN(newYear)) { return; }
          while (newYear > 9999) {
            newYear = parseInt(newYear.toString().slice(1), 10);
          }
          if (newYear < 1) { this.lastSymbol = symbol } else {
            newValue.setFullYear(newYear);
          }
          break;
        // case "h":
        //   newHours = (hours ? (newValue.getHours() % 12 || 12) * 10 : 0) + parseInt(currentChar, 10);
        //   if (isNaN(newHours)) { return; }
        //   while (newHours > 12) {
        //     newHours = parseInt(newHours.toString().slice(1), 10);
        //   }
        //   newValue.setHours(Math.floor(newValue.getHours() / 12) * 12 + newHours % 12);
        //   hours = true;
        //   break;
        // case "H":
        //   newHours = (hours ? (newValue.getHours()) * 10 : 0) + parseInt(currentChar, 10);
        //   if (isNaN(newHours)) { return; }
        //   while (newHours > 23) {
        //     newHours = parseInt(newHours.toString().slice(1), 10);
        //   }
        //   newValue.setHours(newHours);
        //   hours = true;
        //   break;
        // case "m":
        //   var newMinutes = (minutes ? (newValue.getMinutes()) * 10 : 0) + parseInt(currentChar, 10);
        //   if (isNaN(newMinutes)) { return; }
        //   while (newMinutes > 59) {
        //     newMinutes = parseInt(newMinutes.toString().slice(1), 10);
        //   }
        //   newValue.setMinutes(newMinutes);
        //   minutes = true;
        //   break;
        // case "s":
        //   var newSeconds = (seconds ? (newValue.getSeconds()) * 10 : 0) + parseInt(currentChar, 10);
        //   if (isNaN(newSeconds)) { return; }
        //   while (newSeconds > 59) {
        //     newSeconds = parseInt(newSeconds.toString().slice(1), 10);
        //   }
        //   newValue.setSeconds(newSeconds);
        //   seconds = true;
        //   break;
        // case "t":
        //   if (hours) {
        //     typedDayPeriodPart += currentChar.toLowerCase();
        //     while (typedDayPeriodPart.length > 0) {
        //       if (calendar.AM[0].toLowerCase().indexOf(typedDayPeriodPart) === 0 && newValue.getHours() >= 12 ||
        //         calendar.PM[0].toLowerCase().indexOf(typedDayPeriodPart) === 0 && newValue.getHours() < 12) {
        //         newValue.setHours((newValue.getHours() + 12) % 24);
        //         value = newValue;
        //         return true;
        //       }
        //       typedDayPeriodPart = typedDayPeriodPart.substring(1, typedDayPeriodPart.length);
        //     }
        //     return false;
        //   }
        //   break;
        default: break;
      }
      return true
    }
  }

  equals(date?: DateStruct | null): boolean {
    return date != null
      && this.year === date.year
      && this.month === date.month
      && this.day === date.day
  }

  isBefore(other?: DateStruct | null): boolean {
    if (!other) { return false }
    if (this.year === other.year) {
      if (this.month === other.month) { return this.day === other.day ? false : this.day < other.day }
      else { return this.month < other.month }
    } else {
      return this.year < other.year
    }
  }

  isAfter(other?: DateStruct | null): boolean {
    if (!other) { return false }
    if (this.year === other.year) {
      if (this.month === other.month) { return this.day === other.day ? false : this.day > other.day }
      else { return this.month > other.month }
    } else {
      return this.year > other.year
    }
  }
}