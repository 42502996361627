import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class BarcodeService {
    constructor(private http: HttpClient) { }

    generateBarcode(entitate: string, idEntitate: number, code?: string, params?: { [key: string]: any }) {
        return this.http.post('/barcode/generate', {
            entitate: entitate,
            idEntitate: idEntitate,
            code: code
        })
    }
    generateQrcode(entitate: string, idEntitate: number, code?: string, params?: { [key: string]: any }) {
        return this.http.post('/qrcode/generate', {
            entitate: entitate,
            idEntitate: idEntitate,
            code: code
        })
    }

    /**
     * metoda care apeleaza prin post functia din server savePDF417
     * @param tip: cab, op, cec 
     * @param idDocument 
     * @param text 
     * @param idUnitate 
     * @returns 
     */
    savePDF417(tip: string, idDocument: number, text?: string, idUnitate?: number) {
        return this.http.post('/savePDF417', {
            type: tip, idDocument: idDocument,
            text: text, idUnitate: idUnitate
        })
    }


    /**
     * @deprecated
     * use @savePDF417
     */
    savePDF417OP(idDocument: number) {
        return this.http.post('/savePDF417OP', { idDocument: idDocument })
    }

    /**
     * @deprecated
     * use @savePDF417
     */
    savePDF417Cec(idDocument: number, idUnitate: number) {
        return this.http.post('/savePDF417Cec', { idDocument: idDocument, idUnitate: idUnitate })
    }

    /**
     * @deprecated
     * use @savePDF417
     */
    savePDF417NotaContabilaCorectieCAB(idDocument: number) {
        return this.http.post('/savePDF417NotaContabilaCorectieCAB', { idDocument: idDocument })
    }
}
