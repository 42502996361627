<div class="modal-header">
    <h4>{{params.title || 'Parametri raport !'}}</h4>
</div>
<div class="modal-body">
    <ng-template [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{params: params}"></ng-template>

    <fieldset class="text-center">
        <button (click)="getReport('word')" class="btn btn-secondary px-3 mr-1" title="Descarca word">
            <i class="fal fa-3x fa-file-word"></i>
        </button>
        <button (click)="getReport('excel')" class="btn btn-success px-3 mr-1" title="Descarca excel">
            <i class="fal fa-3x fa-file-excel"></i>
        </button>
        <button (click)="getReport('pdf')" class="btn btn-danger px-3 mr-1" title="Descarca pdf">
            <i class="fal fa-3x fa-file-pdf"></i>
        </button>
        <button (click)="getReport('csv')" class="btn btn-primary px-2" title="Descarca csv">
            <div class="fal fa-3x">
                <span style="font-size: .5em; vertical-align: top;" class="fa-stack">
                    <span style="top: .25em; font-size: .7em; font-weight: 700; font-family: 'Oswald';">CSV</span>
                    <i style="vertical-align: top;" class="fal fa-file fa-stack-2x"></i>
                </span>
            </div>
        </button>
    </fieldset>
</div>
<div class="modal-footer">
    <button class="btn btn-danger" (click)="cancel()"><i class="fal fa-undo fa-1x"></i>&nbsp;Renunță</button>
</div>