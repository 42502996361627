export class StringsProvider {
	constructor() { }

	clientId = 'Sigma';
	appErrorPrefix = '[Sigma Eroare] ';
	reportFolder = '/Sigma/';
	serverUri = {
		apiServiceBaseUri: '/',
		apiServiceAccount: '',
		apiBreezeBaseUri: '/'
	};
	events = {
		controllerActivateSuccess: 'controller.activateSuccess',
		spinnerToggle: 'spinner.toggle',
		entitiesChanged: 'dataAcces.entitiesChanged',
		storage: {
			error: 'store.error',
			storeChanged: 'store.changed',
			wipChanged: 'wip.changed'
		},
		registerCompleted: "registerCompleted",
		loginCompleted: "loginCompleted",
		authorizationCompleted: "authorizationCompleted",
		authorizationChanged: "authorizationChanged",
		configChanged: "configChanged",
		configCompleted: "configCompleted",
		httpStateChanged: "httpStateChanged",
		statesUpdated: "statesUpdated"
	};
}

export enum MonthOfYear {
	Ianuarie = 0,
	Februarie,
	Martie,
	Aprilie,
	Mai,
	Iunie,
	Iulie,
	August,
	Septembrie,
	Octombrie,
	Noiembrie,
	Decembrie
}
export enum DayOfWeek {
	Duminică = 0,
	Luni,
	Marți,
	Miercuri,
	Joi,
	Vineri,
	Sâmbătă,
}

export const helpMessages = {
	activitateEconomica: "Activitate economică",
	operatiune: "Operațiune",
	furnizor: "Furnizor",
	iban: "Iban",
	contract: "Contract",
	comanda: "Comandă",
	delegat: "Delegat",
	tipServiciu: "Tip serviciu",
	centreDeCheltuiala: "Adaugă centru de cheltuială în Administrare instituție - Organizare instituțională",
	um: "Unitate de măsură",
	cota: "Cotă",
	tipTva: "Tip tva",
	tipBuget: "Tip buget",
	clasificareFunctionala: "Clasificare funcțională",
	clasificareEconomica: "Clasificare economică",
	utilitate: "Asociează utilitate unui furnizor în Persoane - Asociere furnizori și tipuri de utilități",
	locFolosinta: "Adaugă loc folosință în Administrare instituție - Organizare instituțională",
	persoana: "Adaugă persoana în Persoane - Persoane",
	magazie: "Este o proprietate a gestiunii. Se selectează în cazul în care gestiunea respectivă este în același timp și magazie.",
	magazioner: "Adaugă magazioner în Persoane - Persoane în unitate",
	comisieDeReceptie: "Adaugă comisie de recepție în Persoane - Comisii",
	clasaProduse: "Clasă produse",
	tipProduse: "Tip produs",
	categorie: "Categorie",
	gestiunePrimitor: "Adaugă gestiune primitor în Persoane - Persoane",
	persoanaPrimitor: "Adaugă persoana primitor în Persoane - Persoane",
	cpv: "Cpv",
	beneficiar: "Adaugă beneficiar în Persoane - Persoane",
	domeniu: "Domeniu",
	domeniuFactura: "Pentru public folositi Document tip: Proces verbal de predare/primire.",
	conformHcl: "Proprietate a departamentului. Definește dacă aparține sau nu organigramei definite prin HCL.",
	vizibilSalarii: "Se vor defini departamentele din organigramă.",
	vizibilGestiuni: "Se vor marca acele elemente din organizarea instituțională pentru care se dorește evidențierea distinctă a stocului/inventarului în funcție de propriile criterii (pe tipuri de produse – gestionar, obiecte de inventar, mărfuri, materii prime, etc.).",
	vizibilMijloaceFixe: "Pentru gestionarea activelor corporale şi ţinerea evidenţei tehnico-operative pe locuri de folosinţă. Se vor marca acele noduri din organizarea instituională ce reprezintă locuri de folosință.",
	vizibilContabilitate: "Se vor defini ca activități economice acele activități desfășurate de unitatea administrativă pentru care se dorește sau este necesară întocmirea unei balanțe de verificare (Ex. Activitatea proprie, Proiecte etc.).",
	vizibilImpotax: "Impotax",
	vizibilRegistruAgricol: "Registru Agricol",
	vizibilAsisoc: "Asisoc",
	vizibilInvatamant: "Utilizat în modulul Învățământ. Va fi marcată structura organizațională utilizată pornind de la Unitatea administrativă la Creșe, Grupe etc.",
	centruCheltuiala: "Se vor marca acele entități din structura organizațională pentru care se dorește măsurarea distinctă a costurilor pe care le generează (Ex. Departamente, Echipamente, Persoane etc.). Centrele de cost se pot defini doar ca descendenți ai unui nod de tip „Activitate economică„.",
	casierie: "Se marcheaza ca punct de încasare elementele din structura organizatorică la nivelul cărora se vor realiza operațiuni de casă (numerar). În accepțiunea generală - casieria fizică. Închidere zi operațiuni numerar și generarea borderoului se fac la nivel de punct încasare.",
	cantina: "Utilizat în modulul cantină.  Meniurile/retețarul vor putea fi definite distinct pentru fiecare cantină în parte.  O cantina va fi și o magazie vizibilă în gestiuni.",
	codFiscalTVA: "Este o proprietate a unei Activități Economice dacă este marcată ca structură cu statut de Plătitor de TVA.  Se va completa codul fiscal aferent activității/persoanei înscrise în registrul persoanelor înregistrate în scopuri de TVA. Dacă unitatea administrativă este plătitoare de TVA se va folosi opțiunea existentă în Modulul Persoane - Meniul Persoane - Adăgare/Modificare persoană.",
	proiect: "Este o proprietate a unei Activități Economice. Dacă este marcată ca fiind Proiect se va putea completa codul SMIS.",
	codSMIS: "Este o proprietate a unei Activități Economice marcată ca Proiect. Reprezintă codul SMIS atribuit proiectului.",
	platitorTVA: "Este o proprietate a unei Activități Economice. Se vor marca acele activități economice care au statutul de Plătitor de TVA, adică au obligația de a ține evidența separată a TVA-ului colectat sau plătit. Se folosește doar în cazurile în care una sau multe activități economice desfășurate (dar nu toate) sunt plătitoare de TVA. Dacă unitatea administrativă este plătitoare de TVA se va folosi opțiunea existentă în Modulul Persoane - Meniul Persoane - Adăgare/Modificare persoană.",
	sistemTVAIncasare: "Este o proprietate a unei Activități Economice dacă este marcată ca structură cu statut de Plătitor de TVA.  Marchează faptul că activitatea economică este eligibilă pentru aplicarea sistemului TVA la încasare. Dacă unitatea administrativă este plătitoare de TVA se va folosi opțiunea existentă în Modulul Persoane - Meniul Persoane - Adăgare/Modificare persoană.",
	raportatFinanciar: "Este o proprietate a unei Activități Economice. Se vor marca acele activități economice care se raportează în Sistemul electronic de raportare a situaţiilor financiare din sectorul public - FOREXEBUG.",
	activitateProprie: "Activitate principală",
	ordonatorPrincipal: "Închidere de conturi, generare note contabile în afara bilanțului (clasa 8)",
	buget: "Este o proprietate a gestiunii și/sau a locului de folosință. Va fi propus la recepția unui produs/mijloc fix pentru gestiunea/locul de folosință ales.",
	capitol: "Este o proprietate a gestiunii și/sau a locului de folosință. Va fi propus la recepția unui produs/mijloc fix pentru gestiunea/locul de folosință ales.",
	persoanaResponsabila: "Este o proprietate a gestiunii și/sau a locului de folosință și reprezintă gestionarul/magazionerul care va fi propus.",
	contAnalitic: "Este o proprietate a gestiunii și/sau a locului de folosință. Va fi propus generarea înregistrărilor contabile aferente recepției unui produs/mijloc fix pentru gestiunea/locul de folosință ales.",
	activitateEconomicaOrg: "Este o proprietate a gestiunii și/sau a locului de folosință. Se folosește la generarea înregistrărilor contabile (pe activitatea economică aleasă aici) pentru bonurile de consum/transfer dacă activitatea economică nu poate fi determinată din intrări. (Ex. Stoc inițial).",
	persoanaPlataDirecta: "Persoana care primește banii",
	disponibilLichidari: "Prevederi - Lichidări",
	disponibilPlati: "Prevederi - Plăți"
};