import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Subject, tap } from "rxjs";

import { DataService } from "./DataService";
import { AppSettings } from "./AppSettings";
import { DialogService } from "./DialogService";
import { WebSoketService } from "./WebSocketService";
import { RoutingService } from "./RouterService";
import { Tables } from "../constants/Tables";

@Injectable({ providedIn: 'root' })
export class NotificationService {
    constructor(private http: HttpClient,
        private settings: AppSettings, private router: RoutingService,
        private data: DataService, private dialog: DialogService,
        private ws: WebSoketService) {
        // this.setupNotifications();
    }

    hasNew = new Subject<boolean>();
    notifications = new BehaviorSubject<Array<any>>([]);
    // notifications = new BehaviorSubject<Array<metadata.log.Notificare & metadata.log.NotificareUser>>([]);

    getNotifications() { return this.notifications.getValue() }
    private setupNotifications() {
        this.ws.on('notification').subscribe(n => { // this.ws.multi('notifications', (data) => { });
            var all = this.getNotifications();
            const notif: any = n['message'];
            if (Array.isArray(notif)) {
                all = notif;
            } else {
                all.unshift(notif);
                if (notif.tip == 'update' && notif.actiune == 'refresh')
                    this.action(notif);
            }
            this.notifications.next(all);
            if (all.some(n => !n.dataVizualizare)) { this.hasNew.next(true) }
        })
    }

    getNotificationSettings() {

    }
    getOptions() {
        return this.http.get(`/notifications/options`, {
            params: {}
        }).subscribe(res => { })
    }

    setViewed(item) { //: metadata.log.Notificare & metadata.log.NotificareUser
        return Promise.resolve();
        // return this.data.getEntityByKeyNew<metadata.log.NotificareUser>(Tables.log.NotificareUser, item.id).then((n) => {
        //     n[0].dataVizualizare = new Date();
        //     item.dataVizualizare = new Date();
        //     if (this.getNotifications().every(n => n.dataVizualizare)) { this.hasNew.next(false) }
        //     return this.data.saveChanges(n);
        // })
    }
    action(item) { //: metadata.log.Notificare & metadata.log.NotificareUser
        if (item.actiune == 'refresh') {
            this.dialog.confirm({
                title: 'Actualizare', confirmBtn: 'Refresh',
                message: 'Aplicatia a fost actualizata,\n pentru a functiona corect va rugam sa faceti refresh la browser!',
            }).then(() => {
                this.setViewed(item).then(() => window.location.reload())
            }, () => { })
        } else if (item.actiune) {
            this.router.navigate([item.actiune])
        }
    }
}