import { Injectable, Inject, Type } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Entity } from 'breeze-client';


import { ConfirmDialog, DialogType } from '../controls/modal/dialog.confirm';
import { IncetareInregistrare } from '../controls/modal/dialog.incetare';
import { ProgressDialog } from '../controls/modal/dialog.progress';
import { AlertService } from 'src/app/services';

export enum DialogTextAlign { left, rigth }
export enum DialogSize { small = "sm", large = "lg", xlarge = "xl" }

@Injectable()
export class DialogService {
    constructor(private modal: NgbModal, @Inject(AlertService) private alert) { }

    /**
     * no buttons, default type: info
     * @return: modal instance, close manually
     */
    wait(config: Dialog.waitParams, options?: NgbModalOptions) {
        if (!config.type) { config.type = DialogType.info }
        return this.openModal(config, options);
    }
    /**
     * no buttons, default type: info
     * @return: wait for promise/function to complete, return result and close
     */
    waitFor(config: Dialog.waitForParams, options?: NgbModalOptions) {
        let instance = this.wait(config, options);
        return Promise.resolve(config.waitFor).then(res => {
            instance.close();
            return res;
        })
    }

    /** 1 button: 'ok', default type: info */
    notify(params: Dialog.notifyParams, options?: NgbModalOptions) {
        params.type = params.type || DialogType.info;
        params.confirmBtn = params.hasOwnProperty('confirmBtn') ? params.confirmBtn : 'OK';
        return this.openModal(params, options).result;
    }

    /** 2 button: 'ok' & 'cancel', default type: warning */
    confirm(params: Dialog.confirmParams, options?: NgbModalOptions) {
        params.type = params.type || DialogType.info;
        params.confirmBtn = params.hasOwnProperty('confirmBtn') ? params.confirmBtn : true;
        params.cancelBtn = params.hasOwnProperty('cancelBtn') ? params.cancelBtn : true;
        return this.openModal(params, options).result;
    }

    /** 2 button: 'ok' & 'cancel', default type: warning */
    delete(params: Dialog.deleteParams, options?: NgbModalOptions): Promise<Entity[]> {
        if (!params.entity) { return Promise.reject('No entity provided!') }
        params.type = params.type || DialogType.warning;
        params.deleteBtn = params.hasOwnProperty('deleteBtn') ? params.deleteBtn : true;
        params.cancelBtn = params.hasOwnProperty('cancelBtn') ? params.cancelBtn : true;
        return this.openModal(params, options).result;
    }
    private openModal(params: { [key: string]: any }, options?: NgbModalOptions) {
        const ref = this.custom(ConfirmDialog, { params: params }, options);
        const el = document.getElementsByTagName('ngb-modal-window');
        if (el.length && el[0].classList) { // bs3 interfierce with ngb bs5 modal
            setTimeout(() => el[0].classList.remove('show', 'fade'), 100)
        }
        return ref
    }

    /** 
     * websocket based progress, auto register event and wait for response
     * - for more control over ws property use progressWS(...)
     * - alternative (no ws): use wait/waitFor, with type progress and close manually
     * - 1/no button: 'ok', default type: info 
     */
    progress(config: Dialog.progressParams, options?: NgbModalOptions) {
        return this.progressWS(Object.assign(config, { ws: { event: 'register' } }), options);
    }
    /**
     * websocket based progress
     * - alternative: no ws message/event required, use wait with type progress and close manually
     * - 1/no button: 'ok', default type: info 
     */
    progressWS(params: Dialog.progressWSParams, options?: NgbModalOptions) {
        params.type = params.type || DialogType.info;
        params.cancelBtn = params.cancelBtn || true;
        return this.custom(ProgressDialog, { params: params }, options).result;
    }

    /** 
     * incetare inregistrare la o anumita data/luna/an (inclusiv) 
     * - 2 buttons: ok && cancel
     * Defaults:
     *   @param params.proprietateStop: 'lunaSfarsit'
     *   @param params.dateMode: 'month'
     *   @param params.type: 'warning' // dialog type, 
     * */
    incetare(params: Dialog.stopRecordParams, options?: NgbModalOptions): Promise<any> {
        return Promise.resolve(params.entity).then((entity: Entity | Entity[]) => {
            entity = (Array.isArray(entity) ? entity : [entity]);
            params.proprietateStop = params.proprietateStop || 'lunaSfarsit';
            params.dateMode = params.dateMode || 'month';

            // if (!(entity[0].hasOwnProperty(params.proprietateStop))) {
            if (!(params.proprietateStop in entity[0])) {
                return Promise.reject('Lipseste proprietatea data stop!')
            }
            params.entity = entity;
            params.type = params.type || DialogType.warning;
            return this.custom(IncetareInregistrare, params, options).result;
        })
    }

    /**
     * @param component: angular @Component
     * @param inputParams: se pot accesa in componenta prin decorator @Input() 
     * @param options: bootstrap options
     */
    custom(component: Type<any>, inputParams?: { [key: string]: any }, options?: NgbModalOptions) {
        var modalRef = this.modal.open(component, options || { animation: true, backdrop: false });
        if (inputParams && modalRef.componentInstance) {
            for (var key in inputParams)
                modalRef.componentInstance[key] = inputParams[key]
        }
        return modalRef
    }

    /**
     * @deprecated do not use,
     * alternative use: dialog.custom(modalComponent)
     */
    modalAutentificare(params): Promise<any> {
        return this.alert.modalAutentificare(params)
    }
    /**
    * @deprecated do not use,
    * alternative use: dialog.custom(modalComponent)
    */
    modalExecuteQuery(query?: any): Promise<any> {
        return this.alert.modalExecuteQuery(query)
    }
    /**
     * @deprecated do not use,
     * alternative use: dialog.custom(modalComponent)
     */
    addUser(params: any): Promise<any> {
        return this.alert.addUser(params)
    }
    /**
     * @deprecated do not use,
     * alternative use: dialog.custom(modalComponent)
     */
    changeStart(config: any): Promise<any> {
        return this.alert.changeStart(config)
    }
    /**
     * @deprecated do not use,
     * alternative use: dialog.incetare(...)
     */
    stopRecord(config: any): Promise<any> {
        return this.alert.stopRecord(config)
    }
    /**
     * @deprecated, do not use
     * alternative use: dialog.incetare(...)
     */
    incetareInregistrare(
        entity: Entity | Entity[], mesaj?: string, proprietateStop?: string,
        dataAnulare?: Date, template?: string, isReadOnly?: boolean,
        title?: string, anul?: number, anii?: number[],
        saveFunction?: (dataStop: any, modalInstance: any, id: any) => void): Promise<any> {
        return this.alert.stopRecord(
            entity, mesaj, proprietateStop, dataAnulare, template, isReadOnly, title, anul, anii, saveFunction
        )
    }
    /**
     * @deprecated, do not use
     * alternative use: dialog.incetare(...)
     */
    changeDataStartInregistrare(
        entity: Entity | Entity[], mesaj?: string, proprietateStart?: string,
        dataStart?: Date, template?: string, isReadOnly?: boolean, title?: string,
        saveFunction?: (dataSt: any, modalInstance: any, id: any) => void): Promise<any> {
        return this.alert.stopRecord(
            entity, mesaj, proprietateStart, dataStart, template, isReadOnly, title, saveFunction
        )
    }
}