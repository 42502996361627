import { Inject, Injectable } from "@angular/core";
import { IRootScopeService } from "angular";

import { LogService } from "./LogService";
import { DataService } from "./DataService";
import { DialogService } from "./DialogService";
import { StringsProvider } from "../constants/Strings";

import { Query } from "../constants/Query";
import { States } from "../constants/States";
import { App, Config } from "../constants/Enum";

declare var angular: any;

@Injectable({ providedIn: 'root' })
export class ConfigServiceNew {
    constructor(private data: DataService) { }

    get(cod: Config, idUnitate?: number) {
        return this.set(cod, idUnitate)
    }
    set(cod: Config, value: boolean | string | number | null, idUnitate?: number) {
        if (value && !(value === null || typeof value === 'number' || typeof value === 'string')) {
            value = JSON.stringify(value)
        }
        const query = Query.admin.config(Config[cod], value, undefined, idUnitate);
        return this.data.executeQueryNew(query).then(data => {
            try {
                var res = data[0].valoare;
                res = JSON.parse(res);
            } catch (ex) { }
            return res !== null ? res : {};

            // return data.reduce((prev, current) => {
            //     try {
            //         prev[current.setare] = JSON.parse(current.valoare)
            //     } catch (err) { prev[current.setare] = current.valoare }
            //     return prev
            // }, {})
        })
    }
}

/**
 * @deprecated
 */
@Injectable({ providedIn: 'root' })
export class ConfigService extends StringsProvider {
    constructor(private data: DataService, private log: LogService,
        @Inject('$rootScope') private $rootScope: IRootScopeService,
    ) { super() }

    get(name: Config, idUnitate?: number): Promise<any> {
        const fieldName: string = Config[name] + (idUnitate ? '$' + idUnitate : '');
        if (this[fieldName]) { return Promise.resolve(this[fieldName]) }
        return this.set(name, null, idUnitate)
    }
    set(config: Config, value?: any, idUnitate?: number): Promise<any> {
        const fieldName = Config[config] + (idUnitate ? '$' + idUnitate : '');
        const isUnitateChanged: boolean = !!(
            value && typeof value == 'object' && value !== null
            && value.hasOwnProperty('idUnitate')
            && this[fieldName] && typeof this[fieldName] == 'object' && this[fieldName] !== null
            && this[fieldName].hasOwnProperty('idUnitate')
            && value.idUnitate != this[fieldName]['idUnitate']);

        this[fieldName] = value || undefined;
        if (value) {
            if (typeof value == 'string') { value = value.replace(/'/g, "''") }
            if (typeof value == 'object' && value !== null) {
                for (var p in value) {
                    if (typeof value[p] == 'string')
                        value[p] = value[p].replace(/'/g, "''")
                }
                value = JSON.stringify(value);
            }
        }

        const query = Query.admin.config(Config[config], value, undefined, idUnitate);
        return this.data.executeQuery(query).then((data) => {
            try {
                data = data.results[0].valoare;
                data = JSON.parse(<any>data);
            } catch (ex) { this[Config[config]] = ex }

            this[fieldName] = typeof data !== 'undefined' && data !== null ? data : {};
            this.$rootScope.$broadcast(this.events.configChanged, fieldName, this[fieldName]);
            if (isUnitateChanged) {
                this.$rootScope.$broadcast('idUnitateChanged', this[fieldName])
            }
            return this[fieldName];
        });
    }

    /** Citeste toate configurarile din baza de date */
    getConfigs(idAplicatie?: number) {
        return this.data.executeQuery(Query.admin.getConfigs(idAplicatie)).then(data => {
            if (!(data.results[0] && data.results[0].configs)) { return }
            try {
                var configs = JSON.parse(data.results[0].configs)
            } catch (ex) {
                this.log.error("JSON.parse(data.results[0].configs)", data.results[0], true);
                return;
            }
            for (var config in configs) {
                try {
                    this[config] = JSON.parse(configs[config])
                } catch (ex) { this[config] = configs[config] }
            }
            this.$rootScope.$broadcast(this.events.configCompleted, this)
        })
    }

    /** Cauta proprietatea in toate configurile aplicatiilor */
    getPropertyFromConfigs(propertyName): Promise<any> {
        //lista id-uri aplicatii
        var listIdAplicatie = <any>[];
        for (var key in App) {
            var value = Number(App[key]);
            if (!isNaN(value)) { listIdAplicatie.push(value) }
        }
        return this.getPropertyFromContextRecusiv(listIdAplicatie, propertyName);
    }

    private getPropertyFromContextRecusiv(listIdAplicatie: number[], propertyName: string) {
        var idAplicatie = listIdAplicatie.shift();
        if (!idAplicatie) { Promise.resolve(null) }

        return this.getContextNavbar(idAplicatie, false).then(settings => {
            if (settings && settings[propertyName]) {
                // avem proprietatea dorita, nu mai continuam
                return Promise.resolve(settings[propertyName])
            } else {
                // continuam sa cautam proprietatea
                return this.getPropertyFromContextRecusiv(listIdAplicatie, propertyName)
            }
        })
    }

    /**
     *----------------
     * Aduce toate setarile context pentru navbar-ul fiecarei aplicatii in parte
     * (Buget, Contab, Achizitii, Inventare, Persoane, Salarii, Admin) 
     * @idAplicatie 
     * @validateContext: boolean (default true) -> validare obiect cu parametri
     * @redirectIfInvalid: boolean (default true) -> 
     */
    private getContextNavbar(idAplicatie: number, redirectIfInvalid: boolean = true) {
        if (!appContext[idAplicatie] || !Config[appContext[idAplicatie]]) {
            console.log('Nu exista config pentru aplicatia: ' + idAplicatie);
            return Promise.resolve(null);
        }

        return this.get(Config[appContext[idAplicatie]]).then(data => {
            let configObj: any;
            switch (idAplicatie) {
                case App.buget: configObj = new ConfigBuget(data); break;
                case App.contab: configObj = new ConfigContab(data); break;
                case App.achizitii: configObj = new ConfigAchizitii(data); break;
                case App.salarii: configObj = new ConfigSalarii(data); break;
                case App.inventare: configObj = new ConfigInventare(data); break;
                case App.persoane: configObj = new ConfigPersoane(data); break;
                case App.adrese: configObj = new ConfigAdrese(data); break;
                case App.incasari: configObj = new ConfigIncasare(data); break;
                case App.documente: configObj = new ConfigDocumente(data); break;
                case App.administrareinstitutie: configObj = new ConfigAdministrare(data); break;
                case App.impotax: configObj = new ConfigImpotax(data); break;
                case App.raportarifinanciare: configObj = new ConfigRaportariFinanciare(data); break;
                case App.invatamant: configObj = new ConfigInvatamant(data); break;
                case App.asisoc: configObj = new ConfigAsisoc(data); break;
                case App.cantina: configObj = new ConfigCantina(data); break;
                case App.investitii: configObj = new ConfigInvestitii(data); break;
                default:
                    console.log('Config neimplementat pentru aplicatia: ' + idAplicatie);
                    return null;
            }
            return configObj.validate(redirectIfInvalid);
        });
    }

    /**
     * ???
     */
    rejectSettingsChanges(idAplicatie: App): Promise<any> {
        let $injector = angular.element(document).injector();

        let settingValue: any = $injector.get('settings');
        return this.get(Config[appContext[idAplicatie]]).then(data => {
            settingValue.getProperties().forEach((p: string) => { settingValue[p] = data[p] });
            return settingValue;
        });
    }

    /**
     * Salveaza toate setarile context pentru navbar-ul fiecarei aplicatii in parte
     * (Buget, Contab, Achizitii, Inventare, Persoane, Salarii, Admin) 
     * @idAplicatie
     * @context -> obiectul care trebuie  salvat
     * @validateContext (default true) -> validare obiect cu parametri
     * @showSuccessLog (default true) -> arata mesaj dupa ce salveaza datele 
     */
    saveContextNavbar(
        idAplicatie: number, context: any, validateContext: boolean = true, showSuccessLog?: boolean
    ): Promise<any> {
        //try {
        //    delete context['properties'];
        //    delete context['configState'];
        //} catch (ex) { }

        if (context['isValid']) {
            if (context['isValid'].call(context)) {
                let saveObj: any = {};
                context['properties'].forEach(p => { saveObj[p.property] = context[p.property] });

                return this.set(Config[appContext[idAplicatie]], saveObj).then((data) => {
                    if (showSuccessLog) {
                        this.log.success("Parametri de lucru au fost salvați cu succes!")
                    }
                    //this.refreshSettingsIfNecessary(idAplicatie, data);
                    this.$rootScope.$broadcast(broadcastMessage[idAplicatie]);

                    return this.syncAllNavbarContext(context, idAplicatie).then(() => {
                        return this.get(Config[appContext[idAplicatie]])
                    })
                })
            } else {
                this.log.error(context.message);
                return Promise.reject();
            }
        }
        return this.set(Config[appContext[idAplicatie]], context).then(() => {
            if (showSuccessLog) {
                this.log.success("Parametri de lucru au fost salvați cu succes!")
            }
            this.$rootScope.$broadcast(broadcastMessage[idAplicatie]);
            return this.syncAllNavbarContext(context, idAplicatie).then(() => {
                return this.get(Config[appContext[idAplicatie]])
            })
        })
    }

    private refreshSettingsIfNecessary(idAplicatie: App, context: any) {
        let $injector = angular.element(document).injector();
        let settingValue: any = $injector.get('settings');
        for (var p in context) {
            //    if (settingValue.hasOwnProperty(p)) delete settingValue[p];
            settingValue[p] = context[p];
        }
    }

    /** get apps context */
    private syncAllNavbarContext(contextBaza, idAplicatie): Promise<any> {
        //  var contexts: { idAplicatie: number; context: JSON }[] = [];
        //var apps = [App.buget, App.contab, App.achizitii, App.inventare, App.persoane, App.salarii, App.admin];
        let promisses: any[] = [];
        if (idAplicatie != App.buget)
            promisses.push(this.get(Config[appContext[App.buget]]).then((context) => {
                return this.updateContext(contextBaza, context, App.buget)
            }))
        if (idAplicatie != App.contab)
            promisses.push(this.get(Config[appContext[App.contab]]).then((context) => {
                return this.updateContext(contextBaza, context, App.contab)
            }))
        if (idAplicatie != App.achizitii)
            promisses.push(this.get(Config[appContext[App.achizitii]]).then((context) => {
                return this.updateContext(contextBaza, context, App.achizitii)
            }))
        if (idAplicatie != App.inventare)
            promisses.push(this.get(Config[appContext[App.inventare]]).then((context) => {
                return this.updateContext(contextBaza, context, App.inventare)
            }))
        if (idAplicatie != App.persoane)
            promisses.push(this.get(Config[appContext[App.persoane]]).then((context) => {
                return this.updateContext(contextBaza, context, App.persoane)
            }))
        if (idAplicatie != App.salarii)
            promisses.push(this.get(Config[appContext[App.salarii]]).then((context) => {
                this.updateContext(contextBaza, context, App.salarii)
            }))
        if (idAplicatie != App.administrareinstitutie)
            promisses.push(this.get(Config[appContext[App.administrareinstitutie]]).then((context) => {
                return this.updateContext(contextBaza, context, App.administrareinstitutie)
            }))
        if (idAplicatie != App.adrese)
            promisses.push(this.get(Config[appContext[App.adrese]]).then((context) => {
                return this.updateContext(contextBaza, context, App.adrese)
            }))
        if (idAplicatie != App.incasari)
            promisses.push(this.get(Config[appContext[App.incasari]]).then((context) => {
                return this.updateContext(contextBaza, context, App.incasari)
            }))
        if (idAplicatie != App.documente)
            promisses.push(this.get(Config[appContext[App.documente]]).then((context) => {
                return this.updateContext(contextBaza, context, App.documente)
            }))
        if (idAplicatie != App.impotax)
            promisses.push(this.get(Config[appContext[App.impotax]]).then((context) => {
                return this.updateContext(contextBaza, context, App.impotax)
            }))
        if (idAplicatie != App.raportarifinanciare)
            promisses.push(this.get(Config[appContext[App.raportarifinanciare]]).then((context) => {
                return this.updateContext(contextBaza, context, App.raportarifinanciare)
            }))
        if (idAplicatie != App.cantina)
            promisses.push(this.get(Config[appContext[App.cantina]]).then((context) => {
                return this.updateContext(contextBaza, context, App.cantina)
            }))
        if (idAplicatie != App.invatamant)
            promisses.push(this.get(Config[appContext[App.invatamant]]).then((context) => {
                return this.updateContext(contextBaza, context, App.invatamant)
            }))
        if (idAplicatie != App.investitii) {
            promisses.push(this.get(Config[appContext[App.investitii]]).then((context) => {
                return this.updateContext(contextBaza, context, App.investitii)
            }))
        }
        return promisses.length ? Promise.all(promisses) : Promise.resolve(contextBaza)
    }

    /**contextToUpdate */
    private updateContext(contextBaza: any, contextToUpdate: any, idAplicatie: number): Promise<any> {

        for (var keyBaza in contextBaza) {
            for (var keyToSync in contextToUpdate) {
                if ((keyBaza == 'an' && (keyToSync == 'an' || keyToSync == 'anBuget')) ||
                    (keyBaza == 'anBuget' && (keyToSync == 'an' || keyToSync == 'anBuget'))) {
                    if (contextToUpdate.hasOwnProperty(keyToSync)) {
                        contextToUpdate[keyToSync] = contextBaza[keyBaza];
                        continue;
                    }
                }
                if (keyToSync === keyBaza && (keyBaza === "idUnitate" || keyBaza === "luna"))
                    contextToUpdate[keyToSync] = contextBaza[keyBaza];
            }
        }
        if (idAplicatie == App.buget) {
            return this.get(Config.configBugetUserPeUnitate$Buget$Buget, contextBaza['idUnitate']).then((data: Array<any>) => {
                let setareAn: any
                if (data && data.length) {
                    setareAn = data.find(item => item.anBuget == contextToUpdate['anBuget']);
                    if (setareAn) {
                        contextToUpdate['idBuget'] = setareAn.idBuget;
                        contextToUpdate['idRectificare'] = setareAn.idRectificare;
                        contextToUpdate['dataRectificare'] = setareAn.dataRectificare;
                        contextToUpdate['extindereDetalieri'] = setareAn.extindereDetalieri;
                    }
                }
                if (!setareAn) {
                    contextToUpdate['idBuget'] = undefined;
                    contextToUpdate['idBuget'] = contextBaza['an'] || new Date().getFullYear();
                    contextToUpdate['idRectificare'] = undefined;
                    contextToUpdate['dataRectificare'] = undefined;
                    contextToUpdate['extindereDetalieri'] = undefined;
                }
                return this.set(Config[appContext[idAplicatie]], contextToUpdate).then(() => {
                    this.$rootScope.$broadcast(broadcastMessage[idAplicatie])
                });
            })
        } else if (idAplicatie == App.salarii) {
            return this.get(Config.configSalariiUserPeUnitate$Salarii$Salarii).then((data: Array<any>) => {
                let setareAn: any;
                if (data && data.length) {
                    setareAn = data.find(item => (item.an == contextToUpdate.an && item.luna == contextToUpdate.luna));
                    if (setareAn) {
                        contextToUpdate['idOrganizareInstitutionala'] = setareAn['idOrganizareInstitutionala'];
                        contextToUpdate['idOrganizareInstitutionalaPK'] = setareAn['idOrganizareInstitutionalaPK'];
                    }
                }
                if (!setareAn) {
                    contextToUpdate['idOrganizareInstitutionala'] = undefined;
                    contextToUpdate['idOrganizareInstitutionalaPK'] = undefined;
                }
                return this.set(Config[appContext[idAplicatie]], contextToUpdate).then(() => {
                    this.$rootScope.$broadcast(broadcastMessage[idAplicatie])
                });
            })
        } else if (idAplicatie == App.contab) {
            return this.get(Config.configContabUserPeUnitate$Contab$Contab, contextBaza['idUnitate']).then((data: Array<any>) => {
                let setareAn: any;
                if (data && data.length) {
                    setareAn = data.find(item => (item.an == contextToUpdate.an && item.luna == contextToUpdate.luna));
                    if (setareAn) {
                        contextToUpdate['idOrganizareInstitutionala'] = setareAn['idOrganizareInstitutionala'];
                        contextToUpdate['idOrganizareInstitutionalaPK'] = setareAn['idOrganizareInstitutionalaPK'];
                    }
                }
                if (!setareAn) {
                    contextToUpdate['idOrganizareInstitutionala'] = undefined;
                    contextToUpdate['idOrganizareInstitutionalaPK'] = undefined;
                }
                return this.set(Config[appContext[idAplicatie]], contextToUpdate).then(() => {
                    this.$rootScope.$broadcast(broadcastMessage[idAplicatie])
                });
            })
        } else if (idAplicatie == App.cantina) {
            return this.get(Config.configCantina$Cantina$Cantina, contextBaza['idUnitate']).then((data: Array<any>) => {
                if (data) {
                    if (contextToUpdate.idUnitate != data['idUnitate']) {
                        contextToUpdate['idOrganizareInstitutionala'] = undefined;
                        contextToUpdate['idOrganizareInstitutionalaPK'] = undefined;
                    }
                }
                return this.set(Config[appContext[idAplicatie]], contextToUpdate).then(() => {
                    this.$rootScope.$broadcast(broadcastMessage[idAplicatie])
                });
            })
        } else if (idAplicatie == App.invatamant) {
            contextToUpdate['idOrganizareInstitutionala'] = undefined;
            contextToUpdate['idOrganizareInstitutionalaPK'] = undefined;
        } else {
            return this.set(Config[appContext[idAplicatie]], contextToUpdate).then(() => {
                this.$rootScope.$broadcast(broadcastMessage[idAplicatie])
            })
        }
    }
}



/** 
 * codul setarii pentru contextul aplicatiilor
 * id-ul fiecarei enumeratii corespunde cu id-ul aplicatiei
 */
enum appContext {
    "configBuget$Buget$Buget" = 73,
    "configContab$Contab$Contab" = 819,
    "configAchizitii$Setariaplicatie$Setariaplicatie" = 776,
    "configInventar$Stocurisiimobilizari$Stocurisiimobilizari" = 1403,
    "configPersoane$Persoane$Persoane" = 89,
    "configSalarii$Salarii$Salarii" = 80,
    "configAdmin$Administraresistem$Administraresistem" = 95,
    "configAdrese$Adrese$Adrese" = 88,
    "configImpotax$Impotax$Impotax" = 1,
    "configDocumente$Documente$Documente" = 1058,
    "configIncasare$Incasarisiplati$Incasarisiplati" = 2552,
    "configAdministrare$Administrareinstitutie$Administrareinstitutie" = 2739,
    "configRaportariFinanciare$Raportarifinanciare$Raportarifinanciare" = 84,
    "configInvatamant$Invatamant$Invatamant" = 3245,
    "configAsisoc$Asistentasociala$Asistentasociala" = 77,
    "configCantina$Cantina$Cantina" = 3557,
    "configInvestitii$Investitii$Investitii" = 4162
}

/** link-ul pentru redirect la macheta de setare context*/
export enum redirectLink {
    "/buget" = 73,
    "/contab/conta" = 819,
    "/achizitii/achizitii" = 776,
    "/setari/inventare" = 1403,
    "/persoane/setareUnitateAnLuna" = 89,
    "/admin/organigrama" = 80,
    "/admin/config" = 95,
    "/adrese/adreseconfig" = 88,
    "/impotax/impotaxconfig" = 1,
    "/incasariplati/incasare/configincasare" = 2552,
    "/documente/configurari/config/" = 1058,
    "/raportarifinanciare/configurari/config/" = 84,
    "/cantina/config/cantinaconfig/" = 3557,
    "/investitii/configurari/config/" = 4162
}

/** mesajul emis la salvare context*/
enum broadcastMessage {
    "navbarBugetUpdated" = 73,
    "navbarContabUpdated" = 819,
    "navbarAchizitiiUpdated" = 776,
    "navbarAsisocUpdated" = 77,
    "navbarInventarUpdated" = 1403,
    "navbarPersoaneUpdated" = 89,
    "navbarSalariiUpdated" = 80,
    "navbarAdminUpdated" = 95,
    "navbarAdministrareUpdated" = 2739,
    "navbarAdreseUpdated" = 88,
    "navbarImpotaxUpdated" = 1,
    "navbarIncasareUpdated" = 2552,
    "navbarDocumenteUpdated" = 1058,
    "navbarRaportariFinanciareUpdated" = 84,
    "navbarInvatamantUpdated" = 3245,
    "navbarCantinaUpdated" = 3557,
    "navbarInvestitiiUpdated" = 4162
}


export module config {
    export class optionsOrganizareInstitutionala {
        IsConformHcl: boolean = false;
        IsVizibilSalarii: boolean = false;
        IsVizibilGestiuni: boolean = false;
        IsVizibilMijloaceFixe: boolean = false;
        IsVizibilContabilitate: boolean = false;
    }
}

export class ConfigBase {
    constructor(config: any, properties: { property: string, errorMessage: string }[], configState: string) {
        if (typeof properties == 'undefined' || properties == null) {
            throw { message: 'Please provide a valid value for the properties parameter!' }
        } else if (!configState) {
            throw { message: 'Please provide a valid value for the configState parameter!' }
        }
        this.configState = configState;
        this.properties = properties;
        for (var p in config) { this[p] = config[p] }
    }

    message: string;
    protected configState: string;
    protected dialog: DialogService;
    protected $state;
    protected properties: { property: string, errorMessage: string }[];

    isValid = function (): boolean {
        let result: boolean = false;
        result = !this.properties.some(p => {
            if (typeof this[p.property] == 'undefined' || this[p.property] == null) {
                this.message = p.errorMessage;
                return true;
            }
            this.message = '';
            return false;
        });

        return result;
    }

    validate = function (rejectOnInvalid: boolean): Promise<any> {
        let $injector = angular.element(document).injector();
        if (!this.isValid()) {
            this.$state = $injector.get('$state');
            this.dialog = $injector.get('dialog');
            return rejectOnInvalid ? Promise.reject(this) : Promise.resolve(this);
        }
        return Promise.resolve(this)
    }

    getProperties = function (): string[] {
        return !this.properties ? [] : this.properties.map(p => p.property)
    }
}

export class ConfigBuget extends ConfigBase {
    constructor(config: any) {
        super(config, [
            { property: 'idUnitate', errorMessage: 'Nu aţi selectat unitatea!' },
            { property: 'idBuget', errorMessage: 'Nu aţi selectat bugetul!' },
            { property: 'idRectificare', errorMessage: 'Nu aţi selectat rectificarea!' },
            { property: 'dataRectificare', errorMessage: 'Nu aţi selectat rectificarea!' },
            { property: 'anBuget', errorMessage: 'Nu aţi selectat bugetul!' },
            { property: 'extindereDetalieri', errorMessage: '' },
            { property: 'isInLei', errorMessage: '' }
        ], States.buget.configBuget);
    }

    /** Valoare din campul idPersoanaUnitate din tabela unitatiIstoric.OrganizareInstitutionala */
    idUnitate: number;
    /** Id-ul comun din tabela bugetIstoric.Buget */
    idBuget: number;
    /** Id-ul din tabela buget.BugetRectificare */
    idRectificare: number;
    /** Data rectificarii selectate in formatul yyyy-MM-dd */
    dataRectificare: string;
    /** Anul bugetului selectat. */
    anBuget: number;
    /** Marcaj care determina in macheta Prevederi Buget la ce nivel se face extinderea din detalieri 1 - Fara, 2 - La nivel de Titlu, 3 - La nivel de Articol */
    extindereDetalieri: number;
    /** Determina daca bugetul selectat este in lei sau in mii lei. */
    isInLei: boolean;
}

function toStringFormat(format: string, value: Date): string {
    if (!format) { return value.toString() }
    var parts: number[] = [
        value.getFullYear(), value.getMonth() + 1, value.getDate(),
        value.getHours(), value.getMinutes(), value.getSeconds()
    ];
    format = format.replace('dd', (parts[2] < 10 ? ('0' + parts[2]) : parts[2]).toString());
    format = format.replace('d', parts[2].toString());
    format = format.replace('MM', (parts[1] < 10 ? ('0' + parts[1]) : parts[1]).toString());
    format = format.replace('M', parts[1].toString());
    format = format.replace('yyyy', parts[0].toString());
    format = format.replace('yy', (parts[0] % 100).toString());

    if (new RegExp('h').test(format)) {
        var tt = 'AM';
        if (parts[3] > 12) {
            tt = 'PM';
            parts[3] -= 12;
        }
        format = format.replace('hh', (parts[3] < 10 ? ('0' + parts[3]) : parts[3]).toString());
        format = format.replace('h', parts[3].toString() + tt);
    }
    format = format.replace('HH', (parts[3] < 10 ? ('0' + parts[3]) : parts[3]).toString());
    format = format.replace('H', parts[3].toString());
    format = format.replace('mm', (parts[4] < 10 ? ('0' + parts[4]) : parts[4]).toString());
    format = format.replace('m', parts[4].toString());
    format = format.replace('ss', (parts[5] < 10 ? ('0' + parts[5]) : parts[5]).toString());
    format = format.replace('s', parts[5].toString());
    return format;
}
export class ConfigContab extends ConfigBase {
    constructor(config: any) {
        super(config, [
            { property: 'idUnitate', errorMessage: 'Nu aţi selectat unitatea!' },
            { property: 'idOrganizareInstitutionala', errorMessage: 'Nu aţi selectat activitatea economică!' },
            { property: 'idOrganizareInstitutionalaPK', errorMessage: 'Nu aţi selectat activitatea economică!' },
            { property: 'isUnitatePlatitorTVA', errorMessage: 'Nu aţi selectat unitatea!' },
            { property: 'an', errorMessage: 'Nu aţi selectat anul!' },
            { property: 'luna', errorMessage: 'Nu aţi selectat luna!' },
            { property: 'lunaInchisa', errorMessage: '' },
        ], States.contab.conta);

        this.lunaInchisa = !!config.lunaInchisa;
        this.lunaInchisaTooltip = 'Luna este închisă!';
        this.isUnitatePlatitorTVA = !!config.isUnitatePlatitorTVA;
        this.anLuna = config.an * 100 + config.luna;
        this.dataReferinta = new Date(config.an, (config.luna - 1), 1);
        this.dataReferintaString = toStringFormat('yyyyMMdd', new Date(config.an, (config.luna - 1), 1));
    }

    /** Valoare din campul idPersoanaUnitate din tabela unitatiIstoric.OrganizareInstitutionala */
    idUnitate: number;
    /** Activitatea economică(id-ul comun din tabela unitatiIstoric.OrganizareInstitutionala) */
    idOrganizareInstitutionala: number;
    /** Activitatea economică(id-ul PK din tabela unitatiIstoric.OrganizareInstitutionala) */
    idOrganizareInstitutionalaPK: number;
    /** Proprietate care marchează dacă unitatea este plătitoare TVA. */
    isUnitatePlatitorTVA: boolean;
    /** Anul pe care se lucrează. */
    an: number;
    /** Luna pe care se lucrează. */
    luna: number;
    /** Proprietate care marchează că luna selectată este închisă. */
    lunaInchisa: boolean;
    /** Mesaj pentru tooltip butoane că luna selectată este închisă. */
    lunaInchisaTooltip: string;
    /** AnLuna pe care se lucrează. */
    anLuna: number;
    /** Data Referinta de tip obiect cu prima zi din luna setata. */
    dataReferinta: any;
    /** Data Referinta de tip string de forma 'yyyyMMdd' cu prima zi din luna setata. */
    dataReferintaString: string;
}

export class ConfigAdmin extends ConfigBase {
    constructor(config: any) {
        super(config, [], '') //States.sigmaClient.home);
    }
}
export class ConfigConversiiDate extends ConfigBase {
    constructor(config: any) {
        super(config, [], '') //States.conversiiDate.wizardWelcome);
    }
}

export class ConfigAdministrare extends ConfigBase {
    constructor(config: any) {
        super(config, [
            { property: 'idUnitate', errorMessage: 'Nu aţi selectat unitatea!' }
        ], States.administrareinstitutie.administrareUnitati);

        this.lunaInchisa = !!config.lunaInchisa;
        this.anLuna = config.an * 100 + config.luna;
        this.dataReferinta = new Date(config.an, (config.luna - 1), 1);
        this.dataReferintaString = toStringFormat('yyyyMMdd', new Date(config.an, (config.luna - 1), 1));
    }

    /** Valoare din campul idPersoanaUnitate din tabela unitatiIstoric.OrganizareInstitutionala */
    idUnitate: number;
    /** Anul pe care se lucrează. */
    an: number;
    /** Luna pe care se lucrează. */
    luna: number;
    /** Proprietate care marchează că luna selectată este închisă. */
    lunaInchisa: boolean;
    /** AnLuna pe care se lucrează. */
    anLuna: number;
    /** Data Referinta de tip obiect cu prima zi din luna setata. */
    dataReferinta: any;
    /** Data Referinta de tip string de forma 'yyyyMMdd' cu prima zi din luna setata. */
    dataReferintaString: string;
}

export class ConfigAchizitii extends ConfigBase {
    constructor(config: any) {
        super(config, [
            { property: 'idUnitate', errorMessage: 'Nu aţi selectat unitatea!' },
            { property: 'an', errorMessage: 'Nu aţi selectat anul!' }
        ], States.achizitii.achizitii);
    }

    /** Valoare din campul idPersoanaUnitate din tabela unitatiIstoric.OrganizareInstitutionala */
    idUnitate: number;
    /** Anul pe care se lucrează. */
    an: number;
}

export class ConfigAsisoc extends ConfigBase {
    constructor(config: any) {
        super(config, [
            { property: 'idUnitate', errorMessage: 'Nu aţi selectat unitatea!' },
            { property: 'an', errorMessage: 'Nu aţi selectat anul!' }
        ], States.asisoc.asisocConfigAsisoc);
    }

    /** Unitatea pe care se lucrează. */
    idUnitate: number;
    /** Anul pe care se lucrează. */
    an: number;
}

export class ConfigSalarii extends ConfigAdministrare {
    constructor(config: any) {
        super(config);
        this.configState = States.administrareinstitutie.administrareOrganigramaOrganigramaSetari;
        this.properties.splice(1, 0,
            { property: 'an', errorMessage: 'Nu aţi selectat anul!' },
            { property: 'luna', errorMessage: 'Nu aţi selectat luna!' },
            { property: 'idOrganizareInstitutionala', errorMessage: 'Nu aţi selectat departamentul!' },
            { property: 'idOrganizareInstitutionalaPK', errorMessage: 'Nu aţi selectat departamentul!' });
    }

    /** Anul pe care se lucrează. */
    an: number;
    /** Luna pe care se lucrează. */
    luna: number;
    /** Proprietate care marchează că luna selectată este închisă. */
    /** Departamentul(id-ul comun din tabela unitatiIstoric.OrganizareInstitutionala) */
    idOrganizareInstitutionala: number;
    /** Departamentul(id-ul PK din tabela unitatiIstoric.OrganizareInstitutionala) */
    idOrganizareInstitutionalaPK: number;
}


export class ConfigInventare extends ConfigAdministrare {
    constructor(config: any) {
        super(config);
        this.configState = States.inventare.setariInventare;
        this.properties.splice(1, 0,
            { property: 'an', errorMessage: 'Nu aţi selectat anul!' },
            { property: 'luna', errorMessage: 'Nu aţi selectat luna!' }
        );
    }

    /** Anul pe care se lucrează. */
    an: number;
    /** Luna pe care se lucrează. */
    luna: number;
}

export class ConfigPersoane extends ConfigInventare {
    constructor(config: any) {
        super(config);
        this.configState = States.persoane.configConfig;
    }
}

export class ConfigAdrese extends ConfigInventare {
    constructor(config: any) {
        super(config);
        this.configState = States.adrese.adreseconfig;
    }
}

export class ConfigIncasare extends ConfigAdministrare {
    constructor(config: any) {
        super(config);
        this.configState = States.incasari.platiIncasareConfigincasare;
        this.properties.push(
            { property: 'an', errorMessage: 'Nu aţi selectat anul!' },
            { property: 'luna', errorMessage: 'Nu aţi selectat luna!' }
        );
    }

    /** Anul pe care se lucrează. */
    an: number;
    /** Luna pe care se lucrează. */
    luna: number;
    /** Casieria(id-ul comun din tabela unitatiIstoric.OrganizareInstitutionala) */
    idOrganizareInstitutionala: number;
    /** Casieria(id-ul PK din tabela unitatiIstoric.OrganizareInstitutionala) */
    idOrganizareInstitutionalaPK: number;
}

export class ConfigImpotax extends ConfigAchizitii {
    constructor(config: any) {
        super(config);
        this.configState = States.impotax.impotaxconfig;
    }
}

export class ConfigDocumente extends ConfigAchizitii {
    constructor(config: any) {
        super(config);
        this.properties.splice(1, 0, { property: 'areConta', errorMessage: '' });
        this.configState = States.documente.configurariConfig;
        this.areConta = !!this.areConta;
    }

    /** Bifa temporara - are modul de conta */
    public areConta: boolean;
}

export class ConfigInvatamant extends ConfigAchizitii {
    constructor(config: any) {
        super(config);
        //this.properties.splice(1, 0,
        //{ property: 'luna', errorMessage: 'Nu aţi selectat luna!' });
        //{ property: 'idOrganizareInstitutionala', errorMessage: 'Nu aţi selectat departamentul!' },
        //{ property: 'idOrganizareInstitutionalaPK', errorMessage: 'Nu aţi selectat departamentul!' });
        //this.configState = States.administrareInstitutie.administrareOrganigramaOrganigramaSetari;
        this.configState = States.invatamant.configurariConfig;
    }

    /** Departamentul(id-ul comun din tabela unitatiIstoric.OrganizareInstitutionala) */
    idOrganizareInstitutionala: number;
    /** Departamentul(id-ul PK din tabela unitatiIstoric.OrganizareInstitutionala) */
    idOrganizareInstitutionalaPK: number;
}

export class ConfigCantina extends ConfigBase {
    constructor(config: any) {
        super(config, [
            { property: 'idUnitate', errorMessage: 'Nu aţi selectat unitatea!' },
            { property: 'idOrganizareInstitutionala', errorMessage: 'Nu aţi selectat cantina!' },
            { property: 'idOrganizareInstitutionalaPK', errorMessage: 'Nu aţi selectat cantina!' }
        ], States.cantina.configCantinaconfig);
    }

    idUnitate: number;
    /** Departamentul(id-ul comun din tabela unitatiIstoric.OrganizareInstitutionala) */
    idOrganizareInstitutionala: number;
    /** Departamentul(id-ul PK din tabela unitatiIstoric.OrganizareInstitutionala) */
    idOrganizareInstitutionalaPK: number;
}

export class ConfigClienti extends ConfigAchizitii {
    constructor(config: any) {
        super(config);
        this.configState = States.clienti.configConfig;
    }
}

export class ConfigRaportariFinanciare extends ConfigAdministrare {
    constructor(config: any) {
        super(config);
        this.configState = States.raportarifinanciare.configurariConfig
    }
}

export class ConfigInvestitii extends ConfigAchizitii {
    constructor(config: any) {
        super(config);
        this.configState = States.investitii.configurariConfig;
    }
}