import { Injectable } from '@angular/core';
import { DateStruct } from '../date';
import { isInteger } from '../../util';

export function NGB_DATEPICKER_DATE_ADAPTER_FACTORY() {
  return new NgbDateStructAdapter()
}

@Injectable({ providedIn: 'root', useFactory: NGB_DATEPICKER_DATE_ADAPTER_FACTORY })
export abstract class DateAdapter<D> {
  abstract fromModel(value: D | null): DateStruct | null;
  abstract toModel(date: DateStruct | null): D | null;
}

@Injectable()
export class NgbDateStructAdapter extends DateAdapter<DateStruct> {

  fromModel(date: DateStruct | null): DateStruct | null {
    return (date && isInteger(date.year) && isInteger(date.month) && isInteger(date.day))
      ? { year: date.year, month: date.month, day: date.day }
      : null
  }
  toModel(date: DateStruct | null): DateStruct | null {
    return (date && isInteger(date.year) && isInteger(date.month) && isInteger(date.day))
      ? { year: date.year, month: date.month, day: date.day }
      : null
  }
}
