export const routes = [
  {
    "application": {
      "nume": "Administrare sistem"
    },
    "module": {
      "nume": "Dezvoltare"
    },
    "url": "ng/admin/ngtest",
    "icon": "fal fa-file",
    "options": {},
    "title": "Angular2Test",
    "access": "/10/41/9/"
  },
]