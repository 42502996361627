export const States = {
	"admin": {
		"relatiiTabeleEdit": "RelatiiTabeleEdit/id",
		"resursa": "admin/Resursa/jsonParam",
		"resursaControl": "admin/Resursa/control/jsonParam",
		"resursaSetIcon": "admin/Resursa/setIcon/jsonParam",
		"clientStartUp": "client/StartUp",
		"resurseAdaugateRecent": "admin/ResurseAdaugateRecent",
		"dezvoltareActualizare": "admin/dezvoltare/actualizare",
		"dezvoltareBranch": "admin/dezvoltare/branch",
		"ngtest": "ng/admin/ngtest",
		"home": "home",
		"commonSearch": "common/Search/SearchText/returnUrl",
		"clientLogin": "client/Login/returnUrl",
		"cont": "admin/Cont",
		"stop": "admin/Stop/deniedUrl/pageTitle",
		"mentenanta": "admin/Mentenanta/",
		"user": "admin/User/jsonParam",
		"asociereUserPersoana": "AsociereUserPersoana",
		"userAccesLaDate": "admin/userAccesLaDate",
		"userUserorganizareinstitutionala": "admin/user/userorganizareinstitutionala/jsonParam",
		"userDetaliiDrepturiAcces": "admin/User/DetaliiDrepturiAcces/jsonParam",
		"userExtern": "admin/UserExtern",
		"backupBackupFolder": "admin/backup/backupFolder",
		"backupServere": "admin/backup/servere",
		"backupBackupPlan": "admin/backup/backupPlan",
		"backupBackupFile": "admin/backup/backupFile",
		"backupLog": "admin/backup/log",
		"backupConfigurations": "admin/backup/configurations",
		"backupStartup": "admin/backup/startup",
		"backupBackupsigma": "admin/backup/backupsigma/",
		"ghidSigma": "ghid/sigma",
		"ghidAdministrare_sistem": "ghid/administrare_sistem",
		"ghidAdministrare_institutie": "ghid/administrare_institutie",
		"ghidPersoane": "ghid/persoane",
		"ghidAdrese": "ghid/adrese",
		"ghidDocumente": "ghid/documente",
		"ghidBuget": "ghid/buget",
		"ghidContab": "ghid/contab",
		"ghidStocuri_si_imobilizari": "ghid/stocuri_si_imobilizari",
		"ghidRaportari_financiare": "ghid/raportari_financiare",
		"ghidImpotax": "ghid/impotax",
		"ghidIncasari_si_plati": "ghid/incasari_si_plati",
		"ghidAchizitii": "ghid/achizitii",
		"ghidClienti": "ghid/clienti",
		"ghidResurse_umane": "ghid/resurse_umane",
		"ghidSalarii": "ghid/salarii",
		"ghidAsistenta_sociala": "ghid/asistenta_sociala",
		"ghidAgroregis": "ghid/agroregis",
		"ghidContracte": "ghid/contracte",
		"ghidNomenclatoare_generale": "ghid/nomenclatoare_generale",
		"ghidDeploy": "ghid/deploy",
		"ghidConversii_date": "ghid/conversii_date",
		"ghidInvatamant": "ghid/invatamant",
		"ghidCantina": "ghid/cantina",
		"ghidInvestitii": "ghid/investitii",
		"ghidPachetfincon": "ghid/pachetfincon",
		"ghidPachetfinconconfig": "ghid/pachetfinconconfig",
		"ghidPachetfinconflux": "ghid/pachetfinconflux",
		"ghidFluxincasarifaradebitavansat": "ghid/fluxincasarifaradebitavansat",
		"ghidStatplatapers": "ghid/statplatapers",
		"ghidStatplatacolab": "ghid/statplatacolab",
		"ghidStatplataalte": "ghid/statplataalte",
		"ghidFluxdeplasari": "ghid/fluxdeplasari",
		"ghidFluxavansdecontare": "ghid/fluxavansdecontare",
		"rapoarte": "admin/Rapoarte",
		"detaliiRapoarte": "admin/DetaliiRapoarte/path",
		"situatieRapoarte": "admin/SituatieRapoarte",
		"semnaturiSemnaturi": "admin/semnaturi/semnaturi/id",
		"semnaturirapoarte": "admin/semnaturirapoarte/id/jsonParam",
		"antet": "admin/antet/jsonParam",
		"setare": "admin/Setare/jsonParam",
		"setareEdit": "admin/Setare/edit/id/jsonParam",
		"setareUser": "admin/Setare/user/",
		"testtypeaheadTesttypeahead": "admin/testtypeahead/testtypeahead/",
		"relatiiTabele": "RelatiiTabele",
		"importAdrese": "admin/import/adrese",
		"intretinereIntretineredb": "admin/intretinere/intretineredb/jsonParam",
		"reportingConfig": "admin/reporting/config/",
		"debug": "admin/Debug",
		"testdataserviceTestdataservice": "admin/testdataservice/testdataservice/id",
		"systemInfo": "admin/SystemInfo/",
		"testdataserviceDataservicelog": "admin/testdataservice/dataservicelog/",
		"nomenclatoareActualizareNomenclatoare": "admin/nomenclatoare/actualizareNomenclatoare/",
		"relatiiTabeleUnitate": "RelatiiTabeleUnitate",
		"problemaunitate": "problemaunitate/idDocument/codTipProblema/isAmortizariMF/idProdusMF/idOrganizareInstitutionalaLF/anLunaAmortizare",
		"startUp": "startUp"
	},
	"administrareinstitutie": {
		"administrareUnitati": "administrare/Unitati/",
		"administrareUnitatiNew": "administrare/Unitati/new/id/data/jsonParam",
		"administrareOrdonatorCrediteNew": "administrare/OrdonatorCredite/new/id/jsonParam",
		"administrareOrganizareInstitutionala": "administrare/OrganizareInstitutionala/jsonParam",
		"administrareOrganizareInstitutionalaNew": "administrare/OrganizareInstitutionala/new/parentId/id/dataReferinta/jsonParam",
		"administrareOrganigrama": "administrare/Organigrama/id/idContract/jsonParam",
		"administrareOrganigramaPostOrganigrama": "administrare/Organigrama/postOrganigrama/idPost/idDepartament/jsonParam",
		"administrareOrganigramaPostOrganigramaContract": "administrare/Organigrama/postOrganigramaContract/id/idPost/jsonParam",
		"administrareOrganigramaOrganigramaSetari": "administrare/organigrama/OrganigramaSetari/jsonParam",
		"tipvenitTipurivenituri": "administrareinstitutie/tipvenit/tipurivenituri/jsonParam",
		"tipvenitTipurivenituriAsocieri": "administrareinstitutie/tipvenit/tipurivenituri/asocieri/jsonParam",
		"tipvenitTipurivenituriEdit": "administrareinstitutie/tipvenit/tipurivenituri/edit/jsonParam",
		"contabAsociereAECapitolArticol": "Contab/AsociereAECapitolArticol",
		"contabAsociereAECapitolArticolEdit": "Contab/AsociereAECapitolArticolEdit/jsonParam",
		"administrareAsociereClasificatieMultipla": "administrare/asociereClasificatieMultipla/jsonParam",
		"administrareAdaugareAsociereClasificatieMultipla": "administrare/adaugareAsociereClasificatieMultipla/id/jsonParam",
		"administrareAdaugareDetaliuClasificatieMultipla": "administrare/adaugareDetaliuClasificatieMultipla/id/jsonParam",
		"administrareEditareProcenteDetalii": "administrare/editareProcenteDetalii/id/jsonParam"
	},
	"persoane": {
		"nomenclatorPersoane": "persoane/NomenclatorPersoane/detaliiPersoana/jsonParam",
		"persoanaPfa": "persoana/pfa/id/jsonParam",
		"persoanaFiz": "persoana/fiz/id/jsonParam",
		"persoanaBanca": "persoana/Banca/id/jsonParam",
		"persoanaJur": "persoana/jur/id/jsonParam",
		"persoanaunitate": "persoane/persoanaunitate/id/jsonParam",
		"persoanaunitatePersoanainunitatenew": "persoane/persoanaunitate/persoanainunitatenew/id/jsonParam",
		"comisii": "comisii/id",
		"comisiiNew": "comisii/new/id",
		"comisieNew": "persoaneComisie/new/idComisie/id",
		"controloriVizaCFP": "persoane/controloriVizaCFP/jsonParam",
		"controloriVizaCFPnew": "persoane/controloriVizaCFPnew/id/jsonParam",
		"persoanaUnificarepersoane": "persoane/persoana/unificarepersoane/jsonParam",
		"bancaNomenclatorBanci": "persoane/banca/NomenclatorBanci/",
		"iban": "iban/idPersoana/dataReferinta",
		"ibannew": "ibannew/idEntitate/idPersoana/idBanca/dataReferinta/iban/jsonParam",
		"configConfig": "persoane/config/config/",
		"utilitatiAsociereutilitati": "persoane/utilitati/asociereutilitati/id",
		"utilitatiAsociereutilitatiNew": "persoane/utilitati/asociereutilitati/new/id"
	},
	"adrese": {
		"stradaIstoricstrada": "adrese/strada/istoricstrada/jsonParam",
		"stradaAdrese": "adrese/strada/adrese/jsonParam",
		"stradaAdresenew": "adrese/strada/adresenew/jsonParam",
		"zonarefiscala": "adrese/zonarefiscala/jsonParam",
		"regulazonarefiscala": "adrese/regulazonarefiscala/jsonParam",
		"tarlaparcela": "adrese/tarlaparcela/jsonParam",
		"tarlaparcelaNew": "adrese/tarlaparcela/new/jsonParam",
		"zonarefiscalaparcele": "adrese/zonarefiscalaparcele/jsonParam",
		"regulazonarefiscalaparcele": "adrese/regulazonarefiscalaparcele/jsonParam",
		"unificarestrazi": "adrese/unificarestrazi/jsonParam",
		"unificarenumere": "adrese/unificarenumere/jsonParam",
		"unificareblocuri": "adrese/unificareblocuri/jsonParam",
		"unificarescari": "adrese/unificarescari/jsonParam",
		"unificareapartamente": "adrese/unificareapartamente/jsonParam",
		"regiunetip": "adrese/regiunetip/jsonParam",
		"regiunetipNew": "adrese/regiunetip/new/id",
		"regiune": "adrese/regiune/jsonParam",
		"regiuneNew": "adrese/regiune/new/id",
		"zonareadministrativa": "adrese/zonareadministrativa/jsonParam",
		"regulazonareadministrativa": "adrese/regulazonareadministrativa/jsonParam",
		"adreseconfig": "adrese/adreseconfig"
	},
	"documente": {
		"documentFurnizor": "document/furnizor/idFactura/jsonParam",
		"documentNou": "documentNou/tipFactura/idTipDocument/idFactura/idPersoana/isSold/jsonParam",
		"documentfurnizorDetaliuNouMijlocFix": "documentfurnizor/detaliuNouMijlocFix/idFactura/idDetaliu/idTipDocument/idReceptie/idReceptieDetaliu/idProdusTip/jsonParam",
		"documentfurnizorDetaliuNou": "documentfurnizor/detaliuNou/idFactura/idDetaliu/idTipDocument/idReceptie/idReceptieDetaliu/idProdusTip/jsonParam",
		"documentefurnizoriFurnizoriDetaliunouserviciu": "documente/documentefurnizori/furnizori/detaliunouserviciu/idFactura/idDetaliu",
		"documentefurnizoriFurnizoriDetaliunouutilitati": "documente/documentefurnizori/furnizori/detaliunouutilitati/idFactura/idDetaliu",
		"documentefurnizoriFurnizoriDetaliunouobiective": "documente/documentefurnizori/furnizori/detaliunouobiective/idFactura/idDetaliu",
		"documentefurnizoriFurnizoriDetaliunoumodernizari": "documente/documentefurnizori/furnizori/detaliunoumodernizari/idFactura/idDetaliu",
		"documentfurnizorCompletareMultiplaEFactura": "documentfurnizor/completareMultiplaEFactura/idFactura/idDetaliu/jsonParam",
		"documentfurnizorEFacturaReceptionareMateriale": "documentfurnizor/eFacturaReceptionareMateriale/idFactura/idDetaliuList/jsonParam",
		"documentfurnizorEFacturaReceptionareMF": "documentfurnizor/eFacturaReceptionareMF/idFactura/idDetaliuList/jsonParam",
		"documentfurnizorEFacturaReceptionareOI": "documentfurnizor/eFacturaReceptionareOI/idFactura/idDetaliuList/jsonParam",
		"documentfurnizorEFacturaReceptionareAlteValori": "documentfurnizor/eFacturaReceptionareAlteValori/idFactura/idDetaliuList/jsonParam",
		"documentfurnizorEFacturaOperareUtilitati": "documentfurnizor/eFacturaOperareUtilitati/idFactura/idDetaliuList/jsonParam",
		"documentfurnizorEFacturaOperareServicii": "documentfurnizor/eFacturaOperareServicii/idFactura/idDetaliu/idDetaliuList/jsonParam",
		"documentfurnizorEFacturaOperareObiectiveInCurs": "documentfurnizor/eFacturaOperareObiectiveInCurs/idFactura/idDetaliuList/jsonParam",
		"documentfurnizorEFacturaOperareModernizariMF": "documentfurnizor/eFacturaOperareModernizariMF/idFactura/idDetaliuList/jsonParam",
		"documentfurnizorProdusNou": "documentfurnizor/produsNou/idFactura/idDetaliu/idTipDocument/jsonParam",
		"documentefurnizoriAvizeinfactura": "documente/documentefurnizori/avizeinfactura/idFactura",
		"documentefurnizoriAjustareSume": "documente/documentefurnizori/ajustareSume/idFactura",
		"documentefurnizoriFurnizoriDistribuiresume": "documente/documentefurnizori/furnizori/distribuiresume/idDocument",
		"documentefurnizoriFurnizoriDistribuiresumenew": "documente/documentefurnizori/furnizori/distribuiresumenew/id/idDocument/isSoldPartener/isPlatitorTva/isAlteDocumente",
		"documentFurnizorCuSoldInitial": "document/furnizorCuSoldInitial",
		"contracte": "documente/contracte",
		"contractNou": "documente/contractNou/idContract/idTipContract/idPartener/jsonParam",
		"crediteAngajamentMultianual": "documente/crediteAngajamentMultianual/idContract/jsonParam",
		"comenzi": "comenzi/",
		"comandaNoua": "comandaNoua/idComanda/idPartener/idContract",
		"documentefurnizoriFurnizoriFinalizare": "documente/documentefurnizori/furnizori/finalizare/id",
		"clienti": "documente/clienti/",
		"clientiDocumentnou": "documente/clienti/documentnou/id/idDocumentTip",
		"clientiDetaliunou": "documente/clienti/detaliunou/id/tipDetaliu/idDocument",
		"clientiDetaliunoudinstoc": "documente/clienti/detaliunoudinstoc/id/idDocument",
		"clientiRefacturareUtilitati": "documente/clienti/refacturareUtilitati",
		"clientiDebitorNou": "documente/clienti/debitorNou/idDocument",
		"clientiGenerareFacturiClientiRefacturareUtilitati": "documente/clienti/generareFacturiClientiRefacturareUtilitati",
		"clientiFinalizare": "documente/clienti/finalizare/idDocument",
		"documentClientiCuSoldInitial": "document/clientiCuSoldInitial",
		"documenteclientiClientiDistribuiresumenew": "documente/documenteclienti/clienti/distribuiresumenew/id/idDocument/isSoldPartener/isPlatitorTva/isAlteDocumente/idDetaliu",
		"alteoperatiuni": "documente/alteoperatiuni/",
		"alteoperatiuninew": "documente/alteoperatiuninew/id",
		"eFactura": "documente/eFactura/",
		"eFacturaGenerareDocumenteFurnizor": "documente/eFactura/generareDocumenteFurnizor/",
		"executieSalariiImportResum": "executieSalariiImportResum",
		"listaExecutieSalarii": "listaExecutieSalarii",
		"executieSalariiEditare": "executieSalariiEditare/idDocument",
		"executieSalariiCopiere": "executieSalariiCopiere/idDocument",
		"executieSalarii": "executieSalarii/idDocument",
		"executieSalariiCompletareCodIndicatorAngajament": "executieSalariiCompletareCodIndicatorAngajament/idDocument",
		"executieSalariiPlataOP": "executieSalariiPlataOP/idDocument",
		"executieSalariiAsociereStatAvansuri": "executieSalariiAsociereStatAvansuri/idDocument",
		"executieSalariiEditareSuma": "executieSalariiEditareSuma/id/idDocument/idBuget/idBugetClasificatieFunctionala/idFormularClasificatieEconomica",
		"vizacfpTipDocOpCFP": "vizacfp/tipDocOpCFP/",
		"vizacfpAsociere": "vizacfp/asociere/id/jsonParam",
		"vizacfpRegistruVizaCFP": "vizacfp/registruVizaCFP/",
		"vizacfpVizaManual": "vizacfp/vizaManual/idDocument/refuzViza/idDocumentGenerator/an/jsonParam",
		"vizacfpVizareDocumente": "vizacfp/vizareDocumente/",
		"vizacfpAdaugareDocument": "vizacfp/adaugareDocument/",
		"clasificareProduse": "clasificareProduse/",
		"garantiiDeBunaExecutie": "garantiiDeBunaExecutie/jsonParam",
		"garantiiDeBunaExecutieAdaugareConstituireDeCatreFurnizor": "garantiiDeBunaExecutieAdaugareConstituireDeCatreFurnizor/idDocumentGarantieBunaExecutie/idDocumentSubTip",
		"garantiiDeBunaExecutieAdaugareSoldInitial": "garantiiDeBunaExecutieAdaugareSoldInitial/idDocumentGarantieBunaExecutie/idDocumentSubTip",
		"garantiiDeBunaExecutieAdaugareScrisoareGarantieBancara": "garantiiDeBunaExecutieAdaugareScrisoareGarantieBancara/idDocumentGarantieBunaExecutie/idDocumentSubTip",
		"garantiiDeBunaExecutieAdaugarePolitaDeAsigurare": "garantiiDeBunaExecutieAdaugarePolitaDeAsigurare/idDocumentGarantieBunaExecutie/idDocumentSubTip",
		"garantiiDeBunaExecutieAdaugareRestituire": "garantiiDeBunaExecutieAdaugareRestituire/idDocumentGarantieBunaExecutie/idDocumentSubTip",
		"garantiiParticipareLicitatie": "garantiiParticipareLicitatie/jsonParam",
		"garantiiLicitatiiAdaugareSoldInitial": "garantiiLicitatiiAdaugareSoldInitial/idDocumentGarantieLicitatie/idDocumentSubTip",
		"garantiiLicitatieAdaugarePolitaDeAsigurare": "garantiiLicitatieAdaugarePolitaDeAsigurare/idDocumentGarantieLicitatie/idDocumentSubTip",
		"garantiiParticipareLicitatieAdaugareDeblocare": "garantiiParticipareLicitatieAdaugareDeblocare/idDocumentGarantieLicitatie/idDocumentSubTip",
		"garantiiLicitatieAdaugareScrisoareBancara": "garantiiLicitatieAdaugareScrisoareBancara/idDocumentGarantieLicitatie/idDocumentSubTip",
		"rapoarte": "rapoarte/jsonParam",
		"configurariConfig": "documente/configurari/config/",
		"configurariAlocarenumarchitanta": "documente/configurari/alocarenumarchitanta/jsonParam",
		"configurariAlocarenumarchitantaNew": "documente/configurari/alocarenumarchitanta/new/jsonParam",
		"configurariSetarivizacfp": "documente/configurari/setarivizacfp",
		"configurariAlocarenumarchitanta1": "documente/configurari/alocarenumarchitanta1/jsonParam",
		"configurariAlocarenumarchitantaNew1": "documente/configurari/alocarenumarchitanta/new1/jsonParam"
	},
	"buget": {
		"prevederiPrevederi": "buget/prevederi/Prevederi/",
		"prevederiBuget": "buget/PrevederiBuget/",
		"estimariIndiciEstimari": "buget/estimari/indiciEstimari/tip/idBugetRectificare/idBugetClasificatieFunctionala/codSursaNume",
		"executieExecutieExecutiebugetara": "buget/executie/executie/executiebugetara/",
		"executieSupldimangajamentbugetar": "executie/supldimangajamentbugetar/tip/idDocument/idAngajamentBugetar",
		"executieAngajamentbugetar": "executie/angajamentbugetar/idDocument",
		"executieSupldimangajamentlegal": "executie/supldimangajamentlegal/tip/idDocument/idAngajamentLegal",
		"executieAngajamentlegal": "executie/angajamentlegal/idDocument/idAngajamentBugetar",
		"executieCorectieCodAngajamentIndicaor": "executie/corectieCodAngajamentIndicaor/idDocument",
		"executieExecutieLichidari": "buget/executie/executie/lichidari/idDocument/idAngajamentLegal/idAngajamentBugetar",
		"executieOrdonantare": "executie/ordonantare/idDocument/idAngajamentLegal/idLichidare",
		"executieExecutieBugetaraExtrasCont": "executie/executieBugetaraExtrasCont/",
		"executieCererideschidericredite": "executie/cererideschidericredite/id/jsonParam",
		"executieCererideschidericredite_raportcentralizator": "executie/cererideschidericredite_raportcentralizator/id/jsonParam",
		"executieCererideschidericreditenew": "executie/cererideschidericreditenew/id/jsonParam",
		"executieExecutieDeschideridecredite": "buget/executie/executie/deschideridecredite/id/jsonParam",
		"executieExecutieDeschidericreditenew": "executie/executie/deschidericreditenew/id",
		"executieExecutieTransferSolduri": "buget/executie/executie/transferSolduri/",
		"executieExecutieGenerareOrdonantariDocumenteFurnizor": "buget/executie/executie/generareOrdonantariDocumenteFurnizor/",
		"rectificari": "buget/Rectificari/jsonParam",
		"restaurareRectificari": "buget/restaurareRectificari/id",
		"bugetNew": "Buget/Buget/new/idUnitate/id/anBuget/jsonParam",
		"editareRectificare": "editareRectificare/idBuget/id/aprobare/jsonParam",
		"platiCheltuieli": "buget/PlatiCheltuieli",
		"rapoarteListarerapoarte": "buget/rapoarte/listarerapoarte/id/jsonParam",
		"nomenclatorDetalieri": "buget/Nomenclator/detalieri/jsonParam",
		"nomenclatorDetalieriNew": "buget/Nomenclator/detalieri/new/idTree/id/jsonParam",
		"nomenclatorCodAngajament": "buget/Nomenclator/codAngajament/",
		"nomenclatorCodAngajamentNew": "buget/Nomenclator/codAngajament/new/id",
		"formularFormularFormularDetalieri_v2": "buget/formular/formular/formularDetalieri_v2/",
		"formularFormularFormulardetalieriNew": "buget/formular/formular/formulardetalieri/new/id/parentId",
		"formularFormularFormulardetalieriPersonalizare": "buget/formular/formular/formulardetalieri/personalizare/id",
		"formularFormularFormuledetalieri": "buget/formular/formular/formuledetalieri/",
		"formularBugetFormularBuget": "buget/formularBuget/formularBuget/",
		"formularbugetFormularbugetFormularbugetNew": "buget/formularbuget/formularbuget/formularbuget/new/id/parentId",
		"formularBugetFormuleBuget": "buget/FormularBuget/formuleBuget/",
		"configBuget": "buget/ConfigBuget/jsonParam"
	},
	"contab": {
		"actualizareBalantaV2": "contab/ActualizareBalantaV2/toateConturile/conturiInAfaraBilantului/strContSelectat/jsonParam",
		"analitice": "contab/Analitice/id/jsonParam",
		"analiticeAltfelNew": "contab/Analitice/Altfel/new/idCont/idAnalitic/idBalanta/jsonParam",
		"analiticeVenitincasareNew": "contab/Analitice/Venitincasare/new/tipAnalitic/idCont/idAnalitic/idBalanta/jsonParam",
		"analiticeCheltuialafinantareNew": "contab/Analitice/Cheltuialafinantare/new/tipAnalitic/idCont/idAnalitic/idBalanta/jsonParam",
		"analiticeExcedentNew": "contab/Analitice/Excedent/new/idCont/idAnalitic/idBalanta/jsonParam",
		"generareAnalitice": "contab/GenerareAnalitice/id/jsonParam",
		"generareAnaliticeVenit": "contab/generareAnaliticeVenit/id",
		"notePropuse": "contab/notePropuse/idDocument/idSubTipDocument",
		"note": "contab/note/jsonParam",
		"noteCautareAvansata": "contab/noteCautareAvansata/jsonParam",
		"documenteAltedocumente": "documente/altedocumente/",
		"documenteAlteDocumenteNew": "documente/alteDocumenteNew/idTipDocument/idDocument/idPersoana/jsonParam",
		"documenteAlteDocumenteNotaNew": "documente/alteDocumenteNotaNew/id/idDocument/idTipDocument/jsonParam",
		"soldinitialpartener": "contab/soldinitialpartener/jsonParam",
		"inchideri": "contab/Inchideri/",
		"jurnalGeneral": "contab/jurnalGeneral/",
		"jurnalGeneralNew": "contab/jurnalGeneral/new/id",
		"centralizatoareCentralizatorFisaContului": "contab/centralizatoare/centralizatorFisaContului/",
		"vizualizareBalanta": "contab/VizualizareBalanta/",
		"facturiRestanteFurnizori": "contab/facturiRestanteFurnizori/",
		"registruJurnal": "contab/registruJurnal/",
		"registruInventar": "contab/registruInventar/",
		"registruCarteMare": "contab/registruCarteMare/",
		"registruCarteMareSah": "contab/registruCarteMareSah/",
		"situatieparteneriV3": "contab/situatieparteneriV3",
		"diferenteBalantaSitParteneri": "contab/diferenteBalantaSitParteneri/idOrganizareInstitutionala/idOrganizareInstitutionalaCentruCheltuiala/an/dataReferintaStart/dataReferintaStop/contSintetic/a1/a2",
		"generareDebite": "contab/GenerareDebite/idOrganizareInstitutionala/idOrganizareInstitutionalaCentruCheltuiala/an/dataReferintaStart/dataReferintaStop/contSintetic/a1/a2",
		"d394": "Contab/D394/",
		"d394New": "Contab/D394New/id",
		"d394IncasareNew": "Contab/D394IncasareNew/id/idDeclaratie",
		"d394FacturaNew": "Contab/D394FacturaNew/id/idDeclaratie",
		"d394SerieNew": "Contab/D394SerieNew/id/idDeclaratie",
		"fisaInregistrariContabile": "contab/fisaInregistrariContabile/",
		"rapoarteSituatieCheltuieliCentreCheltuiala": "contab/rapoarte/SituatieCheltuieli/CentreCheltuiala/",
		"rapoarteSituatiiZilnice": "contab/rapoarte/situatiiZilnice/",
		"rapoarteJurnaleAnalitice": "contab/rapoarte/jurnaleAnalitice/",
		"rapoarteSituatieFacturiPlatiAferente": "contab/rapoarte/situatieFacturiPlatiAferente/",
		"rapoarteRaportJurnalCumparari": "contab/rapoarte/raportJurnalCumparari/",
		"rapoarteRaportJurnalVanzari": "contab/rapoarte/raportJurnalVanzari/",
		"centralizatoareCentralizatorBalantaSetari": "contab/centralizatoare/centralizatorBalantaSetari/",
		"centralizatoareCentralizatorBalanta": "contab/centralizatoare/centralizatorBalanta/",
		"centralizatoareCentralizatorFisacontuluiSetari": "contab/centralizatoare/centralizatorFisacontuluiSetari/",
		"centralizatoareCentralizatorFisaContului2": "contab/centralizatoare/centralizatorFisaContului2/",
		"centralizatorAE": "Contab/centralizatorAE/",
		"inchidereLuna": "contab/InchidereLuna/",
		"conturiInchidere": "contab/ConturiInchidere/idCont",
		"asociereContInchidere": "contab/AsociereContInchidere/id",
		"cautareDocumente": "contab/cautareDocumente/jsonParam",
		"diferenteSumeBugetConta": "contab/DiferenteSumeBugetConta",
		"conturi": "contab/Conturi/id/jsonParam",
		"conturiNew": "contab/Conturi/new/id/idParinte/jsonParam",
		"jurnale": "contab/Jurnale/jsonParam",
		"jurnaleEditJurnal": "contab/Jurnale/editJurnal/id/jsonParam",
		"sabloaneDeConturi": "contab/sabloaneDeConturi/id/jsonParam",
		"sabloaneDeConturiNew": "contab/SabloaneDeConturi/new/id/jsonParam",
		"sabloaneConturiPeOperatiuni": "contab/sabloaneConturiPeOperatiuni",
		"algoritmiDeGenerareNoteContabile": "contab/algoritmiDeGenerareNoteContabile/jsonParam",
		"algoritmiDeGenerareNoteContabileNew": "contab/algoritmiDeGenerareNoteContabileNew/id/jsonParam",
		"generatorNoteVenituri": "contab/generatorNoteVenituri/id",
		"generatorNoteVenituriNew": "contab/generatorNoteVenituri/new/id",
		"generatorNoteVenituriCopiereSabloane": "contab/generatorNoteVenituriCopiereSabloane/id",
		"sabloaneConturiInchidere": "contab/SabloaneConturiInchidere",
		"adaugaresabloncontinchidere": "contab/adaugaresabloncontinchidere/id",
		"asociereContTipCreditBugetar": "contab/asociereContTipCreditBugetar/jsonParam",
		"asociereContTipCreditBugetarNew": "contab/asociereContTipCreditBugetar/new/jsonParam",
		"asociereContTipCreditBugetarV2": "contab/asociereContTipCreditBugetarV2/jsonParam",
		"asociereContTipCreditBugetarV2New": "contab/asociereContTipCreditBugetarV2/new/jsonParam",
		"asociereContTipCreditBugetarV3": "contab/asociereContTipCreditBugetarV3/jsonParam",
		"asociereContTipCreditBugetarV3New": "contab/asociereContTipCreditBugetarV3/new/jsonParam",
		"conta": "Contab/Conta",
		"grupareConturi": "contab/grupareConturi/",
		"grupareConturiNew": "contab/grupareConturi/new/",
		"setariraportbalanta": "contab/setariraportbalanta/tip/search1/search2/analitic1/analitic2/tipBuget/sufixClasificatieFunctionala/checkInAfaraBilantului/checkBalantaCompleta",
		"setariParametriiRaport": "contab/setariParametriiRaport/tip/search1/search2/analitic1/analitic2/tipBuget/sufixClasificatieFunctionala/organizareInstitutionalaStringIds/checkInAfaraBilantului/checkBalantaCompleta"
	},
	"inventare": {
		"obiectiveInCursDeExecutie": "obiectiveInCursDeExecutie/jsonParam",
		"obiectiveInCursDeExecutiePvobiectiveincursnew": "obiectiveInCursDeExecutie/pvobiectiveincursnew/id/idProdusGrupTip/idOrganizareInstitutionala/jsonParam",
		"obiectiveInCursDeExecutieStocNou": "inventare/obiectiveInCursDeExecutie/stocNou/id/idOrganizareInstitutionala/jsonParam",
		"iesiri": "iesiri/jsonParam",
		"iesiriIesireNoua": "iesiri/iesireNoua/id/idProdusGrupTip/idOrganizareInstitutionala/jsonParam",
		"iesiriIesireDinPropuneri": "iesiri/iesireDinPropuneri/idIesire/idProdusIesire/jsonParam",
		"iesiriOperareProdusDisponibil": "iesiri/operareProdusDisponibil/idIesire/idProdusIesire/jsonParam",
		"transferuri": "transferuri/jsonParam",
		"transferuriTransferurinew": "transferuri/transferurinew/id/idProdusGrupTip/idOrganizareInstitutionala/jsonParam",
		"transferuriMFtoOI": "transferuri/MFtoOI/id/idProdusGrupTip/idOrganizareInstitutionala/jsonParam",
		"transferuriOperareProdusDisponibil": "transferuri/operareProdusDisponibil/idTransfer/jsonParam",
		"transferuriOperareMFtoOI": "transferuri/operareMFtoOI/idTransfer/jsonParam",
		"transferuriOperareMFtoOI_ng2": "inventar/transferuri/operareMFtoOI_ng2",
		"transformari": "transformari/jsonParam",
		"transformariTransformarinew": "transformari/transformarinew/id/idProdusGrupTip/idOrganizareInstitutionala/jsonParam",
		"transformariOperareProdusDisponibil": "transformari/operareProdusDisponibil/idTransformare/idProdusIesire/jsonParam",
		"transformariAdaugaProdusObtinut": "transformari/adaugaProdusObtinut/idOrganizareInstitutionala/jsonParam",
		"restituiri": "restituiri/jsonParam",
		"restituiriRestituirinew": "restituiri/restituirinew/id/idProdusGrupTip/idOrganizareInstitutionala/jsonParam",
		"restituiriOperareProdusIesit": "restituiri/operareProdusIesit/idRestituire/idProdusIesire/jsonParam",
		"dareconsumConsumalimente": "inventare/dareconsum/consumalimente/id",
		"dareconsumConsumalimenteNew": "inventare/dareconsum/consumalimente/new/id",
		"dareconsumConsumalimentesuplimentariNew": "inventare/dareconsum/consumalimentesuplimentari/new/id",
		"necesarAprovizionare": "inventare/necesar/aprovizionare",
		"propuneriDeCasare": "inventare/propuneriDeCasare/id",
		"propuneriPropunereNoua": "propuneri/propunereNoua/id/idProdusGrupTip/idOrganizareInstitutionala/isAreDetalii/jsonParam",
		"propuneriSelectieProduse": "propuneri/selectieProduse/idPropunere/idProdusIesire/jsonParam",
		"catalogmijloacefixe": "inventare/catalogmijloacefixe/jsonParam",
		"catalogmijloacefixeModernizari": "inventare/catalogmijloacefixe/modernizari/id/jsonParam",
		"catalogmijloacefixeModernizarinew": "inventare/catalogmijloacefixe/modernizarinew/id/jsonParam",
		"catalogmijloacefixeScadentar": "inventare/catalogmijloacefixe/scadentar/id/jsonParam",
		"calculamortizari": "inventare/calculamortizari/jsonParam",
		"caracteristiciMijloaceFixe": "inventare/caracteristiciMijloaceFixe/idProdus/jsonParam",
		"catalogmijloacefixeCautaremijlocfix": "inventare/catalogmijloacefixe/cautaremijlocfix/jsonParam",
		"reevaluari": "inventare/reevaluari",
		"reevaluariReevaluarenoua": "inventare/reevaluari/reevaluarenoua/id",
		"stocInitial": "inventare/StocInitial/jsonParam",
		"stocNou": "inventare/stocNou/idOrganizareInstitutionala/jsonParam",
		"receptie": "receptie",
		"receptiiCorectiedetaliere": "inventare/receptii/corectiedetaliere/id/jsonParam",
		"receptiiReceptieNoua": "receptii/receptieNoua/id/idFactura/idProdusGrupTip/idOrganizareInstitutionala/jsonParam",
		"inventar": "Inventare/Inventar/",
		"rapoarteBalanta": "inventare/rapoarte/balanta/id",
		"situatiiSituatiiamortizari": "inventare/situatii/situatiiamortizari/id",
		"rapoarteFisaproduse": "inventare/rapoarte/fisaproduse/id",
		"rapoarteListareraport": "inventare/rapoarte/listareraport/id",
		"categorieProduse": "inventare/categorieProduse/jsonParam",
		"categorieProdusenew": "inventare/categorieProdusenew/idProdus/jsonParam",
		"modOrganizareMF": "inventare/modOrganizareMF/jsonParam",
		"modOrganizareMFnew": "inventare/modOrganizareMFnew/idModOrganizare/jsonParam",
		"tipFinantare": "inventare/tipFinantare/jsonParam",
		"tipFinantareMFnew": "inventare/tipFinantareMFnew/idTipFinantare/jsonParam",
		"registruProduse": "inventare/RegistruProduse/",
		"registruproduseCorectie": "inventare/registruproduse/corectie/id/jsonParam",
		"registruproduseCorectiemultipla": "inventare/registruproduse/corectiemultipla/id/jsonParam",
		"nomenclatorIpc": "inventare/nomenclator/ipc",
		"asociereProdusAliment": "inventare/asociereProdusAliment",
		"registruproduseUnificareproduse": "inventare/registruproduse/unificareproduse/id/jsonParam",
		"setariInventare": "setari/inventare"
	},
	"raportarifinanciare": {
		"contabBalantadeschidere": "raportarifinanciare/contab/balantadeschidere/",
		"contabExecutienontrezor": "raportarifinanciare/contab/executienontrezor",
		"contabBalantaverificare": "raportarifinanciare/contab/balantaverificare/",
		"contabFormular30PlatiRestante": "raportarifinanciare/contab/formular30PlatiRestante/id",
		"contabFormular19": "raportarifinanciare/contab/formular19",
		"contabFormular27": "raportarifinanciare/contab/formular27/id",
		"contabFormular28": "raportarifinanciare/contab/formular28/id",
		"contabFormular40": "raportarifinanciare/contab/formular40/id",
		"contabBalantadecembrie": "raportarifinanciare/contab/balantadecembrie",
		"contabFormular34": "raportarifinanciare/contab/formular34/",
		"contabFormular35a": "raportarifinanciare/contab/formular35a/",
		"contabFormular35c": "raportarifinanciare/contab/formular35c/",
		"contabFormular37": "raportarifinanciare/contab/formular37/",
		"contabAlteanexe": "raportarifinanciare/contab/alteanexe/id",
		"contabRaportarifinanciare": "raportarifinanciare/contab/raportarifinanciare/id/jsonParam",
		"bugetBugetindividual": "raportarifinanciare/buget/bugetindividual/",
		"bugetBugetagregat": "raportarifinanciare/buget/bugetagregat/",
		"bugetExportlotbuget": "raportarifinanciare/buget/exportlotbuget/",
		"bugetExportLoturi": "raportarifinanciare/buget/exportLoturi/",
		"trezorerieContexecutie": "raportarifinanciare/trezorerie/contexecutie",
		"trezorerieCompletaresumacredit": "raportarifinanciare/trezorerie/completaresumacredit",
		"trezorerieActualizaresumacredit": "raportarifinanciare/trezorerie/actualizaresumacredit",
		"trezorerieCorectieCAB": "raportarifinanciare/trezorerie/corectieCAB",
		"trezorerieDocumenteCAB": "raportarifinanciare/trezorerie/documenteCAB",
		"trezorerieSoldInitial": "raportarifinanciare/trezorerie/soldInitial",
		"trezorerieListasoldInitial": "raportarifinanciare/trezorerie/listasoldInitial",
		"configurariConfig": "raportarifinanciare/configurari/config/",
		"configurariFormulare": "raportarifinanciare/configurari/formulare/",
		"exportExportContaAs": "raportarifinanciare/export/exportContaAs",
		"exportExportCurteaDeConturi": "raportarifinanciare/export/exportCurteaDeConturi",
		"darsBilantSituatiiFormulare": "raportarifinanciare/DarsBilantSituatii/formulare",
		"darsBilantSituatiiFormulareDetalii": "raportarifinanciare/DarsBilantSituatii/formulareDetalii",
		"forexebugDocumente": "raportarifinanciare/forexebug/documente"
	},
	"impotax": {
		"casierieRamasitaCasierieRamasita": "impotax/casierie/ramasita/casierie/ramasita/jsonParam",
		"casierieRamasitaCasierieRamasitaNew": "impotax/Casierie/Ramasita/casierie/Ramasita/new/jsonParam",
		"ramasitaScadereMultiple": "ramasita/scadereMultiple/jsonParam",
		"debiteCompensariDebiteBorderoucompensari": "impotax/debite/compensari/debite/borderoucompensari/jsonParam",
		"debiteCompensariDebiteCompensare": "impotax/debite/compensari/debite/compensare/jsonParam",
		"auto": "impotax/Auto/tipPers/id/filtru/idDecl/istoric/nrMat/copy/jsonParam",
		"autoNew": "impotax/Auto/new/tipPers/id/idPers/idDecl/idOp/filtru/copy/jsonParam",
		"autoSearch": "impotax/auto/search/filtru/idPers/idDecl/tip/copy/jsonParam",
		"teren": "impotax/Teren/tipPers/id/filtru/idDecl/istoric/nrMat/copy/jsonParam",
		"terenNew": "impotax/Teren/new/tipPers/id/idPers/idDecl/idOp/filtru/copy/jsonParam",
		"terenSearch": "impotax/Teren/search/filtru/idPers/tip/cod/jsonParam",
		"scutire": "impotax/Scutire/filtru/idPers/idDecl/tip/scutit/copy/jsonParam",
		"coproprietari": "impotax/Coproprietari/tipPers/id/idPers/idDec/filtru/tip/codOrg/jsonParam",
		"cladire": "impotax/Cladire/tipPers/id/filtru/idDecl/istoric/nrMat/copy/jsonParam",
		"cladireNew": "impotax/Cladire/new/tipPers/id/idPers/idDecl/idOp/filtru/copy/jsonParam",
		"amenda": "impotax/Amenda/tipPers/id/idPers/idDecl/filtru/copy/jsonParam",
		"amendaNew": "impotax/Amenda/new/tipPers/id/idPers/idDecl/idOp/filtru/copy/jsonParam",
		"amendaSearch": "impotax/Amenda/search/filtru/idPers/idDecl/copy/jsonParam",
		"autoLente": "impotax/AutoLente/tipPers/id/filtru/idDecl/istoric/nrMat/copy/jsonParam",
		"autoLenteNew": "impotax/AutoLente/new/tipPers/id/idPers/idDecl/idOp/filtru/copy/jsonParam",
		"taxaFirma": "impotax/TaxaFirma/tipPers/id/filtru/idDecl/istoric/nrMat/copy/jsonParam",
		"taxaFirmaNew": "impotax/TaxaFirma/new/tipPers/id/idPers/idDecl/idOp/filtru/copy/jsonParam",
		"alteDebite": "impotax/AlteDebite/tipPers/id/filtru/idDecl/istoric/nrMat/copy/jsonParam",
		"alteDebiteNew": "impotax/AlteDebite/new/tipPers/id/idPers/idDecl/idOp/filtru/copy/jsonParam",
		"testTest": "test/test/",
		"contracteNomenclatorUnitate": "contracte/Nomenclator/unitate/",
		"dUPLICATContracteContract": "DUPLICATContracte/Contract/",
		"valoriValoriCorectieTeren": "impotax/valori/ValoriCorectieTeren/id/jsonParam",
		"valoriValoriDebitExecutareSilita": "impotax/valori/ValoriDebitExecutareSilita/id/jsonParam",
		"valoriValoriAuto": "impotax/valori/ValoriAuto/id/jsonParam",
		"valoriValoriTerenuriAgricole": "impotax/valori/ValoriTerenuriAgricole/id/jsonParam",
		"valoriValoriSpectacole": "impotax/valori/ValoriSpectacole/id/jsonParam",
		"valoriValoriTipAutoLente": "impotax/valori/ValoriTipAutoLente/id/jsonParam",
		"valoriValoriTerenuriExtravilanCateg": "impotax/valori/ValoriTerenuriExtravilanCateg/id/jsonParam",
		"valoriValoriTaxeLunare": "impotax/valori/ValoriTaxeLunare/id/jsonParam",
		"valoriValoriCladiriTipuri": "impotax/valori/ValoriCladiriTipuri/id/jsonParam",
		"menuValori": "impotax/MenuValori/id/jsonParam",
		"valoriValoriTerenuriIntravilan": "impotax/valori/ValoriTerenuriIntravilan/id/jsonParam",
		"valoriValoriTaxaFirma": "impotax/valori/ValoriTaxaFirma/id/jsonParam",
		"valoriValoriReduceriProcenteCladiri": "impotax/valori/ValoriReduceriProcenteCladiri/id/jsonParam",
		"valoriCladiriFiz": "impotax/ValoriCladiriFiz/id/jsonParam",
		"valoriValoriProcenteMajorareCladiriFiz": "impotax/valori/ValoriProcenteMajorareCladiriFiz/id/jsonParam",
		"valoriValoriProcentSubsol": "impotax/valori/ValoriProcentSubsol/id/jsonParam",
		"valoriValoriRangZona": "impotax/valori/ValoriRangZona/id/jsonParam",
		"valoriCladiriJur": "impotax/ValoriCladiriJur/id/jsonParam",
		"valoriValoriCoeficientiMajorareCL": "impotax/valori/ValoriCoeficientiMajorareCL/id/jsonParam",
		"valoriValoripoprire": "impotax/valori/valoripoprire/id",
		"generic": "impotax/Generic/tip/jsonParam",
		"genericNew": "impotax/Generic/new/tip/jsonParam",
		"tipuriScutiri": "impotax/TipuriScutiri/jsonParam",
		"tipuriScutiriNew": "impotax/TipuriScutiri/new/jsonParam",
		"banciPlataPOS": "impotax/BanciPlataPOS/jsonParam",
		"banciPlataPOSNew": "impotax/BanciPlataPOS/new/jsonParam",
		"sarbatoriLegale": "impotax/SarbatoriLegale/jsonParam",
		"sarbatoriLegaleNew": "impotax/SarbatoriLegale/new/jsonParam",
		"tipCertificat": "impotax/TipCertificat/jsonParam",
		"tipCertificatNew": "impotax/TipCertificat/new/jsonParam",
		"procenteDobandaPenalitate": "impotax/ProcenteDobandaPenalitate/tabel/persoana/jsonParam",
		"procenteDobandaPenalitateTranse": "impotax/ProcenteDobandaPenalitateTranse/tabel/persoana/idTipVenit/idPersoana/jsonParam",
		"procenteDobandaPenalitateTranseNew": "impotax/ProcenteDobandaPenalitateTranse/new/tabel/persoana/idTipVenit/idPersoana/nou/newId/jsonParam",
		"menuGenerice": "impotax/MenuGenerice/id/jsonParam",
		"grupuriVenit": "impotax/GrupuriVenit/jsonParam",
		"grupVenit": "impotax/GrupVenit/jsonParam",
		"grupVenitNew": "impotax/GrupVenit/new/jsonParam",
		"borderouPosta": "impotax/BorderouPosta",
		"borderouPostaDocumente": "impotax/BorderouPosta/Documente",
		"borderouPostaDetalii": "impotax/BorderouPosta/Detalii/id/fizjur",
		"borderouPostaStarePosta": "impotax/BorderouPosta/StarePosta/id/fizjur",
		"borderouPostaExplicatie": "impotax/BorderouPosta/Explicatie/id",
		"tipCaenNew": "impotax/TipCaen/new/newId/id",
		"impotaxconfig": "impotax/impotaxconfig/"
	},
	"incasari": {
		"impotaxCasierieCasaCasierieCasaIncasare": "impotax/casierie/casa/casierie/casa/incasare/id/jsonParam",
		"impotaxCasierieCasaCasierieCasaIncasareparametrizataIncasari": "impotax/casierie/casa/casierie/casa/incasareparametrizata/incasari/jsonParam",
		"impotaxCasierieCasaCasierieCasaPlatafaradebitIncasari": "impotax/casierie/casa/casierie/casa/platafaradebit/incasari/jsonParam",
		"impotaxCasierieCasaCasieriePlatafaradebitfarapersoaneIncasari": "impotax/casierie/casa/casierie/platafaradebitfarapersoane/incasari/jsonParam",
		"impotaxCasierieCasaCasierieCasaPlatafaradebitgarantieIncasari": "impotax/casierie/casa/casierie/casa/platafaradebitgarantie/incasari/jsonParam",
		"impotaxCasierieCasaCasierieCasaRestituire": "impotax/casierie/casa/casierie/casa/restituire/jsonParam",
		"impotaxCasierieCasaCasierieCasaRestituiregarantie": "impotax/casierie/casa/casierie/casa/restituiregarantie/jsonParam",
		"impotaxCasierieCasaGestionareincasarifarapersoana": "impotax/casierie/casa/gestionareincasarifarapersoana/jsonParam",
		"impotaxCasierieCasaCasierieCasaIncasarefacturi": "impotax/casierie/casa/casierie/casa/incasarefacturi/jsonParam",
		"impotaxCasierieCasaCasierieCasaPlatadirecta": "impotax/casierie/casa/casierie/casa/platadirecta/id/idDetaliu",
		"impotaxCasierieCasaBorderouincasari": "impotax/casierie/casa/borderouincasari/jsonParam",
		"impotaxCasierieCasaValidareincasaridepunerenumerar": "impotax/casierie/casa/validareincasaridepunerenumerar/id/jsonParam",
		"platiDocumenteDocumentePredarebani": "incasariplati/documente/documente/predarebani/id",
		"platiRegistruCasaSimplificat": "incasariplati/registruCasaSimplificat/id/jsonParam",
		"platiRegistruCasaSimplificatCecridicarenumerarsimplificat": "incasariplati/registruCasaSimplificat/cecridicarenumerarsimplificat/jsonParam",
		"platiRegistruCasaSimplificatPlatafaradebit": "incasariplati/registruCasaSimplificat/platafaradebit/jsonParam",
		"platiRegistruCasaSimplificatIncasarecuchitanta": "incasariplati/registruCasaSimplificat/incasarecuchitanta/jsonParam",
		"platiRegistrucasasimplificatRegularizare": "incasariplati/registrucasasimplificat/regularizare/jsonParam",
		"platiRegistrucasasimplificatPlatafaradebitfarapersoana": "incasariplati/registrucasasimplificat/platafaradebitfarapersoana/jsonParam",
		"platiRegistruCasaSimplificatPlatafaradebitGarantie": "incasariplati/registruCasaSimplificat/platafaradebitGarantie/jsonParam",
		"platiRegistrucasasimplificatRestituiregarantie": "incasariplati/registrucasasimplificat/restituiregarantie/jsonParam",
		"impotaxCasierieCasaSituatieincasari": "impotax/casierie/casa/situatieincasari/jsonParam",
		"platiValidareincasariposExtrascontincasaripos": "incasariplati/validareincasaripos/extrascontincasaripos/jsonParam",
		"platiValidareIncasariPOS": "incasariplati/validareIncasariPOS/jsonParam",
		"listaDocumentePlata": "listaDocumentePlata/idDocumentOrdonantare/idDocumentStatDePlata/jsonParam",
		"documentPlata": "documentPlata/idDocument/idPersoana/jsonParam",
		"documentPlataInAfaraBugetului": "documentPlataInAfaraBugetului/idDocumentSubTip/jsonParam",
		"documentIncasare": "documentIncasare/idDocument",
		"documentPlataRestituireGarantie": "documentPlataRestituireGarantie/jsonParam",
		"documentViramentInternMandatCheltuiala": "documentViramentInternMandatCheltuiala/jsonParam",
		"documentViramentInternMandatVenit": "documentViramentInternMandatVenit/jsonParam",
		"listaDocumentePlataOPME": "listaDocumentePlataOPME/jsonParam",
		"documentPlataOPME": "documentPlataOPME/idDocument/jsonParam",
		"platiDocumenteplataDocumenteplataBorderouop": "incasariplati/documenteplata/documenteplata/borderouop/jsonParam",
		"platiIncasaribancaIncasaribancaListaincasari": "incasariplati/incasaribanca/incasaribanca/listaincasari/jsonParam",
		"platiIncasaribancaAlteincasari": "incasariplati/incasaribanca/alteincasari/jsonParam",
		"platiDocumenteListacec": "incasariplati/documente/listacec/jsonParam",
		"platiDocumenteCecridicarenumeredetaliu": "incasariplati/documente/cecridicarenumeredetaliu/jsonParam",
		"platiDocumenteCecridicarenumere": "incasariplati/documente/cecridicarenumere/jsonParam",
		"platiDocumenteAvansuri": "incasariplati/documente/avansuri/id/jsonParam",
		"platiDocumenteAvansurinew": "incasariplati/documente/avansurinew/id/jsonParam",
		"platiDocumenteDocumenteAvansdecont": "incasariplati/documente/documente/avansdecont/jsonParam",
		"platiDocumenteOrdindeplatanew": "incasariplati/documente/ordindeplatanew/id/idDetaliu/jsonParam",
		"platiDocumenteDeplasari": "incasariplati/documente/deplasari/id/jsonParam",
		"platiDocumenteDeplasarinew": "incasariplati/documente/deplasarinew/id/jsonParam",
		"platiDocumenteAdddocjustificativ": "incasariplati/documente/adddocjustificativ/id/jsonParam",
		"platiDocumenteDispozitiideplata": "incasariplati/documente/dispozitiideplata/id/jsonParam",
		"platiDocumenteDispozitiideplatanew": "incasariplati/documente/dispozitiideplatanew/id/jsonParam",
		"platiDocumenteDispozitiideincasare": "incasariplati/documente/dispozitiideincasare/id/jsonParam",
		"platiDocumenteDispozitiideincasarenew": "incasariplati/documente/dispozitiideincasarenew/id/jsonParam",
		"platiDocumenteFoiDeVarsamant": "incasariplati/documente/FoiDeVarsamant/",
		"platiDocumenteFoiDeVarsamantAdaugare": "incasariplati/documente/FoiDeVarsamantAdaugare/",
		"platiSituatiiRegistruCasa": "incasariplati/situatii/registruCasa/id/jsonParam",
		"platiSituatiiSituatiiBorderoupunctdelucru": "incasariplati/situatii/situatii/borderoupunctdelucru/jsonParams",
		"platiSituatiiSituatiiFisapersoana": "incasariplati/situatii/situatii/fisapersoana/jsonParam",
		"platiDocumenteStatdeplatanumerar": "incasariplati/documente/statdeplatanumerar/id/jsonParam",
		"platiSituatiiSituatiiBorderou": "incasariplati/situatii/situatii/borderou/jsonParam",
		"platiSituatiiSituatiiListaordinplata": "incasariplati/situatii/situatii/listaordinplata/jsonParam",
		"platiSituatiiSituatiiListarerapoarte": "incasariplati/situatii/situatii/listarerapoarte/jsonParam",
		"platiAlteincasariIncasareepay": "incasariplati/alteincasari/incasareepay/jsonParam",
		"platiAlteincasariConfiggpay": "incasariplati/alteincasari/configgpay/jsonParam",
		"platiAlteincasariIncasareghiseul": "incasariplati/alteincasari/incasareghiseul/jsonParam",
		"platiIncasareConfigincasare": "incasariplati/incasare/configincasare",
		"platiAlteincasariConfigghiseul": "incasariplati/alteincasari/configghiseul/",
		"raportarifinanciareTrezorerieExtrascont": "raportarifinanciare/trezorerie/extrascont",
		"raportarifinanciareTrezorerieImportextrascont": "raportarifinanciare/trezorerie/importextrascont",
		"raportarifinanciareTrezorerieTrezorerieExtrascontincasare": "raportarifinanciare/trezorerie/trezorerie/extrascontincasare/jsonParam"
	},
	"achizitii": {
		"propuneri": "achizitii/Propuneri/id",
		"propuneriTrimite": "achizitii/PropuneriTrimite/id/tipDocument/idUnitate/idInreg",
		"propuneriPropuneriv2New": "achizitii/propuneri/propuneriv2/new/id/an/unitate/departament",
		"propuneriPropuneriincadrarecpv": "achizitii/propuneri/propuneriincadrarecpv/jsonParam",
		"propuneriPropuneristerse": "achizitii/propuneri/propuneristerse/jsonParam",
		"propuneriPropuneriistoric": "achizitii/propuneri/propuneriistoric/jsonParam",
		"propuneriDetalii": "achizitii/propuneri/detalii/id/idPropunere/unitate/an/dataAdaugare",
		"propunerifisa": "achizitii/propunerifisa/id",
		"propuneriIncadrareBugetara": "achizitii/Propuneri/incadrareBugetara/idPropunere/idDetaliu/unitate/an/dataAdaugare/",
		"propuneriPropuneriananterior": "achizitii/propuneri/propuneriananterior/jsonParam",
		"programanual": "achizitii/Programanual/id",
		"surseFinantare": "achizitii/SurseFinantare/id",
		"surseFinantarenew": "achizitii/SurseFinantarenew/id/idPozitie",
		"programanualProgramanualv2New": "achizitii/programanual/programanualv2/new/id/idPersoanaUnitate/an/idUnic",
		"programanualProgramanualv2pereferate": "achizitii/programanual/programanualv2pereferate/id/idPersoanaUnitate/an/idUnic/jsonParam",
		"programnew": "achizitii/Programnew/id",
		"propuneriPropunericentralizator": "achizitii/propuneri/propunericentralizator/jsonParam",
		"propuneriAjustarepropuneri": "achizitii/propuneri/ajustarepropuneri/jsonParam",
		"propuneriCentralizatorcpv": "achizitii/propuneri/centralizatorcpv/jsonParam",
		"referateNecesitate": "achizitii/ReferateNecesitate/id",
		"referateNecesitateFisa": "achizitii/ReferateNecesitateFisa/id/nrReferat",
		"referateNecesitateSurseFinantare": "achizitii/ReferateNecesitateSurseFinantare/id/jsonParam",
		"referateNecesitateSurseFinantarenew": "achizitii/ReferateNecesitateSurseFinantarenew/id/idReferat",
		"referateReferatenecesitatev2New": "achizitii/referate/referatenecesitatev2/new/id/an/idPersoana/idDocument",
		"referateReferatenecesitatesterse": "achizitii/referate/referatenecesitatesterse/jsonParam",
		"referateReferatnecesitateistoric": "achizitii/referate/referatnecesitateistoric/jsonParam",
		"referateReferatenecesitatedetaliu": "achizitii/referate/referatenecesitatedetaliu/jsonParam",
		"referateNecesitateDetaliinew": "achizitii/ReferateNecesitateDetaliinew/id/idReferat/unitate/an/dataReferat/cursValutar",
		"referateNecesitateIstoric": "achizitii/ReferateNecesitateIstoric/id/nrReferat",
		"formularAchizitii": "achizitii/formularAchizitii/",
		"formularAchizitiiNew": "achizitii/formularAchizitiiNew/id/an/idUnitate",
		"oferte": "achizitii/oferte/id",
		"proceduriachizitiiOfertenew": "achizitii/proceduriachizitii/ofertenew/id/jsonParam",
		"atasareReferat": "achizitii/atasareReferat/id/an/idUnitate",
		"etape": "achizitii/Etape/idTip/idEtapa/idDocument",
		"criterii": "achizitii/Criterii",
		"criteriinew": "achizitii/Criteriinew/id",
		"achizitii": "Achizitii/Achizitii"
	},
	"clienti": {
		"documenteContracteContracteclientContracteContracteclientNewClienti": "documente/contracte/contracteclient/contracte/contracteclient/new/clienti/jsonParam",
		"documentClient": "document/client",
		"contracteClient": "contracte/client/jsonParam",
		"contracteContracteClientNew": "contracte/contracteClient/new/jsonParam",
		"configConfig": "clienti/config/config/"
	},
	"resum": {
		"pontaj": "resum/pontaj"
	},
	"salarii": {
		"nomenclatorFinantari": "salarii/Nomenclator/Finantari/",
		"nomenclatorFinantariNew": "salarii/Nomenclator/Finantari/new/id"
	},
	"asisoc": {
		"familia": "asisoc/Familia/id",
		"familiaNew": "asisoc/Familia/new/id",
		"registreTipajutor": "asisoc/registre/tipajutor",
		"dgaspcListacerere": "asisoc/dgaspc/listacerere/id",
		"dgaspcDgaspc": "asisoc/dgaspc/dgaspc/id",
		"dgaspcHandicapHandicap": "asisoc/dgaspc/handicap/handicap/id",
		"dgaspcHandicapDgaspcHandicapRaspuns": "asisoc/dgaspc/handicap/dgaspc/handicap/raspuns/id/idTipCerere/idCertificat",
		"dgaspcTichetparcareTicheteparcare": "asisoc/dgaspc/tichetparcare/ticheteparcare/id",
		"dgaspcTicheteparcareTicheteparcareRaspuns": "asisoc/dgaspc/ticheteparcare/ticheteparcare/raspuns/id/idTichet",
		"dgaspcVignetaVigneta": "asisoc/dgaspc/vigneta/vigneta/id",
		"dgaspcVignetaVigneteRaspuns": "asisoc/dgaspc/vigneta/vignete/raspuns/id/idVigneta",
		"dgaspcExtrasCfExtras": "asisoc/dgaspc/extrasCf/extras/id",
		"dgaspcExtrascfExtrascfRaspuns": "asisoc/dgaspc/extrascf/extrascf/raspuns/id/idSolicitareScutireProprietate",
		"dgaspcScutireimpozitScutire": "asisoc/dgaspc/scutireimpozit/scutire/id",
		"dgaspcScutireimpozitScutireRaspuns": "asisoc/dgaspc/scutireimpozit/scutire/raspuns/id/idSolicitareScutireImpozit",
		"dgaspcDetalii": "asisoc/dgaspc/detalii/id/jsonParam",
		"dgaspcCertificatnou": "asisoc/dgaspc/certificatnou/id",
		"asisocConfigAsisoc": "Asisoc/asisoc/configAsisoc"
	},
	"agroregis": {
		"aplicatie": "agroregis/Aplicatie/id"
	},
	"nomenclatoaregenerale": {
		"nomgenSectorbugetarNomenclatorSectorbugetar": "nomgen/sectorbugetar/nomenclator/sectorbugetar/id/jsonParam",
		"nomgenSectorbugetarNomenclatorSectorbugetarNew": "nomgen/sectorbugetar/nomenclator/sectorbugetar/new/id/jsonParam",
		"nomgenSursafinantareNomenclatorSursafinantare": "nomgen/sursafinantare/nomenclator/sursafinantare/id/jsonParam",
		"nomgenSursafinantareNomenclatorSursafinantareNew": "nomgen/sursafinantare/nomenclator/sursafinantare/new/id/jsonParam",
		"nomgenNomenclatorClasificatiefunctionala": "nomgen/nomenclator/clasificatiefunctionala/id/jsonParam",
		"nomgenClasificatiefunctionalaNomenclatorClasificatiefunctionalaNew": "nomgen/clasificatiefunctionala/nomenclator/clasificatiefunctionala/new/idTree/id/jsonParam",
		"nomgenClasificatieeconomicaNomenclatorClasificatieeconomica": "nomgen/clasificatieeconomica/nomenclator/clasificatieeconomica/id/jsonParam",
		"nomgenClasificatieeconomicaNomenclatorClasificatieeconomicaNew": "nomgen/clasificatieeconomica/nomenclator/clasificatieeconomica/new/parentId/id/jsonParam",
		"nomgenCodprogrambugetarNomenclatorCodprogrambugetar": "nomgen/codprogrambugetar/nomenclator/codprogrambugetar/id/jsonParam",
		"nomgenCodprogrambugetarNomenclatorCodprogrambugetarNew": "nomgen/codprogrambugetar/nomenclator/codprogrambugetar/new/id/jsonParam",
		"nomgenCursvalutarCursvalutar": "nomgen/cursvalutar/cursvalutar/id",
		"nomgenClasificareproduse": "nomgen/clasificareproduse/id/jsonParam",
		"nomgenClasificareproduseClasificarenoua": "nomgen/clasificareproduse/clasificarenoua/idParinte/jsonParam",
		"nomgenFunctiiFunctii": "nomgen/functii/functii/id/jsonParam",
		"nomgenFunctiiFunctiiNew": "nomgen/functii/functii/new/parentId/id/dataReferinta/jsonParam",
		"nomgenUtilitatiNomenclatorTipuriutilitati": "nomgen/utilitati/nomenclator/tipuriutilitati/id",
		"nomgenUtilitatiNomenclatorTipuriutilitatiNew": "nomgen/utilitati/nomenclator/tipuriutilitati/new/id",
		"nomgenUtilitatiNomenclatorTipuriservicii": "nomgen/utilitati/nomenclator/tipuriservicii/id",
		"nomgenUtilitatiNomenclatorTipuriserviciiNew": "nomgen/utilitati/nomenclator/tipuriservicii/new/id",
		"nomgenUtilitatiNomenclatorTipuriplatinumerar": "nomgen/utilitati/nomenclator/tipuriplatinumerar/id",
		"nomgenUtilitatiNomenclatorTipuriplatinumerarNew": "nomgen/utilitati/nomenclator/tipuriplatinumerar/new/id",
		"nomgenUtilitatiNomenclatorTipuriplatinumerarPersonalizare": "nomgen/utilitati/nomenclator/tipuriplatinumerar/personalizare/id",
		"nomgenCpv": "nomgen/cpv/",
		"nomgenTipuriVenituri": "nomgen/TipuriVenituri/jsonParam",
		"nomgenTipuriVenituriNew": "nomgen/TipuriVenituri/new/jsonParam",
		"nomgenDocumenteOperatiuniCFP": "nomgen/documente/operatiuniCFP/id",
		"nomgenDocumenteOperatiuniCFPNew": "nomgen/documente/operatiuniCFP/new/id/jsonParam",
		"nomgenDocumenteOperatiuniCFPCAsociereClasificatieEconomica": "nomgen/documente/operatiuniCFPCAsociereClasificatieEconomica/id/jsonParam",
		"nomgenDocumenteNomenclatorOperatiuniproprii": "nomgen/documente/nomenclator/operatiuniproprii/",
		"nomgenDocumenteNomenclatorOperatiunipropriiNew": "nomgen/documente/nomenclator/operatiuniproprii/new/id",
		"nomgenDocumenteNomenclatorOperatiunipropriiDetaliunew": "nomgen/documente/nomenclator/operatiuniproprii/detaliunew/id",
		"nomgenSabloaneDeConturi": "nomgen/sabloaneDeConturi/id/jsonParam",
		"nomgenSabloaneDeConturiNew": "nomgen/SabloaneDeConturi/new/id/jsonParam",
		"nomgenSabloaneConturiPeOperatiuni": "nomgen/sabloaneConturiPeOperatiuni",
		"nomgenAsociereContBancaCuTipBuget": "nomgen/asociereContBancaCuTipBuget/",
		"nomgenAsociereContBancaCuTipBugetNew": "nomgen/asociereContBancaCuTipBuget/new/id",
		"nomgenDarsbilantsituatiiDarsbilantsituatiiFormulardars": "nomgen/darsbilantsituatii/darsbilantsituatii/formulardars"
	},
	"deploy": {
		"databaseScript": "deploy/database/Script",
		"databaseValidate": "deploy/database/Validate",
		"dateGenerarePachete": "deploy/date/generarePachete/jsonParam",
		"dateGenerareLogDataService": "deploy/date/generareLogDataService/jsonParam",
		"dateRegistruImportDate": "deploy/date/registruImportDate/jsonParam",
		"dateRegistruImportDateNew": "deploy/date/registruImportDate/new/jsonParam",
		"surseDeploySurse": "deploy/surse/deploySurse",
		"surseValidaresurse": "deploy/surse/validaresurse",
		"administrare": "deploy/administrare"
	},
	"conversiidate": {
		"wizardWelcome": "conversiidate/wizard/Welcome/",
		"wizardSetup": "conversiidate/wizard/Setup/",
		"wizardPersoane": "conversiidate/wizard/Persoane/",
		"wizardUnitati": "conversiidate/wizard/Unitati/",
		"wizardBanci": "conversiidate/wizard/Banci/",
		"wizardBuget": "conversiidate/wizard/Buget/",
		"wizardGestStoc": "conversiidate/wizard/GestStoc",
		"wizardMiFix": "conversiidate/wizard/MiFix",
		"wizardContab": "conversiidate/wizard/Contab",
		"wizardFinalSetup": "conversiidate/wizard/FinalSetup/",
		"wizardVerificare": "conversiidate/wizard/verificare/"
	},
	"invatamant": {
		"grupe": "invatamant/grupe/jsonParam",
		"grupaGrupanew": "invatamant/grupa/grupanew/id/idGrupa/jsonParam",
		"prezenta": "invatamant/prezenta/",
		"rapoarteRestante": "invatamant/rapoarte/restante/jsonParam",
		"rapoarteValabilitateFlotant": "invatamant/rapoarte/valabilitateFlotant/",
		"configurariConfig": "invatamant/configurari/config/jsonParam",
		"configurariVacante": "invatamant/configurari/vacante/jsonParam",
		"configurariVacanteNew": "invatamant/configurari/vacante/new/id/jsonParam"
	},
	"cantina": {
		"cantinaPreparate": "cantina/cantina/preparate/jsonParam",
		"cantinaPreparateNew": "cantina/cantina/preparate/new/jsonParam",
		"cantinaMeniu": "cantina/cantina/meniu/jsonParam",
		"cantinaMeniuNew": "cantina/cantina/meniu/new/jsonParam",
		"cantinaPlanificare": "cantina/cantina/planificare/jsonParam",
		"planificareNew": "cantina/planificare/new/id/jsonParam",
		"cantinaListaalimenteperioada": "cantina/cantina/listaalimenteperioada/jsonparam",
		"cantinaListaalimentebucatar": "cantina/cantina/listaalimentebucatar/jsonParam",
		"cantinaListazilnicaalimente": "cantina/cantina/listazilnicaalimente",
		"cantinaAlergen": "cantina/cantina/alergen/jsonParam",
		"cantinaAlergenNew": "cantina/cantina/alergen/new/jsonParam",
		"cantinaAlimente": "cantina/cantina/alimente/jsonParam",
		"cantinaAlimenteNew": "cantina/cantina/alimente/new/jsonParam",
		"categoriiPersoane": "cantina/categorii/persoane",
		"categoriiPersoaneNew": "cantina/categorii/persoane/new",
		"oraservire": "cantina/oraservire/",
		"oraservireNew": "cantina/oraservire/new/",
		"preparat": "cantina/preparat",
		"categorieNew": "cantina/categorie/new/",
		"configCantinaconfig": "cantina/config/cantinaconfig/jsonParam",
		"configSoldinitial": "cantina/config/soldinitial/id",
		"configAsocierecategorii": "cantina/config/asocierecategorii",
		"asocierecategorieNew": "cantina/asocierecategorie/new",
		"configListasolduri": "cantina/config/listasolduri/id"
	},
	"investitii": {
		"obiectiveObiectivnou": "investitii/obiective/obiectivnou/idObiectiv/idSubobiectiv/isSubobiectiv",
		"obiective": "investitii/obiective",
		"obiectiveCompletaresolduriinitiale": "investitii/obiective/completaresolduriinitiale/id/jsonParam",
		"configurariConfig": "investitii/configurari/config/"
	},
	"dev": {
		"setari": "dev/setari",
		"resursa": "dev/resursa",
		"resursaControl": "dev/resursa/control",
		"componentSamples": "dev/component/samples",
		"deploy": "dev/deploy",
		"releaseValidare": "dev/release/validare"
	},
	"user": {
		"cont": "user/cont",
		"setare": "user/setare",
		"theme": "user/theme",
		"notificari": "user/notificari",
		"email": "user/email",
		"accessDenied": "user/access/denied/page"
	}
}