import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Entity } from 'breeze-client';

import { DataService } from '../../services/DataService';

@Component({
    selector: 'dialog-incetare', templateUrl: './dialog.incetare.html',
})
export class IncetareInregistrare implements OnInit {
    constructor(public activeModal: NgbActiveModal, private data: DataService) { }

    @Input() message: string;
    @Input() entity: Entity[];
    @Input() minDate: Date;
    @Input() dataAnulare: Date;

    @Input() dateMode: 'day' | 'month' | 'year';
    @Input() isReadOnly: boolean;
    @Input() proprietateStop: string;
    @Input() isProprietateStopInt: boolean;

    dateOptions = {};

    ngOnInit() {
        const mode = /s$/.test(this.dateMode) ? this.dateMode : this.dateMode + 's';
        if (mode != 'days') {
            this.dateOptions['view'] = mode;
            this.dateOptions['minView'] = mode;
        }
        if (!this.dataAnulare) { this.dataAnulare = new Date() }
        if (!this.message) {
            this.message = 'Înregistrarea va fi valabilă până '.concat(
                mode == 'years' ? 'la sfârșitul anului: ' : (mode == 'months' ? 'la sfarsitul lunii: ' : 'în data (inclusiv): ')
            )
        }
    }

    dateSelect(params) {
        this.dataAnulare = params.date;
        this.save();
    }

    cancel() { this.activeModal.dismiss('close') }
    save() {
        const mode = /s$/.test(this.dateMode) ? this.dateMode : this.dateMode + 's';
        const year = this.dataAnulare.getFullYear();
        let month = 0, day = 1;
        if (mode == 'months' || mode == 'days') {
            month = (this.dataAnulare.getMonth() + 1)
        }
        if (mode == 'days') {
            day = this.dataAnulare.getDate()
        }

        for (var i = 0; i < this.entity.length; i++) {
            this.entity[i][this.proprietateStop] = this.isProprietateStopInt
                ? (mode == 'days' ? ((year * 100 + month) * 100 + day) : (year * 100 + month))
                : this.dataAnulare
        }
        this.data.saveChanges(this.entity, 'Inregistrarea a fost incetata.').then(result => {
            this.activeModal.close(result.entities)
        })
    }
}