import { Injectable } from "@angular/core";
import { Location } from '@angular/common';
import { ToastrService, IndividualConfig } from 'ngx-toastr';
import { environment } from 'src/environments/environment.dev';

@Injectable({ providedIn: 'root' })
export class LogService {
    constructor(private location: Location, private toastr: ToastrService) { }

    clearAll() { this.toastr.clear() }
    clearToast(toastId: number) { this.toastr.clear(toastId) }

    info(message: string, title?: string, data?: any, overrideOptions?: Partial<IndividualConfig>) {
        return this.message(message, data, 'info', title, overrideOptions)
    }
    warning(message: string, title?: string, data?: any, overrideOptions?: Partial<IndividualConfig>) {
        return this.message(message, data, 'warning', title, overrideOptions)
    }
    success(message: string, title?: string, data?: any, overrideOptions?: Partial<IndividualConfig>) {
        return this.message(message, data, 'success', title, overrideOptions)
    }
    error(message: string, title?: string, data?: any, overrideOptions?: Partial<IndividualConfig>) {
        return this.message(message, data, 'error', title, overrideOptions)
    }
    custom(message: string, title?: string, data?: any, overrideOptions?: Partial<IndividualConfig>, cssClass?: string) {
        if (data) { console.log(message, data) }
        return this.toastr.show(message, title, overrideOptions, cssClass);
    }
    progress(message: string, title?: string, data?: any, overrideOptions?: Partial<IndividualConfig>) {
        if (!overrideOptions) { overrideOptions = {} }
        overrideOptions.enableHtml = true;
        const iSpinner = '<i class="fa fa-2x fa-spin fa-pulse fa-spinner me-2"></i>';
        message = `<div class="d-flex align-items-center">${iSpinner}<span class="lead">${message}</span></div>`;
        return this.message(message, data, 'progress', title, overrideOptions);
    }

    private message(message: string, data: any, toastType: string, title?: string, overrideOptions?: Partial<IndividualConfig>) {
        // if (!environment.production) {
        //     let logType = toastType === 'error' ? 'error' : 'log';
        //     console[logType](this.location.path(true));
        //     if (data) { console[logType](message, data) }
        // }
        switch (toastType) {
            case 'error': return this.toastr.error(message, title, overrideOptions);
            case 'warning': return this.toastr.warning(message, title, overrideOptions);
            case 'success': return this.toastr.success(message, title, overrideOptions);
            case 'progress': return this.toastr.show(message, title, overrideOptions, 'bg-info toast-w400 px-3');
            default: return this.toastr.info(message, title, overrideOptions);
        }
    }
}