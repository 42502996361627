import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  FormStyle, getLocaleDayNames, getLocaleMonthNames, TranslationWidth, formatDate
} from '@angular/common';

import { DateStruct } from './date';

export function DATEPICKER_18N_FACTORY(locale) { return new DatepickerI18nDefault(locale) }

@Injectable({ providedIn: 'root', useFactory: DATEPICKER_18N_FACTORY, deps: [LOCALE_ID] })
export abstract class DatepickerI18n {

  /** Returns the weekday name. Default calendar uses: ISO 8601: 'weekday' is 1=Mon ... 7=Sun. */
  abstract getWeekdayFullName(weekday: number): string;
  /** Returns the abbreviated weekday name. Default calendar uses: ISO 8601: 'weekday' is 1=Mon ... 7=Sun. */
  abstract getWeekdayAbbrName(weekday: number): string;
  /** Returns the short weekday name. Default calendar uses: ISO 8601: 'weekday' is 1=Mon ... 7=Sun. */
  abstract getWeekdayShortName(weekday: number): string;

  /** Returns the short month name. Default calendar uses: ISO 8601: 'month' is 1=Jan ... 12=Dec. */
  abstract getMonthShortName(month: number, year?: number): string;
  /** Returns the full month name. Default calendar uses: ISO 8601: 'month' is 1=Jan ... 12=Dec. */
  abstract getMonthFullName(month: number, year?: number): string;
  
  /** Returns the value of the `aria-label` attribute for a specific date. */
  abstract getDayAriaLabel(date: DateStruct): string;
  /** Returns the textual representation of a day that is rendered in a day cell. */
  getDayNumerals(date: DateStruct): string { return `${date.day}`; }
  /** Returns the textual representation of a week number rendered by datepicker. */
  getWeekNumerals(weekNumber: number): string { return `${weekNumber}`; }
  /** Returns the textual representation of a year that is rendered in the datepicker year select box. */
  getYearNumerals(year: number): string { return `${year}`; }
}

@Injectable()
export class DatepickerI18nDefault extends DatepickerI18n {
  private _weekdaysFull: Array<string>;
  private _weekdaysAbbr: Array<string>;
  private _weekdaysShort: Array<string>;

  private _monthsShort//: Array<string>;
  private _monthsFull//: Array<string>;

  constructor(@Inject(LOCALE_ID) private locale: string) {
    super();

    const weekdaysStartingOnSunday = getLocaleDayNames(locale, FormStyle.Standalone, TranslationWidth.Short);
    const weekdaysStartingOnSundayAbbr = getLocaleDayNames(locale, FormStyle.Standalone, TranslationWidth.Abbreviated);
    const weekdaysStartingOnSundayWide = getLocaleDayNames(locale, FormStyle.Standalone, TranslationWidth.Wide);

    this._weekdaysShort = weekdaysStartingOnSunday.map((day, index) => weekdaysStartingOnSunday[(index + 1) % 7]);
    this._weekdaysAbbr = weekdaysStartingOnSundayAbbr.map((day, index) => weekdaysStartingOnSundayAbbr[(index + 1) % 7]);
    this._weekdaysFull = weekdaysStartingOnSundayWide.map((day, index) => weekdaysStartingOnSundayWide[(index + 1) % 7]);

    this._monthsShort = getLocaleMonthNames(locale, FormStyle.Standalone, TranslationWidth.Abbreviated);
    this._monthsFull = getLocaleMonthNames(locale, FormStyle.Standalone, TranslationWidth.Wide);
  }

  getWeekdayFullName(weekday: number): string { return this._weekdaysFull[weekday - 1] || '' }
  getWeekdayAbbrName(weekday: number): string { return this._weekdaysAbbr[weekday - 1] || '' }
  getWeekdayShortName(weekday: number): string { return this._weekdaysShort[weekday - 1] || '' }

  getMonthShortName(month: number): string { return this._monthsShort[month - 1] || '' }
  getMonthFullName(month: number): string { return this._monthsFull[month - 1] || '' }

  getDayAriaLabel(date: DateStruct): string {
    return formatDate(new Date(date.year, date.month - 1, date.day), 'fullDate', this.locale)
  }
}