export const States2 = {
	admin: {
		relatiiTabeleEdit: function(id: number, ...re) { return ['ng/admin/RelatiiTabeleEdit',id].concat(re) },
		resursa: function(jsonParam, ...re) { return ['ng/admin/admin/Resursa',jsonParam].concat(re) },
		resursaControl: function(jsonParam, ...re) { return ['ng/admin/admin/Resursa/control',jsonParam].concat(re) },
		resursaSetIcon: function(jsonParam, ...re) { return ['ng/admin/admin/Resursa/setIcon',jsonParam].concat(re) },
		clientStartUp: function(...re) { return ['ng/admin/client/StartUp'].concat(re) },
		resurseAdaugateRecent: function(...re) { return ['ng/admin/admin/ResurseAdaugateRecent'].concat(re) },
		dezvoltareActualizare: function(...re) { return ['ng/admin/admin/dezvoltare/actualizare'].concat(re) },
		dezvoltareBranch: function(...re) { return ['ng/admin/admin/dezvoltare/branch'].concat(re) },
		ngtest: function(...re) { return ['ng/admin/ngtest'].concat(re) },
		home: function(...re) { return ['ng/admin/home'].concat(re) },
		commonSearch: function(SearchText, returnUrl, ...re) { return ['ng/admin/common/Search',SearchText,returnUrl].concat(re) },
		clientLogin: function(returnUrl, ...re) { return ['ng/admin/client/Login',returnUrl].concat(re) },
		cont: function(...re) { return ['ng/admin/admin/Cont'].concat(re) },
		stop: function(deniedUrl, pageTitle, ...re) { return ['ng/admin/admin/Stop',deniedUrl,pageTitle].concat(re) },
		mentenanta: function(...re) { return ['ng/admin/admin/Mentenanta/'].concat(re) },
		user: function(jsonParam, ...re) { return ['ng/admin/admin/User',jsonParam].concat(re) },
		asociereUserPersoana: function(...re) { return ['ng/admin/AsociereUserPersoana'].concat(re) },
		userAccesLaDate: function(...re) { return ['ng/admin/admin/userAccesLaDate'].concat(re) },
		userUserorganizareinstitutionala: function(jsonParam, ...re) { return ['ng/admin/admin/user/userorganizareinstitutionala',jsonParam].concat(re) },
		userDetaliiDrepturiAcces: function(jsonParam, ...re) { return ['ng/admin/admin/User/DetaliiDrepturiAcces',jsonParam].concat(re) },
		userExtern: function(...re) { return ['ng/admin/admin/UserExtern'].concat(re) },
		backupBackupFolder: function(...re) { return ['ng/admin/admin/backup/backupFolder'].concat(re) },
		backupServere: function(...re) { return ['ng/admin/admin/backup/servere'].concat(re) },
		backupBackupPlan: function(...re) { return ['ng/admin/admin/backup/backupPlan'].concat(re) },
		backupBackupFile: function(...re) { return ['ng/admin/admin/backup/backupFile'].concat(re) },
		backupLog: function(...re) { return ['ng/admin/admin/backup/log'].concat(re) },
		backupConfigurations: function(...re) { return ['ng/admin/admin/backup/configurations'].concat(re) },
		backupStartup: function(...re) { return ['ng/admin/admin/backup/startup'].concat(re) },
		backupBackupsigma: function(...re) { return ['ng/admin/admin/backup/backupsigma/'].concat(re) },
		ghidSigma: function(...re) { return ['ng/admin/ghid/sigma'].concat(re) },
		ghidAdministrare_sistem: function(...re) { return ['ng/admin/ghid/administrare_sistem'].concat(re) },
		ghidAdministrare_institutie: function(...re) { return ['ng/admin/ghid/administrare_institutie'].concat(re) },
		ghidPersoane: function(...re) { return ['ng/admin/ghid/persoane'].concat(re) },
		ghidAdrese: function(...re) { return ['ng/admin/ghid/adrese'].concat(re) },
		ghidDocumente: function(...re) { return ['ng/admin/ghid/documente'].concat(re) },
		ghidBuget: function(...re) { return ['ng/admin/ghid/buget'].concat(re) },
		ghidContab: function(...re) { return ['ng/admin/ghid/contab'].concat(re) },
		ghidStocuri_si_imobilizari: function(...re) { return ['ng/admin/ghid/stocuri_si_imobilizari'].concat(re) },
		ghidRaportari_financiare: function(...re) { return ['ng/admin/ghid/raportari_financiare'].concat(re) },
		ghidImpotax: function(...re) { return ['ng/admin/ghid/impotax'].concat(re) },
		ghidIncasari_si_plati: function(...re) { return ['ng/admin/ghid/incasari_si_plati'].concat(re) },
		ghidAchizitii: function(...re) { return ['ng/admin/ghid/achizitii'].concat(re) },
		ghidClienti: function(...re) { return ['ng/admin/ghid/clienti'].concat(re) },
		ghidResurse_umane: function(...re) { return ['ng/admin/ghid/resurse_umane'].concat(re) },
		ghidSalarii: function(...re) { return ['ng/admin/ghid/salarii'].concat(re) },
		ghidAsistenta_sociala: function(...re) { return ['ng/admin/ghid/asistenta_sociala'].concat(re) },
		ghidAgroregis: function(...re) { return ['ng/admin/ghid/agroregis'].concat(re) },
		ghidContracte: function(...re) { return ['ng/admin/ghid/contracte'].concat(re) },
		ghidNomenclatoare_generale: function(...re) { return ['ng/admin/ghid/nomenclatoare_generale'].concat(re) },
		ghidDeploy: function(...re) { return ['ng/admin/ghid/deploy'].concat(re) },
		ghidConversii_date: function(...re) { return ['ng/admin/ghid/conversii_date'].concat(re) },
		ghidInvatamant: function(...re) { return ['ng/admin/ghid/invatamant'].concat(re) },
		ghidCantina: function(...re) { return ['ng/admin/ghid/cantina'].concat(re) },
		ghidInvestitii: function(...re) { return ['ng/admin/ghid/investitii'].concat(re) },
		ghidPachetfincon: function(...re) { return ['ng/admin/ghid/pachetfincon'].concat(re) },
		ghidPachetfinconconfig: function(...re) { return ['ng/admin/ghid/pachetfinconconfig'].concat(re) },
		ghidPachetfinconflux: function(...re) { return ['ng/admin/ghid/pachetfinconflux'].concat(re) },
		ghidFluxincasarifaradebitavansat: function(...re) { return ['ng/admin/ghid/fluxincasarifaradebitavansat'].concat(re) },
		ghidStatplatapers: function(...re) { return ['ng/admin/ghid/statplatapers'].concat(re) },
		ghidStatplatacolab: function(...re) { return ['ng/admin/ghid/statplatacolab'].concat(re) },
		ghidStatplataalte: function(...re) { return ['ng/admin/ghid/statplataalte'].concat(re) },
		ghidFluxdeplasari: function(...re) { return ['ng/admin/ghid/fluxdeplasari'].concat(re) },
		ghidFluxavansdecontare: function(...re) { return ['ng/admin/ghid/fluxavansdecontare'].concat(re) },
		rapoarte: function(...re) { return ['ng/admin/admin/Rapoarte'].concat(re) },
		detaliiRapoarte: function(path, ...re) { return ['ng/admin/admin/DetaliiRapoarte',path].concat(re) },
		situatieRapoarte: function(...re) { return ['ng/admin/admin/SituatieRapoarte'].concat(re) },
		semnaturiSemnaturi: function(id: number, ...re) { return ['ng/admin/admin/semnaturi/semnaturi',id].concat(re) },
		semnaturirapoarte: function(id: number, jsonParam, ...re) { return ['ng/admin/admin/semnaturirapoarte',id,jsonParam].concat(re) },
		antet: function(jsonParam, ...re) { return ['ng/admin/admin/antet',jsonParam].concat(re) },
		setare: function(jsonParam, ...re) { return ['ng/admin/admin/Setare',jsonParam].concat(re) },
		setareEdit: function(id: number, jsonParam, ...re) { return ['ng/admin/admin/Setare/edit',id,jsonParam].concat(re) },
		setareUser: function(...re) { return ['ng/admin/admin/Setare/user/'].concat(re) },
		testtypeaheadTesttypeahead: function(...re) { return ['ng/admin/admin/testtypeahead/testtypeahead/'].concat(re) },
		relatiiTabele: function(...re) { return ['ng/admin/RelatiiTabele'].concat(re) },
		importAdrese: function(...re) { return ['ng/admin/admin/import/adrese'].concat(re) },
		intretinereIntretineredb: function(jsonParam, ...re) { return ['ng/admin/admin/intretinere/intretineredb',jsonParam].concat(re) },
		reportingConfig: function(...re) { return ['ng/admin/admin/reporting/config/'].concat(re) },
		debug: function(...re) { return ['ng/admin/admin/Debug'].concat(re) },
		testdataserviceTestdataservice: function(id: number, ...re) { return ['ng/admin/admin/testdataservice/testdataservice',id].concat(re) },
		systemInfo: function(...re) { return ['ng/admin/admin/SystemInfo/'].concat(re) },
		testdataserviceDataservicelog: function(...re) { return ['ng/admin/admin/testdataservice/dataservicelog/'].concat(re) },
		nomenclatoareActualizareNomenclatoare: function(...re) { return ['ng/admin/admin/nomenclatoare/actualizareNomenclatoare/'].concat(re) },
		relatiiTabeleUnitate: function(...re) { return ['ng/admin/RelatiiTabeleUnitate'].concat(re) },
		problemaunitate: function(idDocument: number, codTipProblema, isAmortizariMF: boolean, idProdusMF: number, idOrganizareInstitutionalaLF: number, anLunaAmortizare, ...re) { return ['ng/admin/problemaunitate',idDocument,codTipProblema,isAmortizariMF,idProdusMF,idOrganizareInstitutionalaLF,anLunaAmortizare].concat(re) },
		startUp: function(...re) { return ['ng/admin/startUp'].concat(re) }
	},
	administrareinstitutie: {
		administrareUnitati: function(...re) { return ['/administrare/Unitati/'].concat(re) },
		administrareUnitatiNew: function(id: number, data: string|Date, jsonParam, ...re) { return ['/administrare/Unitati/new',id,data,jsonParam].concat(re) },
		administrareOrdonatorCrediteNew: function(id: number, jsonParam, ...re) { return ['/administrare/OrdonatorCredite/new',id,jsonParam].concat(re) },
		administrareOrganizareInstitutionala: function(jsonParam, ...re) { return ['/administrare/OrganizareInstitutionala',jsonParam].concat(re) },
		administrareOrganizareInstitutionalaNew: function(parentId, id: number, dataReferinta: string|Date, jsonParam, ...re) { return ['/administrare/OrganizareInstitutionala/new',parentId,id,dataReferinta,jsonParam].concat(re) },
		administrareOrganigrama: function(id: number, idContract: number, jsonParam, ...re) { return ['/administrare/Organigrama',id,idContract,jsonParam].concat(re) },
		administrareOrganigramaPostOrganigrama: function(idPost: number, idDepartament: number, jsonParam, ...re) { return ['/administrare/Organigrama/postOrganigrama',idPost,idDepartament,jsonParam].concat(re) },
		administrareOrganigramaPostOrganigramaContract: function(id: number, idPost: number, jsonParam, ...re) { return ['/administrare/Organigrama/postOrganigramaContract',id,idPost,jsonParam].concat(re) },
		administrareOrganigramaOrganigramaSetari: function(jsonParam, ...re) { return ['/administrare/organigrama/OrganigramaSetari',jsonParam].concat(re) },
		tipvenitTipurivenituri: function(jsonParam, ...re) { return ['/administrareinstitutie/tipvenit/tipurivenituri',jsonParam].concat(re) },
		tipvenitTipurivenituriAsocieri: function(jsonParam, ...re) { return ['/administrareinstitutie/tipvenit/tipurivenituri/asocieri',jsonParam].concat(re) },
		tipvenitTipurivenituriEdit: function(jsonParam, ...re) { return ['/administrareinstitutie/tipvenit/tipurivenituri/edit',jsonParam].concat(re) },
		contabAsociereAECapitolArticol: function(...re) { return ['/Contab/AsociereAECapitolArticol'].concat(re) },
		contabAsociereAECapitolArticolEdit: function(jsonParam, ...re) { return ['/Contab/AsociereAECapitolArticolEdit',jsonParam].concat(re) },
		administrareAsociereClasificatieMultipla: function(jsonParam, ...re) { return ['/administrare/asociereClasificatieMultipla',jsonParam].concat(re) },
		administrareAdaugareAsociereClasificatieMultipla: function(id: number, jsonParam, ...re) { return ['/administrare/adaugareAsociereClasificatieMultipla',id,jsonParam].concat(re) },
		administrareAdaugareDetaliuClasificatieMultipla: function(id: number, jsonParam, ...re) { return ['/administrare/adaugareDetaliuClasificatieMultipla',id,jsonParam].concat(re) },
		administrareEditareProcenteDetalii: function(id: number, jsonParam, ...re) { return ['/administrare/editareProcenteDetalii',id,jsonParam].concat(re) }
	},
	persoane: {
		nomenclatorPersoane: function(detaliiPersoana, jsonParam, ...re) { return ['/persoane/NomenclatorPersoane',detaliiPersoana,jsonParam].concat(re) },
		persoanaPfa: function(id: number, jsonParam, ...re) { return ['/persoana/pfa',id,jsonParam].concat(re) },
		persoanaFiz: function(id: number, jsonParam, ...re) { return ['/persoana/fiz',id,jsonParam].concat(re) },
		persoanaBanca: function(id: number, jsonParam, ...re) { return ['/persoana/Banca',id,jsonParam].concat(re) },
		persoanaJur: function(id: number, jsonParam, ...re) { return ['/persoana/jur',id,jsonParam].concat(re) },
		persoanaunitate: function(id: number, jsonParam, ...re) { return ['/persoane/persoanaunitate',id,jsonParam].concat(re) },
		persoanaunitatePersoanainunitatenew: function(id: number, jsonParam, ...re) { return ['/persoane/persoanaunitate/persoanainunitatenew',id,jsonParam].concat(re) },
		comisii: function(id: number, ...re) { return ['/comisii',id].concat(re) },
		comisiiNew: function(id: number, ...re) { return ['/comisii/new',id].concat(re) },
		comisieNew: function(idComisie: number, id: number, ...re) { return ['/persoaneComisie/new',idComisie,id].concat(re) },
		controloriVizaCFP: function(jsonParam, ...re) { return ['/persoane/controloriVizaCFP',jsonParam].concat(re) },
		controloriVizaCFPnew: function(id: number, jsonParam, ...re) { return ['/persoane/controloriVizaCFPnew',id,jsonParam].concat(re) },
		persoanaUnificarepersoane: function(jsonParam, ...re) { return ['/persoane/persoana/unificarepersoane',jsonParam].concat(re) },
		bancaNomenclatorBanci: function(...re) { return ['/persoane/banca/NomenclatorBanci/'].concat(re) },
		iban: function(idPersoana: number, dataReferinta: string|Date, ...re) { return ['/iban',idPersoana,dataReferinta].concat(re) },
		ibannew: function(idEntitate: number, idPersoana: number, idBanca: number, dataReferinta: string|Date, iban, jsonParam, ...re) { return ['/ibannew',idEntitate,idPersoana,idBanca,dataReferinta,iban,jsonParam].concat(re) },
		configConfig: function(...re) { return ['/persoane/config/config/'].concat(re) },
		utilitatiAsociereutilitati: function(id: number, ...re) { return ['/persoane/utilitati/asociereutilitati',id].concat(re) },
		utilitatiAsociereutilitatiNew: function(id: number, ...re) { return ['/persoane/utilitati/asociereutilitati/new',id].concat(re) }
	},
	adrese: {
		stradaIstoricstrada: function(jsonParam, ...re) { return ['/adrese/strada/istoricstrada',jsonParam].concat(re) },
		stradaAdrese: function(jsonParam, ...re) { return ['/adrese/strada/adrese',jsonParam].concat(re) },
		stradaAdresenew: function(jsonParam, ...re) { return ['/adrese/strada/adresenew',jsonParam].concat(re) },
		zonarefiscala: function(jsonParam, ...re) { return ['/adrese/zonarefiscala',jsonParam].concat(re) },
		regulazonarefiscala: function(jsonParam, ...re) { return ['/adrese/regulazonarefiscala',jsonParam].concat(re) },
		tarlaparcela: function(jsonParam, ...re) { return ['/adrese/tarlaparcela',jsonParam].concat(re) },
		tarlaparcelaNew: function(jsonParam, ...re) { return ['/adrese/tarlaparcela/new',jsonParam].concat(re) },
		zonarefiscalaparcele: function(jsonParam, ...re) { return ['/adrese/zonarefiscalaparcele',jsonParam].concat(re) },
		regulazonarefiscalaparcele: function(jsonParam, ...re) { return ['/adrese/regulazonarefiscalaparcele',jsonParam].concat(re) },
		unificarestrazi: function(jsonParam, ...re) { return ['/adrese/unificarestrazi',jsonParam].concat(re) },
		unificarenumere: function(jsonParam, ...re) { return ['/adrese/unificarenumere',jsonParam].concat(re) },
		unificareblocuri: function(jsonParam, ...re) { return ['/adrese/unificareblocuri',jsonParam].concat(re) },
		unificarescari: function(jsonParam, ...re) { return ['/adrese/unificarescari',jsonParam].concat(re) },
		unificareapartamente: function(jsonParam, ...re) { return ['/adrese/unificareapartamente',jsonParam].concat(re) },
		regiunetip: function(jsonParam, ...re) { return ['/adrese/regiunetip',jsonParam].concat(re) },
		regiunetipNew: function(id: number, ...re) { return ['/adrese/regiunetip/new',id].concat(re) },
		regiune: function(jsonParam, ...re) { return ['/adrese/regiune',jsonParam].concat(re) },
		regiuneNew: function(id: number, ...re) { return ['/adrese/regiune/new',id].concat(re) },
		zonareadministrativa: function(jsonParam, ...re) { return ['/adrese/zonareadministrativa',jsonParam].concat(re) },
		regulazonareadministrativa: function(jsonParam, ...re) { return ['/adrese/regulazonareadministrativa',jsonParam].concat(re) },
		adreseconfig: function(...re) { return ['/adrese/adreseconfig'].concat(re) }
	},
	documente: {
		documentFurnizor: function(idFactura: number, jsonParam, ...re) { return ['/document/furnizor',idFactura,jsonParam].concat(re) },
		documentNou: function(tipFactura, idTipDocument: number, idFactura: number, idPersoana: number, isSold: boolean, jsonParam, ...re) { return ['/documentNou',tipFactura,idTipDocument,idFactura,idPersoana,isSold,jsonParam].concat(re) },
		documentfurnizorDetaliuNouMijlocFix: function(idFactura: number, idDetaliu: number, idTipDocument: number, idReceptie: number, idReceptieDetaliu: number, idProdusTip: number, jsonParam, ...re) { return ['/documentfurnizor/detaliuNouMijlocFix',idFactura,idDetaliu,idTipDocument,idReceptie,idReceptieDetaliu,idProdusTip,jsonParam].concat(re) },
		documentfurnizorDetaliuNou: function(idFactura: number, idDetaliu: number, idTipDocument: number, idReceptie: number, idReceptieDetaliu: number, idProdusTip: number, jsonParam, ...re) { return ['/documentfurnizor/detaliuNou',idFactura,idDetaliu,idTipDocument,idReceptie,idReceptieDetaliu,idProdusTip,jsonParam].concat(re) },
		documentefurnizoriFurnizoriDetaliunouserviciu: function(idFactura: number, idDetaliu: number, ...re) { return ['/documente/documentefurnizori/furnizori/detaliunouserviciu',idFactura,idDetaliu].concat(re) },
		documentefurnizoriFurnizoriDetaliunouutilitati: function(idFactura: number, idDetaliu: number, ...re) { return ['/documente/documentefurnizori/furnizori/detaliunouutilitati',idFactura,idDetaliu].concat(re) },
		documentefurnizoriFurnizoriDetaliunouobiective: function(idFactura: number, idDetaliu: number, ...re) { return ['/documente/documentefurnizori/furnizori/detaliunouobiective',idFactura,idDetaliu].concat(re) },
		documentefurnizoriFurnizoriDetaliunoumodernizari: function(idFactura: number, idDetaliu: number, ...re) { return ['/documente/documentefurnizori/furnizori/detaliunoumodernizari',idFactura,idDetaliu].concat(re) },
		documentfurnizorCompletareMultiplaEFactura: function(idFactura: number, idDetaliu: number, jsonParam, ...re) { return ['/documentfurnizor/completareMultiplaEFactura',idFactura,idDetaliu,jsonParam].concat(re) },
		documentfurnizorEFacturaReceptionareMateriale: function(idFactura: number, idDetaliuList: number, jsonParam, ...re) { return ['/documentfurnizor/eFacturaReceptionareMateriale',idFactura,idDetaliuList,jsonParam].concat(re) },
		documentfurnizorEFacturaReceptionareMF: function(idFactura: number, idDetaliuList: number, jsonParam, ...re) { return ['/documentfurnizor/eFacturaReceptionareMF',idFactura,idDetaliuList,jsonParam].concat(re) },
		documentfurnizorEFacturaReceptionareOI: function(idFactura: number, idDetaliuList: number, jsonParam, ...re) { return ['/documentfurnizor/eFacturaReceptionareOI',idFactura,idDetaliuList,jsonParam].concat(re) },
		documentfurnizorEFacturaReceptionareAlteValori: function(idFactura: number, idDetaliuList: number, jsonParam, ...re) { return ['/documentfurnizor/eFacturaReceptionareAlteValori',idFactura,idDetaliuList,jsonParam].concat(re) },
		documentfurnizorEFacturaOperareUtilitati: function(idFactura: number, idDetaliuList: number, jsonParam, ...re) { return ['/documentfurnizor/eFacturaOperareUtilitati',idFactura,idDetaliuList,jsonParam].concat(re) },
		documentfurnizorEFacturaOperareServicii: function(idFactura: number, idDetaliu: number, idDetaliuList: number, jsonParam, ...re) { return ['/documentfurnizor/eFacturaOperareServicii',idFactura,idDetaliu,idDetaliuList,jsonParam].concat(re) },
		documentfurnizorEFacturaOperareObiectiveInCurs: function(idFactura: number, idDetaliuList: number, jsonParam, ...re) { return ['/documentfurnizor/eFacturaOperareObiectiveInCurs',idFactura,idDetaliuList,jsonParam].concat(re) },
		documentfurnizorEFacturaOperareModernizariMF: function(idFactura: number, idDetaliuList: number, jsonParam, ...re) { return ['/documentfurnizor/eFacturaOperareModernizariMF',idFactura,idDetaliuList,jsonParam].concat(re) },
		documentfurnizorProdusNou: function(idFactura: number, idDetaliu: number, idTipDocument: number, jsonParam, ...re) { return ['/documentfurnizor/produsNou',idFactura,idDetaliu,idTipDocument,jsonParam].concat(re) },
		documentefurnizoriAvizeinfactura: function(idFactura: number, ...re) { return ['/documente/documentefurnizori/avizeinfactura',idFactura].concat(re) },
		documentefurnizoriAjustareSume: function(idFactura: number, ...re) { return ['/documente/documentefurnizori/ajustareSume',idFactura].concat(re) },
		documentefurnizoriFurnizoriDistribuiresume: function(idDocument: number, ...re) { return ['/documente/documentefurnizori/furnizori/distribuiresume',idDocument].concat(re) },
		documentefurnizoriFurnizoriDistribuiresumenew: function(id: number, idDocument: number, isSoldPartener: boolean, isPlatitorTva: boolean, isAlteDocumente: boolean, ...re) { return ['/documente/documentefurnizori/furnizori/distribuiresumenew',id,idDocument,isSoldPartener,isPlatitorTva,isAlteDocumente].concat(re) },
		documentFurnizorCuSoldInitial: function(...re) { return ['/document/furnizorCuSoldInitial'].concat(re) },
		contracte: function(...re) { return ['/documente/contracte'].concat(re) },
		contractNou: function(idContract: number, idTipContract: number, idPartener: number, jsonParam, ...re) { return ['/documente/contractNou',idContract,idTipContract,idPartener,jsonParam].concat(re) },
		crediteAngajamentMultianual: function(idContract: number, jsonParam, ...re) { return ['/documente/crediteAngajamentMultianual',idContract,jsonParam].concat(re) },
		comenzi: function(...re) { return ['/comenzi/'].concat(re) },
		comandaNoua: function(idComanda: number, idPartener: number, idContract: number, ...re) { return ['/comandaNoua',idComanda,idPartener,idContract].concat(re) },
		documentefurnizoriFurnizoriFinalizare: function(id: number, ...re) { return ['/documente/documentefurnizori/furnizori/finalizare',id].concat(re) },
		clienti: function(...re) { return ['/documente/clienti/'].concat(re) },
		clientiDocumentnou: function(id: number, idDocumentTip: number, ...re) { return ['/documente/clienti/documentnou',id,idDocumentTip].concat(re) },
		clientiDetaliunou: function(id: number, tipDetaliu, idDocument: number, ...re) { return ['/documente/clienti/detaliunou',id,tipDetaliu,idDocument].concat(re) },
		clientiDetaliunoudinstoc: function(id: number, idDocument: number, ...re) { return ['/documente/clienti/detaliunoudinstoc',id,idDocument].concat(re) },
		clientiRefacturareUtilitati: function(...re) { return ['/documente/clienti/refacturareUtilitati'].concat(re) },
		clientiDebitorNou: function(idDocument: number, ...re) { return ['/documente/clienti/debitorNou',idDocument].concat(re) },
		clientiGenerareFacturiClientiRefacturareUtilitati: function(...re) { return ['/documente/clienti/generareFacturiClientiRefacturareUtilitati'].concat(re) },
		clientiFinalizare: function(idDocument: number, ...re) { return ['/documente/clienti/finalizare',idDocument].concat(re) },
		documentClientiCuSoldInitial: function(...re) { return ['/document/clientiCuSoldInitial'].concat(re) },
		documenteclientiClientiDistribuiresumenew: function(id: number, idDocument: number, isSoldPartener: boolean, isPlatitorTva: boolean, isAlteDocumente: boolean, idDetaliu: number, ...re) { return ['/documente/documenteclienti/clienti/distribuiresumenew',id,idDocument,isSoldPartener,isPlatitorTva,isAlteDocumente,idDetaliu].concat(re) },
		alteoperatiuni: function(...re) { return ['/documente/alteoperatiuni/'].concat(re) },
		alteoperatiuninew: function(id: number, ...re) { return ['/documente/alteoperatiuninew',id].concat(re) },
		eFactura: function(...re) { return ['/documente/eFactura/'].concat(re) },
		eFacturaGenerareDocumenteFurnizor: function(...re) { return ['/documente/eFactura/generareDocumenteFurnizor/'].concat(re) },
		executieSalariiImportResum: function(...re) { return ['/executieSalariiImportResum'].concat(re) },
		listaExecutieSalarii: function(...re) { return ['/listaExecutieSalarii'].concat(re) },
		executieSalariiEditare: function(idDocument: number, ...re) { return ['/executieSalariiEditare',idDocument].concat(re) },
		executieSalariiCopiere: function(idDocument: number, ...re) { return ['/executieSalariiCopiere',idDocument].concat(re) },
		executieSalarii: function(idDocument: number, ...re) { return ['/executieSalarii',idDocument].concat(re) },
		executieSalariiCompletareCodIndicatorAngajament: function(idDocument: number, ...re) { return ['/executieSalariiCompletareCodIndicatorAngajament',idDocument].concat(re) },
		executieSalariiPlataOP: function(idDocument: number, ...re) { return ['/executieSalariiPlataOP',idDocument].concat(re) },
		executieSalariiAsociereStatAvansuri: function(idDocument: number, ...re) { return ['/executieSalariiAsociereStatAvansuri',idDocument].concat(re) },
		executieSalariiEditareSuma: function(id: number, idDocument: number, idBuget: number, idBugetClasificatieFunctionala: number, idFormularClasificatieEconomica: number, ...re) { return ['/executieSalariiEditareSuma',id,idDocument,idBuget,idBugetClasificatieFunctionala,idFormularClasificatieEconomica].concat(re) },
		vizacfpTipDocOpCFP: function(...re) { return ['/vizacfp/tipDocOpCFP/'].concat(re) },
		vizacfpAsociere: function(id: number, jsonParam, ...re) { return ['/vizacfp/asociere',id,jsonParam].concat(re) },
		vizacfpRegistruVizaCFP: function(...re) { return ['/vizacfp/registruVizaCFP/'].concat(re) },
		vizacfpVizaManual: function(idDocument: number, refuzViza, idDocumentGenerator: number, an, jsonParam, ...re) { return ['/vizacfp/vizaManual',idDocument,refuzViza,idDocumentGenerator,an,jsonParam].concat(re) },
		vizacfpVizareDocumente: function(...re) { return ['/vizacfp/vizareDocumente/'].concat(re) },
		vizacfpAdaugareDocument: function(...re) { return ['/vizacfp/adaugareDocument/'].concat(re) },
		clasificareProduse: function(...re) { return ['/clasificareProduse/'].concat(re) },
		garantiiDeBunaExecutie: function(jsonParam, ...re) { return ['/garantiiDeBunaExecutie',jsonParam].concat(re) },
		garantiiDeBunaExecutieAdaugareConstituireDeCatreFurnizor: function(idDocumentGarantieBunaExecutie: number, idDocumentSubTip: number, ...re) { return ['/garantiiDeBunaExecutieAdaugareConstituireDeCatreFurnizor',idDocumentGarantieBunaExecutie,idDocumentSubTip].concat(re) },
		garantiiDeBunaExecutieAdaugareSoldInitial: function(idDocumentGarantieBunaExecutie: number, idDocumentSubTip: number, ...re) { return ['/garantiiDeBunaExecutieAdaugareSoldInitial',idDocumentGarantieBunaExecutie,idDocumentSubTip].concat(re) },
		garantiiDeBunaExecutieAdaugareScrisoareGarantieBancara: function(idDocumentGarantieBunaExecutie: number, idDocumentSubTip: number, ...re) { return ['/garantiiDeBunaExecutieAdaugareScrisoareGarantieBancara',idDocumentGarantieBunaExecutie,idDocumentSubTip].concat(re) },
		garantiiDeBunaExecutieAdaugarePolitaDeAsigurare: function(idDocumentGarantieBunaExecutie: number, idDocumentSubTip: number, ...re) { return ['/garantiiDeBunaExecutieAdaugarePolitaDeAsigurare',idDocumentGarantieBunaExecutie,idDocumentSubTip].concat(re) },
		garantiiDeBunaExecutieAdaugareRestituire: function(idDocumentGarantieBunaExecutie: number, idDocumentSubTip: number, ...re) { return ['/garantiiDeBunaExecutieAdaugareRestituire',idDocumentGarantieBunaExecutie,idDocumentSubTip].concat(re) },
		garantiiParticipareLicitatie: function(jsonParam, ...re) { return ['/garantiiParticipareLicitatie',jsonParam].concat(re) },
		garantiiLicitatiiAdaugareSoldInitial: function(idDocumentGarantieLicitatie: number, idDocumentSubTip: number, ...re) { return ['/garantiiLicitatiiAdaugareSoldInitial',idDocumentGarantieLicitatie,idDocumentSubTip].concat(re) },
		garantiiLicitatieAdaugarePolitaDeAsigurare: function(idDocumentGarantieLicitatie: number, idDocumentSubTip: number, ...re) { return ['/garantiiLicitatieAdaugarePolitaDeAsigurare',idDocumentGarantieLicitatie,idDocumentSubTip].concat(re) },
		garantiiParticipareLicitatieAdaugareDeblocare: function(idDocumentGarantieLicitatie: number, idDocumentSubTip: number, ...re) { return ['/garantiiParticipareLicitatieAdaugareDeblocare',idDocumentGarantieLicitatie,idDocumentSubTip].concat(re) },
		garantiiLicitatieAdaugareScrisoareBancara: function(idDocumentGarantieLicitatie: number, idDocumentSubTip: number, ...re) { return ['/garantiiLicitatieAdaugareScrisoareBancara',idDocumentGarantieLicitatie,idDocumentSubTip].concat(re) },
		rapoarte: function(jsonParam, ...re) { return ['/rapoarte',jsonParam].concat(re) },
		configurariConfig: function(...re) { return ['/documente/configurari/config/'].concat(re) },
		configurariAlocarenumarchitanta: function(jsonParam, ...re) { return ['/documente/configurari/alocarenumarchitanta',jsonParam].concat(re) },
		configurariAlocarenumarchitantaNew: function(jsonParam, ...re) { return ['/documente/configurari/alocarenumarchitanta/new',jsonParam].concat(re) },
		configurariSetarivizacfp: function(...re) { return ['/documente/configurari/setarivizacfp'].concat(re) },
		configurariAlocarenumarchitanta1: function(jsonParam, ...re) { return ['/documente/configurari/alocarenumarchitanta1',jsonParam].concat(re) },
		configurariAlocarenumarchitantaNew1: function(jsonParam, ...re) { return ['/documente/configurari/alocarenumarchitanta/new1',jsonParam].concat(re) }
	},
	buget: {
		prevederiPrevederi: function(...re) { return ['/buget/prevederi/Prevederi/'].concat(re) },
		prevederiBuget: function(...re) { return ['/buget/PrevederiBuget/'].concat(re) },
		estimariIndiciEstimari: function(tip, idBugetRectificare: number, idBugetClasificatieFunctionala: number, codSursaNume, ...re) { return ['/buget/estimari/indiciEstimari',tip,idBugetRectificare,idBugetClasificatieFunctionala,codSursaNume].concat(re) },
		executieExecutieExecutiebugetara: function(...re) { return ['/buget/executie/executie/executiebugetara/'].concat(re) },
		executieSupldimangajamentbugetar: function(tip, idDocument: number, idAngajamentBugetar: number, ...re) { return ['/executie/supldimangajamentbugetar',tip,idDocument,idAngajamentBugetar].concat(re) },
		executieAngajamentbugetar: function(idDocument: number, ...re) { return ['/executie/angajamentbugetar',idDocument].concat(re) },
		executieSupldimangajamentlegal: function(tip, idDocument: number, idAngajamentLegal: number, ...re) { return ['/executie/supldimangajamentlegal',tip,idDocument,idAngajamentLegal].concat(re) },
		executieAngajamentlegal: function(idDocument: number, idAngajamentBugetar: number, ...re) { return ['/executie/angajamentlegal',idDocument,idAngajamentBugetar].concat(re) },
		executieCorectieCodAngajamentIndicaor: function(idDocument: number, ...re) { return ['/executie/corectieCodAngajamentIndicaor',idDocument].concat(re) },
		executieExecutieLichidari: function(idDocument: number, idAngajamentLegal: number, idAngajamentBugetar: number, ...re) { return ['/buget/executie/executie/lichidari',idDocument,idAngajamentLegal,idAngajamentBugetar].concat(re) },
		executieOrdonantare: function(idDocument: number, idAngajamentLegal: number, idLichidare: number, ...re) { return ['/executie/ordonantare',idDocument,idAngajamentLegal,idLichidare].concat(re) },
		executieExecutieBugetaraExtrasCont: function(...re) { return ['/executie/executieBugetaraExtrasCont/'].concat(re) },
		executieCererideschidericredite: function(id: number, jsonParam, ...re) { return ['/executie/cererideschidericredite',id,jsonParam].concat(re) },
		executieCererideschidericredite_raportcentralizator: function(id: number, jsonParam, ...re) { return ['/executie/cererideschidericredite_raportcentralizator',id,jsonParam].concat(re) },
		executieCererideschidericreditenew: function(id: number, jsonParam, ...re) { return ['/executie/cererideschidericreditenew',id,jsonParam].concat(re) },
		executieExecutieDeschideridecredite: function(id: number, jsonParam, ...re) { return ['/buget/executie/executie/deschideridecredite',id,jsonParam].concat(re) },
		executieExecutieDeschidericreditenew: function(id: number, ...re) { return ['/executie/executie/deschidericreditenew',id].concat(re) },
		executieExecutieTransferSolduri: function(...re) { return ['/buget/executie/executie/transferSolduri/'].concat(re) },
		executieExecutieGenerareOrdonantariDocumenteFurnizor: function(...re) { return ['/buget/executie/executie/generareOrdonantariDocumenteFurnizor/'].concat(re) },
		rectificari: function(jsonParam, ...re) { return ['/buget/Rectificari',jsonParam].concat(re) },
		restaurareRectificari: function(id: number, ...re) { return ['/buget/restaurareRectificari',id].concat(re) },
		bugetNew: function(idUnitate: number, id: number, anBuget, jsonParam, ...re) { return ['/Buget/Buget/new',idUnitate,id,anBuget,jsonParam].concat(re) },
		editareRectificare: function(idBuget: number, id: number, aprobare, jsonParam, ...re) { return ['/editareRectificare',idBuget,id,aprobare,jsonParam].concat(re) },
		platiCheltuieli: function(...re) { return ['/buget/PlatiCheltuieli'].concat(re) },
		rapoarteListarerapoarte: function(id: number, jsonParam, ...re) { return ['/buget/rapoarte/listarerapoarte',id,jsonParam].concat(re) },
		nomenclatorDetalieri: function(jsonParam, ...re) { return ['/buget/Nomenclator/detalieri',jsonParam].concat(re) },
		nomenclatorDetalieriNew: function(idTree: number, id: number, jsonParam, ...re) { return ['/buget/Nomenclator/detalieri/new',idTree,id,jsonParam].concat(re) },
		nomenclatorCodAngajament: function(...re) { return ['/buget/Nomenclator/codAngajament/'].concat(re) },
		nomenclatorCodAngajamentNew: function(id: number, ...re) { return ['/buget/Nomenclator/codAngajament/new',id].concat(re) },
		formularFormularFormularDetalieri_v2: function(...re) { return ['/buget/formular/formular/formularDetalieri_v2/'].concat(re) },
		formularFormularFormulardetalieriNew: function(id: number, parentId, ...re) { return ['/buget/formular/formular/formulardetalieri/new',id,parentId].concat(re) },
		formularFormularFormulardetalieriPersonalizare: function(id: number, ...re) { return ['/buget/formular/formular/formulardetalieri/personalizare',id].concat(re) },
		formularFormularFormuledetalieri: function(...re) { return ['/buget/formular/formular/formuledetalieri/'].concat(re) },
		formularBugetFormularBuget: function(...re) { return ['/buget/formularBuget/formularBuget/'].concat(re) },
		formularbugetFormularbugetFormularbugetNew: function(id: number, parentId, ...re) { return ['/buget/formularbuget/formularbuget/formularbuget/new',id,parentId].concat(re) },
		formularBugetFormuleBuget: function(...re) { return ['/buget/FormularBuget/formuleBuget/'].concat(re) },
		configBuget: function(jsonParam, ...re) { return ['/buget/ConfigBuget',jsonParam].concat(re) }
	},
	conta: {
		actualizareBalantaV2: function(toateConturile, conturiInAfaraBilantului, strContSelectat, jsonParam, ...re) { return ['/contab/ActualizareBalantaV2',toateConturile,conturiInAfaraBilantului,strContSelectat,jsonParam].concat(re) },
		analitice: function(id: number, jsonParam, ...re) { return ['/contab/Analitice',id,jsonParam].concat(re) },
		analiticeAltfelNew: function(idCont: number, idAnalitic: number, idBalanta: number, jsonParam, ...re) { return ['/contab/Analitice/Altfel/new',idCont,idAnalitic,idBalanta,jsonParam].concat(re) },
		analiticeVenitincasareNew: function(tipAnalitic, idCont: number, idAnalitic: number, idBalanta: number, jsonParam, ...re) { return ['/contab/Analitice/Venitincasare/new',tipAnalitic,idCont,idAnalitic,idBalanta,jsonParam].concat(re) },
		analiticeCheltuialafinantareNew: function(tipAnalitic, idCont: number, idAnalitic: number, idBalanta: number, jsonParam, ...re) { return ['/contab/Analitice/Cheltuialafinantare/new',tipAnalitic,idCont,idAnalitic,idBalanta,jsonParam].concat(re) },
		analiticeExcedentNew: function(idCont: number, idAnalitic: number, idBalanta: number, jsonParam, ...re) { return ['/contab/Analitice/Excedent/new',idCont,idAnalitic,idBalanta,jsonParam].concat(re) },
		generareAnalitice: function(id: number, jsonParam, ...re) { return ['/contab/GenerareAnalitice',id,jsonParam].concat(re) },
		generareAnaliticeVenit: function(id: number, ...re) { return ['/contab/generareAnaliticeVenit',id].concat(re) },
		notePropuse: function(idDocument: number, idSubTipDocument: number, ...re) { return ['/contab/notePropuse',idDocument,idSubTipDocument].concat(re) },
		note: function(jsonParam, ...re) { return ['/contab/note',jsonParam].concat(re) },
		noteCautareAvansata: function(jsonParam, ...re) { return ['/contab/noteCautareAvansata',jsonParam].concat(re) },
		documenteAltedocumente: function(...re) { return ['/documente/altedocumente/'].concat(re) },
		documenteAlteDocumenteNew: function(idTipDocument: number, idDocument: number, idPersoana: number, jsonParam, ...re) { return ['/documente/alteDocumenteNew',idTipDocument,idDocument,idPersoana,jsonParam].concat(re) },
		documenteAlteDocumenteNotaNew: function(id: number, idDocument: number, idTipDocument: number, jsonParam, ...re) { return ['/documente/alteDocumenteNotaNew',id,idDocument,idTipDocument,jsonParam].concat(re) },
		soldinitialpartener: function(jsonParam, ...re) { return ['/contab/soldinitialpartener',jsonParam].concat(re) },
		inchideri: function(...re) { return ['/contab/Inchideri/'].concat(re) },
		jurnalGeneral: function(...re) { return ['/contab/jurnalGeneral/'].concat(re) },
		jurnalGeneralNew: function(id: number, ...re) { return ['/contab/jurnalGeneral/new',id].concat(re) },
		centralizatoareCentralizatorFisaContului: function(...re) { return ['/contab/centralizatoare/centralizatorFisaContului/'].concat(re) },
		vizualizareBalanta: function(...re) { return ['/contab/VizualizareBalanta/'].concat(re) },
		facturiRestanteFurnizori: function(...re) { return ['/contab/facturiRestanteFurnizori/'].concat(re) },
		registruJurnal: function(...re) { return ['/contab/registruJurnal/'].concat(re) },
		registruInventar: function(...re) { return ['/contab/registruInventar/'].concat(re) },
		registruCarteMare: function(...re) { return ['/contab/registruCarteMare/'].concat(re) },
		registruCarteMareSah: function(...re) { return ['/contab/registruCarteMareSah/'].concat(re) },
		situatieparteneriV3: function(...re) { return ['/contab/situatieparteneriV3'].concat(re) },
		diferenteBalantaSitParteneri: function(idOrganizareInstitutionala: number, idOrganizareInstitutionalaCentruCheltuiala: number, an, dataReferintaStart: string|Date, dataReferintaStop: string|Date, contSintetic, a1, a2, ...re) { return ['/contab/diferenteBalantaSitParteneri',idOrganizareInstitutionala,idOrganizareInstitutionalaCentruCheltuiala,an,dataReferintaStart,dataReferintaStop,contSintetic,a1,a2].concat(re) },
		generareDebite: function(idOrganizareInstitutionala: number, idOrganizareInstitutionalaCentruCheltuiala: number, an, dataReferintaStart: string|Date, dataReferintaStop: string|Date, contSintetic, a1, a2, ...re) { return ['/contab/GenerareDebite',idOrganizareInstitutionala,idOrganizareInstitutionalaCentruCheltuiala,an,dataReferintaStart,dataReferintaStop,contSintetic,a1,a2].concat(re) },
		d394: function(...re) { return ['/Contab/D394/'].concat(re) },
		d394New: function(id: number, ...re) { return ['/Contab/D394New',id].concat(re) },
		d394IncasareNew: function(id: number, idDeclaratie: number, ...re) { return ['/Contab/D394IncasareNew',id,idDeclaratie].concat(re) },
		d394FacturaNew: function(id: number, idDeclaratie: number, ...re) { return ['/Contab/D394FacturaNew',id,idDeclaratie].concat(re) },
		d394SerieNew: function(id: number, idDeclaratie: number, ...re) { return ['/Contab/D394SerieNew',id,idDeclaratie].concat(re) },
		fisaInregistrariContabile: function(...re) { return ['/contab/fisaInregistrariContabile/'].concat(re) },
		rapoarteSituatieCheltuieliCentreCheltuiala: function(...re) { return ['/contab/rapoarte/SituatieCheltuieli/CentreCheltuiala/'].concat(re) },
		rapoarteSituatiiZilnice: function(...re) { return ['/contab/rapoarte/situatiiZilnice/'].concat(re) },
		rapoarteJurnaleAnalitice: function(...re) { return ['/contab/rapoarte/jurnaleAnalitice/'].concat(re) },
		rapoarteSituatieFacturiPlatiAferente: function(...re) { return ['/contab/rapoarte/situatieFacturiPlatiAferente/'].concat(re) },
		rapoarteRaportJurnalCumparari: function(...re) { return ['/contab/rapoarte/raportJurnalCumparari/'].concat(re) },
		rapoarteRaportJurnalVanzari: function(...re) { return ['/contab/rapoarte/raportJurnalVanzari/'].concat(re) },
		centralizatoareCentralizatorBalantaSetari: function(...re) { return ['/contab/centralizatoare/centralizatorBalantaSetari/'].concat(re) },
		centralizatoareCentralizatorBalanta: function(...re) { return ['/contab/centralizatoare/centralizatorBalanta/'].concat(re) },
		centralizatoareCentralizatorFisacontuluiSetari: function(...re) { return ['/contab/centralizatoare/centralizatorFisacontuluiSetari/'].concat(re) },
		centralizatoareCentralizatorFisaContului2: function(...re) { return ['/contab/centralizatoare/centralizatorFisaContului2/'].concat(re) },
		centralizatorAE: function(...re) { return ['/Contab/centralizatorAE/'].concat(re) },
		inchidereLuna: function(...re) { return ['/contab/InchidereLuna/'].concat(re) },
		conturiInchidere: function(idCont: number, ...re) { return ['/contab/ConturiInchidere',idCont].concat(re) },
		asociereContInchidere: function(id: number, ...re) { return ['/contab/AsociereContInchidere',id].concat(re) },
		cautareDocumente: function(jsonParam, ...re) { return ['/contab/cautareDocumente',jsonParam].concat(re) },
		diferenteSumeBugetConta: function(...re) { return ['/contab/DiferenteSumeBugetConta'].concat(re) },
		conturi: function(id: number, jsonParam, ...re) { return ['/contab/Conturi',id,jsonParam].concat(re) },
		conturiNew: function(id: number, idParinte: number, jsonParam, ...re) { return ['/contab/Conturi/new',id,idParinte,jsonParam].concat(re) },
		jurnale: function(jsonParam, ...re) { return ['/contab/Jurnale',jsonParam].concat(re) },
		jurnaleEditJurnal: function(id: number, jsonParam, ...re) { return ['/contab/Jurnale/editJurnal',id,jsonParam].concat(re) },
		sabloaneDeConturi: function(id: number, jsonParam, ...re) { return ['/contab/sabloaneDeConturi',id,jsonParam].concat(re) },
		sabloaneDeConturiNew: function(id: number, jsonParam, ...re) { return ['/contab/SabloaneDeConturi/new',id,jsonParam].concat(re) },
		sabloaneConturiPeOperatiuni: function(...re) { return ['/contab/sabloaneConturiPeOperatiuni'].concat(re) },
		algoritmiDeGenerareNoteContabile: function(jsonParam, ...re) { return ['/contab/algoritmiDeGenerareNoteContabile',jsonParam].concat(re) },
		algoritmiDeGenerareNoteContabileNew: function(id: number, jsonParam, ...re) { return ['/contab/algoritmiDeGenerareNoteContabileNew',id,jsonParam].concat(re) },
		generatorNoteVenituri: function(id: number, ...re) { return ['/contab/generatorNoteVenituri',id].concat(re) },
		generatorNoteVenituriNew: function(id: number, ...re) { return ['/contab/generatorNoteVenituri/new',id].concat(re) },
		generatorNoteVenituriCopiereSabloane: function(id: number, ...re) { return ['/contab/generatorNoteVenituriCopiereSabloane',id].concat(re) },
		sabloaneConturiInchidere: function(...re) { return ['/contab/SabloaneConturiInchidere'].concat(re) },
		adaugaresabloncontinchidere: function(id: number, ...re) { return ['/contab/adaugaresabloncontinchidere',id].concat(re) },
		asociereContTipCreditBugetar: function(jsonParam, ...re) { return ['/contab/asociereContTipCreditBugetar',jsonParam].concat(re) },
		asociereContTipCreditBugetarNew: function(jsonParam, ...re) { return ['/contab/asociereContTipCreditBugetar/new',jsonParam].concat(re) },
		asociereContTipCreditBugetarV2: function(jsonParam, ...re) { return ['/contab/asociereContTipCreditBugetarV2',jsonParam].concat(re) },
		asociereContTipCreditBugetarV2New: function(jsonParam, ...re) { return ['/contab/asociereContTipCreditBugetarV2/new',jsonParam].concat(re) },
		asociereContTipCreditBugetarV3: function(jsonParam, ...re) { return ['/contab/asociereContTipCreditBugetarV3',jsonParam].concat(re) },
		asociereContTipCreditBugetarV3New: function(jsonParam, ...re) { return ['/contab/asociereContTipCreditBugetarV3/new',jsonParam].concat(re) },
		conta: function(...re) { return ['/Contab/Conta'].concat(re) },
		grupareConturi: function(...re) { return ['/contab/grupareConturi/'].concat(re) },
		grupareConturiNew: function(...re) { return ['/contab/grupareConturi/new/'].concat(re) },
		setariraportbalanta: function(tip, search1, search2, analitic1, analitic2, tipBuget, sufixClasificatieFunctionala, checkInAfaraBilantului, checkBalantaCompleta, ...re) { return ['/contab/setariraportbalanta',tip,search1,search2,analitic1,analitic2,tipBuget,sufixClasificatieFunctionala,checkInAfaraBilantului,checkBalantaCompleta].concat(re) },
		setariParametriiRaport: function(tip, search1, search2, analitic1, analitic2, tipBuget, sufixClasificatieFunctionala, organizareInstitutionalaStringIds, checkInAfaraBilantului, checkBalantaCompleta, ...re) { return ['/contab/setariParametriiRaport',tip,search1,search2,analitic1,analitic2,tipBuget,sufixClasificatieFunctionala,organizareInstitutionalaStringIds,checkInAfaraBilantului,checkBalantaCompleta].concat(re) }
	},
	inventare: {
		obiectiveInCursDeExecutie: function(jsonParam, ...re) { return ['/inventar/obiectiveInCursDeExecutie',jsonParam].concat(re) },
		obiectiveInCursDeExecutiePvobiectiveincursnew: function(id: number, idProdusGrupTip: number, idOrganizareInstitutionala: number, jsonParam, ...re) { return ['/inventar/obiectiveInCursDeExecutie/pvobiectiveincursnew',id,idProdusGrupTip,idOrganizareInstitutionala,jsonParam].concat(re) },
		obiectiveInCursDeExecutieStocNou: function(id: number, idOrganizareInstitutionala: number, jsonParam, ...re) { return ['/inventar/inventare/obiectiveInCursDeExecutie/stocNou',id,idOrganizareInstitutionala,jsonParam].concat(re) },
		iesiri: function(jsonParam, ...re) { return ['/inventar/iesiri',jsonParam].concat(re) },
		iesiriIesireNoua: function(id: number, idProdusGrupTip: number, idOrganizareInstitutionala: number, jsonParam, ...re) { return ['/inventar/iesiri/iesireNoua',id,idProdusGrupTip,idOrganizareInstitutionala,jsonParam].concat(re) },
		iesiriIesireDinPropuneri: function(idIesire: number, idProdusIesire: number, jsonParam, ...re) { return ['/inventar/iesiri/iesireDinPropuneri',idIesire,idProdusIesire,jsonParam].concat(re) },
		iesiriOperareProdusDisponibil: function(idIesire: number, idProdusIesire: number, jsonParam, ...re) { return ['/inventar/iesiri/operareProdusDisponibil',idIesire,idProdusIesire,jsonParam].concat(re) },
		transferuri: function(jsonParam, ...re) { return ['/inventar/transferuri',jsonParam].concat(re) },
		transferuriTransferurinew: function(id: number, idProdusGrupTip: number, idOrganizareInstitutionala: number, jsonParam, ...re) { return ['/inventar/transferuri/transferurinew',id,idProdusGrupTip,idOrganizareInstitutionala,jsonParam].concat(re) },
		transferuriMFtoOI: function(id: number, idProdusGrupTip: number, idOrganizareInstitutionala: number, jsonParam, ...re) { return ['/inventar/transferuri/MFtoOI',id,idProdusGrupTip,idOrganizareInstitutionala,jsonParam].concat(re) },
		transferuriOperareProdusDisponibil: function(idTransfer: number, jsonParam, ...re) { return ['/inventar/transferuri/operareProdusDisponibil',idTransfer,jsonParam].concat(re) },
		transferuriOperareMFtoOI: function(idTransfer: number, jsonParam, ...re) { return ['/inventar/transferuri/operareMFtoOI',idTransfer,jsonParam].concat(re) },
		transferuriOperareMFtoOI_ng2: function(...re) { return ['/inventar/transferuri/operareMFtoOI_ng2'].concat(re) },
		transformari: function(jsonParam, ...re) { return ['/inventar/transformari',jsonParam].concat(re) },
		transformariTransformarinew: function(id: number, idProdusGrupTip: number, idOrganizareInstitutionala: number, jsonParam, ...re) { return ['/inventar/transformari/transformarinew',id,idProdusGrupTip,idOrganizareInstitutionala,jsonParam].concat(re) },
		transformariOperareProdusDisponibil: function(idTransformare: number, idProdusIesire: number, jsonParam, ...re) { return ['/inventar/transformari/operareProdusDisponibil',idTransformare,idProdusIesire,jsonParam].concat(re) },
		transformariAdaugaProdusObtinut: function(idOrganizareInstitutionala: number, jsonParam, ...re) { return ['/inventar/transformari/adaugaProdusObtinut',idOrganizareInstitutionala,jsonParam].concat(re) },
		restituiri: function(jsonParam, ...re) { return ['/inventar/restituiri',jsonParam].concat(re) },
		restituiriRestituirinew: function(id: number, idProdusGrupTip: number, idOrganizareInstitutionala: number, jsonParam, ...re) { return ['/inventar/restituiri/restituirinew',id,idProdusGrupTip,idOrganizareInstitutionala,jsonParam].concat(re) },
		restituiriOperareProdusIesit: function(idRestituire: number, idProdusIesire: number, jsonParam, ...re) { return ['/inventar/restituiri/operareProdusIesit',idRestituire,idProdusIesire,jsonParam].concat(re) },
		dareconsumConsumalimente: function(id: number, ...re) { return ['/inventar/inventare/dareconsum/consumalimente',id].concat(re) },
		dareconsumConsumalimenteNew: function(id: number, ...re) { return ['/inventar/inventare/dareconsum/consumalimente/new',id].concat(re) },
		dareconsumConsumalimentesuplimentariNew: function(id: number, ...re) { return ['/inventar/inventare/dareconsum/consumalimentesuplimentari/new',id].concat(re) },
		necesarAprovizionare: function(...re) { return ['/inventar/inventare/necesar/aprovizionare'].concat(re) },
		propuneriDeCasare: function(id: number, ...re) { return ['/inventar/inventare/propuneriDeCasare',id].concat(re) },
		propuneriPropunereNoua: function(id: number, idProdusGrupTip: number, idOrganizareInstitutionala: number, isAreDetalii: boolean, jsonParam, ...re) { return ['/inventar/propuneri/propunereNoua',id,idProdusGrupTip,idOrganizareInstitutionala,isAreDetalii,jsonParam].concat(re) },
		propuneriSelectieProduse: function(idPropunere: number, idProdusIesire: number, jsonParam, ...re) { return ['/inventar/propuneri/selectieProduse',idPropunere,idProdusIesire,jsonParam].concat(re) },
		catalogmijloacefixe: function(jsonParam, ...re) { return ['/inventar/inventare/catalogmijloacefixe',jsonParam].concat(re) },
		catalogmijloacefixeModernizari: function(id: number, jsonParam, ...re) { return ['/inventar/inventare/catalogmijloacefixe/modernizari',id,jsonParam].concat(re) },
		catalogmijloacefixeModernizarinew: function(id: number, jsonParam, ...re) { return ['/inventar/inventare/catalogmijloacefixe/modernizarinew',id,jsonParam].concat(re) },
		catalogmijloacefixeScadentar: function(id: number, jsonParam, ...re) { return ['/inventar/inventare/catalogmijloacefixe/scadentar',id,jsonParam].concat(re) },
		calculamortizari: function(jsonParam, ...re) { return ['/inventar/inventare/calculamortizari',jsonParam].concat(re) },
		caracteristiciMijloaceFixe: function(idProdus: number, jsonParam, ...re) { return ['/inventar/inventare/caracteristiciMijloaceFixe',idProdus,jsonParam].concat(re) },
		catalogmijloacefixeCautaremijlocfix: function(jsonParam, ...re) { return ['/inventar/inventare/catalogmijloacefixe/cautaremijlocfix',jsonParam].concat(re) },
		reevaluari: function(...re) { return ['/inventar/inventare/reevaluari'].concat(re) },
		reevaluariReevaluarenoua: function(id: number, ...re) { return ['/inventar/inventare/reevaluari/reevaluarenoua',id].concat(re) },
		stocInitial: function(jsonParam, ...re) { return ['/inventar/inventare/StocInitial',jsonParam].concat(re) },
		stocNou: function(idOrganizareInstitutionala: number, jsonParam, ...re) { return ['/inventar/inventare/stocNou',idOrganizareInstitutionala,jsonParam].concat(re) },
		receptie: function(...re) { return ['/inventar/receptie'].concat(re) },
		receptiiCorectiedetaliere: function(id: number, jsonParam, ...re) { return ['/inventar/inventare/receptii/corectiedetaliere',id,jsonParam].concat(re) },
		receptiiReceptieNoua: function(id: number, idFactura: number, idProdusGrupTip: number, idOrganizareInstitutionala: number, jsonParam, ...re) { return ['/inventar/receptii/receptieNoua',id,idFactura,idProdusGrupTip,idOrganizareInstitutionala,jsonParam].concat(re) },
		inventar: function(...re) { return ['/inventar/Inventare/Inventar/'].concat(re) },
		rapoarteBalanta: function(id: number, ...re) { return ['/inventar/inventare/rapoarte/balanta',id].concat(re) },
		situatiiSituatiiamortizari: function(id: number, ...re) { return ['/inventar/inventare/situatii/situatiiamortizari',id].concat(re) },
		rapoarteFisaproduse: function(id: number, ...re) { return ['/inventar/inventare/rapoarte/fisaproduse',id].concat(re) },
		rapoarteListareraport: function(id: number, ...re) { return ['/inventar/inventare/rapoarte/listareraport',id].concat(re) },
		categorieProduse: function(jsonParam, ...re) { return ['/inventar/inventare/categorieProduse',jsonParam].concat(re) },
		categorieProdusenew: function(idProdus: number, jsonParam, ...re) { return ['/inventar/inventare/categorieProdusenew',idProdus,jsonParam].concat(re) },
		modOrganizareMF: function(jsonParam, ...re) { return ['/inventar/inventare/modOrganizareMF',jsonParam].concat(re) },
		modOrganizareMFnew: function(idModOrganizare: number, jsonParam, ...re) { return ['/inventar/inventare/modOrganizareMFnew',idModOrganizare,jsonParam].concat(re) },
		tipFinantare: function(jsonParam, ...re) { return ['/inventar/inventare/tipFinantare',jsonParam].concat(re) },
		tipFinantareMFnew: function(idTipFinantare: number, jsonParam, ...re) { return ['/inventar/inventare/tipFinantareMFnew',idTipFinantare,jsonParam].concat(re) },
		registruProduse: function(...re) { return ['/inventar/inventare/RegistruProduse/'].concat(re) },
		registruproduseCorectie: function(id: number, jsonParam, ...re) { return ['/inventar/inventare/registruproduse/corectie',id,jsonParam].concat(re) },
		registruproduseCorectiemultipla: function(id: number, jsonParam, ...re) { return ['/inventar/inventare/registruproduse/corectiemultipla',id,jsonParam].concat(re) },
		nomenclatorIpc: function(...re) { return ['/inventar/inventare/nomenclator/ipc'].concat(re) },
		asociereProdusAliment: function(...re) { return ['/inventar/inventare/asociereProdusAliment'].concat(re) },
		registruproduseUnificareproduse: function(id: number, jsonParam, ...re) { return ['/inventar/inventare/registruproduse/unificareproduse',id,jsonParam].concat(re) },
		setariInventare: function(...re) { return ['/inventar/setari/inventare'].concat(re) }
	},
	raportarifinanciare: {
		contabBalantadeschidere: function(...re) { return ['/raportarifinanciare/contab/balantadeschidere/'].concat(re) },
		contabExecutienontrezor: function(...re) { return ['/raportarifinanciare/contab/executienontrezor'].concat(re) },
		contabBalantaverificare: function(...re) { return ['/raportarifinanciare/contab/balantaverificare/'].concat(re) },
		contabFormular30PlatiRestante: function(id: number, ...re) { return ['/raportarifinanciare/contab/formular30PlatiRestante',id].concat(re) },
		contabFormular19: function(...re) { return ['/raportarifinanciare/contab/formular19'].concat(re) },
		contabFormular27: function(id: number, ...re) { return ['/raportarifinanciare/contab/formular27',id].concat(re) },
		contabFormular28: function(id: number, ...re) { return ['/raportarifinanciare/contab/formular28',id].concat(re) },
		contabFormular40: function(id: number, ...re) { return ['/raportarifinanciare/contab/formular40',id].concat(re) },
		contabBalantadecembrie: function(...re) { return ['/raportarifinanciare/contab/balantadecembrie'].concat(re) },
		contabFormular34: function(...re) { return ['/raportarifinanciare/contab/formular34/'].concat(re) },
		contabFormular35a: function(...re) { return ['/raportarifinanciare/contab/formular35a/'].concat(re) },
		contabFormular35c: function(...re) { return ['/raportarifinanciare/contab/formular35c/'].concat(re) },
		contabFormular37: function(...re) { return ['/raportarifinanciare/contab/formular37/'].concat(re) },
		contabAlteanexe: function(id: number, ...re) { return ['/raportarifinanciare/contab/alteanexe',id].concat(re) },
		contabRaportarifinanciare: function(id: number, jsonParam, ...re) { return ['/raportarifinanciare/contab/raportarifinanciare',id,jsonParam].concat(re) },
		bugetBugetindividual: function(...re) { return ['/raportarifinanciare/buget/bugetindividual/'].concat(re) },
		bugetBugetagregat: function(...re) { return ['/raportarifinanciare/buget/bugetagregat/'].concat(re) },
		bugetExportlotbuget: function(...re) { return ['/raportarifinanciare/buget/exportlotbuget/'].concat(re) },
		bugetExportLoturi: function(...re) { return ['/raportarifinanciare/buget/exportLoturi/'].concat(re) },
		trezorerieContexecutie: function(...re) { return ['/raportarifinanciare/trezorerie/contexecutie'].concat(re) },
		trezorerieCompletaresumacredit: function(...re) { return ['/raportarifinanciare/trezorerie/completaresumacredit'].concat(re) },
		trezorerieActualizaresumacredit: function(...re) { return ['/raportarifinanciare/trezorerie/actualizaresumacredit'].concat(re) },
		trezorerieCorectieCAB: function(...re) { return ['/raportarifinanciare/trezorerie/corectieCAB'].concat(re) },
		trezorerieDocumenteCAB: function(...re) { return ['/raportarifinanciare/trezorerie/documenteCAB'].concat(re) },
		trezorerieSoldInitial: function(...re) { return ['/raportarifinanciare/trezorerie/soldInitial'].concat(re) },
		trezorerieListasoldInitial: function(...re) { return ['/raportarifinanciare/trezorerie/listasoldInitial'].concat(re) },
		configurariConfig: function(...re) { return ['/raportarifinanciare/configurari/config/'].concat(re) },
		configurariFormulare: function(...re) { return ['/raportarifinanciare/configurari/formulare/'].concat(re) },
		exportExportContaAs: function(...re) { return ['/raportarifinanciare/export/exportContaAs'].concat(re) },
		exportExportCurteaDeConturi: function(...re) { return ['/raportarifinanciare/export/exportCurteaDeConturi'].concat(re) },
		darsBilantSituatiiFormulare: function(...re) { return ['/raportarifinanciare/DarsBilantSituatii/formulare'].concat(re) },
		darsBilantSituatiiFormulareDetalii: function(...re) { return ['/raportarifinanciare/DarsBilantSituatii/formulareDetalii'].concat(re) },
		forexebugDocumente: function(...re) { return ['/raportarifinanciare/forexebug/documente'].concat(re) }
	},
	impotax: {
		casierieRamasitaCasierieRamasita: function(jsonParam, ...re) { return ['/impotax/casierie/ramasita/casierie/ramasita',jsonParam].concat(re) },
		casierieRamasitaCasierieRamasitaNew: function(jsonParam, ...re) { return ['/impotax/Casierie/Ramasita/casierie/Ramasita/new',jsonParam].concat(re) },
		ramasitaScadereMultiple: function(jsonParam, ...re) { return ['/ramasita/scadereMultiple',jsonParam].concat(re) },
		debiteCompensariDebiteBorderoucompensari: function(jsonParam, ...re) { return ['/impotax/debite/compensari/debite/borderoucompensari',jsonParam].concat(re) },
		debiteCompensariDebiteCompensare: function(jsonParam, ...re) { return ['/impotax/debite/compensari/debite/compensare',jsonParam].concat(re) },
		auto: function(tipPers, id: number, filtru, idDecl: number, istoric, nrMat, copy, jsonParam, ...re) { return ['/impotax/Auto',tipPers,id,filtru,idDecl,istoric,nrMat,copy,jsonParam].concat(re) },
		autoNew: function(tipPers, id: number, idPers: number, idDecl: number, idOp: number, filtru, copy, jsonParam, ...re) { return ['/impotax/Auto/new',tipPers,id,idPers,idDecl,idOp,filtru,copy,jsonParam].concat(re) },
		autoSearch: function(filtru, idPers: number, idDecl: number, tip, copy, jsonParam, ...re) { return ['/impotax/auto/search',filtru,idPers,idDecl,tip,copy,jsonParam].concat(re) },
		teren: function(tipPers, id: number, filtru, idDecl: number, istoric, nrMat, copy, jsonParam, ...re) { return ['/impotax/Teren',tipPers,id,filtru,idDecl,istoric,nrMat,copy,jsonParam].concat(re) },
		terenNew: function(tipPers, id: number, idPers: number, idDecl: number, idOp: number, filtru, copy, jsonParam, ...re) { return ['/impotax/Teren/new',tipPers,id,idPers,idDecl,idOp,filtru,copy,jsonParam].concat(re) },
		terenSearch: function(filtru, idPers: number, tip, cod, jsonParam, ...re) { return ['/impotax/Teren/search',filtru,idPers,tip,cod,jsonParam].concat(re) },
		scutire: function(filtru, idPers: number, idDecl: number, tip, scutit, copy, jsonParam, ...re) { return ['/impotax/Scutire',filtru,idPers,idDecl,tip,scutit,copy,jsonParam].concat(re) },
		coproprietari: function(tipPers, id: number, idPers: number, idDec: number, filtru, tip, codOrg, jsonParam, ...re) { return ['/impotax/Coproprietari',tipPers,id,idPers,idDec,filtru,tip,codOrg,jsonParam].concat(re) },
		cladire: function(tipPers, id: number, filtru, idDecl: number, istoric, nrMat, copy, jsonParam, ...re) { return ['/impotax/Cladire',tipPers,id,filtru,idDecl,istoric,nrMat,copy,jsonParam].concat(re) },
		cladireNew: function(tipPers, id: number, idPers: number, idDecl: number, idOp: number, filtru, copy, jsonParam, ...re) { return ['/impotax/Cladire/new',tipPers,id,idPers,idDecl,idOp,filtru,copy,jsonParam].concat(re) },
		amenda: function(tipPers, id: number, idPers: number, idDecl: number, filtru, copy, jsonParam, ...re) { return ['/impotax/Amenda',tipPers,id,idPers,idDecl,filtru,copy,jsonParam].concat(re) },
		amendaNew: function(tipPers, id: number, idPers: number, idDecl: number, idOp: number, filtru, copy, jsonParam, ...re) { return ['/impotax/Amenda/new',tipPers,id,idPers,idDecl,idOp,filtru,copy,jsonParam].concat(re) },
		amendaSearch: function(filtru, idPers: number, idDecl: number, copy, jsonParam, ...re) { return ['/impotax/Amenda/search',filtru,idPers,idDecl,copy,jsonParam].concat(re) },
		autoLente: function(tipPers, id: number, filtru, idDecl: number, istoric, nrMat, copy, jsonParam, ...re) { return ['/impotax/AutoLente',tipPers,id,filtru,idDecl,istoric,nrMat,copy,jsonParam].concat(re) },
		autoLenteNew: function(tipPers, id: number, idPers: number, idDecl: number, idOp: number, filtru, copy, jsonParam, ...re) { return ['/impotax/AutoLente/new',tipPers,id,idPers,idDecl,idOp,filtru,copy,jsonParam].concat(re) },
		taxaFirma: function(tipPers, id: number, filtru, idDecl: number, istoric, nrMat, copy, jsonParam, ...re) { return ['/impotax/TaxaFirma',tipPers,id,filtru,idDecl,istoric,nrMat,copy,jsonParam].concat(re) },
		taxaFirmaNew: function(tipPers, id: number, idPers: number, idDecl: number, idOp: number, filtru, copy, jsonParam, ...re) { return ['/impotax/TaxaFirma/new',tipPers,id,idPers,idDecl,idOp,filtru,copy,jsonParam].concat(re) },
		alteDebite: function(tipPers, id: number, filtru, idDecl: number, istoric, nrMat, copy, jsonParam, ...re) { return ['/impotax/AlteDebite',tipPers,id,filtru,idDecl,istoric,nrMat,copy,jsonParam].concat(re) },
		alteDebiteNew: function(tipPers, id: number, idPers: number, idDecl: number, idOp: number, filtru, copy, jsonParam, ...re) { return ['/impotax/AlteDebite/new',tipPers,id,idPers,idDecl,idOp,filtru,copy,jsonParam].concat(re) },
		testTest: function(...re) { return ['/test/test/'].concat(re) },
		contracteNomenclatorUnitate: function(...re) { return ['/contracte/Nomenclator/unitate/'].concat(re) },
		dUPLICATContracteContract: function(...re) { return ['/DUPLICATContracte/Contract/'].concat(re) },
		valoriValoriCorectieTeren: function(id: number, jsonParam, ...re) { return ['/impotax/valori/ValoriCorectieTeren',id,jsonParam].concat(re) },
		valoriValoriDebitExecutareSilita: function(id: number, jsonParam, ...re) { return ['/impotax/valori/ValoriDebitExecutareSilita',id,jsonParam].concat(re) },
		valoriValoriAuto: function(id: number, jsonParam, ...re) { return ['/impotax/valori/ValoriAuto',id,jsonParam].concat(re) },
		valoriValoriTerenuriAgricole: function(id: number, jsonParam, ...re) { return ['/impotax/valori/ValoriTerenuriAgricole',id,jsonParam].concat(re) },
		valoriValoriSpectacole: function(id: number, jsonParam, ...re) { return ['/impotax/valori/ValoriSpectacole',id,jsonParam].concat(re) },
		valoriValoriTipAutoLente: function(id: number, jsonParam, ...re) { return ['/impotax/valori/ValoriTipAutoLente',id,jsonParam].concat(re) },
		valoriValoriTerenuriExtravilanCateg: function(id: number, jsonParam, ...re) { return ['/impotax/valori/ValoriTerenuriExtravilanCateg',id,jsonParam].concat(re) },
		valoriValoriTaxeLunare: function(id: number, jsonParam, ...re) { return ['/impotax/valori/ValoriTaxeLunare',id,jsonParam].concat(re) },
		valoriValoriCladiriTipuri: function(id: number, jsonParam, ...re) { return ['/impotax/valori/ValoriCladiriTipuri',id,jsonParam].concat(re) },
		menuValori: function(id: number, jsonParam, ...re) { return ['/impotax/MenuValori',id,jsonParam].concat(re) },
		valoriValoriTerenuriIntravilan: function(id: number, jsonParam, ...re) { return ['/impotax/valori/ValoriTerenuriIntravilan',id,jsonParam].concat(re) },
		valoriValoriTaxaFirma: function(id: number, jsonParam, ...re) { return ['/impotax/valori/ValoriTaxaFirma',id,jsonParam].concat(re) },
		valoriValoriReduceriProcenteCladiri: function(id: number, jsonParam, ...re) { return ['/impotax/valori/ValoriReduceriProcenteCladiri',id,jsonParam].concat(re) },
		valoriCladiriFiz: function(id: number, jsonParam, ...re) { return ['/impotax/ValoriCladiriFiz',id,jsonParam].concat(re) },
		valoriValoriProcenteMajorareCladiriFiz: function(id: number, jsonParam, ...re) { return ['/impotax/valori/ValoriProcenteMajorareCladiriFiz',id,jsonParam].concat(re) },
		valoriValoriProcentSubsol: function(id: number, jsonParam, ...re) { return ['/impotax/valori/ValoriProcentSubsol',id,jsonParam].concat(re) },
		valoriValoriRangZona: function(id: number, jsonParam, ...re) { return ['/impotax/valori/ValoriRangZona',id,jsonParam].concat(re) },
		valoriCladiriJur: function(id: number, jsonParam, ...re) { return ['/impotax/ValoriCladiriJur',id,jsonParam].concat(re) },
		valoriValoriCoeficientiMajorareCL: function(id: number, jsonParam, ...re) { return ['/impotax/valori/ValoriCoeficientiMajorareCL',id,jsonParam].concat(re) },
		valoriValoripoprire: function(id: number, ...re) { return ['/impotax/valori/valoripoprire',id].concat(re) },
		generic: function(tip, jsonParam, ...re) { return ['/impotax/Generic',tip,jsonParam].concat(re) },
		genericNew: function(tip, jsonParam, ...re) { return ['/impotax/Generic/new',tip,jsonParam].concat(re) },
		tipuriScutiri: function(jsonParam, ...re) { return ['/impotax/TipuriScutiri',jsonParam].concat(re) },
		tipuriScutiriNew: function(jsonParam, ...re) { return ['/impotax/TipuriScutiri/new',jsonParam].concat(re) },
		banciPlataPOS: function(jsonParam, ...re) { return ['/impotax/BanciPlataPOS',jsonParam].concat(re) },
		banciPlataPOSNew: function(jsonParam, ...re) { return ['/impotax/BanciPlataPOS/new',jsonParam].concat(re) },
		sarbatoriLegale: function(jsonParam, ...re) { return ['/impotax/SarbatoriLegale',jsonParam].concat(re) },
		sarbatoriLegaleNew: function(jsonParam, ...re) { return ['/impotax/SarbatoriLegale/new',jsonParam].concat(re) },
		tipCertificat: function(jsonParam, ...re) { return ['/impotax/TipCertificat',jsonParam].concat(re) },
		tipCertificatNew: function(jsonParam, ...re) { return ['/impotax/TipCertificat/new',jsonParam].concat(re) },
		procenteDobandaPenalitate: function(tabel, persoana, jsonParam, ...re) { return ['/impotax/ProcenteDobandaPenalitate',tabel,persoana,jsonParam].concat(re) },
		procenteDobandaPenalitateTranse: function(tabel, persoana, idTipVenit: number, idPersoana: number, jsonParam, ...re) { return ['/impotax/ProcenteDobandaPenalitateTranse',tabel,persoana,idTipVenit,idPersoana,jsonParam].concat(re) },
		procenteDobandaPenalitateTranseNew: function(tabel, persoana, idTipVenit: number, idPersoana: number, nou, newId, jsonParam, ...re) { return ['/impotax/ProcenteDobandaPenalitateTranse/new',tabel,persoana,idTipVenit,idPersoana,nou,newId,jsonParam].concat(re) },
		menuGenerice: function(id: number, jsonParam, ...re) { return ['/impotax/MenuGenerice',id,jsonParam].concat(re) },
		grupuriVenit: function(jsonParam, ...re) { return ['/impotax/GrupuriVenit',jsonParam].concat(re) },
		grupVenit: function(jsonParam, ...re) { return ['/impotax/GrupVenit',jsonParam].concat(re) },
		grupVenitNew: function(jsonParam, ...re) { return ['/impotax/GrupVenit/new',jsonParam].concat(re) },
		borderouPosta: function(...re) { return ['/impotax/BorderouPosta'].concat(re) },
		borderouPostaDocumente: function(...re) { return ['/impotax/BorderouPosta/Documente'].concat(re) },
		borderouPostaDetalii: function(id: number, fizjur, ...re) { return ['/impotax/BorderouPosta/Detalii',id,fizjur].concat(re) },
		borderouPostaStarePosta: function(id: number, fizjur, ...re) { return ['/impotax/BorderouPosta/StarePosta',id,fizjur].concat(re) },
		borderouPostaExplicatie: function(id: number, ...re) { return ['/impotax/BorderouPosta/Explicatie',id].concat(re) },
		tipCaenNew: function(newId, id: number, ...re) { return ['/impotax/TipCaen/new',newId,id].concat(re) },
		impotaxconfig: function(...re) { return ['/impotax/impotaxconfig/'].concat(re) }
	},
	incasari: {
		impotaxCasierieCasaCasierieCasaIncasare: function(id: number, jsonParam, ...re) { return ['/impotax/casierie/casa/casierie/casa/incasare',id,jsonParam].concat(re) },
		impotaxCasierieCasaCasierieCasaIncasareparametrizataIncasari: function(jsonParam, ...re) { return ['/impotax/casierie/casa/casierie/casa/incasareparametrizata/incasari',jsonParam].concat(re) },
		impotaxCasierieCasaCasierieCasaPlatafaradebitIncasari: function(jsonParam, ...re) { return ['/impotax/casierie/casa/casierie/casa/platafaradebit/incasari',jsonParam].concat(re) },
		impotaxCasierieCasaCasieriePlatafaradebitfarapersoaneIncasari: function(jsonParam, ...re) { return ['/impotax/casierie/casa/casierie/platafaradebitfarapersoane/incasari',jsonParam].concat(re) },
		impotaxCasierieCasaCasierieCasaPlatafaradebitgarantieIncasari: function(jsonParam, ...re) { return ['/impotax/casierie/casa/casierie/casa/platafaradebitgarantie/incasari',jsonParam].concat(re) },
		impotaxCasierieCasaCasierieCasaRestituire: function(jsonParam, ...re) { return ['/impotax/casierie/casa/casierie/casa/restituire',jsonParam].concat(re) },
		impotaxCasierieCasaCasierieCasaRestituiregarantie: function(jsonParam, ...re) { return ['/impotax/casierie/casa/casierie/casa/restituiregarantie',jsonParam].concat(re) },
		impotaxCasierieCasaGestionareincasarifarapersoana: function(jsonParam, ...re) { return ['/impotax/casierie/casa/gestionareincasarifarapersoana',jsonParam].concat(re) },
		impotaxCasierieCasaCasierieCasaIncasarefacturi: function(jsonParam, ...re) { return ['/impotax/casierie/casa/casierie/casa/incasarefacturi',jsonParam].concat(re) },
		impotaxCasierieCasaCasierieCasaPlatadirecta: function(id: number, idDetaliu: number, ...re) { return ['/impotax/casierie/casa/casierie/casa/platadirecta',id,idDetaliu].concat(re) },
		impotaxCasierieCasaBorderouincasari: function(jsonParam, ...re) { return ['/impotax/casierie/casa/borderouincasari',jsonParam].concat(re) },
		impotaxCasierieCasaValidareincasaridepunerenumerar: function(id: number, jsonParam, ...re) { return ['/impotax/casierie/casa/validareincasaridepunerenumerar',id,jsonParam].concat(re) },
		platiDocumenteDocumentePredarebani: function(id: number, ...re) { return ['/incasariplati/documente/documente/predarebani',id].concat(re) },
		platiRegistruCasaSimplificat: function(id: number, jsonParam, ...re) { return ['/incasariplati/registruCasaSimplificat',id,jsonParam].concat(re) },
		platiRegistruCasaSimplificatCecridicarenumerarsimplificat: function(jsonParam, ...re) { return ['/incasariplati/registruCasaSimplificat/cecridicarenumerarsimplificat',jsonParam].concat(re) },
		platiRegistruCasaSimplificatPlatafaradebit: function(jsonParam, ...re) { return ['/incasariplati/registruCasaSimplificat/platafaradebit',jsonParam].concat(re) },
		platiRegistruCasaSimplificatIncasarecuchitanta: function(jsonParam, ...re) { return ['/incasariplati/registruCasaSimplificat/incasarecuchitanta',jsonParam].concat(re) },
		platiRegistrucasasimplificatRegularizare: function(jsonParam, ...re) { return ['/incasariplati/registrucasasimplificat/regularizare',jsonParam].concat(re) },
		platiRegistrucasasimplificatPlatafaradebitfarapersoana: function(jsonParam, ...re) { return ['/incasariplati/registrucasasimplificat/platafaradebitfarapersoana',jsonParam].concat(re) },
		platiRegistruCasaSimplificatPlatafaradebitGarantie: function(jsonParam, ...re) { return ['/incasariplati/registruCasaSimplificat/platafaradebitGarantie',jsonParam].concat(re) },
		platiRegistrucasasimplificatRestituiregarantie: function(jsonParam, ...re) { return ['/incasariplati/registrucasasimplificat/restituiregarantie',jsonParam].concat(re) },
		impotaxCasierieCasaSituatieincasari: function(jsonParam, ...re) { return ['/impotax/casierie/casa/situatieincasari',jsonParam].concat(re) },
		platiValidareincasariposExtrascontincasaripos: function(jsonParam, ...re) { return ['/incasariplati/validareincasaripos/extrascontincasaripos',jsonParam].concat(re) },
		platiValidareIncasariPOS: function(jsonParam, ...re) { return ['/incasariplati/validareIncasariPOS',jsonParam].concat(re) },
		listaDocumentePlata: function(idDocumentOrdonantare: number, idDocumentStatDePlata: number, jsonParam, ...re) { return ['/listaDocumentePlata',idDocumentOrdonantare,idDocumentStatDePlata,jsonParam].concat(re) },
		documentPlata: function(idDocument: number, idPersoana: number, jsonParam, ...re) { return ['/documentPlata',idDocument,idPersoana,jsonParam].concat(re) },
		documentPlataInAfaraBugetului: function(idDocumentSubTip: number, jsonParam, ...re) { return ['/documentPlataInAfaraBugetului',idDocumentSubTip,jsonParam].concat(re) },
		documentIncasare: function(idDocument: number, ...re) { return ['/documentIncasare',idDocument].concat(re) },
		documentPlataRestituireGarantie: function(jsonParam, ...re) { return ['/documentPlataRestituireGarantie',jsonParam].concat(re) },
		documentViramentInternMandatCheltuiala: function(jsonParam, ...re) { return ['/documentViramentInternMandatCheltuiala',jsonParam].concat(re) },
		documentViramentInternMandatVenit: function(jsonParam, ...re) { return ['/documentViramentInternMandatVenit',jsonParam].concat(re) },
		listaDocumentePlataOPME: function(jsonParam, ...re) { return ['/listaDocumentePlataOPME',jsonParam].concat(re) },
		documentPlataOPME: function(idDocument: number, jsonParam, ...re) { return ['/documentPlataOPME',idDocument,jsonParam].concat(re) },
		platiDocumenteplataDocumenteplataBorderouop: function(jsonParam, ...re) { return ['/incasariplati/documenteplata/documenteplata/borderouop',jsonParam].concat(re) },
		platiIncasaribancaIncasaribancaListaincasari: function(jsonParam, ...re) { return ['/incasariplati/incasaribanca/incasaribanca/listaincasari',jsonParam].concat(re) },
		platiIncasaribancaAlteincasari: function(jsonParam, ...re) { return ['/incasariplati/incasaribanca/alteincasari',jsonParam].concat(re) },
		platiDocumenteListacec: function(jsonParam, ...re) { return ['/incasariplati/documente/listacec',jsonParam].concat(re) },
		platiDocumenteCecridicarenumeredetaliu: function(jsonParam, ...re) { return ['/incasariplati/documente/cecridicarenumeredetaliu',jsonParam].concat(re) },
		platiDocumenteCecridicarenumere: function(jsonParam, ...re) { return ['/incasariplati/documente/cecridicarenumere',jsonParam].concat(re) },
		platiDocumenteAvansuri: function(id: number, jsonParam, ...re) { return ['/incasariplati/documente/avansuri',id,jsonParam].concat(re) },
		platiDocumenteAvansurinew: function(id: number, jsonParam, ...re) { return ['/incasariplati/documente/avansurinew',id,jsonParam].concat(re) },
		platiDocumenteDocumenteAvansdecont: function(jsonParam, ...re) { return ['/incasariplati/documente/documente/avansdecont',jsonParam].concat(re) },
		platiDocumenteOrdindeplatanew: function(id: number, idDetaliu: number, jsonParam, ...re) { return ['/incasariplati/documente/ordindeplatanew',id,idDetaliu,jsonParam].concat(re) },
		platiDocumenteDeplasari: function(id: number, jsonParam, ...re) { return ['/incasariplati/documente/deplasari',id,jsonParam].concat(re) },
		platiDocumenteDeplasarinew: function(id: number, jsonParam, ...re) { return ['/incasariplati/documente/deplasarinew',id,jsonParam].concat(re) },
		platiDocumenteAdddocjustificativ: function(id: number, jsonParam, ...re) { return ['/incasariplati/documente/adddocjustificativ',id,jsonParam].concat(re) },
		platiDocumenteDispozitiideplata: function(id: number, jsonParam, ...re) { return ['/incasariplati/documente/dispozitiideplata',id,jsonParam].concat(re) },
		platiDocumenteDispozitiideplatanew: function(id: number, jsonParam, ...re) { return ['/incasariplati/documente/dispozitiideplatanew',id,jsonParam].concat(re) },
		platiDocumenteDispozitiideincasare: function(id: number, jsonParam, ...re) { return ['/incasariplati/documente/dispozitiideincasare',id,jsonParam].concat(re) },
		platiDocumenteDispozitiideincasarenew: function(id: number, jsonParam, ...re) { return ['/incasariplati/documente/dispozitiideincasarenew',id,jsonParam].concat(re) },
		platiDocumenteFoiDeVarsamant: function(...re) { return ['/incasariplati/documente/FoiDeVarsamant/'].concat(re) },
		platiDocumenteFoiDeVarsamantAdaugare: function(...re) { return ['/incasariplati/documente/FoiDeVarsamantAdaugare/'].concat(re) },
		platiSituatiiRegistruCasa: function(id: number, jsonParam, ...re) { return ['/incasariplati/situatii/registruCasa',id,jsonParam].concat(re) },
		platiSituatiiSituatiiBorderoupunctdelucru: function(jsonParams, ...re) { return ['/incasariplati/situatii/situatii/borderoupunctdelucru',jsonParams].concat(re) },
		platiSituatiiSituatiiFisapersoana: function(jsonParam, ...re) { return ['/incasariplati/situatii/situatii/fisapersoana',jsonParam].concat(re) },
		platiDocumenteStatdeplatanumerar: function(id: number, jsonParam, ...re) { return ['/incasariplati/documente/statdeplatanumerar',id,jsonParam].concat(re) },
		platiSituatiiSituatiiBorderou: function(jsonParam, ...re) { return ['/incasariplati/situatii/situatii/borderou',jsonParam].concat(re) },
		platiSituatiiSituatiiListaordinplata: function(jsonParam, ...re) { return ['/incasariplati/situatii/situatii/listaordinplata',jsonParam].concat(re) },
		platiSituatiiSituatiiListarerapoarte: function(jsonParam, ...re) { return ['/incasariplati/situatii/situatii/listarerapoarte',jsonParam].concat(re) },
		platiAlteincasariIncasareepay: function(jsonParam, ...re) { return ['/incasariplati/alteincasari/incasareepay',jsonParam].concat(re) },
		platiAlteincasariConfiggpay: function(jsonParam, ...re) { return ['/incasariplati/alteincasari/configgpay',jsonParam].concat(re) },
		platiAlteincasariIncasareghiseul: function(jsonParam, ...re) { return ['/incasariplati/alteincasari/incasareghiseul',jsonParam].concat(re) },
		platiIncasareConfigincasare: function(...re) { return ['/incasariplati/incasare/configincasare'].concat(re) },
		platiAlteincasariConfigghiseul: function(...re) { return ['/incasariplati/alteincasari/configghiseul/'].concat(re) },
		raportarifinanciareTrezorerieExtrascont: function(...re) { return ['/raportarifinanciare/trezorerie/extrascont'].concat(re) },
		raportarifinanciareTrezorerieImportextrascont: function(...re) { return ['/raportarifinanciare/trezorerie/importextrascont'].concat(re) },
		raportarifinanciareTrezorerieTrezorerieExtrascontincasare: function(jsonParam, ...re) { return ['/raportarifinanciare/trezorerie/trezorerie/extrascontincasare',jsonParam].concat(re) }
	},
	achizitii: {
		propuneri: function(id: number, ...re) { return ['/achizitii/Propuneri',id].concat(re) },
		propuneriTrimite: function(id: number, tipDocument, idUnitate: number, idInreg: number, ...re) { return ['/achizitii/PropuneriTrimite',id,tipDocument,idUnitate,idInreg].concat(re) },
		propuneriPropuneriv2New: function(id: number, an, unitate, departament, ...re) { return ['/achizitii/propuneri/propuneriv2/new',id,an,unitate,departament].concat(re) },
		propuneriPropuneriincadrarecpv: function(jsonParam, ...re) { return ['/achizitii/propuneri/propuneriincadrarecpv',jsonParam].concat(re) },
		propuneriPropuneristerse: function(jsonParam, ...re) { return ['/achizitii/propuneri/propuneristerse',jsonParam].concat(re) },
		propuneriPropuneriistoric: function(jsonParam, ...re) { return ['/achizitii/propuneri/propuneriistoric',jsonParam].concat(re) },
		propuneriDetalii: function(id: number, idPropunere: number, unitate, an, dataAdaugare: string|Date, ...re) { return ['/achizitii/propuneri/detalii',id,idPropunere,unitate,an,dataAdaugare].concat(re) },
		propunerifisa: function(id: number, ...re) { return ['/achizitii/propunerifisa',id].concat(re) },
		propuneriIncadrareBugetara: function(idPropunere: number, idDetaliu: number, unitate, an, dataAdaugare: string|Date, ...re) { return ['/achizitii/Propuneri/incadrareBugetara',idPropunere,idDetaliu,unitate,an,dataAdaugare].concat(re) },
		propuneriPropuneriananterior: function(jsonParam, ...re) { return ['/achizitii/propuneri/propuneriananterior',jsonParam].concat(re) },
		programanual: function(id: number, ...re) { return ['/achizitii/Programanual',id].concat(re) },
		surseFinantare: function(id: number, ...re) { return ['/achizitii/SurseFinantare',id].concat(re) },
		surseFinantarenew: function(id: number, idPozitie: number, ...re) { return ['/achizitii/SurseFinantarenew',id,idPozitie].concat(re) },
		programanualProgramanualv2New: function(id: number, idPersoanaUnitate: number, an, idUnic: number, ...re) { return ['/achizitii/programanual/programanualv2/new',id,idPersoanaUnitate,an,idUnic].concat(re) },
		programanualProgramanualv2pereferate: function(id: number, idPersoanaUnitate: number, an, idUnic: number, jsonParam, ...re) { return ['/achizitii/programanual/programanualv2pereferate',id,idPersoanaUnitate,an,idUnic,jsonParam].concat(re) },
		programnew: function(id: number, ...re) { return ['/achizitii/Programnew',id].concat(re) },
		propuneriPropunericentralizator: function(jsonParam, ...re) { return ['/achizitii/propuneri/propunericentralizator',jsonParam].concat(re) },
		propuneriAjustarepropuneri: function(jsonParam, ...re) { return ['/achizitii/propuneri/ajustarepropuneri',jsonParam].concat(re) },
		propuneriCentralizatorcpv: function(jsonParam, ...re) { return ['/achizitii/propuneri/centralizatorcpv',jsonParam].concat(re) },
		referateNecesitate: function(id: number, ...re) { return ['/achizitii/ReferateNecesitate',id].concat(re) },
		referateNecesitateFisa: function(id: number, nrReferat, ...re) { return ['/achizitii/ReferateNecesitateFisa',id,nrReferat].concat(re) },
		referateNecesitateSurseFinantare: function(id: number, jsonParam, ...re) { return ['/achizitii/ReferateNecesitateSurseFinantare',id,jsonParam].concat(re) },
		referateNecesitateSurseFinantarenew: function(id: number, idReferat: number, ...re) { return ['/achizitii/ReferateNecesitateSurseFinantarenew',id,idReferat].concat(re) },
		referateReferatenecesitatev2New: function(id: number, an, idPersoana: number, idDocument: number, ...re) { return ['/achizitii/referate/referatenecesitatev2/new',id,an,idPersoana,idDocument].concat(re) },
		referateReferatenecesitatesterse: function(jsonParam, ...re) { return ['/achizitii/referate/referatenecesitatesterse',jsonParam].concat(re) },
		referateReferatnecesitateistoric: function(jsonParam, ...re) { return ['/achizitii/referate/referatnecesitateistoric',jsonParam].concat(re) },
		referateReferatenecesitatedetaliu: function(jsonParam, ...re) { return ['/achizitii/referate/referatenecesitatedetaliu',jsonParam].concat(re) },
		referateNecesitateDetaliinew: function(id: number, idReferat: number, unitate, an, dataReferat: string|Date, cursValutar, ...re) { return ['/achizitii/ReferateNecesitateDetaliinew',id,idReferat,unitate,an,dataReferat,cursValutar].concat(re) },
		referateNecesitateIstoric: function(id: number, nrReferat, ...re) { return ['/achizitii/ReferateNecesitateIstoric',id,nrReferat].concat(re) },
		formularAchizitii: function(...re) { return ['/achizitii/formularAchizitii/'].concat(re) },
		formularAchizitiiNew: function(id: number, an, idUnitate: number, ...re) { return ['/achizitii/formularAchizitiiNew',id,an,idUnitate].concat(re) },
		oferte: function(id: number, ...re) { return ['/achizitii/oferte',id].concat(re) },
		proceduriachizitiiOfertenew: function(id: number, jsonParam, ...re) { return ['/achizitii/proceduriachizitii/ofertenew',id,jsonParam].concat(re) },
		atasareReferat: function(id: number, an, idUnitate: number, ...re) { return ['/achizitii/atasareReferat',id,an,idUnitate].concat(re) },
		etape: function(idTip: number, idEtapa: number, idDocument: number, ...re) { return ['/achizitii/Etape',idTip,idEtapa,idDocument].concat(re) },
		criterii: function(...re) { return ['/achizitii/Criterii'].concat(re) },
		criteriinew: function(id: number, ...re) { return ['/achizitii/Criteriinew',id].concat(re) },
		achizitii: function(...re) { return ['/Achizitii/Achizitii'].concat(re) }
	},
	clienti: {
		documenteContracteContracteclientContracteContracteclientNewClienti: function(jsonParam, ...re) { return ['/documente/contracte/contracteclient/contracte/contracteclient/new/clienti',jsonParam].concat(re) },
		documentClient: function(...re) { return ['/document/client'].concat(re) },
		contracteClient: function(jsonParam, ...re) { return ['/contracte/client',jsonParam].concat(re) },
		contracteContracteClientNew: function(jsonParam, ...re) { return ['/contracte/contracteClient/new',jsonParam].concat(re) },
		configConfig: function(...re) { return ['/clienti/config/config/'].concat(re) }
	},
	resum: {
		pontaj: function(...re) { return ['/resum/pontaj'].concat(re) }
	},
	salarii: {
		nomenclatorFinantari: function(...re) { return ['/salarii/Nomenclator/Finantari/'].concat(re) },
		nomenclatorFinantariNew: function(id: number, ...re) { return ['/salarii/Nomenclator/Finantari/new',id].concat(re) }
	},
	asisoc: {
		familia: function(id: number, ...re) { return ['/asisoc/Familia',id].concat(re) },
		familiaNew: function(id: number, ...re) { return ['/asisoc/Familia/new',id].concat(re) },
		registreTipajutor: function(...re) { return ['/asisoc/registre/tipajutor'].concat(re) },
		dgaspcListacerere: function(id: number, ...re) { return ['/asisoc/dgaspc/listacerere',id].concat(re) },
		dgaspcDgaspc: function(id: number, ...re) { return ['/asisoc/dgaspc/dgaspc',id].concat(re) },
		dgaspcHandicapHandicap: function(id: number, ...re) { return ['/asisoc/dgaspc/handicap/handicap',id].concat(re) },
		dgaspcHandicapDgaspcHandicapRaspuns: function(id: number, idTipCerere: number, idCertificat: number, ...re) { return ['/asisoc/dgaspc/handicap/dgaspc/handicap/raspuns',id,idTipCerere,idCertificat].concat(re) },
		dgaspcTichetparcareTicheteparcare: function(id: number, ...re) { return ['/asisoc/dgaspc/tichetparcare/ticheteparcare',id].concat(re) },
		dgaspcTicheteparcareTicheteparcareRaspuns: function(id: number, idTichet: number, ...re) { return ['/asisoc/dgaspc/ticheteparcare/ticheteparcare/raspuns',id,idTichet].concat(re) },
		dgaspcVignetaVigneta: function(id: number, ...re) { return ['/asisoc/dgaspc/vigneta/vigneta',id].concat(re) },
		dgaspcVignetaVigneteRaspuns: function(id: number, idVigneta: number, ...re) { return ['/asisoc/dgaspc/vigneta/vignete/raspuns',id,idVigneta].concat(re) },
		dgaspcExtrasCfExtras: function(id: number, ...re) { return ['/asisoc/dgaspc/extrasCf/extras',id].concat(re) },
		dgaspcExtrascfExtrascfRaspuns: function(id: number, idSolicitareScutireProprietate: number, ...re) { return ['/asisoc/dgaspc/extrascf/extrascf/raspuns',id,idSolicitareScutireProprietate].concat(re) },
		dgaspcScutireimpozitScutire: function(id: number, ...re) { return ['/asisoc/dgaspc/scutireimpozit/scutire',id].concat(re) },
		dgaspcScutireimpozitScutireRaspuns: function(id: number, idSolicitareScutireImpozit: number, ...re) { return ['/asisoc/dgaspc/scutireimpozit/scutire/raspuns',id,idSolicitareScutireImpozit].concat(re) },
		dgaspcDetalii: function(id: number, jsonParam, ...re) { return ['/asisoc/dgaspc/detalii',id,jsonParam].concat(re) },
		dgaspcCertificatnou: function(id: number, ...re) { return ['/asisoc/dgaspc/certificatnou',id].concat(re) },
		asisocConfigAsisoc: function(...re) { return ['/Asisoc/asisoc/configAsisoc'].concat(re) }
	},
	agroregis: {
		aplicatie: function(id: number, ...re) { return ['/agroregis/Aplicatie',id].concat(re) }
	},
	nomenclatoaregenerale: {
		nomgenSectorbugetarNomenclatorSectorbugetar: function(id: number, jsonParam, ...re) { return ['/nomgen/sectorbugetar/nomenclator/sectorbugetar',id,jsonParam].concat(re) },
		nomgenSectorbugetarNomenclatorSectorbugetarNew: function(id: number, jsonParam, ...re) { return ['/nomgen/sectorbugetar/nomenclator/sectorbugetar/new',id,jsonParam].concat(re) },
		nomgenSursafinantareNomenclatorSursafinantare: function(id: number, jsonParam, ...re) { return ['/nomgen/sursafinantare/nomenclator/sursafinantare',id,jsonParam].concat(re) },
		nomgenSursafinantareNomenclatorSursafinantareNew: function(id: number, jsonParam, ...re) { return ['/nomgen/sursafinantare/nomenclator/sursafinantare/new',id,jsonParam].concat(re) },
		nomgenNomenclatorClasificatiefunctionala: function(id: number, jsonParam, ...re) { return ['/nomgen/nomenclator/clasificatiefunctionala',id,jsonParam].concat(re) },
		nomgenClasificatiefunctionalaNomenclatorClasificatiefunctionalaNew: function(idTree: number, id: number, jsonParam, ...re) { return ['/nomgen/clasificatiefunctionala/nomenclator/clasificatiefunctionala/new',idTree,id,jsonParam].concat(re) },
		nomgenClasificatieeconomicaNomenclatorClasificatieeconomica: function(id: number, jsonParam, ...re) { return ['/nomgen/clasificatieeconomica/nomenclator/clasificatieeconomica',id,jsonParam].concat(re) },
		nomgenClasificatieeconomicaNomenclatorClasificatieeconomicaNew: function(parentId, id: number, jsonParam, ...re) { return ['/nomgen/clasificatieeconomica/nomenclator/clasificatieeconomica/new',parentId,id,jsonParam].concat(re) },
		nomgenCodprogrambugetarNomenclatorCodprogrambugetar: function(id: number, jsonParam, ...re) { return ['/nomgen/codprogrambugetar/nomenclator/codprogrambugetar',id,jsonParam].concat(re) },
		nomgenCodprogrambugetarNomenclatorCodprogrambugetarNew: function(id: number, jsonParam, ...re) { return ['/nomgen/codprogrambugetar/nomenclator/codprogrambugetar/new',id,jsonParam].concat(re) },
		nomgenCursvalutarCursvalutar: function(id: number, ...re) { return ['/nomgen/cursvalutar/cursvalutar',id].concat(re) },
		nomgenClasificareproduse: function(id: number, jsonParam, ...re) { return ['/nomgen/clasificareproduse',id,jsonParam].concat(re) },
		nomgenClasificareproduseClasificarenoua: function(idParinte: number, jsonParam, ...re) { return ['/nomgen/clasificareproduse/clasificarenoua',idParinte,jsonParam].concat(re) },
		nomgenFunctiiFunctii: function(id: number, jsonParam, ...re) { return ['/nomgen/functii/functii',id,jsonParam].concat(re) },
		nomgenFunctiiFunctiiNew: function(parentId, id: number, dataReferinta: string|Date, jsonParam, ...re) { return ['/nomgen/functii/functii/new',parentId,id,dataReferinta,jsonParam].concat(re) },
		nomgenUtilitatiNomenclatorTipuriutilitati: function(id: number, ...re) { return ['/nomgen/utilitati/nomenclator/tipuriutilitati',id].concat(re) },
		nomgenUtilitatiNomenclatorTipuriutilitatiNew: function(id: number, ...re) { return ['/nomgen/utilitati/nomenclator/tipuriutilitati/new',id].concat(re) },
		nomgenUtilitatiNomenclatorTipuriservicii: function(id: number, ...re) { return ['/nomgen/utilitati/nomenclator/tipuriservicii',id].concat(re) },
		nomgenUtilitatiNomenclatorTipuriserviciiNew: function(id: number, ...re) { return ['/nomgen/utilitati/nomenclator/tipuriservicii/new',id].concat(re) },
		nomgenUtilitatiNomenclatorTipuriplatinumerar: function(id: number, ...re) { return ['/nomgen/utilitati/nomenclator/tipuriplatinumerar',id].concat(re) },
		nomgenUtilitatiNomenclatorTipuriplatinumerarNew: function(id: number, ...re) { return ['/nomgen/utilitati/nomenclator/tipuriplatinumerar/new',id].concat(re) },
		nomgenUtilitatiNomenclatorTipuriplatinumerarPersonalizare: function(id: number, ...re) { return ['/nomgen/utilitati/nomenclator/tipuriplatinumerar/personalizare',id].concat(re) },
		nomgenCpv: function(...re) { return ['/nomgen/cpv/'].concat(re) },
		nomgenTipuriVenituri: function(jsonParam, ...re) { return ['/nomgen/TipuriVenituri',jsonParam].concat(re) },
		nomgenTipuriVenituriNew: function(jsonParam, ...re) { return ['/nomgen/TipuriVenituri/new',jsonParam].concat(re) },
		nomgenDocumenteOperatiuniCFP: function(id: number, ...re) { return ['/nomgen/documente/operatiuniCFP',id].concat(re) },
		nomgenDocumenteOperatiuniCFPNew: function(id: number, jsonParam, ...re) { return ['/nomgen/documente/operatiuniCFP/new',id,jsonParam].concat(re) },
		nomgenDocumenteOperatiuniCFPCAsociereClasificatieEconomica: function(id: number, jsonParam, ...re) { return ['/nomgen/documente/operatiuniCFPCAsociereClasificatieEconomica',id,jsonParam].concat(re) },
		nomgenDocumenteNomenclatorOperatiuniproprii: function(...re) { return ['/nomgen/documente/nomenclator/operatiuniproprii/'].concat(re) },
		nomgenDocumenteNomenclatorOperatiunipropriiNew: function(id: number, ...re) { return ['/nomgen/documente/nomenclator/operatiuniproprii/new',id].concat(re) },
		nomgenDocumenteNomenclatorOperatiunipropriiDetaliunew: function(id: number, ...re) { return ['/nomgen/documente/nomenclator/operatiuniproprii/detaliunew',id].concat(re) },
		nomgenSabloaneDeConturi: function(id: number, jsonParam, ...re) { return ['/nomgen/sabloaneDeConturi',id,jsonParam].concat(re) },
		nomgenSabloaneDeConturiNew: function(id: number, jsonParam, ...re) { return ['/nomgen/SabloaneDeConturi/new',id,jsonParam].concat(re) },
		nomgenSabloaneConturiPeOperatiuni: function(...re) { return ['/nomgen/sabloaneConturiPeOperatiuni'].concat(re) },
		nomgenAsociereContBancaCuTipBuget: function(...re) { return ['/nomgen/asociereContBancaCuTipBuget/'].concat(re) },
		nomgenAsociereContBancaCuTipBugetNew: function(id: number, ...re) { return ['/nomgen/asociereContBancaCuTipBuget/new',id].concat(re) },
		nomgenDarsbilantsituatiiDarsbilantsituatiiFormulardars: function(...re) { return ['/nomgen/darsbilantsituatii/darsbilantsituatii/formulardars'].concat(re) }
	},
	deploy: {
		databaseScript: function(...re) { return ['/deploy/database/Script'].concat(re) },
		databaseValidate: function(...re) { return ['/deploy/database/Validate'].concat(re) },
		dateGenerarePachete: function(jsonParam, ...re) { return ['/deploy/date/generarePachete',jsonParam].concat(re) },
		dateGenerareLogDataService: function(jsonParam, ...re) { return ['/deploy/date/generareLogDataService',jsonParam].concat(re) },
		dateRegistruImportDate: function(jsonParam, ...re) { return ['/deploy/date/registruImportDate',jsonParam].concat(re) },
		dateRegistruImportDateNew: function(jsonParam, ...re) { return ['/deploy/date/registruImportDate/new',jsonParam].concat(re) },
		surseDeploySurse: function(...re) { return ['/deploy/surse/deploySurse'].concat(re) },
		surseValidaresurse: function(...re) { return ['/deploy/surse/validaresurse'].concat(re) },
		administrare: function(...re) { return ['/deploy/administrare'].concat(re) }
	},
	conversiidate: {
		wizardWelcome: function(...re) { return ['/conversiidate/wizard/Welcome/'].concat(re) },
		wizardSetup: function(...re) { return ['/conversiidate/wizard/Setup/'].concat(re) },
		wizardPersoane: function(...re) { return ['/conversiidate/wizard/Persoane/'].concat(re) },
		wizardUnitati: function(...re) { return ['/conversiidate/wizard/Unitati/'].concat(re) },
		wizardBanci: function(...re) { return ['/conversiidate/wizard/Banci/'].concat(re) },
		wizardBuget: function(...re) { return ['/conversiidate/wizard/Buget/'].concat(re) },
		wizardGestStoc: function(...re) { return ['/conversiidate/wizard/GestStoc'].concat(re) },
		wizardMiFix: function(...re) { return ['/conversiidate/wizard/MiFix'].concat(re) },
		wizardContab: function(...re) { return ['/conversiidate/wizard/Contab'].concat(re) },
		wizardFinalSetup: function(...re) { return ['/conversiidate/wizard/FinalSetup/'].concat(re) },
		wizardVerificare: function(...re) { return ['/conversiidate/wizard/verificare/'].concat(re) }
	},
	invatamant: {
		grupe: function(jsonParam, ...re) { return ['/invatamant/grupe',jsonParam].concat(re) },
		grupaGrupanew: function(id: number, idGrupa: number, jsonParam, ...re) { return ['/invatamant/grupa/grupanew',id,idGrupa,jsonParam].concat(re) },
		prezenta: function(...re) { return ['/invatamant/prezenta/'].concat(re) },
		rapoarteRestante: function(jsonParam, ...re) { return ['/invatamant/rapoarte/restante',jsonParam].concat(re) },
		rapoarteValabilitateFlotant: function(...re) { return ['/invatamant/rapoarte/valabilitateFlotant/'].concat(re) },
		configurariConfig: function(jsonParam, ...re) { return ['/invatamant/configurari/config',jsonParam].concat(re) },
		configurariVacante: function(jsonParam, ...re) { return ['/invatamant/configurari/vacante',jsonParam].concat(re) },
		configurariVacanteNew: function(id: number, jsonParam, ...re) { return ['/invatamant/configurari/vacante/new',id,jsonParam].concat(re) }
	},
	cantina: {
		cantinaPreparate: function(jsonParam, ...re) { return ['/cantina/cantina/preparate',jsonParam].concat(re) },
		cantinaPreparateNew: function(jsonParam, ...re) { return ['/cantina/cantina/preparate/new',jsonParam].concat(re) },
		cantinaMeniu: function(jsonParam, ...re) { return ['/cantina/cantina/meniu',jsonParam].concat(re) },
		cantinaMeniuNew: function(jsonParam, ...re) { return ['/cantina/cantina/meniu/new',jsonParam].concat(re) },
		cantinaPlanificare: function(jsonParam, ...re) { return ['/cantina/cantina/planificare',jsonParam].concat(re) },
		planificareNew: function(id: number, jsonParam, ...re) { return ['/cantina/planificare/new',id,jsonParam].concat(re) },
		cantinaListaalimenteperioada: function(jsonparam, ...re) { return ['/cantina/cantina/listaalimenteperioada',jsonparam].concat(re) },
		cantinaListaalimentebucatar: function(jsonParam, ...re) { return ['/cantina/cantina/listaalimentebucatar',jsonParam].concat(re) },
		cantinaListazilnicaalimente: function(...re) { return ['/cantina/cantina/listazilnicaalimente'].concat(re) },
		cantinaAlergen: function(jsonParam, ...re) { return ['/cantina/cantina/alergen',jsonParam].concat(re) },
		cantinaAlergenNew: function(jsonParam, ...re) { return ['/cantina/cantina/alergen/new',jsonParam].concat(re) },
		cantinaAlimente: function(jsonParam, ...re) { return ['/cantina/cantina/alimente',jsonParam].concat(re) },
		cantinaAlimenteNew: function(jsonParam, ...re) { return ['/cantina/cantina/alimente/new',jsonParam].concat(re) },
		categoriiPersoane: function(...re) { return ['/cantina/categorii/persoane'].concat(re) },
		categoriiPersoaneNew: function(...re) { return ['/cantina/categorii/persoane/new'].concat(re) },
		oraservire: function(...re) { return ['/cantina/oraservire/'].concat(re) },
		oraservireNew: function(...re) { return ['/cantina/oraservire/new/'].concat(re) },
		preparat: function(...re) { return ['/cantina/preparat'].concat(re) },
		categorieNew: function(...re) { return ['/cantina/categorie/new/'].concat(re) },
		configCantinaconfig: function(jsonParam, ...re) { return ['/cantina/config/cantinaconfig',jsonParam].concat(re) },
		configSoldinitial: function(id: number, ...re) { return ['/cantina/config/soldinitial',id].concat(re) },
		configAsocierecategorii: function(...re) { return ['/cantina/config/asocierecategorii'].concat(re) },
		asocierecategorieNew: function(...re) { return ['/cantina/asocierecategorie/new'].concat(re) },
		configListasolduri: function(id: number, ...re) { return ['/cantina/config/listasolduri',id].concat(re) }
	},
	investitii: {
		obiectiveObiectivnou: function(idObiectiv: number, idSubobiectiv: number, isSubobiectiv: boolean, ...re) { return ['/investitii/obiective/obiectivnou',idObiectiv,idSubobiectiv,isSubobiectiv].concat(re) },
		obiective: function(...re) { return ['/investitii/obiective'].concat(re) },
		obiectiveCompletaresolduriinitiale: function(id: number, jsonParam, ...re) { return ['/investitii/obiective/completaresolduriinitiale',id,jsonParam].concat(re) },
		configurariConfig: function(...re) { return ['/investitii/configurari/config/'].concat(re) }
	},
	dev: {
		setari: function(...re) { return ['/dev/setari'].concat(re) },
		resursa: function(...re) { return ['/dev/resursa'].concat(re) },
		resursaControl: function(...re) { return ['/dev/resursa/control'].concat(re) },
		componentSamples: function(...re) { return ['/dev/component/samples'].concat(re) },
		deploy: function(...re) { return ['/dev/deploy'].concat(re) },
		releaseValidare: function(...re) { return ['/dev/release/validare'].concat(re) }
	},
	user: {
		cont: function(...re) { return ['/user/cont'].concat(re) },
		setare: function(...re) { return ['/user/setare'].concat(re) },
		theme: function(...re) { return ['/user/theme'].concat(re) },
		notificari: function(...re) { return ['/user/notificari'].concat(re) },
		email: function(...re) { return ['/user/email'].concat(re) },
		accessDenied: function(page, ...re) { return ['/user/access/denied',page].concat(re) }
	}
}