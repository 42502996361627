import { InjectionToken } from "@angular/core";
// import { DataService } from "C:/Users/sorin.muresan/Documents/Visual Studio 2017/Projects/Sief/Public/Sief.Client/scripts/Sief.Core"
// import { DataService } from "C:/Users/sorin.muresan/Documents/Visual Studio 2017/Projects/Sief/Public/Sief.Client/scripts/Sief.Core"

export const DataService = new InjectionToken<any>('data');
export const ConfigService = new InjectionToken<any>('config');
export const UserService = new InjectionToken<any>('user');
export const DialogService = new InjectionToken<any>('dialog');
export const AlertService = new InjectionToken<any>('alert');
export const CursValutarService = new InjectionToken<any>('cursValutar');
export const EmailService = new InjectionToken<any>('email');
export const FileService = new InjectionToken<any>('file');
export const LogService = new InjectionToken<any>('log');
export const ReportService = new InjectionToken<any>('report');
export const ValidationService = new InjectionToken<any>('validation');
export const WebSocketService = new InjectionToken<any>('ws');
// export const settings = new InjectionToken<any>('settings');
