import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { catchError, map, share, switchMap, take, takeWhile } from 'rxjs/operators';

import { UserService } from "./UserService";
import { environment } from 'src/environments/environment.dev';

interface IWebSoketResponse {
    message: string | { [key: string]: any },
    event: string,
    type?: string,
}

@Injectable({ providedIn: 'root' })
export class WebSoketService {
    constructor(private user: UserService) {
        const w = window.location;
        let origin = w.origin ? w.origin : `${w.protocol}//${w.hostname}${(w.port ? `:${w.port}` : '')}`;
        if (!environment.production) { origin = origin.replace(environment['port'], environment['serverPort']) }
        this.wsUrl = `${origin.replace('http', 'ws')}/ws`;
    }

    private wsUrl: string;
    private socket: WebSocketSubject<any>;
    private subscription: Observable<string | { [key: string]: any }>;

    /**
     * close/complete curent subscription and socket
     * .send(...) will create new socket and subscription
     */
    close() {
        this.socket && this.socket.complete && this.socket.complete();
        this.subscription = null;
        this.socket = null;
    }

    /**
     * DO NOT USE WITH callback parameter
     * send message to server, creates new subscription, DON'T FORGET to unsubscribe
     * @param event 
     * @param message 
     * @returns subscription
     */
    send(event: string, message?: string | { [key: string]: any }, callback?: (data) => any): Observable<IWebSoketResponse | string | any> {
        this.connect();
        if (!this.subscription) { this.subscription = this.getSubscription() }
        this.socket.next({ event: event, message: message, auth: `Bearer ${this.user.token}` });
        if (callback && event == 'executeQuery') { // only one response expected
            return <any>this.subscription.pipe(take(1)).subscribe(data => callback(data))
        }
        return this.subscription;
    }

    /**
     * listens to server messages, 
     * posts back if event is equal to the one provided, also includes 'error' and 'warning' events
     * error event will throw error, stops subscription
     * 
     * unsubscribes automatically if message is instanceOf Error or contains event: 'error' or progress/procent = 100
     * otherwise DON'T FORGET clean up subscription (unsubscribe)
     * 
     * @param event 
     * @returns subscription
     */
    on(event: string): Observable<string | { [key: string]: any }> {
        this.connect();
        return this.socket.multiplex(
            () => ({ subscribe: event, event: 'register' }),
            () => ({ unsubscribe: event, event: 'register' }),
            (msg) => msg.type === event || msg.event === event || msg.event === 'error' || msg.event === 'warning'
        ).pipe(
            // takeUntil(this.socket.pipe(catchError(error => { return error }))), // this will close subscription, but wont emit error
            catchError(error => { return error }),
            takeWhile(data => !(data['progress'] == 100 || data['procent'] == 100 || data.event == 'error' || data instanceof Error), true),
            map(data => {
                if (data instanceof Error) { throw data }
                if (data.event == 'error') { throw new Error(data.message) }
                if (typeof data.message == 'string' && data.message.length > 0) {
                    try { data.message = JSON.parse(data.message) } catch (error) { }
                }
                return <string | { [key: string]: any }>data.message
            }),
        )
    }
    /**
     * @deprecated, will be removed, use .on() instead
     * uses .on() and subscribes if callback is provided
     */
    register(event: string, callback?: (data: { event: string, message: any, progress?: number, procent?: number }) => void) {
        const sub = this.on(event);
        if (callback) { sub.subscribe(data => callback(<any>data)) }
        return sub;
    }

    private connect() {
        if (!this.socket || this.socket.closed) {
            this.close();
            this.socket = webSocket({ url: this.wsUrl });
        } else {
            console.log('socket ok, new socket not necessary')
        }
    }
    private getSubscription() {
        return this.socket.asObservable().pipe(
            share(),
            catchError(error => { return error }),
            switchMap(data => {
                if (typeof data.message == 'string' && data.message.length > 0) {
                    try { data.message = JSON.parse(data.message) } catch (error) { }
                }
                // notifications will need to separate messages by events => need to return data
                // if (data.event == 'error') { return throwError(() => new Error(data.message || data)) }
                if (data.event == 'error' || data instanceof Error) { throw new Error(data.message || data) }
                return of<IWebSoketResponse>(data)
            })
        )
    }
}