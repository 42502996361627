<div class="modal-header bg-warning">
    <h4>Incetare inregistrare</h4>
</div>
<div class="modal-body">
    <h5>{{message}}</h5>
    <form class="form-horizontal">
        <div class="mb-2">
            <label class="form-label">Dată încetare: </label>

            <datepicker *ngIf="dateMode=='month' || dateMode=='year'"
                [date]="dataAnulare" [options]="dateOptions" (change)="dateSelect($event)">
            </datepicker>

            <div class="input-group" *ngIf="dateMode=='day'">
                <input type="text" [disabled]="isReadOnly" autofocus
                    [(ngModel)]="dataAnulare" name="data" (change)="save()"
                    #d="ngbDatepicker" ngbDatepicker (dateSelect)="save()"
                    [minDate]="minDate" [view]="dateMode" />
                <button type="button" class="btn btn-primary" tabindex="-1"
                    [disabled]="isReadOnly" (click)="d.toggle()">
                    <i class="fal fa-1x fa-calendar"></i>
                </button>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="submit" class="btn btn-success" (click)="save()"><i class="fa fa-save fa-1x"></i>&nbsp;Confirmă</button>
    <button type="cancel" class="btn btn-danger" (click)="cancel()"><i class="fa fa-undo fa-1x"></i>&nbsp;Renunță</button>
</div>