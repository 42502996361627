import { Injectable } from '@angular/core';
import { AirDatepickerLocale, AirDatepickerOptions } from 'air-datepicker';
import localeRo from 'air-datepicker/locale/ro';

import { PlacementArray } from '../element.position';
import { DateStruct } from './date';

@Injectable({ providedIn: 'root' })
export class DatepickerConfig implements Partial<AirDatepickerOptions> {

  locale: AirDatepickerLocale = localeRo;

  markDisabled: (date: DateStruct, current?: { year: number, month: number }) => boolean;
  getDefaultValue: () => Date;
}

@Injectable({ providedIn: 'root' })
export class DatepickerInputConfig extends DatepickerConfig {

  dateFormat: string = 'dd/MM/yyyy';

  container: null | 'body';
  autoClose: boolean | 'inside' | 'outside' = true;
  placement: PlacementArray = ['bottom-left', 'bottom-right', 'top-left', 'top-right'];
  restoreFocus: true | HTMLElement | string = true;
  positionTarget: string | HTMLElement;
}