import { Injectable } from '@angular/core';

import { ConfigServiceNew } from './ConfigService';
import { UserService } from './UserService';
import { StyleManager } from './StyleManager';
import { LogService } from './LogService';
import { Config, App, AppContext } from '../constants/Enum';

const userSettingsKey = 'user.settings';

@Injectable({ providedIn: 'root' })
export class AppSettings {
    constructor(private config: ConfigServiceNew, private log: LogService, styleManager: StyleManager) {

        return new Proxy(this, {
            get(target, prop: string, receiver) {
                if (typeof target[prop] === 'function') {
                    return new Proxy(target[prop], {
                        apply(applyTarget, thisArg, args) {
                            return Reflect.apply(applyTarget, thisArg, args);
                        }
                    })
                }
                switch (prop) {
                    case 'css': return { agTheme: styleManager.css.agTheme };
                    case 'editform': return styleManager.editForm;
                    default: return target.setare.hasOwnProperty(prop)
                        ? target.setare[prop]
                        : target.hasOwnProperty(prop) ? target[prop] : undefined;
                }
            },
            set(target, prop: string, newValue: any, receiver: any) {
                if (typeof target[prop] === 'function') {
                    return false
                } else if (target.setare.hasOwnProperty(prop)) {
                    target.setare[prop] = newValue
                } else if (target.hasOwnProperty(prop)) {
                    target[prop] = newValue
                }
                config.set(Config[prop], newValue); // update setare db
                return true;
            },
        })
    }

    declare css: { agTheme: string };
    declare form: StyleManager['editForm'];

    private setare: { [key: string]: any } = {};
    private stareLuna: { [key: string]: boolean } = {};

    reportFormat: string;
    isReportAutoOpen: boolean = true;

    achizitii: ConfigAchizitii;
    conta: ConfigConta;

    isLunaInchisa(idAplicatie: App): boolean {
        if (this.stareLuna[`${idAplicatie}.${this.setare.anLuna}`]) {
            this.log.warning('Nu se poate opera!', 'Luna este inchisa!')
        }
        return this.stareLuna[`${idAplicatie}.${this.setare.anLuna}`]
        // return false
    }

    private getConfig(idAplicatie?: AppContext) {
        return this.config.get(Config[AppContext[idAplicatie]]).then(data => {
            let configObj: any;
            switch (idAplicatie) {
                // case App.buget: configObj = new ConfigBuget(data); break;
                // case App.contab: configObj = new ConfigContab(data); break;
                // case App.achizitii: configObj = new ConfigAchizitii(data); break;
                // case App.salarii: configObj = new ConfigSalarii(data); break;
                // case App.inventare: configObj = new ConfigInventare(data); break;
                // case App.persoane: configObj = new ConfigPersoane(data); break;
                // case App.adrese: configObj = new ConfigAdrese(data); break;
                // case App.incasari: configObj = new ConfigIncasare(data); break;
                // case App.documente: configObj = new ConfigDocumente(data); break;
                // case App.administrareinstitutie: configObj = new ConfigAdministrare(data); break;
                // case App.impotax: configObj = new ConfigImpotax(data); break;
                // case App.raportarifinanciare: configObj = new ConfigRaportariFinanciare(data); break;
                // case App.invatamant: configObj = new ConfigInvatamant(data); break;
                // case App.asisoc: configObj = new ConfigAsisoc(data); break;
                // case App.cantina: configObj = new ConfigCantina(data); break;
                // case App.investitii: configObj = new ConfigInvestitii(data); break;
                default:
                    console.log('Config neimplementat pentru aplicatia: ' + idAplicatie);
                    return null;
            }
            // return configObj.validate(redirectIfInvalid);
        })
    }
}

interface ConfigAchizitii {
    readonly idUnitate: number
}
interface ConfigConta {
    readonly idUnitate: number
}