import { Injectable } from '@angular/core';
import { CustomDate } from './date';
import { isInteger } from '../util';

export function fromJSDate(jsDate: Date) {
  return new CustomDate(jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate())
}

export function toJSDate(date: CustomDate) {
  const jsDate = new Date(date.year, date.month - 1, date.day, 12);
  // this is done avoid 30 -> 1930 conversion
  if (!isNaN(jsDate.getTime())) { jsDate.setFullYear(date.year) }
  return jsDate
}

export type DatePeriod = 'y' | 'm' | 'd';
export function DATEPICKER_CALENDAR_FACTORY() { return new CalendarGregorian() }

@Injectable({ providedIn: 'root', useFactory: DATEPICKER_CALENDAR_FACTORY })
export abstract class Calendar {

  /** Returns the number of days per week. */
  abstract getDaysPerWeek(): number;

  /** Returns an array of months per year. Default calendar: ISO 8601 and return [1, 2, ..., 12] */
  abstract getMonths(year?: number): number[];

  /** Returns the number of weeks per month. */
  abstract getWeeksPerMonth(): number;

  /** Returns the weekday number for a given day. Default calendar: ISO 8601: 'weekday' is 1=Mon ... 7=Sun */
  abstract getWeekday(date: CustomDate): number;

  /** Returns all weekdays with proper order */
  abstract getWeekdays(): number[];

  /** Returns the week number for a given week. */
  abstract getWeekNumber(week: readonly CustomDate[], firstDayOfWeek: number): number;

  /** Checks if a date is valid in the current calendar. */
  abstract isValid(date?: CustomDate | null): boolean;

  /** Returns the today's date. */
  abstract getToday(): CustomDate;

  /**
   * Adds a number of years, months or days to a given date. Always return new date.
   * * `period` can be `y`, `m` or `d` and defaults to day.
   * * `number` defaults to 1.
   */
  abstract getNext(date: CustomDate, period?: DatePeriod, number?: number): CustomDate;

  /**
   * Subtracts a number of years, months or days from a given date. Always return new date.
   * * `period` can be `y`, `m` or `d` and defaults to day.
   * * `number` defaults to 1.
   */
  abstract getPrev(date: CustomDate, period?: DatePeriod, number?: number): CustomDate;
}

@Injectable()
export class CalendarGregorian extends Calendar {

  getDaysPerWeek() { return 7 }
  getWeeksPerMonth() { return 6 }
  getWeekdays() { return [1, 2, 3, 4, 5, 6, 0] }
  getMonths() { return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] }

  getToday(): CustomDate { return fromJSDate(new Date()) }

  getPrev(date: CustomDate, period: DatePeriod = 'd', number = 1) {
    return this.getNext(date, period, -number)
  }
  getNext(date: CustomDate, period: DatePeriod = 'd', number = 1) {
    let jsDate = toJSDate(date), checkMonth = true;
    let expectedMonth = jsDate.getMonth();

    switch (period) {
      case 'y': jsDate.setFullYear(jsDate.getFullYear() + number); break;
      case 'm':
        expectedMonth += number;
        jsDate.setMonth(expectedMonth);
        expectedMonth = expectedMonth % 12;
        if (expectedMonth < 0) { expectedMonth = expectedMonth + 12 }
        break;
      case 'd':
        jsDate.setDate(jsDate.getDate() + number);
        checkMonth = false;
        break;
      default: return date;
    }

    if (checkMonth && jsDate.getMonth() !== expectedMonth) {
      // this means the destination month has less days than the initial month
      // let's go back to the end of the previous month:
      jsDate.setDate(0)
    }

    return fromJSDate(jsDate);
  }

  getWeekday(date: CustomDate) {
    let jsDate = toJSDate(date);
    let day = jsDate.getDay();
    return day === 0 ? 7 : day; // in JS Date Sun=0, in ISO 8601 Sun=7
  }

  getWeekNumber(week: readonly CustomDate[], firstDayOfWeek: number) {
    // in JS Date Sun=0, in ISO 8601 Sun=7
    if (firstDayOfWeek === 7) { firstDayOfWeek = 0 }

    const thursdayIndex = (4 + 7 - firstDayOfWeek) % 7;
    let date = week[thursdayIndex];

    const jsDate = toJSDate(date);
    jsDate.setDate(jsDate.getDate() + 4 - (jsDate.getDay() || 7));  // Thursday
    const time = jsDate.getTime();
    jsDate.setMonth(0);  // Compare with Jan 1
    jsDate.setDate(1);
    return Math.floor(Math.round((time - jsDate.getTime()) / 86400000) / 7) + 1;
  }

  isValid(date?: CustomDate | null): boolean {
    if (!date || !isInteger(date.year) || !isInteger(date.month) || !isInteger(date.day)) {
      return false
    }
    // year 0 doesn't exist in Gregorian calendar
    if (date.year === 0) { return false }

    const jsDate = toJSDate(date);
    return !isNaN(jsDate.getTime())
      && jsDate.getFullYear() === date.year
      && jsDate.getMonth() + 1 === date.month
      && jsDate.getDate() === date.day;
  }
}
