export enum ReportFolder {
	admin = "/",
	buget = "/",
	client = "/",
	momenclator = "/",
	conta = "/",
	furnizor = "/",
	document = "/",
	trezo = "/",
	miFix = "/",
	inventar = "/",
	salar = "/",
	stoc = "/"
}
export enum ReportFileType {
	pdf = 'pdf',
	word = 'word',
	excel = 'excel',
	csv = 'csv'
}

export enum FileEndpoints {
	search = '/file/search',
	upload = '/file/upload',
	download = '/file/download',
}
export enum DataImport {
	import = '/import',
}