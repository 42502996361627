<!-- style="border-top-right-radius:5px; border-top-left-radius:5px"-->
<div class="modal-header" [ngClass]="setIcon()">
    <h4>{{params.title || 'Confirm'}}</h4>
</div>
<div class="modal-body text-center">
    <i class="fa fa-2x" *ngIf="params.icon != 'none'" [ngClass]="setIcon(true)"></i>
    <br *ngIf="params.icon != 'none'" />
    <br *ngIf="params.icon != 'none'" />
    <div *ngIf="params.message" [ngClass]="textAlign()" class="overflow-auto"
        [innerHtml]="params.message" style="max-height:50vh; white-space: pre-wrap;">
    </div>
    <div *ngIf="params['template']" class="overflow-auto" style="max-height:50vh;"
        [innerHtml]="params['template']">
    </div>
</div>
<div class="modal-footer" *ngIf="btns.length > 0">
    <button *ngFor="let btn of btns" [type]="btn.type" class="btn" [ngClass]="btn.btnClass" (click)="onClick(btn)">
        <i class="fa fa-1x" [ngClass]="btn.icon"></i>&nbsp;{{btn.caption}}
    </button>
</div>