import {
    Component, OnInit, OnChanges, OnDestroy, SimpleChanges,
    Input, Output, EventEmitter, HostListener, forwardRef, ElementRef,
} from '@angular/core';
import {
    AbstractControl, NG_VALIDATORS, ValidationErrors, Validator
} from '@angular/forms';

import dayjs from 'dayjs';
import AirDatepicker, { AirDatepickerOptions } from 'air-datepicker'

import { DatepickerConfig } from './datepicker-config';
import { Keys } from '../element.key';

@Component({
    exportAs: 'datepicker',
    selector: 'datepicker', template: '',
    providers: [
        { provide: NG_VALIDATORS, multi: true, useExisting: forwardRef(() => Datepicker) },
    ],
})
export class Datepicker implements OnInit, OnChanges, OnDestroy, Validator {
    constructor(private el: ElementRef<HTMLInputElement>, private config: DatepickerConfig) { }

    private picker: AirDatepicker<HTMLInputElement>;
    @Input() options: Partial<AirDatepickerOptions>;
    @Input() date: Date;

    @Output() onShow = new EventEmitter<boolean>();
    @Output() onHide = new EventEmitter<boolean>();
    @Output() change = new EventEmitter<{ date: Date | Date[]; formattedDate: string | string[]; datepicker: AirDatepicker<HTMLInputElement>; }>();
    @Output() onSelect = new EventEmitter<{ date: Date | Date[]; formattedDate: string | string[]; datepicker: AirDatepicker<HTMLInputElement>; }>();

    ngOnInit(): void {
        this.picker = new AirDatepicker(this.el.nativeElement, Object.assign({
            inline: true,
            startDate: this.date,
            buttons: ['today', 'clear'],
        }, this.config, this.options, {
            onShow: (params) => this.onShow.emit(params),
            onHide: (params) => this.onHide.emit(params),
            onSelect: (params) => {
                this.date = params.date;
                this.onSelect.emit(params);
                this.change.emit(params);
            },
        }));
        this.picker.setFocusDate(this.date);
    }
    ngOnDestroy(): void {
        this.picker.destroy()
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.date && !changes.date.firstChange && changes.date.previousValue != changes.date.currentValue) {
            this.picker.selectDate(changes.date.currentValue, { silent: true })
        } else if (changes.options && !changes.options.firstChange && changes.options.previousValue != changes.options.currentValue) {
            this.picker.update(changes.options.currentValue)
        }
    }

    @HostListener('keydown', ['$event'])
    keydown(event: KeyboardEvent) {
        switch (event.key) {
            case Keys.ArrowUp: this.picker.up(); break;
            case Keys.ArrowDown: this.picker.down(); break;
            case Keys.ArrowLeft: this.picker.prev(); break;
            case Keys.ArrowRight: this.picker.next(); break;
            case Keys.Backspace:
            case Keys.Delete:
                this.picker.clear(); break;
        }
    }
    validate(control: AbstractControl<any, any>): ValidationErrors {
        const { value } = control;

        if (value != null) {
            let currentDate = this.picker.viewDate;
            if (!currentDate) { return { date: { invalid: value } } }

            if (this.options.minDate && dayjs(currentDate).isBefore(this.options.minDate)) {
                return { min: { invalid: value, minDate: this.options.minDate } }
            } else if (this.options.maxDate && dayjs(currentDate).isAfter(this.options.maxDate)) {
                return { max: { invalid: value, maxDate: this.options.maxDate } }
            }
        }
        return null;
    }
}