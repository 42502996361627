import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class FileReaderService {
    constructor() { }

    /** if no file is provided will open file selection */
    readAsText(
        acceptedExtensions?: string | string[], file?: Blob | File, progress?: Subject<number>
    ): Promise<string> { return this.read('readAsBinaryString', file, acceptedExtensions, progress) }

    /** if no file is provided will open file selection */
    readAsDataURL(
        acceptedExtensions?: string | string[], file?: Blob | File, progress?: Subject<number>
    ): Promise<string> { return this.read('readAsDataURL', file, acceptedExtensions, progress) }

    /** if no file is provided will open file selection */
    readAsBinaryString(
        acceptedExtensions?: string | string[], file?: Blob | File, progress?: Subject<number>
    ): Promise<string> { return this.read('readAsBinaryString', file, acceptedExtensions, progress) }

    /** if no file is provided will open file selection */
    readAsArrayBuffer(
        acceptedExtensions?: string | string[], files?: Blob | File, progress?: Subject<number>
    ): Promise<ArrayBuffer> { return this.read('readAsArrayBuffer', files, acceptedExtensions, progress) }

    /** will open browser file selection explorer */
    select(accept?: string | string[]): Promise<File> {
        return new Promise((resolve, reject) => {
            var input = document.createElement('input');
            input.type = 'file';
            input.setAttribute("style", "display:none");
            var ext = Array.isArray(accept) ? accept : [accept];
            if (accept) { input.accept = ext.join(',') }

            input.onchange = function (evt) {
                var search = new RegExp('(' + ext.join('|').replace(/,/g, '|') + ')$', 'i');
                if (!search.test(this['files'][0].name)) {
                    reject(`Se accepta doar fisiere de tip ${ext.join()}`)
                }
                resolve(this['files'][0])
            };
            document.body.appendChild(input);
            input.click();
            setTimeout(function () { document.body.removeChild(input) }, 333);
        })
    }

    private read(
        readAs: string, file?: Blob | File, acceptedExtensions?: string | string[], progress?: Subject<number>
    ): Promise<any> {
        var promise = file ? Promise.resolve(file) : this.select(acceptedExtensions);
        return promise.then((file) => {
            var reader = new FileReader();
            return new Promise((resolve, reject) => {
                reader.onabort = function onAbort() { reject(reader.result) };
                reader.onload = function onLoad() { resolve(reader.result || '') };
                reader.onprogress = (evt: ProgressEvent) => {
                    progress && progress.next(evt.lengthComputable ? Math.round((evt.loaded / evt.total) * 100) : 0)
                };
                reader.onerror = function errorHandler(error) {
                    console.error(error)
                    switch (error.target.error.code) {
                        case error.target.error.NOT_FOUND_ERR: reject('File Not Found!'); break;
                        case error.target.error.NOT_SUPPORTED_ERR: reject('File is not readable!'); break;
                        case error.target.error.ABORT_ERR: reject(); break; // noop
                        default: reject('An error occurred reading this file!');
                    }
                };
                reader[readAs](file);
            })
        })
    }
}