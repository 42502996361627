import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { IRequestConfig, IRootScopeService } from 'angular'; // will be removed

import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
// import { AuthService } from 'ng2-ui-auth';

import { routes } from '../routes.ng1';
import { ConfigService } from './ConfigService';
import { DialogService } from './DialogService';
import { DataService } from './DataService';
import { LogService } from './LogService';
import { Query } from '../constants/Query';
import { Config } from '../constants/Enum';

enum TokenType { access = "access_token", refresh = "refresh_token" }
const storageKey = 'authorizationData'; //'currentUser';

@Injectable({ providedIn: 'root' })
export class UserService { // private auth: AuthService
    constructor(private router: Router, private http: HttpClient,
        private data: DataService, private log: LogService,
        private config: ConfigService, private dialog: DialogService,
        @Inject('$rootScope') private $rootScope: IRootScopeService,
        @Inject('$state') private $state
    ) {
        const myToken = JSON.parse(localStorage.getItem(storageKey));
        this.currentUserSubject = new BehaviorSubject<User.Identity>(myToken);
        this.authorizationData = myToken;

        this.onLoginComplete = this.currentUserSubject.asObservable();
        // this.loginComplete.subscribe(() => {
        //     console.log("loginComplete.subscripstion");
        //     Promise.all([this.config.getConfigs()]).then((data) => {
        //         this.getUserAuthorization().then(() => { console.log("getUserAuthorization complete"); })
        //     })
        // })
    }

    public permissions: { [key: string]: number };
    public onLoginComplete: Observable<User.Identity>;
    private currentUserSubject: BehaviorSubject<User.Identity>;

    get nivelAcces() {
        return this.permissions ? Promise.resolve(this.permissions || {}) : this.getNivelAcces()
    }
    getNivelAcces(): Promise<{ [key: string]: number }> {
        return this.data.executeQueryNew(Query.user.getAuthorization()).then(data => {
            return this.permissions = JSON.parse(data[0].auth)
        })
    }

    get identity(): User.Identity { return this.currentUserSubject.value || <any>{} }
    get token(): string { return this.identity.token || '' }
    get isAuthenticated() { return !!this.identity.token }

    private setToken(
        token: string, id: number, email: string, userName: string, name: string,
        provider: string, refreshToken?: string, useRefreshToken?: boolean, activeDirectory?: boolean
    ) {
        let user = new User.Identity(
            token, id, email, name, userName, provider,
            refreshToken, useRefreshToken, activeDirectory
        );
        localStorage.setItem(storageKey, JSON.stringify(user));
        this.currentUserSubject.next(user);
        // console.log("broadcast", this.config.events.loginCompleted);
        // this.$rootScope.broadcast(this.config.events.loginCompleted, user);
    }

    private request(url: string, registration: User.IRegistration) {
        return this.http.post(url, registration, { responseType: 'text' })
    }
    changeEmail(registration: User.IRegistration) {
        return this.request('/user/change/email', registration)
    }
    changePassword(registration: User.IRegistration) {
        return this.request('/user/change/pwd', registration)
    }
    resetPassword(registration: User.IRegistration) {
        return this.request('/user/reset/pwd', registration)
    }
    confirmResetPassword(registration: User.IRegistration) {
        return this.request('/user/reset/pwd/confirm', registration)
    }
    register(registration: User.IRegistration) {
        return this.request('/user/register', registration)
    }
    loginNew(loginData: User.IRegistration) {
        return this.http.post((loginData.activeDirectory ? '/auth/activeDirectory' : '/auth/token'), {
            username: loginData.userName,
            password: window.btoa(encodeURIComponent(loginData.password)),
            grant_type: 'credentials'
        }).pipe(
            map(res => res["authorizationData"]),
            tap(res => {
                this.setToken(
                    res["access_token"], res["id"] || res["idUser"], res["email"],
                    loginData.userName, res["name"], res["provider"], res["refresh_token"])
            }),
            catchError(error => {
                this.logOutNew();
                throw error;
            })
        )
    }
    loginWithSatellizerTokenNew(provider: string, loginData: User.IRegistration) {
        // return this.auth.authenticate(provider, loginData).pipe(
        //     tap(res => {
        //         this.setToken(
        //             res["access_token"], res["id"] || res["idUser"], res["idPersoana"],
        //             res["email"], res["userName"], res["name"], res["provider"])
        //     }),
        //     catchError(err => {
        //         this.auth.logout();
        //         throw err;
        //     }))
    }
    refreshToken() {
        return this.http.post('/auth/token', {
            refresh_token: this.identity.refreshToken || '',
            grant_type: 'refresh_token',
        }).pipe(tap(res => {
            this.setToken(
                res["access_token"], res["id"], res["email"], res["userName"],
                res["name"], res["provider"], res["refresh_token"], res["useRefreshToken"])
        }))
    }
    logOutNew(skipNavigation?: boolean) {
        sessionStorage.clear();
        localStorage.removeItem(storageKey);
        this.permissions = null;
        this.currentUserSubject.next(null);
        !skipNavigation && this.router.navigate(['login']);
    }



    // old, to be removed

    private authorizationData;
    private REFRESH_TOKEN_REQUEST;

    initFile: string;
    domainName: string;
    backupActive: boolean;
    states: any[] = [];
    homePageStates: any[] = [];
    favoriteStates: any[] = [];
    refreshingStates: boolean = false;

    get authData() { return this.authorizationData || {} }
    set authData(data) { this.setAuthorizationData(data) }

    private getAuthorizationData() {
        return JSON.parse(localStorage.getItem('authorizationData') || '{}')
    }
    private setAuthorizationData(data) {
        this.authorizationData = {
            "userName": data['userName'],
            "name": data['name'],
            "provider": data['provider'],
            "access_token": data['access_token'],
            "refresh_token": data['refresh_token'] || this.authData["refresh_token"] || '',
            "useRefreshTokens": (data['refresh_token'] || this.authData["refresh_token"]) ? true : false,
            ".expires": data['.expires']
        };
        this.setToken(
            data["access_token"], data["id"] || data["idUser"], data["email"],
            data.userName, data["name"], data["provider"], data["refresh_token"]
        );
        localStorage.setItem('authorizationData', JSON.stringify(this.authorizationData))
    }
    private removeAuthorizationData() {
        this.authorizationData = null;
        localStorage.removeItem('authorizationData');
    }

    authToken = {
        get: (tokenType: string): string => { return this.authData[tokenType]; },
        useRefreshTokens: (): boolean => { return this.authData.useRefreshTokens; },
        isExpired: (): boolean => {
            return (this.authData[".expires"] && (this.authData[".expires"] <= (new Date()).toJSON())) ? true : false;
        },
        refresh: (): Promise<any> => {
            this.REFRESH_TOKEN_REQUEST = this.getRefreshTokenRequest();

            return this.REFRESH_TOKEN_REQUEST
                .then(response => { return response })
                .catch(err => { return this.isAuthenticated ? this.openAuthenticateUserModal() : Promise.reject(err) })
                .finally(() => { this.REFRESH_TOKEN_REQUEST = null })
        }
    }

    retryRequest(config) {
        return this.http.post(config.url, this.configWithAuthorizationHeaders(config));
    }
    setAuthorizationHeaders(config: IRequestConfig, request?): Promise<any> | IRequestConfig {
        if (this.authToken.isExpired() && (this.authToken.useRefreshTokens() || this.isAuthenticated)) {
            return this.authenticateUser().then((data) => {
                return this.configWithAuthorizationHeaders(config, request)
            }, (error) => {
                if (error && (typeof error === 'object' && error !== null) && !error.config) { error.config = config }
                this.logOut();
                throw error;
            })
        } else {
            return this.configWithAuthorizationHeaders(config, request)
        }
    }
    configWithAuthorizationHeaders(config: IRequestConfig, request?): IRequestConfig {
        if (config && config.headers) {
            var token = this.authToken.get("access_token");
            // adauga cookie "Authorization"
            if (token) { document.cookie = `Authorization=${token}` }
            // adauga header "Authorization"
            // if (token) { config.headers["Authorization"] = `Bearer ${token || ''}` }
            // adauga header "AccessDate"
            if (this.$state && this.$state.current) {
                const state = this.$state.current.url.replace('^', '');
                // config.headers = request.headers.set('accessDate', { current: state, real: this.router.url });
                config.headers["accessDate"] = JSON.stringify({
                    current: state, real: this.router.url
                });
            }
        }
        return config;
    }
    authenticateUser(userData?: User.IRegistration): Promise<any> {
        var promise = userData
            ? this.getTokenRequest(userData)
            : (this.authToken.useRefreshTokens() ? this.authToken.refresh() : this.openAuthenticateUserModal());

        return promise.then(response => {
            this.setAuthorizationData(response["authorizationData"]);
            return this.onAuthenticationCompleted().then(() => { return response });
        })
    }
    getTokenRequest(userData: User.IAuthentication) {
        var data = `grant_type=credentials&username=${userData.userName}&password=${window.btoa(encodeURIComponent(userData.password))}`;
        if (userData.useRefreshTokens) {
            data += `&client_id=${this.config.clientId}&useRefreshTokens=${userData.useRefreshTokens}`
        }
        return firstValueFrom(this.http.post(userData.activeDirectory ? `/auth/activeDirectory` : `/auth/token`, data, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }))//.pipe(map(res => { return { data: res } }))) // de vazut dc e comentat
    }

    //     return states.some(s =>
    //     (s.module.nume || "").toLowerCase() !== "configurări"
    // ) ? states : [];

    ng2Menu;
    getAllowedStates() {
        return Promise.resolve(
            this.identity.isAuthenticated ? this.$state.get().filter(s => s.nivelAcces) : []
        );
    }
    setAllowedStates(auth?: Object) {
        if (auth) {
            this.$state.get().forEach(s => {
                s.nivelAcces = auth[s.hierarchyId];
                if (s.nivelAcces)
                    s.commands.forEach(cmd => { cmd.nivelAcces = auth[cmd.hierarchyId]; });
            });
        } else {
            this.$state.get().forEach(s => {
                delete s["nivelAcces"];
            });
        }
        this.refreshStates();
    }
    refreshStates(cfg?: { idUnitate?: number, event?: string }) {
        if (this.refreshingStates) return;

        this.refreshingStates = true;
        this.getAllowedStates().then((states) => {
            if (states && states.length) {
                var getIdUnitate = () => cfg && cfg.idUnitate && Number.isInteger(cfg.idUnitate)
                    ? Promise.resolve(cfg.idUnitate)
                    : this.config.get(Config.configIncasare$Incasarisiplati$Incasarisiplati).then((value: any) => { return value.idUnitate });

                getIdUnitate().then((idUnitate: any) => {
                    this.data.executeQueryNew(Query.admin.resursa.getMacheteBySetari(idUnitate)).then((data) => {
                        var visibilityObject;
                        try {
                            visibilityObject = JSON.parse(data[0]["json"]);
                        } catch (err) {
                            console.error(err);
                        }
                        if (visibilityObject) {
                            states.forEach((s) => {
                                if (s.options.hasVisibilityCondition && visibilityObject.hasOwnProperty(s.id)) {
                                    s.options.isStartPageMenu = visibilityObject[s.id] ? true : false;
                                }
                            })
                        }
                        //--
                        this.states = states;
                        this.homePageStates = this.getHomePageStates().concat(routes);
                        this.config.get(Config.favorites$Administraresistem$Administraresistem).then((favorites) => {
                            this.favoriteStates = this.getFavoriteStates(favorites || []);
                            this.refreshingStates = false;

                            this.$rootScope.$broadcast(this.config.events.statesUpdated);
                        }).catch((err) => {
                            this.refreshingStates = false;
                        });
                        //if (cfg && cfg.event) {
                        //    this.log.info(`Meniu principal actualizat`);
                        //}

                    }).catch((err) => { this.refreshingStates = false; });
                }).catch((err) => { this.refreshingStates = false; })
            } else {
                this.refreshingStates = false;
            }
        }).catch((err) => { this.refreshingStates = false; })
    }
    public getHomePageStates(): any[] {
        var states = this.states.filter(s => s.options && s.options.isStartPageMenu);

        if (!this.backupActive)
            states = states.filter(s => (s.module.nume || "").toLowerCase() !== "backup");

        return states.some(s =>
            (s.module.nume || "").toLowerCase() !== "configurări"
        ) ? states : [];

        //return states.some(s => s.module.nume !== "configurări") ? states : [];
    }
    public getFavoriteStates(favorites?: string[]): any[] {
        return (favorites && favorites.length) ?
            this.homePageStates.filter(s => {
                if (~favorites.indexOf(s.name)) {
                    s.isFavorite = true;
                    return s;
                }
            }) : [];
    }
    getRefreshTokenRequest(): Promise<any> {
        return (
            this.REFRESH_TOKEN_REQUEST
            ||
            firstValueFrom(this.http.post('/token', `grant_type=refresh_token&refresh_token=${this.authToken.get("refresh_token")}&client_id=Sigma`, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }))
        )
    }

    /** load user authorization, configs ... etc */
    onAuthenticationCompleted() {
        return Promise.all([this.config.getConfigs()]).then((data) => {
            return this.getUserAuthorization().then(() => {
                this.$rootScope.$broadcast(this.config.events.loginCompleted);
                return data;
            })
        })
    }

    sendEmail(registration: User.IRegistration) {
        registration['url'] = this.router.url;
        return firstValueFrom(this.http.post('/sendResetPasswordEmail', registration));
    }

    pwReset(registration: User.IRegistration) {
        return firstValueFrom(this.http.post('/pwReset', registration).pipe(tap(response => {
            if (response["authorizationData"]) {
                this.setAuthorizationData(response["authorizationData"]);
                this.onAuthenticationCompleted();
            }
        })))
    }

    changeUserData(registration: User.IRegistration, isPw?: boolean, isFromAdmin?: boolean) {
        if (isFromAdmin && registration) { registration['isFromAdmin'] = isFromAdmin }
        return firstValueFrom(this.http.post((isPw ? '/changePw' : '/changeEmail'), registration))
    }

    saveRegistration(registration: User.IRegistration, logOut: boolean = true) {
        if (logOut) { this.logOut() }
        registration['url'] = this.router.url;
        return firstValueFrom(this.http.post('/register', registration))
    }

    getUserAuthorization() {
        if (!this.isAuthenticated) { return Promise.reject(false) }
        return this.data.executeQueryNew(Query.comun.userServices.getUserAuthorization()).then(data => {
            this.setAllowedStates(JSON.parse(data[0].auth));
        })
    }

    loginWithSatellizerToken(token: any) {
        // this.$auth.logout(); //satellizer
    }

    openAuthenticateUserModal() {
        // return Promise.resolve({})
        // return this.dialog.custom(ModalAutentificareUser, { userName: this.identity.userName }).result.then(res => {
        //     res["fromModal"] = true;
        //     return res;
        // })
        return this.dialog.modalAutentificare({ userName: this.identity.userName }).then((response) => {
            response["fromModal"] = true;
            return response;
        });
    }

    login(loginData: User.IRegistration) {
        if (this.checkLoginData(loginData)) {
            return this.authenticateUser(loginData).then(response => {
                response
            }, (err) => {
                this.logOut();
                throw this.errorHandler(err);
            })
        } else {
            return Promise.reject(this.errorHandler({ data: "Completați nume și parola utilizator!" }))
        }
    }
    private checkLoginData(loginData: User.IAuthentication): boolean {
        return (loginData
            && loginData.userName && loginData.userName.length
            && loginData.password && loginData.password.length) ? true : false;
    }
    private errorHandler(err: any): { data: any, status: number } {
        const error: { data: any, status: number } = { data: "", status: 400 };
        try {
            if (err) {
                if (typeof err == 'object' && err !== null) {
                    if (err.data) { error.data = err.data }
                    if (err.status) { error.status = err.status }
                } else if (typeof err == 'string') {
                    error.data = err
                } else { throw err }
            }
            this.log.error(error.data, null, err);
            return error;
        } catch (e) { return err }
    }

    /** @deprecated */
    logOut(isUserAction?: boolean) {
        sessionStorage.clear(); // sterge rutele din cache (etc etc)
        if (this.isAuthenticated) {
            this.removeAuthorizationData();
            this.setAllowedStates();

            this.permissions = null;
            // this.currentUserSubject.next(null);
            localStorage.removeItem(storageKey);

            if (!isUserAction) {
                this.log.error('Ai fost deconectat, trebuie să te autentifici din nou.')
            }
            this.router.navigate(['login']);
        }
    }

    fillAuthData() {
        if (!this.authData || !Object.keys(this.authData).length) {
            const auth = this.getAuthorizationData();
            this.currentUserSubject.next(new User.Identity(
                auth.token, null, auth.email, auth.nume, auth.userName,
                auth.provider, auth.refreshToken, auth.refreshToken
            ));
        } else if (this.authData && !this.authToken.isExpired()) {
            this.currentUserSubject.next(new User.Identity(
                this.authData.userName, null, this.authData.email,
                this.authData.name, this.authData.userName,
                this.authData.provider, null, this.authData.useRefreshTokens
            ))
        }
    }
    /**
    * post la syncUsers din user.js si actualizeaza identity.name si authorizationData din localStorage
    */
    syncCurrentIdentity() {
        this.http.post('/syncUsers', { params: { currentUserName: this.identity.userName } }).subscribe((response) => {
            var authData = this.getAuthorizationData();
            this.identity.name = response["Nume"];
            authData.name = response["Nume"];
            this.setAuthorizationData(authData);
        });
    }

    onError(err: any): { data: any, status: number } {
        try {
            const error: { data: any, status: number } = { data: "", status: 400 };
            if (err) {
                if (Object.prototype.toString.call(err) === '[object Object]') {
                    if (err.data) error.data = err.data;
                    if (err.status) error.status = err.status;
                } else if (typeof err == 'string') {
                    error.data = err;
                } else throw err;
            }
            this.log.error(error.data, err, true);
            return error;
        } catch (e) { return err; }
    }
    onResponseError(rejection): Promise<any> {
        console.log("rejection.status: ", rejection.status);
        switch (rejection.status) {
            case 401:
                console.log(this.authToken.useRefreshTokens(), this.isAuthenticated);
                if (this.authToken.useRefreshTokens() || this.isAuthenticated) {
                    return this.authenticateUser().then(() => {
                        return firstValueFrom(this.retryRequest(rejection.config)).then(() => rejection.config)
                    }).catch(err => {
                        this.logOut();
                        throw rejection;
                    })
                } else {
                    this.logOut();
                    this.logOutNew();
                    return Promise.reject(rejection);
                }
            case 403:
                // this.$state.go(States.admin.stop, { pageTitle: "Access denied" });
                return Promise.reject(rejection);
            default: return Promise.reject(rejection);
        }
    }
}

export module User {
    // export enum Providers { Indeco, Facebook, Google, Microsoft, Yahoo, LinkedIn }
    export interface IAuthentication {
        userName: string;
        password?: string;
        useRefreshTokens?: boolean;
        activeDirectory?: boolean;
    }
    export interface IRegistration extends IAuthentication {
        email: string;
        newPassword?: string;
        confirmPassword?: string;
        confirmEmail?: boolean;
    }
    export class Identity {
        constructor(
            public token: string,
            public id: number,
            public email: string,
            public name: string,
            public userName: string,
            public provider: string,
            public refreshToken?: string,
            public useRefreshTokens?: boolean,
            public isActiveDirectory?: boolean,
            public expires?: string,
        ) { }

        access_token = this.token;
        refresh_token = this.refreshToken;
        isAuthenticated: boolean = !!this.token; // will be removed
    }
}