import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { WebSoketService } from '../../services/WebSocketService';
import { LogService } from '../../services/LogService';

@Component({
    selector: 'dialog-progress', templateUrl: './dialog.progress.html',
})
export class ProgressDialog implements OnInit {
    constructor(public activeModal: NgbActiveModal,
        private ws: WebSoketService, private log: LogService) { }

    @Input() params: Dialog.progressWSParams;
    set message(msg: string) { this.progress.message = msg }
    set procent(proc: number) { this.progress.procent = proc }
    private sub: Subscription;

    progress = {
        message: 'Se prelucreaza datele. Va rugam asteptati.',
        procent: 0, autoClose: false, error: null, detaliu: null
    };

    cancel() {
        this.sub.unsubscribe();
        this.activeModal.dismiss();
    }
    close(finish?: boolean) {
        this.sub.unsubscribe();
        this.activeModal.close(finish);
    }
    ngOnInit() {
        this.progress.message = this.params && this.params.message;
        this.progress.autoClose = this.params && this.params.autoClose;

        this.sub = this.ws.send(this.params.ws.event, this.params.ws.message).subscribe({
            error: (err) => { this.log.warning(err.message) },
            next: (data) => {
                if (this.params.ws.callback) {
                    data = this.params.ws.callback(data) || {}
                }
                this.progress.message = data.message;
                this.progress.procent = data.progress;
                this.progress.autoClose = data.autoClose;

                const err = (data.detailedError || data.error);
                if (err) { this.progress.error = err.message || err }
                if (this.progress.autoClose && !this.progress.error) { this.close(true) }
            },
        })
    }
}